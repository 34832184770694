/**
 * クラス名：形鋼の材質名称詳細画面
 * 説明：形鋼に使用の材質名称詳細画面です。
 * 作成者：ナンス
 * 作成日：2024/10/09
 * バージョン：1.0
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Select, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import { formValidatorMode, isDecimal, defaultValue, toDecimal, regexExp, toHalfWidth } from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';
import { getIsExistMaterialClassName, getIsExistSteelMaterialName } from '../../../common/CommonAPI';

dayjs.extend(customParseFormat);

const SteelMaterialNameDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [isNoData, setIsNoData] = useState(false);
  const [detailEditMode, setDetailEditMode] = useState(false);

  const inputItemRef = {};
  const editMode = props.editMode;

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let detailData = props.selectedData;
      detailData.info.gravity = toDecimal(detailData.info.gravity, 2);
      detailData.info.lossCoeff = toDecimal(detailData.info.lossCoeff, 2);
      let selectedData = JSON.parse(JSON.stringify({ ...detailData }));
      setOriginData({ ...props.selectedData });
      setSelectedRowData(selectedData);
      setIsNoData(false);
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode && detailEditMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  useEffect(() => {
    setMaterialTypeList(props?.materialTypeList);
  }, [props.materialTypeList]);

  const editDetailInput = (objKey, value) => {
    if (editMode === false) return;
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData, info: { ...prevData.info } };
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  const editData = () => {
    props.updateEditMode(true);
    setDetailEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = async (inputItems, procMode, isDetail, materialClassNameLst) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;

    // 材質名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材質区分名
    if (isDetail) {
      input = document.getElementsByName('materialRef')?.[0];
    } else {
      input = document.getElementsByName('materialTypeId')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.materialType && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質区分名').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 比重
    input = inputItems['gravity']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('比重').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('比重').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    let materialClassId = procMode === formValidatorMode.Create ? 0 : selectedRowData.id;
    let name = inputItems['name']?.input?.value;
    if (name !== '') {
      let isExistCodeName = await getIsExistSteelMaterialName(materialClassId, name);
      if (isExistCodeName) {
        message.push(<li>{ErrorMessage('材質名称').E020}</li>);
        input = inputItems['name']?.input;
        input?.classList?.remove('input-error');
        input?.classList.add('input-error');
        ret = false;
      }
    }
    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = async (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      var materialClassNameLst = props.materialClassNameData?.filter((item) => item.id !== selectedRowData.id);
      if (!(await formValidation(inputItemRef, formValidatorMode.Edit, true, materialClassNameLst))) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };
      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
      setDetailEditMode(false);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData({ ...props.selectedData });
    setCancelConfirm(false);
    setDetailEditMode(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (isCheckZenkaku) {
      if (name === 'gravity') {
        value = toDecimal(value, 2);
      } else {
        value = toDecimal(value === '' ? 1 : value, 2);
      }
    }
    editDetailInput(name, value);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode && detailEditMode ? 'input-editable' : 'input-non-editable'}
                    name="code"
                    id="code"
                    value={selectedRowData?.code}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['code'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('code', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode && detailEditMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('name', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質区分名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質区分名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode && detailEditMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          let gravityLst = props?.steelMaterialNames?.filter((item) => item.materialTypeId === e);
                          let curGravity = selectedRowData?.info?.gravity;
                          if (gravityLst?.length > 0) {
                            curGravity = gravityLst?.[0]?.info.gravity;
                          } else {
                            curGravity = 0;
                          }
                          curGravity = curGravity ? toDecimal(curGravity, 2) : toDecimal(0, 2);
                          editDetailInput('materialTypeId', e);
                          setSelectedRowData((prevData) => {
                            const updatedData = { ...prevData, info: { ...prevData.info } };
                            updatedData.info.gravity = curGravity;
                            return updatedData;
                          });
                        }}
                        placeholder="材質区分名"
                        id="materialRef"
                        name="materialRef"
                        value={selectedRowData?.materialTypeId}
                      >
                        {materialTypeList?.map((item, index) => (
                          <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">
                      {props?.getMaterilTypeName(selectedRowData?.materialTypeId)}
                    </label>
                  )}
                </td>
              </tr>
              {/* 比重 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">比重</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode && detailEditMode ? 'input-editable' : 'input-non-editable'}
                    name="gravity"
                    id="gravity"
                    value={selectedRowData?.info?.gravity}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['gravity'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                  />
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default SteelMaterialNameDetail;
