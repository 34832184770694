/**
 * クラス名：形鋼子部品見積詳細
 * 説明：板形鋼子部品見積詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/07
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { Input, Row, Col, Select, Image, Space, Button, Radio, Checkbox, Divider, Modal, Tabs } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';

import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  isDigit,
  handleKeyPress,
  JPYs,
  toDecimal,
  getDisplayMaterialSizeIq3,
  getMaterialAutoSize,
  getInitMaterialPatternsInfo,
  getInitMaterialSizeInfo,
  toCheckZenkaku,
  currentTime,
  currentDate,
  formatDateString,
  padWithLeadingZeros,
  isDigitZenkaku,
  regexExp,
  secondsToHms,
  toDecimal_place3,
} from '../common/Common.js';
import { ErrorMessage } from '../common/Message.js';
import { error } from '../common/CommonModal.js';
import CurrencyInput from 'react-currency-input-field';
import { WorkType } from '../common/enums.js';
import { PNG, STEEL_SHAPE_URL, unitLst } from '../common/Constant.js';

const RadioGroup = Radio.Group;

const IQ5EstimateDetail = forwardRef((props, ref) => {
  const [detailData, setDetailData] = useState([]);
  const [currentRecordIndex, setCurrentRecordIndex] = useState(1);
  const [materialInstruction, setMaterialInstruction] = useState(1);
  const [openWorkType, setOpenWorkType] = useState(WorkType.PaSteelCut);
  const [dataType, setDataType] = useState(0);
  const [materialIq5Info, setMaterialIq5Info] = useState({});
  const [materialIq5UnitType, setMaterialIq5UnitType] = useState(1);

  useEffect(() => {
    if (props?.tabOpen || props?.selectedIQ5DataDetail) {
      initEstimateDetailsInfo(props.selectedIQ5DataDetail);
      let curRowNo = props?.currentRecordNo ? props?.currentRecordNo : 1;
      for (let i = 0; i < props.selectedEstimateData?.esIq5Info?.length; i++) {
        if (props.selectedEstimateData?.esIq5Info[i]?.no === curRowNo) {
          setCurrentRecordIndex(i);
        }
      }
    }
  }, [props?.tabOpen, props.selectedIQ5DataDetail]);

  const initEstimateDetailsInfo = (selectedIQ5DataDetail) => {
    const childPartDetail = selectedIQ5DataDetail;
    setDetailData({ ...childPartDetail });
    setDataType(childPartDetail?.additions?.length > 0 ? childPartDetail?.additions?.[0]?.dataType : 0);
    let materialIq5Parm = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.materialIq5;
    let materialIq5 = materialIq5Parm?.filter((item) => item.id === childPartDetail?.materialIq5Id);
    setMaterialIq5Info(materialIq5?.length > 0 ? materialIq5?.[0] : {});
    setMaterialIq5UnitType(childPartDetail?.materailUnitType);
  };

  useImperativeHandle(ref, () => ({}), [detailData]);

  const onChangeProcessDetail = (workType) => {
    setOpenWorkType(workType);
    props?.onChangeOpenProcessDetail(true, workType);
  };

  const materialInstructionLst = [
    {
      label: '自動',
      key: 1,
    },
    {
      label: '手動',
      key: 2,
    },
    {
      label: '定尺',
      key: 3,
    },
  ];

  const standardLen = [
    {
      label: '2m',
      key: 1,
    },
    {
      label: '4m',
      key: 2,
    },
    {
      label: '4.5m',
      key: 3,
    },
    {
      label: '5.5m',
      key: 4,
    },
    {
      label: '8m',
      key: 5,
    },
    {
      label: '10m',
      key: 6,
    },
    {
      label: '12m',
      key: 7,
    },
  ];

  return (
    <>
      {/* {props.tabOpen ? ( */}
      <div style={{ width: '100%', paddingTop: 0 }}>
        {/* 基本情報 */}
        <Row style={{ width: '100%' }}>
          <Col span={21}>
            <label className="detail-iq5-tbl-val-title-label">【基本情報】</label>
            <div>
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 材料名称 */}
                  <tr>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">材料名称</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">{detailData?.materialName}</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">番号</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 3 }}
                        className={'input-editable-iq5-detail'}
                        name="drawingNo"
                        id="drawingNo"
                        value={detailData?.drawingNo}
                        type="text"
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">寸法</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 5 }}
                        className={'input-editable-iq5-detail'}
                        name="size"
                        id="size"
                        value={detailData?.size}
                        type="text"
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">員数</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 5 }}
                        className={'input-editable-iq5-detail'}
                        name="partCount"
                        id="partCount"
                        value={detailData?.partCount}
                        type="text"
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">
                        面積(cm<sup>2</sup>)
                      </label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">
                        {detailData?.totalSurfaceArea ? Math.round(detailData?.totalSurfaceArea) : 0}
                      </label>
                    </td>
                  </tr>
                  {/** 材質名称 */}
                  <tr>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title ">
                      <label>材質名称</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">{detailData?.materialTypeName}</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label>名称</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 5 }}
                        className={'input-editable-iq5-detail'}
                        name="name"
                        id="name"
                        value={detailData?.name}
                        type="text"
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title ">
                      <label>鋼材</label>
                    </td>
                    <td colSpan={2} style={{ width: '7%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">{detailData?.steelShapeName}</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label>重量(kg)</label>
                    </td>
                    <td colSpan={2} style={{ width: '7%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">
                        {detailData?.weight ? toDecimal_place3(detailData?.weight, 3) : toDecimal_place3(0, 3)}
                      </label>
                    </td>
                  </tr>
                </thead>
              </Table>
            </div>
            <div style={{ textAlign: 'left', marginTop: 5 }}>
              <Button
                className={'iq5-material-select-btn'}
                onClick={(e) => {
                  {
                    props?.showSteelMaterialList();
                  }
                }}
              >
                選択
              </Button>
            </div>
          </Col>
          <Col span={3}>
            <div className="steel-shape-img-div">
              <img
                className={
                  detailData?.imageId === undefined || detailData?.imageId === '' ? 'pmx-no-img' : 'steel-shape-img'
                }
                src={STEEL_SHAPE_URL + detailData?.imageId + PNG}
                alt=""
              />
            </div>
          </Col>
        </Row>
        {/* 材料情報 */}
        <Row style={{ width: '100%', marginTop: 20 }}>
          <Col span={24}>
            <label className="detail-iq5-tbl-val-title-label">【材料】</label>
            <Table className="iq5-detail-tbl">
              <thead>
                {/** 部品合計(mm) */}
                <tr>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>部品合計(mm)</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.size *
                        detailData?.partCount *
                        props?.selectedEstimateData?.estimateProducts?.quantity}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料指示</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={1}
                      id="materialInstruction"
                      name="materialInstruction"
                      className={'materialInstruction'}
                      value={materialInstruction}
                      onChange={(e) => {
                        if (materialInstruction != e) {
                          setMaterialInstruction(e);
                        }
                      }}
                    >
                      {materialInstructionLst?.map((item, index) => (
                        <Select.Option key={index} id={'materialInstruction' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料寸法</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{detailData?.size}</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>重量</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.weight ? toDecimal_place3(detailData?.weight, 3) : toDecimal_place3(0, 3)}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>捨代</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{0}</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>刃幅</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{0}</label>
                  </td>
                </tr>
                {/** 本数 */}
                <tr>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title ">
                    <label>本数</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>定尺名称</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={''}
                      id="standardLen"
                      name="standardLen"
                      className={'materialInstruction'}
                      // value={plusMinusAct}
                      disabled={materialInstruction === 3 ? false : true}
                    >
                      <Select.Option key={0} id={'standardLen-1'} value={''}>
                        {''}
                      </Select.Option>
                      {standardLen?.map((item, index) => (
                        <Select.Option key={index} id={'standardLen' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title ">
                    <label>採用単価</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={1}
                      id="unit"
                      name="unit"
                      className={'materialInstruction'}
                      value={materialIq5UnitType}
                      // onChange={(e) => {
                      //   if (materialInstruction != e) {
                      //     setMaterialInstruction(e);
                      //   }
                      // }}
                    >
                      {unitLst?.map((item, index) => (
                        <Select.Option key={index} id={'unit' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>単価</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {unitLst?.find((u) => u.key === detailData?.materailUnitType)?.label === 'M単価'
                        ? materialIq5Info?.info?.pricePerM
                          ? JPYs.format(materialIq5Info?.info?.pricePerM)
                          : JPYs.format(0)
                        : materialIq5Info?.info?.pricePerKg
                        ? JPYs.format(materialIq5Info?.info?.pricePerKg)
                        : JPYs.format(0)}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料費合計</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.materialCostTotalPrice
                        ? JPYs.format(detailData?.materialCostTotalPrice)
                        : JPYs.format(0)}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料費</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.materialCostTotalPrice
                        ? JPYs.format(detailData?.materialCostTotalPrice)
                        : JPYs.format(0)}
                    </label>
                  </td>
                </tr>
              </thead>
            </Table>
          </Col>
        </Row>
        <div>
          {/* 採用データ */}
          <Row style={{ width: '100%', marginTop: 20 }}>
            <Space size="middle">
              <label className="detail-iq5-tbl-val-title-label">採用データ</label>
              <RadioGroup
                name="dataType"
                defaultValue={0}
                className="radioCustomerGpEdit input-editable"
                onChange={(e) => setDataType(e.target.value)}
                value={dataType}
              >
                <Radio value={0}>自動</Radio>
                <Radio value={1}>手動</Radio>
              </RadioGroup>
              <div style={{ textAlign: 'left' }}>
                <Button
                  className={'iq5-dataType-btn'}
                  onClick={(e) => {
                    // onChange('1');
                  }}
                >
                  手動に複写
                </Button>
              </div>
            </Space>
          </Row>
          {/* 工程積算情報 */}
          <Row style={{ width: '100%', marginTop: 5 }}>
            <div style={{ width: '45%' }}>
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 工程タイトル */}
                  <tr>
                    <td
                      rowSpan={2}
                      style={{ width: '3%' }}
                      className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                    >
                      <a
                        onClick={(e) => {
                          props?.updateDetailProcessSelect(true);
                        }}
                        href="#"
                      >
                        <label className="detail-tbl-val-process-iq5">選択</label>
                      </a>
                    </td>
                    <td
                      rowSpan={2}
                      style={{ width: '5%' }}
                      className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                    >
                      <a
                        onClick={(e) => {
                          onChangeProcessDetail(WorkType.PaSteelCut);
                        }}
                      >
                        <label className="detail-tbl-val-process-iq5">工程</label>
                      </a>
                    </td>
                    <td colSpan={4} className="detail-tbl-val-title-iq5-bg-color">
                      <label className="detail-iq5-tbl-val-title-label">単価自動</label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '5%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取時間</label>
                    </td>
                    <td style={{ width: '8%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取金額</label>
                    </td>
                    <td style={{ width: '5%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工時間</label>
                    </td>
                    <td style={{ width: '8%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工金額</label>
                    </td>
                  </tr>
                  {/** 工程合計金額 */}
                  {(() => {
                    const arr = [];
                    for (let j = 0; j < props?.processMasterData?.length; j++) {
                      let curProcess = props?.processMasterData?.[j];
                      let curAddition = props?.selectedIQ5DataDetail?.additions?.filter(
                        (item) => item.processId == curProcess?.id
                      )?.[0];
                      let processSelect = props?.selectedIQ5DataDetail?.processSelect?.details?.filter(
                        (item) => item.processId == curProcess?.id && item.isUsedForCalc
                      );
                      arr.push(
                        <tr>
                          <td
                            style={{ width: '3%' }}
                            className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                          >
                            <Checkbox disabled={true} checked={processSelect?.length > 0 ? true : false} />
                          </td>
                          <td
                            style={{ width: '5%' }}
                            className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                          >
                            <a
                              onClick={(e) => {
                                if (processSelect?.length > 0) {
                                  onChangeProcessDetail(curProcess?.workType);
                                }
                              }}
                            >
                              <label className="detail-tbl-val-process-iq5">{curProcess?.name}</label>
                            </a>
                          </td>
                          <td style={{ width: '5%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label ">
                              {curAddition?.dataItems?.totalDandoriTime
                                ? secondsToHms(curAddition?.dataItems?.totalDandoriTime)
                                : secondsToHms(0)}
                            </label>
                          </td>
                          <td style={{ width: '8%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {curAddition?.dataItems?.totalDandoriAmt
                                ? JPYs.format(curAddition?.dataItems?.totalDandoriAmt)
                                : JPYs.format(0)}
                            </label>
                          </td>
                          <td style={{ width: '5%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {curAddition?.dataItems?.totalSagyoTime
                                ? secondsToHms(curAddition?.dataItems?.totalSagyoTime)
                                : secondsToHms(0)}
                            </label>
                          </td>
                          <td style={{ width: '8%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {curAddition?.dataItems?.totalSagyoAmt
                                ? JPYs.format(curAddition?.dataItems?.totalSagyoAmt)
                                : JPYs.format(0)}
                            </label>
                          </td>
                        </tr>
                      );
                    }
                    return arr;
                  })()}
                </thead>
              </Table>
            </div>
            <div style={{ width: '36%' }} className="iq5-detail-tbl-space">
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 工程タイトル */}
                  <tr>
                    <td colSpan={4} style={{ width: '8%' }} className="detail-tbl-val-title-iq5-bg-color ">
                      <label className="detail-iq5-tbl-val-title-label">単価手動</label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '6%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取時間</label>
                    </td>
                    <td style={{ width: '10%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取金額</label>
                    </td>
                    <td style={{ width: '6%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工時間</label>
                    </td>
                    <td style={{ width: '10%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工金額</label>
                    </td>
                  </tr>
                  {/** 工程合計金額 */}
                  {(() => {
                    const arr = [];
                    for (let j = 0; j < props?.processMasterData?.length; j++) {
                      let curProcess = props?.processMasterData?.[j];
                      let curAddition = props?.selectedIQ5DataDetail?.additions?.filter(
                        (item) => item.processId == curProcess?.id
                      )?.[0];
                      arr.push(
                        <tr>
                          <td style={{ width: '6%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <Input
                              style={{ padding: 3 }}
                              className={'detail-tbl-val-title-iq5-input detail-iq5-tbl-val-title-label'}
                              name={j + 'setupTm'}
                              id={j + 'setupTm'}
                              value={
                                curAddition?.editItems?.totalDandoriTime
                                  ? secondsToHms(curAddition?.editItems?.totalDandoriTime)
                                  : secondsToHms(0)
                              }
                              type="text"
                            />
                          </td>
                          <td style={{ width: '10%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <CurrencyInput
                              id={j + 'setupAmt'}
                              value={
                                curAddition?.editItems?.totalDandoriAmt ? curAddition?.editItems?.totalDandoriAmt : 0
                              }
                              defaultValue={0}
                              // onValueChange={(e) => {
                              //   if (editedData[i]?.sagyoAmt != e) {
                              //     settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                              //     checkInputWithColor(false, '', i + 'nijikakouSagyoAmt', e, 'amt');
                              //   }
                              // }}
                              style={{ marginLeft: 1 }}
                              className={'input-editable chargeAmount_currency'}
                              prefix="¥"
                              decimalsLimit={100}
                            />
                          </td>
                          <td style={{ width: '6%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <Input
                              style={{ padding: 3 }}
                              className={'detail-tbl-val-title-iq5-input detail-iq5-tbl-val-title-label'}
                              name={j + 'setupTm'}
                              id={j + 'setupTm'}
                              value={
                                curAddition?.editItems?.totalSagyoTime
                                  ? secondsToHms(curAddition?.editItems?.totalSagyoTime)
                                  : secondsToHms(0)
                              }
                              type="text"
                            />
                          </td>
                          <td style={{ width: '10%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <CurrencyInput
                              id={j + 'processingAmt'}
                              value={curAddition?.editItems?.totalSagyoAmt ? curAddition?.editItems?.totalSagyoAmt : 0}
                              defaultValue={0}
                              // onValueChange={(e) => {
                              //   if (editedData[i]?.sagyoAmt != e) {
                              //     settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                              //     checkInputWithColor(false, '', i + 'nijikakouSagyoAmt', e, 'amt');
                              //   }
                              // }}
                              style={{ marginLeft: 1 }}
                              className={'input-editable chargeAmount_currency'}
                              prefix="¥"
                              decimalsLimit={100}
                            />
                          </td>
                        </tr>
                      );
                    }
                    return arr;
                  })()}
                </thead>
              </Table>
            </div>
            <div style={{ width: '18.5%' }} className="iq5-detail-tbl-space">
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 工程タイトル */}
                  <tr>
                    <td colSpan={2} className="detail-tbl-val-title-iq5-bg-color"></td>
                  </tr>
                  <tr>
                    <td style={{ width: '12%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>単価</label>
                    </td>
                    <td style={{ width: '12%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>合計金額</label>
                    </td>
                  </tr>
                  {/** 単価・合計金額 */}
                  {(() => {
                    const arr = [];
                    for (let j = 0; j < props?.processMasterData?.length; j++) {
                      let curProcess = props?.processMasterData?.[j];
                      let curAddition = props?.selectedIQ5DataDetail?.additions?.filter(
                        (item) => item.processId == curProcess?.id
                      )?.[0];
                      let totalAmt = dataType === 0 ? curAddition?.totalDataPrice : curAddition?.totalEditPrice;
                      let unitAmt =
                        totalAmt /
                        (props?.selectedIQ5DataDetail?.partCount *
                          props?.selectedEstimateData?.estimateProducts?.quantity);
                      unitAmt = Number.isFinite(unitAmt) ? unitAmt : 0;
                      arr.push(
                        <tr>
                          <td style={{ width: '12%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label ">
                              {unitAmt ? JPYs.format(unitAmt) : JPYs.format(0)}
                            </label>
                          </td>
                          <td style={{ width: '12%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {totalAmt ? JPYs.format(totalAmt) : JPYs.format(0)}
                            </label>
                          </td>
                        </tr>
                      );
                    }
                    return arr;
                  })()}
                </thead>
              </Table>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
});

export default IQ5EstimateDetail;
