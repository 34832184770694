/**
 * クラス名：板金子部品見積材料詳細
 * 説明：板板金子部品見積材料詳細ファイルである。
 * 作成者：ケイ
 * 作成日：2023/06/29
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState, useRef } from 'react';
import { Table, Row, Col, Select, Button, Input, Popover, Image } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table as RTable } from 'react-bootstrap';
import {
  RightCircleFilled,
  LeftCircleFilled,
  CaretUpOutlined,
  CaretDownOutlined,
  UpCircleFilled,
  DownCircleOutlined,
} from '@ant-design/icons';

import bottomToTop from '../../assets/images/bottomToTop.png';
import rotateY from '../../assets/images/rotateY.png';
import leftToRight from '../../assets/images/leftToRight.png';
import rotateX from '../../assets/images/rotateX.png';
import leftRightFit from '../../assets/images/leftRightFit.png';
import topBottomFit from '../../assets/images/topBottomFit.png';
import {
  JPYs,
  Percent,
  draw,
  freeDraw,
  getDisplayMaterialSizeIq3,
  getInitFreeMaterialSizeInfo,
  getInitMaterialPatternsInfo,
  getInitMaterialSizeInfo,
  getMaterialAutoSize,
  getMaterialDetailPatternName,
  getMaterialNameById,
  getMaterialSizeData,
  getMaterialSurfaceById,
  getMaterialTypeById,
  handleKeyPress,
  isDecimal,
  isDigit,
  isDigitZenkaku,
  isInfinity,
  reCalMaterial,
  regexExp,
  toCheckZenkaku,
  toDecimal,
  toHalfWidth,
  scaleSet,
} from '../common/Common';
import {
  commonModal,
  commonFooter,
  confirmModalTitle,
  confirmUpdateContent,
  confirmDiscardContent,
} from '../common/CommonModal';
import IQ3MaterialDetail_SheetDetailPattern from './IQ3MaterialDetail_SheetDetailPattern';
import { MaterialSurfaceBillingUnit, PatternType } from '../common/enums';

const IQ3MaterialDetail = forwardRef((props, ref) => {
  let zoom = 1;
  const [editMode, setEditMode] = useState(false);
  const [detailData, setDetailData] = useState();
  const [tempDetailData, setTempDetailData] = useState();
  const [materialDetailSizeData, setMaterialDetailSizeData] = useState([]);
  const [materialDetailPatternData, setMaterialDetailPatternData] = useState([]);
  const [activePattern, setActivePattern] = useState(PatternType.Pattern1);
  // 情報項目
  const [partName, setPartName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [partCount, setPartCount] = useState('');
  const [xSize, setXSize] = useState('');
  const [ySize, setYSize] = useState('');
  const [materialId, setMaterialId] = useState(0);
  const [materialTypeId, setMaterialTypeId] = useState(0);
  const [thickness, setThickness] = useState('');
  const [surfaceId, setSurfaceId] = useState(0);
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [xGrabAllowance, setXGrabAllowance] = useState('');
  const [yGrabAllowance, setYGrabAllowance] = useState('');
  const [xWidthOfPier, setXWidthOfPier] = useState('');
  const [yWidthOfPier, setYWidthOfPier] = useState('');
  const [xMachiningRange, setXMachiningRange] = useState('');
  const [yMachiningRange, setYMachiningRange] = useState('');
  // 計算結果項目
  const [summarizeTotalAmount, setSummarizeTotalAmount] = useState('');
  const [summarizeYield, setSummarizeYield] = useState('');
  const [summarizeNumOfMaterial, setSummarizeNumOfMaterial] = useState('');
  const [summarizePrice, setSummarizePrice] = useState('');
  const [summarizeSurface, setSummarizeSurface] = useState('');
  const [summarizeWeight, setSummarizeWeight] = useState('');
  // パターン1項目
  const [pattern1TotalAmount, setPattern1TotalAmount] = useState(0);
  const [pattern1Yield, setPattern1Yield] = useState(0);
  const [pattern1NumOfMaterial, setPattern1NumOfMaterial] = useState(0);
  const [pattern1XSize, setPattern1XSize] = useState(0);
  const [pattern1YSize, setPattern1YSize] = useState(0);
  const [pattern1MaterialSizeId, setPattern1MaterialSizeId] = useState(0);
  // パターン2項目
  const [pattern2TotalAmount, setPattern2TotalAmount] = useState(0);
  const [pattern2Yield, setPattern2Yield] = useState(0);
  const [pattern2NumOfMaterial, setPattern2NumOfMaterial] = useState(0);
  const [pattern2XSize, setPattern2XSize] = useState(0);
  const [pattern2YSize, setPattern2YSize] = useState(0);
  const [pattern2MaterialSizeId, setPattern2MaterialSizeId] = useState(0);
  const [selectedRowNo, setSelectedRowNo] = useState();
  const [selectedMaterialXsize, setSelectedMaterialXsize] = useState();
  const [selectedMaterialYsize, setSelectedMaterialYsize] = useState();
  const [isPatternDetail, setIsPatternDetail] = useState(false);
  const [childTitle, setChildTitle] = useState('');
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isCalResult, setIsCalResult] = useState(true);
  const [isPattern1, setIsPattern1] = useState(true);
  const [isPattern1Img, setIsPattern1Img] = useState(false);
  const [isPattern2, setIsPattern2] = useState(true);
  const [isPattern2Img, setIsPattern2Img] = useState(false);
  const [materialOtherSize, setMaterialOtherSize] = useState(false);
  // パターン1詳細項目
  const [detailP1TotalAmount, setDetailP1TotalAmount] = useState(0);
  const [detailP1Yield, setDetailP1Yield] = useState(0);
  const [detailP1NumOfMaterial, setDetailP1NumOfMaterial] = useState(0);
  const [detailP1XSize, setDetailP1XSize] = useState(0);
  const [detailP1YSize, setDetailP1YSize] = useState(0);
  const [detailP1MaterialSizeId, setDetailP1MaterialSizeId] = useState(0);
  // パターン2詳細項目
  const [detailP2TotalAmount, setDetailP2TotalAmount] = useState(0);
  const [detailP2Yield, setDetailP2Yield] = useState(0);
  const [detailP2NumOfMaterial, setDetailP2NumOfMaterial] = useState(0);
  const [detailP2XSize, setDetailP2XSize] = useState(0);
  const [detailP2YSize, setDetailP2YSize] = useState(0);
  const [detailP2MaterialSizeId, setDetailP2MaterialSizeId] = useState(0);
  // 詳細項目
  const [detailMaterialId, setDetailMaterialId] = useState(0);
  const [detailMaterialTypeId, setDetailMaterialTypeId] = useState(0);
  const [detailThickness, setDetailThickness] = useState('');
  const [detailSurfaceId, setDetailSurfaceId] = useState(0);
  const [detailXGrabAllowance, setDetailXGrabAllowance] = useState('');
  const [detailYGrabAllowance, setDetailYGrabAllowance] = useState('');
  const [detailXWidthOfPier, setDetailXWidthOfPier] = useState('');
  const [detailYWidthOfPier, setDetailYWidthOfPier] = useState('');
  // パターン計算パーツ情報
  const [patternPartInfo, setPatternPartInfo] = useState([]);
  // パターン計算材料情報
  const [patternMaterialInfo, setPatternMaterialInfo] = useState([]);
  // 見積もり情報
  const [oldSelectedEstimateData, setOldSelectedEstimateData] = useState();
  const [p1YSetting, setP1YSetting] = useState(true);
  const [p2YSetting, setP2YSetting] = useState(true);
  const [yRotate, setYRotate] = useState(false);
  const [p1RightFit, setP1RightFit] = useState(false);
  const [p2RightFit, setP2RightFit] = useState(false);
  const [p1TopFit, setP1TopFit] = useState(false);
  const [p2TopFit, setP2TopFit] = useState(false);
  const [minXsize, setMinXsize] = useState(0);
  const [minYsize, setMinYsize] = useState(0);
  const [detailXSize, setDetailXSize] = useState(0);
  const [detailYSize, setDetailYSize] = useState(0);

  const maxXYSize = 99999.99;
  const ZOOM_SPEED = 0.1;
  const sheetDetailRef = useRef();
  /** 配置情報 */
  // Scale
  let ca1Scale = 1;
  let toDrawInfo = {
    partInfo: undefined,
    settingInfo: undefined,
    materialInfo: undefined,
  };
  // 材料マスタ情報
  let materialListData = props?.materialLst?.filter((i) => i?.info?.isUsed);
  // 材質マスタ情報
  let materialTypeListData = props?.materialTypeLst?.filter((i) => i?.info?.isUsed);
  // 材料表面保護マスタ情報
  let materialSurfaceListData = props?.materialSurfaceLst?.filter((i) => i?.info?.isUsed);
  // 材料サイズ
  let materialSizeData = getMaterialSizeData(props?.selectedEstimateData); // 材料サイズ情報;

  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCal || props?.iq3ActionForCal) {
      const selectedEstimateData = props.selectedEstimateData;
      if (selectedEstimateData === undefined || selectedEstimateData?.length === 0) return;
      setOldSelectedEstimateData(JSON.parse(JSON.stringify(selectedEstimateData)));

      // 板金子部品全部の材料を再計算
      if (props?.isReCalMaterial === reCalMaterial.allRecordCal) {
        selectedEstimateData?.esIq3Info?.map((iq3Item) => {
          calculateMaterialDetailSize(iq3Item);
          let sizeLists = iq3Item?.materialDetailSizes;
          let usedMaterialSizeInfo = sizeLists?.find((item) => item.isUsed); // 採用されるサイズの情報
          if (usedMaterialSizeInfo === undefined)
            usedMaterialSizeInfo = sizeLists?.find((item) => item?.sizeName === 'Other'); // Otherサイズを採用する
          updateMaterialSizeUsedFlg(sizeLists, usedMaterialSizeInfo?.materialSizeIq3Id, iq3Item?.no);
        });
      }

      const childPartDetail = getCurrentSelectedIq3Info(selectedEstimateData, props?.currentRecordNo);
      if (childPartDetail === undefined) return;

      setEditMode(props.editMode != undefined ? props.editMode : false);

      // 初期又は材料情報に変更された、タイミングのみ再計算する
      if (
        props?.isReCalMaterial === reCalMaterial.oneRecordCal || // 選択中の板金子部品のみ材料を再計算
        childPartDetail?.materialDetailPatterns?.length === 0 ||
        props?.isFirstTimeMaterialDetail
      ) {
        props?.loading(true);
        let calculatedIq3Data = calculateMaterialDetailSize({ ...childPartDetail }); // 材料リストの計算
        let materialDetailSizeLists = calculatedIq3Data?.materialDetailSizes;
        let materialDetailPatternLists = calculatedIq3Data?.materialDetailPatterns;
        setDetailData({ ...calculatedIq3Data });
        // setTempDetailData({ ...calculatedIq3Data });
        setMaterialDetailInfo(calculatedIq3Data); // 材料詳細情報
        setMaterialDetailPatternData(materialDetailPatternLists); // 材料詳細パターン情報
        let selectedRecord = {};
        // 一番合計歩留まりが良い材料サイズ情報
        let maxYieldRecord = {};
        if (props?.isReCalMaterial === reCalMaterial.oneRecordCal && props?.activeTabKey !== '6') {
          // 材料詳細タブは開くまではOtherで最小の材料サイズを採用
          selectedRecord = materialDetailSizeLists?.find((item) => item?.sizeName === 'Other');
          if (props?.isReCalMaterial === reCalMaterial.oneRecordCal) props?.updateFirstTimeMaterialDetail(true);
        } else {
          if (materialDetailSizeLists?.length > 0) {
            maxYieldRecord = getMaxYieldRecord(materialDetailSizeLists);
            selectedRecord = maxYieldRecord;
          }
        }
        const rowInfo = selectedRecord?.no
          ? selectedRecord
          : materialDetailSizeLists?.length > 0
          ? materialDetailSizeLists?.[0]
          : 0;
        setSelectedRowNo(rowInfo?.materialSizeIq3Id);
        calMaterialXYSize(rowInfo?.materialSizeIq3Id);
        displayMaterialDetailInfo(rowInfo, materialDetailPatternLists);
        updateMaterialSizeUsedFlg(materialDetailSizeLists, rowInfo?.materialSizeIq3Id);
        let orderedMaterialDetailSizes = reOrderMaterialSizeNo(materialDetailSizeLists);
        setMaterialDetailSizeData(orderedMaterialDetailSizes); // 材料リスト
        if (props?.isFirstTimeMaterialDetail === true) props?.updateFirstTimeMaterialDetail(false);
        props.loading(false);
      } else {
        // 再計算しないモードの場合、
        let iq3Data = { ...childPartDetail }; // 材料リストの計算
        let materialDetailSizeLists = iq3Data?.materialDetailSizes;
        let materialDetailPatternLists = iq3Data?.materialDetailPatterns;
        setDetailData({ ...iq3Data });
        // setTempDetailData({ ...iq3Data });
        setMaterialDetailInfo(iq3Data); // 材料詳細情報
        setMaterialDetailSizeData(materialDetailSizeLists); // 材料リスト
        setMaterialDetailPatternData(materialDetailPatternLists); // 材料詳細パターン情報
        let selectedRow = materialDetailSizeLists?.find((item) => item?.isUsed);
        setSelectedRowNo(selectedRow?.materialSizeIq3Id);
        calMaterialXYSize(selectedRow?.materialSizeIq3Id);
        displayMaterialDetailInfo(selectedRow, materialDetailPatternLists);
        updateMaterialSizeUsedFlg(materialDetailSizeLists, selectedRow?.materialSizeIq3Id);
      }
    }
  }, [props?.isReCalMaterial, props?.tabOpen, props?.dialogOk, props?.iq3ActionForCal, props?.isReCal]);

  // 計算結果の下部にあるイメージ表示（IQX_WEBEST-362 材料取りパターンのイメージが正しくない。）
  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCal || props?.iq3ActionForCal) {
      let materialSizeId = selectedRowNo;
      let tempDetailData = JSON.parse(JSON.stringify({ ...detailData }));
      let selectedMaterialSize = tempDetailData?.materialDetailSizes?.find(
        (item) => item.materialSizeIq3Id === materialSizeId
      );
      let pattern1Info = getPatternInfo(tempDetailData?.materialDetailPatterns, materialSizeId, PatternType.Pattern1);
      let pattern2Info = getPatternInfo(tempDetailData?.materialDetailPatterns, materialSizeId, PatternType.Pattern2);
      // パターン１情報設定
      settingCalPatternInfo(
        PatternType.Pattern1,
        materialSizeId,
        pattern1Info?.sizeX,
        pattern1Info?.sizeY,
        selectedMaterialSize?.yRotate,
        tempDetailData?.pierWidthX,
        tempDetailData?.pierWidthY,
        tempDetailData?.grabbingX,
        tempDetailData?.grabbingY,
        false
      );
      if (selectedMaterialSize?.sizeName === 'Free') {
        toDrawInfo = {
          ...toDrawInfo,
          materialInfo: {
            p1MSheetX: pattern1Info?.sizeX,
            p1MSheetY: pattern1Info?.sizeY,
            p2MSheetX: pattern2Info?.sizeX,
            p2MSheetY: pattern2Info?.sizeY,
          },
          settingInfo: {
            mClampdzX: Number(tempDetailData?.grabbingX),
            mClampdzY: Number(tempDetailData?.grabbingY),
            mMarginX: Number(tempDetailData?.pierWidthX),
            mMarginY: Number(tempDetailData?.pierWidthY),
          },
        };
      }
      // パターン１イメージ書く
      sheetDetailImgDraw(
        toDrawInfo,
        false,
        pattern1Info?.ySetting,
        pattern1Info?.rightFit,
        pattern1Info?.topFit,
        selectedMaterialSize?.sizeName === 'Free',
        pattern2Info?.totalPrice > 0 ? true : false
      );
      // パターン２情報設定
      settingCalPatternInfo(
        PatternType.Pattern2,
        materialSizeId,
        pattern2Info?.sizeX,
        pattern2Info?.sizeY,
        selectedMaterialSize?.yRotate,
        tempDetailData?.pierWidthX,
        tempDetailData?.pierWidthY,
        tempDetailData?.grabbingX,
        tempDetailData?.grabbingY,
        false
      );
      if (selectedMaterialSize?.sizeName === 'Free') {
        toDrawInfo = {
          ...toDrawInfo,
          materialInfo: {
            p1MSheetX: pattern1Info?.sizeX,
            p1MSheetY: pattern1Info?.sizeY,
            p2MSheetX: pattern2Info?.sizeX,
            p2MSheetY: pattern2Info?.sizeY,
          },
          settingInfo: {
            mClampdzX: Number(tempDetailData?.grabbingX),
            mClampdzY: Number(tempDetailData?.grabbingY),
            mMarginX: Number(tempDetailData?.pierWidthX),
            mMarginY: Number(tempDetailData?.pierWidthY),
          },
        };
      }
      // パターン２イメージ書く
      sheetDetailImgDraw(
        toDrawInfo,
        true,
        pattern2Info?.ySetting,
        pattern2Info?.rightFit,
        pattern2Info?.topFit,
        selectedMaterialSize?.sizeName === 'Free',
        pattern2Info?.totalPrice > 0 ? true : false
      );
    }
  }, [
    props?.isReCalMaterial,
    detailData?.materialTypeIq3Id,
    detailData?.materialIq3Id,
    detailData?.thickness,
    detailData?.materialSurfaceIq3Id,
    detailData?.grabbingY,
    detailData?.grabbingX,
    detailData?.pierWidthX,
    detailData?.pierWidthY,
    selectedRowNo,
  ]);

  // 材質、材料名称、板厚、表面保護、掴み代X、掴み代Y、桟幅X、桟幅Yを変更した場合、
  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCal || props?.iq3ActionForCal) {
      // if (!isPatternDetail || detailData === undefined) return;
      if (props?.activeTabKey !== '6' || detailData === undefined) return;
      let calculatedIq3Data = calculateMaterialDetailSize(detailData); // 材料リストの計算
      setMaterialDetailSizeData(calculatedIq3Data?.materialDetailSizes); // 材料リスト
      setMaterialDetailPatternData(calculatedIq3Data?.materialDetailPatterns); // 材料詳細パターン情報
      let selectedSizeData = calculatedIq3Data?.materialDetailSizes?.find(
        (item) => item.materialSizeIq3Id === selectedRowNo
      );
      // 採用するサイズが指定してない場合、一番合計歩留まりが良い材料サイズを採用する
      if (selectedSizeData === undefined) {
        selectedSizeData = getMaxYieldRecord(calculatedIq3Data?.materialDetailSizes);
        setSelectedRowNo(selectedSizeData?.materialSizeIq3Id);
        calMaterialXYSize(selectedSizeData?.materialSizeIq3Id);
        updateMaterialSizeUsedFlg(calculatedIq3Data?.materialDetailSizes, selectedSizeData?.materialSizeIq3Id);
      }
      displayMaterialDetailInfo(selectedSizeData, calculatedIq3Data?.materialDetailPatterns);
    }
  }, [
    detailData?.materialTypeIq3Id,
    detailData?.materialIq3Id,
    detailData?.thickness,
    detailData?.materialSurfaceIq3Id,
    detailData?.grabbingY,
    detailData?.grabbingX,
    detailData?.pierWidthX,
    detailData?.pierWidthY,
  ]);

  // パータン１と２詳細画面を切り替え、パータン詳細のボタンを押下した場合、
  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCal || props?.iq3ActionForCal) {
      let materialSizeId = activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId;
      let selectedMaterialSize = tempDetailData?.materialDetailSizes?.find(
        (item) => item.materialSizeIq3Id === materialSizeId
      );

      let patternInfo = getPatternInfo(tempDetailData?.materialDetailPatterns, materialSizeId, activePattern);

      settingCalPatternInfo(
        activePattern,
        materialSizeId,
        patternInfo?.sizeX,
        patternInfo?.sizeY,
        selectedMaterialSize?.yRotate,
        detailXWidthOfPier,
        detailYWidthOfPier,
        detailXGrabAllowance,
        detailYGrabAllowance,
        true
      );

      if (selectedMaterialSize?.sizeName === 'Free') {
        toDrawInfo = {
          ...toDrawInfo,
          materialInfo: {
            p1MSheetX: detailP1XSize,
            p1MSheetY: detailP1YSize,
            p2MSheetX: detailP2XSize,
            p2MSheetY: detailP2YSize,
          },
        };
      }

      sheetDetailRef?.current?.sheetDetailDraw(
        toDrawInfo,
        activePattern === PatternType.Pattern2 ? true : false,
        patternInfo?.ySetting,
        patternInfo?.rightFit,
        patternInfo?.topFit,
        selectedMaterialSize?.sizeName === 'Free',
        activePattern === PatternType.Pattern2 && patternInfo?.totalPrice > 0 ? true : false
      );
    }
  }, [isPatternDetail, activePattern]);

  // 詳細画面の回転を押下した場合、
  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCal || props?.iq3ActionForCal) {
      isChangeMaterialDetails(true); // 材料詳細情報を変更した場合、
    }
  }, [yRotate]);

  // 詳細画面の材質、材料名称、板厚、表面保護、掴み代X、掴み代Y、桟幅X、桟幅Y、ySettingを変更した場合、
  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCal || props?.iq3ActionForCal) {
      isChangeMaterialDetails(false); // 材料詳細情報を変更した場合、
    }
  }, [
    tempDetailData?.materialTypeIq3Id,
    tempDetailData?.materialIq3Id,
    tempDetailData?.thickness,
    tempDetailData?.materialSurfaceIq3Id,
    tempDetailData?.grabbingY,
    tempDetailData?.grabbingX,
    tempDetailData?.pierWidthX,
    tempDetailData?.pierWidthY,
    tempDetailData?.materialDetailPatterns,
    p1YSetting,
    p2YSetting,
    p1RightFit,
    p1TopFit,
    p2RightFit,
    p2TopFit,
    detailXSize,
    detailYSize,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      getSelectedEstimateData: () => {
        return oldSelectedEstimateData;
      },
      getIQ3MaterialDetailInfo: () => {
        return detailData;
      },
      getQuantity: () => {
        return quantity;
      },
    }),
    [detailData]
  );

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 50 + 'px',
      // render: (id, record, index) => index + 1,
    },
    {
      id: '2',
      title: '材料サイズ',
      dataIndex: 'materialSizeIq3Id',
      width: 100 + 'px',
      className: 'cm-a-center',
      render: (value) =>
        value === getMaterialSizeFreeId() ? 'Free' : materialSizeData?.filter((item) => item.id === value)?.[0]?.name,
    },
    {
      id: '3',
      title: '材料枚数',
      dataIndex: 'materialQuantity',
      width: 100 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '4',
      title: '金額',
      dataIndex: 'price',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => JPYs.format(value),
    },
    {
      id: '5',
      title: '歩留まり',
      dataIndex: 'yield',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => Percent.format(value / 100),
    },
    {
      id: '6',
      title: 'パターン1',
      dataIndex: 'pattern1Rate',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : Percent.format(value / 100)),
    },
    {
      id: '7',
      title: '配置数',
      dataIndex: 'pattern1Quantity',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : value),
    },
    {
      id: '8',
      title: 'パターン2',
      dataIndex: 'pattern2Rate',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : Percent.format(value / 100)),
    },
    {
      id: '9',
      title: '配置数',
      dataIndex: 'pattern2Quantity',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : value),
    },
    {
      id: '10',
      title: '最大歩留まり',
      dataIndex: 'maxYield',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => Percent.format(value / 100),
    },
    {
      id: '11',
      title: '最大加工数',
      dataIndex: 'maxQuantity',
      width: 100 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '12',
      title: '',
      dataIndex: 'no',
      hidden: true,
    },
  ]?.filter((item) => !item.hidden);

  // 材料詳細情報を変更した場合、
  const isChangeMaterialDetails = (isDetailRotate) => {
    if (tempDetailData === undefined) return;
    let calculatedIq3Data = calculateMaterialDetailSize(tempDetailData, true, isDetailRotate); // 材料リストの計算
    let selectedSizeData = calculatedIq3Data?.materialDetailSizes?.find(
      (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
    );
    displayMaterialDetailInfo(selectedSizeData, calculatedIq3Data?.materialDetailPatterns, true);
    // setMinXYSizeInfo(calculatedIq3Data, selectedSizeData);
  };

  // 情報の値
  const setMaterialDetailInfo = (selectedIq3Info) => {
    setPartName(selectedIq3Info?.name);
    setQuantity(getParentQuantity(props?.selectedEstimateData));
    setPartCount(selectedIq3Info?.partCount);
    setXSize(Number(selectedIq3Info?.partSizeX));
    setYSize(Number(selectedIq3Info?.partSizeY));
    setMaterialId(selectedIq3Info?.materialIq3Id);
    setMaterialTypeId(selectedIq3Info?.materialTypeIq3Id);
    setThickness(selectedIq3Info?.thickness);
    setSurfaceId(selectedIq3Info?.materialSurfaceIq3Id);
    setWeight(toDecimal(selectedIq3Info?.weight, 2));
    setSurfaceArea(toDecimal(selectedIq3Info?.totalSurfaceArea, 2));
    getCalculateInfo(selectedIq3Info);
  };

  // 計算に利用する情報
  const getCalculateInfo = (selectedIq3Info) => {
    // 掴み代X、掴み代Y（ブランクの場合、装置から材料配置情報を取得する。）
    setXGrabAllowance(toDecimal(selectedIq3Info?.grabbingX));
    setYGrabAllowance(toDecimal(selectedIq3Info?.grabbingY));
    // 桟幅X、桟幅Y（ブランクの場合、装置から材料配置情報を取得する。）
    setXWidthOfPier(toDecimal(selectedIq3Info?.pierWidthX));
    setYWidthOfPier(toDecimal(selectedIq3Info?.pierWidthY));
    // 加工範囲X、加工範囲Y（ブランクの場合、装置から材料配置情報を取得する。）
    setXMachiningRange(selectedIq3Info?.worlRangeX);
    setYMachiningRange(selectedIq3Info?.worlRangeY);

    // 最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
    setMinXsize(
      Number(selectedIq3Info?.partSizeX) +
        toDecimal(selectedIq3Info?.grabbingX) +
        toDecimal(selectedIq3Info?.pierWidthX)
    );
    // 最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
    setMinYsize(
      Number(selectedIq3Info?.partSizeY) +
        toDecimal(selectedIq3Info?.grabbingY) +
        toDecimal(selectedIq3Info?.pierWidthY)
    );
  };

  // 計算結果の値
  const setSummarizeInfo = (totalInfo) => {
    // setSummarizeTotalAmount(JPYs.format(totalInfo.price));
    let totalPrice = totalInfo.price;
    setSummarizeTotalAmount(totalPrice);
    setSummarizeYield(Percent.format(totalInfo.yield / 100));
    setSummarizeNumOfMaterial(totalInfo.materialQuantity);

    // 材料パラメータ情報
    let materialInfo = materialListData?.find((item) => item.id === materialId);
    setSummarizePrice(JPYs.format(materialInfo ? materialInfo.info.unitPrice : 0));

    // 材料表面パラメータ情報
    let surfaceInfo = materialSurfaceListData?.find((item) => item.id === surfaceId);
    if (surfaceInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
      setSummarizeSurface(JPYs.format(surfaceInfo ? surfaceInfo.info.price : 0));
      setSummarizeWeight(JPYs.format(0));
    } else {
      setSummarizeSurface(JPYs.format(0));
      setSummarizeWeight(JPYs.format(surfaceInfo ? surfaceInfo.info.price : 0));
    }

    let selectedIQ3DataDetail = getCurrentSelectedIq3Info(props?.selectedEstimateData, props?.currentRecordNo);
    let unitPrice =
      totalInfo?.price /
      (Number(selectedIQ3DataDetail?.partCount) * Number(getParentQuantity(props?.selectedEstimateData))); //　材料原価/員数＊数量
  };

  // パターン1の値
  const setPattern1Info = (pattern1Info, isDetail = false) => {
    if (pattern1Info === undefined) return;
    if (isDetail) {
      setDetailP1TotalAmount(JPYs.format(Number.isFinite(pattern1Info?.totalPrice) ? pattern1Info?.totalPrice : 0));
      setDetailP1Yield(Percent.format(pattern1Info?.yield / 100));
      setDetailP1NumOfMaterial(Number.isInteger(pattern1Info?.materialQuantity) ? pattern1Info?.materialQuantity : 0);
      setDetailP1MaterialSizeId(pattern1Info?.materialSizeIq3Id);
      setDetailP1XSize(pattern1Info?.sizeX ? Math.floor(pattern1Info?.sizeX) : 0);
      setDetailP1YSize(pattern1Info?.sizeY ? Math.floor(pattern1Info?.sizeY) : 0);
      // 材料詳細の画像設定情報を取得する
      setP1YSetting(pattern1Info?.ySetting);
      setP1RightFit(pattern1Info?.rightFit);
      setP1TopFit(pattern1Info?.topFit);
    } else {
      setPattern1TotalAmount(JPYs.format(Number.isFinite(pattern1Info?.totalPrice) ? pattern1Info?.totalPrice : 0));
      setPattern1Yield(Percent.format(pattern1Info?.yield / 100));
      setPattern1NumOfMaterial(Number.isInteger(pattern1Info?.materialQuantity) ? pattern1Info?.materialQuantity : 0);
      setPattern1MaterialSizeId(pattern1Info?.materialSizeIq3Id);
      setPattern1XSize(pattern1Info?.sizeX ? Math.floor(pattern1Info?.sizeX) : 0);
      setPattern1YSize(pattern1Info?.sizeY ? Math.floor(pattern1Info?.sizeY) : 0);
    }
  };

  // パターン2の値
  const setPattern2Info = (pattern2Info, isDetail = false) => {
    if (pattern2Info === undefined) return;
    if (isDetail) {
      setDetailP2TotalAmount(JPYs.format(Number.isFinite(pattern2Info?.totalPrice) ? pattern2Info?.totalPrice : 0));
      setDetailP2Yield(Percent.format(pattern2Info?.yield / 100));
      setDetailP2NumOfMaterial(Number.isInteger(pattern2Info?.materialQuantity) ? pattern2Info?.materialQuantity : 0);
      setDetailP2MaterialSizeId(pattern2Info?.materialSizeIq3Id);
      setDetailP2XSize(pattern2Info?.sizeX ? Math.floor(pattern2Info?.sizeX) : 0);
      setDetailP2YSize(pattern2Info?.sizeY ? Math.floor(pattern2Info?.sizeY) : 0);
      // 材料詳細の画像設定情報を取得する
      setP2YSetting(pattern2Info?.ySetting);
      setP2RightFit(pattern2Info?.rightFit);
      setP2TopFit(pattern2Info?.topFit);
    } else {
      setPattern2TotalAmount(JPYs.format(Number.isFinite(pattern2Info?.totalPrice) ? pattern2Info?.totalPrice : 0));
      setPattern2Yield(Percent.format(pattern2Info?.yield / 100));
      setPattern2NumOfMaterial(Number.isInteger(pattern2Info?.materialQuantity) ? pattern2Info?.materialQuantity : 0);
      setPattern2MaterialSizeId(pattern2Info?.materialSizeIq3Id);
      setPattern2XSize(pattern2Info?.sizeX ? Math.floor(pattern2Info?.sizeX) : 0);
      setPattern2YSize(pattern2Info?.sizeY ? Math.floor(pattern2Info?.sizeY) : 0);
    }
  };

  // ヘッダーの値
  const setDetailHeaderInfo = () => {
    setDetailMaterialTypeId(materialTypeId);
    setDetailMaterialId(materialId);
    setDetailThickness(thickness);
    setDetailSurfaceId(surfaceId);
    setDetailXGrabAllowance(xGrabAllowance);
    setDetailYGrabAllowance(yGrabAllowance);
    setDetailXWidthOfPier(xWidthOfPier);
    setDetailYWidthOfPier(yWidthOfPier);
  };

  // パターン1の詳細情報
  const callPattern1Detail = () => {
    setTempDetailData(JSON.parse(JSON.stringify({ ...detailData })));
    setIsPatternDetail(true);
    setChildTitle('詳細');
    setActivePattern(PatternType.Pattern1);
    let selectedMaterialSize = materialDetailSizeData?.find(
      (item) =>
        item.materialSizeIq3Id ===
        (activePattern === PatternType.Pattern1 ? pattern1MaterialSizeId : pattern2MaterialSizeId)
    );

    setDetailHeaderInfo();
    let pattern1Info = getPatternInfo(materialDetailPatternData, pattern1MaterialSizeId, PatternType.Pattern1);
    setPattern1Info(pattern1Info, true);
    setPattern2Info(getPatternInfo(materialDetailPatternData, pattern2MaterialSizeId, PatternType.Pattern2), true);
    // 選択中材料のXサイズ
    setSelectedMaterialXsize(pattern1Info?.sizeX);
    // 選択中材料のYサイズ
    setSelectedMaterialYsize(pattern1Info?.sizeY);

    // 材料詳細の画像設定情報を取得する
    setYRotate(selectedMaterialSize?.yRotate);
  };

  // パターン2の詳細情報
  const callPattern2Detail = () => {
    setTempDetailData(JSON.parse(JSON.stringify({ ...detailData })));
    setIsPatternDetail(true);
    setChildTitle('詳細');
    setActivePattern(PatternType.Pattern2);
    let selectedMaterialSize = materialDetailSizeData?.find(
      (item) =>
        item.materialSizeIq3Id ===
        (activePattern === PatternType.Pattern1 ? pattern1MaterialSizeId : pattern2MaterialSizeId)
    );

    setDetailHeaderInfo();
    let pattern1Info = getPatternInfo(materialDetailPatternData, pattern1MaterialSizeId, PatternType.Pattern1);
    setPattern1Info(pattern1Info, true);
    let pattern2Info = getPatternInfo(materialDetailPatternData, pattern2MaterialSizeId, PatternType.Pattern2);
    setPattern2Info(pattern2Info, true);
    // 選択中材料のXサイズ
    setSelectedMaterialXsize(pattern2Info?.sizeX);
    // 選択中材料のYサイズ
    setSelectedMaterialYsize(pattern2Info?.sizeY);

    // 材料詳細の画像設定情報を取得する
    setYRotate(selectedMaterialSize?.yRotate);
  };

  // 材料リストの計算
  const calculateMaterialDetailSize = (selectedIq3Info, isDetail = false, isDetailRotate = false) => {
    let materialPatternLists = [];
    let materialDetailSizes = [];
    let materialDetailPatterns = [];
    if (isDetail && !isDetailRotate) {
      materialDetailSizes = selectedIq3Info?.materialDetailSizes?.filter(
        (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
      );
      materialDetailPatterns = selectedIq3Info?.materialDetailPatterns?.filter(
        (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
      );
    } else {
      materialDetailSizes = selectedIq3Info?.materialDetailSizes;
      materialDetailPatterns = selectedIq3Info?.materialDetailPatterns;
    }
    // freeの場合、
    if (materialDetailSizes?.length === 0 && detailP1MaterialSizeId === getMaterialSizeFreeId()) {
      let initFreeSizeInfo = JSON.parse(JSON.stringify(getInitFreeMaterialSizeInfo()));
      materialDetailSizes?.push({
        ...initFreeSizeInfo,
        yRotate: yRotate,
      });
      let freeMaterialDetailPatterns = [];
      materialDetailPatterns = getInitMaterialPatternsInfo(freeMaterialDetailPatterns, materialDetailSizes);
      materialDetailPatterns = materialDetailPatterns?.map((pItem) => {
        return {
          ...pItem,
          materialSizeIq3Id: initFreeSizeInfo?.materialSizeIq3Id,
          sizeName: initFreeSizeInfo?.sizeName,
          sizeX: pItem?.patternType === PatternType.Pattern1 ? detailP1XSize : detailP2XSize,
          sizeY: pItem?.patternType === PatternType.Pattern1 ? detailP1YSize : detailP2YSize,
          ySetting: pItem?.patternType === PatternType.Pattern1 ? p1YSetting : p2YSetting,
          topFit: pItem?.patternType === PatternType.Pattern1 ? p1TopFit : p2TopFit,
          rightFit: pItem?.patternType === PatternType.Pattern1 ? p1RightFit : p2RightFit,
        };
      });
    }
    let p1MaterialYSize = 0;
    let p1MaterialXSize = 0;
    let p2MaterialYSize = 0;
    let p2MaterialXSize = 0;
    let isExistPattern2 = true;
    materialDetailPatterns?.map((patternInfo) => {
      let info = { ...patternInfo };
      let isFree = info.sizeName === 'Free' ? true : false;
      let xSize = 0;
      let ySize = 0;
      let xPart = 0;
      let yPart = 0;
      let totalNoOfMaterial = 0;
      let materialPrice = 0; // 合計金額
      let pattern1Price = 0; // パターン１金額
      let pattern2Price = 0; // パターン２金額
      let yiedlRate = 0;
      let pattern1Rate = 0;
      let pattern2Rate = 0;

      let totalPlacement = 0; // 合計配置数
      let pattern1Placement = 0; // パターン１配置数
      let pattern2Placement = 0; // パターン２配置数
      let pattern1NoOfMaterial = 0; // パターン１枚数
      let pattern2NoOfMaterial = 0; // パターン２枚数

      // 最大歩留まり
      let maxYieldRate = 0;
      // 最大加工数
      let maxNoOfPlace = 0;

      // 材料サイズ情報
      let sizeInfo = materialDetailSizes?.find((item) => item.materialSizeIq3Id === info.materialSizeIq3Id);

      // 材料パラメータ情報
      let materialInfo = materialListData?.find((item) => item.id === selectedIq3Info.materialIq3Id);

      let freeMaterialX = '';
      let freeMaterialY = '';

      freeMaterialX =
        info.patternType === PatternType.Pattern1 ? detailP1XSize : isExistPattern2 ? detailP2XSize : detailP1XSize;
      freeMaterialY =
        info.patternType === PatternType.Pattern1 ? detailP1YSize : isExistPattern2 ? detailP2YSize : detailP1YSize;

      if (info?.patternType === PatternType.Pattern1) {
        p1MaterialYSize = 0;
        p1MaterialXSize = 0;
        p2MaterialYSize = 0;
        p2MaterialXSize = 0;
      }

      // 材料枚数の計算
      if (isDetail) {
        settingCalPatternInfo(
          info.patternType,
          info.materialSizeIq3Id,
          isFree ? freeMaterialX : '',
          isFree ? freeMaterialY : '',
          sizeInfo?.yRotate,
          detailXWidthOfPier,
          detailYWidthOfPier,
          detailXGrabAllowance,
          detailYGrabAllowance,
          isDetail
        );
      } else {
        let freeSizeInfo = materialDetailPatterns?.find(
          (item) => item.materialSizeIq3Id === info.materialSizeIq3Id && item.patternType === info?.patternType
        );
        let freeSizeX = freeSizeInfo?.sizeX ? freeSizeInfo?.sizeX : 0;
        let freeSizeY = freeSizeInfo?.sizeY ? freeSizeInfo?.sizeY : 0;
        freeMaterialX = freeSizeX;
        freeMaterialY = freeSizeY;
        settingCalPatternInfo(
          info.patternType,
          info.materialSizeIq3Id,
          isFree ? freeSizeX : '',
          isFree ? freeSizeY : '',
          sizeInfo?.yRotate,
          selectedIq3Info?.pierWidthX,
          selectedIq3Info?.pierWidthY,
          selectedIq3Info?.grabbingX,
          selectedIq3Info?.grabbingY,
          isDetail,
          selectedIq3Info.no
        );
        toDrawInfo.settingInfo = {
          mClampdzX: Number(selectedIq3Info?.pierWidthX),
          mClampdzY: Number(selectedIq3Info?.pierWidthY),
          mMarginX: Number(selectedIq3Info?.grabbingX),
          mMarginY: Number(selectedIq3Info?.grabbingY),
        };
      }
      xSize = isFree ? Number(freeMaterialX) : Number(toDrawInfo?.materialInfo?.mSheetX);
      ySize = isFree ? Number(freeMaterialY) : Number(toDrawInfo?.materialInfo?.mSheetY);
      xPart = Number(toDrawInfo?.partInfo?.mPartX);
      yPart = Number(toDrawInfo?.partInfo?.mPartY);

      let partCntInfo = undefined;
      if (isFree) {
        partCntInfo = freeDraw(
          toDrawInfo?.partInfo,
          toDrawInfo?.settingInfo,
          isDetail
            ? { p1MSheetX: detailP1XSize, p1MSheetY: detailP1YSize, p2MSheetX: detailP2XSize, p2MSheetY: detailP2YSize }
            : {
                p1MSheetX: info?.patternType === PatternType.Pattern2 ? p1MaterialXSize : xSize,
                p1MSheetY: info?.patternType === PatternType.Pattern2 ? p1MaterialYSize : ySize,
                p2MSheetX: info?.patternType === PatternType.Pattern2 ? xSize : p2MaterialXSize,
                p2MSheetY: info?.patternType === PatternType.Pattern2 ? ySize : p2MaterialYSize,
              },
          info?.patternType === PatternType.Pattern2 ? true : false,
          info?.ySetting,
          info?.rightFit,
          info?.topFit,
          '',
          isExistPattern2
        );
        if (
          partCntInfo &&
          (partCntInfo?.invalidMaterial || partCntInfo?.invalidXMaterial || partCntInfo?.invalidYMaterial)
        ) {
          if (partCntInfo?.invalidMaterial) {
            xSize = detailP1XSize;
            ySize = detailP1YSize;
          } else if (partCntInfo?.invalidXMaterial && (activePattern === info.patternType || !isPatternDetail)) {
            xSize = getMaterialAutoSize(selectedIq3Info, yRotate);
          } else if (partCntInfo?.invalidYMaterial && (activePattern === info.patternType || !isPatternDetail)) {
            ySize = getMaterialAutoSize(selectedIq3Info, yRotate, false);
          }
          // 材料・掴み代・部品矩形・桟幅の配置
          partCntInfo = freeDraw(
            toDrawInfo?.partInfo,
            toDrawInfo?.settingInfo,
            isDetail
              ? { p1MSheetX: xSize, p1MSheetY: ySize, p2MSheetX: xSize, p2MSheetY: ySize }
              : {
                  p1MSheetX: info?.patternType === PatternType.Pattern2 ? p1MaterialXSize : xSize,
                  p1MSheetY: info?.patternType === PatternType.Pattern2 ? p1MaterialYSize : ySize,
                  p2MSheetX: info?.patternType === PatternType.Pattern2 ? xSize : p2MaterialXSize,
                  p2MSheetY: info?.patternType === PatternType.Pattern2 ? ySize : p2MaterialYSize,
                },
            info?.patternType === PatternType.Pattern2 ? true : false,
            info?.ySetting,
            info?.rightFit,
            info?.topFit,
            '',
            isExistPattern2
          );
        }
      } else {
        // 材料・掴み代・部品矩形・桟幅の配置
        partCntInfo = draw(
          toDrawInfo?.partInfo,
          toDrawInfo?.settingInfo,
          toDrawInfo?.materialInfo,
          info?.patternType === PatternType.Pattern2 ? true : false,
          info?.ySetting,
          info?.rightFit,
          info?.topFit,
          ''
        );
      }
      if (partCntInfo !== undefined) {
        if (info?.patternType === PatternType.Pattern1) {
          p1MaterialXSize = xSize;
          p1MaterialYSize = ySize;
        } else {
          p2MaterialXSize = xSize;
          p2MaterialYSize = ySize;
        }

        // Y方向をFixの場合、
        if (isDetail && info?.topFit) {
          // 製品の矩形Y
          let partY = toDrawInfo?.partInfo.mPartY + toDrawInfo?.settingInfo.mMarginY;
          if (info?.patternType === PatternType.Pattern1) {
            let p1YSize = partY * partCntInfo?.p1PartCntY + toDrawInfo?.settingInfo.mClampdzY;
            if (p1YSize > 0) p1MaterialYSize = p1YSize;
          } else {
            let p2YSize = partY * partCntInfo?.p2PartCntY + toDrawInfo?.settingInfo.mClampdzY;
            if (p2YSize > 0) p2MaterialYSize = p2YSize;
          }
        }
        // 右方向をFixの場合、
        if (isDetail && info?.rightFit) {
          // 製品の矩形X
          let partX = toDrawInfo?.partInfo.mPartX + toDrawInfo?.settingInfo.mMarginX;
          if (info?.patternType === PatternType.Pattern1) {
            let p1XSize = partX * partCntInfo?.p1PartCntX + toDrawInfo?.settingInfo.mClampdzX;
            if (p1XSize > 0) p1MaterialXSize = p1XSize;
          } else {
            let p2XSize = partX * partCntInfo?.p2PartCntX + toDrawInfo?.settingInfo.mClampdzX;
            if (p2XSize > 0) p2MaterialXSize = p2XSize;
          }
        }

        // 材料詳細の画像を書く
        if (
          isPatternDetail &&
          (info?.materialSizeIq3Id === detailP1MaterialSizeId || info?.materialSizeIq3Id === detailP2MaterialSizeId) &&
          info?.patternType === activePattern &&
          sheetDetailRef?.current
        ) {
          let sheetDrawInfo = { ...toDrawInfo };
          if (isFree) {
            sheetDrawInfo = {
              ...sheetDrawInfo,
              materialInfo: {
                p1MSheetX: p1MaterialXSize,
                p1MSheetY: p1MaterialYSize,
                p2MSheetX: p2MaterialXSize,
                p2MSheetY: p2MaterialYSize,
              },
            };
          }
          sheetDetailRef?.current?.sheetDetailDraw(
            sheetDrawInfo,
            info?.patternType === PatternType.Pattern2 ? true : false,
            info?.ySetting,
            info?.rightFit,
            info?.topFit,
            isFree,
            isExistPattern2
          );
        }

        // 最大加工数
        maxNoOfPlace = partCntInfo.maxNoOfPlace < 0 ? 0 : partCntInfo.maxNoOfPlace;

        // 最大数
        let maxPlacement = Number(selectedIq3Info.partCount * getParentQuantity(props?.selectedEstimateData)); //　員数＊数量

        // パターン１枚数
        pattern1NoOfMaterial = isInfinity(partCntInfo.p1NoOfMaterial) ? 0 : partCntInfo.p1NoOfMaterial;
        // パターン１配置数
        pattern1Placement = partCntInfo.p1NoOfPlace * pattern1NoOfMaterial;

        isExistPattern2 = true;
        if (maxPlacement == pattern1Placement * pattern1NoOfMaterial && maxPlacement == pattern1NoOfMaterial) {
          isExistPattern2 = false;
        } else if (maxPlacement <= pattern1Placement * pattern1NoOfMaterial && pattern1NoOfMaterial == 1) {
          isExistPattern2 = false;
        } else if (maxPlacement == pattern1Placement) {
          isExistPattern2 = false;
        } else if (isInfinity(pattern1NoOfMaterial)) {
          isExistPattern2 = false;
        }

        if (isExistPattern2) {
          pattern2NoOfMaterial = info.sizeName === 'Other' ? 0 : 1;
          totalNoOfMaterial = pattern1NoOfMaterial + pattern2NoOfMaterial;
        } // パターン1の材料枚数＋パターン2の材料枚数
        else {
          totalNoOfMaterial = pattern1NoOfMaterial;
          pattern2NoOfMaterial = 0;
        }

        // 材料費の計算=材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000*重量単価(¥/kg)*材料枚数
        pattern1Price =
          p1MaterialXSize *
          p1MaterialYSize *
          materialInfo?.info?.thick *
          (materialInfo?.info?.gravity / 1000000) *
          materialInfo?.info?.unitPrice *
          pattern1NoOfMaterial;

        // IQX_WEBEST-356 材料代に表面保護が含まれていない
        let surfaceProtectionId = isDetail ? detailSurfaceId : selectedIq3Info?.materialSurfaceIq3Id;
        let surfaceProtectionInfo = materialSurfaceListData?.find((item) => item.id === surfaceProtectionId);
        if (surfaceProtectionInfo) {
          let p1MaterialSize = p1MaterialXSize * p1MaterialYSize; // 材料X(mm)*材料Y(mm)
          if (surfaceProtectionInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
            // 加算方法が平米の場合
            let p1MaterialXSizeM = Number.isFinite(p1MaterialXSize / 1000) ? p1MaterialXSize / 1000 : 0; // 材料X(mm)をｍに変換
            let p1MaterialYSizeM = Number.isFinite(p1MaterialYSize / 1000) ? p1MaterialYSize / 1000 : 0; // 材料Y(mm)をｍに変換
            // 材料X(m)*材料Y(m)
            p1MaterialSize = p1MaterialXSizeM * p1MaterialYSizeM;
          } else {
            // 加算方法が重量の場合
            //材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000
            p1MaterialSize = p1MaterialSize * materialInfo?.info?.thick * (materialInfo?.info?.gravity / 1000000);
          }
          // 材料費*加算金額*材料枚数
          let p1SurfaceProtectionAddAmt = 0;
          if (isInfinity(pattern1NoOfMaterial)) {
            p1SurfaceProtectionAddAmt = p1MaterialSize * surfaceProtectionInfo?.info?.price * 0;
          } else {
            p1SurfaceProtectionAddAmt = p1MaterialSize * surfaceProtectionInfo?.info?.price * pattern1NoOfMaterial;
          }
          // 材料費に表面保護の金額を加算
          if (isInfinity(pattern1Price)) {
            pattern1Price = 0;
          }
          pattern1Price += p1SurfaceProtectionAddAmt;
          pattern1Price = pattern1Price ? pattern1Price : 0;
        }

        // 材料費の計算=材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000*重量単価(¥/kg)*材料枚数
        pattern2Price =
          p2MaterialXSize *
          p2MaterialYSize *
          materialInfo?.info?.thick *
          (materialInfo?.info?.gravity / 1000000) *
          materialInfo?.info?.unitPrice *
          pattern2NoOfMaterial;

        // IQX_WEBEST-356 材料代に表面保護が含まれていない
        if (surfaceProtectionInfo) {
          let p2MaterialSize = p2MaterialXSize * p2MaterialYSize; // 材料X(mm)*材料Y(mm)
          if (surfaceProtectionInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
            // 加算方法が平米の場合
            let p2MaterialXSizeM = Number.isFinite(p2MaterialXSize / 1000) ? p2MaterialXSize / 1000 : 0; // 材料X(mm)をｍに変換
            let p2MaterialYSizeM = Number.isFinite(p2MaterialYSize / 1000) ? p2MaterialYSize / 1000 : 0; // 材料Y(mm)をｍに変換
            // 材料X(m)*材料Y(m)
            p2MaterialSize = p2MaterialXSizeM * p2MaterialYSizeM;
          } else {
            // 加算方法が重量の場合
            //材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000
            p2MaterialSize = p2MaterialSize * materialInfo?.info?.thick * (materialInfo?.info?.gravity / 1000000);
          }
          // 材料費*加算金額*材料枚数
          let p2SurfaceProtectionAddAmt = 0;
          if (isInfinity(pattern1NoOfMaterial)) {
            p2SurfaceProtectionAddAmt = p2MaterialSize * surfaceProtectionInfo?.info?.price * 0;
          } else {
            p2SurfaceProtectionAddAmt = p2MaterialSize * surfaceProtectionInfo?.info?.price * pattern2NoOfMaterial;
          }
          // 材料費に表面保護の金額を加算
          if (isInfinity(pattern2Price)) {
            pattern2Price = 0;
          }
          pattern2Price += p2SurfaceProtectionAddAmt;
          pattern2Price = pattern2Price ? pattern2Price : 0;
        }

        // 合計金額
        materialPrice = pattern1Price + pattern2Price;

        if (isExistPattern2) {
          pattern2Placement = info.sizeName === 'Other' ? 0 : partCntInfo.p2NoOfPlace;
        }
        totalPlacement = pattern1Placement + pattern2Placement; // パターン1の配置数＋パターン2の配置数

        // Otherの材料サイズの計算
        if (info.sizeName === 'Other') {
          // 歩留の計算=矩形X*矩形Y/材料X*材料Y*100
          yiedlRate = ((xPart * yPart) / (p1MaterialXSize * p1MaterialYSize)) * 100;
          pattern1Rate = yiedlRate;
          maxYieldRate = yiedlRate;
          pattern2Rate = 0;
        } else {
          // 材料X*材料Y*材料枚数
          let p1MaterialSize = p1MaterialXSize * p1MaterialYSize * pattern1NoOfMaterial;
          let p2MaterialSize = p2MaterialXSize * p2MaterialYSize * pattern2NoOfMaterial;
          // 矩形X*矩形Y*配置数/材料X*材料Y*材料枚数*100
          yiedlRate = ((xPart * yPart * totalPlacement) / (p1MaterialSize + p2MaterialSize)) * 100;
          pattern1Rate = ((xPart * yPart * pattern1Placement) / p1MaterialSize) * 100;
          if (isExistPattern2) {
            pattern2Rate = ((xPart * yPart * pattern2Placement) / p2MaterialSize) * 100;
          } else {
            pattern2Rate = 0;
          }
          maxYieldRate = ((xPart * yPart * maxNoOfPlace) / (xSize * ySize * totalNoOfMaterial)) * 100;
        }
      }

      if (sizeInfo) {
        sizeInfo.materialQuantity = Number.isInteger(totalNoOfMaterial) ? totalNoOfMaterial : 0;
        sizeInfo.price = Number.isFinite(materialPrice) ? materialPrice : 0;
        sizeInfo.yield = yiedlRate && !isInfinity(yiedlRate) ? yiedlRate : 0;
        sizeInfo.pattern1Rate = pattern1Rate && !isInfinity(pattern1Rate) ? pattern1Rate : 0;
        sizeInfo.pattern1Quantity = pattern1Placement && !isInfinity(pattern1Placement) ? pattern1Placement : 0;
        sizeInfo.pattern2Rate = pattern2Rate && !isInfinity(pattern2Rate) ? pattern2Rate : 0;
        sizeInfo.pattern2Quantity = pattern2Placement && !isInfinity(pattern2Placement) ? pattern2Placement : 0;
        sizeInfo.maxYield = maxYieldRate && !isInfinity(maxYieldRate) ? maxYieldRate : 0;
        sizeInfo.maxQuantity = maxNoOfPlace;
        if (sizeInfo?.yRotate === undefined) sizeInfo['yRotate'] = false;
      }

      // 材料詳細パターン情報
      let isPattern1 = info.patternType === PatternType.Pattern1 ? true : false;
      let materialQuantity = isPattern1 ? pattern1NoOfMaterial : pattern2NoOfMaterial;
      let totalPrice = isPattern1 ? pattern1Price : pattern2Price;
      let rate = isPattern1 ? pattern1Rate : pattern2Rate;
      materialPatternLists?.push({
        patternType: info.patternType,
        totalPrice: totalPrice && !isInfinity(totalPrice) ? totalPrice : 0,
        yield: rate && !isInfinity(rate) ? rate : 0,
        materialQuantity: materialQuantity && !isInfinity(materialQuantity) ? materialQuantity : 0,
        materialSizeIq3Id: info?.materialSizeIq3Id,
        sizeName: info?.sizeName,
        sizeX: info?.rightFit ? (isPattern1 ? p1MaterialXSize : p2MaterialXSize) : xSize,
        sizeY: info?.topFit ? (isPattern1 ? p1MaterialYSize : p2MaterialYSize) : ySize,
        ySetting: info?.ySetting,
        rightFit: info?.rightFit,
        topFit: info?.topFit,
      });
    });

    // 材料サイズリスト情報
    materialDetailSizes?.map((item) => {
      let index = selectedIq3Info.materialDetailSizes?.findIndex(
        (list) => list.materialSizeIq3Id === item.materialSizeIq3Id
      );
      if (index > -1) {
        selectedIq3Info.materialDetailSizes[index] = item;
      } else {
        // Freeサイズの場合、サイズ一覧の先頭行に追加する
        const index = 0;
        selectedIq3Info.materialDetailSizes = [
          ...selectedIq3Info.materialDetailSizes.slice(0, index),
          item,
          ...selectedIq3Info.materialDetailSizes.slice(index),
        ];
      }
    });

    // 材料詳細パターン情報
    materialPatternLists?.map((item) => {
      let index = selectedIq3Info.materialDetailPatterns?.findIndex(
        (list) => list.patternType === item.patternType && list.materialSizeIq3Id === item.materialSizeIq3Id
      );
      if (index > -1) {
        selectedIq3Info.materialDetailPatterns[index] = item;
      } else {
        // Freeサイズの場合、パターン詳細情報に追加する
        selectedIq3Info.materialDetailPatterns.push(item);
      }
    });

    return selectedIq3Info;
  };

  const settingCalPatternInfo = (
    activePatternInfo,
    selectedMaterialSizeId,
    materialX,
    materialY,
    yRotate,
    mMarginX,
    mMarginY,
    mClampdzX,
    mClampdzY,
    isDetail = false,
    currentRecordNo = props?.currentRecordNo
  ) => {
    /** 部品情報 */
    let partInfo = {};
    // if (mPartInfo) {
    let currentIq3Info = getCurrentSelectedIq3Info(props?.selectedEstimateData, currentRecordNo);
    if (currentIq3Info) {
      let mPartInfo = currentIq3Info;
      partInfo = {
        mPartX: yRotate ? Number(mPartInfo.partSizeY) : Number(mPartInfo.partSizeX),
        mPartY: yRotate ? Number(mPartInfo.partSizeX) : Number(mPartInfo.partSizeY),
        partCnt: Number(mPartInfo.partCount),
        suryou: Number(getParentQuantity(props?.selectedEstimateData)),
        totalProcessSuryou: Number(mPartInfo.partCount * getParentQuantity(props?.selectedEstimateData)), //　員数＊数量
      };
      setPatternPartInfo(partInfo);
      setXSize(partInfo.mPartX);
      setYSize(partInfo.mPartY);
      if (isDetail) {
        setMinXsize(partInfo.mPartX + Number(detailXGrabAllowance) + Number(detailXWidthOfPier));
        setMinYsize(partInfo.mPartY + Number(detailYGrabAllowance) + Number(detailYWidthOfPier));
      } else {
        setMinXsize(partInfo.mPartX + Number(xGrabAllowance) + Number(xWidthOfPier));
        setMinYsize(partInfo.mPartY + Number(yGrabAllowance) + Number(yWidthOfPier));
      }
    }

    /** 材料情報 */
    let materialInfo = {};
    let sheetX = 0;
    let sheetY = 0;
    if (materialX && materialY) {
      sheetX = materialX;
      sheetY = materialY;
    } else {
      let selectedMaterialSize = selectedMaterialSizeId;
      let materialSizes = materialSizeData ? materialSizeData?.filter((item) => item.id === selectedMaterialSize) : [];
      if (materialSizes?.length > 0) {
        //材料サイズをOtherに変更した場合、部品矩形サイズ＋桟幅＋掴み代で決定
        if (materialSizes[0]?.sizeX == 0 || materialSizes[0]?.sizeY == 0 || materialSizes[0]?.name === 'Other') {
          setMaterialOtherSize(true);
          // /材料サイズX計算
          sheetX = Number(partInfo.mPartX) + Number(mMarginX) + Number(mClampdzX);
          // /材料サイズY計算
          sheetY = Number(partInfo.mPartY) + Number(mMarginY) + Number(mClampdzY);
        } else if (materialSizes[0]?.name === 'Free') {
          //Freeに変更前のサイズを設定
          // もし、変更前のサイズがＯｔｈｅｒの場合、部品矩形サイズ＋桟幅＋掴み代で決定
          if (materialOtherSize) {
            // /材料サイズX計算
            sheetX = Number(partInfo.mPartX) + Number(mMarginX) + Number(mClampdzX);
            // /材料サイズY計算
            sheetY = Number(partInfo.mPartY) + Number(mMarginY) + Number(mClampdzY);
          } else {
            sheetX = patternMaterialInfo.mSheetX;
            sheetY = patternMaterialInfo.mSheetY;
          }
        } else {
          setMaterialOtherSize(false);
          sheetX = Number(materialSizes[0]?.sizeX);
          sheetY = Number(materialSizes[0]?.sizeY);
        }
      }
    }
    materialInfo = {
      mSheetX: sheetX ? toDecimal(sheetX, 2) : 0,
      mSheetY: sheetY ? toDecimal(sheetY, 2) : 0,
    };
    setPatternMaterialInfo(materialInfo);

    if (activePatternInfo == PatternType.Pattern1) {
      if (isDetail) {
        setDetailP1MaterialSizeId(selectedMaterialSizeId);
        setDetailP1XSize(materialInfo.mSheetX ? Math.floor(materialInfo.mSheetX) : 0);
        setDetailP1YSize(materialInfo.mSheetY ? Math.floor(materialInfo.mSheetY) : 0);
      } else {
        setPattern1MaterialSizeId(selectedMaterialSizeId);
        setPattern1XSize(materialInfo.mSheetX ? Math.floor(materialInfo.mSheetX) : 0);
        setPattern1YSize(materialInfo.mSheetY ? Math.floor(materialInfo.mSheetY) : 0);
      }
    } else {
      if (isDetail) {
        setDetailP2MaterialSizeId(selectedMaterialSizeId);
        setDetailP2XSize(materialInfo.mSheetX ? Math.floor(materialInfo.mSheetX) : 0);
        setDetailP2YSize(materialInfo.mSheetY ? Math.floor(materialInfo.mSheetY) : 0);
      } else {
        setPattern2MaterialSizeId(selectedMaterialSizeId);
        setPattern2XSize(materialInfo.mSheetX ? Math.floor(materialInfo.mSheetX) : 0);
        setPattern2YSize(materialInfo.mSheetY ? Math.floor(materialInfo.mSheetY) : 0);
      }
    }

    toDrawInfo.partInfo = partInfo;
    if (isDetail) {
      toDrawInfo.settingInfo = {
        mClampdzX: Number(detailXGrabAllowance),
        mClampdzY: Number(detailYGrabAllowance),
        mMarginX: Number(detailXWidthOfPier),
        mMarginY: Number(detailYWidthOfPier),
      };
    } else {
      toDrawInfo.settingInfo = {
        mClampdzX: Number(xGrabAllowance),
        mClampdzY: Number(yGrabAllowance),
        mMarginX: Number(xWidthOfPier),
        mMarginY: Number(yWidthOfPier),
      };
    }
    toDrawInfo.materialInfo = materialInfo;
  };

  // idで名称を取得する
  const getNameById = (id, dataList) => {
    if (id && dataList) {
      let data = dataList?.filter((item) => item.id === id);
      return data?.length > 0 ? data[0]?.name : '';
    }
    return '';
  };

  // パターン詳細情報を更新
  const patternUpdateOK = () => {
    setUpdateConfirm(true);
  };

  // パターン詳細情報をキャンセル
  const patternUpdateCancel = (e) => {
    setCancelConfirm(true);
  };

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isPatternDetail) {
      setUpdateConfirm(false);
      setIsPatternDetail(false);
      setDetailData(tempDetailData);
      setMaterialDetailInfo(tempDetailData); // 材料詳細情報
      setMaterialDetailPatternData(tempDetailData?.materialDetailPatterns); // 材料詳細パターン情報
      let selectedRow = tempDetailData?.materialDetailSizes?.find(
        (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
      );
      setSelectedRowNo(selectedRow?.materialSizeIq3Id);
      calMaterialXYSize(selectedRow.materialSizeIq3Id);
      displayMaterialDetailInfo(selectedRow, tempDetailData?.materialDetailPatterns);
      updateMaterialSizeUsedFlg(tempDetailData?.materialDetailSizes, selectedRow?.materialSizeIq3Id);
      let orderedMaterialDetailSizes = reOrderMaterialSizeNo(tempDetailData?.materialDetailSizes);
      setMaterialDetailSizeData(orderedMaterialDetailSizes); // 材料リスト
      updateSelectedEstimateData(tempDetailData);
    }
  };

  //　材料サイズ一覧の順番を並び替え
  const reOrderMaterialSizeNo = (materialDetailSizes) => {
    if (materialDetailSizes === undefined) return materialDetailSizes;
    materialDetailSizes.sort((a, b) => a.materialSizeIq3Id - b.materialSizeIq3Id);
    materialDetailSizes
      ?.filter((item) => item.sizeName !== 'Free')
      ?.map((item, index) => {
        item.no = index + 1;
      });
    return materialDetailSizes;
  };

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isPatternDetail) {
      setIsPatternDetail(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // パターン詳細情報の切り替え
  const changePattern = () => {
    if (activePattern === PatternType.Pattern1) {
      setActivePattern(PatternType.Pattern2);
      if (p2TopFit || p2RightFit) {
        setMinXsize(detailP2XSize);
        setMinYsize(detailP2YSize);
      }
    } else {
      setActivePattern(PatternType.Pattern1);
      if (p1TopFit || p1RightFit) {
        setMinXsize(detailP1XSize);
        setMinYsize(detailP1YSize);
      }
    }
  };

  // 計算結果パネル開く・閉じる制御
  const openCalResultPanel = () => {
    if (isCalResult == true) {
      setIsCalResult(false);
    } else {
      setIsCalResult(true);
    }
  };

  // パターン１パネル開く・閉じる制御
  const openPattern1Panel = () => {
    if (isPattern1 == true) {
      setIsPattern1(false);
    } else {
      setIsPattern1(true);
    }
  };

  const openPattern1ImgPanel = () => {
    if (isPattern1Img == true) {
      setIsPattern1Img(false);
    } else {
      setIsPattern1Img(true);
    }
  };

  // パターン２計算結果パネル開く・閉じる制御
  const openPattern2Panel = () => {
    if (isPattern2 == true) {
      setIsPattern2(false);
    } else {
      setIsPattern2(true);
    }
  };
  const openPattern2ImgPanel = () => {
    if (isPattern2Img == true) {
      setIsPattern2Img(false);
    } else {
      setIsPattern2Img(true);
    }
  };

  // 材質を変更した時、材料、板厚、表面保護の情報をフィルターする
  const onChangeMaterialType = (materialTypeId, isDetail = false) => {
    isDetail ? setDetailMaterialTypeId(materialTypeId) : setMaterialTypeId(materialTypeId);
    // 材料情報
    let materialList = materialListData?.filter(
      (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
    );
    let materialId = 0;
    let materialSurfaceId = 0;
    if (materialList?.length > 0) {
      materialId = materialList[0]?.id;
    }
    let materialName = getMaterialNameById(materialId, materialListData);
    if (isDetail) {
      setDetailThickness(materialId);
      setDetailMaterialId(materialId);
      tempDetailData['thickness'] = materialId;
      tempDetailData['materialIq3Id'] = materialId;
      tempDetailData['materialName'] = materialName;
    } else {
      setThickness(materialId); // 板厚
      setMaterialId(materialId);
      detailData['thickness'] = materialId;
      detailData['materialIq3Id'] = materialId;
      detailData['materialName'] = materialName;
      updateSelectedEstimateData(detailData);
    }

    // 材料表面情報
    let materialSurfaceList = materialSurfaceListData?.filter(
      (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
    );
    if (materialSurfaceList?.length > 0) {
      materialSurfaceId = materialSurfaceList[0]?.id;
    }
    let surfaceName = getMaterialSurfaceById(materialSurfaceId, materialSurfaceListData);
    if (isDetail) {
      setDetailSurfaceId(materialSurfaceId);
      tempDetailData['materialSurfaceIq3Id'] = materialSurfaceId;
      tempDetailData['materialSurfaceName'] = surfaceName;
      tempDetailData['materialDetailSizes'] = getMSizeListByMTypeIq3Id(materialTypeId);
      tempDetailData['materialDetailPatterns'] = getMPatternListByMTypeIq3Id(materialTypeId, isDetail);
    } else {
      setSurfaceId(materialSurfaceId);
      detailData['materialSurfaceIq3Id'] = materialSurfaceId;
      detailData['materialSurfaceName'] = surfaceName;
      detailData['materialDetailSizes'] = getMSizeListByMTypeIq3Id(materialTypeId);
      detailData['materialDetailPatterns'] = getMPatternListByMTypeIq3Id(materialTypeId, isDetail);
      updateSelectedEstimateData(detailData);
    }
  };

  // 変更した材質名称によって表示する材料サイズリストを得る
  const getMSizeListByMTypeIq3Id = (materialTypeIq3Id) => {
    let materialDetailSizes = [];
    let filteredMaterialSizeIq3 = getFilteredMaterialSizeIq3(materialTypeIq3Id);
    materialDetailSizes = filteredMaterialSizeIq3?.map((item, index) => getInitMaterialSizeInfo(index, item));
    return materialDetailSizes;
  };

  // 変更した材質名称によって表示する材料パターンリストを得る
  const getMPatternListByMTypeIq3Id = (materialTypeIq3Id, isDetail) => {
    let materialDetailPatterns = [];
    let filteredMaterialSizeIq3 = getFilteredMaterialSizeIq3(materialTypeIq3Id);
    filteredMaterialSizeIq3?.map((item, index) => {
      let copyItem = JSON.parse(JSON.stringify(item));
      if (item?.sizeName === 'Other') {
        // data = { ...data, isUsed: true };
        let iq3Info = isDetail ? tempDetailData : detailData;
        // 材料サイズX計算
        let sheetX = getMaterialAutoSize(iq3Info, false);
        // 材料サイズY計算
        let sheetY = getMaterialAutoSize(iq3Info, false, false);
        copyItem = { ...copyItem, info: { ...copyItem.info, sizeX: sheetX, sizeY: sheetY } };
      }
      materialDetailPatterns = getInitMaterialPatternsInfo(materialDetailPatterns, copyItem);
    });
    return materialDetailPatterns;
  };

  // 変更した材質名称によって表示する材料サイズマスタ情報を得る
  const getFilteredMaterialSizeIq3 = (materialTypeIq3Id) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    let materialSizeIq3Infos = parameters?.materialSizeIq3;
    let filteredMaterialSizeIq3 = getDisplayMaterialSizeIq3(materialTypeIq3Id, materialSizeIq3Infos);
    return filteredMaterialSizeIq3;
  };

  // 材料サイズFreeのIDを得る
  const getMaterialSizeFreeId = () => {
    // let id = materialSizeData?.find((item) => item.name === 'Free')?.id;
    // return id ? id : 0;
    return 0;
  };

  // 材料詳細の掴み代Xを変更した場合、
  const onChangeDetailXGrabAllow = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let xGrabAllowanceVal = Number(value) > selectedMaterialXsize ? Number(tempDetailData?.grabbingX) : Number(value);
      //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinXsize(xSize + Number(xGrabAllowanceVal) + Number(detailXWidthOfPier));
      if (isCheckZenkaku) xGrabAllowanceVal = toDecimal(xGrabAllowanceVal);
      setDetailXGrabAllowance(xGrabAllowanceVal);
      onUpdateTempDetail('grabbingX', xGrabAllowanceVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setDetailXGrabAllowance(value);
      let xGrabAllowanceVal = toHalfWidth(value);
      xGrabAllowanceVal =
        Number(xGrabAllowanceVal) > selectedMaterialXsize
          ? Number(tempDetailData?.grabbingX)
          : Number(xGrabAllowanceVal);
      onUpdateTempDetail('grabbingX', xGrabAllowanceVal);
    } else {
      setDetailXGrabAllowance(0);
      onUpdateTempDetail('grabbingX', 0);
    }
  };

  // 材料詳細の掴み代Yを変更した場合、
  const onChangeDetailYGrabAllow = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let yGrabAllowanceVal = Number(value) > selectedMaterialYsize ? Number(tempDetailData?.grabbingY) : Number(value);
      // 最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinYsize(Number(ySize) + Number(yGrabAllowanceVal) + Number(detailYWidthOfPier));
      if (isCheckZenkaku) yGrabAllowanceVal = toDecimal(yGrabAllowanceVal);
      setDetailYGrabAllowance(yGrabAllowanceVal);
      onUpdateTempDetail('grabbingY', yGrabAllowanceVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setDetailYGrabAllowance(value);
      let yGrabAllowanceVal = toHalfWidth(value);
      yGrabAllowanceVal =
        Number(yGrabAllowanceVal) > selectedMaterialYsize
          ? Number(tempDetailData?.grabbingY)
          : Number(yGrabAllowanceVal);
      onUpdateTempDetail('grabbingY', yGrabAllowanceVal);
    } else {
      setDetailYGrabAllowance(0);
      onUpdateTempDetail('grabbingY', 0);
    }
  };

  // 材料詳細の桟幅Xを変更した場合、
  const onChangeDetailXWidthOfPier = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let xWidthOfPierVal = Number(value) > selectedMaterialXsize ? Number(tempDetailData?.pierWidthX) : Number(value);
      //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinXsize(xSize + Number(detailXGrabAllowance) + Number(xWidthOfPierVal));
      if (isCheckZenkaku) xWidthOfPierVal = toDecimal(xWidthOfPierVal);
      setDetailXWidthOfPier(xWidthOfPierVal);
      onUpdateTempDetail('pierWidthX', xWidthOfPierVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setDetailXWidthOfPier(value);
      let xWidthOfPierVal = toHalfWidth(value);
      xWidthOfPierVal =
        Number(xWidthOfPierVal) > selectedMaterialXsize ? Number(tempDetailData?.pierWidthX) : Number(xWidthOfPierVal);
      onUpdateTempDetail('pierWidthX', xWidthOfPierVal);
    } else {
      setDetailXWidthOfPier(0);
      onUpdateTempDetail('pierWidthX', 0);
    }
  };

  // 材料詳細の桟幅Yを変更した場合、
  const onChangeDetailYWidthOfPier = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let yWidthOfPierVal = Number(value) > selectedMaterialYsize ? Number(tempDetailData?.pierWidthY) : Number(value);
      //　最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinYsize(Number(ySize) + Number(detailYGrabAllowance) + Number(yWidthOfPierVal));
      if (isCheckZenkaku) yWidthOfPierVal = toDecimal(yWidthOfPierVal);
      setDetailYWidthOfPier(yWidthOfPierVal);
      onUpdateTempDetail('pierWidthY', yWidthOfPierVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setDetailYWidthOfPier(value);
      let yWidthOfPierVal = toHalfWidth(value);
      yWidthOfPierVal =
        Number(yWidthOfPierVal) > selectedMaterialYsize ? Number(tempDetailData?.pierWidthY) : Number(yWidthOfPierVal);
      onUpdateTempDetail('pierWidthY', yWidthOfPierVal);
    } else {
      setDetailYWidthOfPier(0);
      onUpdateTempDetail('pierWidthY', 0);
    }
  };

  // 掴み代Xを変更した場合、
  const onChangeXGrabAllow = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let xGrabAllowanceVal = Number(value) > selectedMaterialXsize ? Number(detailData?.grabbingX) : Number(value);
      //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinXsize(xSize + Number(xGrabAllowanceVal) + Number(xWidthOfPier));
      if (isCheckZenkaku) xGrabAllowanceVal = toDecimal(xGrabAllowanceVal);
      setXGrabAllowance(xGrabAllowanceVal);
      onUpdateDetail('grabbingX', xGrabAllowanceVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setXGrabAllowance(value);
      let xGrabAllowanceVal = toHalfWidth(value);
      xGrabAllowanceVal =
        Number(xGrabAllowanceVal) > selectedMaterialXsize ? Number(detailData?.grabbingX) : Number(xGrabAllowanceVal);
      onUpdateDetail('grabbingX', xGrabAllowanceVal);
    } else {
      setXGrabAllowance(0);
      onUpdateDetail('grabbingX', 0);
    }
  };

  // 掴み代Yを変更した場合、
  const onChangeYGrabAllow = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let yGrabAllowanceVal = Number(value) > selectedMaterialYsize ? Number(detailData?.grabbingY) : Number(value);
      // 最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinYsize(Number(ySize) + Number(yGrabAllowanceVal) + Number(yWidthOfPier));
      if (isCheckZenkaku) yGrabAllowanceVal = toDecimal(yGrabAllowanceVal);
      setYGrabAllowance(yGrabAllowanceVal);
      onUpdateDetail('grabbingY', yGrabAllowanceVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setYGrabAllowance(value);
      let yGrabAllowanceVal = toHalfWidth(value);
      yGrabAllowanceVal =
        Number(yGrabAllowanceVal) > selectedMaterialYsize ? Number(detailData?.grabbingY) : Number(yGrabAllowanceVal);
      onUpdateDetail('grabbingY', yGrabAllowanceVal);
    } else {
      setYGrabAllowance(0);
      onUpdateDetail('grabbingY', 0);
    }
  };

  // 桟幅Xを変更した場合、
  const onChangeXWidthOfPier = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let xWidthOfPierVal = Number(value) > selectedMaterialXsize ? Number(detailData?.pierWidthX) : Number(value);
      //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinXsize(xSize + Number(xGrabAllowance) + xWidthOfPierVal);
      if (isCheckZenkaku) xWidthOfPierVal = toDecimal(xWidthOfPierVal);
      setXWidthOfPier(xWidthOfPierVal);
      onUpdateDetail('pierWidthX', xWidthOfPierVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setXWidthOfPier(value);
      let xWidthOfPierVal = toHalfWidth(value);
      xWidthOfPierVal =
        Number(xWidthOfPierVal) > selectedMaterialXsize ? Number(detailData?.pierWidthX) : Number(xWidthOfPierVal);
      onUpdateDetail('pierWidthX', xWidthOfPierVal);
    } else {
      setXWidthOfPier(0);
      onUpdateDetail('pierWidthX', 0);
    }
  };

  // 桟幅Yを変更した場合、
  const onChangeYWidthOfPier = (value, isCheckZenkaku = true) => {
    if (!isCheckZenkaku && value?.length === 0) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      value = toCheckZenkaku(value);
    }
    if (value && !regexExp.test(value) && (isDecimal(value) || isDigit(value))) {
      let yWidthOfPierVal = Number(value) > selectedMaterialYsize ? Number(detailData?.pierWidthY) : Number(value);
      //　最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
      setMinYsize(Number(ySize) + Number(yGrabAllowance) + yWidthOfPierVal);
      if (isCheckZenkaku) yWidthOfPierVal = toDecimal(yWidthOfPierVal);
      setYWidthOfPier(yWidthOfPierVal);
      onUpdateDetail('pierWidthY', yWidthOfPierVal);
    } else if (value?.length > 0 && isDigitZenkaku(value)) {
      setYWidthOfPier(value);
      let yWidthOfPierVal = toHalfWidth(value);
      yWidthOfPierVal =
        Number(yWidthOfPierVal) > selectedMaterialYsize ? Number(detailData?.pierWidthY) : Number(yWidthOfPierVal);
      onUpdateDetail('pierWidthY', yWidthOfPierVal);
    } else {
      setYWidthOfPier(0);
      onUpdateDetail('pierWidthY', 0);
    }
  };

  // 材料詳細のXサイズを変更した場合、
  const onChangeDetailXSize = (value, isCheckZenkaku = true) => {
    let xVal = value;
    let freeId = getMaterialSizeFreeId();
    setDetailP1MaterialSizeId(freeId);
    setDetailP2MaterialSizeId(freeId);
    if (isCheckZenkaku && regexExp.test(xVal)) {
      xVal = toCheckZenkaku(xVal);
    }
    if (isCheckZenkaku) {
      if (Number(xVal) > maxXYSize || Number(xVal) < minXsize) {
        xVal = minXsize;
      }
    }
    // 入力値が最小値より、大きいの場合、
    if (Number(xVal) > minXsize) {
      if (activePattern === PatternType.Pattern1 ? p1TopFit : p2TopFit) {
        activePattern === PatternType.Pattern1 ? setP1TopFit(false) : setP2TopFit(false);
        updateSetting(freeId, 'topFit', false, activePattern);
      }
      if (activePattern === PatternType.Pattern1 ? p1RightFit : p2RightFit) {
        activePattern === PatternType.Pattern1 ? setP1RightFit(false) : setP2RightFit(false);
        updateSetting(freeId, 'rightFit', false, activePattern);
      }
    }
    if (isCheckZenkaku) {
      xVal = Number(xVal);
      updateXYSize(freeId, 'sizeX', xVal, activePattern);
    } else if (!isDigitZenkaku(xVal)) {
      xVal = minXsize;
    }
    xVal = xVal ? Math.floor(xVal) : 0;
    setDetailP1XSize(xVal);
    setDetailP2XSize(xVal);
  };

  // 材料詳細のYサイズを変更した場合、
  const onChangeDetailYSize = (value, isCheckZenkaku = true) => {
    let yVal = value;
    let freeId = getMaterialSizeFreeId();
    setDetailP1MaterialSizeId(freeId);
    setDetailP2MaterialSizeId(freeId);
    if (isCheckZenkaku && regexExp.test(yVal)) {
      yVal = toCheckZenkaku(yVal);
    }
    if (isCheckZenkaku) {
      if (Number(yVal) > maxXYSize || Number(yVal) < minYsize) {
        yVal = minYsize;
      }
    }
    // 入力値が最小値より、大きいの場合、
    if (Number(yVal) > minYsize) {
      if (activePattern === PatternType.Pattern1 ? p1TopFit : p2TopFit) {
        activePattern === PatternType.Pattern1 ? setP1TopFit(false) : setP2TopFit(false);
        updateSetting(freeId, 'topFit', false, activePattern);
      }
      if (activePattern === PatternType.Pattern1 ? p1RightFit : p2RightFit) {
        activePattern === PatternType.Pattern1 ? setP1RightFit(false) : setP2RightFit(false);
        updateSetting(freeId, 'rightFit', false, activePattern);
      }
    }
    if (isCheckZenkaku) {
      yVal = Number(yVal);
      updateXYSize(freeId, 'sizeY', yVal, activePattern);
    } else if (!isDigitZenkaku(yVal)) {
      yVal = minYsize;
    }
    yVal = yVal ? Math.floor(yVal) : 0;
    setDetailP1YSize(yVal);
    setDetailP2YSize(yVal);
  };

  // 入力項目情報
  const inputFormItem = (
    <>
      <div style={{ width: '100%' }}>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col">
            <div className="cm-a-center">
              <label style={{ marginLeft: 0 }}>入力項目(共通)</label>
            </div>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <RTable className="iq3material-detail">
                <thead>
                  <tr>
                    <th width={'18%'}>材質</th>
                    <th width={'18%'}>材料名称</th>
                    <th width={'18%'}>板厚</th>
                    <th width={'18%'}>表面保護</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 材質 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailMaterialTypeId ? detailMaterialTypeId : ''}
                            onChange={(e) => {
                              onChangeMaterialType(e, true);
                              let name = getMaterialTypeById(e, materialTypeListData);
                              onUpdateDetailByDetailMaterialTypeId(name, e);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialTypeListData
                              ?.filter(
                                (item) =>
                                  materialListData?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1
                              )
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailMaterialTypeId, materialTypeListData)}</>
                      )}
                    </td>
                    {/* 材料名称 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailMaterialId ? detailMaterialId : ''}
                            onChange={(e) => {
                              setDetailThickness(e);
                              setDetailMaterialId(e);
                              let name = getMaterialNameById(e, materialListData);
                              onUpdateDetailByDetailMaterialId(name, e);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialListData
                              ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === detailMaterialTypeId)
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailMaterialId, materialListData)}</>
                      )}
                    </td>
                    {/* 板厚 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailThickness ? detailThickness : ''}
                            onChange={(e) => {
                              setDetailThickness(e);
                              setDetailMaterialId(e);
                              let name = getMaterialNameById(e, materialListData);
                              onUpdateDetailByDetailMaterialId(name, e);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialListData
                              ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === detailMaterialTypeId)
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.info.thick}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailThickness, materialListData)}</>
                      )}
                    </td>
                    {/* 表面保護 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailSurfaceId ? detailSurfaceId : ''}
                            onChange={(e) => {
                              setDetailSurfaceId(e);
                              let name = getMaterialSurfaceById(e, materialSurfaceListData);
                              onUpdateTempDetail('materialSurfaceName', name);
                              onUpdateTempDetail('materialSurfaceIq3Id', e);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialSurfaceListData
                              ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === detailMaterialTypeId)
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailSurfaceId, materialSurfaceListData)}</>
                      )}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th width={'18%'}>掴み代X</th>
                    <th width={'18%'}>掴み代Y</th>
                    <th width={'18%'}>桟幅X</th>
                    <th width={'18%'}>桟幅Y</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 掴み代X */}
                    <td>
                      <Input
                        name="detailXGrabAllowance"
                        id="detailXGrabAllowance"
                        value={detailXGrabAllowance}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onBlur={(e) => onChangeDetailXGrabAllow(e.target.value)}
                        onPressEnter={(e) => onChangeDetailXGrabAllow(e.target.value)}
                        onChange={(e) => onChangeDetailXGrabAllow(e.target.value, false)}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 掴み代Y */}
                    <td>
                      <Input
                        name="detailYGrabAllowance"
                        id="detailYGrabAllowance"
                        value={detailYGrabAllowance}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onBlur={(e) => onChangeDetailYGrabAllow(e.target.value)}
                        onPressEnter={(e) => onChangeDetailYGrabAllow(e.target.value)}
                        onChange={(e) => onChangeDetailYGrabAllow(e.target.value, false)}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 桟幅X */}
                    <td>
                      <Input
                        name="detailXWidthOfPier"
                        id="detailXWidthOfPier"
                        value={detailXWidthOfPier}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onBlur={(e) => onChangeDetailXWidthOfPier(e.target.value)}
                        onPressEnter={(e) => onChangeDetailXWidthOfPier(e.target.value)}
                        onChange={(e) => onChangeDetailXWidthOfPier(e.target.value, false)}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 桟幅Y */}
                    <td>
                      <Input
                        name="detailYWidthOfPier"
                        id="detailYWidthOfPier"
                        value={detailYWidthOfPier}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onBlur={(e) => onChangeDetailYWidthOfPier(e.target.value)}
                        onPressEnter={(e) => onChangeDetailYWidthOfPier(e.target.value)}
                        onChange={(e) => onChangeDetailYWidthOfPier(e.target.value, false)}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Col>
          </Row>
        </div>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={8} className="deli-col">
            <div style={{ display: activePattern === PatternType.Pattern1 ? 'none' : 'block' }}>
              <LeftCircleFilled onClick={changePattern} style={{ fontSize: 20, verticalAlign: 'text-top' }} />
              &nbsp;
              <label>
                {getMaterialDetailPatternName(PatternType.Pattern1) +
                  '(' +
                  detailP1Yield +
                  ' * ' +
                  detailP1NumOfMaterial +
                  ')'}
              </label>
            </div>
          </Col>
          <Col span={8} className="deli-col">
            <div className="cm-a-center">
              <label>{getMaterialDetailPatternName(activePattern)}</label>
            </div>
          </Col>
          <Col span={8} className="deli-col">
            <div style={{ display: activePattern === PatternType.Pattern2 ? 'none' : 'block' }} className="cm-a-right">
              <label>
                {getMaterialDetailPatternName(PatternType.Pattern2) +
                  '(' +
                  detailP2Yield +
                  ' * ' +
                  detailP2NumOfMaterial +
                  ')'}
              </label>
              &nbsp;
              <RightCircleFilled onClick={changePattern} style={{ fontSize: 20, verticalAlign: 'text-top' }} />
            </div>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <RTable className="iq3material-detail">
                <thead>
                  <tr>
                    <th width={'33.33%'}>材料原価</th>
                    <th width={'33.33%'}>歩留率</th>
                    <th width={'33.33%'}>材料枚数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 合計金額 */}
                    <td>{activePattern === PatternType.Pattern1 ? detailP1TotalAmount : detailP2TotalAmount}</td>
                    {/* 歩留まり */}
                    <td>{activePattern === PatternType.Pattern1 ? detailP1Yield : detailP2Yield}</td>
                    {/* 材料枚数 */}
                    <td>{activePattern === PatternType.Pattern1 ? detailP1NumOfMaterial : detailP2NumOfMaterial}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th width={'33.33%'}>材料サイズ</th>
                    <th width={'33.33%'}>Xサイズ</th>
                    <th width={'33.33%'}>Yサイズ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 材料サイズ */}
                    <td>
                      {detailP1MaterialSizeId === getMaterialSizeFreeId() ||
                      detailP2MaterialSizeId === getMaterialSizeFreeId()
                        ? 'Free'
                        : getNameById(
                            activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId,
                            materialSizeData
                          )}
                    </td>
                    {/* Xサイズ */}
                    <td>
                      <Input
                        name={activePattern === PatternType.Pattern1 ? 'pattern1XSize' : 'pattern2XSize'}
                        id="xSize"
                        className={'input-editable '}
                        value={activePattern === PatternType.Pattern1 ? detailP1XSize : detailP2XSize}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        // onPressEnter={(e) => onChangeDetailXSize(e.target.value)}
                        onBlur={(e) => onChangeDetailXSize(e.target.value)}
                        onChange={(e) => {
                          if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                          activePattern === PatternType.Pattern1
                            ? setDetailP1XSize(e.target.value)
                            : setDetailP2XSize(e.target.value);
                        }}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* Yサイズ */}
                    <td>
                      <Input
                        name={activePattern === PatternType.Pattern1 ? 'pattern1YSize' : 'pattern2YSize'}
                        id="xSize"
                        className={'input-editable '}
                        value={activePattern === PatternType.Pattern1 ? detailP1YSize : detailP2YSize}
                        // onPressEnter={(e) => onChangeDetailYSize(e.target.value)}
                        onBlur={(e) => onChangeDetailYSize(e.target.value)}
                        onChange={(e) => {
                          if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                          activePattern === PatternType.Pattern1
                            ? setDetailP1YSize(e.target.value)
                            : setDetailP2YSize(e.target.value);
                        }}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={3} className="deli-col" style={{ display: 'grid' }}>
            <Image
              preview={false}
              width={50}
              src={(activePattern === PatternType.Pattern1 ? p1YSetting : p2YSetting) ? bottomToTop : leftToRight}
              style={{ marginLeft: '5px', marginTop: '15px' }}
              onClick={(e) => {
                let id = activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId;
                let ySetting = activePattern === PatternType.Pattern1 ? p1YSetting : p2YSetting;
                if (ySetting) {
                  activePattern === PatternType.Pattern1 ? setP1YSetting(false) : setP2YSetting(false);
                  updateSetting(id, 'ySetting', false, activePattern);
                } else {
                  activePattern === PatternType.Pattern1 ? setP1YSetting(true) : setP2YSetting(true);
                  updateSetting(id, 'ySetting', true, activePattern);
                }
                if (activePattern === PatternType.Pattern1) {
                  if (p1TopFit) {
                    setP1TopFit(false);
                    updateSetting(id, 'topFit', false, PatternType.Pattern1);
                  }
                  if (p1RightFit) {
                    setP1RightFit(false);
                    updateSetting(id, 'rightFit', false, PatternType.Pattern1);
                  }
                } else {
                  if (p2TopFit) {
                    setP2TopFit(false);
                    updateSetting(id, 'topFit', false, PatternType.Pattern2);
                  }
                  if (p2RightFit) {
                    setP2RightFit(false);
                    updateSetting(id, 'rightFit', false, PatternType.Pattern2);
                  }
                }
              }}
            />
            <Image
              preview={false}
              width={50}
              src={yRotate ? rotateY : rotateX}
              style={{ marginLeft: '5px' }}
              onClick={(e) => {
                let id = activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId;
                if (yRotate) {
                  setYRotate(false);
                  updateYRotate('yRotate', false);
                } else {
                  setYRotate(true);
                  updateYRotate('yRotate', true);
                }
                if (p1TopFit) {
                  setP1TopFit(false);
                  updateSetting(id, 'topFit', false, PatternType.Pattern1);
                }
                if (p2TopFit) {
                  setP2TopFit(false);
                  updateSetting(id, 'topFit', false, PatternType.Pattern2);
                }
                if (p1RightFit) {
                  setP1RightFit(false);
                  updateSetting(id, 'rightFit', false, PatternType.Pattern1);
                }
                if (p2RightFit) {
                  setP2RightFit(false);
                  updateSetting(id, 'rightFit', false, PatternType.Pattern2);
                }
              }}
            />
            <Image
              preview={false}
              width={50}
              src={leftRightFit}
              style={{ marginLeft: '5px' }}
              onClick={(e) => {
                activePattern === PatternType.Pattern1 ? setP1RightFit(true) : setP2RightFit(true);
                let freeId = getMaterialSizeFreeId();
                setDetailP1MaterialSizeId(freeId);
                setDetailP2MaterialSizeId(freeId);
                updateSetting(freeId, 'rightFit', true, activePattern);
              }}
            />
            <Image
              preview={false}
              width={50}
              src={topBottomFit}
              style={{ marginLeft: '5px' }}
              onClick={(e) => {
                activePattern === PatternType.Pattern1 ? setP1TopFit(true) : setP2TopFit(true);
                let freeId = getMaterialSizeFreeId();
                setDetailP1MaterialSizeId(freeId);
                setDetailP2MaterialSizeId(freeId);
                updateSetting(freeId, 'topFit', true, activePattern);
              }}
            />
          </Col>
          <Col span={21} className="deli-col">
            <div className="canvasDiv">
              <div
                class="canvasDivOnWheel"
                onWheel={(e) => {
                  const zoomElement = document.querySelector('.canvasDivOnWheel');
                  if (e.deltaY < 0) {
                    zoomElement.style.transform = `scale(${(zoom += ZOOM_SPEED)})`;
                  } else {
                    zoomElement.style.transform = `scale(${(zoom -= ZOOM_SPEED)})`;
                  }
                }}
              >
                {/* パターン１イメージ */}
                <div style={{ padding: '14px 20px 0px 20px' }}>
                  <IQ3MaterialDetail_SheetDetailPattern ref={sheetDetailRef} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );

  // パターン詳細情報の入力項目
  const patternContent = (
    <>
      <Row style={{ padding: '0px 15px 0px 15px' }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 670,
              fontSize: '13.5px',
              // overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <Button className="mainButton" onClick={patternUpdateOK} style={{ marginRight: 5 }}>
          OK
        </Button>
        <Button className="cancelButton" onClick={patternUpdateCancel}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // パターン詳細情報のタイトル
  const patternTitle = (
    <div
      style={{
        width: 650,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    setSelectedRowNo(record.materialSizeIq3Id);
    calMaterialXYSize(record.materialSizeIq3Id);
    displayMaterialDetailInfo(record, materialDetailPatternData);
    updateMaterialSizeUsedFlg(materialDetailSizeData, record?.materialSizeIq3Id);
  };

  // 選択中の材料サイズ「X,Y」値を最大値として設定
  const calMaterialXYSize = (selectedId) => {
    let mstMaterialSizes = [];
    if (materialSizeData?.length === 0) {
      const selectedEstimateData = props.selectedEstimateData;
      mstMaterialSizes = getMaterialSizeData(selectedEstimateData); // 材料サイズ情報
    } else {
      mstMaterialSizes = materialSizeData;
    }
    // 選択された材料サイズ設定
    let materialSizes = mstMaterialSizes ? mstMaterialSizes?.filter((item) => item.id === selectedId) : [];
    let sheetX = 0;
    let sheetY = 0;
    if (materialSizes?.length > 0) {
      if (materialSizes[0]?.sizeX == 0 || materialSizes[0]?.name === 'Other') {
        sheetX = Number(patternPartInfo.mPartX) + Number(xWidthOfPier) + Number(xGrabAllowance);
      } else {
        sheetX = Number(materialSizes[0]?.sizeX);
      }
      if (materialSizes[0]?.sizeY == 0 || materialSizes[0]?.name === 'Other') {
        sheetY = Number(patternPartInfo.mPartY) + Number(yWidthOfPier) + Number(yGrabAllowance);
      } else {
        sheetY = Number(materialSizes[0]?.sizeY);
      }
    } else {
      let iq3Info = isPatternDetail ? tempDetailData : detailData;
      sheetX = getMaterialAutoSize(iq3Info, yRotate);
      sheetY = getMaterialAutoSize(iq3Info, yRotate, false);
    }
    setSelectedMaterialXsize(sheetX);
    setSelectedMaterialYsize(sheetY);
  };

  // パターン詳細情報を得る
  const getPatternInfo = (patternList, sizeIq3Id, patternNo) => {
    return patternList?.find((item) => item.patternType === patternNo && item.materialSizeIq3Id === sizeIq3Id);
  };

  // 値を変更した場合、
  const onUpdateDetail = (name, value) => {
    let data = { ...detailData };
    data[name] = value;
    setDetailData(data);
    updateSelectedEstimateData(data);
  };

  // 材質を変更した場合、
  const onUpdateDetailByMaterialTypeId = (name, e) => {
    let data = { ...detailData };
    data['materialTypeName'] = name;
    data['materialTypeIq3Id'] = e;
    setDetailData(data);
    updateSelectedEstimateData(data);
  };

  // 材質を変更した場合、
  const onUpdateDetailByMaterialId = (name, e) => {
    let data = { ...detailData };
    data['thickness'] = e;
    data['materialName'] = name;
    data['materialIq3Id'] = e;
    setDetailData(data);
    updateSelectedEstimateData(data);
  };

  // 表面保護を変更した場合、
  const onUpdateDetailByMaterialSurface = (name, e) => {
    let data = { ...detailData };
    data['materialSurfaceName'] = name;
    data['materialSurfaceIq3Id'] = e;
    setDetailData(data);
    updateSelectedEstimateData(data);
  };

  // パラメータ詳細画面の値を変更した場合、
  const onUpdateTempDetail = (name, value) => {
    let data = { ...tempDetailData };
    data[name] = value;
    setTempDetailData(data);
  };

  // パラメータ詳細画面の材質を変更した場合、
  const onUpdateDetailByDetailMaterialTypeId = (name, e) => {
    let data = { ...tempDetailData };
    data['materialTypeName'] = name;
    data['materialTypeIq3Id'] = e;
    setTempDetailData(data);
  };

  // パラメータ詳細画面の材質を変更した場合、
  const onUpdateDetailByDetailMaterialId = (name, e) => {
    let data = { ...tempDetailData };
    data['thickness'] = e;
    data['materialName'] = name;
    data['materialIq3Id'] = e;
    setTempDetailData(data);
  };

  // XY値を変更した場合、
  const updateXYSize = (id, name, value, patternNo) => {
    let data = { ...tempDetailData };
    let index = data?.materialDetailPatterns?.findIndex(
      (item) => item.materialSizeIq3Id === id && item.patternType === patternNo
    );
    if (index > -1) {
      data.materialDetailPatterns[index][name] = value;
    } else if (id === getMaterialSizeFreeId()) {
      // 材料詳細にFreeが無い場合、追加
      let initFreeSizeInfo = JSON.parse(JSON.stringify(getInitFreeMaterialSizeInfo()));
      data?.materialDetailSizes?.push({
        ...initFreeSizeInfo,
        yRotate: yRotate,
      });
      let freeMaterialDetailPatterns = [];
      getInitMaterialPatternsInfo(freeMaterialDetailPatterns, data?.materialDetailSizes);
      let patternList = data?.materialDetailPatterns;
      freeMaterialDetailPatterns?.map((item) => {
        let sizeX = 0;
        let sizeY = 0;
        if (item?.patternType === PatternType.Pattern2) {
          sizeX = name === 'sizeX' ? value : detailP1XSize;
          sizeY = name === 'sizeY' ? value : detailP1YSize;
        } else {
          sizeX =
            item?.patternType === PatternType.Pattern1
              ? name === 'sizeX'
                ? value
                : detailP1XSize
              : name === 'sizeX'
              ? value
              : detailP2XSize;
          sizeY =
            item?.patternType === PatternType.Pattern1
              ? name === 'sizeY'
                ? value
                : detailP1YSize
              : name === 'sizeY'
              ? value
              : detailP2YSize;
        }
        // if (name === 'sizeX') {
        //   if (item?.patternType === patternNo) {
        //     sizeX = value; // 入力値
        //   }
        // }
        // if (name === 'sizeY') {
        //   if (item?.patternType === patternNo) {
        //     sizeY = value; // 入力値
        //   }
        // }
        item.materialSizeIq3Id = initFreeSizeInfo?.materialSizeIq3Id;
        item.sizeName = initFreeSizeInfo?.sizeName;
        item.sizeX = sizeX;
        item.sizeY = sizeY;
        item.ySetting = item?.patternType === PatternType.Pattern1 ? p1YSetting : p2YSetting;
        item.topFit = item?.patternType === PatternType.Pattern1 ? p1TopFit : p2TopFit;
        item.rightFit = item?.patternType === PatternType.Pattern1 ? p1RightFit : p2RightFit;
        patternList.push(item);
      });
      data.materialDetailPatterns = patternList;
    }
    name === 'sizeX' ? setDetailXSize(value) : setDetailYSize(value); // 変更があった場合、計算する
    setTempDetailData(data);
  };

  // 表示設定（yRotate）値を変更した場合、
  const updateYRotate = (name, value) => {
    let data = { ...tempDetailData };
    data.materialDetailSizes = data?.materialDetailSizes?.map((item) => {
      item[name] = value;
      return item;
    });
    setTempDetailData(data);
  };

  // 表示設定（ySetting、topFit、rightFit）値を変更した場合、
  const updateSetting = (id, name, value, patternNo) => {
    let data = { ...tempDetailData };
    let index = data?.materialDetailPatterns?.findIndex(
      (item) => item?.materialSizeIq3Id === id && item?.patternType === patternNo
    );
    if (index > -1) {
      data.materialDetailPatterns[index][name] = value;
      setTempDetailData(data);
    }
  };

  // パターン１詳細情報、パターン２詳細情報、計算結果情報
  const displayMaterialDetailInfo = (selectedSizeData, patternDetailData, isDetail = false) => {
    if (selectedSizeData === undefined) return;
    setPattern1Info(
      getPatternInfo(patternDetailData, selectedSizeData?.materialSizeIq3Id, PatternType.Pattern1),
      isDetail
    ); // パターン１詳細情報
    setPattern2Info(
      getPatternInfo(patternDetailData, selectedSizeData?.materialSizeIq3Id, PatternType.Pattern2),
      isDetail
    ); // パターン２詳細情報
    setSummarizeInfo(selectedSizeData); // 計算結果情報
  };

  // 採用した材料サイズの採用フラグを更新する
  const updateMaterialSizeUsedFlg = (sizeLists, usedId, currentRecordNo = props?.currentRecordNo) => {
    if (sizeLists === undefined) return;
    sizeLists?.map((item) => {
      if (item && item.materialSizeIq3Id === usedId) {
        item['isUsed'] = true;
      } else {
        item['isUsed'] = false;
      }
    });
    updateMaterialSizePrice(sizeLists, currentRecordNo);
  };

  // 材料原価合計、材料原価を更新する
  const updateMaterialSizePrice = (sizeLists, currentRecordNo) => {
    if (sizeLists === undefined) return;
    let usedMaterialSizeInfo = sizeLists?.find((item) => item.isUsed); // 採用されるサイズの情報
    if (usedMaterialSizeInfo) {
      let selectedIq3EstimateData = getCurrentSelectedIq3Info(props?.selectedEstimateData, currentRecordNo);
      let materialTotalPrice = usedMaterialSizeInfo?.price; // 材料原価合計
      let materialUnitPrice =
        materialTotalPrice / (selectedIq3EstimateData?.partCount * getParentQuantity(props?.selectedEstimateData)); // 材料原価/員数＊数量
      selectedIq3EstimateData['materialCostUnitPrice'] =
        materialUnitPrice && !isInfinity(materialUnitPrice) ? materialUnitPrice : 0; // 材料原価
      selectedIq3EstimateData['materialCostTotalPrice'] =
        materialTotalPrice && !isInfinity(materialTotalPrice) ? materialTotalPrice : 0; // 材料原価合計
      updateSelectedEstimateData(selectedIq3EstimateData, currentRecordNo);
    }
  };

  // 選択中の板金見積もりの情報
  const getCurrentSelectedIq3Info = (selectedEstimateData, currentIq3RecordNo) => {
    return selectedEstimateData?.esIq3Info?.find((iq3) => iq3?.no === currentIq3RecordNo);
  };

  // 見積もりの板金情報を更新
  const updateSelectedEstimateData = (iq3Info, currentRecordNo = props?.currentRecordNo) => {
    if (iq3Info === undefined || props?.selectedEstimateData?.esIq3Info === undefined) return;
    let index = props?.selectedEstimateData?.esIq3Info?.findIndex((iq3) => iq3?.no === currentRecordNo);
    if (index > -1) {
      props.selectedEstimateData.esIq3Info[index] = iq3Info;
      setOldSelectedEstimateData(JSON.parse(JSON.stringify(props?.selectedEstimateData)));
    }
    props?.updateCurIQ3MaterialDetailData(iq3Info);
  };

  // 選択中の親部品見積もりの数量
  const getParentQuantity = (selectedEstimateData) => {
    return selectedEstimateData?.estimateProducts?.quantity;
  };
  // 材料名称検索
  const filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

  // 一番合計歩留まりが良い材料サイズ情報
  const getMaxYieldRecord = (sizeLists) => {
    let maxYieldRecord = sizeLists?.reduce((prev, current) => (prev && prev.yield > current.yield ? prev : current));
    return maxYieldRecord;
  };

  const sheetDetailImgDraw = (toDrawInfo, isPattern2, ySetting, rightFit, topFit, isFree, isExistPattern2) => {
    let partCntInfo = undefined;

    /** 配置するCanvasの情報 */
    const canvas = isPattern2 ? document.getElementById('canvasImg2') : document.getElementById('canvasImg1');
    if (canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.width = 480;
      canvas.height = 240;
      const canvasContext = canvas.getContext('2d');
      if (canvasContext !== null) {
        if (!isFree) {
          // 材料・掴み代・部品矩形・桟幅の配置
          partCntInfo = draw(
            toDrawInfo.partInfo,
            toDrawInfo.settingInfo,
            toDrawInfo.materialInfo,
            isPattern2,
            ySetting,
            rightFit,
            topFit,
            canvas
          );
        } else {
          // 材料・掴み代・部品矩形・桟幅の配置
          partCntInfo = freeDraw(
            toDrawInfo.partInfo,
            toDrawInfo.settingInfo,
            toDrawInfo.materialInfo,
            isPattern2,
            ySetting,
            rightFit,
            topFit,
            canvas,
            isExistPattern2
          );
        }
        // 部品XYサイズ配置
        // 製品の矩形X
        let partX = toDrawInfo.partInfo.mPartX + toDrawInfo.settingInfo.mMarginX;
        let partY = toDrawInfo.partInfo.mPartY + toDrawInfo.settingInfo.mMarginY;
        let partCntX = partCntInfo?.partCntX ? partCntInfo?.partCntX : 0;
        let partCntY = partCntInfo?.partCntY ? partCntInfo?.partCntY : 0;
        let fillTextX = 'X=' + partX * partCntX + '(' + partCntX + ')';
        let fillTextY = 'Y=' + partY * partCntY + '(' + partCntY + ')';
        canvasContext.beginPath();
        const { backgroundColor = 'black', color = 'black' } = { backgroundColor: 'PowderBlue', color: 'black' };

        let mSheetX = !isFree
          ? toDrawInfo.materialInfo.mSheetX
          : isPattern2
          ? toDrawInfo.materialInfo.p2MSheetX
          : toDrawInfo.materialInfo.p1MSheetX;
        let mSheetY = !isFree
          ? toDrawInfo.materialInfo.mSheetY
          : isPattern2
          ? toDrawInfo.materialInfo.p2MSheetY
          : toDrawInfo.materialInfo.p1MSheetY;
        if (canvas !== '') {
          //　指定したCanvasに配置できるScaleを計算
          ca1Scale = scaleSet(0, mSheetX, mSheetY, canvas);
          if (ca1Scale == 0) return;
        }
        // 開始X
        let startX = mSheetX * ca1Scale;
        let startY = mSheetY * ca1Scale;
        canvasContext.fillStyle = backgroundColor;
        canvasContext.fillRect(startX - 90, startY - 13, 100, 12);
        canvasContext.fillStyle = color;
        canvasContext.font = 'bold';
        canvasContext.fillText(fillTextX, startX - 88, startY - 3, 200);
        // 開始Y
        canvasContext.fillStyle = backgroundColor;
        canvasContext.fillRect(0, 0, 100, 12);
        canvasContext.fillStyle = color;
        canvasContext.font = 'bold';
        canvasContext.fillText(fillTextY, 2, 10, 200);
      }
    }
    return partCntInfo;
  };

  return (
    <>
      {props?.tabOpen || props?.dialogOk ? (
        <>
          <div style={{ display: 'flex', float: 'left', width: '100%', paddingTop: 0 }}>
            {/* 左 */}
            <div style={{ width: '60%' }}>
              <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
                <Col span={24} className="deli-col">
                  <div className="cm-a-center">
                    <label style={{ marginLeft: 0 }}>情報</label>
                  </div>
                </Col>
              </Row>
              <div>
                <Row>
                  <Col span={24}>
                    <RTable className="iq3material-detail">
                      <thead>
                        <tr>
                          <th colSpan={3} width={'55%'}>
                            子部品名称
                          </th>
                          <th width={'15%'}>子部品製作数</th>
                          <th width={'15%'}>Xサイズ</th>
                          <th width={'15%'}>Yサイズ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 部品名 */}
                          <td colSpan={3}>{partName}</td>
                          {/* 員数(製造個数) */}
                          <td>{partCount + '(' + partCount * quantity + ')'}</td>
                          {/* Xサイズ */}
                          <td>{xSize}</td>
                          {/* Yサイズ */}
                          <td>{ySize}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th width={'18%'}>材質</th>
                          <th width={'18%'}>材料名称</th>
                          <th width={'18%'}>板厚</th>
                          <th width={'15%'}>表面保護</th>
                          <th width={'15%'}>重量(kg)</th>
                          <th width={'15%'}>表面積(c㎡)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 材質 */}
                          <td>
                            {editMode ? (
                              <>
                                <Select
                                  showSearch
                                  value={materialTypeId ? materialTypeId : ''}
                                  onChange={(e) => {
                                    onChangeMaterialType(e);
                                    let name = getMaterialTypeById(e, materialTypeListData);
                                    onUpdateDetailByMaterialTypeId(name, e);
                                  }}
                                  filterOption={filterOption}
                                  style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                                >
                                  {materialTypeListData
                                    ?.filter(
                                      (item) =>
                                        materialListData?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1
                                    )
                                    ?.map((item, index) => (
                                      <Select.Option key={index} value={item.id}>
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </>
                            ) : (
                              <>{getNameById(materialTypeId, materialTypeListData)}</>
                            )}
                          </td>
                          {/* 材料名称 */}
                          <td>
                            {editMode ? (
                              <>
                                <Select
                                  value={materialId ? materialId : ''}
                                  onChange={(e) => {
                                    setThickness(e);
                                    setMaterialId(e);
                                    let name = getMaterialNameById(e, materialListData);
                                    onUpdateDetailByMaterialId(name, e);
                                  }}
                                  style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                                >
                                  {materialListData
                                    ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                                    ?.map((item, index) => (
                                      <Select.Option key={index} value={item.id}>
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </>
                            ) : (
                              <>{getNameById(materialId, materialListData)}</>
                            )}
                          </td>
                          {/* 板厚 */}
                          <td>
                            {editMode ? (
                              <>
                                <Select
                                  value={thickness ? thickness : ''}
                                  onChange={(e) => {
                                    setThickness(e);
                                    setMaterialId(e);
                                    let name = getMaterialNameById(e, materialListData);
                                    onUpdateDetailByMaterialId(name, e);
                                  }}
                                  style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                                >
                                  {materialListData
                                    ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                                    ?.map((item, index) => (
                                      <Select.Option key={index} value={item.id}>
                                        {item.info.thick}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </>
                            ) : (
                              <>{getNameById(thickness, materialListData)}</>
                            )}
                          </td>
                          {/* 表面保護 */}
                          <td>
                            {editMode ? (
                              <>
                                <Select
                                  value={surfaceId ? surfaceId : ''}
                                  name="surface"
                                  id="surface"
                                  onChange={(e) => {
                                    setSurfaceId(e);
                                    let name = getMaterialSurfaceById(e, materialSurfaceListData);
                                    onUpdateDetailByMaterialSurface(name, e);
                                  }}
                                  style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                                >
                                  {materialSurfaceListData
                                    ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                                    ?.map((item, index) => (
                                      <Select.Option key={index} value={item.id}>
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </>
                            ) : (
                              <>{getNameById(surfaceId, materialSurfaceListData)}</>
                            )}
                          </td>
                          {/* 重量(kg) */}
                          <td>{weight}</td>
                          {/* 表面積(c㎡) */}
                          <td>{surfaceArea}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th width={'18%'}>掴み代X</th>
                          <th width={'18%'}>掴み代Y</th>
                          <th width={'18%'}>桟幅X</th>
                          <th width={'15%'}>桟幅Y</th>
                          <th width={'15%'}>加工範囲 X</th>
                          <th width={'15%'}>加工範囲 Y</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 掴み代X */}

                          <td>
                            <Input
                              name="xGrabAllowance"
                              id="xGrabAllowance"
                              className="input-editable"
                              style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                              value={xGrabAllowance}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              onBlur={(e) => onChangeXGrabAllow(e.target.value)}
                              onPressEnter={(e) => onChangeXGrabAllow(e.target.value)}
                              onChange={(e) => onChangeXGrabAllow(e.target.value, false)}
                            />
                          </td>
                          {/* 掴み代Y */}

                          <td>
                            <Input
                              name="yGrabAllowance"
                              id="yGrabAllowance"
                              className={'input-editable'}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              value={yGrabAllowance}
                              onBlur={(e) => onChangeYGrabAllow(e.target.value)}
                              onPressEnter={(e) => onChangeYGrabAllow(e.target.value)}
                              onChange={(e) => onChangeYGrabAllow(e.target.value, false)}
                            />
                          </td>
                          {/* 桟幅X */}

                          <td>
                            <Input
                              name="xWidthOfPier"
                              id="xWidthOfPier"
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              value={xWidthOfPier}
                              onBlur={(e) => onChangeXWidthOfPier(e.target.value)}
                              onPressEnter={(e) => onChangeXWidthOfPier(e.target.value)}
                              onChange={(e) => onChangeXWidthOfPier(e.target.value, false)}
                              className={'input-editable'}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                            />
                          </td>
                          {/* 桟幅Y */}

                          <td>
                            <Input
                              name="yWidthOfPier"
                              id="yWidthOfPier"
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              value={yWidthOfPier}
                              onBlur={(e) => onChangeYWidthOfPier(e.target.value)}
                              onPressEnter={(e) => onChangeYWidthOfPier(e.target.value)}
                              onChange={(e) => onChangeYWidthOfPier(e.target.value, false)}
                              className={'input-editable'}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                            />
                          </td>
                          {/* 加工範囲 X */}
                          <td>{xMachiningRange}</td>
                          {/* 加工範囲 Y */}
                          <td>{yMachiningRange}</td>
                        </tr>
                      </tbody>
                    </RTable>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col span={24}>
                  <div className="cm-a-center">
                    <Table
                      columns={columns}
                      rowKey={(record) => record.no}
                      dataSource={materialDetailSizeData}
                      scroll={{ y: 350, x: '10vw' }}
                      rowClassName={(record) => (record.materialSizeIq3Id == selectedRowNo ? 'active-row' : 'data-row')}
                      className="iq3material-detail-list-table"
                      pagination={false}
                      onRow={(record, index) => {
                        return {
                          onClick: (event) => {
                            onRowSelect(record);
                          },
                        };
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {/* 右 */}
            <div style={{ marginLeft: '10px', width: '40%' }}>
              {/* 計算結果 */}
              <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={openCalResultPanel}>
                <Col span={24} className="deli-col">
                  <div
                    className="cm-a-center"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <label style={{ marginLeft: 0 }}>計算結果</label>
                    {isCalResult ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isCalResult ? 'block' : 'none' }}>
                <Row>
                  <Col span={24}>
                    <RTable className="iq3material-detail">
                      <thead>
                        <tr>
                          <th width={'33.33%'}>材料原価</th>
                          <th width={'33.33%'}>歩留率</th>
                          <th width={'33.33%'}>材料枚数</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 合計金額 */}
                          <td>{JPYs.format(summarizeTotalAmount)}</td>
                          {/* 歩留まり */}
                          <td>{summarizeYield}</td>
                          {/* 材料枚数 */}
                          <td>{summarizeNumOfMaterial}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th width={'33.33%'}>重量単価</th>
                          <th width={'33.33%'}>表面加算</th>
                          <th width={'33.33%'}>重量加算</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 重量単価 */}
                          <td>{summarizePrice}</td>
                          {/* 表面加算 */}
                          <td>{summarizeSurface}</td>
                          {/* 重量加算 */}
                          <td>{summarizeWeight}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th width={'33.33%'}>{/* 材料単価(1部品当たり) */}</th>
                          <th width={'33.33%'}>{/* 原価率(1部品当たり) */}</th>
                          <th width={'33.33%'} className="formula-column">
                            {/* <Popover content={scrapAmount}>スクラップ金額</Popover> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 材料単価(1部品当たり) */}
                          <td>{/* {JPYs.format(summarizeMaterialPrice)} */}</td>
                          {/* 原価率(1部品当たり) */}
                          <td>{/* {summarizeCostRate} */}</td>
                          {/* スクラップ金額 */}
                          {/* <td>{summarizeScrapAmount}</td> */}
                        </tr>
                      </tbody>
                    </RTable>
                  </Col>
                </Row>
              </div>

              {/* パターン1 */}
              <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
                <Col span={24} className="deli-col">
                  <div
                    className="cm-a-center"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {editMode ? (
                      <Button className={'pattern-btn'} id="pattern1Btn" onClick={callPattern1Detail}>
                        {getMaterialDetailPatternName(PatternType.Pattern1)}
                      </Button>
                    ) : (
                      <label style={{ marginLeft: 0 }}>{getMaterialDetailPatternName(PatternType.Pattern1)}</label>
                    )}
                    {isPattern1 ? (
                      <>
                        <CaretUpOutlined
                          style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                          onClick={openPattern1Panel}
                        />
                        {isPattern1Img ? (
                          <UpCircleFilled
                            style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                            onClick={openPattern1ImgPanel}
                          />
                        ) : (
                          <DownCircleOutlined
                            style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                            onClick={openPattern1ImgPanel}
                          />
                        )}
                      </>
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                        onClick={openPattern1Panel}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isPattern1 ? 'block' : 'none', marginBottom: 12 }}>
                <Row>
                  <Col span={24} style={{ height: '153px' }}>
                    <RTable className="iq3material-detail">
                      <thead>
                        <tr>
                          <th width={'33.33%'}>合計金額</th>
                          <th width={'33.33%'}>歩留まり</th>
                          <th width={'33.33%'}>材料枚数</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 合計金額 */}
                          <td>{pattern1TotalAmount}</td>
                          {/* 歩留まり */}
                          <td>{pattern1Yield}</td>
                          {/* 材料枚数 */}
                          <td>{pattern1NumOfMaterial}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th width={'33.33%'}>材料サイズ</th>
                          <th width={'33.33%'}>Xサイズ</th>
                          <th width={'33.33%'}>Yサイズ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 材料サイズ */}
                          <td>
                            {pattern1MaterialSizeId === getMaterialSizeFreeId()
                              ? 'Free'
                              : getNameById(pattern1MaterialSizeId, materialSizeData)}
                          </td>
                          {/* Xサイズ */}
                          <td>{pattern1XSize}</td>
                          {/* Yサイズ */}
                          <td>{pattern1YSize}</td>
                        </tr>
                      </tbody>
                    </RTable>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  display: isPattern1 && isPattern1Img ? 'block' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginBottom: 12,
                }}
              >
                <Row>
                  <Col span={21} className="deli-col">
                    {/* パターン１イメージ */}
                    <div className="canvasDiv">
                      <div style={{ padding: '14px 20px 0px 20px' }}>
                        <canvas width="480" height="240" id="canvasImg1"></canvas>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* パターン2 */}
              <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
                <Col span={24} className="deli-col">
                  <div
                    className="cm-a-center"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {editMode ? (
                      <Button className={'pattern-btn'} id="pattern1Btn" onClick={callPattern2Detail}>
                        {getMaterialDetailPatternName(PatternType.Pattern2)}
                      </Button>
                    ) : (
                      <label style={{ marginLeft: 0 }}>{getMaterialDetailPatternName(PatternType.Pattern2)}</label>
                    )}
                    {isPattern2 ? (
                      <>
                        <CaretUpOutlined
                          style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                          onClick={openPattern2Panel}
                        />
                        {isPattern2Img ? (
                          <UpCircleFilled
                            style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                            onClick={openPattern2ImgPanel}
                          />
                        ) : (
                          <DownCircleOutlined
                            style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                            onClick={openPattern2ImgPanel}
                          />
                        )}
                      </>
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                        onClick={openPattern2Panel}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isPattern2 ? 'block' : 'none', marginBottom: 12 }}>
                <Row>
                  <Col span={24} style={{ height: '153px' }}>
                    <RTable className="iq3material-detail">
                      <thead>
                        <tr>
                          <th width={'33.33%'}>合計金額</th>
                          <th width={'33.33%'}>歩留まり</th>
                          <th width={'33.33%'}>材料枚数</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 合計金額 */}
                          <td>{pattern2TotalAmount}</td>
                          {/* 歩留まり */}
                          <td>{pattern2Yield}</td>
                          {/* 材料枚数 */}
                          <td>{pattern2NumOfMaterial}</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th width={'33.33%'}>材料サイズ</th>
                          <th width={'33.33%'}>Xサイズ</th>
                          <th width={'33.33%'}>Yサイズ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* 材料サイズ */}
                          <td>
                            {pattern2MaterialSizeId === getMaterialSizeFreeId()
                              ? 'Free'
                              : getNameById(pattern2MaterialSizeId, materialSizeData)}
                          </td>
                          {/* Xサイズ */}
                          <td>{pattern2XSize}</td>
                          {/* Yサイズ */}
                          <td>{pattern2YSize}</td>
                        </tr>
                      </tbody>
                    </RTable>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  display: isPattern2 && isPattern2Img ? 'block' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginBottom: 12,
                }}
              >
                <Row>
                  <Col span={21} className="deli-col">
                    {/* パターン２イメージ */}
                    <div className="canvasDiv">
                      <div style={{ padding: '14px 20px 0px 20px' }}>
                        <canvas width="480" height="240" id="canvasImg2"></canvas>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {/* パターン詳細情報 */}
          {isPatternDetail
            ? commonModal(
                isPatternDetail,
                patternTitle,
                null,
                null,
                650,
                patternUpdateOK,
                patternUpdateCancel,
                patternContent,
                'iq3material-pattern-detail',
                null,
                true
              )
            : ''}

          {/* 更新確認 */}
          {updateConfirm
            ? commonModal(
                updateConfirm,
                confirmModalTitle,
                commonFooter(confirmOk, confirmCancel),
                null,
                400,
                confirmOk,
                confirmCancel,
                confirmUpdateContent,
                null,
                null,
                false
              )
            : ''}

          {/** 破棄の確認メッセージ */}
          {cancelConfirm
            ? commonModal(
                cancelConfirm,
                confirmModalTitle,
                commonFooter(cancelConfirmOK, cancelConfirmCancel),
                null,
                400,
                cancelConfirmOK,
                cancelConfirmCancel,
                confirmDiscardContent,
                null,
                null,
                false
              )
            : ''}
        </>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3MaterialDetail;
