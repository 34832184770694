/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Layout, Image, Button, Row, Col, Space, Input, Spin, Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { Table as RTable } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Header from './Header';
import logout from '../assets/images/logout.png';
import parameter from '../assets/images/parameter.png';
import oya_list from '../assets/images/oya_list.png';
import ParentList from '../views/parent/ParentList';
import MainParameter from '../views/parameter/MainParameter';
import MainEstimate from '../views/common/MainEstimate';
import estimate from '../assets/images/estimate.png';
import {
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  confirmScreenChangeWarning,
  sameDrawingNoContent,
} from '../views/common/CommonModal';
import {
  todayTime,
  formatDateString,
  isCustomer,
  toDecimal,
  estimateInitInfo,
  initAdditionalItemCost,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  initAdditionalItemDate,
  updateEstimateInfoByReCal,
  getNameByValue,
  today,
} from '../views/common/Common';
import {
  logOutSubmit,
  createEstimateProductInfo,
  updateEstimateProductInfo,
  GetParametersByCompId,
  getExistUserSetting,
  getIsExistSameEstimate,
  getExistCompanySetting,
} from '../views/common/CommonAPI';
import UserSetting from '../views/setting/UserSetting';
import { ClientType, DataType, ParamCalType, ServiceClass, WorkType, initDispRoutes } from '../views/common/enums';
import { byQuantities, saveSameTypes } from '../views/common/Constant';

const { Content, Sider } = Layout;

const MainLayout = React.forwardRef((props, ref) => {
  const [paramType, setParamType] = useState('');
  const [paramSelected, setParamSelected] = useState('');
  const [partNm, setPartNm] = useState('parentList');
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  // 画面切替警告メッセージポップアップ
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [saveDiscardConfirmPopup, setSaveDiscardConfirmPopup] = useState(false);
  const [navigatePageName, setNavigatePageName] = useState('');
  // 切替する画面の情報
  const [event, setEvent] = useState('');
  const [pageName, setPageName] = useState('');
  const [partName, setPartName] = useState('');
  const [routeType, setRouteType] = useState('');
  const [estimateEditMode, setEstimateEditMode] = useState(false);
  const [parentListClick, setParentListClick] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [processInfo, setProcessInfo] = useState([]);
  const [companySettingInfo, setCompanySettingInfo] = useState([]);
  const [userSettingInfo, setUserSettingInfo] = useState([]);
  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [newMode, setNewMode] = useState(false);
  const [currentParamSelected, setCurrentParamSelected] = useState('');
  const [paramSelected1, setParamSelected1] = useState('');
  const [paramSelected2, setParamSelected2] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [rowSelectTbl, setRowSelectTbl] = useState(0);
  const [rowSelectPage, setRowSelectPage] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [filterDatas, setFilterDatas] = useState({
    customerFlgState: 0,
    supplierFlgState: 0,
    contractorFlgState: 0,
    keyword: '',
    isUsedFlg: 1,
  });

  const paramRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const mainEstimateRef = useRef();
  const parentListRef = useRef();
  const menuContent = useRef(null);
  const userSettingRef = useRef();

  // 別名保存の入力項目
  const [estimateNo, setEstimateNo] = useState('');
  const [drawingNo, setDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [isSaveByOtherName, setIsSaveByOtherName] = useState(false);

  // 活性、非活性タブ
  const [activeTab, setActiveTab] = useState('');

  const fromLogin = location?.state?.fromLogin;

  // Waitingダイアログの表示
  const [loading, setLoading] = useState(false);

  // 新規作成フラグ
  const [createNew, setCreateNew] = useState(false);

  // パラメータ新旧計算モード
  const [isReCal, setIsRecal] = useState(false);

  // 材料詳細の再計算モード
  const [isReCalMaterial, setIsRecalMaterial] = useState(false);

  const [reCalBtnEvent, setReCalBtnEvent] = useState(false);

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品」
  const [parentActiveTabKey, setParentActiveTabKey] = useState('');

  const [isMenuClickByUserSetting, setIsMenuClickByUserSetting] = useState(false);

  const [isNewModeByParent, setIsNewModeByParent] = useState(false);

  const [selectedEstimateDataByParentLst, setSelectedEstimateDataByParentLst] = useState([]);

  // 同一図番で保存
  const [isSaveSameDrawingNo, setIsSaveSameDrawingNo] = useState(false);
  const [saveSameDrawingNoFncName, setSaveSameDrawingNoFncName] = useState('');

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品、材料詳細」
  const [iq3ActiveTabKey, setIq3ActiveTabKey] = useState('1');

  // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
  const [isEstimateOpen, setIsEstimateOpen] = useState(false);
  const [createNewEvent, setCreateNewEvent] = useState(false);

  // 呼び出した見積情報保持　// IQX_WEBEST-351 ログインからログアウトまでの間で、親部品リストから呼び出されたデータの行の背景色を設定する。
  const [openDetailInfo, setOpenDetailInfo] = useState([]);
  const [currentParentRecordId, setCurrentParentRecordId] = useState(0);
  const [currentParentListPage, setCurrentParentListPage] = useState(1);
  const [currentParentListPageBk, setCurrentParentListPageBk] = useState(1);

  const [selectedEstimateDataBeforeParentTab, setSelectedEstimateDataBeforeParentTab] = useState([]);

  useEffect(() => {
    // 親部品リストのページリロードの場合
    if (location?.pathname === initDispRoutes[0]) {
      setParentListClick(true);
      navigate(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (fromLogin) {
      initDisplay();
    }
  }, [fromLogin]);

  const updateCreateNewEvent = (value) => {
    setCreateNewEvent(value);
  };

  const initDisplay = async () => {
    setLoading(true);
    switch (location.pathname) {
      case initDispRoutes[0]:
        await getBackendPrepareData([], true, true, true, true);
        setParentListClick(true);
        navigate(location.pathname);
        return (
          <ParentList
            ref={parentListRef}
            updateEstimateEditMode={updateEstimateEditMode}
            getSelectedEstimateData={getSelectedEstimateData}
            editMode={estimateEditMode}
            parentListClick={parentListClick}
            orderOptions={orderOptions}
            companyInfo={companyInfo}
            clientInfo={clientInfo}
            userInfo={userInfo}
            staffInfo={staffList}
            processInfo={processInfo}
            companySettingInfo={companySettingInfo}
            purchaseCategoryInfo={purchaseCategoryInfo}
            loading={updateLoading}
            openDetailInfo={openDetailInfo}
            currentParentRecordId={currentParentRecordId}
            currentParentListPage={currentParentListPage}
          />
        );
      default:
        return (
          <MainParameter
            paramType={paramType}
            paramNm={paramSelected}
            updateParamSelected={updateParamSelected}
            companySettingInfo={companySettingInfo}
            userSettingInfo={userSettingInfo}
            ref={paramRef}
            updateCurrentParamSelected={updateCurrentParamSelected}
            updateParamSelected1={updateParamSelected1}
            updateParamSelected2={updateParamSelected2}
            paramSelected1={paramSelected1}
            paramSelected2={paramSelected2}
            updateSelectedRowId={updateSelectedRowId}
            selectedRowId={selectedRowId}
            rowSelectTbl={rowSelectTbl}
          />
        );
    }
  };

  const getBackendPrepareData = async (
    currentEstimateData,
    isMaterialParam = true,
    isProcessParam = true,
    isPurchaseParam = true,
    isOtherParam = true
  ) => {
    let updateEstimateData = currentEstimateData;
    let newParameters = await GetParametersByCompId();
    if (newParameters) {
      if (isOtherParam) {
        getProjectStatusData(newParameters.estimateStatus);
        getLoginCompanyInfo(newParameters.company);
        getStaffListInfo(newParameters.staff);
        getItakusakiInfo(newParameters.client);
        getLoginUserInfo(newParameters.currentUserInfo);
        getCompanySettingInfo(newParameters.companySettings);
        getUserSettingInfo(newParameters.userSettings);
      }
      if (isProcessParam) getProcessMstInfo(newParameters.process);
      if (isPurchaseParam) getPurchaseCategoryInfo(newParameters.purchaseCategory);
      if (updateEstimateData != undefined && updateEstimateData?.estimateProducts) {
        if (isOtherParam) {
          // その他全て（係数、カスタマイズ・・・）
          updateEstimateData.estimateProducts.calcParameters.parameters.company = newParameters.company;
          updateEstimateData.estimateProducts.calcParameters.parameters.staff = newParameters.staff;
          updateEstimateData.estimateProducts.calcParameters.parameters.client = newParameters.client;
          updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = newParameters.estimateStatus;
          updateEstimateData.estimateProducts.calcParameters.settings.userSettings = newParameters.userSettings;
          updateEstimateData.estimateProducts.calcParameters.settings.companySettings = newParameters.companySettings;
        }
        if (isProcessParam) {
          // 加工系（工程マスター、装置設定、工程パターン）
          updateEstimateData.estimateProducts.calcParameters.parameters.process = newParameters.process;
          updateEstimateData.estimateProducts.calcParameters.parameters.processPattern = newParameters.processPattern;
          updateEstimateData.estimateProducts.calcParameters.parameters.device = newParameters.device;
          let processSelect = newParameters.processPattern?.filter(
            (item) => item.id === updateEstimateData.estimateProducts.processSelect?.id
          );
          if (processSelect?.length > 0) {
            updateEstimateData.estimateProducts.processSelect = processSelect[0];
          } else {
            // IQX_WEBEST-252 表面処理の処理数を増やした時に工程入力や工程積算が連動されない
            // 新パラ情報により親工程の空欄パターン情報更新
            let parentSelect = newParameters.processPattern?.filter(
              (item) => item?.class === ServiceClass.Parent && item?.info?.isUsed
            );
            let currentProcessPattern = { ...updateEstimateData.estimateProducts.processSelect };
            currentProcessPattern?.details?.map((item) => {
              let updateSelectPattern = parentSelect?.[0]?.details?.filter(
                (parItem) =>
                  parItem.processId === item.processId && // IQX_WEBEST-336 「CURT.パラ」実行で親部品、追加工程の選択装置やチェックON/OFF状態が変更されてしまう等の不具合
                  parItem.multiprocesses[0]?.processDetailTypeId === item.multiprocesses[0]?.processDetailTypeId
              );
              if (updateSelectPattern?.length > 0) {
                updateSelectPattern[0]?.multiprocesses?.map((upItem) => {
                  let currentProcess = item.multiprocesses?.filter((multi) => upItem.processNo === multi.processNo);
                  if (currentProcess?.length > 0) {
                    upItem.processDetailGroup = currentProcess[0].processDetailGroup;
                    upItem.processDetailTypeId = currentProcess[0].processDetailTypeId;
                    upItem.processDetailTypeName = currentProcess[0].processDetailTypeName;
                  }
                });
                item.multiprocesses = updateSelectPattern[0]?.multiprocesses;
              }
            });
            updateEstimateData.estimateProducts.processSelect = currentProcessPattern;
          }
          // IQX_WEBEST-252 表面処理の処理数を増やした時に工程入力や工程積算が連動されない
          // 新パラ情報により板金工程パターン情報更新
          updateEstimateData?.esIq3Info?.map((iq3) => {
            let iq3ProcessSelect = newParameters.processPattern?.filter((item) => item.id === iq3.processSelect?.id);
            // 新パラ情報により板金工程の存在パターン情報更新
            if (iq3ProcessSelect?.length > 0) {
              iq3.processSelect = JSON.parse(JSON.stringify(iq3ProcessSelect[0]));
            } else {
              // 新パラ情報により板金工程の空欄パターン情報更新
              let iq3Select = newParameters.processPattern?.filter(
                (item) => item?.class === ServiceClass.SheetMetal && item?.info?.isUsed
              );
              iq3?.processSelect?.details?.map((item) => {
                let updateIQ3SelectPattern = iq3Select?.[0]?.details?.filter(
                  (parItem) =>
                    parItem.processId === item.processId && // IQX_WEBEST-336 「CURT.パラ」実行で親部品、追加工程の選択装置やチェックON/OFF状態が変更されてしまう等の不具合
                    parItem.multiprocesses[0]?.processDetailTypeId === item.multiprocesses[0]?.processDetailTypeId
                );
                if (updateIQ3SelectPattern?.length > 0) {
                  updateIQ3SelectPattern[0]?.multiprocesses?.map((upItem) => {
                    let currentProcess = item.multiprocesses?.filter((multi) => upItem.processNo === multi.processNo);
                    if (currentProcess?.length > 0) {
                      upItem.processDetailGroup = currentProcess[0].processDetailGroup;
                      upItem.processDetailTypeId = currentProcess[0].processDetailTypeId;
                      upItem.processDetailTypeName = currentProcess[0].processDetailTypeName;
                    }
                  });
                  item.multiprocesses = JSON.parse(JSON.stringify(updateIQ3SelectPattern[0]?.multiprocesses));
                }
              });
            }
          });
        }
        if (isPurchaseParam) {
          // 購入品
          updateEstimateData.estimateProducts.calcParameters.parameters.purchase = newParameters.purchase;
          updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory =
            newParameters.purchaseCategory;
          updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
            newParameters.purchaseAllocation;
        }
        if (isMaterialParam) {
          // 材料系（材質区分、材質名称、材料名称・重量単価）
          // 板金パラメータ情報
          updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = newParameters.materialIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 = newParameters.materialSizeIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
            newParameters.materialSurfaceIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialType = newParameters.materialType;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 = newParameters.materialTypeIq3;
          // 形鋼パラメータ情報
          updateEstimateData.estimateProducts.calcParameters.parameters.steelFixedSize = newParameters.steelFixedSize;
          updateEstimateData.estimateProducts.calcParameters.parameters.steelShape = newParameters.steelShape;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq5 = newParameters.materialTypeIq5;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialIq5 = newParameters.materialIq5;
        }
        setSelectedEstimateData(updateEstimateData);
      }
    }
  };

  useEffect(() => {
    if (reCalBtnEvent) {
      setTimeout(() => {
        setIsEstimateOpen(false); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
        // IQX_WEBEST-171
        setLoading(false); // Waitingダイアログを非表示にする
        let calEstimateData = updateEstimateInfoByReCal(selectedEstimateData, false); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
        //親部品の基本情報更新
        mainEstimateRef?.current
          ?.getParentEstimateRef()
          ?.current?.getParentEstimateDetailRef()
          ?.current?.updateParentEstimateDetail(calEstimateData);
        mainEstimateRef?.current?.updateEstimateInfo(calEstimateData);
        setSelectedEstimateData(calEstimateData);
        setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(calEstimateData)));
        setReCalBtnEvent(false);
        setIsRecal(false);
      }, 100);
    }
  }, [reCalBtnEvent]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false); // Waitingダイアログを非表示にする
      }, 5000);
    }
  }, [loading]);

  const settingParametersByReCal = async (
    mode,
    isMaterialParam = true,
    isProcessParam = true,
    isPurchaseParam = true,
    isOtherParam = true
  ) => {
    setLoading(true); // Waitingダイアログを表示にする
    let updateEstimateData = selectedEstimateData;
    if (updateEstimateData != undefined && updateEstimateData?.estimateProducts?.calcParameters) {
      if (mode == ParamCalType.NewParam) {
        await getBackendPrepareData(updateEstimateData, isMaterialParam, isProcessParam, isPurchaseParam, isOtherParam);
      } else {
        // パネルの再計算ボタンを押した場合、CURT.パラで再計算
        let oldParameters = updateEstimateData?.estimateProducts?.calcParameters?.parameters;
        let oldSettings = updateEstimateData?.estimateProducts?.calcParameters?.settings;
        if (oldParameters) {
          getProjectStatusData(oldParameters.estimateStatus);
          getLoginCompanyInfo(oldParameters.company);
          getStaffListInfo(oldParameters.staff);
          getItakusakiInfo(oldParameters.client);
          getProcessMstInfo(oldParameters.process);
          if (oldSettings) {
            getCompanySettingInfo(oldSettings?.companySettings);
            getUserSettingInfo(oldSettings?.userSettings);
          }
          getPurchaseCategoryInfo(oldParameters.purchaseCategory);
          if (updateEstimateData) {
            updateEstimateData.estimateProducts.calcParameters.parameters.company = oldParameters.company;
            updateEstimateData.estimateProducts.calcParameters.parameters.staff = oldParameters.staff;
            updateEstimateData.estimateProducts.calcParameters.parameters.client = oldParameters.client;
            updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = oldParameters.estimateStatus;
            updateEstimateData.estimateProducts.calcParameters.parameters.process = oldParameters.process;
            updateEstimateData.estimateProducts.calcParameters.parameters.device = oldParameters.device;
            let processSelect = oldParameters.processPattern?.filter(
              (item) => item.id === updateEstimateData.estimateProducts.processSelect[0]?.id
            );
            if (processSelect?.length > 0) updateEstimateData.estimateProducts.processSelect = processSelect[0];
            updateEstimateData.estimateProducts.calcParameters.parameters.purchase = oldParameters.purchase;
            updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory =
              oldParameters.purchaseCategory;
            updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
              oldParameters.purchaseAllocation;
            updateEstimateData.estimateProducts.calcParameters.settings.userSettings = oldSettings?.userSettings;
            updateEstimateData.estimateProducts.calcParameters.settings.companySettings = oldSettings?.companySettings;
            //板金パラメータ情報
            updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = oldParameters.materialIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 =
              oldParameters.materialSizeIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
              oldParameters.materialSurfaceIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialType = oldParameters.materialType;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 =
              oldParameters.materialTypeIq3;
            setSelectedEstimateData(updateEstimateData);
            setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(updateEstimateData)));
          }
        }
      }
      updateIsRecalMaterial(true);
      setIsRecal(true);
      setReCalBtnEvent(true);
    }
  };

  // 再計算フラグを更新
  const updateIsRecal = (result) => {
    setIsRecal(result);
  };

  // 材料詳細の再計算フラグを更新
  const updateIsRecalMaterial = (result) => {
    setIsRecalMaterial(result);
  };

  const getItakusakiInfo = (result) => {
    result = result?.filter((record) => isCustomer(record.clientType) && record.info.isUsed);
    if (result) setClientInfo(result?.length > 0 ? result : []);
  };

  // 案件状態データ
  const getProjectStatusData = (result) => {
    setOrderOptions(result?.filter((item) => item.info.isUsed));
  };

  // ログインしているユーザーの会社情報を取得する
  const getLoginCompanyInfo = (result) => {
    setCompanyInfo(result);
  };

  // 担当者情報を取得する
  const getStaffListInfo = (result) => {
    setStaffList(result?.filter((item) => item.info.isUsed));
  };

  // ユーザー情報を取得する
  const getLoginUserInfo = (result) => {
    setUserInfo(result);
  };

  // 工程マスタの情報を取得する
  const getProcessMstInfo = (result) => {
    setProcessInfo(result);
  };

  const getCompanySettingInfo = async (result) => {
    if (result === '') {
      setCompanySettingInfo(await getExistCompanySetting());
    } else {
      setCompanySettingInfo([result]);
    }
  };

  const getUserSettingInfo = async (result) => {
    if (result === '') {
      setUserSettingInfo(await getExistUserSetting());
    } else {
      setUserSettingInfo(result);
    }
  };

  const getPurchaseCategoryInfo = (result) => {
    setPurchaseCategoryInfo(result);
  };

  const updateParamSelected = (param) => {
    setParamType(param);
    let textMenu;
    switch (param) {
      case '共通':
        textMenu = '・共通';
        break;
      case 'parent':
        textMenu = '・親部品';
        break;
      case 'iq3':
        textMenu = '・iQ3';
        break;
    }
    if (param === '共通') {
      setParamType('common');
    }
    let menus = menuContent?.current?.menu?.list?.children;
    let ul = menuContent?.current?.menu?.list;
    if (ul.classList.contains('paramMenu')) {
      ul.classList?.remove('paramMenu');
      ul.classList.add('paramMenu-active');
    }
    [...menus]?.forEach((menu) => {
      if (menu.innerText?.includes(textMenu)) {
        menu.classList.add('ant-menu-submenu-active');
        menu.classList.add('ant-menu-submenu-selected');
      } else {
        menu.classList?.remove('ant-menu-submenu-active');
        menu.classList?.remove('ant-menu-submenu-selected');
      }
    });
  };

  const updateCurrentParamSelected = (paramSelected) => {
    setCurrentParamSelected(paramSelected);
  };

  const updateParamSelected1 = (paramSelected1) => {
    setParamSelected1(paramSelected1);
  };

  const updateParamSelected2 = (paramSelected2) => {
    setParamSelected2(paramSelected2);
  };

  const updateSelectedRowId = (selectedRowId, rowSelectTbl, rowSelectPage, ascSortFlg, sortNm, filterDatas) => {
    setSelectedRowId(selectedRowId);
    setRowSelectTbl(rowSelectTbl);
    setRowSelectPage(rowSelectPage);
    setAscSortFlg(ascSortFlg);
    setSortNm(sortNm);
    setFilterDatas(filterDatas);
  };

  // 画面切り替えする
  const discardConfirmOk = () => {
    setEstimateEditMode(false);
    setEditModeCancelConfirm(false);

    if (routeType === 1) {
      navigatePage(event, pageName, partName);
    } else if (routeType === 2) {
      paramChangeEvent(event);
    } else {
      handleClick(event);
    }
  };

  // 画面切り替えしない
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    // キャンセルボタン押下でスピナー画面を閉じる
    setLoading(false); // Waitingダイアログを非表示にする
  };

  // 編集中の場合、画面切替警告メッセージポップアップを表示
  const isEditingMode = (e, type, page, part) => {
    setNavigatePageName('customer');
    setEvent(e);
    setRouteType(type);
    setPageName(page);
    setPartName(part);
    if (estimateEditMode || paramRef?.current?.getCurrentEditMode()) {
      let edtModeCancel =
        userSettingInfo[0]?.massages?.moveInEdit === undefined ? true : userSettingInfo[0]?.massages?.moveInEdit;
      setEditModeCancelConfirm(edtModeCancel);
      if (!edtModeCancel) {
        return false;
      }
      return true;
    }
    return false;
  };

  // 見積画面から、画面切り替えの場合
  const changeScreenEvent = async (e, page) => {
    setLoading(true); // Waitingダイアログを表示にする
    setNavigatePageName(page);
    await getUserSettingInfo('');
    await getCompanySettingInfo('');
    setIsMenuClickByUserSetting(false);
    setParentListClick(false);
    setIsNewModeByParent(false);
    if (
      location.pathname === '/home/:estimate.parent' ||
      location.pathname === '/home/:estimate.iq3' ||
      location.pathname === '/home/:estimate.iq5'
    ) {
      setNavigatePageName(page);
      if (page === 'parentList') {
        setParentListClick(true);
        setActiveTab('1');
        navigatePage(e, ':parentList', 'parentList');
      } else if (page === 'parent') {
        setLoading(false); // (IQX_WEBEST-222)メニューの見積データを選択中で、見積データを押下した場合、Waitingダイアログを非表示にする
        setActiveTab('1');
        navigatePage(e, ':estimate.parent', 'parent');
      } else if (page === 'sheetMetal') {
        setLoading(false); // (IQX_WEBEST-222)メニューの見積データを選択中で、見積データを押下した場合、Waitingダイアログを非表示にする
        setActiveTab('2');
        navigatePage(e, ':estimate.iq3', 'iq3');
      } else if (page === 'beams') {
        setLoading(false); // (IQX_WEBEST-222)メニューの見積データを選択中で、見積データを押下した場合、Waitingダイアログを非表示にする
        setActiveTab('2');
        navigatePage(e, ':estimate.iq5', 'iq5');
      } else {
        // setActiveTab('1');  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
        paramChangeEvent();
        setLoading(false); // Waitingダイアログを非表示にする
      }
    } else if (
      location.pathname.indexOf('/home/:common.') > -1 ||
      location.pathname.indexOf('/home/:parent.') > -1 ||
      location.pathname.indexOf('/home/:iq3.') > -1
    ) {
      setNavigatePageName(page);
      if (page === 'parentList') {
        if (!isEditingMode(e, 1, ':parentList', 'parentList')) {
          setParentListClick(true);
          setActiveTab('1');
          navigatePage(e, ':parentList', 'parentList');
        }
        // !isEditingMode(e, 1, ':parentList', 'parentList');
      } else if (page === 'parent') {
        if (!isEditingMode(e, 1, ':estimate.parent', 'parent')) {
          // setActiveTab('1');  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
          navigatePage(e, ':estimate.parent', 'parent');
        }
      } else if (page === 'sheetMetal') {
        if (!isEditingMode(e, 1, ':estimate.iq3', 'iq3')) {
          setActiveTab('2');
          navigatePage(e, ':estimate.iq3', 'iq3');
        }
      } else if (page === 'beams') {
        if (!isEditingMode(e, 1, ':estimate.iq5', 'iq5')) {
          setActiveTab('3');
          navigatePage(e, ':estimate.iq5', 'iq5');
        }
      } else {
        !isEditingMode(e, 2, '', '');
        setLoading(false); // Waitingダイアログを非表示にする
      }
    } else if (location.pathname === '/home/:userSetting') {
      setIsMenuClickByUserSetting(false);
      //ユーザー設定データに変更があった場合のみ表示
      let diff = userSettingRef?.current?.isChangeSetting();
      if (diff) {
        if (Object.keys(diff).length > 0) {
          setActiveTab('1');
          setIsMenuClickByUserSetting(true);
          userSettingRef?.current?.updateSaveDiscardConfirmPopup();
        } else {
          if (page === 'parentList') {
            setParentListClick(true);
            setActiveTab('1');
            navigatePage(e, ':parentList', 'parentList');
          } else if (page === 'parent') {
            setActiveTab('1');
            navigatePage(e, ':estimate.parent', 'parent');
          } else if (page === 'sheetMetal') {
            setActiveTab('2');
            navigatePage(e, ':estimate.iq3', 'iq3');
          } else if (page === 'beams') {
            setActiveTab('3');
            navigatePage(e, ':estimate.iq5', 'iq5');
          } else {
            setActiveTab('1');
            paramChangeEvent();
          }
        }
      } else {
        if (page === 'parentList') {
          setParentListClick(true);
          setActiveTab('1');
          navigatePage(e, ':parentList', 'parentList');
        } else if (page === 'parent') {
          setActiveTab('1');
          navigatePage(e, ':estimate.parent', 'parent');
        } else if (page === 'sheetMetal') {
          setActiveTab('2');
          navigatePage(e, ':estimate.iq3', 'iq3');
        } else if (page === 'beams') {
          setActiveTab('3');
          navigatePage(e, ':estimate.iq5', 'iq5');
        } else {
          setActiveTab('1');
          paramChangeEvent();
        }
      }
    } else {
      if (page === 'parentList') {
        setLoading(false); // (IQX_WEBEST-222)メニューの親部品リストを選択中で、親部品リストを押下した場合、Waitingダイアログを非表示にする
        setParentListClick(true);
        setActiveTab('1');
        navigatePage(e, ':parentList', 'parentList');
      } else if (page === 'parent') {
        setActiveTab('1');
        navigatePage(e, ':estimate.parent', 'parent');
      } else if (page === 'sheetMetal') {
        setActiveTab('2');
        navigatePage(e, ':estimate.iq3', 'iq3');
      } else if (page === 'beams') {
        setActiveTab('3');
        navigatePage(e, ':estimate.iq5', 'iq5');
      } else {
        setActiveTab('1');
        paramChangeEvent();
      }
    }
  };

  const paramChangeEvent = async (e) => {
    let paramSelected = currentParamSelected !== '' ? currentParamSelected : 'customer';
    setParamSelected(paramSelected);
    await getUserSettingInfo('');
    await getCompanySettingInfo('');
    setIsMenuClickByUserSetting(false);
    setLoading(false); // Waitingダイアログを非表示にする
    navigateParameterRoute(paramSelected);
  };

  // パラメータ画面切り替え
  const navigateParameterRoute = (e) => {
    if (e == 'customer') {
      navigate(':common.customer');
    } else if (e == 'personInCharge') {
      navigate(':common.personInCharge');
    } else if (e == 'purchase') {
      navigate(':common.purchase');
    } else if (e == 'coefficient') {
      navigate(':common.coefficient');
    } else if (e == 'additional') {
      navigate(':common.additional');
    } else if (e == 'project') {
      navigate(':common.project');
    } else if (e == 'processMaster') {
      navigate(':common.processMaster');
    } else if (e == 'materialClass') {
      navigate(':common.materialClass');
    } else if (e == 'autoReserve') {
      navigate(':common.autoReserve');
    } else if (e == 'oya_patternProcess') {
      navigate(':parent.patternProcess');
    } else if (e == 'oya_processMaster') {
      navigate(':parent.processMaster');
    } else if (e == 'oya_chargeProcess') {
      navigate(':parent.device');
    } else if (e == 'iq3_chargeProcess') {
      navigate(':iq3.device');
    } else if (e == 'iq3_patternProcess') {
      navigate(':iq3.patternProcess');
    } else if (e == 'iq3_materialClassName') {
      navigate(':iq3.materialClassName');
    } else if (e == 'iq3_materialName') {
      navigate(':iq3.materialName');
    } else if (e == 'iq3_materialSurface') {
      navigate(':iq3.materialSurface');
    } else if (e == 'iq3_materialSize') {
      navigate(':iq3.materialSize');
    } else if (e == 'iq3_processMaster') {
      navigate(':iq3.processMaster');
    } else if (e == 'materialClass') {
      navigate(':common.materialClass');
    } else if (e == 'processMaster') {
      navigate(':common.processMaster');
    } else {
      navigate(':common.customer');
    }
  };

  const navigatePage = async (e, pageNm, partNm) => {
    setParamType('');
    setParamSelected('');
    setIsMenuClickByUserSetting(false);
    if (partNm === 'parent' || partNm === 'iq3') {
      await goToParentScreen(pageNm, partNm);
    } else if (partNm === 'parentList') {
      // await getBackendPrepareData([], true, true, true, true);
      setPartNm(partNm);
      navigate(pageNm);
    }
  };

  // サイドメニューの親部品作成ボタン押下で作成画面に遷移する
  const goToParentScreen = async (pageNm, partNm) => {
    // カレント見積がない場合、新規情報を設定して親部品画面に移動
    if (selectedEstimateData === undefined || selectedEstimateData?.length === 0 || isNewModeByParent) {
      let newParameters = await GetParametersByCompId();
      let newParentInfo = getNewParentInfo(partNm, newParameters);
      setSelectedEstimateData(newParentInfo);
      setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(newParentInfo)));
      setNewMode(true);
      setCreateNew(true);
      setCreateNewEvent(true);
      setIsEstimateOpen(false); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
    }
    setPartNm(partNm);
    navigate(pageNm);
    // setParentActiveTabKey('1'); // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
  };

  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  const updateNewMode = (mode) => {
    setNewMode(mode);
  };

  const updateNewModeByParent = (mode) => {
    setParentListClick(false);
    // "新規作成"コマンドを開いた時にメッセージを表示
    //見積データに変更があった場合のみ表示
    setIsNewModeByParent(true);
    setSaveDiscardConfirmPopup(true);
  };

  // 親部品リストから呼び出した場合
  const getSelectedEstimateData = (estimateData, estimateEditMode, currentPage) => {
    setParentActiveTabKey('1');
    setParentListClick(false);
    setIsNewModeByParent(false);
    setActiveTab('1');
    setEstimateEditMode(estimateEditMode);
    setCurrentParentListPageBk(currentPage);
    //　呼び出した見積が現在開いている見積と同じかチェック
    if (estimateData?.estimateProducts?.id !== selectedEstimateData?.estimateProducts?.id) {
      //　他の見積データを開いたときのメッセージ表示
      setSaveDiscardConfirmPopup(true);
      setSelectedEstimateDataByParentLst(estimateData);
    } else {
      // 呼び出した見積データのIDを保持　// IQX_WEBEST-351 ログインからログアウトまでの間で、親部品リストから呼び出されたデータの行の背景色を設定する。
      if (openDetailInfo) {
        let currentOpenDetailInfo = {
          id: estimateData?.estimateProducts?.id,
        };
        let openDetailInfoCpy = [...openDetailInfo];
        if (
          openDetailInfoCpy?.length === 0 ||
          !openDetailInfoCpy?.some((item) => item.id === estimateData?.estimateProducts?.id)
        ) {
          openDetailInfoCpy.push(currentOpenDetailInfo);
          setOpenDetailInfo(openDetailInfoCpy);
        }
      }
      setCurrentParentRecordId(estimateData?.estimateProducts?.id);
      setCurrentParentListPage(currentPage);
      setIsEstimateOpen(true); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
      // 同じ見積データを開いた時にはメッセージを表示されず、現在見積に上書きして表示。
      setSelectedEstimateData(estimateData);
      setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(estimateData)));
      setPartNm('parent');
      navigate(':estimate.parent');
      setParentActiveTabKey('1');
    }
  };

  const updateEstimateEditMode = (mode) => {
    setEstimateEditMode(mode);
  };

  const updateSelectedEstimateData = (result) => {
    setSelectedEstimateData(result);
    setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(result)));
  };

  const updateSelectedEstimateDataByPMX = (result) => {
    setSelectedEstimateData(result);
    setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(result)));
  };

  // Waitingダイアログを表示・非表示に更新する
  const updateLoading = (load) => {
    setLoading(load);
  };

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品」に更新する
  const updateParentActiveTabKey = (key) => {
    setParentActiveTabKey(key);
  };

  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応-START
  // アクティブなTabキー「親部品、板金」に更新する
  const updateActiveTabKey = (key) => {
    setActiveTab(key);
  };

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品、材料詳細」に更新する
  const updateIq3ActiveTabKey = (key) => {
    setIq3ActiveTabKey(key);
  };
  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応-END

  // 親部品見積新規作成の初期値を作成する
  const getNewParentInfo = (partNm, newParameters) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    let costLst = [];
    let labelLst = [];
    let memoLst = [];
    let dateLst = [];
    let clientInfo = newParameters.client;
    let companyInfo = newParameters.company;
    let processInfo = newParameters.process;
    let deviceMasterInfo = newParameters.device;
    let processPattern = newParameters.processPattern;
    let estimateStatus = newParameters.estimateStatus;
    let purchaseAllocation = newParameters.purchaseAllocation;
    let purchaseCategoryInfo = newParameters.purchaseCategory;
    let purchasesData = newParameters.purchase;
    let staffList = newParameters.staff;
    let selectedStaff = staffList?.filter((i) => i?.info?.isUsed);
    let userSettingData = newParameters.userSettings;
    let companySettingData = newParameters.companySettings;
    //板金パラメータ情報
    let materialIq3 = newParameters.materialIq3;
    let materialSizeIq3 = newParameters.materialSizeIq3;
    let materialSurfaceIq3 = newParameters.materialSurfaceIq3;
    let materialType = newParameters.materialType;
    let materialTypeIq3 = newParameters.materialTypeIq3;

    let clientData = clientInfo?.filter((i) => !i.isDeleted && i?.info?.isUsed);
    let client = clientData?.length > 0 ? clientData[0] : {};
    if (companyInfo?.length > 0) {
      // orderCoeffs = companyInfo[0].coeffOrders;
      orderCoeffs = companyInfo?.[0]?.coeffOrders?.filter((i) => !i.isDeleted && i.isUsed);
      deliDateCoeffs = companyInfo?.[0]?.coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      purCoeffs = companyInfo?.[0]?.coeffPurchases?.filter((i) => !i.isDeleted && i.isUsed);
      mngCoeffs = companyInfo?.[0]?.coeffManagements?.filter((i) => !i.isDeleted && i.isUsed);

      costLst = companyInfo?.[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemCost(item.id, item.code, item.name);
        });

      labelLst = companyInfo?.[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemLabel(item.id, item.code, item.name);
        });

      memoLst = companyInfo?.[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemMemo(item.id, item.code, item.name);
        });

      dateLst = companyInfo?.[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemDate(item.id, item.code, item.name);
        });
    }
    let kouteiPatternList = processPattern?.filter(
      (item) => item.id === userSettingData?.[0]?.users?.parentProcessPatternId
    );
    // IQX_WEBEST-323 新規処理の前回値の採用
    if (
      userSettingData?.length > 0 &&
      userSettingData?.[0].users.newParentInitialInfo.parentProcessPatternId !== undefined
    ) {
      let userSettingProcessPattern = processPattern?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.parentProcessPatternId
      );
      kouteiPatternList = userSettingProcessPattern?.length > 0 ? userSettingProcessPattern : kouteiPatternList;
    }
    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = processPattern?.filter((item) => item.class === ServiceClass.Parent);
    }

    let parameters = {
      company: companyInfo,
      client: clientInfo,
      process: processInfo,
      device: deviceMasterInfo,
      processPattern: processPattern,
      estimateStatus: estimateStatus,
      purchaseAllocation: purchaseAllocation,
      purchaseCategory: purchaseCategoryInfo,
      purchase: purchasesData,
      staff: staffList,
      materialIq3: materialIq3,
      materialSizeIq3: materialSizeIq3,
      materialSurfaceIq3: materialSurfaceIq3,
      materialType: materialType,
      materialTypeIq3: materialTypeIq3,
      steelFixedSize: newParameters.steelFixedSize,
      steelShape: newParameters.steelShape,
      materialTypeIq5: newParameters.materialTypeIq5,
      materialIq5: newParameters.materialIq5,
    };

    let settings = {
      userSettings: userSettingData,
      companySettings: companySettingData,
    };

    //表面処理初期設定
    const surfaceDetails = processInfo?.filter((i) => i.workType === WorkType.PaSurface);
    let surfaceId = surfaceDetails?.[0]?.id;
    let surface = kouteiPatternList?.[0]?.details?.filter((i) => i.processId === surfaceId);
    let surfaceDataFromSelect = [];
    if (surface != undefined) {
      surfaceDataFromSelect = surface?.[0]?.multiprocesses?.filter(
        (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
      );
    }
    const surfaceDeviceDetails = deviceMasterInfo?.filter((i) => i.id === surface?.[0]?.deviceId)?.[0]?.details;
    const surfaceInfo = surfaceDataFromSelect?.map((item1) => {
      const unit = surfaceDeviceDetails?.charges[item1?.processDetailTypeId]?.unit; // IQX_WEBEST-226
      return {
        no: item1.processNo,
        calcType: unit !== undefined ? unit : 1, // IQX_WEBEST-226
        processDetailWorkTypeGroup: item1?.processDetailGroup,
        processDetailTypeNo: item1?.processDetailTypeId,
        areaUnitPrice: toDecimal(0, 2),
        weightUnitPrice: toDecimal(0, 2),
        weightCount: 1,
        weightCountAutoExtract: 1,
        areaCount: 1,
        areaCountAutoExtract: 1,
        areaAutoExtract: 0,
        weightAutoExtract: 0,
        totalArea: toDecimal(0, 2) * 1,
        totalWeight: toDecimal(0, 2) * 1,
      };
    });

    //親部品工程積算情報設定
    let kouteiCalArr = [];
    let processSelectDetails = kouteiPatternList?.[0]?.details;
    if (processSelectDetails) {
      processSelectDetails?.map((item, index) => {
        const processInfoFilter = processInfo?.filter((i) => i.id === item.processId && item.isUsedForCalc)?.[0] || [];
        if (Object.keys(processInfoFilter)?.length > 0) {
          let dataItems = [];
          if (item.multiprocesses?.length > 0) {
            for (let m = 0; m < item.multiprocesses?.length; m++) {
              let dataItem = {
                id: m + 1,
                processName: processInfoFilter?.name,
                processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
                dandoriAmt: 0,
                dandoriTime: 0,
                sagyoAmt: 0,
                sagyoTime: 0,
                processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
                kasho: 1,
                isCommission: false,
                supplier: '',
                supplierCode: '',
                formula: '',
              };
              dataItems.push(dataItem);
            }
          }
          let addition = {
            no: kouteiCalArr?.length === 0 ? 1 : kouteiCalArr?.length + 1,
            processId: item?.processId,
            dataType: DataType.Data,
            dataItems: dataItems,
            editItems: [],
            totalDandori: {
              dataItemsAmt: 0,
              dataItemsTime: 0,
              editItemsAmt: 0,
              editItemsTime: 0,
            },
            totalSagyo: {
              dataItemsAmt: 0,
              dataItemsTime: 0,
              editItemsAmt: 0,
              editItemsTime: 0,
            },
            totalDataPrice: 0,
            totalDataTime: 0,
            totalEditPrice: 0,
            totalEditTime: 0,
            editDataFlg: false,
          };
          kouteiCalArr.push(addition);
        }
      });
    }

    //IQX_WEBEST-323 新規処理の前回値の採用
    // お客様名・お客様係数
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newParentInitialInfo.clientId !== undefined) {
      let userSettingClient = clientData?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.clientId
      );
      client = userSettingClient?.length > 0 ? userSettingClient[0] : client;
    }
    // 案件状態
    let estimateStatusId = estimateStatus?.length > 0 ? estimateStatus?.[0].id : '';
    let estimateStatusNm = estimateStatus?.length > 0 ? estimateStatus?.[0].name : '';
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newParentInitialInfo.estimateStatusId !== undefined) {
      let userSettingStatus = estimateStatus?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.estimateStatusId
      );
      estimateStatusId = userSettingStatus?.length > 0 ? userSettingStatus[0]?.id : estimateStatusId;
      estimateStatusNm = userSettingStatus?.length > 0 ? userSettingStatus[0]?.name : estimateStatusNm;
    }
    // 受注形態
    let coeffOrdersId = orderCoeffs?.length > 0 ? orderCoeffs?.[0].id : '';
    let coeffOrdersName = orderCoeffs?.length > 0 ? orderCoeffs?.[0].name : '';
    let coeffOrders = orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0].coeff, 2) : toDecimal(0, 2);
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newParentInitialInfo.coeffOrdersId !== undefined) {
      let userSettingOrder = orderCoeffs?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.coeffOrdersId
      );
      coeffOrdersId = userSettingOrder?.length > 0 ? userSettingOrder[0]?.id : coeffOrdersId;
      coeffOrdersName = userSettingOrder?.length > 0 ? userSettingOrder[0]?.name : coeffOrdersName;
      coeffOrders = userSettingOrder?.length > 0 ? toDecimal(userSettingOrder?.[0].coeff, 2) : coeffOrders;
    }
    // 納期形態
    let coeffDeliveryDatesId = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].id : '';
    let coeffDeliveryDatesName = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].name : '';
    let coeffDeliveryDates = deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0].coeff, 2) : toDecimal(0, 2);
    if (
      userSettingData?.length > 0 &&
      userSettingData?.[0].users.newParentInitialInfo.coeffDeliveryDatesId !== undefined
    ) {
      let userSettingDelivery = deliDateCoeffs?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.coeffDeliveryDatesId
      );
      coeffDeliveryDatesId = userSettingDelivery?.length > 0 ? userSettingDelivery[0]?.id : coeffDeliveryDatesId;
      coeffDeliveryDatesName = userSettingDelivery?.length > 0 ? userSettingDelivery[0]?.name : coeffDeliveryDatesName;
      coeffDeliveryDates =
        userSettingDelivery?.length > 0 ? toDecimal(userSettingDelivery?.[0].coeff, 2) : coeffDeliveryDates;
    }
    // 購入品
    let coeffPurchasesId = purCoeffs?.length > 0 ? purCoeffs?.[0].id : '';
    let coeffPurchasesName = purCoeffs?.length > 0 ? purCoeffs?.[0].name : '';
    let coeffPurchases = purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0].coeff, 2) : toDecimal(0, 2);
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newParentInitialInfo.coeffPurchasesId !== undefined) {
      let userSettingPurchase = purCoeffs?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.coeffPurchasesId
      );
      coeffPurchasesId = userSettingPurchase?.length > 0 ? userSettingPurchase[0]?.id : coeffPurchasesId;
      coeffPurchasesName = userSettingPurchase?.length > 0 ? userSettingPurchase[0]?.name : coeffPurchasesName;
      coeffPurchases = userSettingPurchase?.length > 0 ? toDecimal(userSettingPurchase?.[0].coeff, 2) : coeffPurchases;
    }
    // 管理方法
    let coeffManagementsId = mngCoeffs?.length > 0 ? mngCoeffs?.[0].id : '';
    let coeffManagementsName = mngCoeffs?.length > 0 ? mngCoeffs?.[0].name : '';
    let coeffManagements = mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0].coeff, 2) : toDecimal(0, 2);
    if (
      userSettingData?.length > 0 &&
      userSettingData?.[0].users.newParentInitialInfo.coeffManagementsId !== undefined
    ) {
      let userSettingMng = mngCoeffs?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.coeffManagementsId
      );
      coeffManagementsId = userSettingMng?.length > 0 ? userSettingMng[0]?.id : coeffManagementsId;
      coeffManagementsName = userSettingMng?.length > 0 ? userSettingMng[0]?.name : coeffManagementsName;
      coeffManagements = userSettingMng?.length > 0 ? toDecimal(userSettingMng?.[0].coeff, 2) : coeffManagements;
    }
    // 作成者
    let createrId =
      userSettingData?.length > 0
        ? userSettingData?.[0]?.users?.staffId
        : selectedStaff?.length > 0
        ? selectedStaff?.[0]?.id
        : '';
    let creater =
      userSettingData?.length > 0
        ? userSettingData[0]?.users?.nickName
        : selectedStaff?.length > 0
        ? selectedStaff[0]?.name
        : '';
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newParentInitialInfo.modifierId !== undefined) {
      let userSettingModifier = selectedStaff?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.modifierId
      );
      createrId = userSettingModifier?.length > 0 ? userSettingModifier[0]?.id : createrId;
      creater = userSettingModifier?.length > 0 ? userSettingModifier[0]?.name : creater;
    }
    // 最終更新者
    let modifierId =
      userSettingData?.length > 0
        ? userSettingData?.[0]?.users?.staffId
        : selectedStaff?.length > 0
        ? selectedStaff?.[0]?.id
        : '';
    let modifier =
      userSettingData?.length > 0
        ? userSettingData?.[0]?.users?.nickName
        : selectedStaff?.length > 0
        ? selectedStaff?.[0]?.name
        : '';
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newParentInitialInfo.modifierId !== undefined) {
      let userSettingModifier = selectedStaff?.filter(
        (i) => i.id === userSettingData?.[0].users.newParentInitialInfo.modifierId
      );
      modifierId = userSettingModifier?.length > 0 ? userSettingModifier[0]?.id : modifierId;
      modifier = userSettingModifier?.length > 0 ? userSettingModifier[0]?.name : modifier;
    }

    return {
      ...estimateInitInfo,
      estimateProducts: {
        ...estimateInitInfo.estimateProducts,
        modifierId: modifierId,
        modifier: modifier,
        drawingNo: 'Product-' + formatDateString(todayTime()),
        name: 'Product-' + formatDateString(todayTime()),
        clientId: client ? client?.id : 0,
        clientName: client ? client?.name : '',
        clientCode: client ? client?.code : '',
        coeffOrdersId: coeffOrdersId,
        coeffOrdersName: coeffOrdersName,
        coeffDeliveryDatesId: coeffDeliveryDatesId,
        coeffDeliveryDatesName: coeffDeliveryDatesName,
        coeffPurchasesId: coeffPurchasesId,
        coeffPurchasesName: coeffPurchasesName,
        coeffManagementsId: coeffManagementsId,
        coeffManagementsName: coeffManagementsName,
        createrId: createrId,
        creater: creater,
        estimateNo: formatDateString(todayTime()),
        estimateStatusId: estimateStatusId,
        estimateStatus: estimateStatusNm,
        otherProduct: {
          adjustment: 0,
          adjustmentMethod: '+',
          coeffClient: client
            ? toDecimal(client?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff, 2)
            : toDecimal(0, 2),
          coeffOrders: coeffOrders,
          coeffDeliveryDates: coeffDeliveryDates,
          coeffPurchases: coeffPurchases,
          coeffManagements: coeffManagements,
        },
        processSelect: {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.modified : dayjs().format(),
        },
        processInput: {
          surfaceProcessInputInfo: surfaceInfo,
          assenbleProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            purchaseInstTime: 0,
            assyList: [{ no: 1, name: '', workTime: 0, workCount: 0, workTotalTime: 0 }],
            autoExtract: 1, // IQX_WEBEST-179
          },
          inspectionProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            autoExtract: 1, // IQX_WEBEST-179
          },
          packingProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            autoExtract: 1, // IQX_WEBEST-179
          },
        },
        additions: kouteiCalArr,
        purchases: [],
        byQuantities: JSON.parse(JSON.stringify(byQuantities)),
        calcParameters: {
          settings: settings,
          parameters: parameters,
        },
      },
      epCustomCosts: costLst,
      epCustomDates: dateLst,
      epCustomLabels: labelLst,
      epCustomMemos: memoLst,
    };
  };

  const cancelOk = () => {
    setSaveDiscardConfirmPopup(false);
    setIsNewModeByParent(false);
  };

  const closeOk = () => {
    setSaveDiscardConfirmPopup(false);
    setIsNewModeByParent(false);
  };

  // 画面切り替え、確認メッセージの保存ボタン押下
  const saveEstimateData = async () => {
    setSaveDiscardConfirmPopup(false);
    setLoading(true); // Waitingダイアログを表示にする
    let validation = mainEstimateRef?.current?.callFormValidation();
    if (!validation) {
      setLoading(false); // Waitingダイアログを非表示にする
      return;
    }

    // 親部品&板金
    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();

    // 同一図番があるか存在チェックする
    if (!isSaveSameDrawingNo && !companySettingInfo?.[0]?.environments?.isUseSameName) {
      let estProducts = parentEstimateData?.estimateProducts;
      let saveSameType = companySettingInfo?.[0]?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setLoading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('saveEstimateData');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    let createdData = await createEstimateProductInfo(parentEstimateData, false);
    if (createdData) {
      Modal.success({
        width: 465,
        className: 'modal-complete',
        title: '完了',
        content: (
          <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
            {'データ保存は正常に完了しました。'}
          </p>
        ),
        onOk: () => onRouteChange(),
      });
    }
    setLoading(false); // Waitingダイアログを非表示にする
  };

  // Routeを変更する
  const onRouteChange = async () => {
    setLoading(true);
    if (isNewModeByParent) {
      // 新規コマンドの場合、初期データ作成と設定
      let newParameters = await GetParametersByCompId();
      let newParentInfo = getNewParentInfo(partNm, newParameters);
      setSelectedEstimateData(newParentInfo);
      setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(newParentInfo)));
      setNewMode(true);
      setCreateNew(true);
      setCreateNewEvent(true);
      setActiveTab('1'); // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
      setIsEstimateOpen(false); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
    } else {
      // 親部品リストから呼び出した場合、選択したデータを設定
      if (selectedEstimateDataByParentLst) {
        setIsEstimateOpen(true); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
        setSelectedEstimateData(selectedEstimateDataByParentLst);
        setSelectedEstimateDataBeforeParentTab(JSON.parse(JSON.stringify(selectedEstimateDataByParentLst)));
        // 呼び出した見積データのIDを保持　// IQX_WEBEST-351 ログインからログアウトまでの間で、親部品リストから呼び出されたデータの行の背景色を設定する。
        if (openDetailInfo) {
          let currentOpenDetailInfo = {
            id: selectedEstimateDataByParentLst?.estimateProducts?.id,
          };
          let openDetailInfoCpy = [...openDetailInfo];
          if (
            openDetailInfoCpy?.length === 0 ||
            !openDetailInfoCpy?.some((item) => item.id === selectedEstimateDataByParentLst?.estimateProducts?.id)
          ) {
            openDetailInfoCpy.push(currentOpenDetailInfo);
            setOpenDetailInfo(openDetailInfoCpy);
          }
        }
        setCurrentParentRecordId(selectedEstimateDataByParentLst?.estimateProducts?.id);
        setCurrentParentListPage(currentParentListPageBk);
      }
    }
    setPartNm(partNm);
    navigate(':estimate.parent');
    setParentActiveTabKey('1');
  };

  const updateEstimateData = async () => {
    setSaveDiscardConfirmPopup(false);
    setLoading(true); // Waitingダイアログを表示にする
    let validation = mainEstimateRef?.current?.callFormValidation();
    if (!validation) {
      setLoading(false); // Waitingダイアログを非表示にする
      return;
    }
    // 親部品
    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();

    // 同一図番があるか存在チェックする
    if (!isSaveSameDrawingNo && !companySettingInfo?.[0]?.environments?.isUseSameName) {
      let estProducts = parentEstimateData?.estimateProducts;
      let saveSameType = companySettingInfo?.[0]?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setLoading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('updateEstimateData');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    let updatedData = await updateEstimateProductInfo(parentEstimateData, false);
    if (updatedData) {
      Modal.success({
        width: 465,
        className: 'modal-complete',
        title: '完了',
        content: (
          <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
            {'データ更新は正常に完了しました。'}
          </p>
        ),
        onOk: () => onRouteChange(),
      });
    }
    setLoading(false); // Waitingダイアログを非表示にする
  };

  const discardEstimateData = async (e) => {
    setSaveDiscardConfirmPopup(false);
    onRouteChange();
  };

  // 別名保存の入力フォーム
  const saveEstimateDataByOtherName = () => {
    setIsSaveByOtherName(true);
    // setDrawingNo(formatDateString(todayTime()));
    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
    const data = JSON.parse(JSON.stringify(parentEstimateData));
    setEstimateNo(data?.estimateProducts?.estimateNo + ' ' + formatDateString(todayTime())); // IQX_WEBEST-346 見積データ「別名保存」操作時の図番、名称、見積番号の初期値変更
    setDrawingNo(data?.estimateProducts?.drawingNo + ' ' + formatDateString(todayTime())); // IQX_WEBEST-346 見積データ「別名保存」操作時の図番、名称、見積番号の初期値変更
    setParentEstimateName(data?.estimateProducts?.name + ' ' + formatDateString(todayTime())); // IQX_WEBEST-346 見積データ「別名保存」操作時の図番、名称、見積番号の初期値変更
  };

  // 別名保存入力ボックスのOKボタン押下
  const saveByOtherNameOk = async () => {
    // 親部品
    setIsSaveByOtherName(false);
    setSaveDiscardConfirmPopup(false);
    setLoading(true); // Waitingダイアログを表示にする
    let validation = mainEstimateRef?.current?.callFormValidation();
    if (!validation) {
      setLoading(false); // Waitingダイアログを非表示にする
      return;
    }

    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
    const copyData = JSON.parse(JSON.stringify(parentEstimateData));

    copyData.estimateProducts = {
      ...copyData.estimateProducts,
      id: 0,
      estimateNo: estimateNo,
      drawingNo: drawingNo,
      name: parentEstimateName,
    };

    // 同一図番があるか存在チェックする
    if (!isSaveSameDrawingNo && !companySettingInfo?.[0]?.environments?.isUseSameName) {
      let estProducts = copyData.estimateProducts;
      let saveSameType = companySettingInfo?.[0]?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setLoading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('saveByOtherNameOk');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    copyData.epCustomCosts = copyData?.epCustomCosts?.map((cost) => {
      return { ...cost, id: 0 };
    });
    copyData.epCustomDates = copyData?.epCustomDates?.map((date) => {
      return { ...date, id: 0 };
    });
    copyData.epCustomLabels = copyData?.epCustomLabels?.map((label) => {
      return { ...label, id: 0 };
    });
    copyData.epCustomMemos = copyData?.epCustomMemos?.map((memo) => {
      return { ...memo, id: 0 };
    });

    copyData.esIq3Info = copyData?.esIq3Info?.map((item) => ({ ...item, id: 0 }));
    // IQX_WEBEST-348 「別名保存」されたデータの作成日と更新日
    copyData.estimateProducts.created = dayjs(today).format('YYYY-MM-DD');
    copyData.estimateProducts.modified = dayjs(today).format('YYYY-MM-DD');

    let createdData = await createEstimateProductInfo(copyData, false);

    if (createdData) {
      Modal.success({
        width: 465,
        className: 'modal-complete',
        title: '完了',
        content: (
          <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
            {'データ保存は正常に完了しました。'}
          </p>
        ),
        onOk: () => onRouteChange(),
      });
    }
    setLoading(false); // Waitingダイアログを非表示にする
  };

  // 別名保存入力ボックスのCancelボタン押下
  const saveByOtherNameCancel = () => {
    setIsSaveByOtherName(false);
  };

  // 同一図面で保存する確認メッセージのOkボタン押下
  const saveSameDrawingNoOk = async () => {
    await eval(saveSameDrawingNoFncName + '()');
  };

  // 同一図面で保存する確認メッセージのCancelボタン押下
  const saveSameDrawingNoCancel = () => {
    setIsSaveSameDrawingNo(false);
  };

  // 別名保存
  const saveByOtherNameInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        表示中の見積データを別名で保存します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                ref={ref}
                name="drawingNo"
                id="drawingNo"
                value={drawingNo}
                type="text"
                onChange={(e) => {
                  setDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">見積番号</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="estimateNo"
                id="estimateNo"
                value={estimateNo}
                type="text"
                onChange={(e) => {
                  setEstimateNo(e.target.value);
                }}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  // 親部品から他のメニューを押下した場合、表示する確認メッセージ
  const saveDiscardConfirmContent = (
    <>
      <Row>
        <p style={{ fontSize: '13.5px' }}>
          見積データが保存されていません。
          <br />
          そのまま画面を切り替えると編集データが削除されます。
          <br /> 保存しますか？
        </p>
      </Row>
      <Row align="middle">
        <Col span={8}>
          {selectedEstimateData?.estimateProducts?.id === 0 ? (
            <>
              <Button className="mainButton" onClick={saveEstimateData}>
                保存
              </Button>
            </>
          ) : (
            <>
              <Space size={'middle'}>
                <Button className="mainButton" onClick={saveEstimateDataByOtherName}>
                  別名保存
                </Button>
                <Button className="mainButton" onClick={updateEstimateData}>
                  上書保存
                </Button>
              </Space>
            </>
          )}
        </Col>

        <Col span={8} offset={7}>
          <Space size={'middle'}>
            <Button className="discardButton" style={{ textAlign: 'center' }} onClick={discardEstimateData}>
              {/* <CloseOutlined /> */}
              破棄
            </Button>
            <Button className="cancelButton" onClick={cancelOk}>
              キャンセル
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const sameDrawingNoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const renderComponent = () => {
    if (
      location.pathname == '/home/:parentList' ||
      location.pathname == '/home/:estimate.parent' ||
      location.pathname == '/home/:estimate.iq3' ||
      location.pathname == '/home/:estimate.iq5'
    ) {
      return (
        <>
          {/** 親部品リスト画面 */}
          <div style={{ display: location.pathname == '/home/:parentList' ? 'block' : 'none' }}>
            <ParentList
              ref={parentListRef}
              updateEstimateEditMode={updateEstimateEditMode}
              getSelectedEstimateData={getSelectedEstimateData}
              editMode={estimateEditMode}
              parentListClick={parentListClick}
              orderOptions={orderOptions}
              companyInfo={companyInfo}
              clientInfo={clientInfo}
              userInfo={userInfo}
              staffInfo={staffList}
              processInfo={processInfo}
              companySettingInfo={companySettingInfo}
              userSettingInfo={userSettingInfo}
              purchaseCategoryInfo={purchaseCategoryInfo}
              loading={updateLoading}
              updateParentActiveTabKey={updateParentActiveTabKey}
              updateNewModeByParent={updateNewModeByParent}
              openDetailInfo={openDetailInfo}
              currentParentRecordId={currentParentRecordId}
              currentParentListPage={currentParentListPage}
            />
          </div>
          {/**　親部品・板金見積画面 */}
          <div
            style={{
              display:
                location.pathname == '/home/:estimate.parent' ||
                location.pathname == '/home/:estimate.iq3' ||
                location.pathname == '/home/:estimate.iq5'
                  ? 'block'
                  : 'none',
            }}
          >
            <MainEstimate
              createNew={createNew}
              ref={mainEstimateRef}
              updateEstimateEditMode={updateEstimateEditMode}
              selectedEstimateData={selectedEstimateData}
              updateSelectedEstimateData={updateSelectedEstimateData}
              companyInfo={companyInfo}
              staffList={staffList}
              estimateStatusInfo={orderOptions}
              clientInfo={clientInfo}
              editMode={estimateEditMode}
              activeTab={activeTab}
              updateActiveTabKey={updateActiveTabKey} // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
              newMode={newMode}
              updateNewMode={updateNewMode}
              settingParametersByReCal={settingParametersByReCal}
              userSettingInfo={userSettingInfo}
              companySettingInfo={companySettingInfo}
              loading={updateLoading}
              isReCal={isReCal}
              updateIsRecal={updateIsRecal}
              isReCalMaterial={isReCalMaterial}
              updateIsRecalMaterial={updateIsRecalMaterial}
              updateSelectedEstimateDataByPMX={updateSelectedEstimateDataByPMX}
              parentActiveTabKey={parentActiveTabKey}
              updateParentActiveTabKey={updateParentActiveTabKey}
              iq3ActiveTabKey={iq3ActiveTabKey} // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
              updateIq3ActiveTabKey={updateIq3ActiveTabKey} // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
              navigatePage={navigatePage}
              updateNewModeByParent={updateNewModeByParent}
              isEstimateOpen={isEstimateOpen} // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
              createNewEvent={createNewEvent}
              updateCreateNewEvent={updateCreateNewEvent}
              selectedEstimateDataBeforeParentTab={selectedEstimateDataBeforeParentTab}
            />
          </div>
        </>
      );
    } else if (location.pathname == '/home/:userSetting') {
      return (
        <UserSetting
          ref={userSettingRef}
          navigatePageName={navigatePageName}
          isMenuClickByUserSetting={isMenuClickByUserSetting}
          navigatePage={navigatePage}
          paramChangeEvent={paramChangeEvent}
          loading={updateLoading}
        />
      );
    } else {
      return (
        <MainParameter
          paramType={paramType}
          paramNm={paramSelected}
          updateParamSelected={updateParamSelected}
          companySettingInfo={companySettingInfo}
          userSettingInfo={userSettingInfo}
          ref={paramRef}
          loading={updateLoading}
          updateCurrentParamSelected={updateCurrentParamSelected}
          updateParamSelected1={updateParamSelected1}
          updateParamSelected2={updateParamSelected2}
          paramSelected1={paramSelected1}
          paramSelected2={paramSelected2}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={selectedRowId}
          rowSelectTbl={rowSelectTbl}
          rowSelectPage={rowSelectPage}
          ascSortFlg={ascSortFlg}
          sortNm={sortNm}
          filterDatas={filterDatas}
        />
      );
    }
  };

  return (
    <>
      <Spin spinning={loading} tip="Loading..." size="large">
        <Layout>
          {/* ヘッダー */}
          <Header />
          <Layout
            style={{
              background: 'white',
              left: 0,
            }}
            hasSider
          >
            {/* サイドメニュ */}
            <Sider
              style={{
                background: 'none',
                backgroundColor: '#fafafc',
                left: 0,
                bottom: 0,
                marginLeft: '0px',
                borderRight: '1px solid #212529',
              }}
              width={75}
            >
              <div style={{ paddingLeft: '5px', display: 'flex', flexDirection: 'column', height: '94.5vh' }}>
                <div style={{ paddingTop: '0px' }}>
                  <a onClick={(e) => changeScreenEvent(e, 'parentList')}>
                    <Image preview={false} width={35} src={oya_list} style={{ marginLeft: '15px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color: location.pathname == '/home/:parentList' ? '#1677ff' : '#4a4747',
                        marginLeft: '-5px',
                        marginTop: '-5px',
                      }}
                    >
                      親部品リスト
                    </p>
                  </a>
                </div>
                <div>
                  <a onClick={(e) => changeScreenEvent(e, 'parent')}>
                    <Image preview={false} width={25} src={estimate} style={{ marginLeft: '18px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color:
                          location.pathname === '/home/:estimate.parent' ||
                          location.pathname === '/home/:estimate.iq3' ||
                          location.pathname === '/home/:estimate.iq5'
                            ? '#1677ff'
                            : '#4a4747',
                        marginLeft: '-5px',
                      }}
                    >
                      見積データ
                    </p>
                  </a>
                </div>
                <div style={{ height: '74vh' }}>
                  <a onClick={(e) => changeScreenEvent(e, 'customer')}>
                    <Image preview={false} width={30} src={parameter} style={{ marginLeft: '15px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color:
                          location.pathname.indexOf('/home/:common.') > -1 ||
                          location.pathname.indexOf('/home/:parent.') > -1 ||
                          location.pathname.indexOf('/home/:iq3.') > -1
                            ? '#1677ff'
                            : '#4a4747',
                        marginLeft: '-5px',
                      }}
                    >
                      パラメータ
                    </p>
                  </a>
                </div>
              </div>
            </Sider>
            {/* コンテンツ */}
            <Content style={{ overflow: 'initial' }}>{renderComponent()}</Content>
          </Layout>
        </Layout>
      </Spin>
      {/** 画面切替警告メッセージポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {saveDiscardConfirmPopup
        ? commonModal(
            saveDiscardConfirmPopup,
            confirmScreenChangeWarning,
            null,
            null,
            500,
            discardConfirmOk,
            closeOk,
            saveDiscardConfirmContent,
            null,
            null,
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}

      {/* 別名保存_Modal */}
      {isSaveByOtherName
        ? commonModal(
            isSaveByOtherName,
            commonModalTitle,
            commonFooter(saveByOtherNameOk, saveByOtherNameCancel),
            null,
            520,
            saveByOtherNameOk,
            saveByOtherNameCancel,
            saveByOtherNameInfoContent,
            null,
            null,
            false
          )
        : ''}

      {/* 同一図番_Modal */}
      {isSaveSameDrawingNo
        ? commonModal(
            isSaveSameDrawingNo,
            sameDrawingNoModalTitle,
            commonFooter(saveSameDrawingNoOk, saveSameDrawingNoCancel),
            null,
            400,
            saveSameDrawingNoOk,
            saveSameDrawingNoCancel,
            sameDrawingNoContent(getNameByValue(companySettingInfo?.[0]?.environments?.saveSameType, saveSameTypes)),
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default MainLayout;
