/**
 * クラス名：形鋼の材料明細一覧画面
 * 説明：形鋼の材料明細一覧ファイルです。
 * 作成者：ナンス
 * 作成日：2024/10/10
 * バージョン：1.0
 */
import {
  Table,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Image,
  Typography,
  DatePicker,
  Space,
  Radio,
  Dropdown,
} from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'split-pane-react/esm/themes/default.css';
import SplitPane from 'split-pane-react';

import '../../../../assets/styles/common.css';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  confirmUpdateContent,
  commonActionFooter,
  commonDeleteFooter,
} from '../../../common/CommonModal.js';
import {
  actionArr,
  formatDate,
  formValidatorMode,
  defaultValue,
  regexExp,
  toHalfWidth,
  formatDateString,
  todayTime,
  isDecimal,
  toDecimal,
  JPYs,
  getSteelMaterialNameList,
  getSteelMaterialClassList,
  isDigit,
  getSectionalarea,
  getPerimeter,
  toDecimal_place3,
} from '../../../common/Common.js';
import { ErrorMessage } from '../../../common/Message.js';
import { pageSizes, PNG, STEEL_SHAPE_URL } from '../../../common/Constant.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import SteelMaterialDetail from './SteelMaterialDetail.js';
import CurrencyInput from 'react-currency-input-field';
import {
  createSteelMaterialInfo,
  createSteelMaterialIq5CSV,
  deleteSteelMaterialInfo,
  getExistCompanySetting,
  getSteelMaterialInfo,
  updateSteelMaterialInfo,
} from '../../../common/CommonAPI.js';
import filter_clear_icon from '../../../../assets/images/filter_clear_icon.png';
import search from '../../../../assets/images/search.png';
import { isNumber } from 'lodash';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;

const SteelMaterialList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showCopyNewModal: showCopyNewModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const [paramSelected, setParamSelected] = useState('材料明細');
  const [paramType, setParamType] = useState('iq5');
  const [steelMaterialData, setSteelMaterialData] = useState([]);
  const [tempSteelMaterial, setTempSteelMaterial] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeIq5List, setMaterialTypeIq5List] = useState([]);
  const [mstMaterialTypeIq3List, setMstMaterialTypeIq3List] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  // Content Resize
  const [sizes, setSizes] = useState(['60%']);
  // 一括編集選択行
  const [currentPage, setCurrentPage] = useState(1);
  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();
  // pagination
  const [pageSize, setPageSize] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [filterDatas, setFilterDatas] = useState({
    steelShapeId: 0,
    shapeNo: 0,
    materialTypeIq5Id: 0,
    t1: '',
    t2: '',
    a: '',
    b: '',
    c: '',
    t1CondKey: 1,
    t2CondKey: 1,
    aCondKey: 1,
    bCondKey: 1,
    cCondKey: 1,
    kgPerM: '0',
    pricePerKg: '0',
  });
  const [tblLoading, setTblLoading] = useState(false);
  const [steelMaterialClassList, setSteelMaterialClassList] = useState([]);
  const [addCurSteelMaterialClassInfo, setAddCurSteelMaterialClassInfo] = useState([]);
  const [isFilterFlg, setIsFilterFlg] = useState(false);
  const [isImgSearch, setIsImgSearch] = useState(false);
  const [isInnerDiameterIncluded, setIsInnerDiameterIncluded] = useState(false);

  const csvExportFileName = `材料明細_${formatDateString(todayTime())}.csv`;
  const detailRef = useRef();
  const messageRef = useRef([]);
  const navigate = useNavigate();
  const inputItemRef = {};

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      render: (id, record, index) => {
        let pageBefore = currentPage - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        return startPage + (index + 1); // IQX_WEBEST-240
      },
      className: 'cm-a-right',
    },
    {
      key: 'steelShapeId',
      title: '鋼材名称',
      dataIndex: 'steelShapeId',
      width: 200,
      sorter: (a, b) => setSortNm('steelShapeId'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (steelShapeId) => getSteelMaterialClassName(steelShapeId),
    },
    {
      key: 'materialTypeIq5Id',
      title: '材質名称',
      dataIndex: 'materialTypeIq5Id',
      width: 220,
      sorter: (a, b) => setSortNm('materialTypeIq5Id'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (materialTypeIq5Id) => getMaterialTypeIq5Name(materialTypeIq5Id),
    },
    {
      key: 'name',
      title: '材料名称',
      dataIndex: 'name',
      width: 200,
      sorter: (a, b) => setSortNm('name'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (record, i) => {
        return (
          <Typography.Text id={'typographyTxt'} ellipsis={{ tooltip: [record] }}>
            {record ? record : ''}
          </Typography.Text>
        );
      },
    },
    {
      key: 't1',
      title: 'T1',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('t1'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => toDecimal(info?.t1, 2),
    },
    {
      key: 't2',
      title: 'T2',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('t2'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => toDecimal(info?.t2, 2),
    },
    {
      key: 'a',
      title: 'A',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('a'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => toDecimal(info?.a, 2),
    },
    {
      key: 'b',
      title: 'B',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('b'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => toDecimal(info?.b, 2),
    },
    {
      key: 'c',
      title: 'C',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('c'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => toDecimal(info?.c, 2),
    },
    {
      key: 'kgPerM',
      title: '単価重量',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('kgPerM'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => (info?.kgPerM ? toDecimal_place3(info?.kgPerM, 3) : toDecimal_place3(0, 3)),
    },
    {
      key: 'pricePerM',
      title: 'M単価',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('pricePerM'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (text, record) => {
        return record.info.pricePerM ? JPYs.format(record.info.pricePerM) : JPYs.format(0);
      },
    },
    {
      key: 'pricePerKg',
      title: 'Kg単価',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('pricePerKg'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (text, record) => {
        return record.info.pricePerKg ? JPYs.format(record.info.pricePerKg) : JPYs.format(0);
      },
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
      sorter: (a, b) => setSortNm('created'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
      sorter: (a, b) => setSortNm('modified'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  useEffect(() => {
    getSteelMaterialIQ5Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordId));
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else if (discardOKAction.key === actionArr[6]?.key) {
        // CSV入力
        onClickCSVImport();
      } else if (discardOKAction.key === actionArr[7]?.key) {
        // CSV出力
        onClickCSVExport();
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMeth(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  // 検索
  useEffect(() => {
    if (isFilterFlg) {
      // 絞り込み、
      getDataByFilter();
    }
  }, [filterDatas]);

  // 形鋼子部品材料明細マスタ情報を取得する
  const getSteelMaterialIQ5Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let steelMaterialData = [];
    let pageBefore =
      props?.initialParam === 'iq5_SteelMaterial' && props?.rowSelectPage !== undefined && props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let propSortFlg =
      props?.initialParam === 'iq5_SteelMaterial' && props?.ascSortFlg !== undefined ? props?.ascSortFlg : ascSortFlg;
    let propSortNm = props.initialParam === 'iq5_SteelMaterial' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
    let propFilterDatas =
      props?.initialParam === 'iq5_SteelMaterial' && props?.filterDatas !== undefined
        ? props?.filterDatas
        : filterDatas;
    const steelMaterialInfo = await getSteelMaterialInfo(
      pageSize,
      pageBefore,
      propSortFlg,
      propSortNm,
      propFilterDatas
    );
    if (steelMaterialInfo) {
      steelMaterialData = steelMaterialInfo ? steelMaterialInfo?.data : [];
      setTotalCount(steelMaterialInfo ? steelMaterialInfo?.totalCount : 0);
      setSteelMaterialData(steelMaterialData);
      setTempSteelMaterial(steelMaterialData);
      setFilteredData(steelMaterialData);
    }
    if (steelMaterialData?.length > 0) {
      setSelectedRowId(steelMaterialData[0]?.id);
      setSelectedData(steelMaterialData[0]);
      // 前回選択された行がある場合
      if (
        props?.initialParam === 'iq5_SteelMaterial' &&
        props?.selectedRowId !== undefined &&
        props?.selectedRowId !== 0
      ) {
        let selectedRowData = steelMaterialData?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          setSelectedRowId(selectedRowData?.[0]?.id);
          setSelectedData(selectedRowData?.[0]);
        }
        setShowFlag(propFilterDatas?.isUsedFlg);
      }
    }

    if (!props?.fromEstimate) {
      props?.setParam(paramType, paramSelected);
    }
    let materialTypeIq5List = await getSteelMaterialNameList();
    setMaterialTypeIq5List(materialTypeIq5List);

    let steelMaterialClassLists = await getSteelMaterialClassList();
    setSteelMaterialClassList(steelMaterialClassLists);
    props?.loading(false); // Waitingダイアログを非表示にする
    let companySetting = await getExistCompanySetting();
    if (companySetting !== undefined && companySetting?.length > 0) {
      let isInnerDiameterIncluded = companySetting?.[0]?.environments?.isInnerDiameterIncluded;
      setIsInnerDiameterIncluded(isInnerDiameterIncluded ? isInnerDiameterIncluded : false);
    }
  };

  // 材質名称名をIDで取得する
  const getMaterialTypeIq5Name = (materialTypeIq5Id) => {
    return materialTypeIq5List?.filter((item) => item.id === materialTypeIq5Id)?.[0]?.name;
  };

  // 鋼材名称をIDで取得する
  const getSteelMaterialClassName = (steelMaterialClassId) => {
    return steelMaterialClassList?.filter((item) => item.id === steelMaterialClassId)?.[0]?.name;
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let ascSortFlg = 'ASC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    let SteelMaterialIQ5Info = await getSteelMaterialInfo(
      pageSize,
      pageBefore,
      ascSortFlg,
      sorter?.columnKey,
      filterDatas
    );
    if (SteelMaterialIQ5Info) {
      let materialTypeIQ5 = SteelMaterialIQ5Info ? SteelMaterialIQ5Info?.data : [];
      setFilteredData(materialTypeIQ5);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  // 新規作成の項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true, isChangeEvent) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (isCheckZenkaku) {
      if (name == 'kgPerM') {
        value = value ? toDecimal_place3(value, 3) : toDecimal_place3(0, 3);
      } else {
        value = toDecimal(value, 2);
      }
    }
    if (value == tempSteelMaterial?.info?.[name]) return;
    let changeData = tempSteelMaterial;
    let kgPerM = 0;
    if (changeData) {
      let perimeter = 0;
      let sectionalarea = 0;
      if (addCurSteelMaterialClassInfo?.length > 0 && !isChangeEvent) {
        sectionalarea = getSectionalarea(addCurSteelMaterialClassInfo?.[0]?.shapeNo, tempSteelMaterial?.info);
        perimeter = getPerimeter(
          addCurSteelMaterialClassInfo?.[0]?.shapeNo,
          tempSteelMaterial?.info,
          isInnerDiameterIncluded
        );
        let materialTypeIq5 = materialTypeIq5List?.filter((item) => item.id === tempSteelMaterial?.materialTypeIq5Id);
        let specificGravity = materialTypeIq5?.[0]?.info.gravity;
        kgPerM = (specificGravity * sectionalarea) / 1000;
        kgPerM = kgPerM ? Math.abs(kgPerM) : 0;
        kgPerM = kgPerM ? toDecimal_place3(kgPerM, 3) : toDecimal_place3(0, 3);
        changeData.info['kgPerM'] = kgPerM;
        changeData.info['area'] = sectionalarea;
        changeData.info['perimeter'] = perimeter;
      } else {
        kgPerM = name == 'kgPerM' ? value : 0;
      }
    }
    changeData.info[name] = name == 'kgPerM' ? kgPerM : value;
    setTempSteelMaterial((prevData) => ({
      ...prevData,
      info: changeData.info,
    }));
  };

  const onRowSelect = (record, index) => {
    if (props?.fromEstimate) {
      props?.updateSteelSelect(record, steelMaterialClassList, materialTypeIq5List);
    }
    setChangeRecordId(record?.id);
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        if (!props?.fromEstimate) navigate(':iq5.steelMaterial');
      }
    } else {
      setSelectedRowId(record?.id);
      setSelectedData(record);
      if (!props?.fromEstimate) navigate(':iq5.steelMaterial');
    }
    if (!props?.fromEstimate) {
      props?.updateSelectedRowId(record?.id, 0, currentPage, ascSortFlg, sortNm, filterDatas);
      props?.resetInitialParam();
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
  };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(steelMaterialData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!(await detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false))) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let createdDataInfo = await createSteelMaterialInfo(tempSteelMaterial);
      if (createdDataInfo && createdDataInfo?.data?.length > 0) {
        let createdData = createdDataInfo?.data[0];
        stateData.push(createdData);
        setFilteredData(stateData);
        setSteelMaterialData(stateData);
        resetAddModal(stateData?.length + 1);
        setSelectedRowId(createdData?.id);
        setSelectedData(createdData);
        props?.updateSelectedRowId(createdData?.id, 0, 1, ascSortFlg, sortNm, filterDatas);
      }
      setVisibleAddNewModal(false);

      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    }
  };

  const updConfirmCancel = () => {
    setUpdateCancelConfirm(false);
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    }
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      /* updateEditMode(false); */
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit(false);
      }
    } else {
      handleAddFormSubmit(false);
    }
  };

  const showCopyNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[8]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit(true);
      }
    } else {
      handleAddFormSubmit(true);
    }
  };

  // 新規作成ボタン・複写ボタンの押下
  const handleAddFormSubmit = (isCopy) => {
    if (isCopy) {
      let copyData = JSON.parse(JSON.stringify(selectedData));
      let steelMaterialClassInfo = steelMaterialClassList?.filter((item) => item.id === copyData.steelShapeId);
      setAddCurSteelMaterialClassInfo(steelMaterialClassInfo?.length > 0 ? steelMaterialClassInfo : []);
      setTempSteelMaterial({
        name: copyData ? copyData.name : '',
        materialTypeIq5Id: copyData ? copyData.materialTypeIq5Id : defaultValue.materialType,
        steelShapeId: copyData ? copyData.steelShapeId : defaultValue.steelMaterialClass,
        info: copyData
          ? copyData.info
          : {
              isUsed: true,
              t1: 0,
              t2: 0,
              a: 0,
              b: 0,
              c: 0,
              area: 0,
              perimeter: 0,
              kgPerM: 0,
              pricePerM: 0,
              pricePerKg: 0,
              remarks: '',
              sortNum: 0,
            },
        created: dayjs().format('YYYY-MM-DD'),
        modified: dayjs().format('YYYY-MM-DD'),
      });
    } else {
      setAddCurSteelMaterialClassInfo([]);
      setTempSteelMaterial({
        name: '',
        materialTypeIq5Id: defaultValue.materialType,
        steelShapeId: defaultValue.steelMaterialClass,
        info: {
          isUsed: true,
          t1: 0,
          t2: 0,
          a: 0,
          b: 0,
          c: 0,
          area: 0,
          perimeter: 0,
          kgPerM: 0,
          pricePerM: 0,
          pricePerKg: 0,
          remarks: '',
          sortNum: 0,
        },
        created: dayjs().format('YYYY-MM-DD'),
        modified: dayjs().format('YYYY-MM-DD'),
      });
    }

    setVisibleAddNewModal(true);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteSteelMaterialInfo(selectedData?.id);
    // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
    if (deletedData !== undefined && deletedData?.data?.[0].id > 0) {
      let data = [...filteredData];
      data = data?.filter((item) => item.id != deletedData?.data?.[0].id);
      setSteelMaterialData(data);
      setFilteredData(data);
      setSelectedRowId(data?.length > 0 ? data[0]?.id : -1);
      setSelectedData(data?.length > 0 ? data[0] : []);
      props?.updateSelectedRowId(data?.length > 0 ? data[0]?.id : 0, 0, currentPage, ascSortFlg, sortNm, filterDatas);
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = (count) => {
    setTempSteelMaterial({
      info: {
        isUsed: true,
        sizeX: 0,
        sizeY: 0,
        remarks: '',
      },
    });
  };

  const setSplitPaneSize = (splitSize) => {
    if (!props?.fromEstimate) {
      setSizes(splitSize);
    } else {
      setSizes(['100%']);
    }
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...steelMaterialData];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updateSteelMaterialInfo(updateData);
    updatedInfo = updateData;
    var index = tmpData?.findIndex((item) => item.id === updatedInfo?.id);
    tmpData[index] = updatedInfo;
    setSteelMaterialData(tmpData);
    setFilteredData(tmpData);
    setSelectedRowId(updatedInfo?.id);
    setSelectedData(updatedInfo);
    props?.updateSelectedRowId(updatedInfo?.id, 0, currentPage, ascSortFlg, sortNm, filterDatas);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    let csvHeader = columns?.filter((item) => item.key !== 'created' && item.key !== 'modified');
    csvHeader?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    return str;
  };

  // CSV出力情報
  const getCsvExportData = async () => {
    let str = '';
    str = getCSVHeaderInfo();
    let pageBefore =
      props.initialParam === 'iq5_SteelMaterial' && props?.rowSelectPage !== undefined && props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let propSortFlg =
      props.initialParam === 'iq5_SteelMaterial' && props?.ascSortFlg !== undefined ? props?.ascSortFlg : ascSortFlg;
    let propSortNm = props.initialParam === 'iq5_SteelMaterial' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
    let propFilterDatas =
      props.initialParam === 'iq5_SteelMaterial' && props?.filterDatas !== undefined ? props?.filterDatas : filterDatas;
    const SteelMaterialInfo = await getSteelMaterialInfo(
      totalCount,
      pageBefore,
      propSortFlg,
      propSortNm,
      propFilterDatas
    );
    if (SteelMaterialInfo) {
      let filteredData = SteelMaterialInfo ? SteelMaterialInfo?.data : [];
      filteredData?.map((data, index) => {
        let mTypeIq5SNmStr = '';
        let steelShapeNmStr = '';
        mTypeIq5SNmStr = getMaterialTypeIq5Name(data.materialTypeIq5Id);
        steelShapeNmStr = getSteelMaterialClassName(data.steelShapeId);
        // CSV内容
        str +=
          '\n' +
          (index + 1) +
          ',' +
          steelShapeNmStr +
          ',' +
          mTypeIq5SNmStr +
          ',' +
          data.name +
          ',' +
          (data.info.t1 ? toDecimal(data.info.t1, 2) : 0) +
          ',' +
          (data.info.t2 ? toDecimal(data.info.t2, 2) : 0) +
          ',' +
          (data.info.a ? toDecimal(data.info.a, 2) : 0) +
          ',' +
          (data.info.b ? toDecimal(data.info.b, 2) : 0) +
          ',' +
          (data.info.c ? toDecimal(data.info.c, 2) : 0) +
          ',' +
          (data.info.kgPerM ? data.info.kgPerM?.toFixed(3) : 0) +
          ',' +
          (data.info.pricePerM ? JPYs.format(data.info.pricePerM) : JPYs.format(0)) +
          ',' +
          (data.info.pricePerKg ? JPYs.format(data.info.pricePerKg) : JPYs.format(0));
      });
    }
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDataInfo = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let tempData = {
          name: '',
          materialTypeIq5Id: 0,
          steelShapeId: 0,
          info: {
            isUsed: true,
            t1: 0,
            t2: 0,
            a: 0,
            b: 0,
            c: 0,
            area: 0,
            perimeter: 0,
            kgPerM: 0,
            pricePerM: 0,
            pricePerKg: 0,
            remarks: '',
            sortNum: 0,
          },
          created: dayjs().format('YYYY-MM-DD'),
          modified: dayjs().format('YYYY-MM-DD'),
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }

        let steelMaterial = steelMaterialClassList?.filter((item) => item.name == data?.[1]);
        if (steelMaterial?.length > 0) {
          tempData.steelShapeId = steelMaterial?.[0]?.id;
          if (steelMaterial?.[0]?.t1) tempData.info.t1 = data?.[4] ? toDecimal(data?.[4], 2) : 0;
          if (steelMaterial?.[0]?.t2) tempData.info.t2 = data?.[5] ? toDecimal(data?.[5], 2) : 0;
          if (steelMaterial?.[0]?.a) tempData.info.a = data?.[6] ? toDecimal(data?.[6], 2) : 0;
          if (steelMaterial?.[0]?.b) tempData.info.b = data?.[7] ? toDecimal(data?.[7], 2) : 0;
          if (steelMaterial?.[0]?.c) tempData.info.c = data?.[8] ? toDecimal(data?.[8], 2) : 0;
        }
        let materialTypeIq5 = materialTypeIq5List?.filter((item) => item.name == data?.[2]);
        if (materialTypeIq5?.length > 0) tempData.materialTypeIq5Id = materialTypeIq5?.[0].id;
        tempData.name = data?.[3];
        tempData.info.kgPerM = data?.[9] ? data?.[9]?.toFixed(3) : 0;
        let pricePerMTxt = data?.[10] ? data?.[10].replace('¥', '') : 0;
        pricePerMTxt = pricePerMTxt ? pricePerMTxt.replace(',', '') : 0;
        let pricePerKgTxt = data?.[11] ? data?.[11].replace('¥', '') : 0;
        pricePerKgTxt = pricePerKgTxt ? pricePerKgTxt.replace(',', '') : 0;
        tempData.info.pricePerM = pricePerMTxt;
        tempData.info.pricePerKg = pricePerKgTxt;
        tempDatas.push(tempData);
      }
      let pageBefore =
        props.initialParam === 'iq5_SteelMaterial' && props?.rowSelectPage !== undefined && props?.rowSelectPage !== 0
          ? props?.rowSelectPage - 1
          : 0;
      setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
      let propSortFlg =
        props.initialParam === 'iq5_SteelMaterial' && props?.ascSortFlg !== undefined ? props?.ascSortFlg : ascSortFlg;
      let propSortNm =
        props.initialParam === 'iq5_SteelMaterial' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
      let propFilterDatas =
        props.initialParam === 'iq5_SteelMaterial' && props?.filterDatas !== undefined
          ? props?.filterDatas
          : filterDatas;
      let createdDataInfo = await createSteelMaterialIq5CSV(
        tempDatas,
        pageSize,
        pageBefore,
        propSortFlg,
        propSortNm,
        propFilterDatas
      );
      let createdDatas = [];
      if (createdDataInfo) {
        let createdDatas = createdDataInfo ? createdDataInfo?.data : [];
        setTotalCount(createdDataInfo ? createdDataInfo?.totalCount : 0);
        setSteelMaterialData(createdDatas);
        setTempSteelMaterial(createdDatas);
        setFilteredData(createdDatas);
      }
      if (createdDatas?.length > 0) {
        setSelectedRowId(createdDatas[0]?.id);
        setSelectedData(createdDatas[0]);
        props?.updateSelectedRowId(createdDatas?.[0]?.id, 0, 1, ascSortFlg, sortNm, filterDatas);
        setCurrentPage(1);
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // 鋼材名称
    let input = data?.[1];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('鋼材名称').E007}</li>);
      return false;
    } else {
      // 鋼材名称が存在するかチェック
      let steelShapeNm = steelMaterialClassList?.filter((item) => item.name === input);
      if (steelShapeNm?.length <= 0) {
        messageRef?.current?.push(<li>{no + ErrorMessage('鋼材名称').E022}</li>);
        return false;
      }
    }

    // 材質名称
    input = data?.[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E007}</li>);
      return false;
    } else {
      // 材質名称が存在するかチェック
      let mTypeIq5Nm = materialTypeIq5List?.filter((item) => item.name === input);
      if (mTypeIq5Nm?.length <= 0) {
        messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E022}</li>);
        return false;
      }
    }

    // 材料名称
    input = data?.[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材料名称').E007}</li>);
      return false;
    }

    // T1
    input = data?.[4];
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('T1').E014}</li>);
      return false;
    }

    // T2
    input = data?.[5];
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('T2').E014}</li>);
      return false;
    }

    // A
    input = data?.[6];
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('A').E014}</li>);
      return false;
    }

    // B
    input = data?.[7];
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('B').E014}</li>);
      return false;
    }
    // C
    input = data?.[8];
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('C').E014}</li>);
      return false;
    }

    // 単価重量
    input = data?.[9];
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('単価重量').E014}</li>);
      return false;
    }

    // M単価
    // input = data?.[10];
    // input = input.replace('¥', '')?.replace(',', '');
    // if (input?.length > 0 && !isDigit(input)) {
    //   messageRef?.current?.push(<li>{no + ErrorMessage('M単価').E014}</li>);
    //   return false;
    // }

    // Kg単価
    // input = data?.[11];
    // input = input.replace('¥', '')?.replace(',', '');
    // if (input?.length > 0 && !isDigit(input)) {
    //   messageRef?.current?.push(<li>{no + ErrorMessage('Kg単価').E014}</li>);
    //   return false;
    // }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  // 入力ボックスは金額タイプの場合、他の項目CSSを参照にして利用
  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList)?.filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };

  const getDataByFilter = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let steelMaterialData = [];
    let propSortFlg = ascSortFlg;
    let propSortNm = sortNm;
    let propFilterDatas = filterDatas;
    const steelMaterialInfo = await getSteelMaterialInfo(pageSize, 0, propSortFlg, propSortNm, propFilterDatas);
    if (steelMaterialInfo) {
      steelMaterialData = steelMaterialInfo ? steelMaterialInfo?.data : [];
      setTotalCount(steelMaterialInfo ? steelMaterialInfo?.totalCount : 0);
      setSteelMaterialData(steelMaterialData);
      setTempSteelMaterial(steelMaterialData);
      setFilteredData(steelMaterialData);
    }
    if (steelMaterialData?.length > 0) {
      setSelectedRowId(steelMaterialData[0]?.id);
      setSelectedData(steelMaterialData[0]);
    }
    setIsFilterFlg(false);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 検索条件を初期値にする
  const clearAllFilter = (e) => {
    setFilterDatas({
      steelShapeId: 0,
      shapeNo: 0,
      materialTypeIq5Id: 0,
      t1: '',
      t2: '',
      a: '',
      b: '',
      c: '',
      t1CondKey: 1,
      t2CondKey: 1,
      aCondKey: 1,
      bCondKey: 1,
      cCondKey: 1,
      kgPerM: '0',
      pricePerKg: '0',
    });
    setIsFilterFlg(true);
  };

  const imgSearchOk = (e) => {
    let curSteelShapeId = steelMaterialClassList?.filter((item) => item.shapeNo === filterDatas?.shapeNo)?.[0]?.id;
    if (filterDatas?.shapeNo === 0) {
      setFilterDatas({
        ...filterDatas,
        steelShapeId: 0,
      });
    } else {
      setFilterDatas({
        ...filterDatas,
        steelShapeId: curSteelShapeId ? curSteelShapeId : filterDatas?.steelShapeId,
      });
    }
    setIsFilterFlg(true);
    setIsImgSearch(false);
  };

  const imgSearchCancel = (e) => {
    setFilterDatas({
      ...filterDatas,
      shapeNo: 0,
    });
    setIsImgSearch(false);
  };

  const addModalContent = (
    <div id="addNewSteelMaterial" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          <tr className="detail-tbl-row" style={{ height: 45 }}>
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">鋼材名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                style={{ width: '96%' }}
                onChange={(e) => {
                  {
                    let steelMaterialClassInfo = steelMaterialClassList?.filter((item) => item.id === e);
                    setAddCurSteelMaterialClassInfo(steelMaterialClassInfo?.length > 0 ? steelMaterialClassInfo : []);

                    let sectionalarea =
                      steelMaterialClassInfo?.length > 0
                        ? getSectionalarea(steelMaterialClassInfo?.[0]?.shapeNo, tempSteelMaterial?.info)
                        : 0;
                    let perimeter = getPerimeter(
                      steelMaterialClassInfo?.[0]?.shapeNo,
                      tempSteelMaterial?.info,
                      isInnerDiameterIncluded
                    );
                    let materialTypeIq5 = materialTypeIq5List?.filter(
                      (item) => item.id === tempSteelMaterial?.materialTypeIq5Id
                    );
                    let specificGravity = materialTypeIq5?.length > 0 ? materialTypeIq5?.[0]?.info.gravity : 0;
                    let kgPerM = (specificGravity * sectionalarea) / 1000;
                    kgPerM = kgPerM ? Math.abs(kgPerM) : 0;
                    kgPerM = kgPerM?.toFixed(3);
                    let infoTemp = tempSteelMaterial;
                    infoTemp.info.kgPerM = kgPerM;
                    infoTemp.info.area = sectionalarea;
                    infoTemp.info.perimeter = perimeter;
                    setTempSteelMaterial({
                      ...tempSteelMaterial,
                      steelShapeId: e,
                      info: infoTemp.info,
                    });
                  }
                }}
                id="steelShapeId"
                name="steelShapeId-CC"
                defaultValue={defaultValue.steelMaterialClass}
                value={tempSteelMaterial?.steelShapeId}
              >
                <Select.Option id="SteelShape" value={defaultValue.steelMaterialClass}>
                  {defaultValue.steelMaterialClass}
                </Select.Option>
                {steelMaterialClassList?.map((item, index) => (
                  <Select.Option key={index} id={'SteelShapeId' + item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </td>
            <td
              style={{ verticalAlign: 'middle', borderLeft: '1px solid #a5a5a5', width: '130px', textAlign: 'center' }}
              rowSpan={2}
            >
              <Image
                src={
                  addCurSteelMaterialClassInfo?.[0]?.shapeNo == 0
                    ? ''
                    : STEEL_SHAPE_URL + addCurSteelMaterialClassInfo?.[0]?.shapeNo + PNG
                }
                alt=""
                width={90}
                preview={true}
                style={{ marginLeft: -5 }}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                style={{ width: '96%' }}
                onChange={(e) => {
                  {
                    let sectionalarea =
                      addCurSteelMaterialClassInfo?.length > 0
                        ? getSectionalarea(addCurSteelMaterialClassInfo?.[0]?.shapeNo, tempSteelMaterial?.info)
                        : 0;
                    let perimeter = getPerimeter(
                      addCurSteelMaterialClassInfo?.[0]?.shapeNo,
                      tempSteelMaterial?.info,
                      isInnerDiameterIncluded
                    );
                    let materialTypeIq5 = materialTypeIq5List?.filter((item) => item.id === e);
                    let specificGravity = materialTypeIq5?.[0]?.info.gravity;
                    let kgPerM = (specificGravity * sectionalarea) / 1000;
                    kgPerM = kgPerM ? Math.abs(kgPerM) : 0;
                    kgPerM = kgPerM?.toFixed(3);
                    let infoTemp = tempSteelMaterial;
                    infoTemp.info.kgPerM = kgPerM;
                    infoTemp.info.area = sectionalarea;
                    infoTemp.info.perimeter = perimeter;
                    setTempSteelMaterial({
                      ...tempSteelMaterial,
                      materialTypeIq5Id: e,
                      info: infoTemp.info,
                    });
                  }
                }}
                id="materialTypeIq5Id"
                name="materialTypeIq5Id-CC"
                defaultValue={defaultValue.materialType}
                value={tempSteelMaterial?.materialTypeIq5Id}
              >
                <Select.Option id="MaterialTypeIq5" value={defaultValue.materialType}>
                  {defaultValue.materialType}
                </Select.Option>
                {materialTypeIq5List?.map((item, index) => (
                  <Select.Option key={index} id={'MaterialTypeIq5' + item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </td>
            <td></td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材料名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempSteelMaterial?.name}
                onChange={(e) => {
                  {
                    setTempSteelMaterial({ ...tempSteelMaterial, name: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">T1(mm)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="t1"
                name="t1"
                type="text"
                data-name="name"
                className={addCurSteelMaterialClassInfo?.[0]?.t1 ? 'input-editable' : 'input-non-editable'}
                value={tempSteelMaterial?.info?.t1}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                ref={(ref) => {
                  inputItemRef['t1'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">T2(mm)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="t2"
                name="t2"
                type="text"
                data-name="name"
                className={addCurSteelMaterialClassInfo?.[0]?.t2 ? 'input-editable' : 'input-non-editable'}
                value={tempSteelMaterial?.info?.t2}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                ref={(ref) => {
                  inputItemRef['t2'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">A(mm)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="a"
                name="a"
                type="text"
                data-name="name"
                className={addCurSteelMaterialClassInfo?.[0]?.a ? 'input-editable' : 'input-non-editable'}
                value={tempSteelMaterial?.info?.a}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                ref={(ref) => {
                  inputItemRef['a'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">B(mm)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="b"
                name="b"
                type="text"
                data-name="name"
                className={addCurSteelMaterialClassInfo?.[0]?.b ? 'input-editable' : 'input-non-editable'}
                value={tempSteelMaterial?.info?.b}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                ref={(ref) => {
                  inputItemRef['b'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">C(mm)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="c"
                name="c"
                type="text"
                data-name="name"
                className={addCurSteelMaterialClassInfo?.[0]?.c ? 'input-editable' : 'input-non-editable'}
                value={tempSteelMaterial?.info?.c}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                ref={(ref) => {
                  inputItemRef['c'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">単価重量(kg/M)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="kgPerM"
                name="kgPerM"
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempSteelMaterial?.info?.kgPerM}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                onFocus={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                ref={(ref) => {
                  inputItemRef['kgPerM'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">M単価</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <CurrencyInput
                id="pricePerM"
                name="pricePerM"
                type="text"
                data-name="name"
                className={getAntInputBoxClassName('pricePerM') + ' param-currency'}
                value={tempSteelMaterial?.info?.pricePerM ? tempSteelMaterial?.info?.pricePerM : 0}
                defaultValue={0}
                onValueChange={(e) => {
                  setTempSteelMaterial((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, pricePerM: e },
                  }));
                }}
                prefix="¥"
                decimalsLimit={100}
                ref={(ref) => {
                  inputItemRef['pricePerM'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">Kg単価</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <CurrencyInput
                id="pricePerKg"
                name="pricePerKg"
                type="text"
                data-name="name"
                className={getAntInputBoxClassName('pricePerKg') + ' param-currency'}
                value={tempSteelMaterial?.info?.pricePerKg ? tempSteelMaterial?.info?.pricePerKg : 0}
                defaultValue={0}
                onValueChange={(e) => {
                  setTempSteelMaterial((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, pricePerKg: e },
                  }));
                }}
                prefix="¥"
                decimalsLimit={100}
                ref={(ref) => {
                  inputItemRef['pricePerKg'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">作成日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <DatePicker
                name={'created'}
                id={'created'}
                className={'input-editable'}
                style={{ padding: 5, marginLeft: '5px !important', width: '60%' }}
                onChange={(e, dateString) => {
                  if (dateString !== '') {
                    setTempSteelMaterial((prevData) => ({
                      ...prevData,
                      created: dateString,
                    }));
                  }
                }}
                value={tempSteelMaterial?.created ? dayjs(tempSteelMaterial?.created, 'YYYY/MM/DD') : ''}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">更新日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }} colSpan={2}>
              <Input
                id="modified"
                name="modified"
                type="text"
                data-name="name"
                className={'input-non-editable'}
                value={tempSteelMaterial?.modified ? formatDate(tempSteelMaterial?.modified) : ''}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </div>
  );

  // 検索条件をクリアボタン
  const filterClearBtn = (
    <>
      <div style={{ display: 'flex' }}>
        <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
        <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
      </div>
    </>
  );

  const imgSearchContent = (
    <div id="addNewSteelMaterial" style={{ marginTop: 8 }}>
      <Row justify="start" gutter={[6, 8]}>
        <Col
          span={4}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            setFilterDatas({
              ...filterDatas,
              shapeNo: 0,
            });
          }}
        >
          <div
            id={'shapeNoAll'}
            className="img-search-div"
            style={{ backgroundColor: filterDatas?.shapeNo == 0 ? '#91caff' : '' }}
          >
            <div style={{ backgroundColor: 'white', width: '79px', height: '77px', marginTop: 3, marginLeft: 3 }} />
          </div>
          <div style={{ backgroundColor: '#1063aa' }}>
            <label className="img-search-txt">全て</label>
          </div>
        </Col>
        {steelMaterialClassList?.map((item, index) => (
          <Col
            style={{ cursor: 'pointer' }}
            span={4}
            onClick={(e) => {
              setFilterDatas({
                ...filterDatas,
                shapeNo: item.shapeNo,
              });
            }}
          >
            <div
              id={'shapeNoId' + index}
              className="img-search-div"
              style={{ backgroundColor: filterDatas?.shapeNo == item.shapeNo ? '#91caff' : '' }}
            >
              <Image
                src={STEEL_SHAPE_URL + item.shapeNo + PNG}
                alt=""
                width={77}
                preview={false}
                style={{ marginTop: 3 }}
              />
            </div>
            <div style={{ backgroundColor: '#1063aa' }}>
              <Typography.Text id={'typographyTxt'} className="img-search-txt" ellipsis={{ tooltip: [item.name] }}>
                {item.name}
              </Typography.Text>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  const greaterThanTxt = '> ¥0';
  const equalTxt = '= ¥0';
  const [t1Cond, setT1Cond] = useState('=');
  const [t2Cond, setT2Cond] = useState('=');
  const [aCond, setACond] = useState('=');
  const [bCond, setBCond] = useState('=');
  const [cCond, setCCond] = useState('=');

  const onCondClick = (e, itemNm) => {
    let btnKey = ++e;
    if (btnKey > condItems?.length) {
      btnKey = 1;
    }
    if (itemNm === 't1') {
      setT1Cond(condItems?.filter((item) => item.key == btnKey)?.[0]?.label);
      setFilterDatas({
        ...filterDatas,
        t1CondKey: btnKey,
      });
    } else if (itemNm === 't2') {
      setFilterDatas({
        ...filterDatas,
        t2CondKey: btnKey,
      });
      setT2Cond(condItems?.filter((item) => item.key == btnKey)?.[0]?.label);
    } else if (itemNm === 'a') {
      setFilterDatas({
        ...filterDatas,
        aCondKey: btnKey,
      });
      setACond(condItems?.filter((item) => item.key == btnKey)?.[0]?.label);
    } else if (itemNm === 'b') {
      setFilterDatas({
        ...filterDatas,
        bCondKey: btnKey,
      });
      setBCond(condItems?.filter((item) => item.key == btnKey)?.[0]?.label);
    } else if (itemNm === 'c') {
      setFilterDatas({
        ...filterDatas,
        cCondKey: btnKey,
      });
      setCCond(condItems?.filter((item) => item.key == btnKey)?.[0]?.label);
    }
  };
  const condItems = [
    {
      key: '1',
      label: '=',
    },
    {
      key: '2',
      label: '>',
    },
    {
      key: '3',
      label: '>=',
    },
    {
      key: '4',
      label: '<=',
    },
    {
      key: '5',
      label: '<',
    },
  ];

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={props?.fromEstimate ? ['100%'] : sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/* 検索アリア */}
            <Space size="small">
              <label>鋼材名称</label>
              <Select
                className={'input-editable'}
                style={{ marginLeft: 0, width: '180px' }}
                onChange={(e) => {
                  setFilterDatas({
                    ...filterDatas,
                    steelShapeId: e,
                  });
                  setIsFilterFlg(true);
                }}
                placeholder="鋼材名称"
                id="steelShapeId"
                name="steelShapeId"
                value={filterDatas?.steelShapeId}
              >
                <Select.Option id="allOptionSteelShapeId" value={0}>
                  {'全て'}
                </Select.Option>
                {steelMaterialClassList?.map((item, index) => (
                  <Select.Option key={index} id={'steelShapeId' + item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Button
                className="filter-img-btn"
                onClick={(e) => {
                  setIsImgSearch(true);
                }}
              >
                画像から選択
              </Button>
              <label style={{ marginLeft: 25 }}>材質名称</label>
              <Select
                className={'input-editable'}
                style={{ marginLeft: 0, width: '180px' }}
                onChange={(e) => {
                  setFilterDatas({
                    ...filterDatas,
                    materialTypeIq5Id: e,
                  });
                  setIsFilterFlg(true);
                }}
                placeholder="材質名称"
                id="materialTypeIq5Id"
                name="materialTypeIq5Id"
                value={filterDatas?.materialTypeIq5Id}
              >
                <Select.Option id="allOptionMaterialTypeIq5Id" value={0}>
                  {'全て'}
                </Select.Option>
                {materialTypeIq5List?.map((item, index) => (
                  <Select.Option key={index} id={'materialTypeIq5Id' + item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Button style={{ marginLeft: 25 }} className="filterClearButton" onClick={clearAllFilter}>
                {filterClearBtn}
              </Button>
            </Space>
            <div style={{ marginTop: 10, alignItems: 'center', display: 'flex' }}>
              <label>T1</label>
              <Button
                className="steel-material-search-btn"
                onClick={(e) => {
                  onCondClick(filterDatas?.t1CondKey, 't1');
                }}
              >
                {t1Cond}
              </Button>
              <Input
                className="input-editable search-select-input-steel-material"
                id="t1"
                name="t1"
                allowClear
                value={filterDatas?.t1}
                onChange={(e) => {
                  if (e.target.value === '' || isDecimal(e.target.value)) {
                    setFilterDatas({
                      ...filterDatas,
                      t1: e.target.value,
                    });
                  }
                }}
              />
              <label style={{ marginLeft: 5 }}>T2</label>
              <Button
                className="steel-material-search-btn"
                onClick={(e) => {
                  onCondClick(filterDatas?.t2CondKey, 't2');
                }}
              >
                {t2Cond}
              </Button>
              <Input
                className="input-editable search-select-input-steel-material"
                id="t2"
                name="t2"
                allowClear
                value={filterDatas?.t2}
                onChange={(e) => {
                  if (e.target.value === '' || isDecimal(e.target.value)) {
                    setFilterDatas({
                      ...filterDatas,
                      t2: e.target.value,
                    });
                  }
                }}
              />
              <label style={{ marginLeft: 5 }}>A</label>
              <Button
                className="steel-material-search-btn"
                onClick={(e) => {
                  onCondClick(filterDatas?.aCondKey, 'a');
                }}
              >
                {aCond}
              </Button>
              <Input
                className="input-editable search-select-input-steel-material"
                id="a"
                name="a"
                allowClear
                value={filterDatas?.a}
                onChange={(e) => {
                  if (e.target.value === '' || isDecimal(e.target.value)) {
                    setFilterDatas({
                      ...filterDatas,
                      a: e.target.value,
                    });
                  }
                }}
              />
              <label style={{ marginLeft: 5 }}>B</label>
              <Button
                className="steel-material-search-btn"
                onClick={(e) => {
                  onCondClick(filterDatas?.bCondKey, 'b');
                }}
              >
                {bCond}
              </Button>
              <Input
                className="input-editable search-select-input-steel-material"
                id="b"
                name="b"
                allowClear
                value={filterDatas?.b}
                onChange={(e) => {
                  if (e.target.value === '' || isDecimal(e.target.value)) {
                    setFilterDatas({
                      ...filterDatas,
                      b: e.target.value,
                    });
                  }
                }}
              />
              <label style={{ marginLeft: 5 }}>C</label>
              <Button
                className="steel-material-search-btn"
                onClick={(e) => {
                  onCondClick(filterDatas?.cCondKey, 'c');
                }}
              >
                {cCond}
              </Button>
              <Input
                className="input-editable search-select-input-steel-material"
                id="c"
                name="c"
                allowClear
                value={filterDatas?.c}
                onChange={(e) => {
                  if (e.target.value === '' || isDecimal(e.target.value)) {
                    setFilterDatas({
                      ...filterDatas,
                      c: e.target.value,
                    });
                  }
                }}
              />
              <div className="radio-steel-material-name-search">
                <label style={{ marginLeft: 5 }}>M単価</label>
                <RadioGroup
                  name="kgPerMRadio"
                  defaultValue={'0'}
                  className="radioCustomerGpEdit input-editable"
                  onChange={(e) => {
                    setFilterDatas({
                      ...filterDatas,
                      kgPerM: e.target.value ? e.target.value.toString() : '0',
                    });
                  }}
                  value={filterDatas.kgPerM}
                  style={{ marginLeft: 3 }}
                >
                  <Radio value={'0'}>全て</Radio>
                  <Radio value={'1'}>{greaterThanTxt}</Radio>
                  <Radio value={'2'}>{equalTxt}</Radio>
                </RadioGroup>
              </div>
              <div className="radio-steel-material-name-search">
                <label style={{ marginLeft: 5 }}>Kg単価</label>
                <RadioGroup
                  name="pricePerKgRadio"
                  defaultValue={'0'}
                  className="radioCustomerGpEdit input-editable"
                  value={filterDatas.pricePerKg}
                  style={{ marginLeft: 3 }}
                  onChange={(e) => {
                    setFilterDatas({
                      ...filterDatas,
                      pricePerKg: e.target.value ? e.target.value.toString() : '0',
                    });
                  }}
                >
                  <Radio value={'0'}>全て</Radio>
                  <Radio value={'1'}>{greaterThanTxt}</Radio>
                  <Radio value={'2'}>{equalTxt}</Radio>
                </RadioGroup>
              </div>
              <Image
                preview={false}
                width={23}
                height={23}
                src={search}
                style={{ marginBottom: '10px', cursor: 'pointer', marginLeft: 5 }}
                onClick={getDataByFilter}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              {/* 一覧アリア */}
              <Row>
                <Table
                  showSorterTooltip={false}
                  columns={columns}
                  dataSource={filteredData}
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.id}
                  pagination={{
                    position: ['bottomLeft'],
                    total: totalCount,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    defaultCurrent: 1,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizes,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  className={
                    props?.fromEstimate ? 'param_tbListShow-steelMaterial-est' : 'param_tbListShow-steelMaterial'
                  }
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                  onChange={handleTableChange}
                  loading={tblLoading}
                />
              </Row>
            </div>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq5.SteelMaterial"
                element={
                  <SteelMaterialDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={steelMaterialData?.find((element) => element.id === selectedRowId)}
                    materialTypeIq5List={materialTypeIq5List}
                    steelMaterialClassList={steelMaterialClassList}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    getMaterialTypeIq5Name={getMaterialTypeIq5Name}
                    getSteelMaterialClassName={getSteelMaterialClassName}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    isInnerDiameterIncluded={isInnerDiameterIncluded}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm
        ? commonModal(
            updateCancelConfirm,
            confirmModalTitle,
            commonFooter(updCancelOK, updCancelCancel),
            null,
            400,
            updCancelOK,
            updCancelCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材料明細（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}

      {/** 画像検索ポップアップ */}
      {isImgSearch &&
        commonModal(
          isImgSearch,
          modalTitle('画像選択', 600, -24),
          commonFooter(imgSearchOk, imgSearchCancel),
          null,
          600,
          imgSearchOk,
          imgSearchCancel,
          imgSearchContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default SteelMaterialList;
