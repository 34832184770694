/**
 * クラス名：会社設定画面
 * 作成者：チュー
 * 作成日：2023/07/24
 * バージョン：1.0
 */
import { Input, Row, Col, Select, Button, Typography, Checkbox, Modal, Popover } from 'antd';
import React, { Component, useState, useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  EditOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { getAccessToken, updateAccessToken } from '../common/Common';
import { ErrorMessage } from '../common/Message';
import {
  authorizeError,
  error,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmUpdateContent,
  complete,
  commonDeleteFooter,
  confirmDeleteContent,
} from '../common/CommonModal';
import {
  getExistCompanySetting,
  getExcelDatabyUseType,
  getExistUserSetting,
  getS3FileContent,
  deleteS3FileContent,
  downloadS3FileContent,
} from '../common/CommonAPI';

import { StatusCodes } from 'http-status-codes';
import { CurrencySymbols, PrintTypes, RoundUpMethods, SaveSameType, languages, timeZones } from '../common/enums';
import { saveSameTypes } from '../common/Constant';

const csvFilesData = [
  { key: 1, title: '生産管理連携', useFor: '個別出力', examples: '図面、品番、名称、金額、工程1、工程2、' },
  { key: 2, title: '親部品リスト', useFor: 'リスト出力', examples: '図面、品番、名称、金額、工程1、工程2、' },
];

const curSymbol = {
  0: '¥',
};

const riyoushin = {
  0: '個別帳票',
  1: 'リスト帳票',
};

const parentnamingConditions = [
  { id: 0, value: 0, label: ' ' },
  { id: 1, value: 1, label: '年月日' },
  { id: 2, value: 2, label: '時分秒' },
];

const namingConditions = [
  { id: 0, value: 0, label: ' ' },
  { id: 1, value: 1, label: '年月日' },
  { id: 2, value: 2, label: '時分秒' },
  { id: 3, value: 3, label: '材料名' },
  { id: 4, value: 4, label: '材質名' },
  { id: 5, value: 5, label: '表面保護' },
  { id: 6, value: 6, label: '板厚' },
  { id: 7, value: 7, label: '材料サイズX' },
  { id: 8, value: 8, label: '材料サイズY' },
  { id: 9, value: 9, label: '部品サイズX' },
  { id: 10, value: 10, label: '部品サイズY' },
  { id: 11, value: 11, label: '親部品図番' },
  { id: 12, value: 12, label: '親部品名称' },
  { id: 13, value: 13, label: '0000*' },
];

const InitNameTypes = {
  //　図番
  DrawingNo: 0,
  // 名称
  PartName: 1,
  // 見積番号
  EstimateNo: 2,
  // 子部品図番
  ChildDrawingNo: 3,
  // 子部品名称
  ChildPartName: 4,
};

const CompanySetting = forwardRef((props, ref) => {
  const [selectedAdminItem, setSelectedAdminItem] = useState();
  const workFileInputRef = useRef(null);
  const pcFileInputRef = useRef(null);
  const excelFileRef = useRef(null);
  const reportOutputFolderRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [pcFileName, setPCFileName] = useState('');

  const [individualExcelQuickOutput, setIndividualExcelQuickOutput] = useState(true);

  const [individualFormatId, setIndividualFormatId] = useState();
  const [listExcelQuickOutput, setListExcelQuickOutput] = useState(true);
  const [listFormatId, setListFormatId] = useState();

  const [isUseSameName, setIsUseSameName] = useState();
  const [saveSameType, setSaveSameType] = useState(SaveSameType.EstimateNo);

  // CSV出力
  const [individualCSVQuickOutput, setIndividualCSVQuickOutput] = useState(true);

  const [listCSVQuickOutput, setListCSVQuickOutput] = useState(true);

  const [mitsumoriVal, setMitsumoriVal] = useState('切上げ');
  const [currency, setCurrency] = useState('￥');

  const [excelFiles, setExcelFiles] = useState('');

  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);

  const [excelFilesList, setExcelFilesList] = useState([]);

  const [csvFilesList, setCSVFilesList] = useState(csvFilesData);

  const [CSVSettingModal, setCSVSettingModal] = useState(false);

  const [adminLanguage, setAdminLanguage] = useState('日本語');
  const [adminTimeZone, setAdminTimeZone] = useState('(GMT+0:900) Asia/Tokyo');

  const [companySettingObj, setCompanySettingObj] = useState([]);
  const [companySettingBefore, setCompanySettingBefore] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [uploadedFileLst, setUploadedFileLst] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);

  //   見積金額切上げ桁数
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  //   小数点以下桁数
  const [decimalPlaces, setDecimalPlaces] = useState(0);
  /* const [excelFilesList, setExcelFilesList] = useState([]); */

  const [parentDrawingNo, setParentDrawingNo] = useState({});
  const [parentName, setParentName] = useState({});
  const [parentEstimateNo, setParentEstimateNo] = useState({});
  const [childDrawingNo, setChildDrawingNo] = useState({});
  const [childName, setChildName] = useState({});

  const [userSettingInfo, setUserSettingInfo] = useState([]);

  // IQX_WEBEST-273 帳票のExcelをダウンロードしたい IQX_WEBEST-274 帳票のExcelを削除したい
  const [isFileDownloadModal, setIsFileDownloadModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteFileNm, setDeleteFileNm] = useState('');
  const [deleteFileId, setDeleteFileId] = useState(0);
  const [isInnerDiameterIncluded, setIsInnerDiameterIncluded] = useState(false);

  const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSSSZ');

  const getFileList = async () => {
    var transformedFilesOption = [];
    try {
      const apiUrl = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting/getFileList';
      const accessToken = getAccessToken();
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      });

      if (response.data && response.status === StatusCodes.OK) {
        var uploadedFiles = response.data;
        if (uploadedFiles?.length > 0) {
          transformedFilesOption = uploadedFiles?.map((fileName, index) => ({
            id: index + 1,
            value: uploadedFiles[index],
            label: uploadedFiles[index],
            fileId: uploadedFiles[index].replace(/\.[^/.]+$/, ''), // Removes file extension
          }));
        } else {
          transformedFilesOption.push(
            uploadedFiles?.map((fileName, index) => ({
              id: index + 1,
              value: '',
              label: '',
              fileId: '',
            }))
          );
        }
        return transformedFilesOption;
      } else if (
        response.data &&
        (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.CONFLICT)
      ) {
        authorizeError(ErrorMessage().E006);
        throw new Error('Unauthorized or Conflict'); // Throw an error to indicate unauthorized or conflict
      } else if (response.data && response.status !== StatusCodes.OK) {
        error(ErrorMessage()[response.data?.messageCode]);
        throw new Error('Request failed with an error status'); // Throw an error for other error statuses
      }
    } catch (internal_err) {
      error(internal_err.message);
      throw internal_err; // Re-throw the error to propagate it to the caller
    }
  };

  useImperativeHandle(ref, () => ({
    getCompanySettingData: () => {
      return {
        companySettingData: companySettingObj,
        companySettingBefore: companySettingBefore,
        updatedExcelList: excelFilesList,
      };
    },
    updConfirmOkCompany: updConfirmOk,
  }));

  useEffect(() => {
    async function fetchData() {
      setSelectedAdminItem(props.selectedItem);
      var existedData = await getExistCompanySetting();
      var uploadedFiles = await getFileList();
      setUploadedFileLst(uploadedFiles);
      let userSettingInfo = await getExistUserSetting();
      if (userSettingInfo !== undefined && userSettingInfo?.length !== 0) {
        setUserSettingInfo(userSettingInfo[0]);
      }
      if (existedData?.length > 0 && existedData != []) {
        setIndividualExcelQuickOutput(existedData[0]?.printOutputs[PrintTypes.individual]?.isUseQuickOutput);
        setListExcelQuickOutput(existedData[0]?.printOutputs[PrintTypes.list]?.isUseQuickOutput);

        setIsUseSameName(existedData[0]?.environments?.isUseSameName);
        setSaveSameType(existedData[0]?.environments?.saveSameType);
        setExcelFilesList(
          existedData[0]?.excelFormats?.length == 0 || existedData[0]?.excelFormats == undefined
            ? [{ id: 0, title: '', useType: 0, fileName: uploadedFiles[0].value, fileId: uploadedFiles[0].fileId }]
            : existedData[0]?.excelFormats
        );
        setIsInnerDiameterIncluded(
          existedData[0]?.environments?.isInnerDiameterIncluded
            ? existedData[0]?.environments?.isInnerDiameterIncluded
            : false
        );

        if (existedData[0]?.excelFormats?.length > 0) {
          var excelData = getExcelDatabyUseType(existedData[0]?.excelFormats);
          let teset =
            existedData[0]?.printOutputs[0]?.formatsId == 0
              ? excelData[0]?.length > 0
                ? excelData[0][0].fileName
                : ''
              : existedData[0]?.printOutputs[0]?.formatsId;
          setIndividualFormatId(teset);

          setListFormatId(
            existedData[0]?.printOutputs[1]?.formatsId == 0
              ? excelData[1]?.length > 0
                ? excelData[1][0].fileName
                : ''
              : existedData[0].printOutputs[1].formatsId
          );
          /* var excelData = getExcelDatabyUseType(existedData[0]?.excelFormats);
          existedData[0].printOutputs[0].formatsId = excelData[0]?.length > 0 && existedData[0].printOutputs[0].formatsId ==0  ? excelData[0][0].id : 0;
          existedData[0].printOutputs[1].formatsId =
            excelData[1]?.length > 0 && existedData[0].printOutputs[0].formatsId == 0 ? excelData[1][0].id : 0; */
        } else {
          setListFormatId('');
          setIndividualFormatId('');
          existedData[0].printOutputs[0].formatsId = 0;
          existedData[0].printOutputs[1].formatsId = 0;
        }
        // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
        setParentDrawingNo(existedData[0]?.initNames?.parentInitNameInfo[0]);
        setParentName(existedData[0]?.initNames?.parentInitNameInfo[1]);
        setParentEstimateNo(existedData[0]?.initNames?.parentInitNameInfo[2]);
        setChildDrawingNo(existedData[0]?.initNames?.childInitNameInfo[0]);
        setChildName(existedData[0]?.initNames?.childInitNameInfo[1]);

        setCompanySettingObj(existedData[0]);
        setCompanySettingBefore(existedData[0] ? JSON.parse(JSON.stringify(existedData[0])) : []); // IQX_WEBEST-320 設定画面で保存を行った後の操作時に表示される注意ダイアログをやめたいです。
        setIsExist(true);
      } else {
        setExcelFilesList([{ id: 0, title: '', useType: 0, fileName: uploadedFiles[0].value, fileId: '' }]);
        let constantObj = {
          created: currentDateTime,
          modified: currentDateTime,
          languages: { language: 0, timeZone: 0 },
          environments: {
            workFolderPath: '',
            localSavePath: '',
            isUseSameName: true,
            saveSameType: SaveSameType.EstimateNo,
            isBacthPartsAdd: true,
            isEachConfirmSameProcess: true,
            roundUpDigits: 1,
            roundUpMethod: 0,
            decimalPlaces: 1,
            currencySymbol: 0,
          },
          initNames: { parentInitNameInfo: [], childInitNameInfo: [] },
          excelFormats: { id: 0, title: '', useType: 0, fileName: '', fileId: '' },
          csvFormats: { id: 0, title: '', useType: 0, fileName: '', fileId: '' },
          printOutputs: [
            {
              printType: PrintTypes.individual,
              isUseQuickOutput: true,
              formatsId: 0,
            },
            {
              printType: PrintTypes.list,
              isUseQuickOutput: true,
              formatsId: 0,
            },
          ],
          csvOutputs: { printType: 0, isUseQuickOutput: true, formatsId: 0 },
          info: { isUsed: true, sortNum: 0, remarks: '' },
        };
        setCompanySettingObj(JSON.parse(JSON.stringify(constantObj)));
        setCompanySettingBefore(JSON.parse(JSON.stringify(constantObj)));
        setListExcelQuickOutput(true);
        setIndividualExcelQuickOutput(true);
        setIsUseSameName(true);
        setSaveSameType(SaveSameType.EstimateNo);
        setListFormatId('');
        setIndividualFormatId('');
        setIsExist(false);
      }
    }
    fetchData();
  }, [props.selectedItem]);

  useEffect(() => {
    if (uploadedFileLst[0] !== undefined && uploadedFileLst[0]?.value != '' && uploadedFileLst?.length == 1) {
      setExcelFilesList([{ id: 0, title: '', useType: 0, fileName: uploadedFileLst[0]?.value, fileId: '' }]);
    }
  }, [uploadedFileLst]);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Shows a "copy" cursor when dragging over the component
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}CompanySetting/uploadFile`;

      // Add authorization header with bearer token
      const accessToken = getAccessToken();
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      };

      const response = await axios.post(apiUrl, formData, { headers });

      if (response && response.status === StatusCodes.OK) {
        complete('ファイルのアップロードは正常に完了しました。');
        var uploadFiles = await getFileList();
        setUploadedFileLst(uploadFiles);
        //setExcelFilesList([{ id: 0, title: '', useType: 0, fileName: uploadFiles[0].value, fileId: '' }]);
      } else if (
        response &&
        (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.CONFLICT)
      ) {
        authorizeError(ErrorMessage().E006);
        return;
      } else if (response && response.status !== StatusCodes.OK) {
        error(ErrorMessage()[response?.messageCode]);
        return;
      }
    } catch (internal_error) {
      error(internal_error.message);
      /* console.error('Error uploading file:', error.response ? error.response.data : error.message); */
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    const excelFiles = droppedFiles?.filter(
      (file) => file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // for .xlsx files
    );

    if (excelFiles?.length > 0) {
      setFiles(excelFiles);
      handleFileUpload(excelFiles[0]);
    } else {
    }
  };

  const browseWorkFiles = () => {
    workFileInputRef.current.click();
  };
  const browsePCFiles = () => {
    pcFileInputRef.current.click();
  };
  const browseExcelFiles = () => {
    excelFileRef.current.click();
  };
  // IQX_WEBEST-273 帳票のExcelをダウンロードしたい IQX_WEBEST-274 帳票のExcelを削除したい
  const openDownloadFileModal = () => {
    setIsFileDownloadModal(true);
  };
  const browseIndividualFile = () => {
    reportOutputFolderRef.current.click();
  };

  const handleWorkFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setFileName(selectedFile.webkitRelativePath);
  };
  const handlePCFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // setPCFileName(selectedFile.name);
      setPCFileName(selectedFile.webkitRelativePath);
    } else {
      setPCFileName('');
    }
  };

  const handleExcelFileChange = (event) => {
    /* const selectedFile = event.target.files[0];
    if (selectedFile) {
      setExcelFiles(selectedFile.name);
      setSelectedFile(selectedFile);
    } else {
      setExcelFiles('');
    } */
    handleFileUpload(event.target.files[0]);
  };

  const adminLanguageSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>言語とタイムゾーンの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>言語</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={companySettingObj?.languages?.language}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  languages: {
                    ...prevData?.languages,
                    language: e,
                  },
                }));
              }}
            >
              {Object.entries(languages)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ユーザー設定がされている場合、ユーザー設定が優先されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>タイムゾーン</label>
        </Row>
        <Row>
          <Col span={8}>
            　{' '}
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={companySettingObj?.languages?.timeZone}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  languages: {
                    ...prevData?.languages,
                    timeZone: e,
                  },
                }));
              }}
            >
              {Object.entries(timeZones)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key === 'JP' ? '(GMT+0:900) Asia/Tokyo' : ''}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                //setSaveConfirm(true);

                setSaveConfirm(userSettingInfo?.massages?.editOk);
                if (!userSettingInfo?.massages?.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const increaseEstimatedAmount = (lblName) => {
    if (lblName === 'estimate') {
      //setEstimatedAmount((prevCount) => prevCount + 1);
      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          roundUpDigits: prevData?.environments?.roundUpDigits + 1,
        },
      }));
    } else {
      //setDecimalPlaces((prevCount) => prevCount + 1);
      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          decimalPlaces: prevData?.environments?.decimalPlaces + 1,
        },
      }));
    }
  };
  const decreaseEstimatedAmount = (lblName) => {
    if (lblName === 'estimate') {
      // setEstimatedAmount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));

      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          roundUpDigits: prevData?.environments?.roundUpDigits > 0 ? prevData?.environments?.roundUpDigits - 1 : 0,
        },
      }));
    } else {
      // setDecimalPlaces((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          decimalPlaces: prevData?.environments?.decimalPlaces > 0 ? prevData?.environments?.decimalPlaces - 1 : 0,
        },
      }));
    }
  };

  const editCSVSetting = () => {
    setCSVSettingModal(true);
  };
  const handleIncrease = (e, key, name) => {
    if (name === 'excel') {
      // const data= { key: 1, title: '', useFor: '', fileName: '', note: '' }
      const copyData = excelFilesList.slice(); // Create a copy of the original array

      const insertIndex = excelFilesList?.findIndex((item, index) => index === key);
      //const insertIndex = excelFilesList?.map((item,index)=> index ===key);
      const largestId = excelFilesList?.reduce((maxId, item) => {
        return item.key > maxId ? item.key : maxId;
      }, 0);

      if (insertIndex !== -1) {
        const data = {
          id: 0,
          title: '',
          useType: PrintTypes.individual,
          fileName: uploadedFileLst[0].value,
          fileId: '',
        };
        copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
        setExcelFilesList(copyData);
      }
    } else {
      const copyData = csvFilesList.slice(); // Create a copy of the original array

      const insertIndex = csvFilesList?.findIndex((item) => item.key === key); // Find the clicked index of the item
      const largestId = csvFilesList?.reduce((maxId, item) => {
        return item.key > maxId ? item.key : maxId;
      }, 0);

      if (insertIndex !== -1) {
        const data = {
          key: largestId + 1,
          title: '',
          useFor: '',
          examples: '',
        };
        copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
        setCSVFilesList(copyData);
      }
    }
  };
  const handleDecrease = (e, key, name) => {
    if (name === 'excel') {
      if (excelFilesList?.length > 1) {
        const updatedData = excelFilesList?.filter((item, index) => index !== key);
        setExcelFilesList(updatedData);
      }
    } else {
      if (csvFilesList?.length > 1) {
        const updatedData = csvFilesList?.filter((item, index) => index !== key);
        setCSVFilesList(updatedData);
      }
    }
  };
  const environmentSetting = (
    <>
      <Row className="mt-4">
        {/* <label style={{ fontWeight: 800, fontSize: '13.5px' }}>環境の設定</label> */}
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>保存時の設定</label>
      </Row>

      {/* <Row className="mt-2">
          <label>ワークフォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={workFileInputRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
              directory=""
              webkitdirectory=""
              // webkitRelativePath
            />
            <Button className="referenceButton" onClick={browseWorkFiles} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row>

        <Row className="mt-4">
          <label>PC保存フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={pcFileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={pcFileInputRef}
              directory=""
              webkitdirectory=""
              style={{ display: 'none' }}
              onChange={handlePCFileChange}
            />
            <Button className="referenceButton" onClick={browsePCFiles} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">保存時の設定</Row>
        <Row className="mt-2">
          <Checkbox checked={saveZumen} onChange={(e) => setSaveZumen(e.target.checked)}>
            下敷き図面を保存する
          </Checkbox>
        </Row>
        <Row className="mt-1">
          <Checkbox checked={saveImage} onChange={(e) => setSaveImage(e.target.checked)}>
            イメージを保存する
          </Checkbox>
        </Row>
        <Row className="mt-1">
          <Checkbox checked={calculationOnSave} onChange={(e) => setCalculationOnSave(e.target.checked)}>
            保存時に数量別計算を実行する
          </Checkbox>
        </Row>
        <Row className="mt-1">
          <Checkbox checked={saveWithSameName} onChange={(e) => setSaveWithSameName(e.target.checked)}>
            親図番の同一名の保存を許可する
          </Checkbox>
        </Row>
        <Row className="mt-4">子部品追加の設定</Row>
        <Row className="mt-2">
          <Checkbox checked={addMultiChildParts} onChange={(e) => setAddMultiChildParts(e.target.checked)}>
            子部品追加時に個数を指定して複数同時追加を行う
          </Checkbox>
        </Row>
        <Row className="mt-4">工程選択の設定</Row>
        <Row className="mt-2">
          <Checkbox checked={showConfirmDialogue} onChange={(e) => setShowConfirmDialogue(e.target.checked)}>
            工程選択を行ったときに他の子部品も同じ変更を行うか確認ウィンドウを表示する
          </Checkbox>
        </Row> */}

      {/* <Row className="mt-4">金額表示の設定</Row>
        <Row align="middle" className="mt-2">
          <Col span={2}>見積金額切上げ桁数</Col>
          <Col span={2} style={{ textAlign: 'end' }}>
            <Row>
              <Col span={18}>
                <Input value={companySettingObj?.environments?.roundUpDigits} style={{ width: '60%' }}></Input>
              </Col>
              <Col span={4}>
                <Row>
                  <CaretUpOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => increaseEstimatedAmount('estimate')}
                  />
                </Row>
                <Row>
                  <CaretDownOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => decreaseEstimatedAmount('estimate')}
                  />
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={3}>
            <Select
              style={{ fontSize: 11, width: '100%' }}
              value={companySettingObj?.environments?.roundUpMethod}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  environments: {
                    ...prevData?.environments,
                    roundUpMethod: e,
                  },
                }));
              }}
            >
              {Object.entries(RoundUpMethods)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row align="middle" className="mt-2">
          <Col span={2}>小数点以下桁数</Col>
          <Col span={2} style={{ textAlign: 'end' }}>
            <Row>
              <Col span={18}>
                <Input value={companySettingObj?.environments?.decimalPlaces} style={{ width: '60%' }}></Input>
              </Col>
              <Col span={4}>
                <Row>
                  <CaretUpOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => increaseEstimatedAmount('decimal')}
                  />
                </Row>
                <Row>
                  <CaretDownOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => decreaseEstimatedAmount('decimal')}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2" align="middle">
          <Col span={2}>通貨記号</Col>
          <Col span={5}>
            <Select
              style={{ fontSize: 11, width: '88%', marginLeft: '2.5rem' }}
              value={companySettingObj?.environments?.currencySymbol}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  environments: {
                    ...prevData?.environments,
                    currencySymbol: e,
                  },
                }));
              }}
            >
              {Object.entries(CurrencySymbols)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {curSymbol[value]}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row> */}
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-1">
          <Checkbox
            style={{ alignSelf: 'center' }}
            checked={isUseSameName}
            onChange={(e) => {
              if (companySettingObj?.environments) {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  environments: { ...prevData.environments, isUseSameName: e.target.checked },
                }));
              }
              setIsUseSameName(e.target.checked);
            }}
          >
            同一名の保存を許可する
          </Checkbox>
          <Select
            style={{ fontSize: 11, width: '10%', marginLeft: '1rem' }}
            value={saveSameType}
            onChange={(e) => {
              setCompanySettingObj((prevData) => ({
                ...prevData,
                environments: {
                  ...prevData?.environments,
                  saveSameType: e,
                },
              }));
              setSaveSameType(e);
            }}
          >
            {saveSameTypes?.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </div>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>内径含むの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-1">
          <Checkbox
            style={{ alignSelf: 'center' }}
            checked={isInnerDiameterIncluded}
            onChange={(e) => {
              if (companySettingObj?.environments) {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  environments: { ...prevData.environments, isInnerDiameterIncluded: e.target.checked },
                }));
              }
              setIsInnerDiameterIncluded(e.target.checked);
            }}
          >
            内径含む
          </Checkbox>
        </Row>
      </div>
      <Row style={{ marginTop: '4rem' }}>
        <Col span={7} style={{ textAlign: 'right' }}>
          <Button
            className="mainButton"
            onClick={() => {
              setSaveConfirm(userSettingInfo?.massages?.editOk);
              if (!userSettingInfo?.massages?.editOk) {
                updConfirmOk();
              }
            }}
          >
            保存
          </Button>
        </Col>
      </Row>
    </>
  );
  const exportSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>出力フォーマットの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>Excelフォーマット管理</label>
        </Row>
        <div
          className="col-md-10"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{ border: `1px dashed ${dragging ? 'blue' : 'black'}`, padding: '20px', marginTop: '0.5em' }}
        >
          <Row justify="center" align="middle">
            <label>ファイルをドラック＆ドロップかクリックでアップロード　または　</label>{' '}
            <input
              type="file"
              accept=".xlsx"
              ref={excelFileRef}
              style={{ display: 'none' }}
              onChange={handleExcelFileChange}
              onClick={(e) => (e.target.value = null)}
            />
            <Button onClick={browseExcelFiles} style={{ backgroundColor: '#005fab', color: 'white' }}>
              ファイルを選択
            </Button>
            {/** IQX_WEBEST-273 帳票のExcelをダウンロードしたい IQX_WEBEST-274 帳票のExcelを削除したい */}
            <Button
              onClick={openDownloadFileModal}
              style={{ marginLeft: 30, backgroundColor: '#005fab', color: 'white' }}
            >
              ファイル一覧表示
            </Button>
          </Row>
        </div>
        {/*  <div className="overflow-auto" style={{ height: '100px' }}>
          {files?.length > 0 ? (
            <>
              <Row>
                <ul>
                  {files?.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </Row>
              <Row justify="center">
                <Col span={11}>
                  <Button
                    className="mainButton"
                    onClick={() => {
                      handleFileUpload();
                    }}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            </>
          ) : excelFiles?.length > 0 ? (
            <>
              <Row></Row>
              <Row justify="center">
                <Col span={11}>
                  <Button
                    className="mainButton"
                    onClick={() => {
                      handleFileUpload();
                    }}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </div> */}
        <div
          className="registerModal mt-4 chargeAmount overflow-auto"
          style={{ height: 245, overflowY: 'scroll', width: '66%' }}
        >
          <Table>
            <thead>
              <tr style={{ height: '42px', verticalAlign: 'middle' }}>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  タイトル
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  利用シーン
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  ファイル名
                </td>
                {/*  <td className="tbl-header fixedHeader" style={{ width: '15%' }}></td> */}
                <td style={{ border: 'none', backgroundColor: 'white' }} className="fixedHeader"></td>
              </tr>
            </thead>
            <tbody>
              {excelFilesList?.map((item, index) => (
                <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle', height: '40px' }}>
                  <td>
                    <Input
                      value={item.title}
                      onChange={(e) => {
                        setExcelFilesList((prevData) => {
                          const newData = [...prevData];
                          newData[index] = {
                            ...newData[index],
                            title: e.target.value,
                            fileId: e.target.value,
                          };
                          return newData;
                        });
                      }}
                    ></Input>
                  </td>
                  <td>
                    <Select
                      style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
                      value={item.useType}
                      onChange={(e) => {
                        setExcelFilesList((prevData) => {
                          const newData = [...prevData];
                          newData[index] = {
                            ...newData[index],
                            useType: e,
                          };
                          return newData;
                        });
                      }}
                    >
                      {Object.entries(PrintTypes)?.map(([key, value]) => (
                        <Select.Option key={value} value={value}>
                          {riyoushin[value]}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Select
                      style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
                      value={item.fileName}
                      onChange={(e) => {
                        setExcelFilesList((prevData) => {
                          const newData = [...prevData];
                          newData[index] = {
                            ...newData[index],
                            fileName: e,
                            fileId: e,
                          };
                          return newData;
                        });
                      }}
                    >
                      {uploadedFileLst?.map((item, index) => (
                        <Select.Option key={index} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  {/* <td>{item.note}</td> */}
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 5, marginRight: 5 }}
                        onClick={(e) => handleIncrease(e, index, 'excel')}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(e) => handleDecrease(e, index, 'excel')}
                      />
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* <Row className="mt-4">
          <label>CSVフォーマット管理</label>
        </Row>
        <div
          className="registerModal mt-2 chargeAmount overflow-auto"
          style={{ height: 245, overflowY: 'scroll', width: '66%' }}
        >
          <Table>
            <thead>
              <tr style={{ height: '42px', verticalAlign: 'middle' }}>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  タイトル
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  利用シーン
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '30%' }}>
                  出力例
                </td>
                <td style={{ border: 'none', backgroundColor: 'white' }} className="fixedHeader"></td>
              </tr>
            </thead>
            <tbody>
              {csvFilesList?.map((item, index) => (
                <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <td>
                    <Input value={item.title}></Input>
                  </td>
                  <td>
                    <Select style={{ fontSize: 11, width: '100%', textAlign: 'left' }} value={item.useFor}>
                      {outputOptions?.map((item) => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                    <Row>
                      <Col span={22}>{item.examples}</Col>
                      <Col span={2}>
                        <EditOutlined
                          style={{ color: '#1063aa', fontSize: 20, stroke: 'white' }}
                          onClick={editCSVSetting}
                        />
                      </Col>
                    </Row>
                  </td>
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 5, marginRight: 5 }}
                        onClick={(e) => handleIncrease(e, item.key, 'csv')}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(e) => handleDecrease(e, item.key, 'csv')}
                      />
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div> */}
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className={uploadedFileLst[0] == undefined || uploadedFileLst[0]?.length == 0 ? '' : 'mainButton'}
              onClick={() => {
                setSaveConfirm(userSettingInfo?.massages?.editOk);
                if (!userSettingInfo?.massages?.editOk) {
                  updConfirmOk();
                }
              }}
              disabled={uploadedFileLst[0] == undefined || uploadedFileLst[0]?.length == 0}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
  const reportSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>帳票の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">個別帳票</Row>
        <Row className="mt-1">
          <Checkbox
            checked={individualExcelQuickOutput}
            onChange={(e) => {
              if (companySettingObj?.printOutputs?.length > 0) {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  printOutputs: prevData?.printOutputs?.map((output, index) => {
                    if (output.printType === PrintTypes.individual) {
                      return {
                        ...output,
                        isUseQuickOutput: e.target.checked,
                      };
                    }
                    return output;
                  }),
                }));
              }
              setIndividualExcelQuickOutput(e.target.checked);
            }}
          >
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row>
          <Checkbox
            checked={individualAutoExcelExport}
            onChange={(e) => setIndividualAutoExcelExport(e.target.checked)}
          >
            帳票出力後、Excelファイルを自動で開く
          </Checkbox>
        </Row> */}
        {/* <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力ファイル</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={individualFormatId}
              onChange={(e) => {
                if (companySettingObj?.printOutputs?.length > 0) {
                  setCompanySettingObj((prevData) => ({
                    ...prevData,
                    printOutputs: prevData?.printOutputs?.map((output, index) => {
                      if (output.printType === PrintTypes.individual) {
                        // Update the second object while leaving others unchanged
                        return {
                          ...output,
                          formatsId: e,
                        };
                      }
                      return output; // Leave other objects unchanged
                    }),
                  }));
                }
                setIndividualFormatId(e);
              }}
            >
              {excelFilesList[0]?.id !== 0 ? (
                excelFilesList?.map((item) => {
                  if (item.useType === 0) {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item?.fileName}
                      </Select.Option>
                    );
                  }
                  return null; // Return null for items that don't meet the condition
                })
              ) : (
                <Select.Option></Select.Option>
              )}
            </Select>
          </Col>
        </Row>
        {/* list  */}
        <Row style={{ marginTop: '2.5rem' }}>リスト帳票</Row>
        <Row className="mt-1">
          <Checkbox
            checked={listExcelQuickOutput}
            onChange={(e) => {
              if (companySettingObj?.printOutputs?.length > 0) {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  printOutputs: prevData?.printOutputs?.map((output, index) => {
                    if (output.printType === PrintTypes.list) {
                      return {
                        ...output,
                        isUseQuickOutput: e.target.checked,
                      };
                    }
                    return output;
                  }),
                }));
              }
              setListExcelQuickOutput(e.target.checked);
            }}
          >
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row>
          <Checkbox checked={listAutoExcelExport} onChange={(e) => setListAutoExcelExport(e.target.checked)}>
            帳票出力後、Excelファイルを自動で開く
          </Checkbox>
        </Row> */}
        {/* <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力ファイル</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              // value={excelFilesList[0]?.id != 0 ? listFormatId : ''}
              value={listFormatId}
              onChange={(e) => {
                if (companySettingObj?.printOutputs?.length > 0) {
                  setCompanySettingObj((prevData) => ({
                    ...prevData,
                    printOutputs: prevData?.printOutputs?.map((output, index) => {
                      if (output.printType === PrintTypes.list) {
                        // Update the second object while leaving others unchanged
                        return {
                          ...output,
                          formatsId: e,
                        };
                      }
                      return output;
                    }),
                  }));
                }
                setListFormatId(e);
              }}
            >
              {excelFilesList[0]?.id !== 0 ? (
                excelFilesList?.map((item) => {
                  if (item?.useType === 1) {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item?.fileName}
                      </Select.Option>
                    );
                  }
                  return null; // Return null for items that don't meet the condition
                })
              ) : (
                <Select.Option></Select.Option>
              )}
              {/* {formatLst != [] || formatLst != undefined ? (
                formatLst?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.fileName}
                  </Select.Option>
                ))
              ) : (
                
              )} */}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button
              className={excelFilesList[0]?.id == 0 ? '' : 'mainButton'}
              onClick={() => {
                setSaveConfirm(true);
              }}
              disabled={excelFilesList[0]?.id == 0}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
  const csvExportSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>CSV出力の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">個別帳票</Row>
        <Row className="mt-1">
          <Checkbox checked={individualCSVQuickOutput} onChange={(e) => setIndividualCSVQuickOutput(e.target.checked)}>
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row className="mt-1">
          <Checkbox checked={individualAutoCSVExport} onChange={(e) => setIndividualAutoCSVExport(e.target.checked)}>
            帳票出力後、CSVファイルを自動で開く
          </Checkbox>
        </Row>
        <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力形式</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select style={{ width: '100%' }} value={1}>
              <Select.Option value={1}>生産管理連携_最新</Select.Option>
            </Select>
          </Col>
        </Row>
        {/* list  */}
        <Row style={{ marginTop: '2.5rem' }}>リスト帳票</Row>
        <Row className="mt-1">
          <Checkbox checked={listCSVQuickOutput} onChange={(e) => setListCSVQuickOutput(e.target.checked)}>
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row className="mt-1">
          <Checkbox checked={listAutoCSVExport} onChange={(e) => setListAutoCSVExport(e.target.checked)}>
            帳票出力後、CSVファイルを自動で開く
          </Checkbox>
        </Row>
        <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力ファイル</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select style={{ width: '100%' }} value={1}>
              <Select.Option value={1}>親備品リス</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button className="mainButton">保存</Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const defaultNameSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>親部品の初期名称の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        {/** 図番 */}
        <Row className="mt-2">
          <label>図番</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input
              name="input1"
              value={parentDrawingNo?.input1}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.input1 = e.target.value;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5 }}
              className="input-editable"
            />
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input2"
              value={parentDrawingNo.input2}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.input2 = e.target.value;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, width: '70%', textAlign: 'center' }}
              className="input-editable"
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentDrawingNo.select1}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.select1 = e;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input3"
              value={parentDrawingNo.input3}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.input3 = e.target.value;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable"
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentDrawingNo.select2}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.select2 = e;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input4"
              value={parentDrawingNo.input4}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.input4 = e.target.value;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable"
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentDrawingNo.select3}
              onChange={(e) => {
                const data = { ...parentDrawingNo };
                data.select3 = e;
                setParentDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/** 名称 */}
        <Row className="mt-2">
          <label>名称</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input
              name="input1"
              value={parentName?.input1}
              onChange={(e) => {
                const data = { ...parentName };
                data.input1 = e.target.value;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5 }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input2"
              value={parentName.input2}
              onChange={(e) => {
                const data = { ...parentName };
                data.input2 = e.target.value;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentName.select1}
              onChange={(e) => {
                const data = { ...parentName };
                data.select1 = e;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input3"
              value={parentName.input3}
              onChange={(e) => {
                const data = { ...parentName };
                data.input3 = e.target.value;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentName.select2}
              onChange={(e) => {
                const data = { ...parentName };
                data.select2 = e;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input4"
              value={parentName.input4}
              onChange={(e) => {
                const data = { ...parentName };
                data.input4 = e.target.value;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentName.select3}
              onChange={(e) => {
                const data = { ...parentName };
                data.select3 = e;
                setParentName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/** 見積番号 */}
        <Row className="mt-2">
          <label>見積番号</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input
              name="input1"
              value={parentEstimateNo?.input1}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.input1 = e.target.value;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5 }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input2"
              value={parentEstimateNo.input2}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.input2 = e.target.value;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentEstimateNo.select1}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.select1 = e;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input3"
              value={parentEstimateNo.input3}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.input3 = e.target.value;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentEstimateNo.select2}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.select2 = e;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input4"
              value={parentEstimateNo.input4}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.input4 = e.target.value;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={parentEstimateNo.select3}
              onChange={(e) => {
                const data = { ...parentEstimateNo };
                data.select3 = e;
                setParentEstimateNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.parentInitNameInfo[2] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {parentnamingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      <Row style={{ marginTop: '2.5rem' }}>
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>子部品の初期名称の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        {/** 図番 */}
        <Row className="mt-2">
          <label>図番</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input
              name="input1"
              value={childDrawingNo?.input1}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.input1 = e.target.value;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5 }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input2"
              value={childDrawingNo.input2}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.input2 = e.target.value;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childDrawingNo.select1}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.select1 = e;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input3"
              value={childDrawingNo.input3}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.input3 = e.target.value;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childDrawingNo.select2}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.select2 = e;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input4"
              value={childDrawingNo.input4}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.input4 = e.target.value;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childDrawingNo.select3}
              onChange={(e) => {
                const data = { ...childDrawingNo };
                data.select3 = e;
                setChildDrawingNo(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[0] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/** 子部品名称 */}
        <Row className="mt-2">
          <label>子部品名称</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input
              name="input1"
              value={childName?.input1}
              onChange={(e) => {
                const data = { ...childName };
                data.input1 = e.target.value;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5 }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input2"
              value={childName.input2}
              onChange={(e) => {
                const data = { ...childName };
                data.input2 = e.target.value;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childName.select1}
              onChange={(e) => {
                const data = { ...childName };
                data.select1 = e;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input3"
              value={childName.input3}
              onChange={(e) => {
                const data = { ...childName };
                data.input3 = e.target.value;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childName.select2}
              onChange={(e) => {
                const data = { ...childName };
                data.select2 = e;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input
              name="input4"
              value={childName.input4}
              onChange={(e) => {
                const data = { ...childName };
                data.input4 = e.target.value;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
              style={{ padding: 5, textAlign: 'center', width: '70%' }}
              className="input-editable percent-input "
            />
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childName.select3}
              onChange={(e) => {
                const data = { ...childName };
                data.select3 = e;
                setChildName(data);
                const initNamesCopy = { ...companySettingObj?.initNames };
                initNamesCopy.childInitNameInfo[1] = data;
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  initNames: initNamesCopy,
                }));
              }}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginTop: '4rem' }}>
          <Col span={14} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingInfo?.massages?.editOk);
                if (!userSettingInfo?.massages?.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  async function addCompanySetting(companySetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          companySettingType: selectedAdminItem,
          Langs: companySetting.languages,
          Envs: companySetting.environments,
          InitNames: companySetting.initNames,
          ExcelFormats: companySetting.excelFormats,
          CSVFormats: companySetting.csvFormats,
          PrintOutputs: companySetting.printOutputs,
          CSVOutputs: companySetting.csvOutputs,
          SettingInfo: companySetting.info,
          downloadFormatId: 0,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            if (ret) {
              props?.showCompleteMsg();
              setCompanySettingBefore(JSON.parse(JSON.stringify(companySettingObj)));
            }
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            if (data?.messageCode == 'E020' || data?.messageCode == 'E007') {
              let errorMsg = '';
              if (data?.messageCode === 'E020') {
                errorMsg = ErrorMessage('ファイル名「' + data.detailMessage + '」')[data?.messageCode];
              } else if (data?.messageCode === 'E007') {
                errorMsg =
                  '出力ファイルを選択してください。選択物がない場合、出力フォーマットにフォーマットをさきに設定してください。';
              }

              error(errorMsg);
              return;
            } else {
              error(ErrorMessage()[data?.messageCode]);
              return;
            }
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    ret?.length > 0 && ret != undefined ? setIsExist(true) : setIsExist(false);
    return ret;
  }

  async function updateCompanySetting(companySetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          companySettingType: selectedAdminItem,
          Langs: companySetting.languages,
          Envs: companySetting.environments,
          InitNames: companySetting.initNames,
          ExcelFormats: companySetting.excelFormats,
          CSVFormats: companySetting.csvFormats,
          PrintOutputs: companySetting.printOutputs,
          CSVOutputs: companySetting.csvOutputs,
          SettingInfo: companySetting.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            if (ret) {
              props?.showCompleteMsg();
              setCompanySettingBefore(JSON.parse(JSON.stringify(companySettingObj)));
            }
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            if (data?.messageCode == 'E020' || data?.messageCode == 'E007') {
              let errorMsg = '';
              if (data?.messageCode === 'E020') {
                errorMsg = ErrorMessage('ファイル名「' + data.detailMessage + '」')[data?.messageCode];
              } else if (data?.messageCode === 'E007') {
                errorMsg =
                  '出力ファイルを選択してください。選択物がない場合、出力フォーマットにフォーマットをさきに設定してください。';
              }
              error(errorMsg);
              return;
            } else {
              error(ErrorMessage()[data?.messageCode]);
              return;
            }
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const updConfirmOk = async (e) => {
    companySettingObj.excelFormats = excelFilesList;
    if (isExist) {
      await updateCompanySetting(companySettingObj);
    } else {
      await addCompanySetting(companySettingObj);
    }
    setSaveConfirm(false);
  };

  const updConfirmCancel = () => {
    setSaveConfirm(false);
  };

  // 表示するフォーム
  const displayForm = (selectedAdminItem) => {
    if (selectedAdminItem === '0') {
      return adminLanguageSetting;
    } else if (selectedAdminItem === '1') {
      return environmentSetting;
    } else if (selectedAdminItem === '2') {
      // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
      return defaultNameSetting;
    } else if (selectedAdminItem === '3') {
      return exportSetting;
    } else if (selectedAdminItem === '4') {
      return reportSetting;
    }
  };

  // IQX_WEBEST-273 帳票のExcelをダウンロードしたい IQX_WEBEST-274 帳票のExcelを削除したい
  const downloadOk = async (id, fileNm) => {
    if (document.getElementById('rf' + deleteFileId)) {
      document.getElementById('rf' + deleteFileId).style.backgroundColor = 'white';
    }
    setDeleteFileNm(fileNm);
    setDeleteFileId(id);
    if (document.getElementById('rf' + id)) {
      document.getElementById('rf' + id).style.backgroundColor = '#fcfade';
    }
    const ExcelJS = require('exceljs');
    const s3Stream = await downloadS3FileContent(fileNm);
    if (s3Stream) {
      const workbookIndividual = new ExcelJS.Workbook();
      await workbookIndividual.xlsx.load(s3Stream);
      let uint8Array = await workbookIndividual.xlsx.writeBuffer();
      let blob = new Blob([uint8Array], { type: 'application/octet-binary' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileNm;
      link.click();
      link.remove();
    }
  };

  // IQX_WEBEST-273 帳票のExcelをダウンロードしたい
  const downloadCancel = () => {
    setIsFileDownloadModal(false);
  };

  //  IQX_WEBEST-274 帳票のExcelを削除したい
  const deleteClick = (fileId, fileNm) => {
    if (document.getElementById('rf' + deleteFileId)) {
      document.getElementById('rf' + deleteFileId).style.backgroundColor = 'white';
    }
    setIsDelete(true);
    setDeleteFileNm(fileNm);
    setDeleteFileId(fileId);
    if (document.getElementById('rf' + fileId)) {
      document.getElementById('rf' + fileId).style.backgroundColor = '#fcfade';
    }
  };

  //  IQX_WEBEST-274 帳票のExcelを削除したい
  const deleteOk = async () => {
    setIsDelete(false);
    const result = await deleteS3FileContent(deleteFileNm);
    if (result) {
      complete('データ削除は正常に完了しました。');
      var uploadFiles = await getFileList();
      setUploadedFileLst(uploadFiles);
      if (document.getElementById('rf' + deleteFileId)) {
        document.getElementById('rf' + deleteFileId).style.backgroundColor = 'white';
      }
    }
  };

  //  IQX_WEBEST-274 帳票のExcelを削除したい
  const deleteCancel = () => {
    setIsDelete(false);
  };

  // IQX_WEBEST-273 帳票のExcelをダウンロードしたい IQX_WEBEST-274 帳票のExcelを削除したい
  const childModalTitle = (
    <div
      style={{
        width: 850,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>ファイル一覧表示</p>
    </div>
  );

  // IQX_WEBEST-274 帳票のExcelを削除したい
  const deleteFileContent = <p style={{ fontSize: '13.5px' }}>ファイルを削除します。よろしいでしょうか。</p>;

  return (
    <>
      <div>{displayForm(selectedAdminItem)}</div>
      {/* {CSVSettingModal ? <CSVSetting csvModal={CSVSettingModal} /> : ''} */}

      {/** 新規追加破棄確認ポップアップ */}
      {saveConfirm &&
        commonModal(
          saveConfirm,
          confirmModalTitle,
          commonFooter(updConfirmOk, updConfirmCancel),
          null,
          400,
          updConfirmOk,
          updConfirmCancel,
          confirmUpdateContent,
          null,
          null,
          false
        )}
      {/* ファイル一覧表示　IQX_WEBEST-273 帳票のExcelをダウンロードしたい  IQX_WEBEST-274 帳票のExcelを削除したい*/}
      <Modal
        maskClosable={false}
        title={childModalTitle}
        open={isFileDownloadModal}
        onCancel={downloadCancel}
        width={850}
        style={{ top: 230 }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={[]}
      >
        <div style={{ width: '100%', height: 460, overflow: 'auto', marginTop: 15 }}>
          <Row className="oyabuhin-row oyabuhin-row-bg">
            <Col span={2} className="oyabuhin-detail-col">
              <div style={{ fontWeight: 600, fontSize: '17px' }}>
                <span>No</span>
              </div>
            </Col>
            <Col span={18} className="oyabuhin-detail-col">
              <div style={{ marginTop: 2, fontWeight: 600, fontSize: '15px' }}>
                <span>ファイル名</span>
              </div>
            </Col>
            <Col span={2} className="oyabuhin-detail-col"></Col>
            <Col span={2} className="oyabuhin-detail-col"></Col>
          </Row>

          {uploadedFileLst?.map((item, index) => (
            <Row className="oyabuhin-row" id={'rf' + item.id}>
              <Col span={2} className="oyabuhin-detail-col">
                <div style={{ marginTop: 5, fontSize: '15px' }}>
                  <span>{index + 1}</span>
                </div>
              </Col>
              <Col span={18} className="oyabuhin-detail-col">
                <div style={{ marginTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '15px' }}>
                  <Popover content={item.label}>
                    <span>{item.label}</span>
                  </Popover>
                </div>
              </Col>
              <Col span={2} className="oyabuhin-detail-col" style={{ textAlign: 'center', backgroundColor: 'white' }}>
                <CloudDownloadOutlined className="download-icon" onClick={(e) => downloadOk(item.id, item.label)} />
              </Col>
              <Col span={2} className="oyabuhin-detail-col" style={{ textAlign: 'center', backgroundColor: 'white' }}>
                <CloseCircleFilled
                  className="download-delete-icon"
                  style={{ marginTop: 3 }}
                  onClick={(e) => deleteClick(item.id, item.label)}
                />
              </Col>
            </Row>
          ))}
        </div>
      </Modal>
      {/* 削除 IQX_WEBEST-274 帳票のExcelを削除したい */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            deleteFileContent,
            null,
            { top: 150 },
            false
          )
        : ''}
    </>
  );
});

export default CompanySetting;
