import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input, Checkbox } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { handleKeyPress, regexExp, toDecimal } from '../common/Common';
import { WorkType, WorkTypeGroup } from '../common/enums';

const IQ3KouteiInput_2Jikakou = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);

  useEffect(() => {
    if (props?.tabOpen) {
      const processes = props.processMaster;
      const secondaryDetails = processes?.filter((i) => i.workType === WorkType.SmSecondaryWork);
      if (secondaryDetails?.length > 0) {
        let types = secondaryDetails[0].details?.processDetailTypes?.filter(
          (i) =>
            (i.group === WorkTypeGroup.SmSecondaryWorkSize && i.isUsed) ||
            (i.group === WorkTypeGroup.SmSecondaryWorkMillimeter && i.isUsed)
        );
        setWorkTypes(types);
      }

      let data = props.secondaryWorkInfo;
      if (data != undefined) {
        setListData(data.details);
      }
    }
  }, [props.secondaryWorkInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addRow = (id) => {
    const copyData = listData?.slice(); // Create a copy of the original array

    const insertIndex = listData?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = listData?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: copyData[insertIndex].types,
        typesAutoExtract: '',
        diameter: copyData[insertIndex].diameter === null ? 0 : copyData[insertIndex].diameter,
        diameterAutoExtract: '',
        xsize: copyData[insertIndex].xsize === null ? 0 : copyData[insertIndex].xsize,
        xsizeAutoExtract: '',
        ysize: copyData[insertIndex].ysize === null ? 0 : copyData[insertIndex].ysize,
        ysizeAutoExtract: '',
        perimeter: copyData[insertIndex].perimeter === null ? 0 : copyData[insertIndex].perimeter,
        perimeterAutoExtract: '',
        count: copyData[insertIndex].count === null ? 1 : copyData[insertIndex].count,
        countAutoExtract: '',
        blankFlag: copyData[insertIndex].blankFlag,
        blankFlagAutoExtract: '',
        workTypeGroup: copyData[insertIndex].workTypeGroup,
        workTypeGroupAutoExtract: '',
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setListData(copyData);
      const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
      newSecondaryWorkInfo.details = copyData;
      props.updateSecondaryWork(newSecondaryWorkInfo);
    }
  };

  const deleteRow = (id) => {
    if (listData?.length > 1) {
      const updatedData = listData?.filter((item) => item.id !== id);

      setListData(updatedData);
      const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
      newSecondaryWorkInfo.details = updatedData;
      props.updateSecondaryWork(newSecondaryWorkInfo);
    }
  };

  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(listData));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: event };
          const getGroup = workTypes?.filter((i) => i.id === event);
          updatedRow.workTypeGroup = getGroup ? getGroup[0].group : 0;
          return updatedRow;
          // return { ...row, [types]: event };
        }
        return row;
      });
      // setListData(updatedData);
    } else if (types === 'blankFlag') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.checked };
        }
        return row;
      });
      // setListData(updatedData);
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: !regexExp.test(event.target.value) ? event.target.value : 0 };
        }
        return row;
      });
      // setListData(updatedData);
    }
    setListData(updatedData);
    const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
    newSecondaryWorkInfo.details = updatedData;
    props.updateSecondaryWork(newSecondaryWorkInfo);
  };

  const blurDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(listData));
    let updatedData = [];

    updatedData = temp?.map((row) => {
      if (row.id === id) {
        return { ...row, [types]: toDecimal(event.target.value, 2) };
      }
      return row;
    });

    setListData(updatedData);
    const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
    newSecondaryWorkInfo.details = updatedData;
    props.updateSecondaryWork(newSecondaryWorkInfo);
  };

  // IQX_WEBEST-227の対応
  const blankAllChecked = (event) => {
    const temp = JSON.parse(JSON.stringify(listData));
    let updatedData = [];
    updatedData = temp?.map((row) => {
      return { ...row, blankFlag: event.target.checked };
    });
    setListData(updatedData);
    const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
    newSecondaryWorkInfo.details = updatedData;
    props.updateSecondaryWork(newSecondaryWorkInfo);
  };
  // IQX_WEBEST-227の対応

  const gettypeName = (id) => {
    for (let i = 0; i < workTypes.length; i++) {
      if (workTypes[i].id === id && workTypes[i].isUsed) {
        return workTypes[i].name;
      }
    }
  };

  return (
    <>
      {props?.tabOpen && props?.isSecondProcess ? (
        <Row className="mt-2 registerModal">
          <Table className="kouteiinput " style={{ width: '85%' }}>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: '0.1%' }} className="tbl-header">
                  No.
                </th>
                <th style={{ width: '10.9%' }} className="tbl-header">
                  種類.形状
                </th>
                <th style={{ width: '12.2%' }} className="tbl-header">
                  Mサイズ・径(mm)
                </th>
                {/* <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th> */}
                <th style={{ width: '12.2%' }} className="tbl-header">
                  数
                </th>
                <th style={{ width: '12.2%' }} className="tbl-header">
                  ブランク
                  {/* IQX_WEBEST-227の対応 */}
                  <Checkbox
                    style={{ marginLeft: '5px' }}
                    onChange={(e) => blankAllChecked(e)}
                    checked={listData?.findIndex((data) => !data.blankFlag) === -1}
                  />
                  {/* IQX_WEBEST-227の対応 */}
                </th>
              </tr>
            </thead>
            <tbody>
              {listData?.map((i, index) => (
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {/* {editMode ? (
                    <> */}
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                      {gettypeName(i.typesAutoExtract)}
                    </label>
                    <Select
                      defaultValue={1}
                      size="middle"
                      value={i.types}
                      onChange={(e) => changeDetails(e, i.id, 'types')}
                      style={{ width: '58%', marginLeft: 9 }}
                    >
                      {workTypes
                        ?.filter((i) => i.isUsed)
                        ?.map((option) => (
                          <Select.Option value={option.id}>{option.name}</Select.Option>
                        ))}
                    </Select>
                    {/* </>
                  ) : (
                    <>{getNameById(i.types, types)}</>
                  )} */}
                  </td>

                  {/* <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td> */}
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.diameterAutoExtract}</label>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.diameter}
                      onChange={(e) => changeDetails(e, i.id, 'diameter')}
                      onBlur={(e) => blurDetails(e, i.id, 'diameter')}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                    ></Input>
                  </td>
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.countAutoExtract}</label>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.count}
                      onChange={(e) => changeDetails(e, i.id, 'count')}
                      onKeyPress={(e) => {
                        handleKeyPress(e, true);
                      }}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                    ></Input>
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {/** IQX_WEBEST-179 */}
                    <Checkbox
                      checked={i.blankFlagAutoExtract}
                      value={i.blankFlagAutoExtract}
                      onChange={(e) => changeDetails(e, i.id, 'blankFlagAutoExtract')}
                      style={{
                        marginLeft: 15,
                        width: '38%',
                        pointerEvents: 'none',
                        display: i.blankFlagAutoExtract === '' ? 'none' : '',
                      }}
                    ></Checkbox>
                    <Checkbox
                      checked={i.blankFlag}
                      value={i.blankFlag}
                      onChange={(e) => changeDetails(e, i.id, 'blankFlag')}
                      style={{
                        width: i.blankFlagAutoExtract === '' ? '0%' : '30%',
                        marginLeft: i.blankFlagAutoExtract === '' ? 41 : 9,
                      }}
                    ></Checkbox>
                  </td>
                  {/* {editMode ? (
                  <> */}
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '5.8%' }}>
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        onClick={() => addRow(i.id)}
                        style={{ marginLeft: 5, marginRight: 5 }}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={() => deleteRow(i.id)}
                        // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      />
                    </Row>
                  </td>
                  {/* </>
                ) : (
                  <>
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )} */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_2Jikakou;
