import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Input } from 'antd';
import { Table } from 'react-bootstrap';

import '../../assets/styles/common.css';
import { secondsToHms, handleKeyPress, getPrepCoeffTypeName, regexExp } from '../common/Common.js';
import ParentKouteiInput_ChildPartInfoSetting from './ParentKouteiInput_ChildPartInfoSetting';
import ParentKouteiInput_Kumitate_DetailsModal from './ParentKouteiInput_Kumitate_DetailsModal';

const ParentKouteiInput_Kumitate = forwardRef((props, ref) => {
  const [kumitateDataLst, setKumitateDataLst] = useState([]);
  const [curKumitateData, setCurKumitateData] = useState([]);
  const [curDataIndex, setCurDataIndex] = useState(0);
  // add new
  const [addModal, setAddModal] = useState(false);
  // 編集モード
  const editMode = props.editMode;
  const [isDetailsModal, setIsDetailsModal] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [purchaseCategory, setPurchaseCategory] = useState([]);
  const [kumitateJikanList, setKumitateJikanList] = useState([]);
  const [calculateType, setCalculateType] = useState();
  const [childPartsCount, setChildPartsCount] = useState();
  const [purchaseTime, setPurchaseTime] = useState();
  const [purchaseTotalTensuu, setPurchaseTotalTensuu] = useState(0);
  const [purchaseTotalInstTime, setPurchaseTotalInstTime] = useState(0);
  const [assenbleWorkBasicTime, setAssenbleWorkBasicTime] = useState(0);
  const [childPartsCountOrg, setChildPartsCountOrg] = useState(); // IQX_WEBEST-179

  useEffect(() => {
    if (props?.activeTabKey === '3' || props.isReCal) {
      const kumitateDetailsData = props?.kouteiInputInfo?.assenbleProcessInputInfo;
      setKumitateDataLst(kumitateDetailsData);

      setPurchases(props?.purchasesData);
      calculateKumitateData(props?.purchasesData);

      setPurchaseCategory(props.purchaseCategoryInfo);
      if (kumitateDetailsData) {
        setCalculateType(kumitateDetailsData?.calcType);
        setChildPartsCount(kumitateDetailsData?.smPartsCount);
        setPurchaseTime(kumitateDetailsData?.purchaseInstTime);
        setKumitateJikanList(kumitateDetailsData?.assyList);
        setChildPartsCountOrg(kumitateDetailsData?.autoExtract); // IQX_WEBEST-179
      }
      if (props?.kouteiSentakuProcessesId?.length > 0) {
        const assenbleItem = props?.kouteiSentakuProcessesId?.find((item) => item.assenbleTime);

        // Check if assenbleItem is found and get its value
        const assenbleTime = assenbleItem ? assenbleItem?.assenbleTime : 0;

        setAssenbleWorkBasicTime(assenbleTime);
      }
    }
  }, [props.purchasesData, props.purchaseCategoryInfo, props.kouteiInputInfo, props.kouteiSentakuProcessesId]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const updateChildPart = (selectedChildParts) => {
    const iQ3Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ3';
    });
    const iQ5Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ5';
    });
    const iQ7Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ7';
    });
    let sum = selectedChildParts?.reduce(function (prev, current) {
      return prev + +current.area;
    }, 0);

    const updatedChildPart = {
      key: curKumitateData?.key,
      kumitateType: curKumitateData?.kumitateType,
      childPartNm: curKumitateData?.childPartNm,
      childParts: selectedChildParts,
      iq3: iQ3Data?.length,
      iq5: iQ5Data?.length,
      iq7: iQ7Data?.length,
      sum: sum,
      purchaseCnt: curKumitateData?.purchaseCnt,
      totalCnt:
        parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length) + parseInt(purchaseCnt),
      selectedChildPartCnt: parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length),
    };
    const data = [...kumitateDataLst];
    data[curDataIndex] = updatedChildPart;
    setKumitateDataLst(data);
    setAddModal(false);
  };

  const cancelChildPart = () => {
    setAddModal(false);
  };

  const calculateKumitateData = (listData) => {
    if (listData?.length > 0) {
      let totalTensuu = listData
        ?.filter((i) => !i.isDeleted)
        ?.reduce((total, item) => {
          // Convert "required" from string to number
          const required = parseFloat(item?.required);
          // Check if "required" is a valid number
          if (!isNaN(required)) {
            return total + required;
          }
          return total;
        }, 0);
      totalTensuu = totalTensuu ? totalTensuu : 0;
      setPurchaseTotalTensuu(totalTensuu);

      let totalPurchaseInstTime = listData
        ?.filter((i) => !i.isDeleted)
        ?.reduce((total, item) => total + item?.processTime, 0);
      totalPurchaseInstTime = totalPurchaseInstTime ? totalPurchaseInstTime : 0;
      // IQX_WEBEST-282 組立工程の工程入力で購入品の取付時間が数量分かけた値になっていない
      totalPurchaseInstTime = totalPurchaseInstTime * totalTensuu;
      setPurchaseTotalInstTime(totalPurchaseInstTime);
    } else {
      setPurchaseTotalTensuu(0);
      setPurchaseTotalInstTime(0);
    }
  };

  const content = (
    <>
      <tr>
        <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          1
        </td>
        <td style={{ verticalAlign: 'middle' }}>{getPrepCoeffTypeName(calculateType)}</td>
        <td style={{ verticalAlign: 'middle' }}>
          {/** IQX_WEBEST-179 */}
          <label style={{ marginLeft: 0, width: '40%', textAlign: 'center' }}>{childPartsCountOrg}</label>
          <Input
            name="types"
            value={childPartsCount}
            style={{ textAlign: 'center', width: '53%', marginLeft: 9 }}
            className={editMode ? 'input-editable ' : 'input-non-editable '}
            onChange={(e) => {
              if (regexExp.test(e.target.value)) {
                e.target.value = 0;
              }
              setChildPartsCount(e.target.value);
              kumitateDataLst.smPartsCount = e.target.value;
              props.updatedKumitateProcessInput(kumitateDataLst);
            }}
            onKeyPress={(e) => {
              handleKeyPress(e, true);
            }}
          ></Input>
        </td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          {secondsToHms(childPartsCount * assenbleWorkBasicTime)}
        </td>
        <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          {secondsToHms(childPartsCount * assenbleWorkBasicTime + purchaseTotalInstTime)}
        </td>

        {/* <td style={{ verticalAlign: 'middle', display: 'none' }}>
          <Row justify="center">
            <Button
              key="submit"
              className="mainButton"
              onClick={showDetailsModal}
              style={{ pointerEvents: editMode ? 'auto' : 'none' }}
            >
              詳細
            </Button>
          </Row>
        </td> */}
      </tr>
      <tr>
        <td style={{ verticalAlign: 'middle' }}>購入品取付時間</td>
        <td style={{ verticalAlign: 'middle' }}>
          <Input
            name="types"
            value={purchaseTotalTensuu}
            style={{ textAlign: 'center' }}
            className="input-non-editable "
          ></Input>
        </td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{secondsToHms(purchaseTotalInstTime)}</td>
      </tr>
    </>
  );

  const updateDetailsModal = (result) => {
    setIsDetailsModal(result);
  };

  const updateKumitateJikanList = (result) => {
    const totalValue = result?.reduce((acc, item) => acc + item.workTotalTime, 0);
    const totalPurchaseInstTime = purchases?.reduce((acc, item) => acc + item.processTime, 0);
    setKumitateJikanList(result);
    kumitateDataLst.assyList = result;
    kumitateDataLst.purchaseInstTime = totalValue + totalPurchaseInstTime + purchaseTime;
    setPurchaseTime(totalValue + totalPurchaseInstTime + purchaseTime);
    props.updatedKumitateProcessInput(kumitateDataLst);
  };

  return (
    <>
      {props?.activeTabKey === '3' ? (
        <>
          {/* 組立 */}
          <Row className="mt-1 registerModal" align="middle">
            <Table className="kouteiinput" style={{ width: '76.1%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '0.1%' }} className="tbl-header">
                    No
                  </th>
                  <th style={{ width: '17.4%' }} className="tbl-header">
                    計算方法
                  </th>
                  <th style={{ width: '19.3%' }} className="tbl-header">
                    点数
                  </th>
                  <th style={{ width: '19.3%' }} className="tbl-header">
                    時間
                  </th>
                  <th style={{ width: '19.3%' }} className="tbl-header">
                    合計時間
                  </th>
                  {/* <th colSpan={4} style={{ width: '40%' }} className="tbl-header">
                詳細
              </th> */}
                  <th style={{ width: '8%', display: 'none' }} className="tbl-header">
                    購入品詳細
                  </th>
                </tr>
              </thead>
              <tbody>{content}</tbody>
            </Table>
          </Row>
          {/* 詳細 */}
          {addModal ? (
            <ParentKouteiInput_ChildPartInfoSetting
              editMode={editMode}
              onUpdate={updateChildPart}
              onCancel={cancelChildPart}
              curData={curKumitateData}
              shoriNm={'組立' + curKumitateData.key}
            />
          ) : (
            ''
          )}

          {isDetailsModal ? (
            <ParentKouteiInput_Kumitate_DetailsModal
              isDetailsModal={isDetailsModal}
              updateDetailsModal={updateDetailsModal}
              purchases={purchases}
              purchaseCategory={purchaseCategory}
              kumitateJikanList={kumitateJikanList}
              updateKumitateJikanList={updateKumitateJikanList}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
});

export default ParentKouteiInput_Kumitate;
