/**
 * クラス名：ログイン画面
 * 説明：システムログイン画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Form, Input, Button, Image, Checkbox, Alert, Modal } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import logo from '../assets/images/logo.ico';
import { WithRouter } from '../components/WithRouter';
import { StatusCodes } from 'http-status-codes';

import { ErrorMessage } from '../views/common/Message';
import { updateAccessToken } from '../views/common/Common';
import { getCurrentUserInfo, getExistUserSetting } from '../views/common/CommonAPI';

const provinceData = ['日本語', '英語'];

const Login = forwardRef((props, ref) => {
  const formRef = React.useRef(null);
  let params = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(params.has('ErrorMessage') ? params.get('ErrorMessage') : '');
  const [loading, setLoading] = useState(false);
  const [isAliveToken, setIsAliveToken] = useState(false);

  useEffect(() => {
    isActiveToken(); // ログインのトークン有効チェック
  }, []);

  // ログインのトークン有効チェック処理
  const isActiveToken = async () => {
    try {
      let token = '';
      if (localStorage.getItem('iQxWeb_AccessToken')) {
        // ログインの有効チェック、
        token = localStorage.getItem('iQxWeb_AccessToken');
        let result = await isAlive(token);
        if (result?.statusCode == StatusCodes.OK) {
          setIsAliveToken(true);
          // 正常の場合、
          token = result.updateToken;
          // セクションにトークン情報を保持する
          updateAccessToken(token);
          // 取得正常の場合、ログインユーザー情報を取得する
          result = await getCurrentUserInfo();
          if (result === '') {
            await changeRoute();
          } else {
            // 異常の場合、
            setErrorMessage(result);
            return;
          }
        } else {
          // 異常の場合、
          setErrorMessage(result);
          return;
        }
      }
    } catch (error) {
      // 異常の場合、
      setErrorMessage(error.message);
      return error;
    }
  };

  // APIでトークン有効チェック処理
  const isAlive = async (token) => {
    let ret;
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/IsAliveToken';
      const otherParam = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data;
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            ret = ErrorMessage()[data?.messageCode];
          }
        })
        .catch((error) => {
          // 異常の場合、
          ret = ErrorMessage().E010;
        });
    } catch (error) {
      // 異常の場合、
      ret = error.message;
    }
    return ret;
  };

  // ログインボタンの押下
  const handleSubmit = async (values) => {
    let loginId = values?.loginId;
    let password = values?.password;
    let remember = values?.rememberMe;
    setLoading(true);
    await isOtherLogin(loginId, password, remember);
    // await login(loginId, password, remember);
    setLoading(false);
  };

  // ログイン処理
  const isOtherLogin = async (loginId, password, remember) => {
    // 必須チェック
    if (!loginId || !password) {
      setErrorMessage(ErrorMessage().E005);
      return;
    }
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/IsOtherLogin';
      const otherParam = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          loginId: loginId,
          password: password,
          rememberMe: remember,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.statusCode === StatusCodes.OK) {
            // 正常の場合、
            await login(loginId, password, remember);
          } else if (
            data &&
            (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
          ) {
            Modal.confirm({
              width: 465,
              className: 'modal-error',
              title: '確認',
              content: (
                <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
                  {ErrorMessage()[data?.messageCode]}
                </p>
              ),
              okText: 'ログイン',
              cancelText: 'キャンセル',
              onOk: async () => {
                await login(loginId, password, remember);
              },
            });
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            // 異常の場合、
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          }
        })
        .catch((error) => {
          // 異常の場合、
          setErrorMessage(ErrorMessage().E010);
        });
    } catch (error) {
      // 異常の場合、
      setErrorMessage(ErrorMessage().E003);
      return error;
    }
  };

  // ログイン処理
  const login = async (loginId, password, remember) => {
    // 必須チェック
    if (!loginId || !password) {
      setErrorMessage(ErrorMessage().E005);
      return;
    }
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/Login';
      const otherParam = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          loginId: loginId,
          password: password,
          rememberMe: remember,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.statusCode === StatusCodes.OK) {
            // 正常の場合、
            let token = data.updateToken;
            // セクションにトークン情報を保持する
            updateAccessToken(token);
            // ログインユーザー情報を取得する
            let result = await getCurrentUserInfo();
            if (result === '') {
              await changeRoute();
            } else {
              // 異常の場合、
              setErrorMessage(result);
            }
          } else if (
            data &&
            (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
          ) {
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          } else if (data && data?.statusCode !== StatusCodes.OK) {
            // 異常の場合、
            setErrorMessage(ErrorMessage()[data?.messageCode]);
          }
        })
        .catch((error) => {
          // 異常の場合、
          setErrorMessage(ErrorMessage().E010);
        });
    } catch (error) {
      // 異常の場合、
      setErrorMessage(ErrorMessage().E003);
      return error;
    }
  };

  // 入力項目に値を設定
  const setFormValue = (name, value) => {
    formRef.current?.setFieldValue(name, value);
  };

  // Routeを変更する
  const changeRoute = async () => {
    let userSettingInfo = await getExistUserSetting();
    let gotoRoute = '/home/:parentList';
    if (userSettingInfo !== undefined && userSettingInfo?.length !== 0) {
      switch (userSettingInfo?.[0]?.users?.initShowDisp) {
        case 0:
          gotoRoute = '/home/:parentList';
          break;
        case 1:
          gotoRoute = '/home/:estimate.parent';
          break;
        case 2:
          gotoRoute = '/home/:estimate.iq3';
          break;
        default:
          gotoRoute = '/home/:parentList';
          break;
      }
    }
    //係数・追加項目一覧をセッションにセット。
    props.navigate(gotoRoute, { state: { fromLogin: true } });
  };

  return (
    <div
      style={{
        display: isAliveToken ? 'none' : 'flex',
        justifyContent: 'center',
        verticalAlign: 'center',
        backgroundColor: '#005FAB',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '60px',
          // overflowX: 'hidden',
          // overflowY: 'hidden',
        }}
      >
        <Form
          name="Login"
          onFinish={handleSubmit}
          ref={formRef}
          layout="vertical"
          style={{ maxWidth: '500px', height: '100%' }}
          initialValues={{
            loginId: 'user@zero-four.co.jp',
            password: '1234',
            rememberMe: false,
          }}
        >
          <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <Image preview={false} width={100} height={100} src={logo}></Image>
          </Form.Item>
          <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: '-20px' }}>
            <p style={{ fontSize: '25px', fontWeight: 'bold', color: 'gold' }}>ZeroFour System Login</p>
          </Form.Item>
          {/* ユーザーID */}
          <Form.Item
            name="loginId"
            label={<label style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>ユーザーID</label>}
          >
            <Input style={{ width: '380px', height: '40px' }} placeholder="test@demo111.jp" />
          </Form.Item>
          {/* パスワード */}
          <Form.Item
            name="password"
            label={<label style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>パスワード</label>}
          >
            <Input style={{ width: '380px', height: '40px' }} type="password" placeholder="123456" />
          </Form.Item>
          {/* 自動ログイン */}
          <Form.Item
            name="rememberMe"
            valuePropName="checked"
            style={{ display: 'flex', justifyContent: 'left', height: '40px' }}
          >
            <Checkbox>
              <p style={{ color: 'white', fontSize: '15px', margin: 0 }}>次回から自動ログイン</p>
            </Checkbox>
          </Form.Item>
          {/* ログインボタン */}
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              key="login"
              style={{
                backgroundColor: '#e7c71ae3',
                color: '#212529',
                width: '150px',
                height: '40px',
                fontWeight: '600',
                borderColor: '#808080',
                borderWidth: '1px',
              }}
              type="primary"
              htmlType="submit2"
              loading={loading | { delay: 500 }}
            >
              ログイン
            </Button>
          </Form.Item>
          {errorMessage != '' ? (
            <Form.Item name="errorMessage">
              <Alert
                style={{ width: 380, textAlign: 'center', whiteSpace: 'pre-line' }}
                message={errorMessage}
                type="error"
                showIcon
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </div>
    </div>
  );
});

export default WithRouter(Login);
