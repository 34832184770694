import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Input } from 'antd';
import { Table } from 'react-bootstrap';

import '../../assets/styles/common.css';
import ParentKouteiInput_ChildPartInfoSetting from './ParentKouteiInput_ChildPartInfoSetting';
import { getPrepCoeffTypeName, handleKeyPress, regexExp, secondsToHms } from '../common/Common';

const ParentKouteiInput_Konpou = forwardRef((props, ref) => {
  const [konpouDataLst, setKonpouDataLst] = useState([]);
  const [curKensaData, setCurKensaData] = useState([]);
  const [curDataIndex, setCurDataIndex] = useState(0);
  // add new
  const [addModal, setAddModal] = useState(false);
  // 編集モード
  const editMode = props.editMode;
  const [calculateType, setCalculateType] = useState();
  const [childPartsCount, setChildPartsCount] = useState();
  const [packingWorkBasicTime, setPackingWorkBasicTime] = useState(0);
  const [childPartsCountOrg, setChildPartsCountOrg] = useState(0); // IQX_WEBEST-179

  useEffect(() => {
    if (props?.activeTabKey === '3' || props.isReCal) {
      if (props.kouteiSentakuProcessesId?.length > 0) {
        const packingItem = props.kouteiSentakuProcessesId?.find((item) => item.packingTime);

        // Check if packingItem is found and get its value
        const packingTime = packingItem ? packingItem?.packingTime : 0;

        setPackingWorkBasicTime(packingTime);
      }
      const konpouDetailsData = props?.kouteiInputInfo?.packingProcessInputInfo;
      if (konpouDetailsData) {
        setCalculateType(konpouDetailsData?.calcType);
        setChildPartsCount(konpouDetailsData?.smPartsCount);
        setKonpouDataLst(konpouDetailsData);
        setChildPartsCountOrg(konpouDetailsData?.autoExtract); // IQX_WEBEST-179
      }
    }
  }, [props.kouteiInputInfo, props.kouteiSentakuProcessesId, props.isReCal]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const updateChildPart = (selectedChildParts) => {
    const iQ3Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ3';
    });
    const iQ5Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ5';
    });
    const iQ7Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ7';
    });
    let sum = selectedChildParts?.reduce(function (prev, current) {
      return prev + +current.area;
    }, 0);

    const updatedChildPart = {
      key: curKensaData?.key,
      kensaType: curKensaData?.kensaType,
      childPartNm: curKensaData?.childPartNm,
      childParts: selectedChildParts,
      sum: sum,
      purchaseCnt: curKensaData?.purchaseCnt,
      totalCnt:
        parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length) + parseInt(purchaseCnt),
      selectedChildPartCnt: parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length),
    };
    const data = [...konpouDataLst];
    data[curDataIndex] = updatedChildPart;
    setKonpouDataLst(data);
    setAddModal(false);
  };

  const cancelChildPart = () => {
    setAddModal(false);
  };

  const konpouComponent = (
    <>
      <tr>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>1</td>
        <td style={{ verticalAlign: 'middle' }}>{getPrepCoeffTypeName(calculateType)}</td>

        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          {/** IQX_WEBEST-179 */}
          <label style={{ marginLeft: 0, width: '40%', textAlign: 'center' }}>{childPartsCountOrg}</label>
          <Input
            name="types"
            value={childPartsCount}
            style={{ textAlign: 'center', width: '53%', marginLeft: 9 }}
            className={editMode ? 'input-editable ' : 'input-non-editable '}
            // onChange={(event) => {
            //   const { name, value } = event.target;
            //   const data = [...konpouDataLst];
            //   data[index][name] = value;
            //   setKonpouDataLst(data);
            // }}
            onChange={(e) => {
              if (regexExp.test(e.target.value)) {
                e.target.value = 0;
              }
              setChildPartsCount(e.target.value);
              konpouDataLst.smPartsCount = e.target.value;
              konpouDataLst.total = e.target.value * packingWorkBasicTime;
              props.updatedKonpouProcessInput(konpouDataLst);
            }}
            onKeyPress={(e) => {
              handleKeyPress(e, true);
            }}
          ></Input>
        </td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          {secondsToHms(packingWorkBasicTime * childPartsCount)}
        </td>
      </tr>
    </>
  );

  return (
    <>
      {props?.activeTabKey === '3' ? (
        <>
          {/* 組立 */}
          <Row className="mt-1 registerModal" align="middle">
            <Table className="kouteiinput" style={{ width: '57.3%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '0.1%' }} className="tbl-header">
                    No
                  </th>
                  <th style={{ width: '17.4%' }} className="tbl-header">
                    計算方法
                  </th>
                  <th style={{ width: '19.2%' }} className="tbl-header">
                    点数
                  </th>
                  <th style={{ width: '19.2%' }} className="tbl-header">
                    合計時間
                  </th>
                  {/* <th colSpan={3} style={{ width: '19%' }} className="tbl-header">
                詳細
              </th> */}
                  {/* <th style={{ width: '8%' }} className="tbl-header">
                合計
              </th> */}
                  {/* <th style={{ width: '8%' }} className="tbl-header">
                構成子部品
              </th> */}
                </tr>
              </thead>
              <tbody>{konpouComponent}</tbody>
            </Table>
          </Row>
          {/* 詳細 */}
          {addModal ? (
            <ParentKouteiInput_ChildPartInfoSetting
              editMode={editMode}
              onUpdate={updateChildPart}
              onCancel={cancelChildPart}
              curData={curKensaData}
              shoriNm={'梱包' + curKensaData.key}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
});

export default ParentKouteiInput_Konpou;
