import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Space, Spin } from 'antd';
import { CaretDownFilled, CaretUpOutlined } from '@ant-design/icons';

import '../../assets/styles/common.css';
import ParentKouteiCalculate_Hyomen from './ParentKouteiCalculate_Hyomen';
import ParentKouteiCalculate_Konpo from './ParentKouteiCalculate_Konpo';
import ParentKouteiCalculate_Kumitate from './ParentKouteiCalculate_Kumitate';
import ParentKouteiCalculate_Kensa from './ParentKouteiCalculate_Kensa';
import ParentKouteiCalculate_Additional from './ParentKouteiCalculate_Additional';
import { WorkType } from '../common/enums';

const ParentKouteiCalculate = forwardRef((props, ref) => {
  const [kouteiCalInfo, setKouteiCalInfo] = useState([]);
  const paSurfaceRef = useRef();
  const paAssembleRef = useRef();
  const paInspectionRef = useRef();
  const paPackingRef = useRef();
  const paCustomRef = useRef();

  useEffect(() => {
    if (props?.activeTabKey === '4' || props.isReCal) {
      setKouteiCalInfo(props?.selectedDataDetail?.estimateProducts?.additions);
    }
  }, [props?.activeTabKey]);

  // useEffect(() => {
  //   if (props?.activeTabKey === '4' && kouteiCalInfo === undefined)
  //     setKouteiCalInfo(props?.selectedDataDetail?.estimateProducts?.additions);
  // }, [props?.selectedDataDetail?.estimateProducts]);

  const updateKouteiCal = (kouteiInfo, workType) => {
    let kouteiCalInfoMoto = []; //古い工程積算情報が残っているため、リセット
    if (kouteiCalInfoMoto === undefined || kouteiCalInfoMoto?.length === 0) {
      let usedCalProcess = props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
        (item) => item.isUsedForCalc
      );

      for (let i = 0; i < usedCalProcess?.length; i++) {
        let usedKouteiCal = [];
        if (usedCalProcess?.[i]?.multiprocesses?.length > 0) {
          usedKouteiCal = props?.selectedDataDetail?.estimateProducts?.additions?.filter(
            (item) =>
              item.processId === usedCalProcess?.[i].processId &&
              item?.dataItems?.[0]?.processDetailTypeNo ===
                usedCalProcess?.[i]?.multiprocesses?.[0]?.processDetailTypeId &&
              item?.dataItems?.[0]?.processDetailWorkTypeGroup ===
                usedCalProcess?.[i]?.multiprocesses?.[0]?.processDetailGroup
          );
        } else {
          usedKouteiCal = props?.selectedDataDetail?.estimateProducts?.additions?.filter(
            (item) => item.processId === usedCalProcess?.[i].processId
          );
        }

        if (usedKouteiCal?.length > 0) kouteiCalInfoMoto?.push(usedKouteiCal?.[0]);
      }
    }
    if (props?.selectedDataDetail?.estimateProducts == undefined) return kouteiCalInfoMoto;

    // 更新する工程が工程選択に入っているかチェック
    // 追加工程の場合
    if (workType === WorkType.PaCustom) {
      for (let i = 0; i < kouteiInfo?.length; i++) {
        let info = kouteiInfo[i];
        //　更新する工程を工程積算一覧に検索
        let updateKoutei = kouteiCalInfoMoto?.filter(
          (item) =>
            item.processId == info.processId &&
            /* item.no == info.no && */
            item?.dataItems?.[0]?.processDetailTypeNo === info?.dataItems?.[0]?.processDetailTypeNo &&
            item?.dataItems?.[0]?.processDetailWorkTypeGroup === info?.dataItems?.[0]?.processDetailWorkTypeGroup
        );
        //　削除された工程をもう一度設定した場合
        if (updateKoutei?.length == 0) {
          kouteiCalInfoMoto.push(info);
        } else {
          // 工程選択に入っている工程積算情報を見積データに更新
          kouteiCalInfoMoto?.forEach((data, index) => {
            if (
              data.processId == info.processId &&
              data?.dataItems?.[0]?.processDetailTypeNo === info?.dataItems?.[0]?.processDetailTypeNo &&
              data?.dataItems?.[0]?.processDetailWorkTypeGroup === info?.dataItems?.[0]?.processDetailWorkTypeGroup
              /* && data.no == info.no */
            ) {
              kouteiCalInfoMoto[index] = info;
            }
          });
        }
      }
    } else {
      //　更新する工程を工程積算一覧に検索
      let updateKoutei = kouteiCalInfoMoto?.filter((item) => item.processId == kouteiInfo.processId);
      //　存在しない工程の場合、新規追加
      if (updateKoutei?.length == 0) {
        kouteiCalInfoMoto.push(kouteiInfo);
      } else {
        // 工程選択に入っている工程積算情報を見積データに更新
        kouteiCalInfoMoto?.forEach((data, index) => {
          if (data.processId == kouteiInfo.processId) {
            kouteiCalInfoMoto[index] = kouteiInfo;
          }
        });
      }
    }
    setKouteiCalInfo(kouteiCalInfoMoto);
    props.selectedDataDetail.estimateProducts.additions = kouteiCalInfoMoto;
    return kouteiCalInfoMoto;
  };

  useImperativeHandle(
    ref,
    () => ({
      getUpdateKouteiCal: updateKouteiCal,
      getKouteiCalInfo: () => {
        return kouteiCalInfo;
      },
    }),
    [kouteiCalInfo]
  );

  const openAll = () => {
    paSurfaceRef?.current?.setHyomenDisplay(true);
    paAssembleRef?.current?.setKumitateDisplay(true);
    paInspectionRef?.current?.setKensaDisplay(true);
    paPackingRef?.current?.setKonpoDisplay(true);
    paCustomRef?.current?.setCustomDisplay(true);
  };
  const closeAll = () => {
    paSurfaceRef?.current?.setHyomenDisplay(false);
    paAssembleRef?.current?.setKumitateDisplay(false);
    paInspectionRef?.current?.setKensaDisplay(false);
    paPackingRef?.current?.setKonpoDisplay(false);
    paCustomRef?.current?.setCustomDisplay(false);
  };

  return (
    <Spin spinning={props?.activeTabKey === '4' ? false : true} tip="" size="large">
      <>
        {props?.activeTabKey === '4' || props.isReCal ? (
          <>
            <Row
              className="mb-1"
              style={{ display: props?.selectedDataDetail?.estimateProducts?.additions?.length > 0 ? '' : 'none' }}
            >
              <Col style={{ paddingLeft: 2 }}>
                <Space size="small">
                  <CaretUpOutlined
                    style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                    onClick={closeAll}
                  />

                  <CaretDownFilled
                    style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                    onClick={openAll}
                  />
                </Space>
              </Col>
            </Row>
            <div className="overflow-auto kouteical KouteiCalculate" style={{ height: '79vh' }}>
              {/* 表面処理 */}
              <ParentKouteiCalculate_Hyomen
                ref={paSurfaceRef}
                editMode={true}
                newMode={props?.newMode}
                selectedDataDetail={props?.selectedDataDetail}
                updateKouteiCal={updateKouteiCal}
                activeTabKey={props?.activeTabKey}
                isReCal={props?.isReCal}
                activePartMenu={props.activePartMenu} // IQX_WEBEST-91 「親部品」「iQ3板金」の切替でパネルの金額に反映されない
              ></ParentKouteiCalculate_Hyomen>

              {/* 組立 */}
              <ParentKouteiCalculate_Kumitate
                ref={paAssembleRef}
                editMode={true}
                newMode={props?.newMode}
                selectedDataDetail={props?.selectedDataDetail}
                updateKouteiCal={updateKouteiCal}
                activeTabKey={props?.activeTabKey}
                purchasesData={props?.purchasesData}
                isReCal={props?.isReCal}
                activePartMenu={props.activePartMenu} // IQX_WEBEST-91 「親部品」「iQ3板金」の切替でパネルの金額に反映されない
              ></ParentKouteiCalculate_Kumitate>

              {/* 検査 */}
              <ParentKouteiCalculate_Kensa
                ref={paInspectionRef}
                editMode={true}
                newMode={props?.newMode}
                selectedDataDetail={props?.selectedDataDetail}
                updateKouteiCal={updateKouteiCal}
                activeTabKey={props?.activeTabKey}
                isReCal={props?.isReCal}
                activePartMenu={props.activePartMenu} // IQX_WEBEST-91 「親部品」「iQ3板金」の切替でパネルの金額に反映されない
              ></ParentKouteiCalculate_Kensa>

              {/* 梱包 */}
              <ParentKouteiCalculate_Konpo
                ref={paPackingRef}
                editMode={true}
                newMode={props?.newMode}
                selectedDataDetail={props?.selectedDataDetail}
                updateKouteiCal={updateKouteiCal}
                activeTabKey={props?.activeTabKey}
                isReCal={props?.isReCal}
                activePartMenu={props.activePartMenu} // IQX_WEBEST-91 「親部品」「iQ3板金」の切替でパネルの金額に反映されない
              ></ParentKouteiCalculate_Konpo>

              {/* 追加工程 */}
              <ParentKouteiCalculate_Additional
                ref={paCustomRef}
                editMode={true}
                newMode={props?.newMode}
                selectedDataDetail={props?.selectedDataDetail}
                updateKouteiCal={updateKouteiCal}
                activeTabKey={props?.activeTabKey}
                isReCal={props?.isReCal}
                activePartMenu={props.activePartMenu}
              ></ParentKouteiCalculate_Additional>
              {/* 追加工程 */}
            </div>
          </>
        ) : (
          ''
        )}
      </>
    </Spin>
  );
});

export default ParentKouteiCalculate;
