import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  handleCollpse,
  handleKeyPress,
  JPYs,
  kouteiCalculateSelectedArr,
  handleKeyPress2,
  toDecimal,
} from '../common/Common.js';
import { DataType, ServiceClass, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_BlankLaser = forwardRef((props, ref) => {
  const [isBlankingdetail, setIBlankingdetail] = useState(false);
  const [isdandoriDetail, setIsDandoriDetail] = useState(false);
  const [isSagyoDetail, setIsSagyoDetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [laserAmt, setLaserAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [speedOutCoeff, setSpeedOutCoeff] = useState(0);
  const [speedInCoeff, setSpeedInCoeff] = useState(0);

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      dandoriAmtSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });
    let totalSum =
      (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) +
      (dandoriAmtSum ? Math.round(dandoriAmtSum) : 0) +
      (editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole
        ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole)
        : 0) +
      (editDataArr[0].sagyoDetails.sagyoAmtJoint ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtJoint) : 0); // 画面表示用（四捨五入した金額を足す）
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    let totalTimeSum =
      sagyoTimeSec +
      dandoriTimeSec +
      editDataArr[0].sagyoDetails.sagyoTmTwoStepHole +
      editDataArr[0].sagyoDetails.sagyoTmJoint;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum:
        sagyoAmtSum +
        (editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole
          ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole)
          : 0) +
        (editDataArr[0].sagyoDetails.sagyoAmtJoint ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtJoint) : 0),
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec:
        sagyoTimeSec + editDataArr[0].sagyoDetails.sagyoTmTwoStepHole + editDataArr[0].sagyoDetails.sagyoTmJoint,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum:
        sagyoTimeSum + editDataArr[0].sagyoDetails.sagyoTmTwoStepHole + editDataArr[0].sagyoDetails.sagyoTmJoint,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCalIQ3 || props?.iq3ActionForCal) {
      let editData = [];
      let toBEdit = [];
      let orgData = [];
      let totals = [];
      let processMst = [];
      let deviceMst = []; //テスト用ID;
      setTotalSum(0);
      setTotalTimeSum(0);
      setLaserAmt(0);

      let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
      let nijiProcessMst;

      if (parameters?.process?.length > 0) {
        processMst = parameters?.process
          ? parameters?.process?.filter(
              (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBlankLaser
            )[0]
          : [];

        nijiProcessMst = parameters?.process
          ? parameters?.process?.filter(
              (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
            )[0]
          : [];

        setProcessMst(processMst ? processMst : []);
        if (parameters?.device?.length > 0) {
          deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
        }
      }

      let patternInfo = props?.selectedIQ3DataDetail?.processSelect
        ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
        : [];
      if (patternInfo == undefined) return;
      if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

      deviceMst =
        deviceMst && patternInfo
          ? deviceMst?.filter(
              (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
            )[0]
          : []; //テスト用ID;

      let secondaryWorkProcessInput;
      let secondaryUsedForCalc = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === nijiProcessMst?.id
      )?.[0]?.isUsedForCalc;

      if (nijiProcessMst !== undefined && secondaryUsedForCalc) {
        secondaryWorkProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
          (item) => item.processId == nijiProcessMst?.id
        )[0];
      }
      let nijiCnt = 0;
      if (secondaryWorkProcessInput !== undefined && secondaryWorkProcessInput?.details !== undefined) {
        secondaryWorkProcessInput?.details.forEach((e) => {
          if (e.blankFlag && Number(e.diameter) > 0) {
            nijiCnt += Number(e.count);
          }
        });
      }

      setDeviceMst(deviceMst ? deviceMst : []);
      if (deviceMst == undefined) return;
      // 選択された行の工程積算情報を正しく取得するための対応
      let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
        (item) => item.no === props?.selectedIQ3DataDetail?.no
      );
      let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
      if (selectedIQ3DataDetail?.length === 0) return;
      let laserAdditions = selectedIQ3DataDetail?.additions?.filter((item) => item.processId == processMst?.id)[0];
      // プログラムの工程入力情報取得
      let laserProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == processMst?.id
      );
      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      let laserAdditionsEditData = laserAdditions?.editItems;
      let editDataFlg = laserAdditions?.editDataFlg;
      let piercingcount = 1;
      let diameter = 0;
      let perimeter = 0;
      if (laserProcessInput.length > 0) {
        if (laserProcessInput?.[0]?.details?.[0]?.quantityList) {
          let quantityList = laserProcessInput?.[0]?.details?.[0]?.quantityList;
          quantityList.forEach((e) => {
            if (Number(e.perimeter) !== 0) {
              piercingcount += Number(e.count);
            }
            diameter += Number(e.diameter);
            perimeter += Number(e.perimeter);
          });
        }
      }

      // 新規の場合
      // if (laserAdditions?.dataItems?.length == 0 || laserAdditions == undefined) {
      let detailItems = [];
      let editDetailItems = [];
      let sagyoInDetail = {
        sagyoAmtInS: 0,
        sagyoTmInS: 0,
        speedInS: 0,
        speedInSLen: 0,
        sagyoAmtInM: 0,
        sagyoTmInM: 0,
        speedInM: 0,
        speedInMLen: 0,
        sagyoAmtInL: 0,
        sagyoTmInL: 0,
        speedInL: 0,
        speedInLLen: 0,
      };
      let sagyoDetail = {
        sagyoAmtOut: 0,
        sagyoTmOut: 0,
        speedOut: 0,
        speedOutLen: 0,
        sagyoAmtIn: 0,
        sagyoTmIn: 0,
        speedIn: 0,
        speedInLen: 0,
        sagyoAmtOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        sagyoTmOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        speedOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        speedOutTwiceCutLen: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        sagyoAmtInTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        sagyoTmInTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        speedInTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        speedInTwiceCutLen: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        sagyoAmtTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        sagyoTmTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
        sagyoAmtAirDeli: 0,
        sagyoTmAirDeli: 0,
        speedAirDeli: 0,
        speedAirDeliLen: 0,
        sagyoAmtPiercing: 0,
        sagyoTmPiercing: 0,
        speedPiercing: 0,
        speedPiercingLen: 0,
        sagyoAmtScribing: 0,
        sagyoTmScribing: 0,
        speedScribing: 0,
        speedScribingLen: 0,
        sagyoAmtTwoStepHole: 0,
        sagyoTmTwoStepHole: 0,
        twoStepHoleCnt: 0,
        twoStepHoleTm: 0,
        sagyoAmtJoint: 0,
        sagyoTmJoint: 0,
        jointCnt: 0,
        jointTm: 0,
        sagyoInDetails: sagyoInDetail,
      };
      detailItems.push({
        id: 1,
        processName: processMst?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        sagyoDetails: sagyoDetail,
        formula: '',
      });

      editDetailItems = [];
      let laserAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: laserAdditions?.dataType ? laserAdditions.dataType : DataType.Data,
        dataItems: detailItems,
        editItems: laserAdditions?.editItems ? laserAdditions.editItems : editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
        editDataFlg: editDataFlg,
      };
      laserAdditions = laserAddition;
      // }

      //データ設定
      if (laserAdditions?.dataItems) {
        orgData = JSON.parse(JSON.stringify(laserAdditions?.dataItems));
        if (deviceMst != undefined) {
          let totalSagyo = 0;
          let totalSagyoTm = 0;
          // 数量
          let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
          // 板厚
          let thickness = parameters?.materialIq3?.filter(
            (item) => item.id == props?.selectedIQ3DataDetail?.thickness
          )?.[0]?.info?.thick;
          // 重量
          let weight = props?.selectedIQ3DataDetail?.weight;
          // 面積
          let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst);

          //総重量が0、子部品数が0の場合、段取と加工を計算しない。
          if (weight === 0 && area === 0) {
            setProcessArr(orgData);
          } else {
            let speedInLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.innerDia);
            let speedOutLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.outerDia);
            let speedAirDeliLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.airFeedDistance);
            let speedScribingLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.etchingLength); //PMX用ケガキ線長
            // if (diameter === 0 && perimeter === 0) {
            //   setProcessArr(orgData);
            // } else {
            //材質
            let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

            //材質区分
            let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
              ?.materialTypeId;

            // サイズ係数
            let sizeCoef = getSizeCoeff(deviceMst, weight, area);

            //ガス係数
            let gasCoef = getGasCoef(deviceMst, thickness, materialTypeId);

            /** 段取金額/時間 */
            // 基本段取時間
            let basicSetupTimeSec = deviceMst?.details?.prepBasicTime * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
            orgData[0].dandoriTime = basicSetupTimeSec === undefined ? 0 : basicSetupTimeSec;
            let calDanAmt = calDandoriAmt(basicSetupTimeSec, deviceMst);
            orgData[0].dandoriAmt = calDanAmt === undefined ? 0 : calDanAmt;

            // 2工程穴加工金額/時間
            let twoStepHoleTm = deviceMst?.details?.twoStepHoleworkTime;
            let twoStepHoleCnt = nijiCnt; //確認必要？
            orgData[0].sagyoDetails.twoStepHoleTm = twoStepHoleTm === undefined ? 0 : twoStepHoleTm;
            orgData[0].sagyoDetails.twoStepHoleCnt = twoStepHoleCnt === undefined ? 0 : twoStepHoleCnt;
            // 2工程穴加工時間	*	個数
            let twoStepHoleTmsec = twoStepHoleTm * twoStepHoleCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmTwoStepHole = twoStepHoleTmsec === undefined ? 0 : twoStepHoleTmsec;

            let sagyoAmt2Step = calSagyoAmtTwoStepHole(twoStepHoleTmsec, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtTwoStepHole = sagyoAmt2Step === undefined ? 0 : sagyoAmt2Step;

            // ジョイントバラシ加工金額/時間
            let jointTimePerSec = getJointTm(deviceMst, thickness, materialTypeId);
            let jointQtySizeThMaster = deviceMst?.details?.jointQtySizeThMaster?.filter(
              (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
            )?.[0];
            jointQtySizeThMaster = jointQtySizeThMaster
              ? jointQtySizeThMaster
              : deviceMst?.details?.jointQtySizeThMaster?.[deviceMst?.details?.jointQtySizeThMaster.length - 1];

            let jointCnt = deviceMst?.details?.jointQtyItems?.filter(
              (item) => item.no == jointQtySizeThMaster?.no && !item?.isOutOfRange
            )?.[0]?.value;
            orgData[0].sagyoDetails.jointTm = jointTimePerSec === undefined ? 0 : jointTimePerSec;
            orgData[0].sagyoDetails.jointCnt = jointCnt === undefined ? 0 : jointCnt;
            // ジョイントバラシ加工時間	*	個数
            let jointTimeSec = jointTimePerSec * jointCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmJoint = jointTimeSec;

            let sagyoAmtJt = calSagyoAmtJoint(jointTimeSec, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtJoint = sagyoAmtJt === undefined ? 0 : sagyoAmtJt;

            // 外周切断時間（外周切断長	/	加工速度	＊	加工速度係数	*	ガス係数	*	サイズ係数）
            // 加工速度係数
            let speedOutCoef = getSpeedCoef(deviceMst, speedOutLen, 0);
            setSpeedOutCoeff(speedOutCoef);
            let speedOut = 0;
            // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
            if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
              speedOut = getSagyoSpeed(deviceMst, thickness, materialTypeId);
            } else {
              speedOut = getSagyoOut(deviceMst, thickness, materialTypeId);
            }
            orgData[0].sagyoDetails.speedOut = speedOut;

            orgData[0].sagyoDetails.speedOutLen =
              speedOutLen === undefined ? toDecimal(0, 2) : toDecimal(speedOutLen, 2);
            speedOut = speedOut * speedOutCoef * gasCoef; // IQX_WEBEST-188
            let sagyoTmOutPerMin = Number.isFinite((speedOutLen * sizeCoef) / speedOut)
              ? ((speedOutLen * sizeCoef) / speedOut) * 60
              : 0;
            sagyoTmOutPerMin = sagyoTmOutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmOut = sagyoTmOutPerMin === undefined ? 0 : sagyoTmOutPerMin;
            let sAmtOut = calSagyoAmt(sagyoTmOutPerMin, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtOut = sAmtOut === undefined ? 0 : sAmtOut;

            // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
            // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
            // 加工速度係数
            let speedInCoef = getSpeedCoef(deviceMst, speedInLen, 1);
            setSpeedInCoeff(speedInCoef);
            if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
              let speedIn = getSagyoSpeed(deviceMst, thickness, materialTypeId);
              orgData[0].sagyoDetails.speedIn = speedIn === undefined ? 0 : speedIn;
              orgData[0].sagyoDetails.speedInLen =
                speedInLen === undefined ? toDecimal(0, 2) : toDecimal(speedInLen, 2);
              speedIn = speedIn * speedInCoef * gasCoef; // IQX_WEBEST-188
              let sagyoTmInPerMin = Number.isFinite((speedInLen * sizeCoef) / speedIn)
                ? ((speedInLen * sizeCoef) / speedIn) * 60
                : 0;
              sagyoTmInPerMin = sagyoTmInPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
              orgData[0].sagyoDetails.sagyoTmIn = sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
              let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
              orgData[0].sagyoDetails.sagyoAmtIn = sAmtIn === undefined ? 0 : sAmtIn;
            } else {
              let sagyoInDetails = getSagyoIn(
                deviceMst,
                thickness,
                materialTypeId,
                orgData[0].sagyoDetails,
                laserProcessInput?.[0]?.details?.[0]?.quantityList,
                gasCoef,
                sizeCoef
              );
              orgData[0].sagyoDetails.sagyoTmIn =
                sagyoInDetails?.sagyoTmIn === undefined ? 0 : sagyoInDetails?.sagyoTmIn;
              orgData[0].sagyoDetails.sagyoAmtIn =
                sagyoInDetails?.sagyoAmtIn === undefined ? 0 : sagyoInDetails?.sagyoAmtIn;
              orgData[0].sagyoDetails.sagyoInDetails = sagyoInDetails?.sagyoInDetails;
            }

            // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            let materialSurfaceIq3Id = props?.selectedIQ3DataDetail?.materialSurfaceIq3Id;
            let materialSurface = parameters?.materialSurfaceIq3?.filter(
              (item) => item.id === materialSurfaceIq3Id && item.materialTypeIq3Id === materialTypeIq3Id
            );
            let isTwiceCut = materialSurface?.length > 0 ? materialSurface?.[0]?.info?.isTwiceCut : false;
            let twiceCutWorkSpeed = materialSurface?.length > 0 ? materialSurface?.[0]?.info?.workSpeed : 0;
            // 外周の２度切り切断時間（外周切断長＊サイズ係数 /	2度切り加工速度	＊	加工速度係数	*	ガス係数）
            orgData[0].sagyoDetails.speedOutTwiceCutLen =
              speedOutLen === undefined ? toDecimal(0, 2) : toDecimal(speedOutLen, 2);
            if (isTwiceCut) {
              let twiceCutSpeedOut = twiceCutWorkSpeed * speedOutCoef * gasCoef;
              let sagyoTmOutTwiceCutPerMin = Number.isFinite((speedOutLen * sizeCoef) / twiceCutSpeedOut)
                ? ((speedOutLen * sizeCoef) / twiceCutSpeedOut) * 60
                : 0;
              sagyoTmOutTwiceCutPerMin = sagyoTmOutTwiceCutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
              orgData[0].sagyoDetails.sagyoTmOutTwiceCut =
                sagyoTmOutTwiceCutPerMin === undefined ? 0 : sagyoTmOutTwiceCutPerMin;
              let sAmtOutTwiceCut = calSagyoAmt(sagyoTmOutTwiceCutPerMin, deviceMst);
              orgData[0].sagyoDetails.sagyoAmtOutTwiceCut = sAmtOutTwiceCut === undefined ? 0 : sAmtOutTwiceCut;
              orgData[0].sagyoDetails.speedOutTwiceCut = twiceCutWorkSpeed;
            }
            // 内周の２度切り切断時間（内周切断長＊サイズ係数 /	2度切り加工速度	＊	加工速度係数	*	ガス係数）
            orgData[0].sagyoDetails.speedInTwiceCutLen =
              speedInLen === undefined ? toDecimal(0, 2) : toDecimal(speedInLen, 2);
            if (isTwiceCut) {
              let twiceCutSpeedIn = twiceCutWorkSpeed * speedInCoef * gasCoef;
              let sagyoTmInTwiceCutPerMin = Number.isFinite((speedInLen * sizeCoef) / twiceCutSpeedIn)
                ? ((speedInLen * sizeCoef) / twiceCutSpeedIn) * 60
                : 0;
              sagyoTmInTwiceCutPerMin = sagyoTmInTwiceCutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
              orgData[0].sagyoDetails.sagyoTmInTwiceCut =
                sagyoTmInTwiceCutPerMin === undefined ? 0 : sagyoTmInTwiceCutPerMin;
              let sAmtInTwiceCut = calSagyoAmt(sagyoTmInTwiceCutPerMin, deviceMst);
              orgData[0].sagyoDetails.sagyoAmtInTwiceCut = sAmtInTwiceCut === undefined ? 0 : sAmtInTwiceCut;
              orgData[0].sagyoDetails.speedInTwiceCut = twiceCutWorkSpeed;
            }

            orgData[0].sagyoDetails.sagyoAmtTwiceCut =
              orgData[0].sagyoDetails.sagyoAmtOutTwiceCut + orgData[0].sagyoDetails.sagyoAmtInTwiceCut;
            orgData[0].sagyoDetails.sagyoTmTwiceCut =
              orgData[0].sagyoDetails.sagyoTmOutTwiceCut + orgData[0].sagyoDetails.sagyoTmInTwiceCut;

            // 空送時間（空送距離	/	送り速度）
            let speedAirDeli = deviceMst?.details?.feedSpeed;
            orgData[0].sagyoDetails.speedAirDeli = speedAirDeli === undefined ? 0 : speedAirDeli;

            orgData[0].sagyoDetails.speedAirDeliLen = speedAirDeliLen === undefined ? 0 : speedAirDeliLen;
            let sagyoTmAirDeli = Number.isFinite(speedAirDeliLen / speedAirDeli)
              ? (speedAirDeliLen / speedAirDeli) * 60
              : 0;
            sagyoTmAirDeli = sagyoTmAirDeli * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmAirDeli = sagyoTmAirDeli === undefined ? 0 : sagyoTmAirDeli;
            let sAmtDeli = calSagyoAmt(sagyoTmAirDeli, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtAirDeli = sAmtDeli === undefined ? 0 : sAmtDeli;

            // ピアス加工時間（1ピアス加工時間	*	箇所数	＊	ガス係数）
            let sagyoTmPiercingPerSec = getSagyoPiercingTm(deviceMst, thickness, materialTypeId);
            orgData[0].sagyoDetails.speedPiercing = sagyoTmPiercingPerSec === undefined ? 0 : sagyoTmPiercingPerSec;
            let speedPiercingLen = piercingcount;
            orgData[0].sagyoDetails.speedPiercingLen = speedPiercingLen;
            let sagyoTmPiercing = sagyoTmPiercingPerSec * speedPiercingLen * gasCoef;
            sagyoTmPiercing = sagyoTmPiercing * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmPiercing = sagyoTmPiercing === undefined ? 0 : sagyoTmPiercing;
            let sAmtPiercing = calSagyoAmt(sagyoTmPiercing, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtPiercing = sAmtPiercing === undefined ? 0 : sAmtPiercing;

            // ケガキ加工時間（ケガキ線長	/	加工速度）
            let speedScribing = deviceMst?.details?.scribingSpeed;
            orgData[0].sagyoDetails.speedScribing = speedScribing === undefined ? 0 : speedScribing;
            orgData[0].sagyoDetails.speedScribingLen = speedScribingLen === undefined ? 0 : speedScribingLen;
            let sagyoTmScribing = Number.isFinite(speedScribingLen / speedScribing)
              ? (speedScribingLen / speedScribing) * 60
              : 0;
            sagyoTmScribing = sagyoTmScribing * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmScribing = sagyoTmScribing === undefined ? 0 : sagyoTmScribing;
            let scribingAmt = calSagyoAmt(sagyoTmScribing, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtScribing = scribingAmt === undefined ? 0 : scribingAmt;
            //作業合計金額・時間
            orgData[0].sagyoAmt =
              orgData[0].sagyoDetails.sagyoAmtOut +
              orgData[0].sagyoDetails.sagyoAmtIn +
              orgData[0].sagyoDetails.sagyoAmtTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
              orgData[0].sagyoDetails.sagyoAmtAirDeli +
              orgData[0].sagyoDetails.sagyoAmtScribing +
              orgData[0].sagyoDetails.sagyoAmtPiercing;
            orgData[0].sagyoTime =
              orgData[0].sagyoDetails.sagyoTmOut +
              orgData[0].sagyoDetails.sagyoTmIn +
              orgData[0].sagyoDetails.sagyoTmTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
              orgData[0].sagyoDetails.sagyoTmAirDeli +
              orgData[0].sagyoDetails.sagyoTmScribing +
              orgData[0].sagyoDetails.sagyoTmPiercing;

            totalSagyo =
              (orgData[0].sagyoAmt ? Math.round(orgData[0].sagyoAmt) : 0) +
              (orgData[0].sagyoDetails.sagyoAmtTwoStepHole
                ? Math.round(orgData[0].sagyoDetails.sagyoAmtTwoStepHole)
                : 0) +
              (orgData[0].sagyoDetails.sagyoAmtJoint ? Math.round(orgData[0].sagyoDetails.sagyoAmtJoint) : 0);
            totalSagyoTm =
              orgData[0].sagyoTime + orgData[0].sagyoDetails.sagyoTmTwoStepHole + orgData[0].sagyoDetails.sagyoTmJoint;

            /** 　合計時間　*/
            let totalTimeSum = orgData[0].dandoriTime + totalSagyoTm;
            setTotalTimeSum(totalTimeSum);
            laserAdditions.totalDataTime = totalTimeSum;
            laserAdditions.totalDandori.dataItemsTime = orgData[0].dandoriTime;
            laserAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

            /** 　合計金額　*/
            let totalSum =
              (orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0) +
              (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
            setTotalSum(totalSum);
            laserAdditions.totalDataPrice = totalSum;
            laserAdditions.totalDandori.dataItemsAmt = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0;
            laserAdditions.totalSagyo.dataItemsAmt = totalSagyo;
            // }
          }
          setProcessArr(orgData);
          laserAdditions.dataItems = orgData;

          // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
          if (laserAdditionsEditData?.length > 0 && editDataFlg) {
            toBEdit = JSON.parse(JSON.stringify(laserAdditionsEditData));
          } else {
            toBEdit = JSON.parse(JSON.stringify(orgData));
          }
        }
      }
      if (toBEdit?.length > 0) {
        editData = toBEdit;
        laserAdditions.editItems = editData;
        if (editData?.length > 0) {
          setEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum);
          laserAdditions.totalEditPrice = totals[0]?.totalSum;
          laserAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum;
          laserAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

          setTotalTime(totals[0]?.totalTimeSum);
          laserAdditions.totalEditTime = totals[0]?.totalTimeSum;
          laserAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum;
          laserAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;
        }
        setUpdateEditedData(laserAdditions);
      }
      if (laserAdditions?.dataType == DataType.Data) {
        setLaserAmt(laserAdditions.totalDataPrice);
      } else {
        setLaserAmt(laserAdditions.totalEditPrice);
      }
      props?.updateIQ3KouteiCal(laserAdditions, WorkType.SmBlankLaser);
    }
  }, [props?.isReCalIQ3, props?.iq3ActionForCal, props?.tabOpen, props?.dialogOk, props?.selectedIQ3DataDetail]);

  useEffect(() => {
    if (props?.tabOpen) {
      setEditMode(props?.editMode != undefined ? props?.editMode : false);
    }
  }, [props?.editMode]);

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setLaserAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmBlankLaser);
  };

  const getGasCoef = (deviceMst, thickness, materialTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;

    let gasCoeffThickThMaster = deviceMst?.details?.gasCoeffThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
    )?.[0];

    let gasCoeffThItems = Object.entries(deviceMst.details?.gasCoeffThItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeId
    )?.[0];

    let gasCoeffThItemsVal = 0;
    gasCoeffThItems?.map((item, index) => {
      if (index > 0) {
        gasCoeffThItemsVal = item?.filter(
          (item1) => parseInt(item1.no) == gasCoeffThickThMaster?.no && !item1?.isOutOfRange
        )?.[0]?.value;
      }
    });

    // IQX_WEBEST-147
    let gasCoeffThMaster = deviceMst?.details?.gasCoeffListItems?.filter(
      (item) => parseInt(item.no) == gasCoeffThItemsVal && !item?.isOutOfRange
    )?.[0];

    let childPartsCoeff = gasCoeffThMaster?.value;
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );

    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : [deviceMst?.details?.prodSizeItems[0]];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    partsCoeff = partsCoeff ? partsCoeff : 0;

    return partsCoeff;
  };

  const getSpeedCoef = (deviceMst, len, processType) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let childPartsCoeff = 0;
    let workSpeedCoeffLengthThMaster = deviceMst?.details?.workSpeedCoeffLengthThMaster?.filter(
      (item) => (item.value == len || item.value > len) && !item?.isOutOfRange
    )?.[0];

    if (processType == 1) {
      // 内周の加工速度係数取得
      childPartsCoeff = deviceMst?.details?.workSpeedCoeffInternalWorkItems?.filter(
        (item) => item.no == workSpeedCoeffLengthThMaster?.no && !item?.isOutOfRange
      )?.[0]?.value;
    } else {
      // 外周の加工速度係数取得
      childPartsCoeff = deviceMst?.details?.workSpeedCoeffExternalWorkItems?.filter(
        (item) => item.no == workSpeedCoeffLengthThMaster?.no && !item?.isOutOfRange
      )?.[0]?.value;
    }
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getSagyoSpeed = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
    )?.[0];

    let workSpeedItems = Object.entries(deviceMst.details?.workSpeedItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    if (deviceMst.details?.isUsedEasyWorkSpeed) {
      // 簡易値を取得
      workSpeedItems?.map((item, index) => {
        if (index > 0) {
          childPartsCoeff = item?.filter(
            (item) => parseInt(item.no) == workSpeedThickThMaster?.no && !item?.isOutOfRange
          )?.[0]?.value;
        }
      });
    }
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
  const getSagyoOut = (deviceMst, thickness, materialTypeIq3Id) => {
    let speedOut = 0;
    let workSpeedItem = {};
    if (deviceMst == undefined || deviceMst?.length == 0) return sagyoDetailsInfo;
    // 加工速度マスタ
    let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
    )?.[0];
    // 加工速度値
    let workSpeedItems = Object.entries(deviceMst.details?.workSpeedItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];
    // 詳細値を取得
    workSpeedItems?.map((item, index) => {
      if (index > 0) {
        workSpeedItem = item?.filter(
          (item) => parseInt(item.no) == workSpeedThickThMaster?.no && !item?.isOutOfRange
        )?.[0];
      }
    });
    speedOut = workSpeedItem?.speedOutLen;
    speedOut = speedOut === undefined ? 0 : speedOut;
    return speedOut;
  };

  // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
  const getSagyoIn = (deviceMst, thickness, materialTypeIq3Id, sagyoDetails, quantityList, gasCoef, sizeCoef) => {
    let workSpeedItem = {};
    let sagyoDetailsInfo = sagyoDetails;
    if (deviceMst == undefined || deviceMst?.length == 0) return sagyoDetailsInfo;
    // 加工速度マスタ
    let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
    )?.[0];
    // 加工速度値
    let workSpeedItems = Object.entries(deviceMst.details?.workSpeedItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];
    // 詳細値を取得
    workSpeedItems?.map((item, index) => {
      if (index > 0) {
        workSpeedItem = item?.filter(
          (item) => parseInt(item.no) == workSpeedThickThMaster?.no && !item?.isOutOfRange
        )?.[0];
      }
    });
    if (workSpeedItem) {
      for (let i = 0; i < quantityList?.length; i++) {
        let holeType = quantityList[i];
        let sizeIndex = 0;
        let holeVal = 0;
        // 丸の場合、丸穴径(Φ) (≦)と条件する
        if (holeType.types === 1) {
          for (let i = 0; i < workSpeedItem.roundHoleDiaThItems?.length; i++) {
            if (Number(holeType.diameter) <= Number(workSpeedItem.roundHoleDiaThItems[i].value)) {
              sizeIndex = i;
              holeVal = Number(holeType.perimeter);
              break;
            }
          }
          // 周長がLサイズより多きの場合、Lサイズを採用
          if (
            sizeIndex === 0 &&
            holeVal > workSpeedItem.roundHoleDiaThItems?.[workSpeedItem.roundHoleDiaThItems?.length - 1]
          ) {
            sizeIndex = 2;
          }
        } else {
          // 丸以外の場合、加工周長(mm)(≦)と条件する
          for (let i = 0; i < workSpeedItem.workLenThItems?.length; i++) {
            if (Number(holeType.perimeter) <= Number(workSpeedItem.workLenThItems[i].value)) {
              sizeIndex = i;
              holeVal = Number(holeType.perimeter);
              break;
            }
          }
          // 周長がLサイズより多きの場合、Lサイズを採用
          if (
            sizeIndex === 0 &&
            Number(holeType.perimeter) > workSpeedItem.workLenThItems?.[workSpeedItem.workLenThItems?.length - 1]?.value
          ) {
            sizeIndex = 2;
            holeVal = Number(holeType.perimeter);
          }
        }

        // 速度取得
        if (sizeIndex === 0) {
          // Sサイズ速度
          let speedInS = workSpeedItem.lenThItems?.[sizeIndex]?.value;
          sagyoDetailsInfo.sagyoInDetails.speedInS = speedInS === undefined ? 0 : speedInS;
          // Sサイズ合計周長
          sagyoDetailsInfo.sagyoInDetails.speedInSLen += holeVal;
          // 加工速度係数
          let speedInCoefS = getSpeedCoef(deviceMst, holeVal, 1);
          // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
          let sagyoTmInPerMin = Number.isFinite((holeVal * sizeCoef) / (speedInS * speedInCoefS * gasCoef))
            ? ((holeVal * sizeCoef) / (speedInS * speedInCoefS * gasCoef)) * 60
            : 0;
          sagyoTmInPerMin = sagyoTmInPerMin * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
          sagyoDetailsInfo.sagyoInDetails.sagyoTmInS += sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
          let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
          sagyoDetailsInfo.sagyoInDetails.sagyoAmtInS += sAmtIn === undefined ? 0 : sAmtIn;
        } else if (sizeIndex === 1) {
          // Mサイズ速度
          let speedInM = workSpeedItem.lenThItems?.[sizeIndex]?.value;
          sagyoDetailsInfo.sagyoInDetails.speedInM = speedInM === undefined ? 0 : speedInM;
          // Mサイズ合計周長
          sagyoDetailsInfo.sagyoInDetails.speedInMLen += holeVal;
          // 加工速度係数
          let speedInCoefM = getSpeedCoef(deviceMst, holeVal, 1);
          // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
          let sagyoTmInPerMin = Number.isFinite((holeVal * sizeCoef) / (speedInM * speedInCoefM * gasCoef))
            ? ((holeVal * sizeCoef) / (speedInM * speedInCoefM * gasCoef)) * 60
            : 0;
          sagyoTmInPerMin = sagyoTmInPerMin * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
          sagyoDetailsInfo.sagyoInDetails.sagyoTmInM += sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
          let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
          sagyoDetailsInfo.sagyoInDetails.sagyoAmtInM += sAmtIn === undefined ? 0 : sAmtIn;
        } else if (sizeIndex === 2) {
          // Lサイズ速度
          let speedInL = workSpeedItem.lenThItems?.[sizeIndex]?.value;
          sagyoDetailsInfo.sagyoInDetails.speedInL = speedInL === undefined ? 0 : speedInL;
          // Lサイズ合計周長
          sagyoDetailsInfo.sagyoInDetails.speedInLLen += holeVal;
          // 加工速度係数
          let speedInCoefL = getSpeedCoef(deviceMst, holeVal, 1);
          // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
          let sagyoTmInPerMin = Number.isFinite((holeVal * sizeCoef) / (speedInL * speedInCoefL * gasCoef))
            ? ((holeVal * sizeCoef) / (speedInL * speedInCoefL * gasCoef)) * 60
            : 0;
          sagyoTmInPerMin = sagyoTmInPerMin * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
          sagyoDetailsInfo.sagyoInDetails.sagyoTmInL += sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
          let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
          sagyoDetailsInfo.sagyoInDetails.sagyoAmtInL += sAmtIn === undefined ? 0 : sAmtIn;
        }
      }
      sagyoDetailsInfo.sagyoInDetails.speedInSLen =
        sagyoDetailsInfo.sagyoInDetails.speedInSLen === undefined
          ? toDecimal(0, 2)
          : toDecimal(sagyoDetailsInfo.sagyoInDetails.speedInSLen, 2);
      sagyoDetailsInfo.sagyoInDetails.speedInMLen =
        sagyoDetailsInfo.sagyoInDetails.speedInMLen === undefined
          ? toDecimal(0, 2)
          : toDecimal(sagyoDetailsInfo.sagyoInDetails.speedInMLen, 2);
      sagyoDetailsInfo.sagyoInDetails.speedInLLen =
        sagyoDetailsInfo.sagyoInDetails.speedInLLen === undefined
          ? toDecimal(0, 2)
          : toDecimal(sagyoDetailsInfo.sagyoInDetails.speedInLLen, 2);
      sagyoDetailsInfo.sagyoTmIn =
        sagyoDetailsInfo.sagyoInDetails.sagyoTmInS +
        sagyoDetailsInfo.sagyoInDetails.sagyoTmInM +
        sagyoDetailsInfo.sagyoInDetails.sagyoTmInL;
      sagyoDetailsInfo.sagyoAmtIn =
        sagyoDetailsInfo.sagyoInDetails.sagyoAmtInS +
        sagyoDetailsInfo.sagyoInDetails.sagyoAmtInM +
        sagyoDetailsInfo.sagyoInDetails.sagyoAmtInL;
    }
    return sagyoDetailsInfo;
  };

  const getSagyoPiercingTm = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    let piercingTimeThickThMaster = deviceMst?.details?.piercingTimeThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
    )?.[0];

    let piercingTimeThItems = Object.entries(deviceMst.details?.piercingTimeThItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    piercingTimeThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter(
          (item) => parseInt(item.no) == piercingTimeThickThMaster?.no && !item?.isOutOfRange
        )?.[0]?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getJointTm = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let jointSeparateThickThMaster = deviceMst?.details?.jointSeparateThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
    )?.[0];

    let jointSeparateItems = Object.entries(deviceMst.details?.jointSeparateItems)?.filter(
      ([key, data]) => parseInt(key) === jointSeparateThickThMaster?.no
    )?.[0];

    // ジョイント時間
    let childPartsCoeff = 0;
    jointSeparateItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == materialTypeIq3Id && !item?.isOutOfRange)?.[0]
          ?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const calSagyoAmtJoint = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.jointSeparate;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoAmtTwoStepHole = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.twoStepHolework;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const Blanking = () => {
    if (isBlankingdetail == true) {
      setIBlankingdetail(false);
    } else {
      setIBlankingdetail(true);
    }
  };
  const dandoriDetail = () => {
    if (isdandoriDetail == true) {
      setIsDandoriDetail(false);
    } else {
      setIsDandoriDetail(true);
    }
  };

  const sagyoDetail = () => {
    if (isSagyoDetail == true) {
      setIsSagyoDetail(false);
    } else {
      setIsSagyoDetail(true);
    }
  };

  const setLaserDisplay = (display) => {
    setIBlankingdetail(display);
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setLaserDisplay: setLaserDisplay,
  }));

  const toTwoDecimal = (j, param) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    newState[j].sagyoDetails[param] = toDecimal(newState[j]?.sagyoDetails?.[param], 2);
    setEditedData(newState);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setLaserAmt(updateEditedData.totalDataPrice);
    } else {
      setLaserAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBlankLaser);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct, arrType) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    let thickness = parameters?.materialIq3?.filter((item) => item.id == props?.selectedIQ3DataDetail?.thickness)?.[0]
      ?.info?.thick;
    // 重量
    let weight = props?.selectedIQ3DataDetail?.weight;
    // 面積
    let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
    //材質
    let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

    //材質区分
    let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
      ?.materialTypeId;

    // サイズ係数
    let sizeCoef = getSizeCoeff(deviceMst, weight, area);

    //ガス係数
    let gasCoef = getGasCoef(deviceMst, thickness, materialTypeId);

    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }

    let sagyo = [];
    let totals = [];
    //let deviceMst = [];

    let oldValue;

    if (value === undefined) {
      value = 0;
    } else {
      if (objId.includes('speed') && objId.includes('Len')) {
        value = value ? value : 0;
      } else {
        value = value ? Number(value) : 0;
      }
    }

    if (arrType === 'dandori') {
      if (objId === 'dandoriTime') {
        if (document.getElementById('dandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById('dandoriAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newState[index].dandoriAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue[index]?.dandoriTime;
              newState[index].dandoriAmt = changeAmtwithTime(
                newState[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              checkInput(index + objId, value);
            } else {
              newState[index].dandoriAmt = changeAmtwithTime(
                newState?.[index]?.dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
            }
          }
        }
        newState[index].dandoriTime = value;
        updateEditedData.editItems[index].dandoriTime = value;
        updateEditedData.editItems[index].dandoriAmt = newState[index]?.dandoriAmt;
        updateEditedData.totalDandori.editItemsAmt = newState[index]?.dandoriAmt
          ? Math.round(newState[index]?.dandoriAmt)
          : 0;
        updateEditedData.totalDandori.editItemsTime = newState[index]?.value;
      } else if (objId === 'dandoriAmt') {
        newState[index].dandoriAmt = value;
        updateEditedData.editItems[index].dandoriAmt = value;
      }
    } else if (arrType === 'sagyo') {
      if (objId.includes('Tm')) {
        let details = newState[index].sagyoDetails;
        newState[0].sagyoDetails[objId] = value;
        let amtId = objId.replace('Tm', 'Amt');
        let cuId = amtId + 'Cu';
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newState[index].sagyoDetails[amtId] = 0;
            } else if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].sagyoTime);
              details[amtId] = changeAmtwithTime(details[amtId], timeType, timeAct, value, 'sagyoTime');
              checkInput(index + objId, value);
            } else {
              newState[index].sagyoDetails[amtId] = changeAmtwithTime(
                details[amtId],
                timeType,
                timeAct,
                value,
                'sagyoTime'
              );
              // checkInputWithColor(false, '', objId, value, 'red');
            }
          }
        }
        updateEditedData.editItems[index].sagyoDetails[amtId] = newState[index]?.sagyoDetails[amtId];
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      } else if (objId.includes('speed') || objId.includes('Len')) {
        let amtId, tmId, id, cuId;
        id = objId;
        if (objId.includes('Len')) {
          id = objId.replace('Len', '');
        }
        amtId = id.replace('speed', 'sagyoAmt');
        tmId = id.replace('speed', 'sagyoTm');
        cuId = amtId + 'Cu';

        let length, speed, lengthId, speedId;

        if (objId.includes('Len')) {
          speedId = objId.replace('Len', '');
          speed = newState[index]?.sagyoDetails[speedId];
          length = value;
        } else if (objId.includes('speed') && !objId.includes('Len')) {
          lengthId = objId + 'Len';
          length = newState[index]?.sagyoDetails[lengthId];
          speed = value;
        }

        /* let length = objId.includes('Len') ? value : sagyo[index]?.length;
        let speed = objId.includes('speed') && !objId.includes('len') ? value : sagyo[index].speed; */
        // ケガキ加工時間（ケガキ線長	/	加工速度）
        let speedCal = 0;
        let sagyoTimePerSec = 0;
        if (
          [
            'speedIn',
            'speedOut',
            'speedInLen',
            'speedOutLen',
            'speedAirDeliLen',
            'speedAirDeli',
            'speedOutTwiceCut', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            'speedOutTwiceCutLen', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            'speedInTwiceCut', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            'speedInTwiceCutLen', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
          ].includes(objId)
        ) {
          if (objId.includes('In')) {
            speedCal = speed * speedInCoeff * gasCoef;
          } else if (objId.includes('Out')) {
            speedCal = speed * speedOutCoeff * gasCoef;
          }
          let lengthCal = length * sizeCoef;
          sagyoTimePerSec = Number.isFinite(lengthCal / speedCal) ? lengthCal / speedCal : 0; // IQX_WEBEST-188
          if (objId.includes('Air')) {
            let speedAirDeliLen = objId === 'speedAirDeliLen' ? value : newState[index].sagyoDetails['speedAirDeliLen'];
            let speedAirDeli = objId === 'speedAirDeli' ? value : newState[index].sagyoDetails['speedAirDeli'];
            sagyoTimePerSec = Number.isFinite(speedAirDeliLen / speedAirDeli) ? speedAirDeliLen / speedAirDeli : 0;
          }
        }

        //sagyo[index].sagyoTime = secondsToHms(sagyoTimePerSec);
        let sagyoTimeSec = sagyoTimePerSec * 60 * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
        newState[index].sagyoDetails[tmId] = sagyoTimeSec;
        /*  sagyo[index].sagyoAmt = calSagyoAmt(setTimetoSec(sagyo[index].sagyoTime), deviceMst); */
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            newState[index].sagyoDetails[amtId] = calSagyoAmt(sagyoTimeSec, deviceMst);
          }
        }
        newState[0].sagyoDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails[amtId] = newState[index].sagyoDetails[amtId];
        updateEditedData.editItems[index].sagyoDetails[tmId] = newState[index]?.sagyoDetails[tmId];
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      } else if (objId.includes('Amt')) {
        newState[0].sagyoDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      } else if (objId === 'piercingcount' || objId === 'piercingTime') {
        // サイズ係数
        let piercingTime = objId === 'piercingTime' ? value : newState[index]?.sagyoDetails.speedPiercing;
        let piercingcount = objId === 'piercingcount' ? value : newState[index]?.sagyoDetails.speedPiercingLen;
        // 通常パンチ時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
        let sagyoTimePerSec = piercingTime * piercingcount * sizeCoef * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
        newState[index].sagyoDetails.sagyoTmPiercing = sagyoTimePerSec;
        if (document.getElementById('sagyoAmtPiercingCu').style.display !== undefined) {
          let showHideCu = document.getElementById('sagyoAmtPiercingCu').style.display;
          if (showHideCu === 'none') {
            newState[index].sagyoDetails.sagyoAmtPiercing = calSagyoAmt(sagyoTimePerSec, deviceMst);
          }
        }
        updateEditedData.editItems[index].sagyoDetails['sagyoAmtPiercing'] =
          newState[index]?.sagyoDetails.sagyoAmtPiercing;
        updateEditedData.editItems[index].sagyoDetails['sagyoTmPiercing'] =
          newState[index]?.sagyoDetails.sagyoTmPiercing;
        if (objId === 'piercingcount') {
          newState[0].sagyoDetails['speedPiercingLen'] = value;
          updateEditedData.editItems[index].sagyoDetails['speedPiercingLen'] = value;
        } else if (objId === 'piercingTime') {
          newState[0].sagyoDetails['speedPiercing'] = value;
          updateEditedData.editItems[index].sagyoDetails['speedPiercing'] = value;
        }
      }
    } else if (objId === 'twoStepHoleCnt' || objId === 'twoStepHoleTm') {
      // 2工程穴加工金額/時間
      newState[index].sagyoDetails.twoStepHoleCnt =
        objId === 'twoStepHoleCnt' ? value : newState?.[index]?.sagyoDetails?.twoStepHoleCnt;
      newState[index].sagyoDetails.twoStepHoleTm =
        objId === 'twoStepHoleTm' ? value : newState?.[index]?.sagyoDetails?.twoStepHoleTm;

      // 2工程穴加工時間	*	個数
      let drillingTimeSec =
        newState?.[index]?.sagyoDetails?.twoStepHoleTm *
        newState?.[index]?.sagyoDetails?.twoStepHoleCnt *
        getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
      newState[index].sagyoDetails.sagyoTmTwoStepHole = drillingTimeSec;
      if (document.getElementById('sagyoAmtTwoStepHoleCu').style.display !== undefined) {
        let showHideCu = document.getElementById('sagyoAmtTwoStepHoleCu').style.display;
        if (showHideCu === 'none') {
          newState[index].sagyoDetails.sagyoAmtTwoStepHole = calSagyoAmtTwoStepHole(drillingTimeSec, deviceMst);
        }
      }
      newState[0].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails['sagyoAmtTwoStepHole'] =
        newState[index].sagyoDetails.sagyoAmtTwoStepHole;
      updateEditedData.editItems[index].sagyoDetails['sagyoTmTwoStepHole'] =
        newState[index].sagyoDetails.sagyoTmTwoStepHole;
    } else if (objId === 'jointCnt' || arrType === 'jointTm') {
      // ジョイント加工金額/時間
      newState[index].sagyoDetails.jointTm = objId === 'jointTm' ? value : newState?.[index]?.sagyoDetails?.jointTm;
      newState[index].sagyoDetails.jointCnt = objId === 'jointCnt' ? value : newState?.[index]?.sagyoDetails?.jointCnt;

      // ジョイント加工時間	*	個数
      let jointTimeSec =
        newState?.[index]?.sagyoDetails?.jointTm *
        newState?.[index]?.sagyoDetails?.jointCnt *
        getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
      newState[index].sagyoDetails.sagyoTmJoint = jointTimeSec;
      if (document.getElementById('sagyoAmtJointCu').style.display !== undefined) {
        let showHideCu = document.getElementById('sagyoAmtJointCu').style.display;
        if (showHideCu === 'none') {
          newState[index].sagyoDetails.sagyoAmtJoint = calSagyoAmtJoint(jointTimeSec, deviceMst);
        }
      }
      newState[0].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails['sagyoAmtJoint'] = newState[index].sagyoDetails.sagyoAmtJoint;
      updateEditedData.editItems[index].sagyoDetails['sagyoTmJoint'] = newState[index].sagyoDetails.sagyoTmJoint;
    } else if (arrType === 'sagyoDetail') {
      if (objId.includes('Tm')) {
        let details = newState[index].sagyoDetails.sagyoInDetails;
        newState[0].sagyoDetails.sagyoInDetails[objId] = value;
        let amtId = objId.replace('Tm', 'Amt');
        let cuId = amtId + 'Cu';
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newState[index].sagyoDetails.sagyoInDetails[amtId] = 0;
            } else if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].sagyoTime);
              details[amtId] = changeAmtwithTime(details[amtId], timeType, timeAct, value, 'sagyoTime');
              checkInput(index + objId, value);
            } else {
              newState[index].sagyoDetails.sagyoInDetails[amtId] = changeAmtwithTime(
                details[amtId],
                timeType,
                timeAct,
                value,
                'sagyoTime'
              );
              // checkInputWithColor(false, '', objId, value, 'red');
            }
          }
        }
        updateEditedData.editItems[index].sagyoDetails.sagyoInDetails[amtId] =
          newState[index]?.sagyoDetails.sagyoInDetails[amtId];
        updateEditedData.editItems[index].sagyoDetails.sagyoInDetails[objId] = value;
      } else if (objId.includes('speed') || objId.includes('Len')) {
        let amtId, tmId, id, cuId;
        id = objId;
        if (objId.includes('Len')) {
          id = objId.replace('Len', '');
        }
        amtId = id.replace('speed', 'sagyoAmt');
        tmId = id.replace('speed', 'sagyoTm');
        cuId = amtId + 'Cu';

        let length, speed, lengthId, speedId;

        if (objId.includes('Len')) {
          speedId = objId.replace('Len', '');
          speed = newState[index]?.sagyoDetails?.sagyoInDetails[speedId];
          length = value;
        } else if (objId.includes('speed') && !objId.includes('Len')) {
          lengthId = objId + 'Len';
          length = newState[index]?.sagyoDetails?.sagyoInDetails[lengthId];
          speed = value;
        }

        let speedInCoeff = getSpeedCoef(deviceMst, length, 1);

        /* let length = objId.includes('Len') ? value : sagyo[index]?.length;
        let speed = objId.includes('speed') && !objId.includes('len') ? value : sagyo[index].speed; */
        // ケガキ加工時間（ケガキ線長	/	加工速度）
        let speedCal = 0;
        let sagyoTimePerSec = 0;
        if (
          [
            'speedIn',
            'speedOut',
            'speedInLen',
            'speedOutLen',
            'speedAirDeliLen',
            'speedAirDeli',
            'speedInS',
            'speedInM',
            'speedInL',
            'speedInSLen',
            'speedInMLen',
            'speedInLLen',
            'speedOutTwiceCut', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            'speedOutTwiceCutLen', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            'speedInTwiceCut', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            'speedInTwiceCutLen', // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
          ].includes(objId)
        ) {
          if (objId.includes('In')) {
            speedCal = speed * speedInCoeff * gasCoef;
          } else if (objId.includes('Out')) {
            speedCal = speed * speedOutCoeff * gasCoef;
          }
          let lengthCal = length * sizeCoef;
          sagyoTimePerSec = Number.isFinite(lengthCal / speedCal) ? lengthCal / speedCal : 0; // IQX_WEBEST-188
        }

        //sagyo[index].sagyoTime = secondsToHms(sagyoTimePerSec);
        let sagyoTimeSec = sagyoTimePerSec * 60 * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
        newState[index].sagyoDetails.sagyoInDetails[tmId] = sagyoTimeSec;
        /*  sagyo[index].sagyoAmt = calSagyoAmt(setTimetoSec(sagyo[index].sagyoTime), deviceMst); */
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            newState[index].sagyoDetails.sagyoInDetails[amtId] = calSagyoAmt(sagyoTimeSec, deviceMst);
          }
        }
        newState[0].sagyoDetails.sagyoInDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails.sagyoInDetails[amtId] =
          newState[index].sagyoDetails.sagyoInDetails[amtId];
        updateEditedData.editItems[index].sagyoDetails.sagyoInDetails[tmId] =
          newState[index]?.sagyoDetails.sagyoInDetails[tmId];
        updateEditedData.editItems[index].sagyoDetails.sagyoInDetails[objId] = value;
      } else if (objId.includes('Amt')) {
        newState[0].sagyoDetails.sagyoInDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails.sagyoInDetails[objId] = value;
      }
    }

    if (
      (deviceMst?.details?.isUsedEasyWorkSpeed !== undefined || !deviceMst?.details?.isUsedEasyWorkSpeed) &&
      arrType === 'sagyoDetail'
    ) {
      newState[0].sagyoDetails.sagyoAmtIn =
        newState[0].sagyoDetails.sagyoInDetails.sagyoAmtInS +
        newState[0].sagyoDetails.sagyoInDetails.sagyoAmtInM +
        newState[0].sagyoDetails.sagyoInDetails.sagyoAmtInL;
      newState[0].sagyoDetails.sagyoTmIn =
        newState[0].sagyoDetails.sagyoInDetails.sagyoTmInS +
        newState[0].sagyoDetails.sagyoInDetails.sagyoTmInM +
        newState[0].sagyoDetails.sagyoInDetails.sagyoTmInL;
    }

    // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
    if (objId.includes('OutTwiceCut') || objId.includes('InTwiceCut')) {
      newState[0].sagyoDetails.sagyoAmtTwiceCut =
        newState[0].sagyoDetails.sagyoAmtOutTwiceCut + newState[0].sagyoDetails.sagyoAmtInTwiceCut;
      newState[0].sagyoDetails.sagyoTmTwiceCut =
        newState[0].sagyoDetails.sagyoTmOutTwiceCut + newState[0].sagyoDetails.sagyoTmInTwiceCut;
    }

    newState[0].sagyoAmt =
      newState[0].sagyoDetails.sagyoAmtOut +
      newState[0].sagyoDetails.sagyoAmtIn +
      newState[0].sagyoDetails.sagyoAmtTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      newState[0].sagyoDetails.sagyoAmtAirDeli +
      newState[0].sagyoDetails.sagyoAmtScribing +
      newState[0].sagyoDetails.sagyoAmtPiercing;
    updateEditedData.editItems[index].sagyoAmt = newState[0].sagyoAmt;

    newState[0].sagyoTime =
      newState[0].sagyoDetails.sagyoTmOut +
      newState[0].sagyoDetails.sagyoTmIn +
      newState[0].sagyoDetails.sagyoTmTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      newState[0].sagyoDetails.sagyoTmAirDeli +
      newState[0].sagyoDetails.sagyoTmScribing +
      newState[0].sagyoDetails.sagyoTmPiercing;
    updateEditedData.editItems[index].sagyoTime = newState[0].sagyoTime;

    let totalSagyo =
      (newState[0].sagyoAmt ? Math.round(newState[0].sagyoAmt) : 0) +
      (newState[0].sagyoDetails.sagyoAmtTwoStepHole ? Math.round(newState[0].sagyoDetails.sagyoAmtTwoStepHole) : 0) +
      (newState[0].sagyoDetails.sagyoAmtJoint ? Math.round(newState[0].sagyoDetails.sagyoAmtJoint) : 0);
    updateEditedData.totalSagyo.editItemsAmt = totalSagyo;
    let totalSagyoTm =
      newState[0].sagyoTime + newState[0].sagyoDetails.sagyoTmTwoStepHole + newState[0].sagyoDetails.sagyoTmJoint;
    updateEditedData.totalSagyo.editItemsTime = totalSagyoTm;

    /** 　合計時間　*/
    let totalTimeSum = newState[0].dandoriTime + totalSagyoTm;
    setTotalTime(totalTimeSum);
    updateEditedData.totalEditTime = totalTimeSum;

    /** 　合計金額　*/
    let totalSum =
      (newState[0].dandoriAmt ? Math.round(newState[0].dandoriAmt) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
    setTotalAmt(totalSum);
    updateEditedData.totalEditPrice = totalSum;
    setEditedData(newState);

    if (updateEditedData.dataType == DataType.Data) {
      setLaserAmt(updateEditedData?.totalDataPrice);
    } else {
      setLaserAmt(updateEditedData?.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBlankLaser);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    //let deviceMst = [];

    if (processType === 'dandoriTime' || processType === 'drillingTime' || processType === 'jointTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('laserProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('laserProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownLaserIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpLaserIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('laserProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownLaserIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpLaserIQ3' + i).style.display = 'block';
    }
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <>
      {props?.tabOpen && props?.isLaser ? (
        <div className="kouteiCalculateDiv">
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={Blanking}>
            <Col span={4} className="oyabuhin-col">
              <div style={{ display: 'flex' }}>
                {isBlankingdetail ? (
                  <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                )}
                <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
              </div>
            </Col>
            <Col span={6} className="oyabuhin-col">
              <div>{laserAmt ? JPYs.format(Math.round(laserAmt)) : amtEmptyStr}</div>
            </Col>
            <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
              <div>
                <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
              </div>
            </Col>
          </Row>
          <div style={{ display: isBlankingdetail ? 'block' : 'none' }}>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                工程詳細
              </Col>
              <Col span={6} className="oyabuhin-detail-col">
                <Checkbox
                  key={kouteiCalculateSelectedArr[0].key}
                  value={kouteiCalculateSelectedArr[0].value}
                  checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
                  disabled={!props?.editMode}
                  style={{ marginRight: 6 }}
                  onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
                ></Checkbox>
                <label>{kouteiCalculateSelectedArr[0].value}</label>
              </Col>
              <Col span={8} className="oyabuhin-detail-col">
                <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                  <Checkbox
                    key={kouteiCalculateSelectedArr[1].key}
                    value={kouteiCalculateSelectedArr[1].value}
                    disabled={!props?.editMode}
                    onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                    checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                    style={{ marginRight: 6 }}
                  ></Checkbox>
                  <label>{kouteiCalculateSelectedArr[1].value}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                  <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                    {DataButton}
                  </Button>
                </div>
              </Col>

              <Col span={1} className="oyabuhin-detail-col">
                単位
              </Col>
              <Col span={5} className="formula-column">
                計算式
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                {processMst?.name}
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalAmtIn"
                  style={{
                    color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalTimeIn"
                  style={{
                    color: totalTime === totalTimeSum ? 'black' : '#4cc12f',
                  }}
                >
                  {totalTime ? secondsToHms(totalTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.procTotalAmt_laser}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.procTotalTm_laser}>時間</Popover>
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  <label> 段取金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="dandoriAmt">
                  {processArr[0]?.dandoriAmt ? JPYs.format(Math.round(processArr[0]?.dandoriAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label /* id="totalDandoriTimeBlank" */ id="dandoriTime">
                  {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={4} className="input-col">
                <CurrencyInput
                  id={'dandoriAmtIn'}
                  value={editedData[0]?.dandoriAmt ? Math.round(editedData[0]?.dandoriAmt) : 0}
                  defaultValue={0}
                  onValueChange={(e) => {
                    if (editedData[0]?.dandoriAmt != e) {
                      settingNewDandoriAmtInArr(e, 0, 'dandoriAmt', '', '', 'dandori');
                      checkInputWithColor(false, '', 'dandoriAmt', e, 'amt');
                    }
                  }}
                  /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(false,'', 'dandoriAmt', e, 'amt');
                            }
                          }} */
                  style={{
                    border: 'none',
                    color:
                      Math.round(processArr[0]?.dandoriAmt) === Math.round(editedData[0]?.dandoriAmt)
                        ? 'black'
                        : checkShowHideIcon('dandoriAmtCu') === 'none'
                        ? '#4cc12f'
                        : 'red',
                  }}
                  className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                  prefix="¥"
                  decimalsLimit={100}
                />
                <div id={'dandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                  <div className="currencyIcon">
                    <BsUsbC style={{ fontSize: '25' }} />
                    <BsX style={{ margin: '-8px 0 0 16px' }} />
                    <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                  </div>
                </div>
              </Col>
              <Col span={4} className="input-col">
                <Row style={{ width: '100%' }}>
                  <Col className="timer-input-col">
                    <Input
                      value={editedData[0]?.dandoriTime ? secondsToHms(editedData[0]?.dandoriTime) : timeHmsStr}
                      id={'dandoriTimeIn'}
                      style={{
                        width: '100%',
                        color: editedData[0]?.dandoriTime === processArr[0]?.dandoriTime ? 'black' : 'red',
                      }}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      onChange={(e) => {
                        //disableTimer('test');
                        settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTime', 'input', 'input', 'dandori');
                        // checkInput( 'dandoriTime', e.target.value);
                      }}
                    ></Input>
                  </Col>
                  <Col className="timer-button-col">
                    {editMode ? (
                      <>
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(editedData[0]?.dandoriTime, '10min', 'dandoriTime');
                            settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', '10min', 'inc', 'dandori');
                            /*  settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input'); */
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(editedData[0]?.dandoriTime, '10min', 'dandoriTime');
                            settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', '10min', 'dec', 'dandori');
                          }}
                        />
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(editedData[0]?.dandoriTime, 'min', 'dandoriTime');

                            settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'min', 'inc', 'dandori');
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(editedData[0]?.dandoriTime, 'min', 'dandoriTime');
                            settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'min', 'dec', 'dandori');
                          }}
                        />
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(editedData[0]?.dandoriTime, 'sec', 'dandoriTime');
                            settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'sec', 'inc', 'dandori');
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(editedData[0]?.dandoriTime, 'sec', 'dandoriTime');
                            settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'sec', 'dec', 'dandori');
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.setupTm_p4}>時間</Popover>
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={sagyoDetail}>
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  {isSagyoDetail ? (
                    <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                  )}
                  <label> 加工金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoAmtBlank">
                  {processArr[0]?.sagyoAmt ? JPYs.format(Math.round(processArr[0]?.sagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoTimeBlank">
                  {processArr[0]?.sagyoTime ? secondsToHms(processArr[0]?.sagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoAmtBlankIn"
                  style={{
                    color:
                      Math.round(editedData[0]?.sagyoAmt) === Math.round(processArr[0]?.sagyoAmt) ? 'black' : '#4cc12f',
                  }}
                >
                  {editedData[0]?.sagyoAmt ? JPYs.format(Math.round(editedData[0]?.sagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoTimeBlankIn"
                  style={{
                    color:
                      Math.round(editedData[0]?.sagyoTime) === Math.round(processArr[0]?.sagyoTime)
                        ? 'black'
                        : '#4cc12f',
                  }}
                >
                  {editedData[0]?.sagyoTime ? secondsToHms(editedData[0]?.sagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.workAmt_p2}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.workTotalTm_laser}>時間</Popover>
              </Col>
            </Row>
            <div style={{ display: isSagyoDetail ? 'block' : 'none' }}>
              {/** 外周 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ31'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(1)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ31'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(1)}
                    />
                    <span style={{ marginLeft: '5px' }}>外周切断金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtOut'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtOut
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtOut))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmOut'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmOut
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmOut)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtOutIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtOut
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtOut)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtOut != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtOut', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtOut', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtOut) ===
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtOut)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtOutCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtOutCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmOut
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmOut)
                            : timeHmsStr
                        }
                        id={'sagyoTmOutIn'}
                        style={{
                          width: '100%',
                          color:
                            Math.round(editedData[0]?.sagyoDetails?.sagyoTmOut) ===
                            Math.round(processArr[0]?.sagyoDetails?.sagyoTmOut)
                              ? 'black'
                              : Number(oldEditedData[0]?.sagyoDetails?.speedOut) !==
                                  Number(editedData[0]?.sagyoDetails?.speedOut) ||
                                Number(oldEditedData[0]?.sagyoDetails?.speedOutLen) !==
                                  Number(editedData[0]?.sagyoDetails?.speedOutLen)
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmOut', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmOut,
                                '10min',
                                'sagyoTmOut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOut', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmOut,
                                '10min',
                                'sagyoTmOut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOut', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmOut,
                                'min',
                                'sagyoTmOut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOut', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmOut,
                                'min',
                                'sagyoTmOut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOut', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmOut,
                                'sec',
                                'sagyoTmOut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOut', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmOut,
                                'sec',
                                'sagyoTmOut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOut', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_outerDia}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_outerDia}>時間</Popover>
                </Col>
              </Row>
              <div id={'laserProcessIQ31'} style={{ display: 'none' }}>
                {/* 外周切断速度/切断長 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      外周切断速度/切断長
                    </span>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="speedOut">{processArr[0]?.sagyoDetails?.speedOut}</label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'speedOutLen'}>
                      {processArr[0]?.sagyoDetails?.speedOutLen
                        ? toDecimal(processArr[0]?.sagyoDetails?.speedOutLen, 2)
                        : toDecimal(0, 2)}
                    </label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedOut}
                      id={'speedOutIn'}
                      min={0}
                      className={editMode ? 'input-editable' : 'input-non-editable '}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedOut === editedData[0]?.sagyoDetails?.speedOut
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedOut', '', '', 'sagyo')}
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedOut');
                      // }}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedOutLen}
                      id={'speedOutLenIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedOutLen === editedData[0]?.sagyoDetails?.speedOutLen
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedOutLen', '', '', 'sagyo')}
                      onBlur={(e) => {
                        toTwoDecimal(0, 'speedOutLen');
                      }}
                    />
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
              {/** 内周 IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい */}
              {deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed ? (
                <>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined
                          id={'arrowUpLaserIQ32'}
                          style={{ fontSize: 17, marginTop: 2 }}
                          onClick={(e) => subpartDetail(2)}
                        />
                        <CaretDownOutlined
                          id={'arrowDownLaserIQ32'}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                          onClick={(e) => subpartDetail(2)}
                        />
                        <span style={{ marginLeft: '5px' }}>内周切断金額/時間</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {/* ¥ */}
                      <label id={'sagyoAmtIn'}>
                        {processArr[0]?.sagyoDetails?.sagyoAmtIn
                          ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtIn))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={'sagyoTmIn'}>
                        {processArr[0]?.sagyoDetails?.sagyoTmIn
                          ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmIn)
                          : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={'sagyoAmtInIn'}
                        value={
                          editedData[0]?.sagyoDetails?.sagyoAmtIn
                            ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtIn)
                            : 0
                        }
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[0]?.sagyoDetails?.sagyoAmtIn != e) {
                            settingNewDandoriAmtInArr(e, 0, 'sagyoAmtIn', '', '', 'sagyo');
                            checkInputWithColor(false, '', 'sagyoAmtIn', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            Math.round(editedData[0]?.sagyoDetails?.sagyoAmtIn) ===
                            Math.round(processArr[0]?.sagyoDetails?.sagyoAmtIn)
                              ? 'black'
                              : checkShowHideIcon('sagyoAmtInCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />

                      <div id={'sagyoAmtInCu'} style={{ display: 'none' }} className="currencyReset">
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={
                              editedData[0]?.sagyoDetails?.sagyoTmIn
                                ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmIn)
                                : timeHmsStr
                            }
                            id={'sagyoTmInIn'}
                            style={{
                              width: '100%',
                              color:
                                editedData[0]?.sagyoDetails?.sagyoTmIn === processArr[0]?.sagyoDetails?.sagyoTmIn
                                  ? 'black'
                                  : Number(oldEditedData[0]?.sagyoDetails?.speedIn) !==
                                      Number(editedData[0]?.sagyoDetails?.speedIn) ||
                                    Number(oldEditedData[0]?.sagyoDetails?.speedInLen) !==
                                      Number(editedData[0]?.sagyoDetails?.speedInLen)
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmIn', 'input', 'input', 'sagyo');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    '10min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', '10min', 'inc', 'sagyo');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    '10min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', '10min', 'dec', 'sagyo');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'min', 'inc', 'sagyo');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'min', 'dec', 'sagyo');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'sec',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'sec', 'inc', 'sagyo');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'sec',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'sec', 'dec', 'sagyo');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={2} className="formula-column formula-column-right-border">
                      <Popover content={helpCmt.workAmt_innerDia}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column">
                      <Popover content={helpCmt.workTm_innerDia}>時間</Popover>
                    </Col>
                  </Row>
                  <div id={'laserProcessIQ32'} style={{ display: 'none' }}>
                    {/* 内周切断速度/切断長 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                          内周切断速度/切断長
                        </span>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="speedIn">{processArr[0]?.sagyoDetails?.speedIn}</label>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'speedInLen'}>
                          {processArr[0]?.sagyoDetails?.speedInLen
                            ? toDecimal(processArr[0]?.sagyoDetails?.speedInLen, 2)
                            : toDecimal(0, 2)}
                        </label>
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.speedIn}
                          id={'speedInIn'}
                          min={0}
                          className={editMode ? 'input-editable' : 'input-non-editable '}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.speedIn === editedData[0]?.sagyoDetails?.speedIn
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedIn', '', '', 'sagyo')}
                          // onBlur={(e) => {
                          //   toTwoDecimal(0, 'speedIn');
                          // }}
                        />
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.speedInLen}
                          id={'speedInLenIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.speedInLen === editedData[0]?.sagyoDetails?.speedInLen
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedInLen', '', '', 'sagyo')}
                          onBlur={(e) => {
                            toTwoDecimal(0, 'speedInLen');
                          }}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="formula-column"></Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined
                          id={'arrowUpLaserIQ32'}
                          style={{ fontSize: 17, marginTop: 2 }}
                          onClick={(e) => subpartDetail(2)}
                        />
                        <CaretDownOutlined
                          id={'arrowDownLaserIQ32'}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                          onClick={(e) => subpartDetail(2)}
                        />
                        <span style={{ marginLeft: '5px' }}>内周切断金額/時間</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {/* ¥ */}
                      <label id={'sagyoAmtIn'}>
                        {processArr[0]?.sagyoDetails?.sagyoAmtIn
                          ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtIn))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={'sagyoTmIn'}>
                        {processArr[0]?.sagyoDetails?.sagyoTmIn
                          ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmIn)
                          : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={'sagyoAmtInIn'}
                        value={
                          editedData[0]?.sagyoDetails?.sagyoAmtIn
                            ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtIn)
                            : 0
                        }
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[0]?.sagyoDetails?.sagyoAmtIn != e) {
                            settingNewDandoriAmtInArr(e, 0, 'sagyoAmtIn', '', '', 'sagyo');
                            checkInputWithColor(false, '', 'sagyoAmtIn', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            Math.round(editedData[0]?.sagyoDetails?.sagyoAmtIn) ===
                            Math.round(processArr[0]?.sagyoDetails?.sagyoAmtIn)
                              ? 'black'
                              : checkShowHideIcon('sagyoAmtInCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />

                      <div id={'sagyoAmtInCu'} style={{ display: 'none' }} className="currencyReset">
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={
                              editedData[0]?.sagyoDetails?.sagyoTmIn
                                ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmIn)
                                : timeHmsStr
                            }
                            id={'sagyoTmInIn'}
                            style={{
                              width: '100%',
                              color:
                                editedData[0]?.sagyoDetails?.sagyoTmIn === processArr[0]?.sagyoDetails?.sagyoTmIn
                                  ? 'black'
                                  : Number(oldEditedData[0]?.sagyoDetails?.speedIn) !==
                                      Number(editedData[0]?.sagyoDetails?.speedIn) ||
                                    Number(oldEditedData[0]?.sagyoDetails?.speedInLen) !==
                                      Number(editedData[0]?.sagyoDetails?.speedInLen)
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmIn', 'input', 'input', 'sagyo');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    '10min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', '10min', 'inc', 'sagyo');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    '10min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', '10min', 'dec', 'sagyo');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'min', 'inc', 'sagyo');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'min',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'min', 'dec', 'sagyo');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'sec',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'sec', 'inc', 'sagyo');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[0]?.sagyoDetails?.sagyoTmIn,
                                    'sec',
                                    'sagyoTmIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmIn', 'sec', 'dec', 'sagyo');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={2} className="formula-column formula-column-right-border">
                      <Popover content={helpCmt.workAmt_innerDia}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column">
                      <Popover content={helpCmt.workTm_innerDia}>時間</Popover>
                    </Col>
                  </Row>
                  <div id={'laserProcessIQ32'} style={{ display: 'none' }}>
                    {/** Sサイズ内周 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', marginLeft: '40px' }}>
                          <span style={{ marginLeft: '5px' }}>内周切断金額/時間(S)</span>
                        </div>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        {/* ¥ */}
                        <label id={'sagyoAmtIn'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS
                            ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS))
                            : amtEmptyStr}
                        </label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'sagyoTmIn'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS
                            ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS)
                            : timeHmsStr}
                        </label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={'sagyoAmtInIn'}
                          value={
                            editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS
                              ? Math.round(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS)
                              : 0
                          }
                          defaultValue={0}
                          onValueChange={(e) => {
                            if (editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS != e) {
                              settingNewDandoriAmtInArr(e, 0, 'sagyoAmtInS', '', '', 'sagyoDetail');
                              checkInputWithColor(false, '', 'sagyoAmtInS', e, 'amt');
                            }
                          }}
                          style={{
                            border: 'none',
                            color:
                              Math.round(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS) ===
                              Math.round(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInS)
                                ? 'black'
                                : checkShowHideIcon('sagyoAmtInCu') === 'none'
                                ? '#4cc12f'
                                : 'red',
                          }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />

                        <div id={'sagyoAmtInSCu'} style={{ display: 'none' }} className="currencyReset">
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={
                                editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS
                                  ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS)
                                  : timeHmsStr
                              }
                              id={'sagyoTmInInS'}
                              style={{
                                width: '100%',
                                color:
                                  editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS ===
                                  processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS
                                    ? 'black'
                                    : Number(oldEditedData[0]?.sagyoDetails?.sagyoInDetails?.speedInS) !==
                                        Number(editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInS) ||
                                      Number(oldEditedData[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen) !==
                                        Number(editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen)
                                    ? '#4cc12f'
                                    : 'red',
                              }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(
                                  e.target.value,
                                  0,
                                  'sagyoTmInS',
                                  'input',
                                  'input',
                                  'sagyoDetail'
                                );
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS,
                                      '10min',
                                      'sagyoTmInS'
                                    );
                                    settingNewDandoriAmtInArr(
                                      editedTime,
                                      0,
                                      'sagyoTmInS',
                                      '10min',
                                      'inc',
                                      'sagyoDetail'
                                    );
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS,
                                      '10min',
                                      'sagyoTmInS'
                                    );
                                    settingNewDandoriAmtInArr(
                                      editedTime,
                                      0,
                                      'sagyoTmInS',
                                      '10min',
                                      'dec',
                                      'sagyoDetail'
                                    );
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS,
                                      'min',
                                      'sagyoTmInS'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInS', 'min', 'inc', 'sagyoDetail');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS,
                                      'min',
                                      'sagyoTmInS'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInS', 'min', 'dec', 'sagyoDetail');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS,
                                      'sec',
                                      'sagyoTmInS'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInS', 'sec', 'inc', 'sagyoDetail');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInS,
                                      'sec',
                                      'sagyoTmInS'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInS', 'sec', 'dec', 'sagyoDetail');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={2} className="formula-column formula-column-right-border">
                        <Popover content={helpCmt.workAmt_innerDia}>金額</Popover>
                      </Col>
                      <Col span={2} className="formula-column">
                        <Popover content={helpCmt.workTm_innerDia}>時間</Popover>
                      </Col>
                    </Row>
                    {/* 内周切断速度/切断長 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', marginLeft: '40px' }}>
                          <span className="ddl_2_noImg" style={{ marginLeft: '10px' }}>
                            内周切断速度/切断長(S)
                          </span>
                        </div>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="speedIn">{processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInS}</label>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'speedInLen'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen
                            ? toDecimal(processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen, 2)
                            : toDecimal(0, 2)}
                        </label>
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInS}
                          id={'speedInIn'}
                          min={0}
                          className={editMode ? 'input-editable' : 'input-non-editable '}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInS ===
                              editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInS
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'speedInS', '', '', 'sagyoDetail')
                          }
                          // onBlur={(e) => {
                          //   toTwoDecimal(0, 'speedIn');
                          // }}
                        />
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen}
                          id={'speedInLenIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen ===
                              editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInSLen
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'speedInSLen', '', '', 'sagyoDetail')
                          }
                          onBlur={(e) => {
                            toTwoDecimal(0, 'speedInSLen');
                          }}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="formula-column"></Col>
                    </Row>

                    {/** Mサイズ内周 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', marginLeft: '40px' }}>
                          <span style={{ marginLeft: '5px' }}>内周切断金額/時間(M)</span>
                        </div>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        {/* ¥ */}
                        <label id={'sagyoAmtIn'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM
                            ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM))
                            : amtEmptyStr}
                        </label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'sagyoTmIn'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM
                            ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM)
                            : timeHmsStr}
                        </label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={'sagyoAmtInIn'}
                          value={
                            editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM
                              ? Math.round(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM)
                              : 0
                          }
                          defaultValue={0}
                          onValueChange={(e) => {
                            if (editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM != e) {
                              settingNewDandoriAmtInArr(e, 0, 'sagyoAmtInM', '', '', 'sagyoDetail');
                              checkInputWithColor(false, '', 'sagyoAmtInM', e, 'amt');
                            }
                          }}
                          style={{
                            border: 'none',
                            color:
                              Math.round(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM) ===
                              Math.round(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInM)
                                ? 'black'
                                : checkShowHideIcon('sagyoAmtInCu') === 'none'
                                ? '#4cc12f'
                                : 'red',
                          }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />

                        <div id={'sagyoAmtInMCu'} style={{ display: 'none' }} className="currencyReset">
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={
                                editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM
                                  ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM)
                                  : timeHmsStr
                              }
                              id={'sagyoTmInIn'}
                              style={{
                                width: '100%',
                                color:
                                  editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM ===
                                  processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM
                                    ? 'black'
                                    : Number(oldEditedData[0]?.sagyoDetails?.sagyoInDetails?.speedInM) !==
                                        Number(editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInM) ||
                                      Number(oldEditedData[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen) !==
                                        Number(editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen)
                                    ? '#4cc12f'
                                    : 'red',
                              }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(
                                  e.target.value,
                                  0,
                                  'sagyoTmInM',
                                  'input',
                                  'input',
                                  'sagyoDetail'
                                );
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM,
                                      '10min',
                                      'sagyoTmInM'
                                    );
                                    settingNewDandoriAmtInArr(
                                      editedTime,
                                      0,
                                      'sagyoTmInM',
                                      '10min',
                                      'inc',
                                      'sagyoDetail'
                                    );
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM,
                                      '10min',
                                      'sagyoTmInM'
                                    );
                                    settingNewDandoriAmtInArr(
                                      editedTime,
                                      0,
                                      'sagyoTmInM',
                                      '10min',
                                      'dec',
                                      'sagyoDetail'
                                    );
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM,
                                      'min',
                                      'sagyoTmInM'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInM', 'min', 'inc', 'sagyoDetail');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM,
                                      'min',
                                      'sagyoTmInM'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInM', 'min', 'dec', 'sagyoDetail');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM,
                                      'sec',
                                      'sagyoTmInM'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInM', 'sec', 'inc', 'sagyoDetail');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInM,
                                      'sec',
                                      'sagyoTmInM'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInM', 'sec', 'dec', 'sagyoDetail');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={2} className="formula-column formula-column-right-border">
                        <Popover content={helpCmt.workAmt_innerDia}>金額</Popover>
                      </Col>
                      <Col span={2} className="formula-column">
                        <Popover content={helpCmt.workTm_innerDia}>時間</Popover>
                      </Col>
                    </Row>
                    {/* 内周切断速度/切断長 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', marginLeft: '40px' }}>
                          <span className="ddl_2_noImg" style={{ marginLeft: '10px' }}>
                            内周切断速度/切断長(M)
                          </span>
                        </div>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="speedIn">{processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInM}</label>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'speedInLen'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen
                            ? toDecimal(processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen, 2)
                            : toDecimal(0, 2)}
                        </label>
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInM}
                          id={'speedInIn'}
                          min={0}
                          className={editMode ? 'input-editable' : 'input-non-editable '}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInM ===
                              editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInM
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'speedInM', '', '', 'sagyoDetail')
                          }
                          // onBlur={(e) => {
                          //   toTwoDecimal(0, 'speedIn');
                          // }}
                        />
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen}
                          id={'speedInLenIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen ===
                              editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInMLen
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'speedInMLen', '', '', 'sagyoDetail')
                          }
                          onBlur={(e) => {
                            toTwoDecimal(0, 'speedInMLen');
                          }}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="formula-column"></Col>
                    </Row>
                    {/** Lサイズ内周 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', marginLeft: '40px' }}>
                          <span style={{ marginLeft: '5px' }}>内周切断金額/時間(L)</span>
                        </div>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        {/* ¥ */}
                        <label id={'sagyoAmtIn'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL
                            ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL))
                            : amtEmptyStr}
                        </label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'sagyoTmIn'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL
                            ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL)
                            : timeHmsStr}
                        </label>
                      </Col>
                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={'sagyoAmtInIn'}
                          value={
                            editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL
                              ? Math.round(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL)
                              : 0
                          }
                          defaultValue={0}
                          onValueChange={(e) => {
                            if (editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL != e) {
                              settingNewDandoriAmtInArr(e, 0, 'sagyoAmtInL', '', '', 'sagyoDetail');
                              checkInputWithColor(false, '', 'sagyoAmtInL', e, 'amt');
                            }
                          }}
                          style={{
                            border: 'none',
                            color:
                              Math.round(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL) ===
                              Math.round(processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoAmtInL)
                                ? 'black'
                                : checkShowHideIcon('sagyoAmtInCu') === 'none'
                                ? '#4cc12f'
                                : 'red',
                          }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />

                        <div id={'sagyoAmtInLCu'} style={{ display: 'none' }} className="currencyReset">
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={
                                editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL
                                  ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL)
                                  : timeHmsStr
                              }
                              id={'sagyoTmInIn'}
                              style={{
                                width: '100%',
                                color:
                                  editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL ===
                                  processArr[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL
                                    ? 'black'
                                    : Number(oldEditedData[0]?.sagyoDetails?.sagyoInDetails?.speedInL) !==
                                        Number(editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInL) ||
                                      Number(oldEditedData[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen) !==
                                        Number(editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen)
                                    ? '#4cc12f'
                                    : 'red',
                              }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(
                                  e.target.value,
                                  0,
                                  'sagyoTmInL',
                                  'input',
                                  'input',
                                  'sagyoDetail'
                                );
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL,
                                      '10min',
                                      'sagyoTmInL'
                                    );
                                    settingNewDandoriAmtInArr(
                                      editedTime,
                                      0,
                                      'sagyoTmInL',
                                      '10min',
                                      'inc',
                                      'sagyoDetail'
                                    );
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL,
                                      '10min',
                                      'sagyoTmInL'
                                    );
                                    settingNewDandoriAmtInArr(
                                      editedTime,
                                      0,
                                      'sagyoTmInL',
                                      '10min',
                                      'dec',
                                      'sagyoDetail'
                                    );
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL,
                                      'min',
                                      'sagyoTmInL'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInL', 'min', 'inc', 'sagyoDetail');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL,
                                      'min',
                                      'sagyoTmInL'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInL', 'min', 'dec', 'sagyoDetail');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL,
                                      'sec',
                                      'sagyoTmInL'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInL', 'sec', 'inc', 'sagyoDetail');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[0]?.sagyoDetails?.sagyoInDetails?.sagyoTmInL,
                                      'sec',
                                      'sagyoTmInL'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInL', 'sec', 'dec', 'sagyoDetail');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={2} className="formula-column formula-column-right-border">
                        <Popover content={helpCmt.workAmt_innerDia}>金額</Popover>
                      </Col>
                      <Col span={2} className="formula-column">
                        <Popover content={helpCmt.workTm_innerDia}>時間</Popover>
                      </Col>
                    </Row>
                    {/* 内周切断速度/切断長 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', marginLeft: '40px' }}>
                          <span className="ddl_2_noImg" style={{ marginLeft: '10px' }}>
                            内周切断速度/切断長(L)
                          </span>
                        </div>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="speedIn">{processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInL}</label>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={'speedInLen'}>
                          {processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen
                            ? toDecimal(processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen, 2)
                            : toDecimal(0, 2)}
                        </label>
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInL}
                          id={'speedInIn'}
                          min={0}
                          className={editMode ? 'input-editable' : 'input-non-editable '}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInL ===
                              editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInL
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'speedInL', '', '', 'sagyoDetail')
                          }
                          // onBlur={(e) => {
                          //   toTwoDecimal(0, 'speedIn');
                          // }}
                        />
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen}
                          id={'speedInLenIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen ===
                              editedData[0]?.sagyoDetails?.sagyoInDetails?.speedInLLen
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'speedInLLen', '', '', 'sagyoDetail')
                          }
                          onBlur={(e) => {
                            toTwoDecimal(0, 'speedInLLen');
                          }}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="formula-column"></Col>
                    </Row>
                  </div>
                </div>
              )}
              {/** IQX_WEBEST-359 材料表面、レーザー2度切りの対応 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ38'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(8)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ38'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(8)}
                    />
                    <span style={{ marginLeft: '5px' }}>2度切り切断金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtTwiceCut'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtTwiceCut
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtTwiceCut))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmTwiceCut'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmTwiceCut
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmTwiceCut)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtTwiceCutIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtTwiceCut
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtTwiceCut)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtTwiceCut != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtTwiceCut', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtTwiceCut', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtTwiceCut) ===
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtTwiceCut)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtTwiceCutCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtTwiceCutCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmTwiceCut
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmTwiceCut)
                            : timeHmsStr
                        }
                        id={'sagyoTmTwiceCutIn'}
                        style={{
                          width: '100%',
                          color:
                            Math.round(editedData[0]?.sagyoDetails?.sagyoTmTwiceCut) ===
                            Math.round(processArr[0]?.sagyoDetails?.sagyoTmTwiceCut)
                              ? 'black'
                              : '#4cc12f',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmTwiceCut', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwiceCut,
                                '10min',
                                'sagyoTmTwiceCut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwiceCut', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwiceCut,
                                '10min',
                                'sagyoTmTwiceCut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwiceCut', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwiceCut,
                                'min',
                                'sagyoTmTwiceCut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwiceCut', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwiceCut,
                                'min',
                                'sagyoTmTwiceCut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwiceCut', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwiceCut,
                                'sec',
                                'sagyoTmTwiceCut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwiceCut', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwiceCut,
                                'sec',
                                'sagyoTmTwiceCut'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwiceCut', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_twiceCut}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_twiceCut}>時間</Popover>
                </Col>
              </Row>
              <div id={'laserProcessIQ38'} style={{ display: 'none' }}>
                {/** 外周2度切り加工金額・時間 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex', marginLeft: '40px' }}>
                      <CaretUpOutlined
                        id={'arrowUpLaserIQ36'}
                        style={{ fontSize: 17, marginTop: 2 }}
                        onClick={(e) => subpartDetail(6)}
                      />
                      <CaretDownOutlined
                        id={'arrowDownLaserIQ36'}
                        style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        onClick={(e) => subpartDetail(6)}
                      />
                      <span style={{ marginLeft: '5px' }}>外周切断金額/時間</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    {/* ¥ */}
                    <label id={'sagyoAmtOutTwiceCut'}>
                      {processArr[0]?.sagyoDetails?.sagyoAmtOutTwiceCut
                        ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtOutTwiceCut))
                        : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'sagyoTmOutTwiceCut'}>
                      {processArr[0]?.sagyoDetails?.sagyoTmOutTwiceCut
                        ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmOutTwiceCut)
                        : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={4} className="input-col">
                    <CurrencyInput
                      id={'sagyoAmtOutTwiceCutIn'}
                      value={
                        editedData[0]?.sagyoDetails?.sagyoAmtOutTwiceCut
                          ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtOutTwiceCut)
                          : 0
                      }
                      defaultValue={0}
                      onValueChange={(e) => {
                        if (editedData[0]?.sagyoDetails?.sagyoAmtOutTwiceCut != e) {
                          settingNewDandoriAmtInArr(e, 0, 'sagyoAmtOutTwiceCut', '', '', 'sagyo');
                          checkInputWithColor(false, '', 'sagyoAmtOutTwiceCut', e, 'amt');
                        }
                      }}
                      style={{
                        border: 'none',
                        color:
                          Math.round(editedData[0]?.sagyoDetails?.sagyoAmtOutTwiceCut) ===
                          Math.round(processArr[0]?.sagyoDetails?.sagyoAmtOutTwiceCut)
                            ? 'black'
                            : checkShowHideIcon('sagyoAmtOutTwiceCutCu') === 'none'
                            ? '#4cc12f'
                            : 'red',
                      }}
                      className={
                        editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                      }
                      prefix="¥"
                      decimalsLimit={100}
                    />

                    <div id={'sagyoAmtOutTwiceCutCu'} style={{ display: 'none' }} className="currencyReset">
                      <div className="currencyIcon">
                        <BsUsbC style={{ fontSize: '25' }} />
                        <BsX style={{ margin: '-8px 0 0 16px' }} />
                        <span class="tooltiptext">
                          金額に変更がありましたので、以降時間と連携して自動計算されない。
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut
                              ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut)
                              : timeHmsStr
                          }
                          id={'sagyoTmOutTwiceCutIn'}
                          style={{
                            width: '100%',
                            color:
                              Math.round(editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut) ===
                              Math.round(processArr[0]?.sagyoDetails?.sagyoTmOutTwiceCut)
                                ? 'black'
                                : Number(oldEditedData[0]?.sagyoDetails?.speedOutTwiceCut) !==
                                    Number(editedData[0]?.sagyoDetails?.speedOutTwiceCut) ||
                                  Number(oldEditedData[0]?.sagyoDetails?.speedOutTwiceCutLen) !==
                                    Number(editedData[0]?.sagyoDetails?.speedOutTwiceCutLen)
                                ? '#4cc12f'
                                : 'red',
                          }}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          onChange={(e) => {
                            settingNewDandoriAmtInArr(
                              e.target.value,
                              0,
                              'sagyoTmOutTwiceCut',
                              'input',
                              'input',
                              'sagyo'
                            );
                          }}
                        ></Input>
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut,
                                  '10min',
                                  'sagyoTmOutTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOutTwiceCut', '10min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut,
                                  '10min',
                                  'sagyoTmOutTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOutTwiceCut', '10min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut,
                                  'min',
                                  'sagyoTmOutTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOutTwiceCut', 'min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut,
                                  'min',
                                  'sagyoTmOutTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOutTwiceCut', 'min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut,
                                  'sec',
                                  'sagyoTmOutTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOutTwiceCut', 'sec', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmOutTwiceCut,
                                  'sec',
                                  'sagyoTmOutTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmOutTwiceCut', 'sec', 'dec', 'sagyo');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={2} className="formula-column formula-column-right-border">
                    <Popover content={helpCmt.workAmt_twiceCutOut}>金額</Popover>
                  </Col>
                  <Col span={2} className="formula-column">
                    <Popover content={helpCmt.workTm_twiceCutOut}>時間</Popover>
                  </Col>
                </Row>
                <div id={'laserProcessIQ36'} style={{ display: 'none' }}>
                  {/* 2度切り加工速度/切断長 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <span className="ddl_2_noImg" style={{ marginLeft: '60px' }}>
                        2度切り加工速度/切断長
                      </span>
                    </Col>

                    <Col span={3} className="oyabuhin-detail-col">
                      <label id="speedOut">{processArr[0]?.sagyoDetails?.speedOutTwiceCut}</label>
                    </Col>

                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={'speedOutTwiceCutLen'}>
                        {processArr[0]?.sagyoDetails?.speedOutTwiceCutLen
                          ? toDecimal(processArr[0]?.sagyoDetails?.speedOutTwiceCutLen, 2)
                          : toDecimal(0, 2)}
                      </label>
                    </Col>

                    <Col span={4} className="input-col">
                      <Input
                        value={editedData[0]?.sagyoDetails?.speedOutTwiceCut}
                        id={'speedOutTwiceCut'}
                        min={0}
                        className={editMode ? 'input-editable' : 'input-non-editable '}
                        style={{
                          width: '45%',
                          border: 'none',
                          color:
                            processArr[0]?.sagyoDetails?.speedOutTwiceCut ===
                            editedData[0]?.sagyoDetails?.speedOutTwiceCut
                              ? 'black'
                              : 'red',
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onChange={(e) =>
                          settingNewDandoriAmtInArr(e.target.value, 0, 'speedOutTwiceCut', '', '', 'sagyo')
                        }
                        // onBlur={(e) => {
                        //   toTwoDecimal(0, 'speedOut');
                        // }}
                      />
                    </Col>

                    <Col span={4} className="input-col">
                      <Input
                        value={editedData[0]?.sagyoDetails?.speedOutTwiceCutLen}
                        id={'speedOutTwiceCutLen'}
                        min={0}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        controls={false}
                        style={{
                          width: '45%',
                          border: 'none',
                          color:
                            processArr[0]?.sagyoDetails?.speedOutTwiceCutLen ===
                            editedData[0]?.sagyoDetails?.speedOutTwiceCutLen
                              ? 'black'
                              : 'red',
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e);
                        }}
                        onChange={(e) =>
                          settingNewDandoriAmtInArr(e.target.value, 0, 'speedOutTwiceCutLen', '', '', 'sagyo')
                        }
                        onBlur={(e) => {
                          toTwoDecimal(0, 'speedOutTwiceCutLen');
                        }}
                      />
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={4} className="formula-column"></Col>
                  </Row>
                </div>
                {/** 内周2度切り加工金額・時間 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex', marginLeft: '40px' }}>
                      <CaretUpOutlined
                        id={'arrowUpLaserIQ37'}
                        style={{ fontSize: 17, marginTop: 2 }}
                        onClick={(e) => subpartDetail(7)}
                      />
                      <CaretDownOutlined
                        id={'arrowDownLaserIQ37'}
                        style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        onClick={(e) => subpartDetail(7)}
                      />
                      <span style={{ marginLeft: '5px' }}>内周切断金額/時間</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    {/* ¥ */}
                    <label id={'sagyoAmtInTwiceCut'}>
                      {processArr[0]?.sagyoDetails?.sagyoAmtInTwiceCut
                        ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtInTwiceCut))
                        : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'sagyoTmInTwiceCut'}>
                      {processArr[0]?.sagyoDetails?.sagyoTmInTwiceCut
                        ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmInTwiceCut)
                        : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={4} className="input-col">
                    <CurrencyInput
                      id={'sagyoAmtInTwiceCutIn'}
                      value={
                        editedData[0]?.sagyoDetails?.sagyoAmtInTwiceCut
                          ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtInTwiceCut)
                          : 0
                      }
                      defaultValue={0}
                      onValueChange={(e) => {
                        if (editedData[0]?.sagyoDetails?.sagyoAmtInTwiceCut != e) {
                          settingNewDandoriAmtInArr(e, 0, 'sagyoAmtInTwiceCut', '', '', 'sagyo');
                          checkInputWithColor(false, '', 'sagyoAmtInTwiceCut', e, 'amt');
                        }
                      }}
                      style={{
                        border: 'none',
                        color:
                          Math.round(editedData[0]?.sagyoDetails?.sagyoAmtInTwiceCut) ===
                          Math.round(processArr[0]?.sagyoDetails?.sagyoAmtInTwiceCut)
                            ? 'black'
                            : checkShowHideIcon('sagyoAmtInTwiceCutCu') === 'none'
                            ? '#4cc12f'
                            : 'red',
                      }}
                      className={
                        editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                      }
                      prefix="¥"
                      decimalsLimit={100}
                    />

                    <div id={'sagyoAmtInTwiceCutCu'} style={{ display: 'none' }} className="currencyReset">
                      <div className="currencyIcon">
                        <BsUsbC style={{ fontSize: '25' }} />
                        <BsX style={{ margin: '-8px 0 0 16px' }} />
                        <span class="tooltiptext">
                          金額に変更がありましたので、以降時間と連携して自動計算されない。
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut
                              ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut)
                              : timeHmsStr
                          }
                          id={'sagyoTmInTwiceCut'}
                          style={{
                            width: '100%',
                            color:
                              Math.round(editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut) ===
                              Math.round(processArr[0]?.sagyoDetails?.sagyoTmInTwiceCut)
                                ? 'black'
                                : Number(oldEditedData[0]?.sagyoDetails?.speedIntTwiceCut) !==
                                    Number(editedData[0]?.sagyoDetails?.speedInTwiceCut) ||
                                  Number(oldEditedData[0]?.sagyoDetails?.speedInTwiceCutLen) !==
                                    Number(editedData[0]?.sagyoDetails?.speedInTwiceCutLen)
                                ? '#4cc12f'
                                : 'red',
                          }}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          onChange={(e) => {
                            settingNewDandoriAmtInArr(
                              e.target.value,
                              0,
                              'sagyoTmInTwiceCut',
                              'input',
                              'input',
                              'sagyo'
                            );
                          }}
                        ></Input>
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut,
                                  '10min',
                                  'sagyoTmInTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInTwiceCut', '10min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut,
                                  '10min',
                                  'sagyoTmInTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInTwiceCut', '10min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut,
                                  'min',
                                  'sagyoTmInTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInTwiceCut', 'min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut,
                                  'min',
                                  'sagyoTmInTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInTwiceCut', 'min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut,
                                  'sec',
                                  'sagyoTmInTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInTwiceCut', 'sec', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.sagyoTmInTwiceCut,
                                  'sec',
                                  'sagyoTmInTwiceCut'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmInTwiceCut', 'sec', 'dec', 'sagyo');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={2} className="formula-column formula-column-right-border">
                    <Popover content={helpCmt.workAmt_twiceCutIn}>金額</Popover>
                  </Col>
                  <Col span={2} className="formula-column">
                    <Popover content={helpCmt.workTm_twiceCutIn}>時間</Popover>
                  </Col>
                </Row>
                <div id={'laserProcessIQ37'} style={{ display: 'none' }}>
                  {/* 2度切り加工速度/切断長 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <span className="ddl_2_noImg" style={{ marginLeft: '60px' }}>
                        2度切り加工速度/切断長
                      </span>
                    </Col>

                    <Col span={3} className="oyabuhin-detail-col">
                      <label id="speedIn">{processArr[0]?.sagyoDetails?.speedInTwiceCut}</label>
                    </Col>

                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={'speedInTwiceCutLen'}>
                        {processArr[0]?.sagyoDetails?.speedInTwiceCutLen
                          ? toDecimal(processArr[0]?.sagyoDetails?.speedInTwiceCutLen, 2)
                          : toDecimal(0, 2)}
                      </label>
                    </Col>

                    <Col span={4} className="input-col">
                      <Input
                        value={editedData[0]?.sagyoDetails?.speedInTwiceCut}
                        id={'speedInTwiceCut'}
                        min={0}
                        className={editMode ? 'input-editable' : 'input-non-editable '}
                        style={{
                          width: '45%',
                          border: 'none',
                          color:
                            processArr[0]?.sagyoDetails?.speedInTwiceCut ===
                            editedData[0]?.sagyoDetails?.speedInTwiceCut
                              ? 'black'
                              : 'red',
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onChange={(e) =>
                          settingNewDandoriAmtInArr(e.target.value, 0, 'speedInTwiceCut', '', '', 'sagyo')
                        }
                        // onBlur={(e) => {
                        //   toTwoDecimal(0, 'speedOut');
                        // }}
                      />
                    </Col>

                    <Col span={4} className="input-col">
                      <Input
                        value={editedData[0]?.sagyoDetails?.speedInTwiceCutLen}
                        id={'speedInTwiceCutLen'}
                        min={0}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        controls={false}
                        style={{
                          width: '45%',
                          border: 'none',
                          color:
                            processArr[0]?.sagyoDetails?.speedInTwiceCutLen ===
                            editedData[0]?.sagyoDetails?.speedInTwiceCutLen
                              ? 'black'
                              : 'red',
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e);
                        }}
                        onChange={(e) =>
                          settingNewDandoriAmtInArr(e.target.value, 0, 'speedInTwiceCutLen', '', '', 'sagyo')
                        }
                        onBlur={(e) => {
                          toTwoDecimal(0, 'speedInTwiceCutLen');
                        }}
                      />
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={4} className="formula-column"></Col>
                  </Row>
                </div>
              </div>
              {/** 空送 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ33'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(3)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ33'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(3)}
                    />
                    <span style={{ marginLeft: '5px' }}>空送金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtAirDeli'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtAirDeli
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtAirDeli))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmAirDeli'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmAirDeli
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmAirDeli)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtAirDeliIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtAirDeli
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtAirDeli)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoAmt != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtAirDeli', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtAirDeli', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtAirDeli) ===
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtAirDeli)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtAirDeliCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtAirDeliCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmAirDeli
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmAirDeli)
                            : timeHmsStr
                        }
                        id={'sagyoTmAirDeliIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli === processArr[0]?.sagyoDetails?.sagyoTmAirDeli
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.speedAirDeli !==
                                  editedData[0]?.sagyoDetails?.speedAirDeli ||
                                oldEditedData[0]?.sagyoDetails?.speedAirDeliLen !==
                                  editedData[0]?.sagyoDetails?.speedAirDeliLen
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmAirDeli', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                '10min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                '10min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'sec',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'sec',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_airDistance}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_airDistance}>時間</Popover>
                </Col>
              </Row>
              <div id={'laserProcessIQ33'} style={{ display: 'none' }}>
                {/* 空送速度/空送距離 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      空送速度/空送距離
                    </span>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="speedAirDeli">{processArr[0]?.sagyoDetails?.speedAirDeli}</label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'speedAirDeliLen'}>{processArr[0]?.sagyoDetails?.speedAirDeliLen}</label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedAirDeli}
                      id={'speedAirDeliIn'}
                      min={0}
                      className={editMode ? 'input-editable' : 'input-non-editable '}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedAirDeli === editedData[0]?.sagyoDetails?.speedAirDeli
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedAirDeli', '', '', 'sagyo')}
                      onBlur={(e) => {
                        toTwoDecimal(0, 'speedAirDeli');
                      }}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedAirDeliLen}
                      id={'speedAirDeliLenIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          Number(processArr[0]?.sagyoDetails?.speedAirDeliLen) ===
                          Number(editedData[0]?.sagyoDetails?.speedAirDeliLen)
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedAirDeliLen', '', '', 'sagyo')}
                      onBlur={(e) => {
                        toTwoDecimal(0, 'speedAirDeliLen');
                      }}
                    />
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
              {/** ピアス加工金額/時間 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ34'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(4)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ34'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(4)}
                    />
                    <span style={{ marginLeft: '5px' }}>ピアス加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtPiercing'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtPiercing
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtPiercing))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmPiercing'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmPiercing
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmPiercing)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtPiercingIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtPiercing
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtPiercing)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtPiercing != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtPiercing', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtPiercing', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtPiercing) ===
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtPiercing)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtPiercingCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtPiercingCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmPiercing
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmPiercing)
                            : timeHmsStr
                        }
                        id={'sagyoTmPiercingIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoTmPiercing ===
                            processArr[0]?.sagyoDetails?.sagyoTmPiercing
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.speedPiercingLen !==
                                  editedData[0]?.sagyoDetails?.speedPiercingLen ||
                                oldEditedData[0]?.sagyoDetails?.speedPiercing !==
                                  editedData[0]?.sagyoDetails?.speedPiercing
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmPiercing', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPiercing,
                                '10min',
                                'sagyoTmPiercing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPiercing', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPiercing,
                                '10min',
                                'sagyoTmPiercing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPiercing', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPiercing,
                                'min',
                                'sagyoTmPiercing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPiercing', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPiercing,
                                'min',
                                'sagyoTmPiercing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPiercing', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPiercing,
                                'sec',
                                'sagyoTmPiercing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPiercing', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPiercing,
                                'sec',
                                'sagyoTmPiercing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPiercing', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_piercing}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_piercing}>時間</Popover>
                </Col>
              </Row>
              <div id={'laserProcessIQ34'} style={{ display: 'none' }}>
                {/* ピアス回数/時間 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      ピアス回数/時間
                    </span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'speedPiercingLen'}>{processArr[0]?.sagyoDetails?.speedPiercingLen}</label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="speedPiercing">
                      {processArr[0]?.sagyoDetails?.speedPiercing
                        ? secondsToHms(processArr[0]?.sagyoDetails?.speedPiercing)
                        : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedPiercingLen}
                      id={'speedPiercingLenIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedPiercingLen ===
                          editedData[0]?.sagyoDetails?.speedPiercingLen
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'piercingcount', '', '', 'sagyo')}
                    />
                  </Col>
                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.speedPiercing
                              ? secondsToHms(editedData[0]?.sagyoDetails?.speedPiercing)
                              : timeHmsStr
                          }
                          id={'speedPiercingIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.speedPiercing === editedData[0]?.sagyoDetails?.speedPiercing
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, '', 'piercingTime', 'input', 'input', 'sagyo')
                          }
                        />
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.speedPiercing,
                                  '10min',
                                  'speedPiercing'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'piercingTime', '10min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.speedPiercing,
                                  '10min',
                                  'speedPiercing'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'piercingTime', '10min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.speedPiercing,
                                  'min',
                                  'speedPiercing'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'piercingTime', 'min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.speedPiercing,
                                  'min',
                                  'speedPiercing'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'piercingTime', 'min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.speedPiercing,
                                  'sec',
                                  'speedPiercing'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'piercingTime', 'sec', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.speedPiercing,
                                  'sec',
                                  'speedPiercing'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'piercingTime', 'sec', 'dec', 'sagyo');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
              {/** ケガキ加工金額/時間 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ35'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(5)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ35'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(5)}
                    />
                    <span style={{ marginLeft: '5px' }}>ケガキ加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtScribing'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtScribing
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtScribing))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmScribing'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmScribing
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmScribing)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtScribingIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtScribing
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtScribing)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtScribing != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtScribing', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtScribing', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtScribing) ===
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtScribing)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtScribingCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtScribingCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row
                    style={{
                      width: '100%',
                    }}
                  >
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmScribing
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmScribing)
                            : timeHmsStr
                        }
                        id={'sagyoTmScribingIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoAmtScribing ===
                            processArr[0]?.sagyoDetails?.sagyoAmtScribing
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.speedScribing !==
                                  editedData[0]?.sagyoDetails?.speedScribing ||
                                oldEditedData[0]?.sagyoDetails?.speedScribingLen !==
                                  editedData[0]?.sagyoDetails?.speedScribingLen
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmScribing', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                '10min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                '10min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'sec',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'sec',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_scribing}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_scribing}>時間</Popover>
                </Col>
              </Row>
              <div id={'laserProcessIQ35'} style={{ display: 'none' }}>
                {/* ケガキ加工速度/切断長 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      ケガキ加工速度/切断長
                    </span>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="speedScribing">{processArr[0]?.sagyoDetails?.speedScribing}</label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'speedScribingLen'}>{processArr[0]?.sagyoDetails?.speedScribingLen}</label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedScribing}
                      id={'speedScribingIn'}
                      min={0}
                      className={editMode ? 'input-editable' : 'input-non-editable '}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedScribing === editedData[0]?.sagyoDetails?.speedScribing
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedScribing', '', '', 'sagyo')}
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedScribing');
                      // }}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedScribingLen}
                      id={'speedScribingLenIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedScribingLen ===
                          editedData[0]?.sagyoDetails?.speedScribingLen
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) =>
                        settingNewDandoriAmtInArr(e.target.value, 0, 'speedScribingLen', '', '', 'sagyo')
                      }
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedScribingLen');
                      // }}
                    />
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
            </div>

            {/** 2工程穴加工金額/時間 */}
            <div>
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <div style={{ display: 'flex' }}>
                    <CaretUpOutlined
                      id={'arrowUpBlankDrilling'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                    />

                    <CaretDownOutlined id={'arrowDownBlankDrilling'} style={{ fontSize: 17, marginTop: 2 }} />
                    <span>2工程穴加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <label id={'sagyoAmtTwoStepHole'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <label id={'sagyoTmTwoStepHole'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id="sagyoAmtTwoStepHoleIn"
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtTwoStepHole', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtTwoStepHole', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole) ===
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtTwoStepHoleCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />
                  <div id={'sagyoAmtTwoStepHoleCu'} style={{ display: 'none' }}>
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole)
                            : timeHmsStr
                        }
                        id={'sagyoTmTwoStepHoleIn'}
                        style={{
                          width: '100%',
                          color:
                            processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole ===
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole
                              ? 'black'
                              : oldEditedData[0]?.twoStepHoleCnt !== editedData[0]?.sagyoDetails?.twoStepHoleCnt ||
                                oldEditedData[0]?.twoStepHoleTm !== editedData[0]?.sagyoDetails?.twoStepHoleTm
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          //disableTimer('test');
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmTwoStepHole', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                '10min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                '10min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'sec',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'sec',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}></Col>
                <Col
                  span={2}
                  className="formula-column formula-column-right-border"
                  onClick={(e) => handleCollpse('BlankDrilling')}
                >
                  <Popover content={helpCmt.workAmt_twoStepHole}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <Popover content={helpCmt.workTm_twoStepHole}>時間</Popover>
                </Col>
              </Row>

              <div id={'divBlankDrilling'} style={{ display: 'none' }}>
                <Row className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="drillingCount">{processArr[0]?.sagyoDetails?.twoStepHoleCnt}</label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="drillingCountTime">
                      {processArr[0]?.sagyoDetails?.twoStepHoleTm
                        ? secondsToHms(processArr[0]?.sagyoDetails?.twoStepHoleTm)
                        : timeHmsStr}
                    </label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.twoStepHoleCnt}
                      id="drillingCountIn"
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          editedData[0]?.sagyoDetails?.twoStepHoleCnt === processArr[0]?.sagyoDetails?.twoStepHoleCnt
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) =>
                        settingNewDandoriAmtInArr(e.target.value, 0, 'twoStepHoleCnt', '', '', 'twoStepHoleCnt')
                      }
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.twoStepHoleTm
                              ? secondsToHms(editedData[0]?.sagyoDetails?.twoStepHoleTm)
                              : timeHmsStr
                          }
                          id="drillingCountTimeIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            color:
                              editedData[0]?.sagyoDetails?.twoStepHoleTm === processArr[0]?.sagyoDetails?.twoStepHoleTm
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(
                              e.target.value,
                              0,
                              'twoStepHoleTm',
                              'input',
                              'input',
                              'twoStepHoleTm'
                            )
                          }
                        />
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  '10min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  '10min',
                                  'inc',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  '10min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  '10min',
                                  'dec',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'min',
                                  'inc',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,
                                  'twoStepHoleTm',
                                  'min',
                                  'dec',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'sec',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'sec',
                                  'inc',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'sec',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'sec',
                                  'dec',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
              </div>
            </div>
            {/* ジョイントバラシ加工金額/時間 */}
            <div>
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
                  <div style={{ display: 'flex' }}>
                    <CaretUpOutlined id={'arrowUpBlankJoint'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

                    <CaretDownOutlined id={'arrowDownBlankJoint'} style={{ fontSize: 17, marginTop: 2 }} />
                    <span style={{ fontSize: 11 }}>ジョイントバラシ加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
                  <label id={'sagyoAmtJoint'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtJoint
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtJoint))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
                  <label id={'sagyoTmJoint'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmJoint
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmJoint)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id="sagyoAmtJointIn"
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtJoint
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtJoint)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtJoint != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtJoint', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtJoint', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(editedData[0]?.sagyoDetails?.sagyoAmtJoint) ===
                        Math.round(processArr[0]?.sagyoDetails?.sagyoAmtJoint)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtJointCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />
                  <div id={'sagyoAmtJointCu'} style={{ display: 'none' }}>
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmJoint
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmJoint)
                            : timeHmsStr
                        }
                        id={'sagyoTmJointIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoTmJoint === processArr[0]?.sagyoDetails?.sagyoTmJoint
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.jointCnt !== editedData[0]?.sagyoDetails?.jointCnt ||
                                oldEditedData[0]?.sagyoDetails?.jointTm !== editedData[0]?.sagyoDetails?.jointTm
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          //disableTimer('test');
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmJoint', 'input', 'input', 'sagyo');
                          // settingNewDandoriAmtInArr(e.target.value, j, 'kensaTime', 'input', 'input');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                '10min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                '10min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'sec',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'sec',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}></Col>
                <Col
                  span={2}
                  className="formula-column formula-column-right-border"
                  onClick={(e) => handleCollpse('BlankJoint')}
                >
                  <Popover content={helpCmt.workAmt_joint}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column" onClick={(e) => handleCollpse('BlankJoint')}>
                  <Popover content={helpCmt.workTm_joint}>時間</Popover>
                </Col>
              </Row>

              <div id={'divBlankJoint'} style={{ display: 'none' }}>
                <Row className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="jointCnt">{processArr[0]?.sagyoDetails?.jointCnt}</label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="jointCntTime">
                      {processArr[0]?.sagyoDetails?.jointTm
                        ? secondsToHms(processArr[0]?.sagyoDetails?.jointTm)
                        : timeHmsStr}
                    </label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.jointCnt}
                      id="jointCntIn"
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          parseFloat(editedData[0]?.sagyoDetails?.jointCnt) === processArr[0]?.sagyoDetails?.jointCnt
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'jointCnt', '', '', 'jointCnt')}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.jointTm
                              ? secondsToHms(editedData[0]?.sagyoDetails?.jointTm)
                              : timeHmsStr
                          }
                          id="jointTmIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            color:
                              editedData[0]?.sagyoDetails?.jointTm === processArr[0]?.sagyoDetails?.jointTm
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'jointTm', 'input', 'input', 'jointTm')
                          }
                        />
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  '10min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', '10min', 'inc', 'jointTm');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  '10min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', '10min', 'dec', 'jointTm');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'min', 'inc', 'jointTm');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'min', 'dec', 'jointTm');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'sec',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'sec', 'inc', 'jointTm');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'sec',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'sec', 'dec', 'jointTm');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiCalculate_BlankLaser;
