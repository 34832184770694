import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { handleKeyPress, regexExp, toDecimal } from '../common/Common';

const types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];
const IQ3KouteiInput_Blank = forwardRef((props, ref) => {
  const [dimensionData, setDimensionData] = useState({});
  const [quantityList, setQuantityList] = useState([]);

  useEffect(() => {
    if (props?.tabOpen) {
      let data = props.blankInfo;
      if (data != undefined || data?.length > 0) {
        setDimensionData(data?.details?.[0]?.dimensionData);
        setQuantityList(data?.details?.[0]?.quantityList);
      }
    }
  }, [props.blankInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const getInnerDia = (lists) => {
    let totalCount = lists?.reduce((total, item) => {
      const countValue = parseFloat(item?.perimeter * item?.count);
      return total + countValue;
    }, 0);
    props.blankInfo.details[0].dimensionData['innerDia'] = toDecimal(totalCount, 2);
    props.blankInfo.details[0].dimensionData['innerAutoExtract'] = toDecimal(totalCount, 2);
  };
  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(quantityList));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          row[types] = event;
          if (event === 1 || event === 2) {
            row['xsize'] = toDecimal(0, 2);
            row['ysize'] = toDecimal(0, 2);
          } else if (event === 3 || event === 4) {
            row['diameter'] = toDecimal(0, 2);
          } else {
            row['xsize'] = toDecimal(0, 2);
            row['ysize'] = toDecimal(0, 2);
            row['diameter'] = toDecimal(0, 2);
          }
          row['perimeter'] = calculatePerimeter(row);
        }
        return row;
      });
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          if (!regexExp.test(event.target.value)) {
            row[types] = event.target.value;
          } else {
            row[types] = toDecimal(0, 2);
          }
          // IQX_WEBEST-310 工程入力、ブランクで、種類.形状を「その他」としたときの周長の入力について
          if (types !== 'perimeter') {
            row['perimeter'] = calculatePerimeter(row);
          }
        }
        return row;
      });
    }
    setQuantityList(updatedData);
    getInnerDia(updatedData);
    const newBlankInfo = { ...props.blankInfo };
    newBlankInfo.details[0].quantityList = updatedData;
    props.updateBlank(newBlankInfo);
  };
  const blurDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(quantityList));
    let updatedData = [];
    updatedData = temp?.map((row) => {
      if (row.id === id) {
        row[types] = toDecimal(event.target.value, 2);
        row['perimeter'] = calculatePerimeter(row);
      }
      return row;
    });
    setQuantityList(updatedData);
    getInnerDia(updatedData);
    const newBlankInfo = { ...props.blankInfo };
    newBlankInfo.details[0].quantityList = updatedData;
    props.updateBlank(newBlankInfo);
  };
  const calculatePerimeter = (plankObj) => {
    let perimeter = 0;
    switch (plankObj.types) {
      case 1:
        if (plankObj.diameter !== undefined && plankObj.diameter !== null)
          perimeter = Number(plankObj.diameter) * Math.PI;
        break;
      case 2:
        if (plankObj.diameter !== undefined && plankObj.diameter !== null) perimeter = 4 * Number(plankObj.diameter);
        break;
      case 3:
        if (
          plankObj.xsize !== undefined &&
          plankObj.xsize !== null &&
          plankObj.ysize !== undefined &&
          plankObj.ysize !== null
        )
          perimeter = 2 * (Number(plankObj.xsize) + Number(plankObj.ysize));
        break;
      case 4:
        if (
          plankObj.xsize !== undefined &&
          plankObj.xsize !== null &&
          plankObj.ysize !== undefined &&
          plankObj.ysize !== null
        )
          // perimeter =
          //   Number(plankObj.xsize) * 2 - Number(plankObj.ysize) + Number(plankObj.ysize) * Number(plankObj.count);
          perimeter =
            Number(plankObj.xsize) -
            Number(plankObj.ysize) +
            (Number(plankObj.xsize) - Number(plankObj.ysize)) +
            Number(plankObj.ysize) * Math.PI;
        break;
      default:
        perimeter = plankObj.perimeter;
        break;
    }
    return toDecimal(perimeter, 2);
  };

  const addRow = (id) => {
    const copyData = quantityList?.slice(); // Create a copy of the original array

    const insertIndex = quantityList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = quantityList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: quantityList[insertIndex].types,
        typesAutoExtract: '',
        diameter: quantityList[insertIndex].diameter
          ? toDecimal(quantityList[insertIndex].diameter, 2)
          : toDecimal(0, 2),
        diameterAutoExtract: '',
        xsize: quantityList[insertIndex].xsize ? toDecimal(quantityList[insertIndex].xsize, 2) : toDecimal(0, 2),
        xsizeAutoExtract: '',
        ysize: quantityList[insertIndex].ysize ? toDecimal(quantityList[insertIndex].ysize, 2) : toDecimal(0, 2),
        ysizeAutoExtract: '',
        perimeter: quantityList[insertIndex].perimeter
          ? toDecimal(quantityList[insertIndex].perimeter, 2)
          : toDecimal(0, 2),
        perimeterAutoExtract: '',
        count: quantityList[insertIndex].count,
        countAutoExtract: '',
        blankFlag: quantityList[insertIndex].blankFlag,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setQuantityList(copyData);
      getInnerDia(copyData);
      const newBlankInfo = { ...props.blankInfo };
      newBlankInfo.details[0].quantityList = copyData;
      props.updateBlank(newBlankInfo);
    }
  };
  const deleteRow = (id) => {
    if (quantityList?.length > 1) {
      const updatedData = quantityList?.filter((item) => item.id !== id);

      setQuantityList(updatedData);
      getInnerDia(updatedData);
      const newBlankInfo = { ...props.blankInfo };
      newBlankInfo.details[0].quantityList = updatedData;
      props.updateBlank(newBlankInfo);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!regexExp.test(value)) {
      const newBlankInfo = { ...props?.blankInfo };

      const newDimensionData = { ...newBlankInfo?.details?.[0]?.dimensionData };

      newDimensionData[name] = value || 0;

      newBlankInfo.details[0].dimensionData = newDimensionData;
      setDimensionData(newDimensionData);
      props.updateBlank(newBlankInfo);
    }
  };
  const handleInputBlur = (e) => {
    const { name, val } = e.target;
    const newBlankInfo = { ...props?.blankInfo };

    const newDimensionData = { ...newBlankInfo?.details?.[0]?.dimensionData };

    if (name === 'outerDia') {
      newDimensionData[name] = toDecimal(newDimensionData?.outerDia, 2);
    } else if (name === 'innerDia') {
      newDimensionData[name] = toDecimal(newDimensionData?.innerDia, 2);
    } else {
      newDimensionData[name] = toDecimal(newDimensionData?.airFeedDistance, 2);
    }
    // newDimensionData[name] = newDimensionData?.outerDia?.toFixed(2);

    newBlankInfo.details[0].dimensionData = newDimensionData;
    setDimensionData(newDimensionData);
    props.updateBlank(newBlankInfo);
  };

  const gettypeName = (id) => {
    for (let i = 0; i < types.length; i++) {
      if (types[i].id === id) {
        return types[i].label;
      }
    }
  };

  return (
    <>
      {props?.tabOpen && props?.isPlank ? (
        <>
          <Row className="mt-1 registerModal">
            <Table className="kouteiinput " style={{ width: '57.2%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    外径周長(mm)
                  </th>
                  {/* <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(外径周長)
              </th> */}
                  <th style={{ width: '19%' }} className="tbl-header">
                    内径周長(mm)
                  </th>
                  {/* <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(内径周長)
              </th> */}
                  <th style={{ width: '19%' }} className="tbl-header">
                    空送距離(mm)
                  </th>
                  {/* <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(空送距離)
              </th> */}
                </tr>
              </thead>
              <tbody>
                {/* {firstplankValues?.map((i) => (
              <> */}
                <tr>
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                      {dimensionData?.outerAutoExtract}
                    </label>
                    <Input
                      className="input-editable"
                      value={dimensionData?.outerDia}
                      name="outerDia"
                      onChange={handleInputChange}
                      onBlur={(e) => handleInputBlur(e)}
                      // onChange={(e) => changeValue(e, 'val1')}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  {/* <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  value={dimensionData.outerAutoExtract}
                  name="outerAutoExtract"
                  // onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val2')}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                      {dimensionData?.innerAutoExtract}
                    </label>
                    <Input
                      value={dimensionData?.innerDia}
                      name="innerDia"
                      onChange={handleInputChange}
                      onBlur={(e) => handleInputBlur(e)}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      // onChange={(e) => changeValue(e, 'val3')}
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                    ></Input>
                  </td>
                  {/* <td>
                <Input
                  value={dimensionData.innerAutoExtract}
                  name="innerAutoExtract"
                  // onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val4')}
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                      {dimensionData?.airFeedAutoExtract}
                    </label>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={dimensionData?.airFeedDistance}
                      name="airFeedDistance"
                      onChange={handleInputChange}
                      onBlur={(e) => handleInputBlur(e)}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      // onChange={(e) => changeValue(e, 'val5')}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                    ></Input>
                  </td>
                  {/* <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  value={dimensionData.airFeedAutoExtract}
                  name="airFeedAutoExtract"
                  // onChange={handleInputChange}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
                </tr>
                {/* </>
            ))} */}
              </tbody>
            </Table>
          </Row>
          <Row className="mt-2 registerModal">
            <Table className="kouteiinput " style={{ width: '99%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '1%' }} className="tbl-header">
                    No.
                  </th>
                  <th style={{ width: '17.4%' }} className="tbl-header">
                    種類.形状
                  </th>
                  <th style={{ width: '19.3%' }} className="tbl-header">
                    径/辺長(mm)
                  </th>
                  <th style={{ width: '19.3%' }} className="tbl-header">
                    Xサイズ(mm)
                  </th>
                  <th style={{ width: '19.3%' }} className="tbl-header">
                    Yサイズ(mm)
                  </th>
                  <th style={{ width: '11%' }} className="tbl-header">
                    周長(mm)
                  </th>
                  <th style={{ width: '7%' }} className="tbl-header">
                    数
                  </th>
                </tr>
              </thead>
              <tbody>
                {quantityList?.map((i, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {/* {editMode ? (
                    <> */}
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 10, width: '35%' }}>{gettypeName(i.typesAutoExtract)}</label>
                      <Select
                        defaultValue={1}
                        size="middle"
                        value={i.types}
                        onChange={(e) => changeDetails(e, i.id, 'types')}
                        style={{ width: '58%' }}
                      >
                        {types?.map((option, index) => (
                          <Select.Option key={index} value={option.id}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                        {i.diameterAutoExtract}
                      </label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.diameter}
                        onChange={(e) => changeDetails(e, i.id, 'diameter')}
                        onBlur={(e) => blurDetails(e, i.id, 'diameter')}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        style={{
                          width: '58%',
                          marginLeft: 9,
                          height: 32,
                          textAlign: 'center',
                          borderColor: i.types == 3 || i.types == 4 || i.types == 5 ? 'transparent' : '#a5a5a5',
                          /*  backgroundColor: i.types == 3 || i.types == 4 || i.types == 5 ? '#d9d9d9' : 'white', */
                          pointerEvents: i.types == 3 || i.types == 4 || i.types == 5 ? 'none' : 'auto',
                        }}
                      ></Input>
                    </td>
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.xsizeAutoExtract}</label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.xsize}
                        onChange={(e) => changeDetails(e, i.id, 'xsize')}
                        onBlur={(e) => blurDetails(e, i.id, 'xsize')}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        style={{
                          width: '58%',
                          marginLeft: 9,
                          height: 32,
                          textAlign: 'center',
                          borderColor: i.types == 1 || i.types == 2 || i.types == 5 ? 'transparent' : '#a5a5a5',

                          /* backgroundColor: i.types == 1 || i.types == 2 ? '#d9d9d9' : 'white', */
                          pointerEvents: i.types == 1 || i.types == 2 || i.types == 5 ? 'none' : 'auto',
                        }}
                      ></Input>
                    </td>
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.ysizeAutoExtract}</label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.ysize}
                        onChange={(e) => changeDetails(e, i.id, 'ysize')}
                        onBlur={(e) => blurDetails(e, i.id, 'ysize')}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        style={{
                          width: '58%',
                          marginLeft: 9,
                          height: 32,
                          textAlign: 'center',
                          borderColor: i.types == 1 || i.types == 2 || i.types == 5 ? 'transparent' : '#a5a5a5',
                          /*  backgroundColor: i.types == 1 || i.types == 2 ? '#d9d9d9' : 'white', */
                          pointerEvents: i.types == 1 || i.types == 2 || i.types == 5 ? 'none' : 'auto',
                        }}
                      ></Input>
                    </td>
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                        {i.perimeterAutoExtract}
                      </label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.perimeter}
                        onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                        onBlur={(e) => blurDetails(e, i.id, 'perimeter')}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        style={{
                          width: '58%',
                          marginLeft: 9,
                          height: 32,
                          textAlign: 'center',
                          borderColor: i.types < 5 ? 'transparent' : '#a5a5a5',
                          /*  backgroundColor: i.types < 5 ? '#d9d9d9' : 'white', */
                          pointerEvents: i.types < 5 ? 'none' : 'auto',
                        }}
                      ></Input>
                    </td>
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '20%', textAlign: 'center' }}>{i.countAutoExtract}</label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.count}
                        onChange={(e) => changeDetails(e, i.id, 'count')}
                        // onBlur={(e) => blurDetails(e, i.id, 'count')}
                        onKeyPress={(e) => {
                          handleKeyPress(e, true);
                        }}
                        style={{ width: '58%', marginLeft: 9, height: 32, textAlign: 'center' }}
                      ></Input>
                    </td>
                    {/* {editMode ? (
                  <> */}
                    <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                      <Row justify="center">
                        <PlusCircleFilled
                          className="add-remove-icon"
                          onClick={() => addRow(i.id)}
                          style={{ marginLeft: 5, marginRight: 5 }}
                        />
                        <CloseCircleFilled
                          className="add-remove-icon"
                          onClick={() => deleteRow(i.id)}
                          // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                      </Row>
                    </td>
                    {/* </>
                ) : (
                  <>
                    {' '}
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )} */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_Blank;
