import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { WorkType } from '../common/enums';
import { FRBend } from '../common/Constant';
import { handleKeyPress, regexExp, toDecimal } from '../common/Common';

const IQ3KouteiInput_Bending = forwardRef((props, ref) => {
  const [bendingData, setBendingData] = useState({});
  const [bendingList, setBendingList] = useState([]);
  const [types, setTypes] = useState([]);
  const [maxBendLengthAutoExtract, setMaxBendLengthAutoExtract] = useState(0);

  useEffect(() => {
    if (props?.tabOpen) {
      const processes = props.processMaster;
      const bendingDetails = processes?.filter((i) => i.workType === WorkType.SmBending);
      if (bendingDetails?.length > 0) {
        let types = bendingDetails?.[0].details?.processDetailTypes?.filter((i) => i.isUsed);
        setTypes(types);
      }

      let data = props.bendingInfo;
      if (data != undefined) {
        setBendingData(toDecimal(data.details?.[0]?.bendingData?.maxBendLength, 2));
        setBendingList(data.details?.[0]?.bendingList);
        setMaxBendLengthAutoExtract(toDecimal(data.details?.[0]?.bendingData?.maxBendAutoExtract, 2));
      }
    }
  }, [props.bendingInfo]);

  const getMaxBendLength = (list) => {
    const maxBendLength = list
      ? list?.reduce((max, item) => {
          return item.bendLength > max ? item.bendLength : max;
        }, -Infinity)
      : -Infinity;
    const newBendingData = { ...props.bendingInfo?.details?.[0]?.bendingData };

    newBendingData['maxBendLength'] = toDecimal(maxBendLength, 2) || 0;
    newBendingData['maxBendAutoExtract'] = toDecimal(maxBendLength, 2) || 0;

    props.bendingInfo.details[0].bendingData = newBendingData;
    setBendingData(toDecimal(maxBendLength, 2) || 0);
    // return maxBendLength;
  };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addBending = (id) => {
    const copyData = bendingList?.slice(); // Create a copy of the original array

    const insertIndex = bendingList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = bendingList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: copyData[insertIndex]?.types,
        typesAutoExtract: '',
        bendLength: copyData[insertIndex]?.bendLength,
        bendLengthAutoExtract: '',
        lines: copyData[insertIndex]?.lines,
        linesAutoExtract: '',
        bendCount: copyData[insertIndex]?.bendCount,
        bendCountAutoExtract: '',
        // num: copyData[insertIndex].num,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setBendingList(copyData);
      getMaxBendLength(copyData);
      const newBendingInfo = { ...props.bendingInfo };
      newBendingInfo.details[0].bendingList = copyData;
      props.updateBending(newBendingInfo);
    }
  };
  const deleteBending = (id) => {
    if (bendingList?.length > 1) {
      const updatedData = bendingList?.filter((item) => item.id !== id);

      setBendingList(updatedData);
      getMaxBendLength(updatedData);
      const newBendingInfo = { ...props.bendingInfo };
      newBendingInfo.details[0].bendingList = updatedData;
      props.updateBending(newBendingInfo);
    }
  };
  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(bendingList));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: !regexExp.test(event.target.value) ? event.target.value : 0 };
        }
        return row;
      });
    }
    setBendingList(updatedData);
    getMaxBendLength(updatedData);
    const newBendingInfo = { ...props.bendingInfo };
    newBendingInfo.details[0].bendingList = updatedData;
    props.updateBending(newBendingInfo);
  };
  const blurDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(bendingList));
    let updatedData = [];

    updatedData = temp?.map((row) => {
      if (row.id === id) {
        return { ...row, [types]: toDecimal(event.target.value, 2) };
      }
      return row;
    });

    setBendingList(updatedData);
    getMaxBendLength(updatedData);
    const newBendingInfo = { ...props.bendingInfo };
    newBendingInfo.details[0].bendingList = updatedData;
    props.updateBending(newBendingInfo);
  };

  const changeValue = (e) => {
    const { name, value } = e.target;
    const newBendingInfo = { ...props.bendingInfo };

    const newBendingData = { ...newBendingInfo.details[0].bendingData };

    newBendingData[name] = parseFloat(value) || 0;

    newBendingInfo.details[0].bendingData = newBendingData;
    setBendingData(newBendingData);
    props.updateBending(newBendingInfo);
  };

  const gettypeName = (id) => {
    for (let i = 0; i < types.length; i++) {
      if (types[i].id === id) {
        return types[i].name;
      }
    }
  };

  return (
    <>
      {props?.tabOpen && props?.isBending ? (
        <>
          <Row className="mt-1 registerModal">
            <Table className="kouteiinput" style={{ width: '19.79%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '19.79%' }} className="tbl-header">
                    最大曲げ線長(mm)
                  </th>
                  {/* <th style={{ width: '22%' }} className="tbl-header">
                自動抽出(最大曲げ線長)
              </th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                      {maxBendLengthAutoExtract}
                    </label>
                    <Input
                      // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                      className="input-editable"
                      name="maxBendLength"
                      // value={bendingData.maxBendLength}
                      value={bendingData}
                      onChange={(e) => {
                        if (!regexExp.test(e.target.value)) {
                          setBendingData(e.target.value);
                          props.bendingInfo.details[0].bendingData = toDecimal(e.target.value, 2);
                        }
                      }}
                      onBlur={(e) => setBendingData(toDecimal(e.target.value, 2))}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      // onChange={changeValue}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                    ></Input>
                  </td>
                  {/* <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  name="maxBendAutoExtract"
                  value={bendingData.maxBendAutoExtract}
                  // onChange={(e) => changeValue(e, 'maxBendAutoExtract')}
                  // onChange={changeValue}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
                </tr>
                {/* </>
            ))} */}
              </tbody>
            </Table>
          </Row>
          <Row className="mt-2 registerModal">
            <Table className="kouteiinput" style={{ width: '85%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '0.1%' }} className="tbl-header">
                    No
                  </th>
                  <th style={{ width: '11.3%' }} className="tbl-header">
                    種類.形状
                  </th>
                  <th style={{ width: '12.31%' }} className="tbl-header">
                    曲げ長さ(mm)
                  </th>
                  <th style={{ width: '12.31%' }} className="tbl-header">
                    曲げ回数
                  </th>
                  <th style={{ width: '12.31%' }} className="tbl-header">
                    FR曲げ回数
                  </th>
                </tr>
              </thead>
              <tbody>
                {bendingList?.map((i, index) => (
                  <>
                    <tr>
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {/* {editMode ? (
                      <> */}
                        {/** IQX_WEBEST-179 */}
                        <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                          {gettypeName(i.typesAutoExtract)}
                        </label>
                        <Select
                          defaultValue={1}
                          value={i.types}
                          onChange={(e) => changeDetails(e, i.id, 'types')}
                          size="middle"
                          style={{ width: '58%', marginLeft: 9 }}
                        >
                          {types?.map((option) => (
                            <Select.Option value={option.id}>{option.name}</Select.Option>
                          ))}
                        </Select>
                      </td>

                      <td>
                        {/** IQX_WEBEST-179 */}
                        <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                          {i.bendLengthAutoExtract}
                        </label>
                        <Input
                          className="input-editable"
                          value={i.bendLength}
                          onChange={(e) => changeDetails(e, i.id, 'bendLength')}
                          onBlur={(e) => blurDetails(e, i.id, 'bendLength')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                        ></Input>
                      </td>
                      <td>
                        {/** IQX_WEBEST-179 */}
                        <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.linesAutoExtract}</label>
                        <Input
                          className="input-editable"
                          value={i.lines}
                          onChange={(e) => changeDetails(e, i.id, 'lines')}
                          // onBlur={(e) => blurDetails(e, i.id, 'lines')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                        ></Input>
                      </td>
                      <td>
                        {types
                          ?.filter((item) => item.name.includes(FRBend)) // IQX_WEBEST-196
                          ?.map((item) => {
                            if (item.id === i.types) {
                              // Render the content you want when a match is found
                              return (
                                <>
                                  {/** IQX_WEBEST-179 */}
                                  <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                                    {i.bendCountAutoExtract}
                                  </label>
                                  <Input
                                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                                    className="input-editable"
                                    value={i.bendCount}
                                    onChange={(e) => changeDetails(e, i.id, 'bendCount')}
                                    // onBlur={(e) => blurDetails(e, i.id, 'bendCount')}
                                    onKeyPress={(e) => {
                                      handleKeyPress(e, true);
                                    }}
                                    style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                                  ></Input>
                                </>
                              );
                            } else {
                              // IQX_WEBEST-196
                              return <></>; // or any other content for no match
                            }
                          })}
                      </td>
                      {/* <td>
                    {types?.filter((item) => item.name === FRBend)?.[0].id === i.types ? (
                      <>
                        <Input
                          // className={editMode ? 'input-editable ' : 'input-non-editable '}
                          className="input-editable"
                          value={i.bendCount}
                          onChange={(e) => changeDetails(e, i.id, 'bendCount')}
                          style={{ height: 32, textAlign: 'center' }}
                        ></Input>
                      </>
                    ) : (
                      <></>
                    )}
                  </td> */}

                      {/* {editMode ? (
                    <> */}
                      <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                        <Row justify="center">
                          <PlusCircleFilled
                            className="add-remove-icon"
                            style={{ marginLeft: 10, marginRight: 10 }}
                            onClick={() => addBending(i.id)}
                          />
                          <CloseCircleFilled className="add-remove-icon" onClick={() => deleteBending(i.id)} />
                        </Row>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_Bending;
