import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { WorkType, DataType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_Shirring = forwardRef((props, ref) => {
  const [isShirringdetail, setIShirringdetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [shirringAmt, setShirringAmt] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [areaa, setAreaa] = useState();
  const [weightt, setWeightt] = useState();

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setShirringAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmShearing);
  };

  const settingEditData = (arr) => {
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let childSumArr = [];
    let dandoriAmtSum = 0;
    let dandoriTimeSumSec = 0;

    if (arr[0] == undefined) return;

    dandoriAmtSum = arr[0].dandoriAmt;
    dandoriTimeSumSec = arr[0].dandoriTime;

    for (let i = 0; i < arr?.length; i++) {
      sagyoAmt += arr[i].sagyoAmt;
      sagyoTimeSec += arr[i].sagyoTime;

      childSumArr.push({
        id: i,
        processName: arr[i].processName,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        // dandoriTimeSec: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        sagyoTime: arr[i].sagyoTime,
        // sagyoTimeSec: arr[i].sagyoTime,
        processCount: arr[i].processCount,
        processTime: arr[i].processTime,
      });
    }
    return childSumArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSum = 0;
    let sagyoAmtSum = 0;
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      sagyoTimeSum += element.sagyoTime;
    });

    let totalSum =
      (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) +
      (editDataArr[0].dandoriAmt ? Math.round(editDataArr[0].dandoriAmt) : 0); // 画面表示用（四捨五入した金額を足す）
    let totalTimeSum = sagyoTimeSum + editDataArr[0].dandoriTime;
    /* let sagyoTimeSum = sagyoTimeSum; */

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      sagyoTimeSum: sagyoTimeSum,
      totalSum: totalSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCalIQ3 || props?.iq3ActionForCal) {
      let editData = [];
      let totals = [];
      let processMst = [];
      let deviceMst = [];
      let toBEdit = [];
      setSagyoTimeSum(0);
      setSagyoAmtSum(0);
      setTotalSum(0);
      setTotalTimeSum(0);

      let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
      processMst = parameters?.process
        ? parameters.process?.filter((item) => item.workType === WorkType.SmShearing)[0]
        : [];
      let patternInfo = props?.selectedIQ3DataDetail?.processSelect
        ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
        : [];
      if (patternInfo == undefined) return;
      if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
      deviceMst =
        parameters?.device && patternInfo
          ? parameters?.device?.filter(
              (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
            )[0]
          : []; //テスト用ID;
      setProcessMst(processMst ? processMst : []);
      setDeviceMst(deviceMst ? deviceMst : []);
      // 選択された行の工程積算情報を正しく取得するための対応
      let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
        (item) => item.no === props?.selectedIQ3DataDetail?.no
      );
      let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
      if (selectedIQ3DataDetail?.length === 0) return;
      let shirringAdditions = selectedIQ3DataDetail?.additions?.filter((item) => item.processId == processMst?.id)[0];
      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      let shirringAdditionsEditData = shirringAdditions?.editItems;
      let editDataFlg = shirringAdditions?.editDataFlg;
      // シャーリング工程入力情報取得
      let shirringProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == processMst?.id
      )[0];
      // 新規の場合
      // if (shirringAdditions?.dataItems?.length == 0 || shirringAdditions == undefined) {
      let shirringAddition = {};
      let detailItems = [];
      let editDetailItems = [];
      let processName = '加工金額/時間';
      let processTime = 0;

      // シャーリング詳細情報
      detailItems.push({
        id: 1,
        processName: processName,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        processCount: Number(shirringProcessInput?.details?.[0]?.numOfCuts),
        processTime: processTime,
        kihon: true,
        formula: '',
      });

      shirringAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: shirringAdditions?.dataType ? shirringAdditions.dataType : DataType.Data,
        dataItems: detailItems,
        editItems: shirringAdditions?.editItems ? shirringAdditions.editItems : editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
        editDataFlg: editDataFlg,
      };
      shirringAdditions = shirringAddition;
      // }

      if (shirringAdditions?.dataItems) {
        let orgData = JSON.parse(JSON.stringify(shirringAdditions?.dataItems));
        if (deviceMst != undefined && orgData?.length > 0) {
          // 重量
          let weight = props?.selectedIQ3DataDetail?.weight;
          setWeightt(weight);
          // 面積
          let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
          setAreaa(area);

          //総重量が0、面積が0の場合、段取と加工を計算しない。
          if (weight === 0 && area === 0) {
            /** 段取金額/時間 */
            // 段取時間設定
            shirringAdditions.dataItems[0].dandoriTime = 0;
            orgData[0].dandoriTime = 0;

            // 段取金額設定
            shirringAdditions.dataItems[0].dandoriAmt = 0;
            orgData[0].dandoriAmt = 0;

            /** 作業金額/時間 */
            shirringAdditions.dataItems[0].processCount = 0;
            orgData[0].processCount = 0;

            shirringAdditions.dataItems[0].sagyoTime = 0;
            orgData[0].sagyoTime = 0;
            setSagyoTimeSum(0);

            // 1カット時間
            shirringAdditions.dataItems[0].processTime = 0;
            orgData[0].processTime = 0;

            // 作業金額
            shirringAdditions.dataItems[0].sagyoAmt = 0;
            orgData[0].sagyoAmt = 0;
            setSagyoAmtSum(0);

            setTotalSum(0);
            shirringAdditions.totalDataPrice = 0;
            shirringAdditions.totalDandori.dataItemsAmt = 0;
            shirringAdditions.totalSagyo.dataItemsAmt = 0;

            setTotalTimeSum(0);
            shirringAdditions.totalDataTime = 0;
            shirringAdditions.totalDandori.dataItemsTime = 0;
            shirringAdditions.totalSagyo.dataItemsTime = 0;
          } else {
            //カット数
            let processCount = Number(shirringProcessInput?.details?.[0]?.numOfCuts);
            let dandoriTimeSec = 0;
            let dandoriTimeHms = 0;
            let dandoriAmt = 0;
            // カット数が０の場合、段取計算しない。（IQX_WEBEST-63）
            if (processCount > 0) {
              /** 段取金額/時間 */
              // 段取時間設定
              //　基本段取時間＊サイズ係数
              dandoriTimeSec = calDandoriTime(deviceMst, weight, area);
              dandoriTimeHms = dandoriTimeSec;
              shirringAdditions.dataItems[0].dandoriTime = dandoriTimeHms;
              orgData[0].dandoriTime = dandoriTimeHms ? dandoriTimeHms : 0;
              // 段取金額設定
              //段階時間＊時間チャージ
              dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
              shirringAdditions.dataItems[0].dandoriAmt = dandoriAmt;
              orgData[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
            } else {
              shirringAdditions.dataItems[0].dandoriTime = 0;
              orgData[0].dandoriTime = 0;
              shirringAdditions.dataItems[0].dandoriAmt = 0;
              orgData[0].dandoriAmt = 0;
            }
            /** 作業金額/時間 */
            // 作業時間
            // １カット時間＊カット数＊サイズ係数
            shirringAdditions.dataItems[0].processCount = processCount;
            orgData[0].processCount = processCount ? processCount : 0;

            let cSagyoTimePerSec = calSagyoTime(deviceMst, weight, area, processCount);
            let cSagyoTime = cSagyoTimePerSec;
            shirringAdditions.dataItems[0].sagyoTime = cSagyoTime;
            orgData[0].sagyoTime = cSagyoTime ? cSagyoTime : 0;
            setSagyoTimeSum(cSagyoTime);

            // 1カット時間
            shirringAdditions.dataItems[0].processTime = deviceMst?.details?.cutTime;
            orgData[0].processTime = deviceMst?.details?.cutTime === undefined ? 0 : deviceMst?.details?.cutTime;

            // 作業金額
            //　作業時間＊時間チャージ
            let cSagyoAmt = calSagyoAmt(cSagyoTimePerSec, deviceMst);
            shirringAdditions.dataItems[0].sagyoAmt = cSagyoAmt;
            orgData[0].sagyoAmt = cSagyoAmt ? cSagyoAmt : 0;
            setSagyoAmtSum(cSagyoAmt);

            let totalSum = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (cSagyoAmt ? Math.round(cSagyoAmt) : 0); // 画面表示用（四捨五入した金額を足す）
            setTotalSum(totalSum);
            shirringAdditions.totalDataPrice = totalSum;
            shirringAdditions.totalDandori.dataItemsAmt = dandoriAmt ? Math.round(dandoriAmt) : 0;
            shirringAdditions.totalSagyo.dataItemsAmt = cSagyoAmt ? Math.round(cSagyoAmt) : 0;

            let totalTimeSec = dandoriTimeSec + cSagyoTime;
            setTotalTimeSum(totalTimeSec);
            shirringAdditions.totalDataTime = totalTimeSec;
            shirringAdditions.totalDandori.dataItemsTime = dandoriTimeSec;
            shirringAdditions.totalSagyo.dataItemsTime = cSagyoTime;
          }

          setProcessArr(orgData);

          // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
          if (shirringAdditionsEditData?.length > 0 && editDataFlg) {
            editData = settingEditData(JSON.parse(JSON.stringify(shirringAdditionsEditData)));
          } else {
            editData = settingEditData(JSON.parse(JSON.stringify(orgData)));
          }
          if (editData != undefined && editData?.length > 0) {
            shirringAdditions.editItems = editData;
            setEditedData(editData);
            setOldEditedData(editData);
            totals = calculateTotals(editData);

            setTotalAmt(totals[0]?.totalSum);
            shirringAdditions.totalEditPrice = totals[0]?.totalSum;
            shirringAdditions.totalDandori.editItemsAmt = editData[0].dandoriAmt
              ? Math.round(editData[0].dandoriAmt)
              : 0;
            shirringAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

            setTotalTime(totals[0]?.totalTimeSum);
            shirringAdditions.totalEditTime = totals[0]?.totalTimeSum;
            shirringAdditions.totalDandori.editItemsTime = editData[0].dandoriTime;
            shirringAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

            setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
            setTotalSagyoTime(totals[0]?.sagyoTimeSum);

            setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
            setTotalDandoriTime(totals[0]?.dandoriTimeSum);
          }
          setShirringAmt(
            shirringAdditions?.dataType == DataType.Data
              ? shirringAdditions.totalDataPrice
              : shirringAdditions.totalEditPrice
          );
          setUpdateEditedData(shirringAdditions);
          props?.updateIQ3KouteiCal(shirringAdditions, WorkType.SmShearing);
        }
      }
    }
  }, [props?.isReCalIQ3, props?.iq3ActionForCal, props?.tabOpen, props?.dialogOk]);

  useEffect(() => {
    if (props?.tabOpen) {
      setEditMode(props?.editMode != undefined ? props?.editMode : false);
    }
  }, [props?.editMode]);

  const calDandoriTime = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item?.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoTime = (deviceMst, weight, area, processCount) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 作業時間
    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item?.isOutOfRange
          )?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊検査数＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.cutTime * processCount * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    dandoriTimeSec = dandoriTimeSec * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
    return dandoriTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const shirring = () => {
    if (isShirringdetail == true) {
      setIShirringdetail(false);
    } else {
      setIShirringdetail(true);
    }
  };

  const setShirringDisplay = (display) => {
    setIShirringdetail(display);
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setShirringDisplay: setShirringDisplay,
  }));

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setShirringAmt(updateEditedData.totalDataPrice);
    } else {
      setShirringAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmShearing);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }
    let oldValue = editedData[index][objId];
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'ShirringDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'ShirringDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].dandoriAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
          }
          // checkInputWithColor(false, '', index + 'dandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      if (document.getElementById(index + 'ShirringSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'ShirringSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          }
          // checkInputWithColor(false, '', index + 'sagyoAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (objId === 'handelingTime') {
      if (document.getElementById('ShirringHandlingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('ShirringHandlingAmtCu').style.display;
        if (showHideCu === 'block') {
          //checkInputWithColor(false,'','ShirringHandlingAmt', newState[0].handelingAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          } else {
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          }
          /* checkInputWithColor(false,'','ShirringHandlingAmt', newState[0].handelingAmt, 'green');*/
        }
      }
    } else if (objId === 'processCount') {
      // 1シャーリング時間＊シャーリング箇所＊サイズ係数
      let cSagyoTimePerSec = calSagyoTime(deviceMst, weightt, areaa, Number(value));
      let cSagyoTimeSec = cSagyoTimePerSec; /* cSagyoTimePerSec * value * 1.0; */
      let cSagyoTime = cSagyoTimeSec;
      newState[index].sagyoTime = cSagyoTime;
      updateEditedData.editItems[index].sagyoTime = cSagyoTime;

      // 作業金額
      //　作業時間＊時間チャージ
      if (document.getElementById(index + 'ShirringSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'ShirringSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          newState[index].sagyoAmt = cSagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = cSagyoAmt;
        }
      }
    } else if (objId === 'processTime') {
      // 入力１シャーリング時間＊シャーリング箇所＊サイズ係数
      let cSagyoTimeSecIn = Number(value);
      let cSagyoTimeSec = cSagyoTimeSecIn * newState[index].processCount * 1.0;
      let cSagyoTime = cSagyoTimeSec;
      newState[index].sagyoTime = cSagyoTime;
      updateEditedData.editItems[index].sagyoTime = cSagyoTime;

      // 作業金額
      //　作業時間＊時間チャージ
      if (document.getElementById(index + 'ShirringSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'ShirringSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          newState[index].sagyoAmt = cSagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = cSagyoAmt;
        }
      }
    }
    // else if (objId === 'shirringTime') {
    //   if (document.getElementById('ShirringShirringAmtCu').style.display !== undefined) {
    //     let showHideCu = document.getElementById('ShirringShirringAmtCu').style.display;
    //     if (showHideCu === 'block') {
    //       //checkInputWithColor(false,'','ShirringShirringAmt', newState[0].shirringAmt, 'red');
    //     } else {
    //       if (timeAct === 'input') {
    //         let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
    //         newState[0].shirringAmt = changeAmtwithTime(newState[0].shirringAmt, timeType, timeAct, value,objId);
    //       } else {
    //         newState[0].shirringAmt = changeAmtwithTime(newState[0].shirringAmt, timeType, timeAct, value,objId);
    //       }
    //       checkInputWithColor(false, '', 'ShirringShirringAmt', newState[0].shirringAmt, '#4cc12f');
    //     }
    //   }
    // }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += Math.round(element.sagyoAmt);
      newDandoriSum += Math.round(element.dandoriAmt);
      newSagyoTimeSec += element.sagyoTime;
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += Number(newState[0].handelingAmt);
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime); */
    newSum = (newSagyoSum ? Math.round(newSagyoSum) : 0) + (newDandoriSum ? Math.round(newDandoriSum) : 0); // 画面表示用（四捨五入した金額を足す）

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    // let shirringTimeSec = setTimetoSec(newState[0].shirringTime);
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);

    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setShirringAmt(updateEditedData.totalDataPrice);
    } else {
      setShirringAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmShearing);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    //let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <>
      {props?.tabOpen && props?.isShirring ? (
        <div className="kouteiCalculateDiv">
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={shirring}>
            <Col span={4} className="oyabuhin-col">
              <div style={{ display: 'flex' }}>
                {isShirringdetail ? (
                  <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                )}
                <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
              </div>
            </Col>
            <Col span={6} className="oyabuhin-col">
              <div>{shirringAmt ? JPYs.format(Math.round(shirringAmt)) : amtEmptyStr}</div>
            </Col>
            <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
              <div>
                <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
              </div>
            </Col>
          </Row>
          <div style={{ display: isShirringdetail ? 'block' : 'none' }}>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                工程詳細
              </Col>
              <Col span={6} className="oyabuhin-detail-col">
                <Checkbox
                  key={kouteiCalculateSelectedArr[0].key}
                  value={kouteiCalculateSelectedArr[0].value}
                  checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
                  disabled={!props?.editMode}
                  style={{ marginRight: 6 }}
                  onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
                ></Checkbox>
                <label>{kouteiCalculateSelectedArr[0].value}</label>
              </Col>
              <Col span={8} className="oyabuhin-detail-col">
                <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                  <Checkbox
                    key={kouteiCalculateSelectedArr[1].key}
                    value={kouteiCalculateSelectedArr[1].value}
                    disabled={!props?.editMode}
                    onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                    checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                    style={{ marginRight: 6 }}
                  ></Checkbox>
                  <label>{kouteiCalculateSelectedArr[1].value}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                  <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                    {DataButton}
                  </Button>
                </div>
              </Col>

              <Col span={1} className="oyabuhin-detail-col">
                単位
              </Col>
              <Col span={5} className="formula-column">
                計算式
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                {processMst?.name}
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label id="totalAmtIn" style={{ color: totalSum == totalAmt ? 'black' : '#4cc12f' }}>
                  {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label id="totalTimeIn" style={{ color: totalTimeSum == totalTime ? 'black' : '#4cc12f' }}>
                  {totalTime ? secondsToHms(totalTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.procTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.procTotalTm}>時間</Popover>
              </Col>
            </Row>
            {/* 段取金額/時間 */}
            {/* <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('ShirringDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpShirringDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownShirringDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{JPYs.format(dandoriAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtIn" className="resetColor">
             
              {JPYs.format(totalDandoriAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeIn" className="resetColor">
              {totalDandoriTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row> */}

            {(() => {
              const arr = [];
              for (let j = 0; j < processArr?.length; j++) {
                arr.push(
                  <div>
                    {/* ○○_標準 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex' }}>
                          <span>段取金額/時間</span>
                        </div>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'ShirringDandoriAmt'}>
                          {processArr[0]?.dandoriAmt ? JPYs.format(Math.round(processArr[0]?.dandoriAmt)) : amtEmptyStr}
                        </label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        {
                          <label id={j + 'ShirringDndoriTime'}>
                            {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
                          </label>
                        }
                      </Col>

                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={j + 'ShirringDandoriAmtIn'}
                          value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                          defaultValue={0}
                          style={{
                            border: 'none',
                            color:
                              Number(processArr[j]?.dandoriAmt) === Number(editedData[j]?.dandoriAmt)
                                ? 'black'
                                : checkShowHideIcon(j + 'ShirringDandoriAmtCu') === 'none'
                                ? '#4cc12f'
                                : 'red',
                          }}
                          onValueChange={(e) => {
                            //checkRegex(e.target.value);

                            if (editedData[j]?.dandoriAmt != e) {
                              let value = e ? parseInt(e) : 0;
                              settingNewDandoriAmtInArr(value, j, 'dandoriAmt', '', '');
                              checkInputWithColor(false, '', j + 'ShirringDandoriAmt', value, 'amt');
                            }
                          }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={j + 'ShirringDandoriAmtCu'} style={{ display: 'none' }}>
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                              id={j + 'ShirringDandoriTimeIn'}
                              style={{
                                width: '100%',
                                color:
                                  processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                    ? handleShowHide(
                                        Number(editedData[j]?.dandoriAmt),
                                        processArr[j]?.dandoriAmt,
                                        j + 'ShirringDandoriAmtCu'
                                      )
                                    : 'red',
                              }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      '10min',
                                      j + 'ShirringDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      '10min',
                                      j + 'ShirringDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'min',
                                      j + 'ShirringDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'min',
                                      j + 'ShirringDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'sec',
                                      j + 'ShirringDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'sec',
                                      j + 'ShirringDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={2} className="formula-column formula-column-right-border">
                        <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                      </Col>
                      <Col span={2} className="formula-column">
                        <Popover content={helpCmt.setupTm_p6}>時間</Popover>
                      </Col>
                    </Row>
                  </div>
                );
              }
              return arr;
            })()}

            {/*  作業金額/時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('ShirringSagyoTotal')}>
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpShirringSagyoTotal'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />

                  <CaretDownOutlined id={'arrowDownShirringSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

                  <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label id="totalSagyoAmtIn" style={{ color: sagyoAmtSum == totalSagyoAmt ? 'black' : '#4cc12f' }}>
                  {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label id="totalSagyoTimeIn" style={{ color: sagyoTimeSum == totalSagyoTime ? 'black' : '#4cc12f' }}>
                  {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.workAmt_p2}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.workTm_shirring}>時間</Popover>
              </Col>
            </Row>
            <div id={'divShirringSagyoTotal'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];

                for (let i = 0; i < editedData?.length; i++) {
                  arr.push(
                    <div>
                      {/* 加工金額/時間 */}
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col">
                          <span className="ddl_2_noImg kouteiCal-proc-title">加工金額/時間</span>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={i + 'ShirringSagyoAmt'}>
                            {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={i + 'ShirringSagyoTime'}>
                            {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>

                        <Col span={4} className="input-col">
                          <CurrencyInput
                            id={i + 'ShirringSagyoAmtIn'}
                            value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                            defaultValue={0}
                            style={{
                              border: 'none',
                              color:
                                Number(processArr[i]?.sagyoAmt) === Number(editedData[i]?.sagyoAmt)
                                  ? 'black'
                                  : checkShowHideIcon(i + 'ShirringSagyoAmtCu') === 'none'
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            onValueChange={(e) => {
                              //checkRegex(e.target.value);

                              if (editedData[i]?.sagyoAmt != e) {
                                let value = e ? parseInt(e) : 0;
                                settingNewDandoriAmtInArr(value, i, 'sagyoAmt', '', '');
                                checkInputWithColor(false, '', i + 'ShirringSagyoAmt', value, 'amt');
                              }
                            }}
                            className={
                              editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                            }
                            prefix="¥"
                            decimalsLimit={100}
                          />
                          <div id={i + 'ShirringSagyoAmtCu'} style={{ display: 'none' }}>
                            <div className="currencyIcon">
                              <BsUsbC style={{ fontSize: '25' }} />
                              <BsX style={{ margin: '-8px 0 0 16px' }} />
                              <span class="tooltiptext">
                                金額に変更がありましたので、以降時間と連携して自動計算されない。
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                                id={i + 'ShirringSagyoTimeIn'}
                                style={{
                                  width: '100%',
                                  color:
                                    processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                      ? handleShowHide(
                                          Number(processArr[i]?.sagyoAmt),
                                          editedData[i]?.sagyoAmt,
                                          i + 'ShirringSagyoAmtCu'
                                        )
                                      : oldEditedData[i]?.processCount !== editedData[i]?.processCount ||
                                        oldEditedData[i]?.processTime !== editedData[i]?.processTime
                                      ? '#4cc12f'
                                      : 'red',
                                }}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                onChange={(e) => {
                                  settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        '10min',
                                        i + 'ShirringSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        '10min',
                                        i + 'ShirringSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'min',
                                        i + 'ShirringSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'min',
                                        i + 'ShirringSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'sec',
                                        i + 'ShirringSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'sec',
                                        i + 'ShirringSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col"></Col>
                        <Col span={4} className="formula-column"></Col>
                      </Row>
                      {/* カット数/1カット加工時間 */}
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col">
                          <span className="ddl_2_noImg">カット数/1カット加工時間</span>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={i + 'processCount'}>{processArr[i]?.processCount}</label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={i + 'processTime'}>
                            {processArr[i]?.processTime ? secondsToHms(processArr[i]?.processTime) : timeHmsStr}
                          </label>
                        </Col>

                        <Col span={4} className="input-col">
                          <Input
                            value={editedData[i]?.processCount}
                            id={i + 'processCountIn'}
                            min={0}
                            className={editMode ? 'input-editable' : 'input-non-editable '}
                            controls={false}
                            style={{
                              width: '45%',
                              border: 'none',
                              color: editedData[i]?.processCount === processArr[i]?.processCount ? 'black' : 'red',
                            }}
                            onKeyPress={(e) => {
                              handleKeyPress(e);
                            }}
                            onChange={(e) =>
                              settingNewDandoriAmtInArr(
                                e.target.value ? parseInt(e.target.value) : 0,
                                i,
                                'processCount',
                                '',
                                ''
                              )
                            }
                          />
                        </Col>

                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={
                                  editedData[i]?.processTime ? secondsToHms(editedData[i]?.processTime) : timeHmsStr
                                }
                                id="processTimeIn"
                                style={{
                                  width: '100%',
                                  color: editedData[i]?.processTime === processArr[i]?.processTime ? 'black' : 'red',
                                }}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                onChange={(e) => {
                                  settingNewDandoriAmtInArr(e.target.value, i, 'processTime', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.processTime,
                                        '10min',
                                        'processTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'processTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.processTime,
                                        '10min',
                                        'processTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'processTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.processTime,
                                        'min',
                                        'processTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.processTime,
                                        'min',
                                        'processTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.processTime,
                                        'sec',
                                        'processTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.processTime,
                                        'sec',
                                        'processTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col"></Col>
                        <Col span={4} className="formula-column"></Col>
                      </Row>
                    </div>
                  );
                }
                return arr;
              })()}
            </div>

            {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            <span style={{ marginLeft: '5px' }}>工程内シャーリング金額/時間</span>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            
            <label id="ShirringShirringAmt">{JPYs.format(shirringAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="ShirringShirringTime">{shirringTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              id="ShirringShirringAmtIn"
              value={editedData[0]?.shirringAmt}
              defaultValue={0}
              onValueChange={(e) => {
                if (editedData[0]?.shirringAmt != e) {
                  settingNewDandoriAmtInArr(e, 0, 'shirringAmt', '', '');
                  checkInputWithColor(false, '', 'ShirringShirringAmt', e, 'amt');
                }
              }}
              style={{
                border: 'none',
                color:
                  Number(editedData[0]?.shirringAmt) === shirringAmt
                    ? 'black'
                    : checkShowHideIcon('ShirringShirringAmtCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              prefix="¥"
              decimalsLimit={100}
            />
            <div id="ShirringShirringAmtCu" style={{ display: 'none' }} className="currencyReset">
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={editedData[0]?.shirringTime}
                  id="ShirringShirringTimeIn"
                  style={{
                    width: '100%',
                    color:
                      editedData[0]?.shirringTime === shirringTime
                        ? handleShowHide(
                            Number(shirringAmt),
                            Number(editedData[0]?.shirringAmt),
                            'ShirringShirringAmtCu'
                          )
                        : 'red',
                  }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    settingNewDandoriAmtInArr(e.target.value, 0, 'shirringTime', 'input', 'input');
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.shirringTime, '10min', 'shirringShirringTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'shirringTime', '10min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.shirringTime, '10min', 'shirringShirringTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'shirringTime', '10min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.shirringTime, 'min', 'shirringShirringTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'shirringTime', 'min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.shirringTime, 'min', 'shirringShirringTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'shirringTime', 'min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.shirringTime, 'sec', 'shirringShirringTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'shirringTime', 'sec', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.shirringTime, 'sec', 'shirringShirringTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'shirringTime', 'sec', 'dec');
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row> */}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiCalculate_Shirring;
