/**
 * クラス名：工程パターンフォーム画面
 * 説明：親部品・板金の共通使う工程パターンの詳細画面の子JSファイルである。
 * 作成者：ナンス
 * 作成日：2023/04/01
 * バージョン：1.0
 */
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Select, Input, Checkbox, Radio, Tag, Button, Row, Col, List, Carousel, Empty, Spin } from 'antd';
import { SearchOutlined, LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  patternPage,
  CarouselNextArrow,
  CarouselPrevArrow,
  groupBy,
  today,
  initialCarouselBtn,
  ProcessDetailGroups,
  getAccessToken,
  updateAccessToken,
  isContractor,
} from '../../../common/Common';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  commonActionFooter,
  authorizeError,
  error,
} from '../../../common/CommonModal';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData';
import { paramCommonCustomer } from '../../../common/Constant';
import { ItakuSaki, itakuSaki } from '../../../common/Constant';
import { getClientInfo } from '../../../common/CommonAPI';
import { ClientType, ProcessDetailGroup, ServiceClass, WorkType, WorkTypeGroup } from '../../../common/enums';
import { ErrorMessage } from '../../../common/Message';

const ProcessPatternForm = forwardRef((props, ref) => {
  const tableRef = useRef(null);
  const [listData, setListData] = useState([]);
  const [patternList, setPatternList] = useState([]);
  const [patternNo, setPatternNo] = useState('');
  const [patternName, setPatternName] = useState('');
  const [selectedPatternInfo, setSelectedPatternInfo] = useState([]);
  const [selectedPatternInfoBk, setSelectedPatternInfoBk] = useState([]);
  const [weldingSochiList, setWeldingSochiList] = useState([]);
  const [weldFinishSochiList, setWeldFinishSochiList] = useState([]);
  const [surfaceSochiList, setSurfaceSochiList] = useState([]);
  const [assemblySochiList, setAssemblySochiList] = useState([]);
  const [packingSochiList, setPackingSochiList] = useState([]);
  const [inspectionSochiList, setInspectionSochiList] = useState([]);

  // IQ5
  const [setusdanProSochiList, setSetusdanProSochiList] = useState([]);
  const [anaProSochiList, setAnaProSochiList] = useState([]);
  const [kikkakeProSochiList, setKikkakeProSochiList] = useState([]);
  const [mageProSochiList, setMageProSochiList] = useState([]);
  const [toritsukeProSochiList, setToritsukeProSochiList] = useState([]);
  const [kaisakiProSochiList, setKaisakiProSochiList] = useState([]);
  const [baritoriProSochiList, setBaritoriProSochiList] = useState([]);
  const [yosetsuProSochiList, setYosetsuProSochiList] = useState([]);
  const [yosetsushiageProSochiList, setYosetsushiageProSochiList] = useState([]);
  const [hyomensekiProSochiList, setHyomensekiProSochiList] = useState([]);
  const [juryoProSochiList, setJuryoProSochiList] = useState([]);

  // IQ3
  const [programProSochiList, setProgramProSochiList] = useState([]);
  const [nijiProSochiList, setNijiProSochiList] = useState([]);
  const [bendingProSochiList, setBendingProSochiList] = useState([]);
  const [shearingProSochiList, setShearingProSochiList] = useState([]);

  const [partInfo, setPartInfo] = useState(1);

  const [weldFinishProcessLst, setWeldFinishProcessLst] = useState([]);
  const [weldFinishProcessTypeLst, setWeldFinishProcessTypeLst] = useState([]);
  const [weldFinishProcessCnt, setWeldFinishProcessCnt] = useState(0);

  const [surfaceProcessLst, setSurfaceProcessLst] = useState([]);
  const [surfaceProcessTypeLst, setSurfaceProcessTypeLst] = useState([]);
  const [surfaceProcessCnt, setSurfaceProcessCnt] = useState(0);

  const [surfaceProcessTypeVal1Lst, setSurfaceProcessTypeVal1Lst] = useState([]);
  const [surfaceProcessTypeVal2Lst, setSurfaceProcessTypeVal2Lst] = useState([]);
  const [surfaceProcessTypeVal3Lst, setSurfaceProcessTypeVal3Lst] = useState([]);
  const [surfaceProcessTypeVal0Lst, setSurfaceProcessTypeVal0Lst] = useState([]);

  const [customProcessLst, setCustomProcessLst] = useState([]);
  const [blankProcessList, setBlankProcessLst] = useState([]);
  const [deburrProcessList, setDeburrProcessLst] = useState([]);

  const [customerModal, setCustomerModal] = useState(false);

  const inputFile = useRef(null);

  const weldFinishProcList = [{ value: 0, label: '無し' }];

  const surfaceProc0List = [{ value: 0, label: 'ー' }];
  const surfaceProc1lList = [];
  const surfaceProc2lList = [];
  const surfaceProc3lList = [];

  // IQ5工程
  const [setsudan, setSetsudan] = useState('');
  const [setsudanChk, setSetsudanChk] = useState('');
  const [ana, setAna] = useState('');
  const [anaChk, setAnaChk] = useState('');
  const [kikkake, setKikkake] = useState('');
  const [kikkakeChk, setKikkakeChk] = useState('');
  const [mage, setMage] = useState('');
  const [mageChk, setMageChk] = useState('');
  const [toritsuke, setToritsuke] = useState('');
  const [toritsukeChk, setToritsukeChk] = useState('');
  const [kaisaki, setKaisaki] = useState('');
  const [kaisakiChk, setKaisakiChk] = useState('');
  const [baritori, setBaritori] = useState('');
  const [baritoriChk, setBaritoriChk] = useState('');
  const [yosetsu, setYosetsu] = useState('');
  const [yosetsuChk, setYosetsuChk] = useState('');
  const [yosetsushiage, setYosetsushiage] = useState('');
  const [yosetsushiageChk, setYosetsushiageChk] = useState('');
  const [hyomenseki, setHyomenseki] = useState('');
  const [hyomensekiChk, setHyomensekiChk] = useState('');
  const [juryo, setJuryo] = useState('');
  const [juryoChk, setJuryoChk] = useState('');

  // IQ3工程
  const [program, setProgram] = useState('');
  const [programChk, setProgramChk] = useState('');
  const [secondary, setSecondary] = useState('');
  const [secondaryChk, setSecondaryChk] = useState('');
  const [bending, setBending] = useState('');
  const [bendingChk, setBendingChk] = useState('');
  const [shearing, setShearing] = useState('');
  const [shearingChk, setShearingChk] = useState('');

  const [welding, setWelding] = useState('');
  const [weldingChk, setWeldingChk] = useState('');
  const [weldFinish, setWeldFinish] = useState('');
  const [weldFinishChk, setWeldFinishChk] = useState('');
  const [surface, setSurface] = useState('');
  const [surfaceChk, setSurfaceChk] = useState('');
  const [assembly, setAssembly] = useState('');
  const [assemblyChk, setAssemblyChk] = useState('');
  const [packing, setPacking] = useState('');
  const [packingChk, setPackingChk] = useState('');
  const [inspection, setInspection] = useState('');
  const [inspectionChk, setInspectionChk] = useState('');
  const [iq3PartList, setIq3PartList] = useState('');
  const [iq5PartList, setIq5PartList] = useState('');

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  // 追加工程のお客様情報設定用変数
  const [addProKey, setAddProKey] = useState(0);
  const [addProDeviceCode, setAddProDeviceCode] = useState(0);
  const [addProCarouselIndex, setAddProCarouselIndex] = useState(0);
  const [addProIndex, setAddProIndex] = useState(0);

  // 編集モード
  const editMode = props.editMode;

  // マスタ情報
  const [processMaster, setProcessMaster] = useState([]);
  const [deviceMaster, setDeviceMaster] = useState([]);
  const [clientMaster, setClientMaster] = useState([]);

  // 呼び出す画面名
  const { page } = props;

  const caroselRef = useRef();

  const inputItemRef = {};

  // 標準工程／追加工程の表示設定
  const renderPartComponent = () => {
    return standardItem;
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  // 工程、装置マスタ情報
  useEffect(() => {
    setProcessMaster(props.processMasterData);
    setDeviceMaster(props.deviceMasterData);
    setClientMaster(props.clientMasterData);
  }, [props.processMasterData, props.deviceMasterData, props.clientMasterData]);

  // 画面の初期設定
  useEffect(() => {
    if (props.selectedData == undefined && props.selectedData == null) return;
    setSelectedPatternInfo(JSON.parse(JSON.stringify(props.selectedData)));
    setSelectedPatternInfoBk(JSON.parse(JSON.stringify(props.selectedData)));
    if (page === patternPage[1] && page === patternPage[2] && !props.isParameter) {
      setIq3PartList(props.iq3PartList);
      setPartInfo(props.childPartNo);
    }else if(page === patternPage[3] && !props.isParameter){
      setIq5PartList(props.iq5PartList);
      setPartInfo(props.childPartNo);
    }
    
    // 工程パターンリスト情報取得
    if (props.listData != undefined && props.listData?.length > 0) setPatternList(props.listData);

    if (props.selectedData != undefined && props.selectedData?.length == 0) {
      setProgramChk(false);
      setProgram('');
      //
      setSetsudanChk(false);
      setSetsudan('');
      setAnaChk(false);
      setAna('');
      setKikkakeChk(false);
      setKikkake('');
      setMageChk(false);
      setMage('');
      setToritsukeChk(false);
      setToritsuke('');
      setKaisakiChk(false);
      setKaisaki('');
      setBaritoriChk(false);
      setBaritori('');
      setYosetsuChk(false);
      setYosetsu('');
      setYosetsushiageChk(false);
      setYosetsushiage('');
      setHyomensekiChk(false);
      setHyomenseki('');
      setJuryoChk(false);
      setJuryo('');
      //
      setBlankProcessLst([]);
      setSecondaryChk(false);
      setSecondary('');
      setBendingChk(false);
      setBending('');
      setDeburrProcessLst([]);
      setShearingChk(false);
      setShearing([]);
      setWeldingChk(false);
      setWelding('');
      setWeldFinishChk(false);
      setWeldFinish('');
      setWeldFinishProcessLst([]);
      setSurfaceChk(false);
      setSurface([]);
      setSurfaceProcessLst([]);
      setAssemblyChk(false);
      setAssembly('');
      setInspectionChk(false);
      setInspection('');
      setCustomProcessLst([]);
      setPackingChk(false);
      setPacking('');
      setPatternNo('');
      if (props?.pmxMode) if (props?.updateIsPrepareInfoFlg) props?.updateIsPrepareInfoFlg(false);
    } else {
      settingProcessPattern(props.selectedData);
      if (props?.pmxMode) if (props?.updateIsPrepareInfoFlg) props?.updateIsPrepareInfoFlg(false);
    }
  }, [props.selectedData, props.listData, props.childPartNo]);

  const settingProcessPattern = async (selectedData) => {
    let processMasterData = [];
    let sochiList = [];
    // const kouteiMasterData = processMaster;
    const kouteiMasterData = props.processMasterData;

    //工程マスターデータにより種類選択ボックスデータ準備
    if (page === patternPage[0]) {
      // const sochiMasterData = deviceMaster;
      const sochiMasterData = props.deviceMasterData;
      processMasterData = kouteiMasterData?.filter((item) => item.class === ServiceClass.Parent);
      sochiList = sochiMasterData;
    } else if (page === patternPage[1]) {
      // const sochiMasterData = deviceMaster;
      const sochiMasterData = props.deviceMasterData;
      processMasterData = kouteiMasterData?.filter((item) => item.class === ServiceClass.SheetMetal);
      sochiList = sochiMasterData;
    } else if (page === patternPage[3]) {
      // const sochiMasterData = deviceMaster;
      const sochiMasterData = props.deviceMasterData;
      processMasterData = kouteiMasterData?.filter((item) => item.class === ServiceClass.Beams);
      sochiList = sochiMasterData;
    }
    if (selectedData !== null && processMasterData !== null) {
      // IQ3工程設定
      if (page === patternPage[1]) {
        settingIQ3Process(processMasterData, sochiList, selectedData);
        //溶接
        // 下記共通工程設定
        //選択された工程パータンの溶接装置情報取得
        const weldWorkType = WorkType.SmWelding;
        const weldProcId = processMasterData?.find((proc) => proc.workType === weldWorkType)?.id;
        const selectedWeld = selectedData?.details?.filter((item) => item.processId == weldProcId);
        if (selectedWeld !== undefined && selectedWeld?.length > 0) {
          setWeldingChk(selectedWeld[0].isUsedForCalc);
          // setWelding(selectedWeld[0].deviceId);
        }

        // 選択ボックスの溶接装置一覧取得
        const weldSochiTypeLst = sochiList?.filter((item) => item.processId === weldProcId && item.info.isUsed);
        let weldSochiLst = [];
        for (let j = 0; j < weldSochiTypeLst?.length; j++) {
          weldSochiLst.push({
            value: weldSochiTypeLst[j].id,
            label: weldSochiTypeLst[j].name,
          });
        }
        setWeldingSochiList(weldSochiLst);

        if (selectedWeld !== undefined && selectedWeld?.length > 0) {
          setWelding(
            isExistSochi(weldSochiLst, selectedWeld[0].deviceId)
              ? selectedWeld[0].deviceId
              : weldSochiLst?.length > 0
              ? weldSochiLst[0]?.value
              : 0
          );
        }

        //溶接仕上
        //選択された工程パータンの溶接仕上装置情報取得
        const weldFinishWorkType = WorkType.SmWeldFinish;
        const weldFinishId = processMasterData?.find((proc) => proc.workType === weldFinishWorkType)?.id;
        const weldFinishNm = selectedData?.details?.filter((item) => item.processId == weldFinishId);
        if (weldFinishNm !== undefined && weldFinishNm?.length > 0) {
          setWeldFinishChk(weldFinishNm[0].isUsedForCalc);
          // setWeldFinish(weldFinishNm[0].deviceId);
        }

        // 選択ボックスの溶接仕上装置一覧取得
        const weldFinishSochiTypeLst = sochiList?.filter((item) => item.processId === weldFinishId && item.info.isUsed);
        let weldFinishSochiLst = [];
        for (let j = 0; j < weldFinishSochiTypeLst?.length; j++) {
          weldFinishSochiLst.push({
            value: weldFinishSochiTypeLst[j].id,
            label: weldFinishSochiTypeLst[j].name,
          });
        }
        setWeldFinishSochiList(weldFinishSochiLst);

        if (weldFinishNm !== undefined && weldFinishNm?.length > 0) {
          setWeldFinish(
            isExistSochi(weldFinishSochiLst, weldFinishNm[0].deviceId)
              ? weldFinishNm[0].deviceId
              : weldFinishSochiLst?.length > 0
              ? weldFinishSochiLst[0]?.value
              : 0
          );
        }

        //溶接仕上種類マスターデータ取得
        const weldFinishType = processMasterData?.filter(
          (item) => item.workType === weldFinishWorkType && item.info.isUsed
        );
        let weldFinishProcessCnt = 0;
        if (weldFinishType) {
          weldFinishProcessCnt = weldFinishType[0]?.details?.processQty;
          setWeldFinishProcessCnt(weldFinishProcessCnt);
          for (let i = 0; i < weldFinishType[0]?.details.processDetailTypes?.length; i++) {
            if (weldFinishType[0].details.processDetailTypes[i].isUsed) {
              weldFinishProcList.push({
                value: weldFinishType[0]?.details.processDetailTypes[i].id,
                label: weldFinishType[0]?.details.processDetailTypes[i].name,
              });
            }
          }
        }
        setWeldFinishProcessTypeLst(weldFinishProcList);
        // 溶接仕上処理一覧データ準備
        const weldFinish = selectedData?.details?.filter((item) => item.processId === weldFinishId);
        if (weldFinish !== undefined && weldFinish?.length > 0) {
          const weldFinishProcLst = [];
          const weldFinishProcLstDisplay = [];
          let key = 0;
          let divider = 4; // 1div4処理表示のため
          let lastIndex = 0; //  4処理をdiv分けて設定するためのインデックス
          let weldFinishProFilter = [];
          for (let i = 1; i <= weldFinishProcessCnt; i++) {
            const selectedWeldFinishDetailProcessLst = weldFinish[0].multiprocesses?.filter(
              (item) => item.processNo == i
            );
            let processDetailTitleId = selectedWeldFinishDetailProcessLst[0]?.processDetailGroup;
            let processDetailTypeId =
              selectedWeldFinishDetailProcessLst?.length > 0
                ? selectedWeldFinishDetailProcessLst[0].processDetailTypeId
                : 0;

            weldFinishProcLst.push({
              key: i,
              title: `第${i}処理`,
              processDetailType: processDetailTitleId,
              processDetailCode: processDetailTypeId,
              processDetailValLst: weldFinishProcList,
            });

            // carouselのdivデータ準備（1div4処理表示）
            if (i == divider) {
              if (i == 4) {
                weldFinishProFilter = weldFinishProcLst?.filter((item) => item.key >= lastIndex && item.key <= divider);
              } else {
                weldFinishProFilter = weldFinishProcLst?.filter((item) => item.key > lastIndex && item.key <= divider);
              }
              weldFinishProcLstDisplay.push({
                key: key,
                weldFinishProcLst: weldFinishProFilter,
              });
              divider = divider + 4;
              lastIndex = i;
              key++;
            } else if (i == weldFinishProcessCnt) {
              // 最後のdiv設定
              weldFinishProFilter = weldFinishProcLst?.filter(
                (item) => item.key > lastIndex && item.key <= weldFinishProcessCnt
              );
              weldFinishProcLstDisplay.push({
                key: key,
                weldFinishProcLst: weldFinishProFilter,
              });
              key++;
            }
          }
          setWeldFinishProcessLst(weldFinishProcLstDisplay);
        }
      } else if (page === patternPage[3]) {
        //　IQ5形鋼
        settingIQ5Process(processMasterData, sochiList, selectedData);
      }

      //表面処理装置情報取得
      const surfaceWorkType = page === patternPage[1] ? WorkType.SmSurface : WorkType.PaSurface;
      const surProcId = processMasterData?.find((proc) => proc.workType === surfaceWorkType)?.id;
      const surfaceNm = selectedData?.details?.filter((item) => item.processId == surProcId);
      if (surfaceNm !== undefined && surfaceNm?.length > 0) {
        setSurfaceChk(surfaceNm[0].isUsedForCalc);
        // setSurface(surfaceNm[0].deviceId);
      }
      // 選択ボックスの表面処理装置一覧取得
      const surfaceSochiTypeLst = sochiList?.filter((item) => item.processId === surProcId && item.info.isUsed);
      let surfaceSochiLst = [];
      for (let j = 0; j < surfaceSochiTypeLst?.length; j++) {
        surfaceSochiLst.push({
          value: surfaceSochiTypeLst[j].id,
          label: surfaceSochiTypeLst[j].name,
        });
      }
      setSurfaceSochiList(surfaceSochiLst);

      if (surfaceNm !== undefined && surfaceNm?.length > 0) {
        setSurface(
          isExistSochi(surfaceSochiLst, surfaceNm[0].deviceId)
            ? surfaceNm[0].deviceId
            : surfaceSochiLst?.length > 0
            ? surfaceSochiLst[0]?.value
            : 0
        );
      }

      // 表面処理種類マスターデータ取得
      const surfaceType = processMasterData?.filter((item) => item.workType === surfaceWorkType && item.info.isUsed);
      const surfaceProcessCnt = surfaceType?.[0]?.details?.processQty;
      setSurfaceProcessCnt(surfaceProcessCnt);
      setSurfaceProcessTypeLst(ProcessDetailGroups);
      setSurfaceProcessTypeVal0Lst(surfaceProc0List);
      // 処理全般
      for (let i = 0; i < surfaceType?.[0]?.details.processDetailTypes?.length; i++) {
        if (
          surfaceType[0].details.processDetailTypes[i].isUsed &&
          surfaceType[0].details.processDetailTypes[i].group ===
            (page === patternPage[1] ? WorkTypeGroup.SmWorkGeneral : WorkTypeGroup.PaWorkGeneral)
        ) {
          surfaceProc1lList.push({
            value: surfaceType?.[0].details?.processDetailTypes?.[i]?.id,
            label: surfaceType?.[0].details?.processDetailTypes?.[i]?.name,
          });
        }
      }
      setSurfaceProcessTypeVal1Lst(surfaceProc1lList);

      // メッキ
      for (let i = 0; i < surfaceType?.[0]?.details.processDetailTypes?.length; i++) {
        if (
          surfaceType[0].details.processDetailTypes[i].isUsed &&
          surfaceType[0].details.processDetailTypes[i].group ===
            (page === patternPage[1] ? WorkTypeGroup.SmPlating : WorkTypeGroup.PaPlating)
        ) {
          surfaceProc2lList.push({
            value: surfaceType[0].details.processDetailTypes[i].id,
            label: surfaceType[0].details.processDetailTypes[i].name,
          });
        }
      }
      setSurfaceProcessTypeVal2Lst(surfaceProc2lList);

      // 塗装
      for (let i = 0; i < surfaceType?.[0]?.details.processDetailTypes?.length; i++) {
        if (
          surfaceType?.[0]?.details?.processDetailTypes?.[i]?.isUsed &&
          surfaceType?.[0]?.details?.processDetailTypes?.[i]?.group ===
            (page === patternPage?.[1] ? WorkTypeGroup.SmCoating : WorkTypeGroup.PaCoating)
        ) {
          surfaceProc3lList.push({
            value: surfaceType?.[0]?.details?.processDetailTypes?.[i]?.id,
            label: surfaceType?.[0]?.details?.processDetailTypes?.[i]?.name,
          });
        }
      }
      setSurfaceProcessTypeVal3Lst(surfaceProc3lList);

      // 表面処理一覧データ準備
      const surface = selectedData?.details?.filter((item) => item.processId === surProcId);
      if (surface !== undefined && surface?.length > 0) {
        const surfaceProcLst = [];
        const surfaceProcLstDisplay = [];
        let no = 1;
        let surface_divider = 4; // 1div4処理表示のため
        let surface_lastIndex = 0; //  4処理をdiv分けて設定するためのインデックス
        let surfaceProFilter = [];
        for (let i = 1; i <= surfaceProcessCnt; i++) {
          const selectedSurfaceDetailProcessLst = surface?.[0]?.multiprocesses?.filter((item) => item.processNo == i);
          let processDetailValLst = [];
          let processDetailTypeId =
            selectedSurfaceDetailProcessLst?.length > 0 ? selectedSurfaceDetailProcessLst?.[0]?.processDetailTypeId : 0;
          let processDetailTitleId =
            selectedSurfaceDetailProcessLst?.length > 0 ? selectedSurfaceDetailProcessLst?.[0]?.processDetailGroup : 0;
          if (processDetailTitleId === ProcessDetailGroup.General) {
            processDetailValLst = surfaceProc1lList;
          } else if (processDetailTitleId === ProcessDetailGroup.Plating) {
            processDetailValLst = surfaceProc2lList;
          } else if (
            processDetailTitleId === ProcessDetailGroup.OneSide ||
            processDetailTitleId === ProcessDetailGroup.DoubleSide
          ) {
            processDetailValLst = surfaceProc3lList;
          } else {
            processDetailValLst = surfaceProc0List;
          }
          surfaceProcLst.push({
            no: i,
            title: `第${i}処理`,
            processDetailType: processDetailTitleId,
            processDetailCode: processDetailTypeId,
            processDetailValLst: processDetailValLst,
          });

          // carouselのdivデータ準備（1div4処理表示）
          if (i == surface_divider) {
            if (i == 4) {
              surfaceProFilter = surfaceProcLst?.filter(
                (item) => item.no >= surface_lastIndex && item.no <= surface_divider
              );
            } else {
              surfaceProFilter = surfaceProcLst?.filter(
                (item) => item.no > surface_lastIndex && item.no <= surface_divider
              );
            }
            surfaceProcLstDisplay.push({
              no: no,
              surfaceProcLst: surfaceProFilter,
            });
            surface_divider = surface_divider + 4;
            surface_lastIndex = i;
            no++;
          } else if (i == surfaceProcessCnt) {
            // 最後のdiv設定
            surfaceProFilter = surfaceProcLst?.filter(
              (item) => item.no > surface_lastIndex && item.no <= surfaceProcessCnt
            );
            surfaceProcLstDisplay.push({
              no: no,
              surfaceProcLst: surfaceProFilter,
            });
            no++;
          }
        }
        setSurfaceProcessLst(surfaceProcLstDisplay);
      }

      //組立装置情報取得（親部品のみ表示）
      if (page === patternPage[0]) {
        const kumitateProcId = processMasterData?.find((proc) => proc.workType === WorkType.PaAssenble)?.id;
        const kumitateNm = selectedData?.details?.filter((item) => item.processId === kumitateProcId);
        if (kumitateNm !== undefined && kumitateNm?.length > 0) {
          setAssemblyChk(kumitateNm?.[0]?.isUsedForCalc);
          // setAssembly(kumitateNm[0].deviceId);
        }

        // 選択ボックスの組立装置一覧取得
        const assemblySochiTypeLst = sochiList?.filter((item) => item.processId === kumitateProcId && item.info.isUsed);
        let assemblySochiLst = [];
        for (let j = 0; j < assemblySochiTypeLst?.length; j++) {
          assemblySochiLst.push({
            value: assemblySochiTypeLst?.[j]?.id,
            label: assemblySochiTypeLst?.[j]?.name,
          });
        }
        setAssemblySochiList(assemblySochiLst);

        if (kumitateNm !== undefined && kumitateNm?.length > 0) {
          setAssembly(
            isExistSochi(assemblySochiLst, kumitateNm[0].deviceId)
              ? kumitateNm?.[0]?.deviceId
              : assemblySochiLst?.length > 0
              ? assemblySochiLst?.[0]?.value
              : 0
          );
        }
      }

      //検査装置情報取得
      const inspectionWorkType = page === patternPage[1] ? WorkType.SmInspection : WorkType.PaInspection;
      const inspectionProcId = processMasterData?.find((proc) => proc.workType === inspectionWorkType)?.id;
      // 検査
      const inspectionProNm = selectedData?.details?.filter((item) => item.processId === inspectionProcId);
      if (inspectionProNm !== undefined && inspectionProNm?.length > 0) {
        setInspectionChk(inspectionProNm?.[0]?.isUsedForCalc);
        // setInspection(inspectionProNm[0].deviceId);
      }

      // 選択ボックスの検査装置一覧取得
      const inspectionProSochiTypeLst = sochiList?.filter(
        (item) => item.processId === inspectionProcId && item.info.isUsed
      );
      let inspectionProSochiLst = [];
      for (let j = 0; j < inspectionProSochiTypeLst?.length; j++) {
        inspectionProSochiLst.push({
          value: inspectionProSochiTypeLst?.[j].id,
          label: inspectionProSochiTypeLst?.[j].name,
        });
      }
      setInspectionSochiList(inspectionProSochiLst);

      if (inspectionProNm !== undefined && inspectionProNm?.length > 0) {
        setInspection(
          isExistSochi(inspectionProSochiLst, inspectionProNm[0].deviceId)
            ? inspectionProNm?.[0]?.deviceId
            : inspectionProSochiLst?.length > 0
            ? inspectionProSochiLst?.[0]?.value
            : 0
        );
      }

      // 親部品の場合、梱包表示
      if (page === patternPage[0]) {
        const packingWorkType = WorkType.PaPacking;
        //梱包装置情報取得
        const packingProcId = processMasterData?.find((proc) => proc.workType === packingWorkType)?.id;
        // 梱包
        const packingNm = selectedData?.details?.filter((item) => item.processId == packingProcId);
        if (packingNm !== undefined && packingNm?.length > 0) {
          setPackingChk(packingNm?.[0]?.isUsedForCalc);
          // setPacking(packingNm[0].deviceId);
        }

        // 選択ボックスの梱包装置一覧取得
        // const packingCodeFilter = processMasterData?.filter((item) => item.processType == '梱包');
        // if (packingCodeFilter?.length > 0) konpoProcessCode = packingCodeFilter[0].code;
        const packingSochiTypeLst = sochiList?.filter((item) => item.processId == packingProcId && item.info.isUsed);
        let packingSochiLst = [];
        for (let j = 0; j < packingSochiTypeLst?.length; j++) {
          packingSochiLst.push({
            value: packingSochiTypeLst?.[j]?.id,
            label: packingSochiTypeLst?.[j]?.name,
          });
        }
        setPackingSochiList(packingSochiLst);

        if (packingNm !== undefined && packingNm?.length > 0) {
          setPacking(
            isExistSochi(packingSochiLst, packingNm[0].deviceId)
              ? packingNm[0].deviceId
              : packingSochiLst?.length > 0
              ? packingSochiLst[0]?.value
              : 0
          );
        }
      } else if (page === patternPage[1]) {
      }

      // 一覧から選択されたレコードの追加工程データ取得
      // const customProcId = processMasterData?.find(
      //   (proc) => proc.workType === (page === patternPage[0] ? WorkType.PaCustom : WorkType.SmCustom)
      // )?.id;

      // 追加工程種類マスターデータ取得
      const customProcess = processMasterData?.filter(
        (item) =>
          item.isAdditional &&
          item.info.isUsed &&
          item.workType === (page === patternPage[0] ? WorkType.PaCustom : WorkType.SmCustom)
      );
      if (customProcess !== undefined && customProcess?.length > 0) {
        const customGroupLst = [];
        // 追加工程種類マスターデータをループして選択されたレコードの追加工程データ設定
        for (let j = 0; j < customProcess?.length; j++) {
          // カスタム
          const selectedCustomInfoLst = selectedData?.details?.filter((item) => item.processId === customProcess[j].id);
          const processDetailValue = customProcess[j].details.processDetailTypes;
          const customLst = [];
          const customProcLstDisplay = [];
          let no = 0;
          let custom_divider = 4; // 1div5種類表示のため
          let custom_lastIndex = 0; //  5種類をdiv分けて設定するためのインデックス
          let customProFilter = [];
          let processId = customProcess[j]?.id; // IQX_WEBEST-259
          for (let i = 0; i < processDetailValue?.length; i++) {
            let deviceCode = '';
            let customerProType = sochiList?.filter(
              (item) =>
                item.processId === customProcess[j].id &&
                // && item.kouteiType == processDetailValue[i].detailType
                item?.details?.addProcessTypeId === processDetailValue[i].id &&
                item.info.isUsed
            );

            let selectedCustomInfo = [];
            selectedCustomInfo = selectedCustomInfoLst?.filter(
              (item) =>
                item.processId == customProcess[j].id &&
                // && item.addProType == processDetailValue[i].detailType
                item?.multiprocesses?.[0]?.processDetailTypeId === processDetailValue[i].id
            );
            if (selectedCustomInfo?.length > 0 && selectedCustomInfo[0].deviceId !== undefined) {
              deviceCode = selectedCustomInfo[0].deviceId;
            } else if (customerProType?.length > 0) {
              deviceCode = customerProType?.[0]?.id;
            }
            let customChecked = selectedCustomInfo?.length > 0 ? selectedCustomInfo[0].isUsedForCalc : false;

            let processDetailTypeId =
              selectedCustomInfo?.length > 0
                ? selectedCustomInfo[0].multiprocesses?.[0]?.processDetailTypeId
                : processDetailValue[i].id; // IQX_WEBEST-259

            // 該当装置の委託先フラグ
            let itakuFlag = sochiList?.filter(
              (item) =>
                item.processId === customProcess[j].id &&
                // item.kouteiType == processDetailValue[i].detailType &&
                item.id === deviceCode &&
                item.info.isUsed
            );
            // 該当装置の委託先名称
            // let itakuInfo = itakuFlag?.length > 0 ? itakuFlag[0].clientCode : '';
            let clientName =
              itakuFlag?.length > 0 ? getCustomerNameById(itakuFlag[0]?.details?.clientId, clientMaster) : '';
            // let companyCode = selectedCustomInfo?.length > 0 ? selectedCustomInfo[0].companyCode : '';
            // companyCode = companyCode == '' ? itakuInfo : companyCode;
            // 追加工程選択ボックスデータ準備
            let customerProTypeLst = [{ value: 0, label: '無し' }];
            if (customerProType != null) {
              for (let k = 0; k < customerProType?.length; k++) {
                customerProTypeLst.push({
                  value: customerProType[k].id,
                  label: customerProType[k].name,
                  // clientCode: customerProType[k].clientCode,
                  // itakuFlag: customerProType[k].itakuFlag,
                });
              }
            }

            customLst.push({
              no: i,
              checked: customChecked,
              deviceId: deviceCode,
              processId: processId,
              processDetailTypeId: processDetailTypeId,
              // companyCode: companyCode,
              clientName: clientName,
              customSochiLst: customerProTypeLst,
              // itakuFlag: itakuFlag?.length > 0 ? itakuFlag[0].itakuFlag : false,
            });

            // carouselのdivデータ準備（1div5種類表示）
            if (i == custom_divider) {
              if (i == 4) {
                customProFilter = customLst?.filter((item) => item.no >= custom_lastIndex && item.no <= custom_divider);
              } else {
                customProFilter = customLst?.filter((item) => item.no > custom_lastIndex && item.no <= custom_divider);
              }
              customProcLstDisplay.push({
                no: no,
                customLst: customProFilter,
              });
              custom_divider = custom_divider + 5;
              custom_lastIndex = i;
              no++;
            } else if (i + 1 == processDetailValue?.length) {
              // 最後のdiv設定
              if (i < 4) {
                // 5種類未満の場合
                customProFilter = customLst?.filter((item) => item.no <= processDetailValue?.length);
              } else {
                // 5種類以上の場合
                customProFilter = customLst?.filter(
                  (item) => item.no > custom_lastIndex && item.no < processDetailValue?.length
                );
              }

              customProcLstDisplay.push({
                no: no,
                customLst: customProFilter,
              });
              no++;
            }
          }
          customGroupLst.push({
            no: j,
            groupNm: customProcess[j].name,
            customTypeLst: customProcLstDisplay,
            groupDetailCnt: processDetailValue?.length,
          });
        }
        setCustomProcessLst(customGroupLst);
      }

      // 選択中である行の情報を画面表示に設定
      // 装置番号
      setPatternNo(selectedData.id);
      // 装置名称
      setPatternName(selectedData.name);
    } else {
      setListData([]);
    }
  };

  // iQ5工程データ設定
  const settingIQ5Process = (processMasterData, sochiList, selectedData) => {
    const handleProcess = (workType, setChkFunc, setProSochiListFunc, setFunc) => {
      const processId = processMasterData?.find((proc) => proc.workType === workType)?.id;
      const processDetail = selectedData?.details?.filter((item) => item.processId == processId);
  
      if (processDetail?.length > 0) {
        setChkFunc(processDetail[0].isUsedForCalc);
      }
  
      const sochiTypeList = sochiList?.filter((item) => item.processId == processId && item.info.isUsed);
      const sochiListData = sochiTypeList.map((sochi) => ({
        value: sochi.id,
        label: sochi.name,
      }));
      setProSochiListFunc(sochiListData);
  
      if (processDetail?.length > 0) {
        setFunc(
          isExistSochi(sochiListData, processDetail[0].deviceId)
            ? processDetail[0].deviceId
            : sochiListData.length > 0
            ? sochiListData[0].value
            : 0
        );
      }
    };
  
    handleProcess(WorkType.PaSteelCut, setSetsudanChk, setSetusdanProSochiList, setSetsudan);
    handleProcess(WorkType.PaSteelHole, setAnaChk, setAnaProSochiList, setAna);
    handleProcess(WorkType.PaSteelNotch, setKikkakeChk, setKikkakeProSochiList, setKikkake);
    handleProcess(WorkType.PaSteelBend, setMageChk, setMageProSochiList, setMage);
    handleProcess(WorkType.PaSteelMount, setToritsukeChk, setToritsukeProSochiList, setToritsuke);
    handleProcess(WorkType.PaSteelGroove, setKaisakiChk, setKaisakiProSochiList, setKaisaki);
    handleProcess(WorkType.PaSteelDebbur, setBaritoriChk, setBaritoriProSochiList, setBaritori);
    handleProcess(WorkType.PaSteelWeld, setYosetsuChk, setYosetsuProSochiList, setYosetsu);
    handleProcess(WorkType.PaSteelWeldFinish, setYosetsushiageChk, setYosetsushiageProSochiList, setYosetsushiage);
    handleProcess(WorkType.PaSteelArea, setHyomensekiChk, setHyomensekiProSochiList, setHyomenseki);
    handleProcess(WorkType.PaSteelWeight, setJuryoChk, setJuryoProSochiList, setJuryo);
  };
  

  //　iQ3工程データ設定
  const settingIQ3Process = (processMasterData, sochiList, selectedData) => {
    //プログラム装置情報取得
    const programId = processMasterData?.find((proc) => proc.workType === WorkType.SmProgram)?.id;
    const program = selectedData?.details?.filter((item) => item.processId == programId);
    if (program !== undefined && program?.length > 0) {
      setProgramChk(program[0].isUsedForCalc);
      // setProgram(program[0].deviceId);
    }

    // 選択ボックスのプログラム装置一覧取得
    const programSochiTypeLst = sochiList?.filter((item) => item.processId == programId && item.info.isUsed);
    let programSochiLst = [];
    for (let j = 0; j < programSochiTypeLst?.length; j++) {
      programSochiLst.push({
        value: programSochiTypeLst[j].id,
        label: programSochiTypeLst[j].name,
      });
    }
    setProgramProSochiList(programSochiLst);

    if (program !== undefined && program?.length > 0) {
      setProgram(
        isExistSochi(programSochiLst, program[0].deviceId)
          ? program[0].deviceId
          : programSochiLst?.length > 0
          ? programSochiLst[0]?.value
          : 0
      );
    }

    // レーザーブランク種類マスターデータ取得
    let blankProLst = [];
    const laserId = processMasterData?.find((proc) => proc.workType === WorkType.SmBlankLaser)?.id;
    const laser = selectedData?.details?.filter((item) => item.processId == laserId);
    // 選択ボックスのプログラム装置一覧取得
    const laserSochiTypeLst = sochiList?.filter((item) => item.processId == laserId && item.info.isUsed);
    let laserSochiLst = [];
    for (let j = 0; j < laserSochiTypeLst?.length; j++) {
      laserSochiLst.push({
        value: laserSochiTypeLst[j].id,
        label: laserSochiTypeLst[j].name,
      });
    }
    if (laser) {
      blankProLst.push({
        key: 0,
        checked: laser[0]?.isUsedForCalc,
        // deviceCodeKey: laser[0]?.deviceId,
        deviceCodeKey: isExistSochi(laserSochiLst, laser[0]?.deviceId)
          ? laser[0]?.deviceId
          : laserSochiLst?.length > 0
          ? laserSochiLst[0]?.value
          : 0,
        blankSochiLst: laserSochiLst,
        blankType: WorkType.SmBlankLaser,
      });
    }

    // パンチブランク種類マスターデータ取得
    const punchId = processMasterData?.find((proc) => proc.workType === WorkType.SmBlankPunch)?.id;
    const punch = selectedData?.details?.filter((item) => item.processId == punchId);
    // 選択ボックスのプログラム装置一覧取得
    const punchSochiTypeLst = sochiList?.filter((item) => item.processId == punchId && item.info.isUsed);
    let punchSochiLst = [];
    for (let j = 0; j < punchSochiTypeLst?.length; j++) {
      punchSochiLst.push({
        value: punchSochiTypeLst[j].id,
        label: punchSochiTypeLst[j].name,
      });
    }
    if (punch) {
      blankProLst.push({
        key: 1,
        checked: punch[0]?.isUsedForCalc,
        // deviceCodeKey: punch[0]?.deviceId,
        deviceCodeKey: isExistSochi(punchSochiLst, punch[0]?.deviceId)
          ? punch[0]?.deviceId
          : punchSochiLst?.length > 0
          ? punchSochiLst[0]?.value
          : 0,
        blankSochiLst: punchSochiLst,
        blankType: WorkType.SmBlankPunch,
      });
    }

    // 複合機ブランク種類マスターデータ取得
    const combiId = processMasterData?.find((proc) => proc.workType === WorkType.SmBlankCombi)?.id;
    const combi = selectedData?.details?.filter((item) => item.processId == combiId);
    // 選択ボックスのプログラム装置一覧取得
    const combiSochiTypeLst = sochiList?.filter((item) => item.processId == combiId && item.info.isUsed);
    let combiSochiLst = [];
    for (let j = 0; j < combiSochiTypeLst?.length; j++) {
      combiSochiLst.push({
        value: combiSochiTypeLst[j].id,
        label: combiSochiTypeLst[j].name,
      });
    }
    if (combi) {
      blankProLst.push({
        key: 2,
        checked: combi[0]?.isUsedForCalc,
        // deviceCodeKey: combi[0]?.deviceId,
        deviceCodeKey: isExistSochi(combiSochiLst, combi[0]?.deviceId)
          ? combi[0]?.deviceId
          : combiSochiLst?.length > 0
          ? combiSochiLst[0]?.value
          : 0,
        blankSochiLst: combiSochiLst,
        blankType: WorkType.SmBlankCombi,
      });
    }

    setBlankProcessLst(blankProLst);

    //２次加工装置情報取得
    const nijiId = processMasterData?.find((proc) => proc.workType === WorkType.SmSecondaryWork)?.id;
    const nijiProcess = selectedData?.details?.filter((item) => item.processId == nijiId);
    if (nijiProcess !== undefined && nijiProcess?.length > 0) {
      setSecondaryChk(nijiProcess[0]?.isUsedForCalc);
      // setSecondary(nijiProcess[0]?.deviceId);
    }

    // 選択ボックスの２次加工装置一覧取得
    const nijiSochiTypeLst = sochiList?.filter((item) => item.processId == nijiId && item.info.isUsed);
    let nijiSochiLst = [];
    for (let j = 0; j < nijiSochiTypeLst?.length; j++) {
      nijiSochiLst.push({
        value: nijiSochiTypeLst[j].id,
        label: nijiSochiTypeLst[j].name,
      });
    }
    setNijiProSochiList(nijiSochiLst);

    if (nijiProcess !== undefined && nijiProcess?.length > 0) {
      setSecondary(
        isExistSochi(nijiSochiLst, nijiProcess[0]?.deviceId)
          ? nijiProcess[0]?.deviceId
          : nijiSochiLst?.length > 0
          ? nijiSochiLst[0]?.value
          : 0
      );
    }

    //ベンディング装置情報取得
    const bendingId = processMasterData?.find((proc) => proc.workType === WorkType.SmBending)?.id;
    const bendingProcess = selectedData?.details?.filter((item) => item.processId == bendingId);
    if (bendingProcess !== undefined && bendingProcess?.length > 0) {
      setBendingChk(bendingProcess[0]?.isUsedForCalc);
      // setBending(bendingProcess[0]?.deviceId);
    }

    // 選択ボックスのベンディング装置一覧取得
    const bendingSochiTypeLst = sochiList?.filter((item) => item.processId == bendingId && item.info.isUsed);
    let bendingSochiLst = [];
    for (let j = 0; j < bendingSochiTypeLst?.length; j++) {
      bendingSochiLst.push({
        value: bendingSochiTypeLst[j].id,
        label: bendingSochiTypeLst[j].name,
      });
    }
    setBendingProSochiList(bendingSochiLst);

    if (bendingProcess !== undefined && bendingProcess?.length > 0) {
      setBending(
        isExistSochi(bendingSochiLst, bendingProcess[0]?.deviceId)
          ? bendingProcess[0]?.deviceId
          : bendingSochiLst?.length > 0
          ? bendingSochiLst[0]?.value
          : 0
      );
    }

    // バリ取種類マスターデータ取得
    let deburrProLst = [];
    // 手動種類マスターデータ取得
    const deburringManualId = processMasterData?.find((proc) => proc.workType === WorkType.SmDeburringManual)?.id;
    const deburringManual = selectedData?.details?.filter((item) => item.processId == deburringManualId);
    // 選択ボックスのプログラム装置一覧取得
    const deburringManualSochiTypeLst = sochiList?.filter(
      (item) => item.processId == deburringManualId && item.info.isUsed
    );
    let deburringManualSochiLst = [];
    for (let j = 0; j < deburringManualSochiTypeLst?.length; j++) {
      deburringManualSochiLst.push({
        value: deburringManualSochiTypeLst[j].id,
        label: deburringManualSochiTypeLst[j].name,
      });
    }
    if (deburringManual) {
      deburrProLst.push({
        key: 0,
        checked: deburringManual[0]?.isUsedForCalc,
        // deviceCodeKey: deburringManual[0]?.deviceId,
        deviceCodeKey: isExistSochi(deburringManualSochiLst, deburringManual[0]?.deviceId)
          ? deburringManual[0]?.deviceId
          : deburringManualSochiLst?.length > 0
          ? deburringManualSochiLst[0]?.value
          : 0,
        deburrSochiLst: deburringManualSochiLst,
        deburrType: WorkType.SmDeburringManual,
      });
    }

    // 自動種類マスターデータ取得
    const deburringAutoId = processMasterData?.find((proc) => proc.workType === WorkType.SmDeburringAuto)?.id;
    const deburringAuto = selectedData?.details?.filter((item) => item.processId == deburringAutoId);
    // 選択ボックスのプログラム装置一覧取得
    const deburringAutoSochiTypeLst = sochiList?.filter(
      (item) => item.processId == deburringAutoId && item.info.isUsed
    );
    let deburringAutoSochiLst = [];
    for (let j = 0; j < deburringAutoSochiTypeLst?.length; j++) {
      deburringAutoSochiLst.push({
        value: deburringAutoSochiTypeLst[j].id,
        label: deburringAutoSochiTypeLst[j].name,
      });
    }
    if (deburringAuto) {
      deburrProLst.push({
        key: 1,
        checked: deburringAuto[0]?.isUsedForCalc,
        // deviceCodeKey: deburringAuto[0]?.deviceId,
        deviceCodeKey: isExistSochi(deburringAutoSochiLst, deburringAuto[0]?.deviceId)
          ? deburringAuto[0]?.deviceId
          : deburringAutoSochiLst?.length > 0
          ? deburringAutoSochiLst[0]?.value
          : 0,
        deburrSochiLst: deburringAutoSochiLst,
        deburrType: WorkType.SmDeburringAuto,
      });
    }
    setDeburrProcessLst(deburrProLst);

    //シャーリング装置情報取得
    const shearingId = processMasterData?.find((proc) => proc.workType === WorkType.SmShearing)?.id;
    const shearingProcess = selectedData?.details?.filter((item) => item.processId == shearingId);
    if (shearingProcess !== undefined && shearingProcess?.length > 0) {
      setShearingChk(shearingProcess[0]?.isUsedForCalc);
      // setShearing(shearingProcess[0]?.deviceId);
    }

    // 選択ボックスのシャーリング装置一覧取得
    const shearingSochiTypeLst = sochiList?.filter((item) => item.processId == shearingId && item.info.isUsed);
    let shearingSochiLst = [];
    for (let j = 0; j < shearingSochiTypeLst?.length; j++) {
      shearingSochiLst.push({
        value: shearingSochiTypeLst[j].id,
        label: shearingSochiTypeLst[j].name,
      });
    }
    setShearingProSochiList(shearingSochiLst);

    if (shearingProcess !== undefined && shearingProcess?.length > 0) {
      setShearing(
        isExistSochi(shearingSochiLst, shearingProcess[0]?.deviceId)
          ? shearingProcess[0]?.deviceId
          : shearingSochiLst?.length > 0
          ? shearingSochiLst[0]?.value
          : 0
      );
    }
  };

  // 工程タイプより、マスタのレコードID
  const getMstProcessId = (workTypeId) => {
    const surProcId = processMaster?.find((proc) => proc.workType === workTypeId)?.id;
    return surProcId;
  };

  // コードで名称を取得
  function getNameByNo(no, Lists) {
    let retValue = '';
    if (no !== undefined && Lists !== undefined) {
      let info = Lists?.filter((info) => info.value === no);
      if (info?.length > 0) {
        retValue = info[0].label;
      }
    }
    return retValue;
  }
  // コードで名称を取得
  function getPatternNameByPatternNo(id, Lists) {
    let retValue = '';
    if (id) {
      let info = Lists?.filter((info) => info.id === id);
      if (info?.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  // お客様コードでお客様名称を取得
  function getCustomerNameById(id, Lists) {
    let retValue = '';
    if (id) {
      let info = Lists?.filter((info) => info.id === id);
      if (info?.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  // 溶接仕上のタイプを変更した場合
  const onChangeWeldFinishProcTypeVal = (typeVal, key, groupKey) => {
    const weldFinishProcessType = [...weldFinishProcessLst];
    const weldFinishProcLst = weldFinishProcessType[groupKey].weldFinishProcLst;
    let weldFinishProc = weldFinishProcLst?.filter((item) => item.key === key);
    weldFinishProc[0].processDetailCode = typeVal;
    setWeldFinishProcessLst(weldFinishProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter(
      (item) => item.processId === getMstProcessId(WorkType.SmWeldFinish)
    );
    targetInfo?.[0]?.multiprocesses?.forEach((element) => {
      if (element.processNo === key) {
        element.processDetailTypeId = typeVal;
        onChangeProcess(element);
      }
    });
  };

  // 表面処理のグループタイプを変更した場合、
  const onChangeSurfaceProcType = (inputVal, processNo, groupKey) => {
    let processDetailCode = '';
    const surfaceProcessType = [...surfaceProcessLst];
    const surfaceProcLst = surfaceProcessType[groupKey].surfaceProcLst;
    let surfaceProc = surfaceProcLst?.filter((item) => item.no === processNo);
    surfaceProc[0]['processDetailType'] = inputVal;
    if (inputVal == '0') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal0Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal0Lst?.[0]?.value;
      processDetailCode = surfaceProcessTypeVal0Lst?.[0]?.value;
    } else if (inputVal == '1') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal1Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal1Lst?.[0]?.value;
      processDetailCode = surfaceProcessTypeVal1Lst?.[0]?.value;
    } else if (inputVal == '2') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal2Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal2Lst?.[0]?.value;
      processDetailCode = surfaceProcessTypeVal2Lst?.[0]?.value;
    } else if (inputVal == '3' || inputVal == '4') {
      surfaceProc[0]['processDetailValLst'] = surfaceProcessTypeVal3Lst;
      surfaceProc[0]['processDetailCode'] = surfaceProcessTypeVal3Lst?.[0]?.value;
      processDetailCode = surfaceProcessTypeVal3Lst?.[0]?.value;
    }
    setSurfaceProcessLst(surfaceProcessType);

    // 表面処理
    let workType = page === patternPage[1] ? WorkType.SmSurface : WorkType.PaSurface;
    let targetInfo = selectedPatternInfo?.details?.filter((item) => item.processId === getMstProcessId(workType));
    targetInfo?.[0]?.multiprocesses?.forEach((element) => {
      if (element.processNo === processNo) {
        // element.processDetailType = type;
        // element.processDetailCode = processDetailCode;
        // selectedPatternInfo.details[targetInfo[0].processNo].multiprocesses[element.processNo] = element;
        element.processDetailTypeId = processDetailCode;
        element.processDetailGroup = inputVal;
        onChangeProcess(element);
      }
    });
  };

  // 表面処理のタイプを変更した場合、
  const onChangeSurfaceProcTypeVal = (inputVal, processNo, groupKey) => {
    const surfaceProcessType = [...surfaceProcessLst];
    const surfaceProcLst = surfaceProcessType[groupKey].surfaceProcLst;
    let surfaceProc = surfaceProcLst?.filter((item) => item.no === processNo);
    surfaceProc[0]['processDetailCode'] = inputVal;
    setSurfaceProcessLst(surfaceProcessType);

    // 表面処理
    let workType = page === patternPage[1] ? WorkType.SmSurface : WorkType.PaSurface;
    let targetInfo = selectedPatternInfo?.details?.filter((item) => item.processId === getMstProcessId(workType));
    targetInfo?.[0]?.multiprocesses?.forEach((element) => {
      if (element.processNo === processNo) {
        element.processDetailTypeId = inputVal;
        onChangeProcess(element);
      }
    });
  };

  // 追加工程のタイプを変更した場合、
  const onChangeAdditionalProType = (inputVal, no, processDetailTypeId, carouselKey, groupKey, processId) => {
    const customProcessType = [...customProcessLst];
    const customTypeLst = customProcessType[groupKey].customTypeLst;
    const customLst = customTypeLst[carouselKey].customLst;
    let custom = customLst?.filter((item) => item.no === no);
    custom[0]['deviceId'] = inputVal;

    //　選択された装置により該当する委託先情報を設定
    // let selectedCustomerCode = selectedPatternInfoBk?.details?.filter(
    //   (item) =>
    //     // カスタム
    //     item.processId === processId &&
    //     // item.processName == customProcessType[groupKey].groupNm &&
    //     item.deviceId === inputVal
    // );
    // let companyCode = '';
    // let itakuFlag = false;
    // if (selectedCustomerCode?.length > 0) {
    //   companyCode = selectedCustomerCode[0].companyCode;
    //   itakuFlag = selectedCustomerCode[0].itakuFlag;
    // } else {
    //   let info = custom[0].customSochiLst?.filter((info) => info.value === inputVal);
    //   if (info?.length > 0) {
    //     companyCode = info[0].companyCode;
    //     itakuFlag = info[0].itakuFlag;
    //   }
    // }
    // custom[0]['companyCode'] = companyCode;
    // custom[0]['itakuFlag'] = itakuFlag;

    custom[0]['clientName'] = getCustomerNameById(
      deviceMaster?.find((item) => item.id === inputVal)?.details?.clientId,
      clientMaster
    );

    setCustomProcessLst(customProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter(
      (item) =>
        item?.processId === processId &&
        // item.processName == customProcessType[groupKey].groupNm &&
        item?.multiprocesses?.[0].processDetailTypeId == processDetailTypeId
    );
    if (targetInfo?.length > 0) {
      targetInfo?.forEach((element) => {
        if (
          element.processId === processId &&
          element?.multiprocesses?.[0].processDetailTypeId === processDetailTypeId
        ) {
          element.deviceId = inputVal;
          // element.companyCode = companyCode;
          // element.itakuFlag = itakuFlag;
          onChangeProcess(element);
        }
      });
    }
  };

  // 追加工程のチェックボタンを押下
  const onChangeAdditionalProChecked = (typeVal, no, processDetailTypeId, carouselKey, groupKey, processId) => {
    const customProcessType = [...customProcessLst];
    const customTypeLst = customProcessType[groupKey].customTypeLst;
    const customLst = customTypeLst[carouselKey].customLst;
    let custom = customLst?.filter((item) => item.no === no);
    custom[0]['checked'] = typeVal;
    setCustomProcessLst(customProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter(
      (item) =>
        item?.processId === processId &&
        // item.processName == customProcessType[groupKey].groupNm &&
        item?.multiprocesses?.[0].processDetailTypeId == processDetailTypeId
    );
    if (targetInfo && targetInfo?.length > 0) {
      targetInfo?.forEach((element) => {
        if (
          element?.multiprocesses?.[0].processDetailTypeId == processDetailTypeId &&
          element.processId === processId
        ) {
          element.deviceId = custom[0]['deviceId'];
          element.isUsedForCalc = typeVal;
          onChangeProcess(element);
        }
      });
    } else {
      // 工程マスターから追加されて保存データにない場合 // IQX_WEBEST-259
      let multiProcessList = [];
      multiProcessList.push({
        processNo: 1,
        processDetailGroup: ProcessDetailGroup.None,
        processDetailTypeId: custom[0]?.processDetailTypeId,
      });
      let processList = {
        processId: custom[0]?.processId,
        deviceId: custom[0]?.deviceId,
        isUsedForCalc: typeVal,
        multiprocesses: multiProcessList,
      };
      const data = { ...selectedPatternInfo };
      data?.details?.push(processList);
      setSelectedPatternInfo(data);
      props.updateKouteiSentakuInfo(data, props.listData);
    }
  };

  // const getCustomerName = (e, item, carouselIndex, index) => {
  //   let isCustomSearch = item.customSochiLst?.length > 0 && item.itakuFlag ? true : false;
  //   if (isCustomSearch) {
  //     setCustomerModal(true);
  //     setAddProKey(item.key);
  //     setAddProDeviceCode(item.key);
  //     setAddProCarouselIndex(carouselIndex);
  //     setAddProIndex(index);
  //   } else {
  //     setCustomerModal(false);
  //   }
  // };

  // 委託先名を取得する
  // const getCustomerName = (clientId) => {
  //   return clientInfo?.filter((item) => item.id === clientId)?.[0]?.name;
  // };

  // // 委託先情報を取得する
  // const getItakusakiInfo = async () => {
  //   let result = await getClientInfo();
  //   // result = result?.filter((record) => record.clientType === ClientType.Contractor && record.info.isUsed);
  //   result = result?.filter((record) => isContractor(record.clientType) && record.info.isUsed);
  //   setClientInfo(result);
  // };

  const selectedCustomerData = (selectedCustomerData) => {
    const customProcessType = [...customProcessLst];
    const customTypeLst = customProcessType[addProIndex].customTypeLst;
    const customLst = customTypeLst[addProCarouselIndex].customLst;
    let custom = customLst?.filter((item) => item.id === addProKey);
    custom[0]['companyCode'] = selectedCustomerData.code;
    setCustomProcessLst(customProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter(
      (item) =>
        item.processId ===
        (page === patternPage[0] ? getMstProcessId(WorkType.PaCustom) : getMstProcessId(WorkType.SmCustom))
    );
    targetInfo?.forEach((element) => {
      if (element.processName == customProcessType[addProIndex].groupNm && element.deviceCodeKey === addProDeviceCode) {
        element.companyCode = selectedCustomerData.code;
        selectedPatternInfo.details[element.id] = element;
        onChangeProcess(element);
      }
    });
    setCustomerModal(false);
  };

  const onChangeBlankProType = (typeVal, key, deviceCode, type) => {
    const blankProcessType = [...blankProcessList];
    blankProcessType[key]['deviceCodeKey'] = typeVal;
    setBlankProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter((item) => item.processId === getMstProcessId(type));
    if (targetInfo && targetInfo?.length > 0) {
      targetInfo[0].deviceId = typeVal;
      onChangeProcess(targetInfo[0]);
    }
  };

  const onChangeBlankProChecked = (typeVal, key, deviceCode, type) => {
    const blankProcessType = [...blankProcessList];
    blankProcessType[key]['checked'] = typeVal;
    blankProcessType?.forEach((element) => {
      if (element.key !== key) {
        element.checked = false;
      }
    });
    setBlankProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter((item) => item.processId === getMstProcessId(type));
    if (targetInfo && targetInfo?.length > 0) {
      targetInfo[0].isUsedForCalc = typeVal;
      onChangeProcess(targetInfo[0]);
    }

    if (type !== WorkType.SmBlankLaser) {
      let laserTargetInfo = selectedPatternInfo?.details?.filter(
        (item) => item.processId === getMstProcessId(WorkType.SmBlankLaser)
      );
      if (laserTargetInfo && laserTargetInfo?.length > 0) {
        laserTargetInfo[0].isUsedForCalc = false;
        onChangeProcess(laserTargetInfo[0]);
      }
    }
    if (type !== WorkType.SmBlankPunch) {
      let punchTargetInfo = selectedPatternInfo?.details?.filter(
        (item) => item.processId === getMstProcessId(WorkType.SmBlankPunch)
      );
      if (punchTargetInfo && punchTargetInfo?.length > 0) {
        punchTargetInfo[0].isUsedForCalc = false;
        onChangeProcess(punchTargetInfo[0]);
      }
    }
    if (type !== WorkType.SmBlankCombi) {
      let combiTargetInfo = selectedPatternInfo?.details?.filter(
        (item) => item.processId === getMstProcessId(WorkType.SmBlankCombi)
      );
      if (combiTargetInfo && combiTargetInfo?.length > 0) {
        combiTargetInfo[0].isUsedForCalc = false;
        onChangeProcess(combiTargetInfo[0]);
      }
    }
  };

  const onChangeDeburrProType = (typeVal, key, deviceCode, type) => {
    const blankProcessType = [...deburrProcessList];
    blankProcessType[key]['deviceCodeKey'] = typeVal;
    setDeburrProcessLst(blankProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter((item) => item.processId === getMstProcessId(type));
    if (targetInfo && targetInfo?.length > 0) {
      targetInfo[0].deviceId = typeVal;
      onChangeProcess(targetInfo[0]);
    }
  };

  const onChangeDeburrProChecked = (typeVal, key, deviceCode, type) => {
    const deburrProcessType = [...deburrProcessList];
    deburrProcessType[key]['checked'] = typeVal;
    setDeburrProcessLst(deburrProcessType);

    let targetInfo = selectedPatternInfo?.details?.filter((item) => item.processId === getMstProcessId(type));
    if (targetInfo && targetInfo?.length > 0) {
      targetInfo[0].isUsedForCalc = typeVal;
      onChangeProcess(targetInfo[0]);
    }
  };

  //工程パターン選択ボックス操作
  const onChangeProcessPatternInfo = (id) => {
    setPatternNo(id);
    // if (props.pmxMode != undefined) {
    //   props.updatedProcessPatternNo = id;
    // }

    let patternList = props.listData;
    const selectedPatternInfo = patternList?.filter((item) => item.id == id);
    setSelectedPatternInfo(selectedPatternInfo[0]);
    if (selectedPatternInfo?.length > 0) {
      if (props.isParameter) {
        // 選択したパターンキーより一覧の選択行を変更
        props.updateSelectedData(selectedPatternInfo[0]);
      } else {
        // 選択したパターンキーより親部品工程選択・子部品工程選択の選択パターン番号を変更
        props.updateKouteiSentakuInfo(selectedPatternInfo[0], patternList);
      }
    }
  };

  //チェックボックスや選択ボックスの操作により工程パターン情報更新
  const onChangeProcess = async (updateData) => {
    if (!props.isParameter) {
      const motoSelectedPatternInfo = { ...selectedPatternInfoBk };
      const curSelectedPatternInfo = { ...selectedPatternInfo };
      //  curSelectedPatternInfo.details[updateData.processId] = updateData;
      if (JSON.stringify(motoSelectedPatternInfo) === JSON.stringify(curSelectedPatternInfo)) {
        props.updateKouteiSentakuInfo(selectedPatternInfo, patternList);
      } else {
        let data = [];
        let newPatternInfo = [];
        let emptyInfo = props.listData?.filter((item) => item.name === '');
        if (emptyInfo?.length > 0) {
          emptyInfo[0].details = curSelectedPatternInfo.details;
          const updatedItems = [...props.listData];
          const index = updatedItems?.findIndex((item) => item.id === emptyInfo[0].id);
          updatedItems[index] = emptyInfo[0];
          newPatternInfo = { ...emptyInfo };
          data = [...updatedItems];
        } else {
          const motoListData = [...props.listData];
          newPatternInfo.push({
            id: 0,
            name: '',
            class: getCurrentServiceClass(),
            details: curSelectedPatternInfo.details,
            modified: curSelectedPatternInfo?.modified,
          });
          data = [newPatternInfo[0], ...motoListData];
        }
        setPatternList(data);
        // getPatternList(data);
        setPatternNo(newPatternInfo[0].id);
        setSelectedPatternInfo(newPatternInfo[0]);
        props.updateKouteiSentakuInfo(newPatternInfo[0], data);
      }
    }
  };

  // 工程パターンのルートパスで「親部品／板金」の判断
  const getCurrentServiceClass = () => {
    const currentRoute = location.pathname;
    if (currentRoute?.includes('parent')) {
      return ServiceClass.Parent;
    } else if (currentRoute?.includes('iq3')) {
      return ServiceClass.SheetMetal;
    } else if (currentRoute?.includes('iq5')) {
      return ServiceClass.Beams;
    }
  };

  // 装置IDの存在チェック
  const isExistSochi = (soChiList, soChiId) => {
    return soChiList?.findIndex((item) => item.value === soChiId) > -1;
  };

  // 工程パターンの存在チェック
  const isExistPattern = (patternList, patternId) => {
    return patternList?.findIndex((item) => item.id === patternId) > -1;
  };

  useImperativeHandle(
    ref,
    () => ({
      getUpdatePatternInfo: () => {
        selectedPatternInfo.modified = today;
        return selectedPatternInfo;
      },
      getPatternNo: () => {
        return patternNo;
      },
      getInputItemRef: () => {
        return inputItemRef;
      },
    }),
    [selectedPatternInfo, inputItemRef]
  );

  const standardItem = (
    <>
      {/* IQ5_切断 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          切断
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={setsudanChk}
            onChange={(e) => {
              setSetsudanChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelCut)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="setsudan"
              name="setsudan"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={setsudan || ''}
              options={setusdanProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelCut)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(setsudan, setusdanProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* IQ5_穴 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          穴
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={anaChk}
            onChange={(e) => {
              setAnaChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelHole)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="ana"
              name="ana"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={ana || ''}
              options={anaProSochiList}
              onChange={(e) => {
                setAna(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelHole)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(ana, anaProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_切欠 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          切欠
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={kikkakeChk}
            onChange={(e) => {
              setKikkakeChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelNotch)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="kikkake"
              name="kikkake"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={kikkake || ''}
              options={kikkakeProSochiList}
              onChange={(e) => {
                setKikkake(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelNotch)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(kikkake, kikkakeProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_曲げ */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          曲げ
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={mageChk}
            onChange={(e) => {
              setMageChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelBend)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="mage"
              name="mage"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={mage || ''}
              options={mageProSochiList}
              onChange={(e) => {
                setMage(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelBend)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(mage, mageProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_取付 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          取付
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={toritsukeChk}
            onChange={(e) => {
              setToritsukeChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelMount)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="toritsuke"
              name="toritsuke"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={toritsuke || ''}
              options={toritsukeProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelMount)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(toritsuke, toritsukeProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_開先 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          開先
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={kaisakiChk}
            onChange={(e) => {
              setKaisakiChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelGroove)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="kaisaki"
              name="kaisaki"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={kaisaki || ''}
              options={kaisakiProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelGroove)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(kaisaki, kaisakiProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_バリ取り */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          バリ取り
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={baritoriChk}
            onChange={(e) => {
              setBaritoriChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelDebbur)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="baritori"
              name="baritori"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={baritori || ''}
              options={baritoriProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelDebbur)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(baritori, baritoriProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_溶接 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          溶接
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={yosetsuChk}
            onChange={(e) => {
              setYosetsuChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelWeld)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="yosetsu"
              name="yosetsu"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={yosetsu || ''}
              options={yosetsuProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelWeld)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(yosetsu, yosetsuProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_溶接仕上 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          溶接仕上
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={yosetsushiageChk}
            onChange={(e) => {
              setYosetsushiageChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelWeldFinish)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="yosetsushiage"
              name="yosetsushiage"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={yosetsushiage || ''}
              options={yosetsushiageProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelWeldFinish)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(yosetsushiage, yosetsushiageProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_表面積 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          表面積
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={hyomensekiChk}
            onChange={(e) => {
              setHyomensekiChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelArea)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="hyomenseki"
              name="hyomenseki"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={hyomenseki || ''}
              options={hyomensekiProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelArea)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(hyomenseki, hyomensekiProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>

      {/* IQ5_重量 */}
      <Row style={{ height: 37, display: page === patternPage[3] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          重量
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={juryoChk}
            onChange={(e) => {
              setJuryoChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaSteelWeight)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="juryo"
              name="juryo"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={juryo || ''}
              options={juryoProSochiList}
              onChange={(e) => {
                setSetsudan(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaSteelWeight)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(juryo, juryoProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* IQ3_プログラム */}
      <Row style={{ height: 37, display: page === patternPage[1] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          プログラム
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderTop: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={programChk}
            onChange={(e) => {
              setProgramChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.SmProgram)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="program"
              name="program"
              ref={tableRef}
              style={{ marginTop: 5 }}
              value={program || ''}
              options={programProSochiList}
              onChange={(e) => {
                setProgram(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.SmProgram)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(program, programProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderTop: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* IQ3_ブランク */}
      <Row style={{ height: 37, display: page === patternPage[1] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          ブランク
        </Col>
        <Col span={20} style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}>
          <List
            className="process-pattern-list"
            grid={{ gutter: 16, column: 5 }}
            dataSource={blankProcessList}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ height: 37 }}>
                  <Col
                    className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
                    style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
                  >
                    <Checkbox
                      style={{ marginLeft: 3, marginRight: 10 }}
                      type="checkbox"
                      disabled={!editMode}
                      checked={item.checked}
                      onChange={(e) =>
                        onChangeBlankProChecked(e.target.checked, item.key, item.deviceCodeKey, item.blankType)
                      }
                    ></Checkbox>
                    {editMode ? (
                      <Select
                        className="pdetail-ant-select-tsuikaPro"
                        style={{ marginTop: 5 }}
                        id="deviceName"
                        name="deviceName"
                        ref={tableRef}
                        value={item.deviceCodeKey || ''}
                        options={item.blankSochiLst}
                        onChange={(e) => onChangeBlankProType(e, item.key, item.key, item.blankType)}
                      />
                    ) : (
                      <>{getNameByNo(item.deviceCodeKey, item.blankSochiLst)}</>
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      {/* IQ3_2次加工 */}
      <Row style={{ height: 37, display: page === patternPage[1] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          2次加工
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={secondaryChk}
            onChange={(e) => {
              setSecondaryChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.SmSecondaryWork)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="secondary"
              name="secondary"
              style={{ marginTop: 5 }}
              ref={tableRef}
              value={secondary || ''}
              options={nijiProSochiList}
              onChange={(e) => {
                setSecondary(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.SmSecondaryWork)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(secondary, nijiProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* IQ3_ベンディング */}
      <Row style={{ height: 37, display: page === patternPage[1] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          ベンディング
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={bendingChk}
            onChange={(e) => {
              setBendingChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.SmBending)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="bending"
              name="bending"
              style={{ marginTop: 5 }}
              ref={tableRef}
              value={bending || ''}
              options={bendingProSochiList}
              onChange={(e) => {
                setBending(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.SmBending)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(bending, bendingProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        ></Col>
      </Row>
      {/* IQ3_バリ取り */}
      <Row style={{ height: 37, display: page === patternPage[1] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            height: '100%',
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          バリ取
        </Col>
        <Col span={20} style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}>
          <List
            className="process-pattern-list"
            grid={{ gutter: 16, column: 5 }}
            dataSource={deburrProcessList}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ height: 37 }}>
                  <Col
                    className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
                    style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
                  >
                    <Checkbox
                      style={{ marginLeft: 3, marginRight: 10 }}
                      type="checkbox"
                      disabled={!editMode}
                      checked={item.checked}
                      onChange={(e) =>
                        onChangeDeburrProChecked(e.target.checked, item.key, item.deviceCodeKey, item.deburrType)
                      }
                    ></Checkbox>
                    {editMode ? (
                      <Select
                        className="pdetail-ant-select-tsuikaPro"
                        style={{ marginTop: 5 }}
                        id="deviceName"
                        name="deviceName"
                        ref={tableRef}
                        value={item.deviceCodeKey || ''}
                        options={item.deburrSochiLst}
                        onChange={(e) => onChangeDeburrProType(e, item.key, item.deviceCodeKey, item.deburrType)}
                      />
                    ) : (
                      <>{getNameByNo(item.deviceCodeKey, item.deburrSochiLst)}</>
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      {/* 溶接 / 溶接仕上 */}
      {page === patternPage[1] ? (
        <>
          {/* 溶接 */}
          <Row style={{ height: 37 }}>
            <Col
              span={4}
              className="parent-pdetail-tbl-val-title"
              style={{
                padding: 5,
                borderTop: '1px solid #a5a5a5',
                borderLeft: '1px solid #a5a5a5',
                borderRight: '1px solid #a5a5a5',
              }}
            >
              溶接
            </Col>
            <Col
              span={4}
              className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
              style={{
                height: '100%',
                verticalAlign: 'middle',
                padding: 3,
                borderTop: page === patternPage[0] ? '1px solid #a5a5a5' : 'none',
                borderBottom: '1px solid #a5a5a5',
                borderRight: '1px solid #a5a5a5',
              }}
            >
              <Checkbox
                style={{ marginRight: 10 }}
                type="checkbox"
                disabled={!editMode}
                checked={weldingChk}
                onChange={(e) => {
                  setWeldingChk(e.target.checked);
                  let targetInfo = selectedPatternInfo?.details?.filter(
                    (item) => item.processId === getMstProcessId(WorkType.SmWelding)
                  );
                  if (targetInfo && targetInfo?.length > 0) {
                    targetInfo[0].isUsedForCalc = e.target.checked;
                    onChangeProcess(targetInfo[0]);
                  }
                }}
              ></Checkbox>
              {editMode ? (
                <Select
                  className="pdetail-ant-select"
                  id="welding"
                  name="welding"
                  style={{ marginTop: 5 }}
                  ref={tableRef}
                  value={welding || ''}
                  options={weldingSochiList}
                  onChange={(e) => {
                    setWelding(e);
                    let targetInfo = selectedPatternInfo?.details?.filter(
                      (item) => item.processId === getMstProcessId(WorkType.SmWelding)
                    );
                    if (targetInfo && targetInfo?.length > 0) {
                      targetInfo[0].deviceId = e;
                      onChangeProcess(targetInfo[0]);
                    }
                  }}
                />
              ) : (
                <>{getNameByNo(welding, weldingSochiList)}</>
              )}
            </Col>
            <Col
              span={16}
              className="parent-pdetail-tbl-td"
              style={{
                borderTop: page === patternPage[0] ? '1px solid #a5a5a5' : 'none',
                borderRight: '1px solid #a5a5a5',
              }}
            ></Col>
          </Row>
          {/* 溶接仕上 */}
          <Row style={{ height: 'auto' }}>
            <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5, border: '1px solid #a5a5a5' }}>
              溶接仕上
            </Col>
            <Col
              span={4}
              className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
              style={{
                verticalAlign: 'middle',
                padding: 3,
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #a5a5a5',
                borderRight: '1px solid #a5a5a5',
              }}
            >
              <Checkbox
                style={{ marginRight: 10 }}
                type="checkbox"
                disabled={!editMode}
                checked={weldFinishChk}
                onChange={(e) => {
                  setWeldFinishChk(e.target.checked);
                  let targetInfo = selectedPatternInfo?.details?.filter(
                    (item) => item.processId === getMstProcessId(WorkType.SmWeldFinish)
                  );
                  if (targetInfo && targetInfo?.length > 0) {
                    targetInfo[0].isUsedForCalc = e.target.checked;
                    onChangeProcess(targetInfo[0]);
                  }
                }}
              ></Checkbox>
              {editMode ? (
                <Select
                  className="pdetail-ant-select"
                  id="weldFinish"
                  name="weldFinish"
                  ref={tableRef}
                  value={weldFinish || ''}
                  options={weldFinishSochiList}
                  onChange={(e) => {
                    setWeldFinish(e);
                    let targetInfo = selectedPatternInfo?.details?.filter(
                      (item) => item.processId === getMstProcessId(WorkType.SmWeldFinish)
                    );
                    if (targetInfo && targetInfo?.length > 0) {
                      targetInfo[0].deviceId = e;
                      onChangeProcess(targetInfo[0]);
                    }
                  }}
                />
              ) : (
                <>{getNameByNo(weldFinish, weldFinishSochiList)}</>
              )}
            </Col>
            <Col
              id={props.isAdd ? 'pattern-weldFinish-carousel-addForm' : 'pattern-weldFinish-carousel'}
              span={16}
              className="parent-pdetail-tbl-td-mul"
              style={{ borderRight: '1px solid #a5a5a5', borderBottom: '1px solid #a5a5a5' }}
            >
              <Carousel
                ref={caroselRef}
                className="pattern-weldFinish-carousel"
                arrows
                prevArrow={
                  <CarouselPrevArrow
                    activeCarouselIndex={0}
                    targetDivCnt={weldFinishProcessLst?.length}
                    name={props.isAdd ? 'pattern-weldFinish-carousel-addForm' : 'pattern-weldFinish-carousel'}
                  />
                }
                nextArrow={
                  <CarouselNextArrow
                    activeCarouselIndex={0}
                    targetDivCnt={weldFinishProcessLst?.length}
                    name={props.isAdd ? 'pattern-weldFinish-carousel-addForm' : 'pattern-weldFinish-carousel'}
                  />
                }
              >
                {weldFinishProcessLst?.map((dis, index) => {
                  return (
                    <div>
                      <List
                        id="carousel-div"
                        className="process-pattern-list"
                        grid={{ gutter: 16, column: 4 }}
                        dataSource={dis.weldFinishProcLst}
                        renderItem={(item) => (
                          <List.Item>
                            <Row style={{ height: 37 }}>
                              <Col className="parent-pdetail-tbl-subtitle">
                                <label style={{ paddingTop: 7 }}>{item.title}</label>
                              </Col>
                            </Row>
                            <Row style={{ height: 37 }}>
                              <Col
                                className={'parent-pdetail-tbl-td-mul'}
                                style={{ verticalAlign: 'middle', textAlign: 'center' }}
                              >
                                {editMode ? (
                                  <Select
                                    style={{ paddingTop: 4 }}
                                    className="pdetail-ant-select"
                                    id="weldFinishProc1"
                                    name="weldFinishProc1"
                                    ref={tableRef}
                                    value={item.processDetailCode || 0}
                                    options={weldFinishProcessTypeLst}
                                    onChange={(e) => onChangeWeldFinishProcTypeVal(e, item.key, index)}
                                  />
                                ) : (
                                  <label style={{ paddingTop: 7 }}>
                                    {getNameByNo(item.processDetailCode, weldFinishProcessTypeLst)}
                                  </label>
                                )}
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {/* 表面処理 */}
      <Row style={{ height: 'auto', border: '1px solid #a5a5a5', display: page === patternPage[3] ? 'none' : ''  }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
            borderBottom: '1px solid #a5a5a5',
          }}
        >
          表面処理
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            verticalAlign: 'middle',
            padding: 3,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={surfaceChk}
            onChange={(e) => {
              setSurfaceChk(e.target.checked);
              // 表面処理
              let workType = page === patternPage[1] ? WorkType.SmSurface : WorkType.PaSurface;
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(workType)
              );

              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="processDetailType"
              name="processDetailType"
              ref={tableRef}
              value={surface || ''}
              options={surfaceSochiList}
              onChange={(e) => {
                setSurface(e);
                // 表面処理
                let workType = page === patternPage[1] ? WorkType.SmSurface : WorkType.PaSurface;
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(workType)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(surface, surfaceSochiList)}</>
          )}
        </Col>
        <Col
          id={props.isAdd ? 'pattern-surface-carousel-addForm' : 'pattern-surface-carousel'}
          span={16}
          className="parent-pdetail-tbl-td"
          style={{ borderRight: '1px solid #a5a5a5', borderBottom: '1px solid #a5a5a5' }}
        >
          <Carousel
            ref={inputFile}
            className="pattern-surface-carousel"
            arrows
            prevArrow={
              <CarouselPrevArrow
                activeCarouselIndex={1}
                targetDivCnt={surfaceProcessLst?.length}
                name={props.isAdd ? 'pattern-surface-carousel-addForm' : 'pattern-surface-carousel'}
              />
            }
            nextArrow={
              <CarouselNextArrow
                activeCarouselIndex={1}
                targetDivCnt={surfaceProcessLst?.length}
                name={props.isAdd ? 'pattern-surface-carousel-addForm' : 'pattern-surface-carousel'}
              />
            }
          >
            {surfaceProcessLst?.map((dis, index) => {
              return (
                <div>
                  <List
                    className="process-pattern-list"
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={dis.surfaceProcLst}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ height: 37 }}>
                          <Col className="parent-pdetail-tbl-subtitle" style={{ borderBottom: '1px solid #a5a5a5' }}>
                            <label style={{ paddingTop: 7 }}>{item.title}</label>
                          </Col>
                        </Row>
                        <Row style={{ height: 37 }}>
                          <Col
                            className="parent-pdetail-tbl-td"
                            style={{ verticalAlign: 'middle', textAlign: 'center', borderRight: '1px solid #a5a5a5' }}
                          >
                            {editMode ? (
                              <Select
                                style={{ paddingTop: 4 }}
                                className="pdetail-ant-select"
                                id="processDetailType"
                                name="processDetailType"
                                ref={tableRef}
                                value={item.processDetailType || '無し'}
                                options={surfaceProcessTypeLst}
                                onChange={(e) => onChangeSurfaceProcType(e, item.no, index)}
                              />
                            ) : (
                              <label style={{ paddingTop: 7 }}>
                                {getNameByNo(item.processDetailType, surfaceProcessTypeLst)}
                              </label>
                            )}
                          </Col>
                        </Row>
                        <Row style={{ height: 37 }}>
                          <Col
                            className="parent-pdetail-tbl-td-mul"
                            style={{ verticalAlign: 'middle', textAlign: 'center', borderRight: '1px solid #a5a5a5' }}
                          >
                            {editMode ? (
                              <Select
                                style={{ paddingTop: 4 }}
                                id="processDetailCode"
                                name="processDetailCode"
                                ref={tableRef}
                                value={item.processDetailCode || 0}
                                options={item.processDetailValLst}
                                onChange={(e) => onChangeSurfaceProcTypeVal(e, item.no, index)}
                                className={
                                  item.processDetailCode == surfaceProc0List[0].value
                                    ? 'input-non-editable pdetail-ant-select'
                                    : 'input-editable pdetail-ant-select'
                                }
                              />
                            ) : (
                              <label style={{ paddingTop: 7 }}>
                                {getNameByNo(item.processDetailCode, item.processDetailValLst)}
                              </label>
                            )}
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
      {/* IQ3_シャーリング */}
      <Row style={{ height: 37, display: page === patternPage[1] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          シャーリング
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={shearingChk}
            onChange={(e) => {
              setShearingChk(e.target.checked);
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.SmShearing)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="shearing"
              name="shearing"
              style={{ marginTop: 5 }}
              ref={tableRef}
              value={shearing || ''}
              options={shearingProSochiList}
              onChange={(e) => {
                setShearing(e);
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.SmShearing)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(shearing, shearingProSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}
        ></Col>
      </Row>
      {/* 組立*/}
      <Row style={{ height: 37, display: page === patternPage[0] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          組立
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={assemblyChk}
            onChange={(e) => {
              setAssemblyChk(e.target.checked);
              // 組立
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaAssenble)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="assembly"
              name="assembly"
              style={{ marginTop: 5 }}
              ref={tableRef}
              value={assembly || ''}
              options={assemblySochiList}
              onChange={(e) => {
                setAssembly(e);
                // 組立
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaAssenble)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(assembly, assemblySochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}
        ></Col>
      </Row>
      {/** IQX_WEBEST-316 親部品画面、工程表示順変更のお願い */}
      {/* 検査*/}
      <Row style={{ height: 37, display: page === patternPage[3] ? 'none' : ''  }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          検査
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={inspectionChk}
            onChange={(e) => {
              setInspectionChk(e.target.checked);
              // 検査
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) =>
                  item.processId ===
                  getMstProcessId(page === patternPage[1] ? WorkType.SmInspection : WorkType.PaInspection)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="inspection"
              name="inspection"
              style={{ marginTop: 5 }}
              ref={tableRef}
              value={inspection || ''}
              options={inspectionSochiList}
              onChange={(e) => {
                setInspection(e);
                // 検査
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) =>
                    item.processId ===
                    getMstProcessId(page === patternPage[1] ? WorkType.SmInspection : WorkType.PaInspection)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(inspection, inspectionSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}
        ></Col>
      </Row>
      {/* 梱包*/}
      <Row style={{ height: 37, display: page === patternPage[0] ? '' : 'none' }}>
        <Col
          span={4}
          className="parent-pdetail-tbl-val-title"
          style={{
            padding: 5,
            borderTop: '1px solid #a5a5a5',
            borderLeft: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          梱包
        </Col>
        <Col
          span={4}
          className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
          style={{
            height: '100%',
            verticalAlign: 'middle',
            padding: 3,
            borderBottom: '1px solid #a5a5a5',
            borderRight: '1px solid #a5a5a5',
          }}
        >
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            disabled={!editMode}
            checked={packingChk}
            onChange={(e) => {
              setPackingChk(e.target.checked);
              // 梱包
              let targetInfo = selectedPatternInfo?.details?.filter(
                (item) => item.processId === getMstProcessId(WorkType.PaPacking)
              );
              if (targetInfo && targetInfo?.length > 0) {
                targetInfo[0].isUsedForCalc = e.target.checked;
                onChangeProcess(targetInfo[0]);
              }
            }}
          ></Checkbox>
          {editMode ? (
            <Select
              className="pdetail-ant-select"
              id="packing"
              name="packing"
              style={{ marginTop: 5 }}
              ref={tableRef}
              value={packing || ''}
              options={packingSochiList}
              onChange={(e) => {
                setPacking(e);
                // 梱包
                let targetInfo = selectedPatternInfo?.details?.filter(
                  (item) => item.processId === getMstProcessId(WorkType.PaPacking)
                );
                if (targetInfo && targetInfo?.length > 0) {
                  targetInfo[0].deviceId = e;
                  onChangeProcess(targetInfo[0]);
                }
              }}
            />
          ) : (
            <>{getNameByNo(packing, packingSochiList)}</>
          )}
        </Col>
        <Col
          span={16}
          className="parent-pdetail-tbl-td"
          style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}
        ></Col>
      </Row>
      {/* 追加工程 */}
      {customProcessLst?.map((customGroup, index) => {
        return (
          <Row style={{ height: 'auto' }}>
            <Col
              span={4}
              className="parent-pdetail-tbl-val-title"
              style={{
                padding: 5,
                borderTop: '1px solid #a5a5a5',
                borderRight: '1px solid #a5a5a5',
                borderBottom: '1px solid #a5a5a5',
              }}
            >
              {customGroup.groupNm}
            </Col>
            <Col
              id={props.isAdd ? 'pattern-custom-carousel-addForm' + index : 'pattern-custom-carousel' + index}
              span={20}
              style={{ borderBottom: '1px solid #a5a5a5', borderRight: '1px solid #a5a5a5' }}
            >
              <Carousel
                ref={inputFile}
                className="pattern-custom-carousel"
                arrows
                prevArrow={
                  <CarouselPrevArrow
                    activeCarouselIndex={index}
                    targetDivCnt={customGroup.customTypeLst?.length}
                    isCustom={true}
                    name={props.isAdd ? 'pattern-custom-carousel-addForm' + index : 'pattern-custom-carousel' + index}
                  />
                }
                nextArrow={
                  <CarouselNextArrow
                    activeCarouselIndex={index}
                    targetDivCnt={customGroup.customTypeLst?.length}
                    isCustom={true}
                    name={props.isAdd ? 'pattern-custom-carousel-addForm' + index : 'pattern-custom-carousel' + index}
                  />
                }
              >
                {customGroup.customTypeLst?.map((dis, carouselIndex) => {
                  return (
                    <div>
                      <List
                        className="process-pattern-list"
                        grid={{ gutter: 16, column: 5 }}
                        dataSource={dis.customLst}
                        renderItem={(item) => (
                          <List.Item>
                            <Row style={{ height: 37 }}>
                              <Col
                                className="parent-pdetail-tbl-td parent-pdetail-tbl-td-align-center"
                                style={{ verticalAlign: 'middle', padding: 3, borderRight: '1px solid #a5a5a5' }}
                              >
                                <Checkbox
                                  style={{ marginLeft: 3, marginRight: 10 }}
                                  type="checkbox"
                                  disabled={!editMode}
                                  checked={item.checked}
                                  onChange={(e) =>
                                    onChangeAdditionalProChecked(
                                      e.target.checked,
                                      item.no,
                                      item.processDetailTypeId,
                                      carouselIndex,
                                      index,
                                      item.processId
                                    )
                                  }
                                ></Checkbox>
                                {editMode ? (
                                  <Select
                                    className="pdetail-ant-select-tsuikaPro"
                                    style={{ marginTop: 0 }}
                                    id="deviceName"
                                    name="deviceName"
                                    ref={tableRef}
                                    value={item.deviceId || '無し'}
                                    options={item.customSochiLst}
                                    onChange={(e) =>
                                      onChangeAdditionalProType(
                                        e,
                                        item.no,
                                        item.processDetailTypeId,
                                        carouselIndex,
                                        index,
                                        item.processId
                                      )
                                    }
                                  />
                                ) : (
                                  <>{item.deviceId ? getNameByNo(item.deviceId, item.customSochiLst) : '無し'}</>
                                )}
                              </Col>
                            </Row>
                            <Row style={{ height: 37 }}>
                              <Col
                                className="parent-pdetail-tbl-td-tsuikaPro parent-pdetail-tbl-td-align-center"
                                style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #a5a5a5',
                                }}
                              >
                                {/* {editMode ? (
                                  <>
                                    <Button
                                      icon={<SearchOutlined />}
                                      size="small"
                                      onClick={(e) => getCustomerName(e, item, carouselIndex, index)}
                                      type="link"
                                      style={{ marginLeft: 2, marginRight: 3 }}
                                      // disabled={!outSupplier1Chk}
                                    ></Button>
                                    <Tag
                                      className="pdetail-ant-tag"
                                      id="outSupplier1Com"
                                      name="outSupplier1Com"
                                      ref={tableRef}
                                    >
                                      <label style={{ width: '80%' }}>
                                        {getCustomerNameByCode(item.companyCode, clientInfo)}
                                      </label>
                                    </Tag>
                                  </>
                                ) : (
                                  <label style={{ textAlign: 'left', marginLeft: 28 }}>
                                    {getCustomerNameByCode(item.clientCode, clientInfo)}
                                  </label>
                                )} */}
                                {editMode ? (
                                  <Tag
                                    className="pdetail-ant-tag"
                                    id="outSupplier1Com"
                                    name="outSupplier1Com"
                                    ref={tableRef}
                                    style={{ width: '82%' }}
                                  >
                                    {/* <label style={{ width: '80%' }}>{item.clientName}</label> */}
                                    <span class="lbl-ellipsis" title={item.clientName}>
                                      {item.clientName}
                                    </span>
                                  </Tag>
                                ) : (
                                  // <label style={{ textAlign: 'left', marginLeft: 28 }}>{item.clientName}</label>
                                  <span
                                    class="lbl-ellipsis"
                                    style={{ textAlign: 'left', marginLeft: 28, marginRight: 10 }}
                                    title={item.clientName}
                                  >
                                    {item.clientName}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        );
      })}
    </>
  );

  return (
    <>
      <div
        className="overflow-auto"
        style={{
          height: props.isParameter == false ? 'auto' : props.isParameter && props.isAdd === undefined ? 780 : 540,
          marginTop: props.isParameter ? 8 : 0,
          marginLeft: props.isParameter ? '10px' : '0px',
        }}
      >
        <div id="showData" style={{ width: '95%' }}>
          <Row
            style={{ height: 37, display: props.isAdd !== undefined || props.isBulkEdit !== undefined ? 'none' : '' }}
          >
            <Col span={4} className="parent-pdetail-tbl-val-title" style={{ padding: 5 }}>
              工程パターン
            </Col>
            <Col
              span={page === patternPage[1] || page === patternPage[3] && !props.isParameter ? 6 : 20}
              style={{ verticalAlign: 'middle', padding: 3, display: props.isParameter ? '' : 'none' }}
            >
              {editMode ? (
                // <label style={{ textAlign: 'left', marginLeft: 2, marginTop: 5 }}>
                //   {getPatternNameByPatternNo(patternNo, patternList)}
                // </label>
                <Input
                  className={'input-editable'}
                  name="patternName"
                  id="patternName"
                  value={patternName}
                  type="text"
                  style={{ width: '40%' }}
                  ref={(ref) => {
                    inputItemRef['patternName'] = ref;
                  }}
                  maxLength={50}
                  onChange={(e) => {
                    setPatternName(e.target.value);
                    let patternInfo = { ...selectedPatternInfo };
                    patternInfo['name'] = e.target.value;
                    setSelectedPatternInfo(patternInfo);
                  }}
                />
              ) : (
                <Select
                  style={{ width: page === patternPage[1] || page === patternPage[3] && !props.isParameter ? '65%' : '19.8%', marginLeft: 2 }}
                  id="pattern"
                  name="pattern"
                  ref={tableRef}
                  value={patternNo}
                  onChange={onChangeProcessPatternInfo}
                  className={'input-editable'}
                  options={patternList?.map((item) => ({
                    value: item.id,
                    label: item.name === '' ? Empty : item.name,
                  }))}
                />
              )}
            </Col>
            {/** 親部品・子部品の工程選択画面のみ表示 */}
            <Col
              span={page === patternPage[1] || page === patternPage[3] && !props.isParameter ? 6 : 20}
              style={{ verticalAlign: 'middle', padding: 3, display: props.isParameter ? 'none' : '' }}
            >
              {props.kouteiSentakuEditMode ? (
                <Select
                  style={{ width: page === patternPage[1] || page === patternPage[3] && !props.isParameter ? '65%' : '19.8%', marginLeft: 2 }}
                  id="pattern"
                  name="pattern"
                  ref={tableRef}
                  value={isExistPattern(patternList, patternNo) ? patternNo : Empty}
                  onChange={onChangeProcessPatternInfo}
                  className={'input-editable'}
                  options={patternList?.map((item) => ({
                    value: item.id,
                    label: item.name === '' ? Empty : item.name,
                  }))}
                />
              ) : (
                <label style={{ textAlign: 'left', marginLeft: 2, marginTop: 5 }}>
                  {getPatternNameByPatternNo(patternNo, patternList)}
                </label>
              )}
            </Col>
            {/** 子部品の選択中部品情報表示、子部品画面のみ表示 */}
            <Col
              span={4}
              className="parent-pdetail-tbl-val-title"
              style={{
                padding: 5,
                display: page === patternPage[1] || page === patternPage[3] && !props.isParameter && !props.pmxMode ? '' : 'none',
              }}
            >
              選択中 部品
            </Col>
            <Col
              span={10}
              style={{
                verticalAlign: 'middle',
                padding: 3,
                display: page === patternPage[1] || page === patternPage[3] && !props.isParameter && !props.pmxMode ? '' : 'none',
              }}
            >
              <Select
                style={{ width: '65%', marginLeft: 2 }}
                id="partInfo"
                name="partInfo"
                ref={tableRef}
                value={partInfo}
                options={iq3PartList || iq5PartList}
                onChange={(e) => {
                  setPartInfo(e);
                  if (page === patternPage[1] || page === patternPage[3] && !props.isParameter) {
                    props.updateSelectedData(e);
                  }
                }}
                className={props.kouteiSentakuEditMode ? 'input-editable' : 'input-non-editable'}
              />
            </Col>
          </Row>
          <div className="kouteiPatternDiv" style={{ marginTop: '10px' }}>
            {renderPartComponent()}
          </div>
        </div>
      </div>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}

      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={selectedCustomerData}
          title={ItakuSaki}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default ProcessPatternForm;
