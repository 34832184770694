import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

import { handleKeyPress, regexExp } from '../common/Common';

const IQ3KouteiInput_Program = forwardRef((props, ref) => {
  const [element, setElement] = useState(0);
  const [elementAuto, setElementAuto] = useState(0);

  useEffect(() => {
    if (props?.tabOpen) {
      let programValues = props.programVal;
      setElement(programValues?.details?.[0]?.elements);
      setElementAuto(programValues?.details?.[0]?.elementsAuto);
    }
  }, [props.programVal, props.sizeX, props.sizeY, props.blankInfo, props.secondaryWorkInfo, props.bendingInfo]); // IQX_WEBEST-199

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  return (
    <>
      {props?.tabOpen && props?.isProgram ? (
        <Row className="mt-1 registerModal">
          <Table className="kouteiinput" style={{ width: '20%' }}>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: '20%' }} className="tbl-header">
                  要素数
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {/** IQX_WEBEST-179 */}
                  <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{elementAuto}</label>
                  <Input
                    className="input-editable"
                    value={element}
                    onChange={(e) => {
                      if (!regexExp.test(e.target.value)) {
                        setElement(e.target.value);
                        props.programVal.details[0].elements = e.target.value;
                        props.updateProgram(props.programVal);
                      }
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e, true);
                    }}
                    style={{ textAlign: 'center', height: 28, width: '58%', marginLeft: 9 }}
                  ></Input>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_Program;
