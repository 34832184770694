import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { Input, Select, Image, DatePicker, Checkbox, Button, Popover, Spin } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';

import search from '../../assets/images/search.png';
import SearchAndGetCustomerData from '../common/SearchAndGetCustomerData';
import { Customer } from '../common/Constant';
import { ClientType } from '../common/enums';
import {
  handleKeyPress,
  toDecimal,
  formatDate,
  JPYs,
  getNameByRecordId,
  regexExp,
  toHalfWidth,
  isDigit,
  getEstimateStatusFontColor,
  isDecimal,
  formatDateString,
  currentTime,
  currentDate,
  isDigitZenkaku,
} from '../common/Common';
import { commonModal, commonFooter, error } from '../common/CommonModal';
import { getExistCompanySetting, updateUserSetting } from '../common/CommonAPI';
import { ErrorMessage } from '../common/Message';
import edit_name from '../../assets/images/edit_name.png';

const plusMinus = [
  {
    label: '+',
    key: '1',
  },
  {
    label: '-',
    key: '2',
  },
];

const ParentEstimateDetail = forwardRef((props, ref) => {
  const [drawingNo, setDrawingNo] = useState('');
  const [name, setName] = useState('');
  const [clientName, setClientName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [coeffOrdersId, setCoeffOrdersId] = useState(0);
  const [coeffDeliveryDatesId, setCoeffDeliveryDatesId] = useState(0);
  const [coeffPurchasesId, setCoeffPurchasesId] = useState(0);
  const [coeffManagementsId, setCoeffManagementsId] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalSurfaceArea, setTotalSurfaceArea] = useState(0);
  const [createrId, setCreaterId] = useState(0);
  const [modifierId, setModifierId] = useState(0);
  const [created, setCreated] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [modified, setModified] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [sizeX, setSizeX] = useState('');
  const [sizeY, setSizeY] = useState('');
  const [sizeZ, setSizeZ] = useState('');
  const [estimateNo, setEstimateNo] = useState('');
  const [estimateStatusId, setEstimateStatusId] = useState(0);
  const [comment, setComment] = useState('');
  const [customCostLst, setCustomCostLst] = useState([]);
  const [customLabelLst, setCustomLabelLst] = useState([]);
  const [customMemoLst, setCustomMemoLst] = useState([]);
  const [customDateLst, setCustomDateLst] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const [customerCoefficient, setCustomerCoefficient] = useState(0);
  const [orderCoefficient, setOrderCoefficient] = useState(0);
  const [deliveryCoefficient, setDeliveryCoefficient] = useState(0);
  const [purchaseCoefficient, setPurchaseCoefficient] = useState(0);
  const [managementCoefficient, setManagementCoefficient] = useState(0);
  const [labelOpen, setLabelOpen] = useState(true);
  const [lableSmallOpen, setLableSmallOpen] = useState(true);
  const [customizeOpen, setCustomizeOpen] = useState(true);
  const [dateOpen, setdateOpen] = useState(true);
  const { TextArea } = Input;
  const [parentEstimateInfo, setParentEstimateInfo] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);
  const [tankaPriotiryChecked, setTankaPriotiryChecked] = useState(true);
  const [isAdjustPrice, setIsAdjustPrice] = useState(false);
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [totalCostPrice, setTotalCostPrice] = useState(0);
  const [costProfitBeforeAdjust, setCostProfitBeforeAdjust] = useState(0);
  const [costProfit, setCostProfit] = useState(0);
  const [rate, setRate] = useState(0);
  const [adjustAmount, setAdjustAmount] = useState(0);
  const [totalAmountByAdjust, setTotalAmountByAdjust] = useState(0);
  const [adjustAmountLabel, setAdjustAmountLabel] = useState(0);
  const [plusMinusAct, setPlusMinusAct] = useState('+');
  const [plusMinusActLabel, setPlusMinusActLabel] = useState('+');
  const [orderCoeffs, setOrderCoeffs] = useState([]);
  const [deliveryDateCoeffs, setDeliveryDateCoeffs] = useState([]);
  const [purchaseCoeffs, setPurchaseCoeffs] = useState([]);
  const [mangCoeffs, setMangCoeffs] = useState([]);
  // 担当者情報リスト
  const [staffList, setStaffList] = useState([]);
  // 案件状態情報
  const [estimateStatusInfo, setEstimateStatusInfo] = useState([]);
  const [inValidItem, setInValidItem] = useState([]); // 入力不正な項目

  useEffect(() => {
    if (props?.activeTabKey === '1') {
      if (props.selectedDataDetail !== undefined && props.selectedDataDetail?.estimateProducts !== undefined) {
        initFormData(props.selectedDataDetail);
        let parameters = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
        // let settings = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
        initCompanyInfo(parameters?.company);
        // initAdditionalInfo(parameters?.company);
        // 担当者情報リスト
        setStaffList(parameters?.staff);
        // 案件状態情報
        setEstimateStatusInfo(parameters?.estimateStatus);
      }
      // setUserSettingInfo(settings?.userSetting);
    }
  }, [props.activeTabKey, props.selectedDataDetail]);

  // IQX_WEBEST-339 保存していたデータを呼び出し、上賀書き保存しても更新日が変わりません。
  useEffect(() => {
    if (props?.activeTabKey === '1' && props.parentModified !== undefined && props.parentModified) {
      setModified(dayjs(props.parentModified).format('YYYY-MM-DD'));
    }
  }, [props.parentModified]);

  // IQX_WEBEST-347 「別名保存」後に扱う見積データ
  useEffect(() => {
    if (props?.activeTabKey === '1' && props.selectedDataDetail) {
      initFormData(props.selectedDataDetail);
    }
  }, [props.parentEstimateProductsId]);

  // 案件状態リストのフォントカラーを更新する
  useEffect(() => {
    if (props?.activeTabKey === '1') {
      document.documentElement.style.setProperty(
        '--parent-estStatus-color',
        getEstimateStatusFontColor(estimateStatusInfo, estimateStatusId)
      );
    }
  }, [estimateStatusId, estimateStatusInfo]);

  const updateParentEstimateDetail = (updateEstimate) => {
    initFormData(updateEstimate);
    let parameters = updateEstimate?.estimateProducts?.calcParameters?.parameters;
    // let settings = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    initCompanyInfo(parameters?.company);
    // initAdditionalInfo(parameters?.company);
    // 担当者情報リスト
    setStaffList(parameters?.staff);
    // 案件状態情報
    setEstimateStatusInfo(parameters?.estimateStatus);
    // setUserSettingInfo(settings?.userSetting);
  };

  useImperativeHandle(
    ref,
    () => ({
      setEditModeChange: setEditModeChange,
      getParentEstimateDetail: getParentEstimateDetail,
      // getParentEstimateDetail: () => {
      //   return parentEstimateInfo;
      // },
      formValidation: formValidation,
      updateParentEstimateDetail: updateParentEstimateDetail,
    }),
    [parentEstimateInfo]
  );

  const getParentEstimateDetail = (mainParentEstimate) => {
    if (mainParentEstimate === undefined || mainParentEstimate?.length === 0) return;
    let parentEstimate = mainParentEstimate;
    parentEstimate.estimateProducts.drawingNo = parentEstimateInfo?.estimateProducts?.drawingNo;
    parentEstimate.estimateProducts.name = parentEstimateInfo?.estimateProducts?.name;
    parentEstimate.estimateProducts.clientId = parentEstimateInfo?.estimateProducts?.clientId;
    parentEstimate.estimateProducts.clientCode = parentEstimateInfo?.estimateProducts?.clientCode;
    parentEstimate.estimateProducts.clientName = parentEstimateInfo?.estimateProducts?.clientName;
    parentEstimate.estimateProducts.quantity = parentEstimateInfo?.estimateProducts?.quantity;
    parentEstimate.estimateProducts.coeffOrdersId = parentEstimateInfo?.estimateProducts?.coeffOrdersId;
    parentEstimate.estimateProducts.coeffOrdersName = parentEstimateInfo?.estimateProducts?.coeffOrdersName;
    parentEstimate.estimateProducts.coeffDeliveryDatesId = parentEstimateInfo?.estimateProducts?.coeffDeliveryDatesId;
    parentEstimate.estimateProducts.coeffDeliveryDatesName =
      parentEstimateInfo?.estimateProducts?.coeffDeliveryDatesName;
    parentEstimate.estimateProducts.coeffPurchasesId = parentEstimateInfo?.estimateProducts?.coeffPurchasesId;
    parentEstimate.estimateProducts.coeffPurchasesName = parentEstimateInfo?.estimateProducts?.coeffPurchasesName;
    parentEstimate.estimateProducts.coeffManagementsId = parentEstimateInfo?.estimateProducts?.coeffManagementsId;
    parentEstimate.estimateProducts.coeffManagementsName = parentEstimateInfo?.estimateProducts?.coeffManagementsName;
    parentEstimate.estimateProducts.totalWeight = parentEstimateInfo?.estimateProducts?.totalWeight;
    parentEstimate.estimateProducts.totalSurfaceArea = parentEstimateInfo?.estimateProducts?.totalSurfaceArea;
    parentEstimate.estimateProducts.createrId = parentEstimateInfo?.estimateProducts?.createrId;
    parentEstimate.estimateProducts.creater = parentEstimateInfo?.estimateProducts?.creater;
    parentEstimate.estimateProducts.created = parentEstimateInfo?.estimateProducts?.created;
    parentEstimate.estimateProducts.modified = parentEstimateInfo?.estimateProducts?.modified;
    parentEstimate.estimateProducts.modifierId = parentEstimateInfo?.estimateProducts?.modifierId;
    parentEstimate.estimateProducts.modifier = parentEstimateInfo?.estimateProducts?.modifier;
    parentEstimate.estimateProducts.sizeX = parentEstimateInfo?.estimateProducts?.sizeX;
    parentEstimate.estimateProducts.sizeY = parentEstimateInfo?.estimateProducts?.sizeY;
    parentEstimate.estimateProducts.sizeZ = parentEstimateInfo?.estimateProducts?.sizeZ;
    parentEstimate.estimateProducts.estimateNo = parentEstimateInfo?.estimateProducts?.estimateNo;
    parentEstimate.estimateProducts.estimateStatusId = parentEstimateInfo?.estimateProducts?.estimateStatusId;
    parentEstimate.estimateProducts.estimateStatus = parentEstimateInfo?.estimateProducts?.estimateStatus;
    parentEstimate.estimateProducts.comment = parentEstimateInfo?.estimateProducts?.comment;
    parentEstimate.estimateProducts.otherProduct.adjustment =
      parentEstimateInfo?.estimateProducts?.otherProduct?.adjustment;
    parentEstimate.estimateProducts.otherProduct.adjustmentMethod =
      parentEstimateInfo?.estimateProducts?.otherProduct?.adjustmentMethod;
    parentEstimate.estimateProducts.otherProduct.coeffClient =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffClient;
    parentEstimate.estimateProducts.otherProduct.coeffOrders =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffOrders;
    parentEstimate.estimateProducts.otherProduct.coeffDeliveryDates =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffDeliveryDates;
    parentEstimate.estimateProducts.otherProduct.coeffPurchases =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffPurchases;
    parentEstimate.estimateProducts.otherProduct.coeffManagements =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffManagements;
    parentEstimate.estimateProducts.setupCoefficient = parentEstimateInfo?.estimateProducts?.setupCoefficient
      ? parentEstimateInfo?.estimateProducts?.setupCoefficient
      : 0;
    parentEstimate.estimateProducts.surfaceAreaCoefficient = parentEstimateInfo?.estimateProducts
      ?.surfaceAreaCoefficient
      ? parentEstimateInfo?.estimateProducts?.surfaceAreaCoefficient
      : 0;
    parentEstimate.epCustomCosts = parentEstimateInfo?.epCustomCosts;
    parentEstimate.epCustomLabels = parentEstimateInfo?.epCustomLabels;
    parentEstimate.epCustomMemos = parentEstimateInfo?.epCustomMemos;
    parentEstimate.epCustomDates = parentEstimateInfo?.epCustomDates;
    return parentEstimate;
  };

  // 初期化設定
  const initFormData = (selectedDataDetail) => {
    let partDetail = selectedDataDetail != undefined ? JSON.parse(JSON.stringify(selectedDataDetail)) : [];
    setParentEstimateInfo(partDetail);
    let basicInfo = partDetail.estimateProducts;
    if (basicInfo === undefined) return;
    setDrawingNo(basicInfo.drawingNo);
    setName(basicInfo.name);
    setClientName(basicInfo.clientName);
    setQuantity(Number(basicInfo.quantity));
    setCoeffOrdersId(basicInfo.coeffOrdersId);
    setCoeffDeliveryDatesId(basicInfo.coeffDeliveryDatesId);
    setCoeffPurchasesId(basicInfo.coeffPurchasesId);
    setCoeffManagementsId(basicInfo.coeffManagementsId);
    setTotalWeight(toDecimal(basicInfo.totalWeight, 2));
    setTotalSurfaceArea(toDecimal(basicInfo.totalSurfaceArea, 2));
    setCreaterId(basicInfo.createrId);
    setModifierId(basicInfo.modifierId);
    setSizeX(toDecimal(basicInfo.sizeX, 2));
    setSizeY(toDecimal(basicInfo.sizeY, 2));
    setSizeZ(toDecimal(basicInfo.sizeZ, 2));
    setEstimateNo(basicInfo.estimateNo);
    setEstimateStatusId(basicInfo.estimateStatusId);
    setComment(basicInfo.comment);
    setCustomerCoefficient(basicInfo?.otherProduct?.coeffClient);
    setOrderCoefficient(basicInfo?.otherProduct?.coeffOrders);
    setDeliveryCoefficient(basicInfo?.otherProduct?.coeffDeliveryDates);
    setPurchaseCoefficient(basicInfo?.otherProduct?.coeffPurchases);
    setManagementCoefficient(basicInfo?.otherProduct?.coeffManagements);
    setCreated(dayjs(basicInfo.created).format('YYYY-MM-DD'));
    setModified(dayjs(basicInfo.modified).format('YYYY-MM-DD'));

    //　IQX_WEBEST-88 価格調整で調整後に、再度価格調整のポップアップウィンドウを開く時に前回値を表示して欲しい
    let estimate = basicInfo?.estimateTotalPrice;
    if (basicInfo?.otherProduct?.adjustmentMethod === '-') {
      estimate = basicInfo?.estimateTotalPrice + basicInfo?.otherProduct?.adjustment;
    } else if (basicInfo?.otherProduct?.adjustmentMethod === '+') {
      estimate = basicInfo?.estimateTotalPrice - basicInfo?.otherProduct?.adjustment;
    }
    estimate = estimate ? estimate : 0;
    setEstimatedAmount(estimate);
    setTotalCostPrice(basicInfo.costTotalPrice);
    // IQX_WEBEST-385 価格調整ダイアログで粗利を調整したときのその他の項目の値が正しくない。
    // 粗利合計(合計見積-合計原価)
    setCostProfitBeforeAdjust(
      estimate !== undefined && basicInfo.costTotalPrice !== undefined ? estimate - basicInfo.costTotalPrice : 0
    );

    setAdjustAmount(basicInfo?.otherProduct?.adjustment);
    setAdjustAmountLabel(basicInfo?.otherProduct?.adjustment);
    setPlusMinusAct(basicInfo?.otherProduct?.adjustmentMethod);
    setPlusMinusActLabel(
      basicInfo?.otherProduct?.adjustmentMethod === '' ? '+' : basicInfo?.otherProduct?.adjustmentMethod
    );
    let totalAdjustAmount = estimate;
    if (basicInfo?.otherProduct?.adjustmentMethod === '-') {
      totalAdjustAmount = totalAdjustAmount - basicInfo?.otherProduct?.adjustment;
    } else if (basicInfo?.otherProduct?.adjustmentMethod === '+') {
      totalAdjustAmount = totalAdjustAmount + basicInfo?.otherProduct?.adjustment;
    }
    setTotalAmountByAdjust(totalAdjustAmount);
    setCostProfit(basicInfo?.grossTotalPrice);
    setRate(basicInfo?.grossRate ? parseFloat(basicInfo?.grossRate)?.toFixed(2) : toDecimal(0, 2));
    const costLst = partDetail.epCustomCosts === undefined ? [] : partDetail.epCustomCosts;
    if (costLst?.length > 0) {
      const isAllUsedTanka = partDetail.epCustomCosts?.filter((item) => item.isUseUnit === false);
      if (isAllUsedTanka?.length === 0) {
        setTankaPriotiryChecked(true);
      } else {
        setTankaPriotiryChecked(false);
      }
    } else {
      setTankaPriotiryChecked(false);
    }
    setCustomCostLst(partDetail?.epCustomCosts);
    setCustomLabelLst(partDetail?.epCustomLabels);
    setCustomMemoLst(partDetail?.epCustomMemos);
    setCustomDateLst(partDetail?.epCustomDates);
  };

  // 会社情報の初期化設定
  const initCompanyInfo = (companyInfo) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo?.[0]?.coeffOrders?.filter((i) => !i.isDeleted && i.isUsed);
      deliDateCoeffs = companyInfo?.[0]?.coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      purCoeffs = companyInfo?.[0]?.coeffPurchases?.filter((i) => !i.isDeleted && i.isUsed);
      mngCoeffs = companyInfo?.[0]?.coeffManagements?.filter((i) => !i.isDeleted && i.isUsed);
    }
    setPurchaseCoeffs(purCoeffs);
    setMangCoeffs(mngCoeffs);
    setOrderCoeffs(orderCoeffs);
    setDeliveryDateCoeffs(deliDateCoeffs);
  };

  // 基本情報・お客様検索ボタン押下
  const getCustomerName = () => {
    setCustomerModal(true);
  };

  // 基本情報・お客様検索画面のClose「X」ボタン押下
  const closeCustomerModal = () => {
    setCustomerModal(false);
  };

  // 画面の入力チェック
  const formValidation = () => {
    let ret = true;
    let message = [];
    let input;
    // 日付
    // customDateLst?.map((date) => {
    //   input = document.getElementsByName('date' + date?.additionalItemId)?.[0];
    //   input?.classList?.remove('input-error');
    //   if (input?.value?.length === 0) {
    //     // 必須
    //     message.push(<li>{ErrorMessage('基本情報の' + date?.additionalItemName).E017}</li>);
    //     input?.classList.add('input-error');
    //     ret = false;
    //   }
    // });

    // 数量
    input = document.getElementsByName('suuryou')?.[0];
    input?.classList?.remove('input-error');
    if (input?.value?.length === 0) {
      // 必須
      message.push(<li>{ErrorMessage('親部品・基本情報の数量').E007}</li>);
      input?.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('親部品・基本情報の数量').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && Number(input?.value) === 0) {
      // 0以外の整数
      message.push(<li>{ErrorMessage('親部品・基本情報の数量は0以外の整数').E007}</li>);
      input?.classList.add('input-error');
      ret = false;
    }
    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };

  const labelOpenDetail = (e, isShow) => {
    setLabelOpen(isShow);
  };
  const labelSmallOpenDetail = (e, isShow) => {
    setLableSmallOpen(isShow);
  };
  const customizeOpenDetail = (e, isShow) => {
    setCustomizeOpen(isShow);
  };
  const dateOpenDetail = (e, isShow) => {
    setdateOpen(isShow);
  };

  // 費用・日付・メモ・ラベルに入力した場合、
  const onArrValChange = (i, e, name, type) => {
    if (type === 'cost') {
      const quantity = props?.selectedDataDetail?.estimateProducts?.quantity;
      const data = [...customCostLst];

      data[i][name] = e ? Number(e) : 0;
      if (name === 'unitPrice') {
        console.log(quantity);
        data[i]['totalPrice'] = e && quantity ? Number(e) * Number(quantity) : 0;
      } else if (name === 'totalPrice') {
        data[i]['unitPrice'] = e && quantity ? Number(e) / Number(quantity) : 0;
      }
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomCosts: data };
      });
      props.selectedDataDetail.epCustomCosts = data;
    } else if (type === 'date') {
      const data = [...customDateLst];
      data[i][name] = e;
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomDates: data };
      });
      props.selectedDataDetail.epCustomDates = data;
    } else if (type === 'label') {
      const data = [...customLabelLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomLabels: data };
      });
      props.selectedDataDetail.epCustomLabels = data;
    } else if (type === 'memo') {
      const data = [...customMemoLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomMemos: data };
      });
      props.selectedDataDetail.epCustomMemos = data;
    }
  };

  // お客様一覧のOKボタン押下
  const selectedCustomerData = async (selectedCustomerData) => {
    let id = selectedCustomerData.id;
    let name = selectedCustomerData.name;
    let code = selectedCustomerData.code;
    let coeff = selectedCustomerData?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff;
    setClientName(name);
    setCustomerCoefficient(coeff);
    const data = parentEstimateInfo;
    data.estimateProducts['clientId'] = id;
    data.estimateProducts['clientName'] = name;
    data.estimateProducts['clientCode'] = code;
    data.estimateProducts['otherProduct']['coeffClient'] = coeff;
    setParentEstimateInfo(data);
    props.selectedDataDetail.estimateProducts.clientId = id;
    props.selectedDataDetail.estimateProducts.clientName = name;
    props.selectedDataDetail.estimateProducts.clientCode = code;
    props.selectedDataDetail.estimateProducts.otherProduct.coeffClient = coeff;
    setCustomerModal(false);
    let userSetting = props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
    if (userSetting?.length > 0) userSetting = userSetting[0];
    if (userSetting !== null) {
      if (userSetting?.users?.newParentInitialInfo?.clientId !== undefined) {
        userSetting.users.newParentInitialInfo.clientId = id;
        await updateUserSetting(userSetting, 0);
      }
    }
  };

  const settingTankaPriority = (e) => {
    setTankaPriotiryChecked(e.target.checked);
    const data = [...customCostLst];
    for (let i = 0; i < data?.length; i++) {
      data[i].isUseUnit = e.target.checked;
    }
    setCustomCostLst(data);
    props.selectedDataDetail.epCustomCosts = data;
  };

  const adjustPrice = () => {
    // initAdjustPrice();
    setIsAdjustPrice(true);
  };

  const initAdjustPrice = () => {
    // IQX_WEBEST-88 価格調整で調整後に、再度価格調整のポップアップウィンドウを開く時に前回値を表示して欲しい
    setCostProfit(props.selectedDataDetail?.estimateProducts?.grossTotalPrice);
    setRate(
      props.selectedDataDetail?.estimateProducts?.grossRate
        ? parseFloat(props.selectedDataDetail?.estimateProducts?.grossRate)?.toFixed(2)
        : toDecimal(0, 2)
    );
    setAdjustAmount(props.selectedDataDetail?.estimateProducts?.otherProduct?.adjustment);
    setPlusMinusAct(props.selectedDataDetail?.estimateProducts?.otherProduct?.adjustmentMethod);
    setTotalAmountByAdjust(props.selectedDataDetail?.estimateProducts?.estimateTotalPrice);
  };

  const adjustPriceOK = () => {
    let adjustMethod = plusMinusAct === undefined ? '+' : plusMinusAct;
    setIsAdjustPrice(false);
    setPlusMinusActLabel(adjustMethod);
    setAdjustAmountLabel(adjustAmount);
    const data = parentEstimateInfo;
    data.estimateProducts.otherProduct.adjustment = adjustAmount;
    data.estimateProducts.otherProduct.adjustmentMethod = adjustMethod;
    setParentEstimateInfo(data);
    if (props.selectedDataDetail.estimateProducts && props.selectedDataDetail.estimateProducts.otherProduct) {
      props.selectedDataDetail.estimateProducts.otherProduct.adjustment = adjustAmount;
      props.selectedDataDetail.estimateProducts.otherProduct.adjustmentMethod = adjustMethod;
    }
  };

  const adjustPriceCancel = () => {
    initAdjustPrice(); // IQX_WEBEST-369 親部品‐基本情報の価格調整ダイアログに修正を行ってキャンセルをしても修正が残っている。
    setIsAdjustPrice(false);
  };

  const calculateEstimateAmountByAdjust = (
    profitInput,
    profitRateInput,
    adjustAmountInput,
    totalAdjustAmountInput,
    adjustMethod
  ) => {
    let profitRate = rate;
    let totalAmountByAdjustCal = totalAmountByAdjust;
    let profitAmount = costProfit;
    let adjustAmountCal = 0;
    // 粗利入力の場合
    if (profitInput !== '') {
      // 粗利率（調整後粗利 / 最終見積金額）* 100
      profitRate = (Number(profitInput) / totalAmountByAdjustCal) * 100; // IQX_WEBEST-87
      profitRate = Number.isFinite(profitRate) ? profitRate : toDecimal(0, 2);
      setRate(parseFloat(profitRate)?.toFixed(2));
      // 最終見積金額（原価/(1-粗利率)）
      totalAmountByAdjustCal = Number(totalCostPrice) / (1 - profitRate); // IQX_WEBEST-87
      totalAmountByAdjustCal = Number.isFinite(totalAmountByAdjustCal) ? totalAmountByAdjustCal : 0;
      // 調整額(最終見積金額 - 調整前見積金額)
      adjustAmountCal = totalAmountByAdjustCal - estimatedAmount;
      setAdjustAmount(Math.abs(adjustAmountCal));
      setPlusMinusAct(adjustAmountCal > 0 ? '+' : '-');
    } // 粗利率入力の場合
    else if (profitRateInput !== '') {
      // 粗利率
      let discount = Number.isFinite(profitRateInput / 100) ? profitRateInput / 100 : 0;
      // 最終見積金額（原価/(1-粗利率)）
      totalAmountByAdjustCal = Number(totalCostPrice) / (1 - discount); // IQX_WEBEST-87
      totalAmountByAdjustCal = Number.isFinite(totalAmountByAdjustCal) ? totalAmountByAdjustCal : 0;
      // 粗利(最終見積金額 - 原価)
      profitAmount = Number(totalAmountByAdjustCal) - Number(totalCostPrice);
      setCostProfit(profitAmount);
      // 調整額(最終見積金額 - 調整前見積金額)
      adjustAmountCal = Number(totalAmountByAdjustCal) - estimatedAmount;
      setAdjustAmount(Math.abs(adjustAmountCal));
      setPlusMinusAct(adjustAmountCal > 0 ? '+' : '-');
    } // 調整金額入力の場合
    else if (adjustAmountInput !== '') {
      // 最終見積金額
      totalAmountByAdjustCal =
        plusMinusAct === '-'
          ? estimatedAmount - Number(adjustAmountInput)
          : estimatedAmount + Number(adjustAmountInput);
      // 粗利(最終見積金額 - 原価)
      profitAmount = totalAmountByAdjustCal > 0 ? totalAmountByAdjustCal - totalCostPrice : 0;
      setCostProfit(Math.round(profitAmount));
      //粗利率（調整後粗利 / 最終見積金額）* 100
      profitRate = (profitAmount / totalAmountByAdjustCal) * 100;
      profitRate = Number.isFinite(profitRate) ? profitRate : 0;
      setRate(parseFloat(profitRate)?.toFixed(2));
    } // 最終見積金額入力の場合
    else if (totalAdjustAmountInput !== '') {
      // 最終見積金額
      totalAmountByAdjustCal = Number(totalAdjustAmountInput);
      // 粗利(最終見積金額 - 原価)
      profitAmount = totalAmountByAdjustCal > 0 ? totalAmountByAdjustCal - totalCostPrice : 0;
      setCostProfit(Math.round(profitAmount));
      //粗利率（調整後粗利 / 最終見積金額）* 100
      profitRate = (profitAmount / totalAmountByAdjustCal) * 100;
      if (Number.isFinite(profitRate) || !isNaN(profitRate)) {
        profitRate = parseFloat(profitRate).toFixed(2);
      } else {
        profitRate = toDecimal(0, 2);
      }
      setRate(profitRate);
      //調整額(最終見積金額 - 調整前見積金額)
      adjustAmountCal = totalAmountByAdjustCal - estimatedAmount;
      setAdjustAmount(Math.abs(adjustAmountCal));
      setPlusMinusAct(adjustAmountCal > 0 ? '+' : '-');
    } else if (adjustMethod !== '') {
      // 調整方法入力の場合
      // 最終見積金額
      totalAmountByAdjustCal =
        adjustMethod === '-' ? estimatedAmount - parseInt(adjustAmount) : estimatedAmount + parseInt(adjustAmount);
      // 粗利(最終見積金額 - 原価)
      profitAmount = totalAmountByAdjustCal > 0 ? totalAmountByAdjustCal - totalCostPrice : 0;
      setCostProfit(Math.round(profitAmount));
      //粗利率（調整後粗利 / 最終見積金額）* 100
      profitRate = (profitAmount / totalAmountByAdjustCal) * 100;
      profitRate = Number.isFinite(profitRate) ? profitRate : 0;
      setRate(parseFloat(profitRate)?.toFixed(2));
    }
    // 最終見積金額
    setTotalAmountByAdjust(totalAmountByAdjustCal ? Math.round(totalAmountByAdjustCal) : 0);
  };

  const resetAdjustAmount = () => {
    initAdjustPrice();
  };

  const adjustPriceTitle = (
    <div
      style={{
        width: 420,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>価格調整</p>
    </div>
  );

  // 粗利率を変更した場合、
  const onChangeRate = (value, isCheckZenkaku = true) => {
    if (regexExp.test(value) && !isDigitZenkaku(value)) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
      if (!isDecimal(value)) value = 0;
    }
    if (isCheckZenkaku) value = toDecimal(value, 2);
    if (rate != value) {
      setRate(value);
      calculateEstimateAmountByAdjust('', value, '', '', '');
    }
  };

  // 価格調整
  const adjustPriceContent = (
    <div id="addNewCustomer" style={{ marginTop: 10, marginBottom: 20 }}>
      <table style={{ width: '87%' }}>
        <thead>
          <tr>
            <td className="parent-detail-adjustPrice-title ">見積合計</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="estimatedAmount"
                id="estimatedAmount"
                value={estimatedAmount ? Math.round(estimatedAmount) : 0}
                defaultValue={0}
                style={{ padding: 3, textAlign: 'right', width: '100%' }}
                className="input-non-editable"
                prefix="¥"
                decimalsLimit={100}
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title ">原価合計</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="totalCostPrice"
                id="totalCostPrice"
                value={totalCostPrice ? Math.round(totalCostPrice) : 0}
                defaultValue={0}
                style={{ padding: 3, textAlign: 'right', width: '100%' }}
                className="input-non-editable"
                prefix="¥"
                decimalsLimit={100}
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title ">粗利</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="costProfit"
                id="costProfit"
                value={costProfitBeforeAdjust !== undefined ? Math.round(costProfitBeforeAdjust) : 0}
                // IQX_WEBEST-385 価格調整ダイアログで粗利を調整したときのその他の項目の値が正しくない。
                // onValueChange={(e) => {
                //   if (costProfit != e) {
                //     setCostProfit(e);
                //     calculateEstimateAmountByAdjust(e, '', '', '', '');
                //   }
                // }}
                style={{ padding: 3, textAlign: 'right', width: '100%' }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="input-non-editable" // IQX_WEBEST-385 価格調整ダイアログで粗利を調整したときのその他の項目の値が正しくない。
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title " style={{ color: 'red' }}>
              粗利率
            </td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <Input
                name="rate"
                id="rate"
                value={rate}
                onKeyPress={(e) => {
                  handleKeyPress(e, false);
                }}
                onPressEnter={(e) => onChangeRate(e.target.value)}
                onBlur={(e) => onChangeRate(e.target.value)}
                onChange={(e) => onChangeRate(e.target.value, false)}
                style={{ padding: 3, textAlign: 'right' }}
                defaultValue={0}
                suffix="%"
                className="input-editable percent-input adjust-rate"
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title " style={{ color: 'red' }}>
              調整額
            </td>
            <td style={{ verticalAlign: 'middle', height: 36, padding: 3, display: 'flex' }}>
              <Select
                defaultValue={'+'}
                id="adjustMethod"
                name="adjustMethod"
                className={
                  plusMinusAct === '-'
                    ? 'plusMinusDDL plusMinusDDL-parent-estimate minus'
                    : 'plusMinusDDL plusMinusDDL-parent-estimate'
                }
                value={plusMinusAct}
                onChange={(e) => {
                  if (plusMinusAct != e) {
                    setPlusMinusAct(e);
                    calculateEstimateAmountByAdjust('', '', '', '', e);
                  }
                }}
              >
                {plusMinus?.map((item, index) => (
                  <Select.Option key={index} id={'plusMinus' + item.value} value={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
              <CurrencyInput
                name="adjustAmount"
                id="adjustAmount"
                value={adjustAmount ? Math.round(adjustAmount) : 0}
                onValueChange={(e) => {
                  if (adjustAmount != e && e >= 0) {
                    // IQX_WEBEST-292 価格調整で金額入力部分に”－”を入力出来ないようにしてほしい
                    setAdjustAmount(Number(e));
                    calculateEstimateAmountByAdjust('', '', e, '', '');
                  }
                }}
                style={{ padding: 3, color: 'red', textAlign: 'right', marginLeft: 5 }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="currencyInput"
              />
            </td>
          </tr>
          {/* IQX_WEBEST-385 価格調整ダイアログで粗利を調整したときのその他の項目の値が正しくない。 */}
          <tr>
            <td className="parent-detail-adjustPrice-title ">調整粗利</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="costProfit"
                id="costProfit"
                value={costProfit !== undefined ? Math.round(costProfit) : 0}
                style={{ padding: 3, textAlign: 'right', width: '100%' }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="input-non-editable"
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title" style={{ fontWeight: 'bold' }}>
              最終見積合計
            </td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="totalAmountByAdjust"
                id="totalAmountByAdjust"
                value={totalAmountByAdjust ? Math.round(totalAmountByAdjust) : 0}
                // IQX_WEBEST-385 価格調整ダイアログで粗利を調整したときのその他の項目の値が正しくない。
                // onValueChange={(e) => {
                //   if (totalAmountByAdjust != e) {
                //     setTotalAmountByAdjust(e);
                //     calculateEstimateAmountByAdjust('', '', '', e, '');
                //   }
                // }}
                style={{ padding: 3, textAlign: 'right', fontWeight: 'bold', width: '100%' }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="input-non-editable" // IQX_WEBEST-385 価格調整ダイアログで粗利を調整したときのその他の項目の値が正しくない。
              />
            </td>
          </tr>
          <tr style={{ marginTop: 10 }}>
            <td></td>
            <td style={{ textAlign: 'right' }}>
              <a
                onClick={resetAdjustAmount}
                style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
              >
                リセット
              </a>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );

  // 入力項目のチェック
  const isValid = (value, name) => {
    let input = '';
    let item = [];
    let ret = true;
    input = document.getElementsByName(name)?.[0];
    input?.classList?.remove('input-error');
    if (name === 'suuryou' && value?.length > 0 && Number(value) === 0) {
      item = inValidItem;
      item.push('suuryou');
      input?.classList.add('input-error');
      ret = false;
    }
    setInValidItem(item);
    return ret;
  };

  // 数量のチェック
  const checkSuuRyou = (e, isCheckZenkaku = true) => {
    let suuryou = e.target.value;
    if (suuryou !== quantity) {
      if (regexExp.test(suuryou) && !isDigitZenkaku(suuryou)) return;
      if (isCheckZenkaku && regexExp.test(suuryou)) {
        // 全角の場合、
        suuryou = toHalfWidth(suuryou); // 半角へ変換
        if (!isDigit(suuryou)) return;
      }
      suuryou = suuryou ? Number(suuryou) : 0;
      setQuantity(suuryou);
      if (!isValid(suuryou, 'suuryou')) return;
      props.selectedDataDetail.estimateProducts.quantity = suuryou;
      for (let i = 0; i < props?.selectedDataDetail?.epCustomCosts?.length; i++) {
        if (props.selectedDataDetail.epCustomCosts[i].isUseUnit === true) {
          props.selectedDataDetail.epCustomCosts[i].totalPrice =
            props.selectedDataDetail?.epCustomCosts?.[i]?.unitPrice && suuryou
              ? props?.selectedDataDetail?.epCustomCosts?.[i]?.unitPrice * suuryou
              : 0;
        } else {
          props.selectedDataDetail.epCustomCosts[i].unitPrice =
            props.selectedDataDetail?.epCustomCosts?.[i]?.totalPrice && suuryou
              ? props?.selectedDataDetail?.epCustomCosts?.[i]?.totalPrice / suuryou
              : 0;
        }
      }
      setCustomCostLst(props.selectedDataDetail?.epCustomCosts);
      props?.onChangeQuantity(true);
    }
  };

  // XYZサイズ（mm）を変更した場合、
  const onChangeXYZSize = (value, name, isCheckZenkaku = true) => {
    if (regexExp.test(value) && !isDigitZenkaku(value)) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
      if (!isDigit(value) && !isDecimal(value)) {
        value = 0;
      }
    }
    if (isCheckZenkaku) value = toDecimal(value, 2);
    if (name === 'sizeX') {
      setSizeX(value);
      props.selectedDataDetail.estimateProducts.sizeX = value;
    } else if (name === 'sizeY') {
      setSizeY(value);
      props.selectedDataDetail.estimateProducts.sizeY = value;
    } else if (name === 'sizeZ') {
      setSizeZ(value);
      props.selectedDataDetail.estimateProducts.sizeZ = value;
    }
  };

  // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
  const getSelectName = (defaultInfo) => {
    let defaultDrawingNo = '';
    if (defaultInfo === undefined || defaultInfo === '') {
      defaultDrawingNo += '';
    } else if (defaultInfo === 1) {
      // 年月日
      defaultDrawingNo += formatDateString(currentDate());
    } else if (defaultInfo === 2) {
      // 時分秒
      defaultDrawingNo += formatDateString(currentTime());
    }
    return defaultDrawingNo;
  };

  // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
  const setToDefaultName = async (type) => {
    let defaultDrawingNoInfo = '';
    let companySetting = await getExistCompanySetting();
    if (companySetting !== undefined && companySetting?.length > 0) {
      defaultDrawingNoInfo = companySetting?.[0]?.initNames?.parentInitNameInfo[0];
      if (type === 'name') {
        defaultDrawingNoInfo = companySetting?.[0]?.initNames?.parentInitNameInfo[1];
      } else if (type === 'estimateNo') {
        defaultDrawingNoInfo = companySetting?.[0]?.initNames?.parentInitNameInfo[2];
      }
      // デフォルト値１
      let defaultName =
        defaultDrawingNoInfo?.input1 === undefined || defaultDrawingNoInfo?.input1 === ''
          ? ''
          : defaultDrawingNoInfo?.input1;
      // デフォルト値２
      defaultName +=
        defaultDrawingNoInfo?.input2 === undefined || defaultDrawingNoInfo?.input2 === ''
          ? ''
          : defaultDrawingNoInfo?.input2;
      // デフォルト値３
      defaultName += getSelectName(defaultDrawingNoInfo?.select1);
      // デフォルト値４
      defaultName +=
        defaultDrawingNoInfo?.input3 === undefined || defaultDrawingNoInfo?.input3 === ''
          ? ''
          : defaultDrawingNoInfo?.input3;
      // デフォルト値５
      defaultName += getSelectName(defaultDrawingNoInfo?.select2);
      // デフォルト値６
      defaultName +=
        defaultDrawingNoInfo?.input4 === undefined || defaultDrawingNoInfo?.input4 === ''
          ? ''
          : defaultDrawingNoInfo?.input4;
      // デフォルト値７
      defaultName += getSelectName(defaultDrawingNoInfo?.select3);
      defaultName = defaultName ? defaultName : '';
      if (type === 'drawingNo') {
        setDrawingNo(defaultName);
        props.selectedDataDetail.estimateProducts.drawingNo = defaultName;
      } else if (type === 'name') {
        setName(defaultName);
        props.selectedDataDetail.estimateProducts.name = defaultName;
      } else if (type === 'estimateNo') {
        setEstimateNo(defaultName);
        props.selectedDataDetail.estimateProducts.estimateNo = defaultName;
      }
    }
  };

  return (
    <Spin spinning={props?.activeTabKey === '1' ? false : true} tip="" size="large">
      <>
        {/* 部品プロパティアリア */}
        {props?.activeTabKey === '1' ? (
          <>
            <div
              style={{
                // margin: '2px',
                display: 'flex',
              }}
            >
              <div style={{ float: 'left', width: '55%', height: '90.8vh' }}>
                <Table className="propertiesTbl">
                  <thead>
                    {/** 図番 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '70%' }}>
                            <label className="detail-tbl-val-title-label">図番</label>
                          </div>
                          <div style={{ width: '25%', textAlign: 'right' }}>
                            <a
                              onClick={async () => {
                                setToDefaultName('drawingNo'); // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
                              }}
                            >
                              <Image
                                preview={false}
                                width={20}
                                height={20}
                                src={edit_name}
                                style={{ marginTop: '10px' }}
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '98%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          //
                          name="drawingNo"
                          id="drawingNo"
                          value={drawingNo}
                          type="text"
                          onChange={(e) => {
                            setDrawingNo(e.target.value);
                            // const data = parentEstimateInfo;
                            // data.estimateProducts[e.target.name] = e.target.value;
                            // setParentEstimateInfo(data);
                            props.selectedDataDetail.estimateProducts.drawingNo = e.target.value;
                          }}
                        />
                      </td>
                    </tr>
                    {/** 名称 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '70%' }}>
                            <label className="detail-tbl-val-title-label">名称</label>
                          </div>
                          <div style={{ width: '25%', textAlign: 'right' }}>
                            <a
                              onClick={async () => {
                                setToDefaultName('name'); // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
                              }}
                            >
                              <Image
                                preview={false}
                                width={20}
                                height={20}
                                src={edit_name}
                                style={{ marginTop: '10px' }}
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '98%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="name"
                          id="name"
                          value={name}
                          type="text"
                          onChange={(e) => {
                            setName(e.target.value);
                            // const data = parentEstimateInfo;
                            // data.estimateProducts[e.target.name] = e.target.value;
                            // setParentEstimateInfo(data);
                            props.selectedDataDetail.estimateProducts.name = e.target.value;
                          }}
                        />
                      </td>
                    </tr>
                    {/** 見積番号・案件状態 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '70%' }}>
                            <label className="detail-tbl-val-title-label">見積番号</label>
                          </div>
                          <div style={{ width: '25%', textAlign: 'right' }}>
                            <a
                              onClick={async () => {
                                setToDefaultName('estimateNo'); // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
                              }}
                            >
                              <Image
                                preview={false}
                                width={20}
                                height={20}
                                src={edit_name}
                                style={{ marginTop: '10px' }}
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '94%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="estimateNo"
                          id="estimateNo"
                          value={estimateNo}
                          type="text"
                          onChange={(e) => {
                            setEstimateNo(e.target.value);
                            // const data = parentEstimateInfo;
                            // data.estimateProducts[e.target.name] = e.target.value;
                            // setParentEstimateInfo(data);
                            props.selectedDataDetail.estimateProducts.estimateNo = e.target.value;
                          }}
                        />
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">案件状態</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          {/* {editMode ? ( */}
                          {estimateStatusInfo !== undefined && estimateStatusInfo.length > 0 ? (
                            <Select
                              className="iq3-detail-select parent-estimate-status"
                              value={estimateStatusId}
                              onChange={async (e) => {
                                setEstimateStatusId(e);
                                let estStatusName = getNameByRecordId(e, estimateStatusInfo);
                                // const data = parentEstimateInfo;
                                // data.estimateProducts['estimateStatusId'] = e;
                                // data.estimateProducts['estimateStatus'] = estStatusName;
                                // setParentEstimateInfo(data);

                                props.selectedDataDetail.estimateProducts.estimateStatusId = e;
                                props.selectedDataDetail.estimateProducts.estimateStatus = estStatusName;
                                let userSetting =
                                  props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                                if (userSetting?.length > 0) userSetting = userSetting[0];
                                if (userSetting !== null) {
                                  if (userSetting.users?.newParentInitialInfo?.estimateStatusId !== undefined) {
                                    userSetting.users.newParentInitialInfo.estimateStatusId = e;
                                    await updateUserSetting(userSetting, 0);
                                  }
                                }
                              }}
                              style={{ marginLeft: 5, width: '94%' }}
                            >
                              {estimateStatusInfo
                                ?.sort((a, b) => Number(a?.info?.sortNum) - Number(b?.info?.sortNum))
                                .map((option, index) => (
                                  <Select.Option key={index} value={option.id}>
                                    {option.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          ) : (
                            <Select style={{ marginLeft: 5, width: '94%' }} />
                          )}
                          {/* ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameByRecordId(estimateStatusId, estimateStatusInfo)}
                      </label>
                    )} */}
                        </div>
                      </td>
                    </tr>
                    {/** お客様名／お客様係数 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">お客様名／お客様係数</label>
                      </td>
                      <td colSpan={6} style={{ width: '50%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '90%' }}
                          // className={editMode ? 'input-editable' : 'input-non-editable'}
                          className={'input-non-editable'}
                          name="clientName"
                          id="clientName"
                          type="text"
                          onChange={async (e) => {
                            setClientName(e.target.value);
                            // const data = parentEstimateInfo;
                            // data.estimateProducts[e.target.name] = e.target.value;
                            // setParentEstimateInfo(data);
                          }}
                          value={clientName}
                        />
                        {editMode ? (
                          <a
                            onClick={() => {
                              getCustomerName();
                            }}
                          >
                            <Image
                              preview={false}
                              width={20}
                              height={20}
                              src={search}
                              style={{ marginBottom: '5px' }}
                            />
                          </a>
                        ) : (
                          ''
                        )}
                      </td>
                      <td colSpan={4} style={{ borderLeft: '0.1px solid #a5a5a5' }} className="detail-tbl-val">
                        <div style={{ display: 'flex' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '94%' }}
                            className={'input-non-editable'}
                            name="customerCoefficient"
                            id="customerCoefficient"
                            type="text"
                            value={toDecimal(customerCoefficient, 2)}
                          />
                        </div>
                      </td>
                    </tr>
                    {/** 数量 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">数量</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '94%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="suuryou"
                          id="suuryou"
                          value={quantity}
                          type="text"
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          onPressEnter={(e) => checkSuuRyou(e)}
                          onBlur={(e) => checkSuuRyou(e)}
                          onChange={(e) => checkSuuRyou(e, false)}
                        />
                        {inValidItem?.includes('suuryou') ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{'0以外の整数を入力してください。'}</div>}
                            placement="right"
                            open={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        {editMode ? (
                          <Button
                            onClick={adjustPrice}
                            className="cancleButton"
                            style={{ width: '90px', marginLeft: 10 }}
                          >
                            価格調整
                          </Button>
                        ) : (
                          <label className="detail-tbl-val-title-label">価格調整</label>
                        )}
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <CurrencyInput
                          style={{ padding: 5, marginLeft: 5, width: '94%' }}
                          className={'input-non-editable'}
                          name="adjustAmountLabel"
                          id="adjustAmountLabel"
                          type="text"
                          value={adjustAmountLabel ? Math.round(adjustAmountLabel) : 0}
                          prefix={plusMinusActLabel + ' ¥'}
                          decimalsLimit={100}
                        />
                      </td>
                    </tr>
                    {/** 受注形態 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">受注形態</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {/* {editMode ? ( */}
                        {orderCoeffs !== undefined && orderCoeffs.length > 0 ? (
                          <Select
                            className="iq3-detail-select"
                            value={coeffOrdersId}
                            onChange={async (e) => {
                              setCoeffOrdersId(e);
                              // const data = parentEstimateInfo;
                              // let record = orderCoeffs?.filter((record) => record.id === e);
                              // setOrderCoefficient(record?.[0].coeff);
                              // setCoeffOrdersName(record?.[0].name);
                              // data.estimateProducts['coeffOrdersName'] = record?.[0].name;
                              // data.estimateProducts['coeffOrdersId'] = record?.[0].id;
                              // data.estimateProducts['otherProduct']['coeffOrders'] = record?.[0].coeff;
                              // setParentEstimateInfo(data);
                              let record = orderCoeffs?.filter((record) => record.id === e);
                              setOrderCoefficient(record?.[0].coeff);
                              props.selectedDataDetail.estimateProducts.coeffOrdersName = record?.[0]?.name;
                              props.selectedDataDetail.estimateProducts.coeffOrdersId = record?.[0]?.id;
                              props.selectedDataDetail.estimateProducts.otherProduct.coeffOrders = record?.[0]?.coeff;
                              let userSetting =
                                props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                              if (userSetting?.length > 0) userSetting = userSetting[0];
                              if (userSetting !== null) {
                                if (userSetting.users?.newParentInitialInfo?.coeffOrdersId !== undefined) {
                                  userSetting.users.newParentInitialInfo.coeffOrdersId = record?.[0]?.id;
                                  await updateUserSetting(userSetting, 0);
                                }
                              }
                            }}
                            style={{ marginLeft: 5, width: '94%' }}
                          >
                            {orderCoeffs
                              ?.sort((a, b) => Number(a?.sortNum) - Number(b?.sortNum))
                              .map((option, index) => (
                                <Select.Option key={index} value={option.id}>
                                  {option.name}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <Select style={{ marginLeft: 5, width: '94%' }} />
                        )}
                        {/* ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getNameById(coeffOrdersId, orderCoeffs)}
                    </label>
                  )} */}
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">受注係数</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '94%' }}
                            className={'input-non-editable'}
                            name="orderCoefficient"
                            id="orderCoefficient"
                            type="text"
                            value={toDecimal(orderCoefficient, 2)}
                          />
                        </div>
                      </td>
                    </tr>
                    {/** 納期形態 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">納期形態</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {deliveryDateCoeffs !== undefined && deliveryDateCoeffs.length > 0 ? (
                          <Select
                            className="iq3-detail-select"
                            value={coeffDeliveryDatesId}
                            onChange={async (e) => {
                              setCoeffDeliveryDatesId(e);
                              // const data = parentEstimateInfo;
                              // let record = deliveryDateCoeffs?.filter((record) => record.id === e);
                              // setDeliveryCoefficient(record[0].coeff);
                              // setCoeffDeliveryDatesName(record?.[0].name);
                              // data.estimateProducts['coeffDeliveryDatesName'] = record?.[0].name;
                              // data.estimateProducts['coeffDeliveryDatesId'] = record?.[0].id;
                              // data.estimateProducts['otherProduct']['coeffDeliveryDates'] = record?.[0].coeff;
                              // setParentEstimateInfo(data);

                              let record = deliveryDateCoeffs?.filter((record) => record.id === e);
                              setDeliveryCoefficient(record[0].coeff);
                              props.selectedDataDetail.estimateProducts.coeffDeliveryDatesName = record?.[0]?.name;
                              props.selectedDataDetail.estimateProducts.coeffDeliveryDatesId = record?.[0]?.id;
                              props.selectedDataDetail.estimateProducts.otherProduct.coeffDeliveryDates =
                                record?.[0]?.coeff;
                              let userSetting =
                                props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                              if (userSetting?.length > 0) userSetting = userSetting[0];
                              if (userSetting !== null) {
                                if (userSetting.users?.newParentInitialInfo?.coeffDeliveryDatesId !== undefined) {
                                  userSetting.users.newParentInitialInfo.coeffDeliveryDatesId = record?.[0]?.id;
                                  await updateUserSetting(userSetting, 0);
                                }
                              }
                            }}
                            style={{ marginLeft: 5, width: '94%' }}
                          >
                            {deliveryDateCoeffs
                              ?.sort((a, b) => Number(a?.sortNum) - Number(b?.sortNum))
                              .map((option, index) => (
                                <Select.Option key={index} value={option.id}>
                                  {option.name}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <Select style={{ marginLeft: 5, width: '94%' }} />
                        )}
                        {/* ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getNameById(coeffDeliveryDatesId, deliveryDateCoeffs)}
                    </label>
                  )} */}
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">納期係数</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '94%' }}
                            className={'input-non-editable'}
                            name="deliveryCoefficient"
                            id="deliveryCoefficient"
                            type="text"
                            value={toDecimal(deliveryCoefficient, 2)}
                          />
                        </div>
                      </td>
                    </tr>
                    {/** 購入品 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">購入品</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <div style={{ display: 'flex' }}>
                          {/* {editMode ? ( */}
                          {purchaseCoeffs !== undefined && purchaseCoeffs.length > 0 ? (
                            <Select
                              className="iq3-detail-select input-editable"
                              value={coeffPurchasesId}
                              onChange={async (e) => {
                                setCoeffPurchasesId(e);
                                // const data = parentEstimateInfo;
                                // let record = purchaseCoeffs?.filter((record) => record.id === e);
                                // setPurchaseCoefficient(record[0].coeff);
                                // setCoeffPurchasesName(record?.[0].name);
                                // data.estimateProducts['coeffPurchasesName'] = record?.[0].name;
                                // data.estimateProducts['coeffPurchasesId'] = record?.[0].id;
                                // data.estimateProducts['otherProduct']['coeffPurchases'] = record?.[0].coeff;
                                // setParentEstimateInfo(data);

                                let record = purchaseCoeffs?.filter((record) => record.id === e);
                                setPurchaseCoefficient(record[0].coeff);
                                props.selectedDataDetail.estimateProducts.coeffPurchasesName = record?.[0]?.name;
                                props.selectedDataDetail.estimateProducts.coeffPurchasesId = record?.[0]?.id;
                                props.selectedDataDetail.estimateProducts.otherProduct.coeffPurchases =
                                  record?.[0]?.coeff;
                                let userSetting =
                                  props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                                if (userSetting?.length > 0) userSetting = userSetting[0];
                                if (userSetting !== null) {
                                  if (userSetting.users?.newParentInitialInfo?.coeffPurchasesId !== undefined) {
                                    userSetting.users.newParentInitialInfo.coeffPurchasesId = record?.[0]?.id;
                                    await updateUserSetting(userSetting, 0);
                                  }
                                }
                              }}
                              style={{ marginLeft: 5, width: '94%' }}
                            >
                              {purchaseCoeffs
                                ?.sort((a, b) => Number(a?.sortNum) - Number(b?.sortNum))
                                .map((option, index) => (
                                  <Select.Option key={index} value={option.id}>
                                    {option.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          ) : (
                            <Select style={{ marginLeft: 5, width: '94%' }} />
                          )}
                          {/* ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameById(coeffPurchasesId, purchaseCoeffs)}
                      </label>
                    )} */}
                        </div>
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">購入品係数</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '94%' }}
                            className={'input-non-editable'}
                            name="purchaseCoefficient"
                            id="purchaseCoefficient"
                            type="text"
                            value={toDecimal(purchaseCoefficient, 2)}
                          />
                        </div>
                      </td>
                    </tr>
                    {/** 管理方法 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">管理方法</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <div style={{ display: 'flex' }}>
                          {/* {editMode ? ( */}
                          {mangCoeffs !== undefined && mangCoeffs.length > 0 ? (
                            <Select
                              className="iq3-detail-select input-editable"
                              value={coeffManagementsId}
                              onChange={async (e) => {
                                setCoeffManagementsId(e);
                                // const data = parentEstimateInfo;
                                // let record = mangCoeffs?.filter((record) => record.id === e);
                                // setManagementCoefficient(record[0].coeff);
                                // setCoeffManagementsName(record?.[0].name);
                                // data.estimateProducts['coeffManagementsName'] = record?.[0].name;
                                // data.estimateProducts['coeffManagementsId'] = record?.[0].id;
                                // data.estimateProducts['otherProduct']['coeffManagements'] = record?.[0].coeff;
                                // setParentEstimateInfo(data);

                                let record = mangCoeffs?.filter((record) => record.id === e);
                                setManagementCoefficient(record?.[0]?.coeff);
                                props.selectedDataDetail.estimateProducts.coeffManagementsName = record?.[0]?.name;
                                props.selectedDataDetail.estimateProducts.coeffManagementsId = record?.[0]?.id;
                                props.selectedDataDetail.estimateProducts.otherProduct.coeffManagements =
                                  record?.[0]?.coeff;
                                let userSetting =
                                  props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                                if (userSetting?.length > 0) userSetting = userSetting[0];
                                if (userSetting !== null) {
                                  if (userSetting.users?.newParentInitialInfo?.coeffManagementsId !== undefined) {
                                    userSetting.users.newParentInitialInfo.coeffManagementsId = record?.[0]?.id;
                                    await updateUserSetting(userSetting, 0);
                                  }
                                }
                              }}
                              style={{ marginLeft: 5, width: '94%' }}
                            >
                              {mangCoeffs
                                ?.sort((a, b) => Number(a?.sortNum) - Number(b?.sortNum))
                                .map((option, index) => (
                                  <Select.Option key={index} value={option.id}>
                                    {option.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          ) : (
                            <Select style={{ marginLeft: 5, width: '94%' }} />
                          )}
                          {/* ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameById(coeffManagementsId, mangCoeffs)}
                      </label>
                    )} */}
                        </div>
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">管理係数</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '94%' }}
                            className={'input-non-editable'}
                            name="managementCoefficient"
                            id="managementCoefficient"
                            type="text"
                            value={toDecimal(managementCoefficient, 2)}
                          />
                        </div>
                      </td>
                    </tr>
                    {/** 配送方法 */}
                    {/* <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">配送方法</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={shippingMethod}
                      onChange={(e) => {
                        setShippingMethod(e);
                        const data = parentEstimateInfo;
                        data['shippingMethod'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {deadLineSelect?.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{shippingMethod}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">配送エリア</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={shippingArea}
                      onChange={(e) => {
                        setShippingArea(e);
                        y;
                        const data = parentEstimateInfo;
                        data['shippingArea'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {deadLineSelect?.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{shippingArea}</label>
                  )}
                </td>
              </tr> */}
                    {/** 総重量・面積 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">総重量（kg）</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <label style={{ marginLeft: '5px' }}>{toDecimal(totalWeight, 2)}</label>

                        {/* <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="totalWeight"
                    id="totalWeight"
                    value={totalWeight}
                    type="text"
                    onChange={(e) => {
                      setTotalWeight(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  /> */}
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">
                          総表面積（cm<sup>2</sup>）
                        </label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <label style={{ marginLeft: '5px' }}>{toDecimal(totalSurfaceArea, 2)}</label>

                        {/* <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="totalSurfaceArea"
                      id="totalSurfaceArea"
                      type="text"
                      onChange={(e) => {
                        setTotalSurfaceArea(e.target.value);
                        const data = parentEstimateInfo;
                        data.estimateProducts[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={totalSurfaceArea}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    /> */}
                        {/* {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )} */}
                      </td>
                    </tr>
                    {/** 作成者・最終更新者 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">作成者</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {/* {editMode ? ( */}
                        {staffList !== undefined && staffList.length > 0 ? (
                          <Select
                            className="iq3-detail-select"
                            value={createrId}
                            onChange={async (e) => {
                              let createrName = getNameByRecordId(e, staffList);
                              setCreaterId(e);
                              // const data = parentEstimateInfo;
                              // data.estimateProducts['creater'] = createrName;
                              // data.estimateProducts['createrId'] = e;
                              // setParentEstimateInfo(data);

                              props.selectedDataDetail.estimateProducts.creater = createrName;
                              props.selectedDataDetail.estimateProducts.createrId = e;
                              let userSetting =
                                props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                              if (userSetting?.length > 0) userSetting = userSetting[0];
                              if (userSetting !== null) {
                                if (userSetting.users?.newParentInitialInfo?.createrId !== undefined) {
                                  userSetting.users.newParentInitialInfo.createrId = e;
                                  await updateUserSetting(userSetting, 0);
                                }
                              }
                            }}
                            style={{ marginLeft: 5, width: '94%' }}
                          >
                            {staffList
                              ?.filter((i) => i?.info?.isUsed)
                              ?.map((option, index) => (
                                <Select.Option key={index} value={option.id}>
                                  {option.name}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <Select style={{ marginLeft: 5, width: '94%' }} />
                        )}
                        {/* ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getNameByRecordId(createrId, staffList)}
                    </label>
                  )} */}
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">最終更新者</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          {/* {editMode ? ( */}
                          {staffList !== undefined && staffList.length > 0 ? (
                            <Select
                              className="iq3-detail-select"
                              value={modifierId}
                              onChange={async (e) => {
                                let modifierName = getNameByRecordId(e, staffList);
                                setModifierId(e);
                                // const data = parentEstimateInfo;
                                // data.estimateProducts['modifier'] = modifierName;
                                // data.estimateProducts['modifierId'] = e;
                                // setParentEstimateInfo(data);
                                props.selectedDataDetail.estimateProducts.modifier = modifierName;
                                props.selectedDataDetail.estimateProducts.modifierId = e;
                                let userSetting =
                                  props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
                                if (userSetting?.length > 0) userSetting = userSetting[0];
                                if (userSetting !== null) {
                                  if (userSetting.users?.newParentInitialInfo?.modifierId !== undefined) {
                                    userSetting.users.newParentInitialInfo.modifierId = e;
                                    await updateUserSetting(userSetting, 0);
                                  }
                                }
                              }}
                              style={{ marginLeft: 5, width: '94%' }}
                            >
                              {staffList
                                ?.filter((i) => i?.info?.isUsed)
                                .map((option, index) => (
                                  <Select.Option key={index} value={option.id}>
                                    {option.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          ) : (
                            <Select style={{ marginLeft: 5, width: '94%' }} />
                          )}
                          {/* ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameByRecordId(modifierId, staffList)}
                      </label>
                    )} */}
                        </div>
                      </td>
                    </tr>
                    {/** 作成日・更新日 */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">作成日</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {/* {editMode ? ( */}
                        {/* <DatePicker
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    onChange={onChangeCreatedDate}
                    value={dayjs(created, 'YYYY-MM-DD')}
                  /> */}
                        {/*IQX_WEBEST-339 保存していたデータを呼び出し、上賀書き保存しても更新日が変わりません。*/}
                        <label style={{ fontSize: '13px', paddingLeft: 10 }}>{formatDate(created)}</label>
                      </td>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">更新日</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        {' '}
                        <div style={{ display: 'flex' }}>
                          {/* {editMode ? (
                      <DatePicker
                        style={{ padding: 5, marginLeft: 5, width: '94%' }}
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        onChange={onChangeModifiedDate}
                        value={dayjs(modified, 'YYYY-MM-DD')}
                      />
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>{formatDate(modified)}</label>
                    )} */}
                          {/*IQX_WEBEST-339 保存していたデータを呼び出し、上賀書き保存しても更新日が変わりません。*/}
                          <label style={{ fontSize: '13px', paddingLeft: 10 }}>{formatDate(modified)}</label>
                        </div>
                      </td>
                    </tr>
                    {/** XYZサイズ（mm） */}
                    <tr>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">XYZサイズ（mm）</label>
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '94%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="sizeX"
                          id="sizeX"
                          value={sizeX}
                          type="text"
                          onBlur={(e) => onChangeXYZSize(e.target.value, 'sizeX')}
                          onPressEnter={(e) => onChangeXYZSize(e.target.value, 'sizeX')}
                          onChange={(e) => onChangeXYZSize(e.target.value, 'sizeX', false)}
                          placeholder="Xサイズ"
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        />
                      </td>
                      <td
                        colSpan={2}
                        style={{ width: '23%', border: '0.1px solid #a5a5a5' }}
                        className="detail-tbl-val"
                      >
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '94%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="sizeY"
                          id="sizeY"
                          value={sizeY}
                          type="text"
                          onBlur={(e) => onChangeXYZSize(e.target.value, 'sizeY')}
                          onPressEnter={(e) => onChangeXYZSize(e.target.value, 'sizeY')}
                          onChange={(e) => onChangeXYZSize(e.target.value, 'sizeY', false)}
                          placeholder="Yサイズ"
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        />
                      </td>
                      <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                        <Input
                          style={{ padding: 5, marginLeft: 5, width: '94%' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="sizeZ"
                          id="sizeZ"
                          value={sizeZ}
                          type="text"
                          onBlur={(e) => onChangeXYZSize(e.target.value, 'sizeZ')}
                          onPressEnter={(e) => onChangeXYZSize(e.target.value, 'sizeZ')}
                          onChange={(e) => onChangeXYZSize(e.target.value, 'sizeZ', false)}
                          placeholder="Zサイズ"
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        />
                      </td>
                    </tr>
                    {/** コメント */}
                    <tr style={{ height: '110px' }}>
                      <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                        <label className="detail-tbl-val-title-label">コメント</label>
                      </td>
                      <td colSpan={10} style={{ width: '80%', height: '174px' }} className="detail-tbl-val">
                        <TextArea
                          style={{ padding: 8, marginLeft: 5, width: '98%', verticalAlign: 'Top', fontSize: '13px' }}
                          className={editMode ? 'input-editable' : 'input-non-editable'}
                          name="comment"
                          id="comment"
                          value={comment}
                          type="text"
                          rows={7}
                          onChange={(e) => {
                            setComment(e.target.value);
                            // const data = parentEstimateInfo;
                            // data.estimateProducts[e.target.name] = e.target.value;
                            // setParentEstimateInfo(data);
                            props.selectedDataDetail.estimateProducts.comment = e.target.value;
                          }}
                        />
                      </td>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div style={{ float: 'right', width: '45%', marginLeft: '5px', overflowY: 'auto', height: '80.5vh' }}>
                <Table className="propertiesTbl">
                  <thead>
                    {/* 費用 */}
                    <tr>
                      <td colSpan={12} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          {customizeOpen ? (
                            <CaretUpOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => customizeOpenDetail(e, false)}
                            />
                          ) : (
                            <CaretDownOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => customizeOpenDetail(e, true)}
                            />
                          )}
                          <label style={{ marginLeft: 5 }}>費用</label>
                        </div>
                      </td>
                    </tr>
                    <tr style={{ display: customCostLst?.length > 0 && customizeOpen ? '' : 'none' }}>
                      <td
                        colSpan={3}
                        className="detail-tbl-val-title"
                        style={{ textAlign: 'center', backgroundColor: '#d9d9d99e', color: 'black' }}
                      >
                        <label style={{ marginLeft: 5 }}>名称</label>
                      </td>
                      <td
                        colSpan={3}
                        className="detail-tbl-val-title"
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#d9d9d99e',
                          color: 'black',
                          borderLeft: '0.1px dashed #a5a5a5',
                        }}
                      >
                        <label style={{ marginLeft: 5 }}>単価</label>
                      </td>
                      <td
                        colSpan={3}
                        className="detail-tbl-val-title"
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#d9d9d99e',
                          color: 'black',
                          borderLeft: '0.1px dashed #a5a5a5',
                        }}
                      >
                        <label style={{ marginLeft: 5 }}>単価入力</label>
                        <Checkbox
                          style={{ marginLeft: '5px' }}
                          disabled={editMode ? false : true}
                          onChange={(e) => settingTankaPriority(e)}
                          checked={tankaPriotiryChecked}
                        />
                      </td>
                      <td
                        colSpan={3}
                        className="detail-tbl-val-title"
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#d9d9d99e',
                          color: 'black',
                          borderLeft: '0.1px dashed #a5a5a5',
                        }}
                      >
                        <label style={{ marginLeft: 5 }}>合計</label>
                      </td>
                    </tr>
                    {customCostLst != undefined
                      ? customCostLst?.map((item, index) => {
                          const { additionalItemName, unitPrice, isUseUnit, totalPrice } = item;
                          // 追加項目の費用マスタに存在する場合、表示

                          return (
                            <tr key={index} style={{ display: customizeOpen ? '' : 'none' }}>
                              <td colSpan={3} style={{ width: '23%' }} className="detail-tbl-val">
                                <Input
                                  style={{
                                    padding: 5,
                                    marginLeft: 5,
                                    width: '91.7%',
                                    textAlign: 'center',
                                    border: 'none',
                                  }}
                                  // className={editMode ? 'input-editable' : 'input-non-editable'}
                                  className={'input-non-editable'}
                                  name="additionalItemName"
                                  id="additionalItemName"
                                  value={additionalItemName}
                                  type="text"
                                />
                              </td>
                              <td
                                colSpan={3}
                                style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                                className="detail-tbl-val"
                              >
                                {isUseUnit ? (
                                  <CurrencyInput
                                    name="unitPrice"
                                    id="unitPrice"
                                    value={unitPrice}
                                    defaultValue={0}
                                    style={{ padding: 3, width: '91.7%' }}
                                    className={
                                      isUseUnit
                                        ? 'currencyInputCost input-editable'
                                        : 'currencyInputCost input-non-editable'
                                    }
                                    prefix="¥"
                                    decimalsLimit={100}
                                    onValueChange={(e) => onArrValChange(index, e, 'unitPrice', 'cost')}
                                  />
                                ) : (
                                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                                    {unitPrice ? JPYs.format(unitPrice) : JPYs.format(0)}
                                  </label>
                                )}
                              </td>
                              <td
                                colSpan={3}
                                style={{ width: '23%', textAlign: 'center', borderLeft: '0.1px dashed #a5a5a5' }}
                                className="detail-tbl-val"
                              >
                                <Checkbox
                                  id={index}
                                  disabled={editMode ? false : true}
                                  checked={isUseUnit}
                                  onChange={(e) => {
                                    const data = [...customCostLst];
                                    data[index].isUseUnit = e.target.checked;
                                    const isAllUsedTanka = data?.filter((item) => item.isUseUnit === false);
                                    if (isAllUsedTanka?.length === 0) {
                                      setTankaPriotiryChecked(true);
                                    } else {
                                      setTankaPriotiryChecked(false);
                                    }
                                    setCustomCostLst(data);
                                    props.selectedDataDetail.epCustomCosts = data;
                                  }}
                                />
                              </td>
                              <td
                                colSpan={3}
                                style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                                className="detail-tbl-val"
                              >
                                {!isUseUnit ? (
                                  <CurrencyInput
                                    name="totalPrice"
                                    className="currencyInputCost"
                                    id="totalPrice"
                                    value={totalPrice}
                                    defaultValue={0}
                                    style={{ padding: 3, width: '91.7%' }}
                                    prefix="¥"
                                    decimalsLimit={100}
                                    onValueChange={(e) => onArrValChange(index, e, 'totalPrice', 'cost')}
                                  />
                                ) : (
                                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                                    {totalPrice ? JPYs.format(totalPrice) : JPYs.format(0)}
                                  </label>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                    {/* 日付 */}
                    <tr>
                      <td colSpan={12} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          {dateOpen ? (
                            <CaretUpOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => dateOpenDetail(e, false)}
                            />
                          ) : (
                            <CaretDownOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => dateOpenDetail(e, true)}
                            />
                          )}
                          <label style={{ marginLeft: 5 }}>日付</label>
                        </div>
                      </td>
                    </tr>
                    {customDateLst != undefined
                      ? customDateLst?.map((item, index) => {
                          const { additionalItemId, additionalItemName, date } = item;
                          // 追加項目の費用マスタに存在する場合、表示
                          return (
                            <tr key={index} style={{ display: dateOpen ? '' : 'none' }}>
                              <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                                <Input
                                  style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                                  className={'input-non-editable'}
                                  name={'labelDate' + additionalItemId}
                                  id={'labelDate' + additionalItemId}
                                  value={additionalItemName}
                                  type="text"
                                />
                              </td>
                              <td
                                colSpan={10}
                                style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                                className="detail-tbl-val"
                              >
                                {editMode ? (
                                  <DatePicker
                                    name={'date' + additionalItemId}
                                    id={'date' + additionalItemId}
                                    style={{ padding: 5, marginLeft: 5, width: '29.7%' }}
                                    className={editMode ? 'input-editable' : 'input-non-editable'}
                                    onChange={(e, dateString) => onArrValChange(index, dateString, 'date', 'date')}
                                    value={date ? dayjs(date, 'YYYY-MM-DD') : ''}
                                  />
                                ) : (
                                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                                    {date ? formatDate(date) : ''}
                                  </label>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                    {/* ラベル */}
                    <tr>
                      <td colSpan={12} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          {lableSmallOpen ? (
                            <CaretUpOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => labelSmallOpenDetail(e, false)}
                            />
                          ) : (
                            <CaretDownOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => labelSmallOpenDetail(e, true)}
                            />
                          )}
                          <label style={{ marginLeft: 5 }}>ラベル</label>
                        </div>
                      </td>
                    </tr>
                    {customLabelLst != undefined
                      ? customLabelLst?.map((item, index) => {
                          const { additionalItemName, label } = item;
                          // 追加項目の費用マスタに存在する場合、表示
                          return (
                            <tr key={index} style={{ display: lableSmallOpen ? '' : 'none' }}>
                              <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                                <Input
                                  style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                                  className={'input-non-editable'}
                                  name="additionalItemName"
                                  id="labelAdditionalItemName"
                                  value={additionalItemName}
                                  type="text"
                                />
                              </td>
                              <td
                                colSpan={10}
                                style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                                className="detail-tbl-val"
                              >
                                <Input
                                  style={{ padding: 5, marginLeft: 5, width: '30%' }}
                                  className={editMode ? 'input-editable' : 'input-non-editable'}
                                  name="label"
                                  id="labelLabel"
                                  value={label}
                                  type="text"
                                  onChange={(e) => onArrValChange(index, e, e.target.name, 'label')}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : ''}

                    {/** メモ */}
                    <tr>
                      <td colSpan={12} className="detail-tbl-val-title">
                        <div style={{ display: 'flex' }}>
                          {labelOpen ? (
                            <CaretUpOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => labelOpenDetail(e, false)}
                            />
                          ) : (
                            <CaretDownOutlined
                              style={{ fontSize: 18, marginLeft: 5 }}
                              onClick={(e) => labelOpenDetail(e, true)}
                            />
                          )}
                          <label style={{ marginLeft: 5 }}>メモ</label>
                        </div>
                      </td>
                    </tr>
                    {customMemoLst != undefined
                      ? customMemoLst?.map((item, index) => {
                          const { additionalItemName, memo } = item;
                          // 追加項目の費用マスタに存在する場合、表示
                          return (
                            <tr key={index} style={{ display: labelOpen ? '' : 'none' }}>
                              <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                                <Input
                                  style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                                  className={'input-non-editable'}
                                  name="additionalItemName"
                                  id="labelAdditionalItemName"
                                  value={additionalItemName}
                                  type="text"
                                />
                              </td>
                              <td
                                colSpan={10}
                                style={{
                                  width: '80%',
                                  borderLeft: '0.1px dashed #a5a5a5',
                                }}
                                className="detail-tbl-val"
                              >
                                <Input
                                  style={{ padding: 5, marginLeft: 5, width: '98%' }}
                                  className={editMode ? 'input-editable' : 'input-non-editable'}
                                  name="memo"
                                  id="labelMemo"
                                  value={memo}
                                  type="text"
                                  onChange={(e) => onArrValChange(index, e, e.target.name, 'memo')}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                  </thead>
                </Table>
              </div>
            </div>

            {customerModal ? (
              <SearchAndGetCustomerData
                customerModal={customerModal}
                selectedCustomerData={selectedCustomerData}
                title={Customer}
                clientType={ClientType.Customer}
                closeCustomerModal={closeCustomerModal}
                selectedEstimateData={props.selectedDataDetail?.estimateProducts}
              />
            ) : (
              ''
            )}
            {/* 価格調整 */}
            {isAdjustPrice
              ? commonModal(
                  isAdjustPrice,
                  adjustPriceTitle,
                  commonFooter(adjustPriceOK, adjustPriceCancel),
                  null,
                  420,
                  adjustPriceOK,
                  adjustPriceCancel,
                  adjustPriceContent,
                  null,
                  null,
                  false
                )
              : ''}
          </>
        ) : (
          ''
        )}
      </>
    </Spin>
  );
});

export default ParentEstimateDetail;
