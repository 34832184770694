import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  secondsToHms,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_2jikakou = forwardRef((props, ref) => {
  const [isNijikakoudetail, setNijikakoudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [secondaryWorkAmt, setSecondaryWorkAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [deviceCharges, setDeviceCharges] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setSecondaryWorkAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmSecondaryWork);
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      dandoriAmtSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.round(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) + (dandoriAmtSum ? Math.round(dandoriAmtSum) : 0); // 画面表示用（四捨五入した金額を足す）
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCalIQ3 || props?.iq3ActionForCal) {
      let editData = [];
      let totals = [];
      let processMst = [];
      let deviceMst = []; //テスト用ID;
      let toBEdit = [];
      let totalSum = 0;
      let totalTimeSum = 0;
      setDandoriAmtSum(0);
      setDandoriTimeSum(0);
      setSagyoAmtSum(0);
      setSagyoTimeSum(0);
      setTotalTimeSum(0);
      setTotalSum(0);

      let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
      // 数量
      let quantity = props?.selectedEstimateData?.estimateProducts?.quantity
        ? Number(props?.selectedEstimateData?.estimateProducts?.quantity)
        : 1;
      // 員数
      let partCnt = props?.selectedIQ3DataDetail?.partCount ? Number(props?.selectedIQ3DataDetail?.partCount) : 1;
      if (parameters?.process?.length > 0) {
        processMst = parameters?.process
          ? parameters?.process?.filter(
              (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
            )[0]
          : [];
        setProcessMst(processMst ? processMst : []);
        if (parameters?.device?.length > 0) {
          deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
        }
      }

      let patternInfo = props?.selectedIQ3DataDetail?.processSelect
        ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
        : [];
      if (patternInfo == undefined) return;
      if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

      deviceMst =
        deviceMst && patternInfo
          ? deviceMst?.filter(
              (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
            )[0]
          : []; //テスト用ID;
      setDeviceMst(deviceMst ? deviceMst : []);
      if (deviceMst == undefined) return;
      // 選択された行の工程積算情報を正しく取得するための対応
      let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
        (item) => item.no === props?.selectedIQ3DataDetail?.no
      );
      let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
      if (selectedIQ3DataDetail?.length === 0) return;
      let secondaryWorkAdditions = selectedIQ3DataDetail?.additions?.filter(
        (item) => item.processId == processMst?.id
      )[0];

      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      let nijiAdditionEditData = secondaryWorkAdditions?.editItems;
      let editDataFlg = secondaryWorkAdditions?.editDataFlg;

      // 2次加工工程入力情報取得
      let secondaryWorkProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == processMst?.id
      )[0];

      let detailItems = [];
      let editDetailItems = [];
      //2次加工種類（サイズ判断）設定
      let sizeProcessInputs = secondaryWorkProcessInput?.details?.filter(
        (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
      );
      for (let j = 0; j < sizeProcessInputs?.length; j++) {
        let sizeProcessInput = sizeProcessInputs[j];
        let processTypeNm = processMst?.details?.processDetailTypes?.filter(
          (item) => item.id == sizeProcessInput.types && item.group == sizeProcessInput.workTypeGroup
        )?.[0];
        detailItems.push({
          id: j + 1,
          processName: '2次加工処理(No' + (j + 1) + ')',
          processTypeId: processTypeNm?.id,
          processType: processTypeNm?.name,
          dandoriAmt: 0,
          dandoriTime: 0,
          sagyoAmt: 0,
          sagyoTime: 0,
          workTimeSize: 0, //1つ当たりの加工時間
          diameter: Number(sizeProcessInput.diameter), //径
          processCnt: Number(sizeProcessInput.count), // 数
          blankFlag: sizeProcessInput.blankFlag, // ブランクフラグ
          secondaryWorkType: sizeProcessInput.workTypeGroup, // 2次加工種類（サイズ判断）/ 2次加工種類（ミリメートル判断）
          // processCnt: partCnt,
          formula: '',
        });
      }

      //2次加工種類（ミリメートル判断）設定
      let millimeterProcessInputs = secondaryWorkProcessInput?.details?.filter(
        (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
      );
      for (let j = 0; j < millimeterProcessInputs?.length; j++) {
        let millimeterProcessInput = millimeterProcessInputs[j];
        let processTypeNm = processMst?.details?.processDetailTypes?.filter(
          (item) => item.id == millimeterProcessInput.types && item.group == millimeterProcessInput.workTypeGroup
        )?.[0];
        let detailItemsId = detailItems?.length == 0 ? 0 : detailItems?.length;
        detailItems.push({
          id: detailItemsId + j + 1,
          processName: '2次加工処理(No' + (j + 1) + ')',
          processTypeId: processTypeNm?.id,
          processType: processTypeNm?.name + '(M' + millimeterProcessInput.diameter + ')',
          dandoriAmt: 0,
          dandoriTime: 0,
          sagyoAmt: 0,
          sagyoTime: 0,
          workTimeSize: 0, //1つ当たりの加工時間
          diameter: Number(millimeterProcessInput.diameter), //径
          processCnt: Number(millimeterProcessInput.count), // 数
          blankFlag: millimeterProcessInput.blankFlag, // ブランクフラグ
          secondaryWorkType: millimeterProcessInput.workTypeGroup, // 2次加工種類（サイズ判断）/ 2次加工種類（ミリメートル判断）
          // processCnt: processCnt,
          formula: '',
        });
      }

      editDetailItems = [];
      let secondaryWorkAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: secondaryWorkAdditions?.dataType ? secondaryWorkAdditions?.dataType : DataType.Data,
        dataItems: detailItems,
        editItems: secondaryWorkAdditions?.editItems ? secondaryWorkAdditions?.editItems : editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
        editDataFlg: editDataFlg,
      };
      secondaryWorkAdditions = secondaryWorkAddition;

      if (secondaryWorkAdditions?.dataItems) {
        let orgData = JSON.parse(JSON.stringify(secondaryWorkAdditions?.dataItems));
        if (deviceMst != undefined) {
          let totalDandori = 0;
          let totalSagyo = 0;
          let totalSagyoTm = 0;
          let totalDandoriTm = 0;
          let dataItemsByProcessInput = [];

          // 板厚
          let thickness = parameters?.materialIq3?.filter(
            (item) => item.id == props?.selectedIQ3DataDetail?.thickness
          )?.[0]?.info?.thick;
          // 重量
          let weight = Number(props?.selectedIQ3DataDetail?.weight);
          // 面積
          let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst);
          //総重量が0、子部品数が0の場合、段取と加工を計算しない。
          if (weight === 0 && area === 0) {
            let dandoriSameTypeArr = [];

            let mainSecondaryWorkAdditions = orgData?.filter(
              (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkSize
            );
            let mainSecondaryWorkProcessInput = secondaryWorkProcessInput?.details?.filter(
              (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
            );
            for (let i = 0; i < mainSecondaryWorkProcessInput?.length; i++) {
              //工程入力情報
              let secondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[i];

              let dataItemByProcessInput = mainSecondaryWorkAdditions[i];

              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) =>
                  item.id == secondaryWorkProcessInputInfo.types &&
                  item.group == secondaryWorkProcessInputInfo?.workTypeGroup
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;

              let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
                ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
              )?.[0];
              setDeviceCharges(deviceCharges);

              let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
              if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
                let dandoriTimeSec = 0;
                let dandoriTime = dandoriTimeSec;
                dataItemByProcessInput.dandoriTime = dandoriTime;

                totalDandoriTm += dandoriTime;

                let dandoriAmt = 0;
                dataItemByProcessInput.dandoriAmt = dandoriAmt;
                totalDandori += dandoriAmt;
              } else {
                dataItemByProcessInput.dandoriTime = 0;
                dataItemByProcessInput.dandoriAmt = 0;
              }

              let sagyoTimePerSec = 0;

              let sagyoTimeSec = 0;
              dataItemByProcessInput.sagyoTime = 0;
              dataItemByProcessInput.workTimeSize = 0;
              totalSagyoTm += sagyoTimeSec;

              let sagyoAmt = 0;
              dataItemByProcessInput.sagyoAmt = sagyoAmt;

              // 作業金額の合計
              totalSagyo += sagyoAmt;
              dataItemsByProcessInput.push(dataItemByProcessInput);
              dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
            }

            let millimeterSecondaryWorkAdditions = orgData?.filter(
              (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkMillimeter
            );
            let millimeterSecondaryProcessType = secondaryWorkProcessInput?.details?.filter(
              (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
            );
            for (let i = 0; i < millimeterSecondaryProcessType?.length; i++) {
              //工程入力情報
              let secondaryWorkProcessInputInfo = millimeterSecondaryProcessType[i];
              if (millimeterSecondaryWorkAdditions?.length == 0) continue;
              //径がない場合、計算しない。
              if (Number(secondaryWorkProcessInputInfo?.diameter) === 0) continue;

              let dataItemByProcessInput = millimeterSecondaryWorkAdditions[i];

              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) =>
                  item.id == secondaryWorkProcessInputInfo.types &&
                  item.group == secondaryWorkProcessInputInfo?.workTypeGroup
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;

              let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
                ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
              )?.[0];
              /** 段取金額/時間 */
              // 基本段取時間＊サイズ係数
              let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
              if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
                let dandoriTimeSec = 0;
                let dandoriTime = dandoriTimeSec;
                dataItemByProcessInput.dandoriTime = 0;
                // 段取時間の合計
                totalDandoriTm += dandoriTime;

                let dandoriAmt = 0;

                dataItemByProcessInput.dandoriAmt = 0;
                // 段取金額の合計
                totalDandori += dandoriAmt;
              } else {
                dataItemByProcessInput.dandoriTime = 0;
                dataItemByProcessInput.dandoriAmt = 0;
              }

              // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
              // 1つ当たりの加工時間
              let sagyoTimePerSec = 0;

              let sagyoTimeSec = 0;
              dataItemByProcessInput.sagyoTime = sagyoTimeSec;
              dataItemByProcessInput.workTimeSize = sagyoTimePerSec;

              let sagyoAmt = 0;
              dataItemByProcessInput.sagyoAmt = sagyoAmt;

              // 作業金額の合計
              totalSagyo += sagyoAmt;
              dataItemsByProcessInput.push(dataItemByProcessInput);
              dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
            }
            // 段取金額の合計
            setDandoriAmtSum(0);
            secondaryWorkAdditions.totalDandori.dataItemsAmt = 0;
            // 段取時間の合計
            setDandoriTimeSum(0);
            secondaryWorkAdditions.totalDandori.dataItemsTime = 0;
            // 作業金額の合計
            setSagyoAmtSum(0);
            secondaryWorkAdditions.totalSagyo.dataItemsAmt = 0;
            setSagyoTimeSum(0);
            secondaryWorkAdditions.totalSagyo.dataItemsTime = 0;

            /** 　合計時間　*/
            let totalTimeSum = 0;
            setTotalTimeSum(totalTimeSum);
            secondaryWorkAdditions.totalDataTime = totalTimeSum;

            /** 　合計金額　*/
            let totalSum = 0;
            setTotalSum(totalSum);
            secondaryWorkAdditions.totalDataPrice = totalSum;

            orgData = dataItemsByProcessInput;
          } else {
            //材質
            let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materilaTypeIq3Id;

            //材質区分
            let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
              ?.materialTypeId;

            // サイズ係数
            let sizeCoef = getSizeCoeff(deviceMst, weight, area);

            let dandoriSameTypeArr = [];

            let mainSecondaryWorkAdditions = orgData?.filter(
              (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkSize
            );
            let mainSecondaryWorkProcessInput = secondaryWorkProcessInput?.details?.filter(
              (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
            );

            for (let i = 0; i < mainSecondaryWorkProcessInput?.length; i++) {
              //工程入力情報
              let secondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[i];

              if (mainSecondaryWorkAdditions?.length === 0) continue;
              //ブランク"チェックONの場合、計算しない。
              if (secondaryWorkProcessInputInfo?.blankFlag) continue;
              // Mサイズ・径が０の場合、計算しない。（IQX_WEBEST-54）
              let diameter = Number(secondaryWorkProcessInputInfo?.diameter);
              if (diameter === 0) continue;

              let dataItemByProcessInput = mainSecondaryWorkAdditions[i];

              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) =>
                  item.id == secondaryWorkProcessInputInfo.types &&
                  item.group == secondaryWorkProcessInputInfo?.workTypeGroup
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;

              let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
                ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
              )?.[0];
              setDeviceCharges(deviceCharges);
              /** 段取金額/時間 */
              // 基本段取時間＊サイズ係数
              let isSameType = dandoriSameTypeArr?.filter(
                (item) => item.types === secondaryWorkProcessInputInfo?.types
              );
              if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
                let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, secondaryWorkProcessInputInfo?.types);
                dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
                let dandoriTime = dandoriTimeSec;
                dataItemByProcessInput.dandoriTime = dandoriTime;
                // 段取時間の合計
                totalDandoriTm += dandoriTime;

                // 段取金額設定
                //段階時間
                let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;
                //装置の段階時間チャージ

                let mSetHRate = 0;
                deviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSetHRate = item?.prep;
                  }
                });
                let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ =
                dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;

                dataItemByProcessInput.dandoriAmt = dandoriAmt;
                // 段取金額の合計
                totalDandori += dandoriAmt;
              } else {
                dataItemByProcessInput.dandoriTime = 0;
                dataItemByProcessInput.dandoriAmt = 0;
              }

              // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
              // 1つ当たりの加工時間
              let sagyoTimePerSec = getSagyoTime(
                deviceMst,
                Number(secondaryWorkProcessInputInfo?.diameter),
                secondaryWorkProcessInputInfo?.types,
                secondaryWorkProcessInputInfo?.workTypeGroup
              );
              sagyoTimePerSec = sagyoTimePerSec ? sagyoTimePerSec : 0;

              // // IQX_WEBEST-72
              // let sagyoTimeSec = 0;
              // let sagyoTimePerCount = 0;
              // let sameTypeIndex = 0;
              // // 製造数により補正時間計算
              // let manufacturingCnt = quantity * partCnt;
              // let sameTypeCnt = 1;
              // for (let m = 0; m < manufacturingCnt; m++) {
              //   let hoseTime = 0;
              //   let curTypeCount = Number(secondaryWorkProcessInputInfo?.count);
              //   if (m > 0) {
              //     let sameTypeCntByCurType = mainSecondaryWorkProcessInput?.filter(
              //       (item) => item.types === secondaryWorkProcessInputInfo?.types
              //     );
              //     for (let s = 0; s < sameTypeCntByCurType?.length; s++) {
              //       sameTypeCnt += Number(sameTypeCntByCurType[s]?.count);
              //     }
              //     sameTypeIndex = sameTypeCnt;
              //   } else {
              //     // IQX_WEBEST-35
              //     let beforeTypeCount = 0;
              //     if (isSameType?.length > 0) {
              //       for (let j = 0; j < isSameType?.length; j++) {
              //         beforeTypeCount += Number(isSameType[j]?.count);
              //       }
              //       // 同一タイプの何個目か計算
              //       sameTypeIndex = isSameType?.length === 1 ? beforeTypeCount + 1 : beforeTypeCount + curTypeCount;
              //     } else {
              //       sameTypeIndex = 1;
              //     }
              //   }
              //   for (let i = 0; i < curTypeCount; i++) {
              //     let hoseCount = sameTypeIndex + i;
              //     // 加工補正時間
              //     let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
              //       (item) => (item.value == hoseCount || item.value > hoseCount) && !item.isOutOfRange
              //     )?.[0];
              //     hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
              //       (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
              //     )?.[0]?.value;

              //     // 加工補正係数
              //     let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
              //     // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*数量*	サイズ係数）
              //     let hoseMultiply = hoseCoeff * hoseTime;
              //     sagyoTimePerCount += (sagyoTimePerSec + hoseMultiply) * sizeCoef;
              //   }
              // }
              // IQX_WEBEST-72
              let sagyoTimeSec = 0;
              let sagyoTimePerCount = 0;
              let sameTypeIndex = 0;
              let sameTypeCnt = 1;
              let manufacturingCnt = quantity * partCnt;
              for (let m = 0; m < manufacturingCnt; m++) {
                let hoseTime = 0;
                let curTypeCount = Number(secondaryWorkProcessInputInfo?.count);

                let sameTypeCntByCurType = mainSecondaryWorkProcessInput?.filter(
                  (item) => item.types === secondaryWorkProcessInputInfo?.types
                );
                for (let s = 0; s < sameTypeCntByCurType?.length; s++) {
                  if (m > 0 || sameTypeCntByCurType[s]?.id !== secondaryWorkProcessInputInfo?.id) {
                    sameTypeCnt += Number(sameTypeCntByCurType[s]?.count) + m;
                  } else {
                    break;
                  }
                }
                sameTypeIndex = sameTypeCnt;
                for (let i = 0; i < curTypeCount; i++) {
                  let hoseCount = sameTypeIndex + i;
                  // 加工補正時間
                  let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
                    (item) => (item.value == hoseCount || item.value > hoseCount) && !item.isOutOfRange
                  )?.[0];
                  hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
                    (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
                  )?.[0]?.value;

                  // 加工補正係数
                  let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
                  // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*数量*	サイズ係数）
                  let hoseMultiply = hoseCoeff * hoseTime;
                  sagyoTimePerCount += (sagyoTimePerSec + hoseMultiply) * sizeCoef;
                }
              }
              // 加工時間　÷　製造数
              sagyoTimePerCount = Number.isFinite(sagyoTimePerCount / manufacturingCnt)
                ? sagyoTimePerCount / manufacturingCnt
                : 0;
              sagyoTimeSec = sagyoTimePerCount ? sagyoTimePerCount * productionQuantityCoeff : 0; // IQX_WEBEST-278 加工時間＊生産個数係数
              dataItemByProcessInput.sagyoTime = sagyoTimeSec;
              dataItemByProcessInput.workTimeSize = sagyoTimePerSec;
              totalSagyoTm += sagyoTimeSec;
              // 作業金額
              let mSagyoTimeMin = Number.isFinite(sagyoTimeSec / 60) ? sagyoTimeSec / 60 : 0;
              //装置の作業時間チャージ
              let mSagyoHRate = 0;
              deviceCharges?.map((item, index) => {
                if (index > 0) {
                  mSagyoHRate = item?.work;
                }
              });
              let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
              sagyoAmt = Number.isFinite(sagyoAmt) ? sagyoAmt : 0;
              dataItemByProcessInput.sagyoAmt = sagyoAmt;

              // 作業金額の合計
              totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
              dataItemsByProcessInput.push(dataItemByProcessInput);
              dandoriSameTypeArr.push(secondaryWorkProcessInputInfo);
            }

            let millimeterSecondaryWorkAdditions = orgData?.filter(
              (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkMillimeter
            );
            let millimeterSecondaryProcessType = secondaryWorkProcessInput?.details?.filter(
              (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
            );
            for (let i = 0; i < millimeterSecondaryProcessType?.length; i++) {
              //工程入力情報
              let secondaryWorkProcessInputInfo = millimeterSecondaryProcessType[i];
              if (millimeterSecondaryWorkAdditions?.length == 0) continue;
              //径がない場合、計算しない。
              if (Number(secondaryWorkProcessInputInfo?.diameter) === 0) continue;

              let dataItemByProcessInput = millimeterSecondaryWorkAdditions[i];

              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) =>
                  item.id == secondaryWorkProcessInputInfo.types &&
                  item.group == secondaryWorkProcessInputInfo?.workTypeGroup
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;

              let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
                ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
              )?.[0];
              /** 段取金額/時間 */
              // 基本段取時間＊サイズ係数
              let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
              if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
                let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, secondaryWorkProcessInputInfo?.types);
                let dandoriTime = dandoriTimeSec;
                dataItemByProcessInput.dandoriTime = dandoriTime === undefined ? 0 : dandoriTime;
                // 段取時間の合計
                totalDandoriTm += dandoriTime;

                // 段取金額設定
                //段階時間
                let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;
                //装置の段階時間チャージ

                let mSetHRate = 0;
                deviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSetHRate = item?.prep;
                  }
                });
                let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
                dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;

                dataItemByProcessInput.dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
                // 段取金額の合計
                totalDandori += dandoriAmt ? Math.round(dandoriAmt) : 0;
              } else {
                dataItemByProcessInput.dandoriTime = 0;
                dataItemByProcessInput.dandoriAmt = 0;
              }

              // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
              // 1つ当たりの加工時間
              let sagyoTimePerSec = getSagyoTime(
                deviceMst,
                Number(secondaryWorkProcessInputInfo?.diameter),
                secondaryWorkProcessInputInfo?.types,
                secondaryWorkProcessInputInfo?.workTypeGroup
              );
              sagyoTimePerSec = sagyoTimePerSec ? sagyoTimePerSec : 0;
              // 加工補正時間
              let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
                (item) =>
                  (item.value == Number(secondaryWorkProcessInputInfo?.count) ||
                    item.value > Number(secondaryWorkProcessInputInfo?.count)) &&
                  !item.isOutOfRange
              )?.[0];
              let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
                (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
              )?.[0]?.value;
              // 加工補正係数
              let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
              // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
              let hoseMultiply = hoseCoeff * hoseTime;
              let sagyoTimeSec =
                (sagyoTimePerSec + hoseMultiply) * Number(secondaryWorkProcessInputInfo?.count) * sizeCoef;
              dataItemByProcessInput.sagyoTime =
                sagyoTimeSec === undefined ? 0 : sagyoTimeSec * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
              dataItemByProcessInput.workTimeSize = sagyoTimePerSec === undefined ? 0 : sagyoTimePerSec;

              // 作業金額
              let mSagyoTimeMin = dataItemByProcessInput?.sagyoTime / 60;
              //装置の作業時間チャージ
              let mSagyoHRate = 0;
              deviceCharges?.map((item, index) => {
                if (index > 0) {
                  mSagyoHRate = item?.work;
                }
              });
              let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
              dataItemByProcessInput.sagyoAmt = sagyoAmt === undefined ? 0 : sagyoAmt;

              // 作業金額の合計
              totalSagyoTm += dataItemByProcessInput?.sagyoTime;
              totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
              dataItemsByProcessInput.push(dataItemByProcessInput);
              dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
            }
            // 段取金額の合計
            setDandoriAmtSum(totalDandori);
            secondaryWorkAdditions.totalDandori.dataItemsAmt = totalDandori;
            // 段取時間の合計
            setDandoriTimeSum(totalDandoriTm);
            secondaryWorkAdditions.totalDandori.dataItemsTime = totalDandoriTm;
            // 作業金額の合計
            setSagyoAmtSum(totalSagyo);
            secondaryWorkAdditions.totalSagyo.dataItemsAmt = totalSagyo;
            setSagyoTimeSum(totalSagyoTm);
            secondaryWorkAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

            /** 　合計時間　*/
            totalTimeSum = totalDandoriTm + totalSagyoTm;
            setTotalTimeSum(totalTimeSum);
            secondaryWorkAdditions.totalDataTime = totalTimeSum;

            /** 　合計金額　*/
            totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
            setTotalSum(totalSum);
            secondaryWorkAdditions.totalDataPrice = totalSum;

            orgData = dataItemsByProcessInput;
          }
          setProcessArr(orgData);
          secondaryWorkAdditions.dataItems = orgData;
          // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
          if (nijiAdditionEditData?.length > 0 && editDataFlg) {
            toBEdit = JSON.parse(JSON.stringify(nijiAdditionEditData));
          } else {
            toBEdit = JSON.parse(JSON.stringify(orgData));
          }
        }
      }
      if (toBEdit !== undefined) {
        editData = toBEdit;
        secondaryWorkAdditions.editItems = editData;
        /* if (editData?.length > 0) { */
        if (editData !== undefined) {
          setEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum);
          secondaryWorkAdditions.totalEditPrice = totals[0]?.totalSum;
          secondaryWorkAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum;
          secondaryWorkAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

          setTotalTime(totals[0]?.totalTimeSum);
          secondaryWorkAdditions.totalEditTime = totals[0]?.totalTimeSum;
          secondaryWorkAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum;
          secondaryWorkAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum);

          setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
          setTotalDandoriTime(totals[0]?.dandoriTimeSum);
        }
        setUpdateEditedData(secondaryWorkAdditions);
      }
      if (secondaryWorkAdditions?.dataType == DataType.Data) {
        setSecondaryWorkAmt(secondaryWorkAdditions.totalDataPrice);
      } else {
        setSecondaryWorkAmt(secondaryWorkAdditions.totalEditPrice);
      }
      props?.updateIQ3KouteiCal(secondaryWorkAdditions, WorkType.SmSurface);
    }
  }, [props?.isReCalIQ3, props?.iq3ActionForCal, props?.tabOpen, props?.dialogOk]);

  useEffect(() => {
    if (props?.tabOpen) {
      setEditMode(props?.editMode != undefined ? props?.editMode : false);
    }
  }, [props?.editMode]);

  const nijikakou = () => {
    if (isNijikakoudetail == true) {
      setNijikakoudetail(false);
    } else {
      setNijikakoudetail(true);
    }
  };

  const setNijiDisplay = (display) => {
    setNijikakoudetail(display);
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    partsCoeff = partsCoeff ? partsCoeff : 0;
    return partsCoeff;
  };

  const getHoseCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let mtWeightThMaster = deviceMst?.details?.mtWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let mtAreaThMaster = deviceMst?.details?.mtAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      mtWeightThMaster?.length > 0 && mtAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == mtWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // 補正係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == mtAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;
    partsCoeff = partsCoeff ? partsCoeff : 0;
    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, weight, area, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let deviceDandori = Object.entries(deviceMst?.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    let dandoriTimeSec = deviceDandoriTm * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, newState) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = 0;

    let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
      ([key, data]) => parseInt(key) === newState?.processTypeId
    )?.[0];

    deviceCharges?.map((item, index) => {
      if (index > 0) {
        mSetHRate = item?.prep;
      }
    });

    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const getSagyoTime = (deviceMst, sizeMm, typeId, groupTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let thMaster = [];
    let workTime = 0;
    // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    //材質
    let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;
    //材質区分
    let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
      ?.materialTypeId;
    // 板厚
    let thickness = parameters?.materialIq3?.filter((item) => item.id == props?.selectedIQ3DataDetail?.thickness)?.[0]
      ?.info?.thick;

    if (groupTypeId === WorkTypeGroup.SmSecondaryWorkSize) {
      thMaster = deviceMst?.details?.sizeThMaster?.filter(
        (item) => (item.value == sizeMm || item.value > sizeMm) && !item.isOutOfRange
      )?.[0];

      let workTimeSizeThItems = Object.entries(deviceMst?.details?.workTimeSizeThItems)?.filter(
        ([key, data]) => parseInt(key) === typeId && !data.isOutOfRange
      )?.[0];

      workTimeSizeThItems?.map((item, index) => {
        if (index > 0) {
          // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
          let workTimeSizeThItem = item?.filter((item) => parseInt(item.no) == thMaster?.no && !item.isOutOfRange)?.[0];
          if (workTimeSizeThItem) {
            if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
              workTime = workTimeSizeThItem?.value;
            } else {
              let sizeThicknessId = deviceMst?.details?.sizeThicknessThMaster?.filter(
                (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
              )?.[0];
              let workTimeItem = workTimeSizeThItem?.workTimeThickThItems[materialTypeId]?.filter(
                (item) => item.no == sizeThicknessId?.no && !item?.isOutOfRange
              )?.[0];
              if (workTimeItem) {
                workTime = workTimeItem?.value;
              }
            }
          }
        }
      });
    } else {
      thMaster = deviceMst?.details?.mmThMaster?.filter(
        (item) => (item.value == sizeMm || item.value > sizeMm) && !item.isOutOfRange
      )?.[0];

      let workTimeSizeHoleThItems = Object.entries(deviceMst?.details?.workTimeSizeHoleThItems)?.filter(
        ([key, data]) => parseInt(key) === typeId && !data.isOutOfRange
      )?.[0];

      workTimeSizeHoleThItems?.map((item, index) => {
        if (index > 0) {
          // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
          let workTimeSizeThItem = item?.filter((item) => parseInt(item.no) == thMaster?.no && !item.isOutOfRange)?.[0];
          if (workTimeSizeThItem) {
            if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
              workTime = workTimeSizeThItem?.value;
            } else {
              let sizeThicknessId = deviceMst?.details?.sizeThicknessThMaster?.filter(
                (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
              )?.[0];
              let workTimeItem = workTimeSizeThItem?.workTimeThickThItems[materialTypeId]?.filter(
                (item) => item.no == sizeThicknessId?.no && !item?.isOutOfRange
              )?.[0];
              if (workTimeItem) {
                workTime = workTimeItem?.value;
              }
            }
          }
        }
      });
    }
    workTime = workTime ? workTime : 0;
    return workTime;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, newState) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = 0;

    let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
      ([key, data]) => parseInt(key) === newState?.processTypeId
    )?.[0];

    deviceCharges?.map((item, index) => {
      if (index > 0) {
        mWorkHRate = item?.prep;
      }
    });

    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('nijikakouProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('nijikakouProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownNijikakouIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpNijikakouIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('nijikakouProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownNijikakouIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpNijikakouIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setNijiDisplay: setNijiDisplay,
  }));

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    setTotalDandoriAmt(dandoriAmtSum ? dandoriAmtSum : 0);
    setTotalDandoriTime(dandoriTimeSum ? dandoriTimeSum : 0);
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setSecondaryWorkAmt(updateEditedData.totalDataPrice);
    } else {
      setSecondaryWorkAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmSecondaryWork);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let newState = [...editedData];
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let newStateUnique = [...editedData];
    let oldValue = editedData[index][objId];
    if (objId !== 'dandoriTime' && objId !== 'dandoriAmt') {
      oldValue = editedData[index][objId];
      newState[index][objId] = Number(value);
      updateEditedData.editItems[index][objId] = newState[index][objId];
    }

    if (objId === 'dandoriTime' || objId === 'dandoriAmt') {
      oldValue = newStateUnique[index][objId];
      newStateUnique[index][objId] = Number(value);
      updateEditedData.editItems[index][objId] = Number(value);
      if (objId === 'dandoriTime') {
        if (document.getElementById(index + 'NijikakouDandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'NijikakouDandoriAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newStateUnique[index].dandoriAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newStateUnique[index].dandoriAmt = changeAmtwithTime(
                newStateUnique[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId,
                newStateUnique[index]
              );
            } else {
              newStateUnique[index].dandoriAmt = changeAmtwithTime(
                newStateUnique[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId,
                newStateUnique[index]
              );
            }
            updateEditedData.editItems[index].dandoriAmt = newStateUnique[index].dandoriAmt;
          }
        }
      }
      /* newState = newState?.map((item) => {
        const matchingItem = newStateUnique?.find((el) => el.processType === item.processType);
        return matchingItem
          ? { ...item, dandoriAmt: matchingItem.dandoriAmt, dandoriTime: matchingItem.dandoriTime }
          : item;
      }); */
    } else if (objId === 'sagyoTime') {
      checkInputWithColor(false, '', index + 'nijikakouSagyoAmt', newState[index].sagyoAmt, 'green');

      if (document.getElementById(index + 'nijikakouSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'nijikakouSagyoAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'nijikakouSagyoAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (value === 0) {
            newState[index].sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].sagyoAmt = changeAmtwithTime(
              newState[index].sagyoAmt,
              timeType,
              timeAct,
              value,
              objId,
              newStateUnique[index]
            );
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(
              newState[index].sagyoAmt,
              timeType,
              timeAct,
              value,
              objId,
              newStateUnique[index]
            );
          }
          updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          /* checkInputWithColor(false, '', index + 'nijikakouSagyoAmt', newState[index].sagyoAmt, 'green'); */
        }
      }
    } else if (objId === 'processCnt' || objId === 'workTimeSize') {
      newState = calWorkTimeByProcessCntAndBasicWorkTime(objId, value, newState, index);
    }
    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      newSagyoTimeSec += element.sagyoTime;
    });

    newStateUnique?.forEach((element) => {
      newDandoriSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += parseFloat(newState[0].handelingAmt);
    newSagyoTimeSec += newState[0].handelingTime); */
    newSum = (newSagyoSum ? Math.round(newSagyoSum) : 0) + (newDandoriSum ? Math.round(newDandoriSum) : 0); // 画面表示用（四捨五入した金額を足す）

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec; /* + editedKensaTime */

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    // setEditedHandelingAmt(newState[0].handelingAmt);
    // setEditedHandelingTime(newState[0].handelingTime);
    // setEditedKensaAmt(newState[0].kensaAmt);
    // setEditedKensaTime(newState[0].kensaTime);
    setEditedData(newState);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setSecondaryWorkAmt(updateEditedData.totalDataPrice);
    } else {
      setSecondaryWorkAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmSecondaryWork);
  };

  const calWorkTimeByProcessCntAndBasicWorkTime = (objId, value, newState, id) => {
    let index = id;
    // 作業時間（(1つ当たりの加工時間	*	個数	*	曲げ長さ係数)	*	サイズ係数）
    // 1つ当たりの加工時間
    let sagyoTimePerSec = objId === 'workTimeSize' ? value : newState[index].workTimeSize; //300;

    // 入力数量
    let processCnt = objId === 'processCnt' ? Number(value) : newState[index].processCnt;

    // 重量
    let weight = props?.selectedIQ3DataDetail?.weight;
    // 面積
    let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
    // サイズ係数
    let sizeCoef = getSizeCoeff(deviceMst, weight, area);

    // 2次加工工程入力情報取得
    let secondaryWorkProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];

    let mainSecondaryWorkProcessInput = secondaryWorkProcessInput?.details?.filter(
      (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
    );
    // 数量
    let quantity = props?.selectedEstimateData?.estimateProducts?.quantity
      ? Number(props?.selectedEstimateData?.estimateProducts?.quantity)
      : 1;
    // 員数
    let partCnt = props?.selectedIQ3DataDetail?.partCount ? Number(props?.selectedIQ3DataDetail?.partCount) : 1;
    // 製造数により補正時間計算
    let manufacturingCnt = quantity * partCnt;
    let editTargetSecondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[index];
    let editTargetSecondaryWorkProcessInputInfoId = editTargetSecondaryWorkProcessInputInfo?.id;
    for (let i = index; i < mainSecondaryWorkProcessInput?.length; i++) {
      //工程入力情報
      let secondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[i];

      // 1つ当たりの加工時間
      let curSagyoTimePerSec = newState[i].workTimeSize;
      sagyoTimePerSec = i === index ? sagyoTimePerSec : curSagyoTimePerSec;

      // IQX_WEBEST-72
      let sagyoTimeSec = 0;
      let sagyoTimePerCount = 0;
      let sameTypeIndex = 0;
      let sameTypeCnt = 1;
      for (let m = 0; m < manufacturingCnt; m++) {
        let hoseTime = 0;
        let curTypeCount = i === index ? Number(processCnt) : Number(newState[i].processCnt);

        let sameTypeCntByCurType = mainSecondaryWorkProcessInput?.filter(
          (item) => item.types === secondaryWorkProcessInputInfo?.types
        );
        for (let s = 0; s < sameTypeCntByCurType?.length; s++) {
          if (m > 0 || sameTypeCntByCurType[s]?.id !== secondaryWorkProcessInputInfo?.id) {
            let sameCurTypeCount = Number(newState[s].processCnt); //現在編集データ値を取得
            // 編集行の回数で計算
            if (i > index && sameTypeCntByCurType[s]?.id === editTargetSecondaryWorkProcessInputInfoId) {
              sameCurTypeCount = Number(processCnt);
            }
            sameTypeCnt += sameCurTypeCount + m;
          } else {
            break;
          }
        }
        sameTypeIndex = sameTypeCnt;
        for (let i = 0; i < curTypeCount; i++) {
          let hoseCount = sameTypeIndex + i;
          // 加工補正時間
          let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
            (item) => (item.value == hoseCount || item.value > hoseCount) && !item.isOutOfRange
          )?.[0];
          hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
            (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;

          // 加工補正係数
          let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
          // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*数量*	サイズ係数）
          let hoseMultiply = hoseCoeff * hoseTime;
          sagyoTimePerCount += (sagyoTimePerSec + hoseMultiply) * sizeCoef;
        }
      }
      // 加工時間　÷　製造数
      sagyoTimePerCount = Number.isFinite(sagyoTimePerCount / manufacturingCnt)
        ? sagyoTimePerCount / manufacturingCnt
        : 0;
      sagyoTimeSec = sagyoTimePerCount ? sagyoTimePerCount * getProductionQuantityCoeff(deviceMst) : 0; // IQX_WEBEST-278 加工時間＊生産個数係数

      // 作業時間
      newState[i].sagyoTime = sagyoTimeSec;
      updateEditedData.editItems[i].sagyoTime = sagyoTimeSec;

      // 作業金額
      let mSagyoTimeMin = sagyoTimeSec / 60;
      //装置の作業時間チャージ
      let mSagyoHRate = 0;
      if (deviceCharges?.length > 0) {
        deviceCharges?.map((item, index) => {
          if (index > 0) {
            mSagyoHRate = item?.work;
          }
        });
      }

      if (document.getElementById(i + 'nijikakouSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(i + 'nijikakouSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          newState[i].sagyoAmt = Number.isFinite(sagyoAmt) ? sagyoAmt : 0;
          updateEditedData.editItems[i].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
        }
      }
    }

    return newState;
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType, newStateObj) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, newStateObj);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, newStateObj);
    } else if (processType === 'handelingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, newStateObj);
    }
    return editedAmt;
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <>
      {props?.tabOpen && props?.isSecondary ? (
        <div className="kouteiCalculateDiv">
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={nijikakou}>
            <Col span={4} className="oyabuhin-col">
              <div style={{ display: 'flex' }}>
                {isNijikakoudetail ? (
                  <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                )}
                <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
              </div>
            </Col>
            <Col span={6} className="oyabuhin-col">
              <div>{secondaryWorkAmt ? JPYs.format(Math.round(secondaryWorkAmt)) : amtEmptyStr}</div>
            </Col>
            <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
              <div>
                <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
              </div>
            </Col>
          </Row>
          <div style={{ display: isNijikakoudetail ? 'block' : 'none' }}>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                工程詳細
              </Col>
              <Col span={6} className="oyabuhin-detail-col">
                <Checkbox
                  key={kouteiCalculateSelectedArr[0].key}
                  value={kouteiCalculateSelectedArr[0].value}
                  checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
                  disabled={!props?.editMode}
                  style={{ marginRight: 6 }}
                  onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
                ></Checkbox>
                <label>{kouteiCalculateSelectedArr[0].value}</label>
              </Col>
              <Col span={8} className="oyabuhin-detail-col">
                <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                  <Checkbox
                    key={kouteiCalculateSelectedArr[1].key}
                    value={kouteiCalculateSelectedArr[1].value}
                    disabled={!props?.editMode}
                    onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                    checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                    style={{ marginRight: 6 }}
                  ></Checkbox>
                  <label>{kouteiCalculateSelectedArr[1].value}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                  <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                    {DataButton}
                  </Button>
                </div>
              </Col>

              <Col span={1} className="oyabuhin-detail-col">
                単位
              </Col>
              <Col span={5} className="formula-column">
                計算式
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                {processMst?.name}
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalAmtIn"
                  style={{
                    color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalTimeIn"
                  style={{
                    color: Math.round(totalTime) === Math.round(totalTimeSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalTime ? secondsToHms(totalTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.procTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.procTotalTm}>時間</Popover>
              </Col>
            </Row>
            {/* 段取金額/時間 */}
            <Row
              style={{ color: 'black' }}
              className="oyabuhin-detail oyabuhin-row"
              onClick={(e) => handleCollpse('NijikakouDandori')}
            >
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpNijikakouDandori'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />

                  <CaretDownOutlined id={'arrowDownNijikakouDandori'} style={{ fontSize: 17, marginTop: 2 }} />
                  <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriAmt">
                  {dandoriAmtSum ? JPYs.format(Math.round(dandoriAmtSum)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriAmtIn"
                  style={{
                    color: Math.round(totalDandoriAmt) === Math.round(dandoriAmtSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalDandoriAmt ? JPYs.format(Math.round(totalDandoriAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriTimeIn"
                  style={{
                    color: Math.round(totalDandoriTime) === Math.round(dandoriTimeSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.setupTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.setupTotalTm}>時間</Popover>
              </Col>
            </Row>
            <div id={'divNijikakouDandori'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];
                const dandoriSameTypeArr = [];
                for (let j = 0; j < processArr?.length; j++) {
                  //同じ種類の場合、計算や表示しない。
                  let sameType = dandoriSameTypeArr?.filter((item) => item == processArr[j]?.processTypeId);
                  if (sameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
                    arr.push(
                      <div>
                        {/* ○○_標準 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <div style={{ display: 'flex' }}>
                              <span style={{ marginLeft: '20px' }}>{processArr[j]?.processType}</span>
                            </div>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'NijikakouDandoriAmt'}>
                              {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                                ? JPYs.format(Math.round(processArr[j]?.dandoriAmt))
                                : amtEmptyStr}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            {
                              <label id={j + 'NijikakouDndoriTime'}>
                                {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                              </label>
                            }
                          </Col>

                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'NijikakouDandoriAmtIn'}
                              value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                              defaultValue={0}
                              style={{
                                border: 'none',
                                color:
                                  processArr[j]?.dandoriAmt === editedData[j]?.dandoriAmt
                                    ? 'black'
                                    : checkShowHideIcon(j + 'NijikakouDandoriAmtCu') === 'none'
                                    ? '#4cc12f'
                                    : 'red',
                              }}
                              onValueChange={(e) => {
                                //checkRegex(e.target.value);
                                if (editedData[j]?.dandoriAmt != e) {
                                  settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                  checkInputWithColor(false, '', j + 'NijikakouDandoriAmt', e, 'amt');
                                }
                              }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'NijikakouDandoriAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={
                                    editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr
                                  }
                                  id={j + 'NijikakouDandoriTimeIn'}
                                  style={{
                                    width: '100%',
                                    color:
                                      processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                        ? handleShowHide(
                                            Number(editedData[j]?.dandoriAmt),
                                            processArr[j]?.dandoriAmt,
                                            j + 'NijikakouDandoriAmtCu'
                                          )
                                        : 'red',
                                  }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j]?.dandoriTime,
                                          '10min',
                                          j + 'NijikakouDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j]?.dandoriTime,
                                          '10min',
                                          j + 'NijikakouDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'min',
                                          j + 'NijikakouDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'min',
                                          j + 'NijikakouDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'sec',
                                          j + 'NijikakouDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'sec',
                                          j + 'NijikakouDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={2} className="formula-column formula-column-right-border">
                            <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                          </Col>
                          <Col span={2} className="formula-column">
                            <Popover content={helpCmt.setupTm_p6}>時間</Popover>
                          </Col>
                        </Row>
                      </div>
                    );
                    dandoriSameTypeArr.push(processArr[j]?.processTypeId);
                  }
                }
                return arr;
              })()}
            </div>
            {/*  作業金額/時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('NijikakouSagyoTotal')}>
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpNijikakouSagyoTotal'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />
                  <CaretDownOutlined id={'arrowDownNijikakouSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />
                  <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoAmtIn"
                  style={{
                    color: Math.round(totalSagyoAmt) === Math.round(sagyoAmtSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoTimeIn"
                  style={{
                    color: Math.round(totalSagyoTime) === Math.round(sagyoTimeSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.workTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.workTotalTm}>時間</Popover>
              </Col>
            </Row>
            <div id={'divNijikakouSagyoTotal'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];

                for (let i = 0; i < processArr?.length; i++) {
                  arr.push(
                    <div>
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <div style={{ display: 'flex', marginLeft: '5px' }}>
                            <CaretUpOutlined id={'arrowUpNijikakouIQ3' + i} style={{ fontSize: 17, marginTop: 2 }} />
                            <CaretDownOutlined
                              id={'arrowDownNijikakouIQ3' + i}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <span style={{ marginLeft: '5px' }}>
                              {processArr[i]?.processType + '(M' + processArr[i]?.diameter + ')'}
                            </span>
                          </div>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          {/* ¥ */}
                          <label id={i + 'nijikakouSagyoAmt'}>
                            {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <label id={i + 'nijikakouSagyoTime'}>
                            {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>
                        <Col span={4} className="input-col">
                          <CurrencyInput
                            id={i + 'nijikakouSagyoAmtIn'}
                            value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                            defaultValue={0}
                            onValueChange={(e) => {
                              if (editedData[i]?.sagyoAmt != e) {
                                settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                                checkInputWithColor(false, '', i + 'nijikakouSagyoAmt', e, 'amt');
                              }
                            }}
                            style={{
                              border: 'none',
                              color:
                                processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt
                                  ? 'black'
                                  : checkShowHideIcon(i + 'nijikakouSagyoAmtCu') === 'none'
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={
                              editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                            }
                            prefix="¥"
                            decimalsLimit={100}
                          />

                          <div id={i + 'nijikakouSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                            <div className="currencyIcon">
                              <BsUsbC style={{ fontSize: '25' }} />
                              <BsX style={{ margin: '-8px 0 0 16px' }} />
                              <span class="tooltiptext">
                                金額に変更がありましたので、以降時間と連携して自動計算されない。
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                                id={i + 'nijikakouSagyoTimeIn'}
                                style={{
                                  width: '100%',
                                  color:
                                    processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                      ? 'black'
                                      : oldEditedData[i]?.processCnt === editedData[i]?.processCnt &&
                                        oldEditedData[i]?.workTimeSize === editedData[i]?.workTimeSize
                                      ? 'red'
                                      : '#4cc12f',
                                }}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                onChange={(e) => {
                                  settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        '10min',
                                        i + 'nijikakouSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        '10min',
                                        i + 'nijikakouSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'min',
                                        i + 'nijikakouSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'min',
                                        i + 'nijikakouSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'sec',
                                        i + 'nijikakouSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'sec',
                                        i + 'nijikakouSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                        <Col
                          span={2}
                          className="formula-column formula-column-right-border"
                          onClick={(e) => subpartDetail(i)}
                        >
                          <Popover content={helpCmt.workAmt_p2}>金額</Popover>
                        </Col>
                        <Col span={2} className="formula-column" onClick={(e) => subpartDetail(i)}>
                          <Popover content={helpCmt.workTm_2ji}>時間</Popover>
                        </Col>
                      </Row>
                      <div id={'nijikakouProcessIQ3' + i} style={{ display: 'none' }}>
                        <div>
                          {/* 作業金額/時間 */}

                          <div>
                            {/* 加工種類/仮付箇所 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id="nijiAmount">{processArr[i]?.processCnt}</label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'nijiTimeForOne'}>
                                  {processArr[i]?.workTimeSize ? secondsToHms(processArr[i]?.workTimeSize) : timeHmsStr}
                                </label>
                              </Col>

                              <Col span={4} className="input-col">
                                <Input
                                  value={editedData[i]?.processCnt}
                                  id={i + 'nijiAmountIn'}
                                  min={0}
                                  className={editMode ? 'input-editable' : 'input-non-editable '}
                                  controls={false}
                                  style={{
                                    width: '45%',
                                    border: 'none',
                                    color:
                                      parseFloat(editedData[i]?.processCnt) === processArr[i]?.processCnt
                                        ? 'black'
                                        : 'red',
                                  }}
                                  onKeyPress={(e) => handleKeyPress(e, false)}
                                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'processCnt', '', '')}
                                />
                              </Col>

                              <Col span={4} className="input-col">
                                <Row style={{ width: '100%' }}>
                                  <Col className="timer-input-col">
                                    <Input
                                      value={
                                        editedData[i]?.workTimeSize
                                          ? secondsToHms(editedData[i]?.workTimeSize)
                                          : timeHmsStr
                                      }
                                      id={i + 'nijiTimeForOneIn'}
                                      min={0}
                                      className={editMode ? 'input-editable' : 'input-non-editable '}
                                      controls={false}
                                      style={{
                                        width: '100%',
                                        border: 'none',
                                        color:
                                          editedData[i]?.workTimeSize === processArr[i]?.workTimeSize ? 'black' : 'red',
                                      }}
                                      //onKeyPress={handleKeyPress}
                                      onChange={(e) =>
                                        settingNewDandoriAmtInArr(e.target.value, i, 'workTimeSize', '', '')
                                      }
                                    />
                                  </Col>
                                  <Col className="timer-button-col">
                                    {editMode ? (
                                      <>
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.workTimeSize,
                                              '10min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.workTimeSize,
                                              '10min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'sec',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'sec',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'dec');
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={4} className="formula-column"></Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return arr;
              })()}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiCalculate_2jikakou;
