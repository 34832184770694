import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, FRBend, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_Bending = forwardRef((props, ref) => {
  const [isBendingdetail, setBendingdetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);

  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [programAmt, setProgramAmt] = useState();
  const [programTime, setProgramTime] = useState();
  const [mageDandoriAmt, setMageDandoriAmt] = useState();
  const [mageDandoriTime, setMageDandoriTime] = useState();
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [bendingAmt, setBendingAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [sizeCoef, setSizeCoeff] = useState();
  const [bendingCoeff, setBendingCoeff] = useState();
  const [bendingModifyCoeff, setBendingModifyCoeff] = useState();

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setBendingAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmBending);
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    let subDataTypeArr = [];
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      sagyoTimeSec += element.sagyoTime;
      if (!subDataTypeArr.includes(element?.processTypeId)) {
        subDataTypeArr.push(element?.processTypeId);
        dandoriAmtSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
        dandoriTimeSec += element.dandoriTime;
      }
    });

    dandoriAmtSum = dandoriAmtSum + Math.round(editDataArr[0]?.programAmt) + Math.round(editDataArr[0]?.mageDandoriAmt);
    let totalSum = (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) + (dandoriAmtSum ? Math.round(dandoriAmtSum) : 0); // 画面表示用（四捨五入した金額を足す）
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec + editDataArr[0]?.programTime + editDataArr[0]?.mageDandoriTime;
    let totalTimeSum = sagyoTimeSec + dandoriTimeSum;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const calculateAmtFromTime = (objId, amt, timeAct, timeType, value) => {
    let objType = objId.substring(0, objId.indexOf('Time'));
    let capitalObj = objType.charAt(0).toUpperCase() + objType.slice(1);
    let returnAmt = 0;
    if (document.getElementById('Bending' + capitalObj + 'AmtCu').style.display !== undefined) {
      if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        returnAmt = changeAmtwithTime(amt, timeType, timeAct, value, objId);
      } else {
        returnAmt = changeAmtwithTime(amt, timeType, timeAct, value, objId);
      }
    }
    returnAmt = returnAmt ? returnAmt : 0;
    return returnAmt;
  };

  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCalIQ3 || props?.iq3ActionForCal) {
      let editData = [];
      let totals = [];
      let processMst = [];
      let deviceMst = []; //テスト用ID;
      let toBEdit = [];
      let programAmtt,
        mageDandoriAmtt,
        programTimeSec,
        bendingTimeSec = 0;
      setDandoriAmtSum(0);
      setDandoriTimeSum(0);
      setSagyoAmtSum(0);
      setSagyoTimeSum(0);
      setTotalTimeSum(0);
      setTotalSum(0);

      let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
      // 数量
      let insuu = props?.selectedIQ3DataDetail?.partCount;
      if (parameters?.process?.length > 0) {
        processMst = parameters?.process
          ? parameters?.process?.filter(
              (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBending
            )[0]
          : [];
        setProcessMst(processMst ? processMst : []);
        if (parameters?.device?.length > 0) {
          deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
        }
      }

      let patternInfo = props?.selectedIQ3DataDetail?.processSelect
        ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
        : [];
      if (patternInfo == undefined) return;
      if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

      deviceMst =
        deviceMst && patternInfo
          ? deviceMst?.filter(
              (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
            )[0]
          : []; //テスト用ID;
      setDeviceMst(deviceMst ? deviceMst : []);
      if (deviceMst == undefined) return;
      // 選択された行の工程積算情報を正しく取得するための対応
      let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
        (item) => item.no === props?.selectedIQ3DataDetail?.no
      );
      let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
      if (selectedIQ3DataDetail?.length === 0) return;
      let bendingAdditions = selectedIQ3DataDetail?.additions?.filter((item) => item.processId == processMst?.id)[0];

      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      let bendingAdditionEditData = bendingAdditions?.editItems;
      let editDataFlg = bendingAdditions?.editDataFlg;
      // ベンディング工程入力情報取得
      let bendingProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == processMst?.id
      )[0];

      let detailItems = [];
      let editDetailItems = [];
      //ベンディング種類情報設定
      for (let j = 0; j < bendingProcessInput?.details?.[0]?.bendingList?.length; j++) {
        let bendingListProcessInput = bendingProcessInput?.details?.[0]?.bendingList[j];
        let processTypeNm = processMst?.details?.processDetailTypes?.filter(
          (item) => item.id == bendingListProcessInput.types && item.isUsed
        )?.[0];
        detailItems.push({
          id: j + 1,
          processName: 'ベンディング処理(No' + (j + 1) + ')',
          processTypeId: processTypeNm?.id,
          processType: processTypeNm?.name,
          dandoriAmt: 0,
          dandoriTime: 0,
          sagyoAmt: 0,
          sagyoTime: 0,
          partCnt: processTypeNm?.name?.includes(FRBend)
            ? Number(bendingListProcessInput.bendCount)
            : Number(bendingListProcessInput.lines), //曲げ回数/FR曲げ回数　（IQX_WEBEST-196）
          workTimeSize: 0, //1つ当たりの加工時間
          bendinglen: Number(bendingListProcessInput.bendLength), //曲げ長
          bendCount: Number(bendingListProcessInput.bendCount), //FR曲げ回数
          programAmt: 0,
          programTime: 0,
          mageDandoriAmt: 0,
          mageDandoriTime: 0,
          formula: '',
        });
      }
      editDetailItems = [];
      let bendingAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: bendingAdditions?.dataType ? bendingAdditions.dataType : DataType.Data,
        dataItems: detailItems,
        editItems: bendingAdditions?.editItems ? bendingAdditions.editItems : editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
        editDataFlg: editDataFlg,
      };
      bendingAdditions = bendingAddition;

      if (bendingAdditions?.dataItems) {
        let orgData = JSON.parse(JSON.stringify(bendingAdditions?.dataItems));
        if (deviceMst != undefined) {
          let totalDandori = 0;
          let totalSagyo = 0;
          let totalSagyoTm = 0;
          let totalDandoriTm = 0;
          let dataItemsByProcessInput = [];

          // 板厚
          let thickness = parameters?.materialIq3?.filter(
            (item) => item.id == props?.selectedIQ3DataDetail?.thickness
          )?.[0]?.info?.thick;
          // 重量
          let weight = props?.selectedIQ3DataDetail?.weight;
          // 面積
          let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst);
          //総重量が0、子部品数が0の場合、段取と加工を計算しない。
          if (weight === 0 && area === 0) {
            let totalBendLines = 0;

            let totalBendLength = 0;

            for (let i = 0; i < bendingProcessInput?.details?.[0]?.bendingList?.length; i++) {
              //工程入力情報
              let bendingProcessInputInfo = bendingProcessInput?.details?.[0]?.bendingList[i];

              let dataItemByProcessInput = orgData[i];

              totalBendLength += Number(bendingProcessInputInfo?.bendLength);
              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == bendingProcessInputInfo.types
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;

              /** 段取金額/時間 */
              // 曲げ_段取時間＊サイズ係数
              let dandoriTimeSec = 0;
              let dandoriTime = dandoriTimeSec;
              dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTime : 0;
              // 段取時間の合計

              let dandoriAmt = 0;
              dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;

              let sagyoTimePerSec = 0;

              let sagyoTimeSec = 0;
              dataItemByProcessInput.sagyoTime = sagyoTimeSec ? sagyoTimeSec : 0;
              dataItemByProcessInput.workTimeSize = sagyoTimePerSec ? sagyoTimePerSec : 0;
              totalSagyoTm += sagyoTimeSec ? sagyoTimeSec : 0;

              let sagyoAmt = 0;
              dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;

              // 作業金額の合計
              totalSagyo += sagyoAmt ? sagyoAmt : 0;
              totalBendLines += 0;

              dataItemsByProcessInput.push(dataItemByProcessInput);
            }
            /* if (dataItemsByProcessInput?.length > 0 && totalBendLines > 0) { */
            // プログラム作成時間

            programTimeSec = 0;
            dataItemsByProcessInput[0].programTime = 0;
            setProgramTime(programTimeSec);
            // プログラム作成金額
            programAmtt = 0;
            dataItemsByProcessInput[0].programAmt = 0;
            setProgramAmt(programAmtt);

            bendingTimeSec = 0;
            dataItemsByProcessInput[0].mageDandoriTime = 0;
            setMageDandoriTime(0);
            // 曲長段取金額
            mageDandoriAmtt = 0;
            dataItemsByProcessInput[0].mageDandoriAmt = 0;
            setMageDandoriAmt(mageDandoriAmtt);

            // 段取金額の合計
            setDandoriAmtSum(0);
            bendingAdditions.totalDandori.dataItemsAmt = 0;
            // 段取時間の合計
            setDandoriTimeSum(0);
            bendingAdditions.totalDandori.dataItemsTime = 0;
            // 作業金額の合計
            setSagyoAmtSum(0);
            bendingAdditions.totalSagyo.dataItemsAmt = 0;
            setSagyoTimeSum(0);
            bendingAdditions.totalSagyo.dataItemsTime = 0;

            /** 　合計時間　*/
            let totalTimeSum = 0;
            setTotalTimeSum(0);
            bendingAdditions.totalDataTime = totalTimeSum;

            /** 　合計金額　*/
            let totalSum = 0;
            setTotalSum(0);
            bendingAdditions.totalDataPrice = 0;
            /*  } */

            orgData = dataItemsByProcessInput;
          } else {
            //材質
            let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

            //材質区分
            let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
              ?.materialTypeId;

            // サイズ係数
            let sizeCoef = getSizeCoeff(deviceMst, weight, area);
            setSizeCoeff(sizeCoef);

            //　曲げ係数
            let bendingCoef = getBendingCoeff(deviceMst, weight, area);
            setBendingCoeff(bendingCoef);

            // 曲げ補正時間係数
            let bendingModifyCoef = getBendingModifyCoeff(deviceMst, weight, area);
            setBendingModifyCoeff(bendingModifyCoef);

            let totalBendLines = 0;

            let totalBendLength = 0;

            let bendTypesArr = [];

            let bendTypesAllArr = bendingProcessInput?.details?.[0]?.bendingList.map((item) => item.types);

            for (let i = 0; i < bendingProcessInput?.details?.[0]?.bendingList?.length; i++) {
              //工程入力情報
              let bendingProcessInputInfo = bendingProcessInput?.details?.[0]?.bendingList[i];
              if (orgData?.length == 0) continue;
              let dataItemByProcessInput = orgData[i];
              if (Number(bendingProcessInputInfo?.bendLength) > 0) {
                totalBendLength += Number(bendingProcessInputInfo?.bendLength);
                let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                  (item) => item.id == bendingProcessInputInfo.types
                )?.[0];
                dataItemByProcessInput.processType = processTypeNm?.name;

                let deviceCharges = deviceMst?.details?.charges;

                /** 段取金額/時間 */
                // 曲げ_段取時間＊サイズ係数
                let dandoriTimeSec = calDandoriTime(deviceMst, thickness, bendingProcessInputInfo.types, sizeCoef);
                let dandoriTime = dandoriTimeSec;
                dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTime : 0;

                // 段取時間の合計

                // 段取金額設定
                //段階時間
                let mSetTimeMin = dandoriTimeSec / 60;
                //装置の段階時間チャージ
                let mSetHRate = deviceCharges?.prep;
                let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
                dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;

                // 段取金額の合計

                if (!bendTypesArr.includes(bendingProcessInputInfo.types)) {
                  bendTypesArr.push(bendingProcessInputInfo.types);
                  totalDandoriTm += dandoriTime ? dandoriTime : 0;
                  totalDandori += dandoriAmt ? dandoriAmt : 0;
                  let index = bendTypesAllArr.indexOf(bendingProcessInputInfo.types);
                  if (dataItemsByProcessInput[index]) {
                    dataItemsByProcessInput[index]['dandoriTime'] = dataItemByProcessInput.dandoriTime;
                    dataItemsByProcessInput[index]['dandoriAmt'] = dataItemByProcessInput.dandoriAmt;
                  }
                }

                // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
                let sagyoTimePerPartCntSec = getSagyoTime(deviceMst, thickness, bendingProcessInputInfo.types);
                //(1つ当たりの加工時間	*	曲げ長さ係数)
                let sagyoBendCoef = sagyoTimePerPartCntSec * bendingCoef;
                let totalSagyoTimeSec = 0;

                // 曲げ回数判断(IQX_WEBEST-196)
                let bendingCount = 1;
                processMst?.details?.processDetailTypes
                  ?.filter((item) => item.name.includes(FRBend))
                  ?.map((item) => {
                    //FR曲げの場合、FR曲げ回数を取得
                    if (item.id === bendingProcessInputInfo?.types) {
                      bendingCount = bendingProcessInputInfo?.bendCount; // FR曲げ回数
                    } else {
                      bendingCount = bendingProcessInputInfo?.lines; // 曲げ回数
                    }
                  });
                bendingCount = bendingCount ? Number(bendingCount) : 1;

                //員数によって作業時間取得
                for (let i = 1; i <= insuu; i++) {
                  // 曲げ補正時間
                  let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
                    (item) => (item.value == i || item.value > i) && !item.isOutOfRange
                  );
                  let bendingModifyTime = deviceMst?.details?.modifyTimeItems?.filter(
                    (item) => item.no == modifyTimeQtyThMaster?.[0]?.no && !item.isOutOfRange
                  )?.[0]?.value;

                  // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
                  //(曲げ補正時間	*	曲げ補正係数)
                  let multiModify = bendingModifyTime * bendingModifyCoef;
                  totalSagyoTimeSec += (sagyoBendCoef + multiModify) * (bendingCount * sizeCoef);
                }
                let sagyoTimeSec = Math.ceil(totalSagyoTimeSec / insuu);

                // 1つ当たりの加工時間
                let sagyoTimePerSec = Math.ceil(sagyoTimeSec / bendingCount);

                dataItemByProcessInput.sagyoTime = sagyoTimeSec ? sagyoTimeSec * productionQuantityCoeff : 0; // IQX_WEBEST-278 加工時間＊生産個数係数
                dataItemByProcessInput.workTimeSize = sagyoTimePerSec ? sagyoTimePerSec : 0;
                totalSagyoTm += dataItemByProcessInput.sagyoTime; // IQX_WEBEST-278 加工時間＊生産個数係数

                // 作業金額
                let mSagyoTimeMin = dataItemByProcessInput?.sagyoTime / 60;
                mSagyoTimeMin = Number.isFinite(mSagyoTimeMin) ? mSagyoTimeMin : 0;
                //装置の作業時間チャージ
                let mSagyoHRate = deviceCharges?.work;
                let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
                dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;

                // 作業金額の合計
                totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
                totalBendLines += bendingCount;
              } else {
                // 曲げ長さ"0"の時は見積計算から省いてほしい（IQX_WEBEST-54）
                dataItemByProcessInput.dandoriAmt = 0;
                dataItemByProcessInput.dandoriTime = 0;
                dataItemByProcessInput.sagyoTime = 0;
                dataItemByProcessInput.workTimeSize = 0;
                dataItemByProcessInput.sagyoAmt = 0;
                dataItemsByProcessInput.push(dataItemByProcessInput);
                continue;
              }

              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == bendingProcessInputInfo.types
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;

              let deviceCharges = deviceMst?.details?.charges;

              /** 段取金額/時間 */
              // 曲げ_段取時間＊サイズ係数
              /*  let dandoriTimeSec = calDandoriTime(deviceMst, thickness, bendingProcessInputInfo.types, sizeCoef);
            let dandoriTime = dandoriTimeSec ? dandoriTimeSec : 0;
            dataItemByProcessInput.dandoriTime = dandoriTime === undefined ? 0 : dandoriTime;
            // 段取時間の合計
            totalDandoriTm += dandoriTime ? dandoriTime : 0;

            // 段取金額設定
            //段階時間
            let mSetTimeMin = dandoriTimeSec / 60;
            //装置の段階時間チャージ
            let mSetHRate = deviceCharges?.prep;
            let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
            dataItemByProcessInput.dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
            // 段取金額の合計
            totalDandori += dandoriAmt ? dandoriAmt : 0;

            // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
            // 1つ当たりの加工時間
            let sagyoTimePerSec = getSagyoTime(deviceMst, thickness, bendingProcessInputInfo.types);
            // 曲げ補正時間
            let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
              (item) => (item.value == partCnt || item.value > partCnt) && !item.isOutOfRange
            );
            let bendingModifyTime = deviceMst?.details?.modifyTimeItems?.filter(
              (item) => item.no == modifyTimeQtyThMaster?.[0]?.no && !item.isOutOfRange
            )?.[0]?.value;

            // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
            let sagyoTimeSec =
              sagyoTimePerSec * bendingCoef +
              bendingModifyTime * bendingModifyCoef * Number(bendingProcessInputInfo?.lines) * sizeCoef;
            dataItemByProcessInput.sagyoTime = sagyoTimeSec === undefined ? 0 : sagyoTimeSec;
            dataItemByProcessInput.workTimeSize = sagyoTimePerSec === undefined ? 0 : sagyoTimePerSec; 
            //totalSagyoTm += sagyoTimeSec;

            // 作業金額
            let mSagyoTimeMin = sagyoTimeSec / 60;
            //装置の作業時間チャージ
            let mSagyoHRate = deviceCharges?.work;
            let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
            dataItemByProcessInput.sagyoAmt = sagyoAmt === undefined ? 0 : sagyoAmt;

            // 作業金額の合計
            //totalSagyo += sagyoAmt;
            totalBendLines += Number(bendingProcessInputInfo?.lines); */
              dataItemsByProcessInput.push(dataItemByProcessInput);
            }
            // 曲げ長さ"0"の時は見積計算から省いてほしい（IQX_WEBEST-54）
            if (dataItemsByProcessInput?.length > 0 && totalBendLines > 0) {
              // 曲げ長さが0以外の行数を取得(IQX_WEBEST-143)
              let validBendingList = bendingProcessInput?.details?.[0]?.bendingList?.filter(
                (item) => Number(item.bendLength) > 0
              );
              // プログラム作成時間
              let programCreateTimeCountfThMaster = deviceMst?.details?.programCreateTimeCountfThMaster?.filter(
                (item) =>
                  (item.value == validBendingList?.length || item.value > validBendingList?.length) &&
                  !item.isOutOfRange
              );
              programTimeSec = deviceMst?.details?.programCreateTimeItems?.filter(
                (item) => item.no == programCreateTimeCountfThMaster?.[0]?.no && !item.isOutOfRange
              )?.[0]?.value;
              dataItemsByProcessInput[0].programTime = programTimeSec === undefined ? 0 : programTimeSec;
              setProgramTime(programTimeSec);
              // プログラム作成金額
              programAmtt = calDandoriAmt(programTimeSec, deviceMst); //装置の加工金額
              dataItemsByProcessInput[0].programAmt = programAmtt === undefined ? 0 : programAmtt;
              setProgramAmt(programAmtt);

              // 曲長段取時間
              let prepBendTimeLengthMaster = deviceMst?.details?.prepBendTimeLengthMaster?.filter(
                (item) =>
                  (item.value == Number(bendingProcessInput?.details?.[0]?.bendingData?.maxBendLength) ||
                    item.value > Number(bendingProcessInput?.details?.[0]?.bendingData?.maxBendLength)) &&
                  !item.isOutOfRange
              );
              bendingTimeSec = deviceMst?.details?.prepBendTimeItems?.filter(
                (item) => item.no == prepBendTimeLengthMaster?.[0]?.no
              )?.[0]?.value;
              dataItemsByProcessInput[0].mageDandoriTime = bendingTimeSec === undefined ? 0 : bendingTimeSec;
              setMageDandoriTime(bendingTimeSec);
              // 曲長段取金額
              mageDandoriAmtt = calDandoriAmt(bendingTimeSec, deviceMst); //装置の加工金額
              dataItemsByProcessInput[0].mageDandoriAmt = mageDandoriAmtt === undefined ? 0 : mageDandoriAmtt;
              setMageDandoriAmt(mageDandoriAmtt);
              totalDandori = Math.round(totalDandori) + Math.round(programAmtt) + Math.round(mageDandoriAmtt);
              totalDandoriTm = totalDandoriTm + programTimeSec + bendingTimeSec;
              // 段取金額の合計
              setDandoriAmtSum(totalDandori ? totalDandori : 0);
              bendingAdditions.totalDandori.dataItemsAmt = totalDandori ? totalDandori : 0;
              // 段取時間の合計
              setDandoriTimeSum(totalDandoriTm ? totalDandoriTm : 0);
              bendingAdditions.totalDandori.dataItemsTime = totalDandoriTm ? totalDandoriTm : 0;
              // 作業金額の合計
              setSagyoAmtSum(totalSagyo ? totalSagyo : 0);
              bendingAdditions.totalSagyo.dataItemsAmt = totalSagyo ? totalSagyo : 0;
              setSagyoTimeSum(totalSagyoTm ? totalSagyoTm : 0);
              bendingAdditions.totalSagyo.dataItemsTime = totalSagyoTm ? totalSagyoTm : 0;
            } else {
              programTimeSec = 0;
              dataItemsByProcessInput[0].programTime = programTimeSec === undefined ? 0 : programTimeSec;
              setProgramTime(programTimeSec);
              // プログラム作成金額
              programAmtt = 0; //装置の加工金額
              dataItemsByProcessInput[0].programAmt = programAmtt === undefined ? 0 : programAmtt;
              setProgramAmt(programAmtt);

              // 曲長段取時間
              bendingTimeSec = 0;
              dataItemsByProcessInput[0].mageDandoriTime = bendingTimeSec === undefined ? 0 : bendingTimeSec;
              setMageDandoriTime(bendingTimeSec);
              // 曲長段取金額
              mageDandoriAmtt = 0; //装置の加工金額
              dataItemsByProcessInput[0].mageDandoriAmt = mageDandoriAmtt === undefined ? 0 : mageDandoriAmtt;
              setMageDandoriAmt(mageDandoriAmtt);
              totalDandori = 0;
              totalDandoriTm = 0;
              // 段取金額の合計
              setDandoriAmtSum(0);
              bendingAdditions.totalDandori.dataItemsAmt = 0;
              // 段取時間の合計
              setDandoriTimeSum(0);
              bendingAdditions.totalDandori.dataItemsTime = 0;
              // 作業金額の合計
              setSagyoAmtSum(0);
              bendingAdditions.totalSagyo.dataItemsAmt = 0;
              setSagyoTimeSum(0);
              bendingAdditions.totalSagyo.dataItemsTime = 0;
            }
            /** 　合計時間　*/
            let totalTimeSum = totalDandoriTm + totalSagyoTm;
            setTotalTimeSum(totalTimeSum);
            bendingAdditions.totalDataTime = totalTimeSum;

            /** 　合計金額　*/
            let totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
            setTotalSum(totalSum ? totalSum : 0);
            bendingAdditions.totalDataPrice = totalSum ? totalSum : 0;
            /*  } */

            orgData = dataItemsByProcessInput;
          }
          setProcessArr(orgData);
          bendingAdditions.dataItems = JSON.parse(JSON.stringify(orgData));

          // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
          if (bendingAdditionEditData?.length > 0 && editDataFlg) {
            toBEdit = JSON.parse(JSON.stringify(bendingAdditionEditData));
          } else {
            toBEdit = JSON.parse(JSON.stringify(orgData));
          }
        }
      }
      if (toBEdit?.length > 0) {
        editData = toBEdit;
        bendingAdditions.editItems = editData;
        if (editData?.length > 0) {
          setEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum ? totals[0]?.totalSum : 0);
          bendingAdditions.totalEditPrice = totals[0]?.totalSum ? totals[0]?.totalSum : 0;
          bendingAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum ? totals[0]?.dandoriAmtSum : 0;
          bendingAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum ? totals[0]?.sagyoAmtSum : 0;

          setTotalTime(totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0);
          bendingAdditions.totalEditTime = totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0;
          bendingAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum ? totals[0]?.dandoriTimeSum : 0;
          bendingAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum ? totals[0]?.sagyoTimeSum : 0;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum ? totals[0]?.sagyoAmtSum : 0);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum ? totals[0]?.sagyoTimeSum : 0);

          setTotalDandoriAmt(totals[0]?.dandoriAmtSum ? totals[0]?.dandoriAmtSum : 0);
          setTotalDandoriTime(totals[0]?.dandoriTimeSum ? totals[0]?.dandoriTimeSum : 0);
        }
        setUpdateEditedData(bendingAdditions);
      }
      if (bendingAdditions?.dataType == DataType.Data) {
        setBendingAmt(bendingAdditions.totalDataPrice);
      } else {
        setBendingAmt(bendingAdditions.totalEditPrice);
      }
      props?.updateIQ3KouteiCal(bendingAdditions, WorkType.SmBending);
    }
  }, [props?.isReCalIQ3, props?.iq3ActionForCal, props?.tabOpen, props?.dialogOk]);

  useEffect(() => {
    if (props?.tabOpen) {
      setEditMode(props?.editMode != undefined ? props?.editMode : false);
    }
  }, [props?.editMode]);

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0].no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;
    partsCoeff = partsCoeff ? partsCoeff : 0;
    return partsCoeff;
  };

  const getBendingCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let bendTimeCoeffWeightThMaster = deviceMst?.details?.bendTimeCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let bendTimeCoeffLenThMaster = deviceMst?.details?.bendTimeCoeffLenThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let bendTimeCoeffItems = Object.entries(deviceMst?.details?.bendTimeCoeffItems)?.filter(
      ([key, data]) => parseInt(key) === bendTimeCoeffWeightThMaster?.[0]?.no && !data.isOutOfRange
    )?.[0];
    let bendTimeCoeffs = [];
    bendTimeCoeffItems?.map((item, index) => {
      if (index > 0) {
        bendTimeCoeffs = item;
      }
    });

    // サイズ係数
    let bendTimeCoeff = bendTimeCoeffs?.filter((item) => item.no == bendTimeCoeffLenThMaster?.[0]?.no)?.[0]?.value;
    bendTimeCoeff = bendTimeCoeff ? bendTimeCoeff : 0;
    return bendTimeCoeff;
  };

  const getBendingModifyCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let modifyCoeffWeightThMaster = deviceMst?.details?.modifyCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let modifyCoeffLenThMaster = deviceMst?.details?.modifyCoeffLenThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      modifyCoeffWeightThMaster?.length > 0 && modifyCoeffLenThMaster?.length > 0
        ? deviceMst?.details?.modifyCoeffItems?.filter((item) => item.weightId == modifyCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.modifyCoeffItems[0];

    // 補正係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.lengthCoeffList?.filter(
            (item) => item.no == modifyCoeffLenThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.lengthCoeffList?.[0]?.value;
    partsCoeff = partsCoeff ? partsCoeff : 0;
    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, thickness, typeId, sizeCoef) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    let basicTimeThickThMaster = deviceMst?.details?.basicTimeThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    );

    let basicTimeThickItems = Object.entries(deviceMst?.details?.basicTimeThickItems)?.filter(
      ([key, data]) => parseInt(key) === typeId && !data.isOutOfRange
    )?.[0];
    let deviceDandori = [];
    basicTimeThickItems?.map((item, index) => {
      if (index > 0) {
        deviceDandori = item;
      }
    });
    //　基本段取時間
    let dandoriTimeSec =
      deviceDandori?.filter((item) => item.no == basicTimeThickThMaster?.[0]?.no && !item.isOutOfRange)?.[0]?.value *
      sizeCoef;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const getSagyoTime = (deviceMst, thickness, typeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    let workTimeThickThMaster = deviceMst?.details?.workTimeThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    );

    let workTimeThickItems = Object.entries(deviceMst?.details?.workTimeThickItems)?.filter(
      ([key, data]) => parseInt(key) === typeId
    )?.[0];
    let deviceWorkTime = [];
    workTimeThickItems?.map((item, index) => {
      if (index > 0) {
        deviceWorkTime = item;
      }
    });
    //　基本段取時間
    let deviceWorkTimeSec = deviceWorkTime?.filter((item) => item.no == workTimeThickThMaster?.[0]?.no)?.[0]?.value;
    deviceWorkTimeSec = deviceWorkTimeSec ? deviceWorkTimeSec : 0;
    return deviceWorkTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst?.details?.charges?.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const bending = () => {
    if (isBendingdetail == true) {
      setBendingdetail(false);
    } else {
      setBendingdetail(true);
    }
  };

  const setBendingDisplay = (display) => {
    setBendingdetail(display);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('bendingProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('bendingProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownBendingIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpBendingIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('bendingProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownBendingIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpBendingIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setBendingDisplay: setBendingDisplay,
  }));

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);

    setTotalDandoriAmt(dandoriAmtSum ? dandoriAmtSum : 0);
    setTotalDandoriTime(dandoriTimeSum ? dandoriTimeSum : 0);

    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setBendingAmt(updateEditedData.totalDataPrice);
    } else {
      setBendingAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBending);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    /*let newState = [...editedData]; */
    let weight = props?.selectedIQ3DataDetail?.weight;
    let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }
    if (objId !== 'dandoriTime' && objId !== 'dandoriAmt') {
      oldValue = editedData[index][objId];
      newState[index][objId] = value;
      updateEditedData.editItems[index][objId] = value;
    }

    if (objId === 'dandoriTime' || objId === 'dandoriAmt' || objId === 'programAmt' || objId === 'mageDandoriAmt') {
      oldValue = newState[index][objId];
      newState[index][objId] = value;
      if (objId === 'dandoriTime') {
        if (document.getElementById(index + 'BendingDandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'BendingDandoriAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newState[index].dandoriAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newState[index].dandoriAmt = changeAmtwithTime(
                newState[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
              updateEditedData.editItems[index].dandoriTime = value;
            } else {
              newState[index].dandoriAmt = changeAmtwithTime(
                newState[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
              updateEditedData.editItems[index].dandoriTime = value;
            }
          }
        }
      }
      // newState = newState?.map((item) => {
      //   const matchingItem = newState?.find((el) => el.processType === item.processType);
      //   return matchingItem
      //     ? { ...item, dandoriAmt: matchingItem.dandoriAmt, dandoriTime: matchingItem.dandoriTime }
      //     : item;
      // });
    } else if (objId === 'sagyoTime') {
      checkInputWithColor(false, '', index + 'bendingSagyoAmt', newState[index].sagyoAmt, 'green');

      if (document.getElementById(index + 'bendingSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'bendingSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
            updateEditedData.editItems[index].sagyoTime = value;
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
            updateEditedData.editItems[index].sagyoTime = value;
          }
        }
      }
    } else if (objId === 'handelingTime') {
      if (document.getElementById('BendingHandlingAmtCu').style.display !== undefined) {
        if (timeAct === 'input') {
          let timeType = value - oldValue;
          newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
        } else {
          newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
        }
        // checkInputWithColor(false, '', 'BendingHandlingAmt', newState[0].handelingAmt, 'green');
      }
    } else if (objId === 'programTime' || objId === 'mageDandoriTime') {
      let cuId = objId === 'programTime' ? 'BendingProgramAmtCu' : 'BendingMageDandoriAmtCu';

      if (document.getElementById(cuId).style.display !== undefined) {
        let showHideCu = document.getElementById(cuId).style.display;
        if (showHideCu === 'none') {
          let amt = objId === 'programTime' ? 'programAmt' : 'mageDandoriAmt';
          newState[0][amt] = value === 0 ? 0 : calculateAmtFromTime(objId, newState[0][amt], timeAct, timeType, value);
        }
      }
    } else if (objId === 'partCnt' || objId === 'workTimeSize') {
      // 作業時間（(1つ当たりの加工時間	*	個数	*	曲げ長さ係数)	*	サイズ係数）
      // 1つ当たりの加工時間
      let sagyoTimePerSec = objId === 'workTimeSize' ? value : newState[index].workTimeSize; //300;
      // 個数
      let partCnt = objId === 'partCnt' ? Number(value) : newState[index].partCnt;
      let sagyoTimeSec = 0;
      //回数入力の場合、1つ当たりの加工時間の計算
      if (objId === 'partCnt') {
        let totalSagyoTimeSec = 0;
        // 数量
        let insuu = props?.selectedIQ3DataDetail?.partCount;
        let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
        // 板厚
        let thickness = parameters?.materialIq3?.filter(
          (item) => item.id == props?.selectedIQ3DataDetail?.thickness
        )?.[0]?.info?.thick;
        // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
        let sagyoTimePerPartCntSec = getSagyoTime(deviceMst, thickness, newState[index].processTypeId);
        //(1つ当たりの加工時間	*	曲げ長さ係数)
        let sagyoBendCoef = sagyoTimePerPartCntSec * bendingCoeff;
        //員数によって作業時間取得
        for (let i = 1; i <= insuu; i++) {
          // 曲げ補正時間
          let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
            (item) => (item.value == i || item.value > i) && !item.isOutOfRange
          );
          let bendingModifyTime = deviceMst?.details?.modifyTimeItems?.filter(
            (item) => item.no == modifyTimeQtyThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value;

          // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
          //(曲げ補正時間	*	曲げ補正係数)
          let multiModify = bendingModifyTime * bendingModifyCoeff;
          totalSagyoTimeSec += (sagyoBendCoef + multiModify) * (partCnt * sizeCoef);
        }

        sagyoTimeSec = Math.ceil(totalSagyoTimeSec / insuu);
        // 1つ当たりの加工時間
        sagyoTimePerSec = Math.ceil(sagyoTimeSec / partCnt);
        newState[index].workTimeSize = sagyoTimePerSec;
        updateEditedData.editItems[index].workTimeSize = sagyoTimePerSec;
      } else {
        sagyoTimeSec = sagyoTimePerSec * partCnt;
      }

      // 作業時間（溶接時間＋仮付時間）
      newState[index].sagyoTime = sagyoTimeSec * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
      updateEditedData.editItems[index].sagyoTime = sagyoTimeSec * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数

      // 作業金額
      let mSagyoTimeMin = newState[index]?.sagyoTime / 60;
      mSagyoTimeMin = Number.isFinite(mSagyoTimeMin) ? mSagyoTimeMin : 0;
      //装置の作業時間チャージ
      let deviceCharges = deviceMst?.details?.charges;
      let mSagyoHRate = deviceCharges?.work;
      if (document.getElementById(index + 'bendingSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'bendingSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          sagyoAmt = Number.isFinite(sagyoAmt) ? sagyoAmt : 0;
          newState[index].sagyoAmt = sagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = sagyoAmt;
        }
      }
    }
    // else if (objId === 'kensaTime') {
    //   if (document.getElementById('BendingKensaAmtCu').style.display !== undefined) {
    //     if (timeAct === 'input') {
    //       let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
    //       newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
    //     } else {
    //       newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
    //     }
    //     checkInputWithColor(false, '', 'BendingKensaAmt', newState[0].kensaAmt, 'green');
    //   }
    // }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;
    let subDataTypeArr = [];
    newState?.forEach((element) => {
      newSagyoSum += Math.round(element.sagyoAmt);
      newSagyoTimeSec += element.sagyoTime;
      if (!subDataTypeArr.includes(element?.processTypeId)) {
        subDataTypeArr.push(element?.processTypeId);
        newDandoriSum += Math.round(element.dandoriAmt);
        newDandoriTimeSec += element.dandoriTime;
      }
    });

    /* newSagyoSum += Number(newState[0].handelingAmt);
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime); */

    let programMageAmt = Math.round(newState[0].programAmt) + Math.round(newState[0].mageDandoriAmt);
    let programMageTime = newState[0].programTime + newState[0].mageDandoriTime;
    newDandoriSum += programMageAmt;
    newDandoriTimeSec += programMageTime;

    newSum = (newSagyoSum ? Math.round(newSagyoSum) : 0) + (newDandoriSum ? Math.round(newDandoriSum) : 0); // 画面表示用（四捨五入した金額を足す）

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    /*  setEditedHandelingAmt(newState[0].handelingAmt);
    setEditedHandelingTime(newState[0].handelingTime); */
    setEditedData(newState);
    updateEditedData.totalEditPrice = newSum ? newSum : 0;
    updateEditedData.totalEditTime = newTotalTimeSum ? newTotalTimeSum : 0;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum ? newDandoriSum : 0;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum ? newDandoriTimeSum : 0;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum ? newSagyoSum : 0;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum ? newSagyoTimeSum : 0;
    if (updateEditedData.dataType == DataType.Data) {
      setBendingAmt(updateEditedData.totalDataPrice);
    } else {
      setBendingAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBending);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    /*  let deviceMst = []; */

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'handelingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'programTime') {
      // プログラム作成金額
      //　 プログラム作成時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'mageDandoriTime') {
      // 曲げ段取金額
      //　曲げ段取時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  const getSetupTmCmt = (type) => {
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{type}段取時間＝基本段取時間ｘ子部品サイズ係数ｘ段取個数係数</p>
      </div>
    );
  };

  const getSetupTotalTm = () => {
    let cmt = '段取時間＝';
    let subDataTypeArr = [];
    for (let j = 0; j < processArr?.length; j++) {
      if (!subDataTypeArr?.includes(processArr[j]?.processTypeId)) {
        subDataTypeArr?.push(processArr[j]?.processTypeId);
        cmt += processArr[j]?.processType + '段取時間+';
      }
    }
    cmt += 'プログラム作成時間+曲長段取時間';
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{cmt}</p>
      </div>
    );
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <>
      {props?.tabOpen && props?.isBending ? (
        <div className="kouteiCalculateDiv">
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={bending}>
            <Col span={4} className="oyabuhin-col">
              <div style={{ display: 'flex' }}>
                {isBendingdetail ? (
                  <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                )}
                <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
              </div>
            </Col>
            <Col span={6} className="oyabuhin-col">
              <div>{bendingAmt ? JPYs.format(Math.round(bendingAmt)) : amtEmptyStr}</div>
            </Col>
            <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
              <div>
                <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
              </div>
            </Col>
          </Row>
          <div style={{ display: isBendingdetail ? 'block' : 'none' }}>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                工程詳細
              </Col>
              <Col span={6} className="oyabuhin-detail-col">
                <Checkbox
                  key={kouteiCalculateSelectedArr[0].key}
                  value={kouteiCalculateSelectedArr[0].value}
                  checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
                  disabled={!props?.editMode}
                  style={{ marginRight: 6 }}
                  onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
                ></Checkbox>
                <label>{kouteiCalculateSelectedArr[0].value}</label>
              </Col>
              <Col span={8} className="oyabuhin-detail-col">
                <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                  <Checkbox
                    key={kouteiCalculateSelectedArr[1].key}
                    value={kouteiCalculateSelectedArr[1].value}
                    disabled={!props?.editMode}
                    onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                    checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                    style={{ marginRight: 6 }}
                  ></Checkbox>
                  <label>{kouteiCalculateSelectedArr[1].value}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                  <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                    {DataButton}
                  </Button>
                </div>
              </Col>

              <Col span={1} className="oyabuhin-detail-col">
                単位
              </Col>
              <Col span={5} className="formula-column">
                計算式
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                {processMst?.name}
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalAmtIn"
                  style={{
                    color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalTimeIn"
                  style={{
                    color: Math.round(totalTime) === Math.round(totalTimeSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalTime ? secondsToHms(totalTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.procTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.procTotalTm}>時間</Popover>
              </Col>
            </Row>
            {/* 段取金額/時間 */}
            <Row
              style={{ color: 'black' }}
              className="oyabuhin-detail oyabuhin-row"
              onClick={(e) => handleCollpse('BendingDandori')}
            >
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpBendingDandori'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />

                  <CaretDownOutlined id={'arrowDownBendingDandori'} style={{ fontSize: 17, marginTop: 2 }} />
                  <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriAmt">
                  {dandoriAmtSum ? JPYs.format(Math.round(dandoriAmtSum)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriAmtIn"
                  style={{
                    color: Math.round(dandoriAmtSum) === Math.round(totalDandoriAmt) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalDandoriAmt ? JPYs.format(Math.round(totalDandoriAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriTimeIn"
                  style={{
                    color: Math.round(dandoriTimeSum) === Math.round(totalDandoriTime) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={getSetupTotalTm()}>時間</Popover>
              </Col>
            </Row>
            <div id={'divBendingDandori'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];
                let subDataTypeArr = [];
                for (let j = 0; j < processArr?.length; j++) {
                  if (!subDataTypeArr.includes(processArr[j]?.processTypeId)) {
                    subDataTypeArr.push(processArr[j]?.processTypeId);
                    let setupTmCmt = getSetupTmCmt(processArr[j]?.processType);
                    arr.push(
                      <div>
                        {/* ○○_標準 */}

                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <div style={{ display: 'flex' }}>
                              <span style={{ marginLeft: '20px' }}>{processArr[j]?.processType}</span>
                            </div>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={j + 'BendingDandoriAmt'}>
                              {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                                ? JPYs.format(Math.round(processArr[j]?.dandoriAmt))
                                : amtEmptyStr}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            {
                              <label id={j + 'BendingDndoriTime'}>
                                {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                              </label>
                            }
                          </Col>

                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={j + 'BendingDandoriAmtIn'}
                              value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                              defaultValue={0}
                              style={{
                                border: 'none',
                                color:
                                  processArr[j]?.dandoriAmt === editedData[j]?.dandoriAmt
                                    ? 'black'
                                    : checkShowHideIcon(j + 'BendingDandoriAmtCu') === 'none'
                                    ? '#4cc12f'
                                    : 'red',
                              }}
                              onValueChange={(e) => {
                                //checkRegex(e.target.value);
                                if (editedData[j]?.dandoriAmt != e) {
                                  settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                  checkInputWithColor(false, '', j + 'BendingDandoriAmt', e, 'amt');
                                }
                              }}
                              className={
                                editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={j + 'BendingDandoriAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={
                                    editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr
                                  }
                                  id={j + 'BendingDandoriTimeIn'}
                                  style={{
                                    width: '100%',
                                    color:
                                      processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                        ? handleShowHide(
                                            Number(editedData[j]?.dandoriAmt),
                                            processArr[j]?.dandoriAmt,
                                            j + 'BendingDandoriAmtCu'
                                          )
                                        : 'red',
                                  }}
                                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                  onChange={(e) => {
                                    settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j]?.dandoriTime,
                                          '10min',
                                          j + 'BendingDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j]?.dandoriTime,
                                          '10min',
                                          j + 'BendingDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'min',
                                          j + 'BendingDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'min',
                                          j + 'BendingDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'sec',
                                          j + 'BendingDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[j]?.dandoriTime,
                                          'sec',
                                          j + 'BendingDandoriTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={2} className="formula-column formula-column-right-border">
                            <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                          </Col>
                          <Col span={2} className="formula-column">
                            <Popover content={setupTmCmt}>時間</Popover>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                }
                return arr;
              })()}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <span style={{ marginLeft: '20px' }}>プログラム作成時間</span>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id="BendingProgramAmt">
                    {processArr[0]?.programAmt ? JPYs.format(Math.round(processArr[0]?.programAmt)) : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id="BendingProgramTime">
                    {processArr[0]?.programTime ? secondsToHms(processArr[0]?.programTime) : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id="BendingProgramAmtIn"
                    value={editedData[0]?.programAmt ? Math.round(editedData[0]?.programAmt) : 0}
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.programAmt != e) {
                        /* setEditedHandelingAmt(e); */
                        settingNewDandoriAmtInArr(e, 0, 'programAmt', '', '');
                        checkInputWithColor(false, '', 'BendingProgramAmt', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        programAmt === editedData[0]?.programAmt
                          ? 'black'
                          : checkShowHideIcon('BendingProgramAmtCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'BendingProgramAmtCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={editedData[0]?.programTime ? secondsToHms(editedData[0]?.programTime) : timeHmsStr}
                        id="BendingProgramTimeIn"
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.programTime === programTime
                              ? handleShowHide(
                                  Number(programAmt),
                                  Number(editedData[0]?.programAmt),
                                  'BendingProgramAmtCu'
                                )
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'programTime', 'input', 'input');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.programTime,
                                '10min',
                                'BendingProgramTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'programTime', '10min', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.programTime,
                                '10min',
                                'BendingProgramTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'programTime', '10min', 'dec');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.programTime,
                                'min',
                                'BendingProgramTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'min', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.programTime,
                                'min',
                                'BendingProgramTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'min', 'dec');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.programTime,
                                'sec',
                                'BendingProgramTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'sec', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.programTime,
                                'sec',
                                'BendingProgramTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'sec', 'dec');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.setupTm_p7}>時間</Popover>
                </Col>
              </Row>

              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <span style={{ marginLeft: '20px' }}>曲長段取時間</span>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id="BendingMageDandoriAmt">
                    {processArr[0]?.mageDandoriAmt
                      ? JPYs.format(Math.round(processArr[0]?.mageDandoriAmt))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id="BendingMageDandoriTime">
                    {processArr[0]?.mageDandoriTime ? secondsToHms(processArr[0]?.mageDandoriTime) : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id="BendingMageDandoriAmtIn"
                    value={editedData[0]?.mageDandoriAmt ? Math.round(editedData[0]?.mageDandoriAmt) : 0}
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.mageDandoriAmt != e) {
                        /* setEditedHandelingAmt(e); */
                        settingNewDandoriAmtInArr(e, 0, 'mageDandoriAmt', '', '');
                        checkInputWithColor(false, '', 'BendingMageDandoriAmt', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        mageDandoriAmt === editedData[0]?.mageDandoriAmt
                          ? 'black'
                          : checkShowHideIcon('BendingMageDandoriAmtCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'BendingMageDandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.mageDandoriTime ? secondsToHms(editedData[0]?.mageDandoriTime) : timeHmsStr
                        }
                        id="BendingMageDandoriTimeIn"
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.mageDandoriTime === mageDandoriTime
                              ? handleShowHide(
                                  Number(mageDandoriAmt),
                                  Number(editedData[0]?.mageDandoriAmt),
                                  'BendingMageDandoriAmtCu'
                                )
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'mageDandoriTime', 'input', 'input');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.mageDandoriTime,
                                '10min',
                                'BendingMageDandoriTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', '10min', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.mageDandoriTime,
                                '10min',
                                'BendingMageDandoriTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', '10min', 'dec');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.mageDandoriTime,
                                'min',
                                'BendingMageDandoriTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'min', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.mageDandoriTime,
                                'min',
                                'BendingMageDandoriTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'min', 'dec');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.mageDandoriTime,
                                'sec',
                                'BendingMageDandoriTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'sec', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.mageDandoriTime,
                                'sec',
                                'BendingMageDandoriTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'sec', 'dec');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.setupTm_p8}>時間</Popover>
                </Col>
              </Row>
            </div>
            {/*  作業金額/時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BendingSagyoTotal')}>
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpBendingSagyoTotal'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />

                  <CaretDownOutlined id={'arrowDownBendingSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

                  <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoAmtIn"
                  style={{
                    color: Math.round(totalSagyoAmt) === Math.round(sagyoAmtSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoTimeIn"
                  style={{
                    color: Math.round(totalSagyoTime) === Math.round(sagyoTimeSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.workTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.workTotalTm}>時間</Popover>
              </Col>
            </Row>
            <div id={'divBendingSagyoTotal'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];

                for (let i = 0; i < processArr?.length; i++) {
                  arr.push(
                    <div>
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <div style={{ display: 'flex', marginLeft: '5px' }}>
                            <CaretUpOutlined id={'arrowUpBendingIQ3' + i} style={{ fontSize: 17, marginTop: 2 }} />
                            <CaretDownOutlined
                              id={'arrowDownBendingIQ3' + i}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <span style={{ marginLeft: '5px' }}>{processArr[i]?.processType}</span>
                          </div>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          {/* ¥ */}
                          <label id={i + 'bendingSagyoAmt'}>
                            {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <label id={i + 'bendingSagyoTime'}>
                            {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>
                        <Col span={4} className="input-col">
                          <CurrencyInput
                            id={i + 'bendingSagyoAmtIn'}
                            value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                            defaultValue={0}
                            onValueChange={(e) => {
                              if (editedData[i]?.sagyoAmt != e) {
                                settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                                checkInputWithColor(false, '', i + 'bendingSagyoAmt', e, 'amt');
                              }
                            }}
                            style={{
                              border: 'none',
                              color:
                                processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt
                                  ? 'black'
                                  : checkShowHideIcon(i + 'bendingSagyoAmtCu') === 'none'
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={
                              editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                            }
                            prefix="¥"
                            decimalsLimit={100}
                          />

                          <div id={i + 'bendingSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                            <div className="currencyIcon">
                              <BsUsbC style={{ fontSize: '25' }} />
                              <BsX style={{ margin: '-8px 0 0 16px' }} />
                              <span class="tooltiptext">
                                金額に変更がありましたので、以降時間と連携して自動計算されない。
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                                id={i + 'bendingSagyoTimeIn'}
                                style={{
                                  width: '100%',
                                  color:
                                    processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                      ? 'black'
                                      : oldEditedData[i]?.partCnt === editedData[i]?.partCnt &&
                                        oldEditedData[i]?.workTimeSize === editedData[i]?.workTimeSize
                                      ? 'red'
                                      : '#4cc12f',
                                }}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                onChange={(e) => {
                                  settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        '10min',
                                        i + 'bendingSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        '10min',
                                        i + 'bendingSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'min',
                                        i + 'bendingSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'min',
                                        i + 'bendingSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'sec',
                                        i + 'bendingSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[i]?.sagyoTime,
                                        'sec',
                                        i + 'bendingSagyoTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                        <Col
                          span={2}
                          className="formula-column formula-column-right-border"
                          onClick={(e) => subpartDetail(i)}
                        >
                          <Popover content={helpCmt.workAmt_p2}>金額</Popover>
                        </Col>
                        <Col span={2} className="formula-column" onClick={(e) => subpartDetail(i)}>
                          <Popover content={helpCmt.workTm_bending}>時間</Popover>
                        </Col>
                      </Row>
                      <div id={'bendingProcessIQ3' + i} style={{ display: 'none' }}>
                        <div>
                          {/* 作業金額/時間 */}

                          <div>
                            {/* 加工種類/仮付箇所 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id="partCnt">{processArr[i]?.partCnt}</label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'bendingTimeForOne'}>
                                  {processArr[i]?.workTimeSize ? secondsToHms(processArr[i]?.workTimeSize) : timeHmsStr}
                                </label>
                              </Col>

                              <Col span={4} className="input-col">
                                <Input
                                  value={editedData[i]?.partCnt}
                                  id={i + 'partCntIn'}
                                  min={0}
                                  className={editMode ? 'input-editable' : 'input-non-editable '}
                                  controls={false}
                                  style={{
                                    width: '45%',
                                    border: 'none',
                                    color: Number(editedData[i]?.partCnt) === processArr[i]?.partCnt ? 'black' : 'red',
                                  }}
                                  onKeyPress={handleKeyPress}
                                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'partCnt', '', '')}
                                />
                              </Col>

                              <Col span={4} className="input-col">
                                <Row style={{ width: '100%' }}>
                                  <Col className="timer-input-col">
                                    <Input
                                      value={
                                        editedData[i]?.workTimeSize
                                          ? secondsToHms(editedData[i]?.workTimeSize)
                                          : timeHmsStr
                                      }
                                      id={i + 'bendingTimeForOneIn'}
                                      min={0}
                                      className={editMode ? 'input-editable' : 'input-non-editable '}
                                      controls={false}
                                      style={{
                                        width: '100%',
                                        border: 'none',
                                        color:
                                          editedData[i]?.workTimeSize === processArr[i]?.workTimeSize ? 'black' : 'red',
                                      }}
                                      onKeyPress={handleKeyPress}
                                      onChange={(e) =>
                                        settingNewDandoriAmtInArr(e.target.value, i, 'workTimeSize', '', '')
                                      }
                                    />
                                  </Col>
                                  <Col className="timer-button-col">
                                    {editMode ? (
                                      <>
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.workTimeSize,
                                              '10min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.workTimeSize,
                                              '10min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'min',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'sec',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.workTimeSize,
                                              'sec',
                                              'workTimeSize'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'dec');
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={4} className="formula-column"></Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return arr;
              })()}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiCalculate_Bending;
