/**
 * クラス名：装置設定の段取設定画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { Component, useEffect, forwardRef, useState, useRef } from 'react';
import { Row, Col, Input, Button, Space, Checkbox, Popover, Typography } from 'antd';
import { Table } from 'react-bootstrap';
import { CaretUpOutlined, CaretDownOutlined, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import {
  confirmUpdateContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  deleteListColData,
  updateListColData,
  sortListData,
  getProcessDetailTypesInfo,
  secondsToHms,
  setTimetoSec,
  getPrepCoeffTypeName,
  defaultQuantiyData,
  defaultWeightData,
  handleKeyPress,
  getInputBorderStyle,
  toDecimal,
  regexExp,
  toHalfWidth,
  isDigitZenkaku,
} from '../../../common/Common';
import { PrepCoeffType, WorkType } from '../../../common/enums';
import { getProcessingShapeCoeffNm, getMaterialTypeIq5Nm, getIQ5CoeffDetailValues } from '../../../common/CommonIQ5';

const SetupSetting = forwardRef((props, ref) => {
  // 段取設定
  const [dantoriKouteiDetails, setDantoriKouteiDetails] = useState([]);

  const [category, setCategory] = useState('');
  const [editMode, setEditMode] = useState(false);
  // 新規modal
  const [isKeisuu, setIsKeisuu] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isDiscardData, setIsDiscardData] = useState(false);

  const [suuryouMstData, setSuuryouMstData] = useState([]);
  const [suuryouMstDataTemp, setSuuryouMstDataTemp] = useState([]);
  const [suuryouChildList, setSuuryouChildList] = useState([]);
  const [suuryouChildTypeList, setSuuryouChildTypeList] = useState([]);
  const [isSuuryou, setIsSuuryou] = useState(false);

  const [weightMstData, setWeightMstData] = useState([]);
  const [weightMstDataTemp, setWeightMstDataTemp] = useState([]);
  const [weightList, setWeightList] = useState([]);
  const [isWeight, setIsWeight] = useState(false);

  // 基本段取時間
  const [basicTime, setBasicTime] = useState(0);

  // 子部品数量
  // const [dantoriSuuryouList, setDantoriSuuryouList] = useState([]);

  // 製品重量係数のサイズリスト
  const [productSizeList, setProductSizeList] = useState([]);

  // 段取按分しない
  const [dantoriFlag, setDantoriFlag] = useState(false);

  const [processMstRecord, setProcessMstRecord] = useState();

  const [groupDisplayList, setGroupDisplayList] = useState([]);

  // 段取係数種類
  const [usedPrepCoeffType, setUsedPrepCoeffType] = useState();
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [weightErrIndex, setWeightErrIndex] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [weightErrorMessage, setWeightErrorMessage] = useState('');

  // 形鋼重量項目
  const [setupShapeCoeff, setSetupShapeCoeff] = useState([]);
  const [materialClassificationCoeff, setMaterialClassificationCoeff] = useState([]);
  const [weightClassificationCoeffThMaster, setWeightClassificationCoeffThMaster] = useState([]);
  const [weightClassificationCoeffThItems, setWeightClassificationCoeffThItems] = useState([]);

  const inputRef = useRef([]);
  const inputWeightRef = useRef([]);

  useEffect(() => {
    setEditMode(props.editMode);
    if (props.detailsData != undefined) {
      if (props?.detailsData?.prepSecs) setDantoriKouteiDetails(props?.detailsData?.prepSecs);
      setCategory(props.processType);

      // 数量
      if (props?.detailsData?.prepThQtyMaster) setSuuryouMstData(props?.detailsData?.prepThQtyMaster);
      if (props?.detailsData?.prepThQtyMaster?.length > 0) {
        setSuuryouMstDataTemp(props?.detailsData?.prepThQtyMaster);
      } else {
        setSuuryouMstDataTemp(defaultQuantiyData);
      }
      if (props?.detailsData?.prepCoeffThChildQtyItems) {
        // const listsWithTwoDecimalPlaces = props?.detailsData?.prepCoeffThChildQtyItems?.map((item) => {
        //   return {
        //     ...item,
        //     value: toDecimal(item.value, 2),
        //   };
        // });
        setSuuryouChildList(props?.detailsData?.prepCoeffThChildQtyItems);
        // setSuuryouChildList(listsWithTwoDecimalPlaces);
      }

      if (props?.detailsData?.prepCoeffThChildTypeQtyItems)
        setSuuryouChildTypeList(props?.detailsData?.prepCoeffThChildTypeQtyItems);

      // 段取係数種類のチェック値
      setUsedPrepCoeffType(props?.detailsData?.prepCoeffType);

      // 重量と体積
      if (
        category === WorkType.PaSteelWeight ||
        category === WorkType.PaSteelArea ||
        category === WorkType.PaSteelWeldFinish ||
        category === WorkType.PaSteelWeld ||
        category === WorkType.PaSteelDebbur ||
        category === WorkType.PaSteelGroove ||
        category === WorkType.PaSteelMount ||
        category === WorkType.PaSteelBend ||
        category === WorkType.PaSteelNotch ||
        category === WorkType.PaSteelHole ||
        category === WorkType.PaSteelCut
      ) {
        if (props?.detailsData?.weightClassificationCoeffThMaster)
          setWeightMstData(props?.detailsData?.weightClassificationCoeffThMaster);
        if (props?.detailsData?.weightClassificationCoeffThMaster?.length > 0) {
          setWeightMstDataTemp(props?.detailsData?.weightClassificationCoeffThMaster);
        } else {
          setWeightMstDataTemp(defaultWeightData);
        }
        if (props?.detailsData?.weightClassificationCoeffThItems)
          setWeightList(props?.detailsData?.weightClassificationCoeffThItems);
      } else {
        if (props?.detailsData?.prepCoeffThWeightMaster) setWeightMstData(props?.detailsData?.prepCoeffThWeightMaster);
        if (props?.detailsData?.prepCoeffThWeightMaster?.length > 0) {
          setWeightMstDataTemp(props?.detailsData?.prepCoeffThWeightMaster);
        } else {
          setWeightMstDataTemp(defaultWeightData);
        }
        if (props?.detailsData?.prepCoeffThWeightItems) setWeightList(props?.detailsData?.prepCoeffThWeightItems);
      }

      // 基本段取時間
      setBasicTime(props?.detailsData?.prepBasicTime);

      // 段取按分しない
      setDantoriFlag(props?.detailsData?.apportionSelect);
    }

    if (props?.processMstRecord) {
      setProcessMstRecord(props.processMstRecord);
    }

    // 段取設定の値がある場合、
    if (props?.detailsData?.prepSecs && props?.processMstRecord) {
      // 工程種別の詳細情報を得る
      const groupDisplayList = getProcessDetailTypesInfo(props?.detailsData?.prepSecs, props?.processMstRecord);

      setGroupDisplayList(groupDisplayList);
    }

    // 子部品数量(同時にリスト更新)
    // setDantoriSuuryouList(props.kobuhinsuuryouList);

    // 製品重量係数のサイズリスト
    setProductSizeList(props.sizeList);

    // 形鋼重量の係数設定
    if (props?.detailsData?.setupShapeCoeff) setSetupShapeCoeff(props?.detailsData?.setupShapeCoeff);
    if (props?.detailsData?.materialClassificationCoeff)
      setMaterialClassificationCoeff(props?.detailsData?.materialClassificationCoeff);
    if (props?.detailsData?.weightClassificationCoeffThMaster)
      setWeightClassificationCoeffThMaster(props?.detailsData?.weightClassificationCoeffThMaster);
    if (props?.detailsData?.weightClassificationCoeffThItems)
      setWeightClassificationCoeffThItems(props?.detailsData?.weightClassificationCoeffThItems);
  }, [
    props.editMode,
    props.processType,
    props.detailsData,
    // props.kihonDantoriTime,
    // props.suuryouData,
    // props.dantoriSetteiSuuryouList,
    // props.kobuhinsuuryouList,
    // props.weight,
    // props.dantoriSizeList,
    // props.sizeList,
    // props.dantoriFlag,
  ]);

  // 段取設定の値を入力された場合、
  const changeDantoriTime = (itemId, value) => {
    // const updatedData = dantoriKouteiDetails?.map((item) => {
    //   if (item.key === itemId) {
    //     const updatedSubItems = item.processDetailValue?.map((subItem) => {
    //       if (subItem.key === subItemId) {
    //         return { ...subItem, dantoriTime: value };
    //       }
    //       return subItem;
    //     });

    //     return { ...item, processDetailValue: updatedSubItems };
    //   }
    //   return item;
    // });
    Object.entries(dantoriKouteiDetails)?.map(([key]) => {
      if (parseInt(key) === itemId) {
        dantoriKouteiDetails[key] = setTimetoSec(value);
      }
    });
    setDantoriKouteiDetails(dantoriKouteiDetails);
    props.updatedDantoriSetting(dantoriKouteiDetails);
  };

  const addData = () => {
    setIsUpdateData(true);
  };

  // 更新確認メッセージのOKボタン押下
  const updateDataOk = () => {
    if (isKeisuu == true) {
    }
  };

  // 更新確認メッセージのCancelボタン押下
  const updateDataCancel = () => {
    if (isKeisuu == true) {
      setIsKeisuu(true);
    }
    setIsUpdateData(false);
  };

  // 破壊確認メッセージのOKボタン押下
  const discardOk = () => {
    if (isKeisuu == true) {
      setIsKeisuu(false);
    }
    setIsDiscardData(false);
  };

  // 破壊確認メッセージのCancelボタン押下
  const discardCancel = () => {
    if (isKeisuu == true) {
      setIsKeisuu(true);
    }
    setIsDiscardData(false);
  };

  // 個数又重量マスタ一覧の[X]ボタン押下
  const closeAddModal = () => {
    if (isWeight === true) {
      setIsWeight(false);
    } else if (isSuuryou === true) {
      setIsSuuryou(false);
    } else if (isYousosuu === true) {
      setIsYousosuu(false);
    }
  };

  // 基本段取時間を入力された場合、
  const changeBasicTime = (e, isSec = false) => {
    let basicTime = isSec ? e : setTimetoSec(e);
    setBasicTime(basicTime);
    props.updatedBasicTime(basicTime);
  };

  // 個数ボタン押下
  const suuryou = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsSuuryou(true);
  };

  // 個数マスタ一覧の追加ボタン押下
  const addSuuryou = (no, index) => {
    inputRef.current[index].focus();
    const copyData = suuryouMstDataTemp.slice(); // Create a copy of the original array

    const insertIndex = suuryouMstDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: suuryouMstDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSuuryouMstDataTemp(copyData);
    }
  };

  // 個数マスタ一覧の削除ボタン押下
  const deleteSuuryou = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    const listTemp = JSON.parse(JSON.stringify(suuryouMstDataTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setSuuryouMstDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 個数マスタ一覧のOKボタン押下
  const okSuuryouMasterData = () => {
    const deletedId = [];

    suuryouMstData?.forEach((item1, index) => {
      const found = suuryouMstDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = suuryouMstDataTemp
      ?.filter((item) => !item.isOutOfRange && !suuryouMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const afterDeletedChildList = deleteListColData(suuryouChildList, deletedId);

    const updatedDataChildList = updateListColData(afterDeletedChildList, newId);

    const sortedChildList = sortListData(updatedDataChildList, suuryouMstDataTemp);

    const afterDeletedChildTypeList = deleteListColData(suuryouChildTypeList, deletedId);

    const updatedDataChildTypeList = updateListColData(afterDeletedChildTypeList, newId);

    const sortedChildTypeList = sortListData(updatedDataChildTypeList, suuryouMstDataTemp);

    // const sortedList = updatedData?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = suuryouMstDataTemp?.findIndex((obj) => obj.no === a.no);
    //     const bIndex = suuryouMstDataTemp?.findIndex((obj) => obj.no === b.no);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //     isUsed: item.isUsed,
    //   };
    // });

    setIsSuuryou(false);
    setSuuryouMstData(suuryouMstDataTemp);
    props.updatedSuuryouMstData(suuryouMstDataTemp);

    setSuuryouChildList(sortedChildList);
    props.updatedSuuryouChildList(sortedChildList);

    setSuuryouChildTypeList(sortedChildTypeList);
    props.updatedSuuryouChildTypeList(sortedChildTypeList);
  };

  // 個数マスタ一覧のCancelボタン押下
  const cancelSuuryouMasterData = () => {
    if (isSuuryou === true) {
      setSuuryouMstDataTemp(suuryouMstData);
      setIsSuuryou(false);
    }
  };

  // 個数マスタ一覧の値を入力された場合、
  const handleSuuryouChange = (event, no, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const temp = JSON.parse(JSON.stringify(suuryouMstDataTemp));
    const updatedData = temp
      ?.filter((i) => !i.isOutOfRange)
      ?.map((row) => {
        if (row.no === no) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });

    const previousValue = updatedData[updatedData?.length - 2].value;
    if (previousValue) {
      updatedData[updatedData?.length - 1].value = (parseInt(previousValue) + 1).toString();
      setSuuryouMstDataTemp(updatedData);
    } else {
      setSuuryouMstDataTemp(updatedData);
    }
  };

  // 個数マスタ一覧のヘッダー
  const addSuuryouModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>個数</p>
    </div>
  );
  const isValueOrderCorrect = (e, no, field) => {
    const temp = suuryouMstDataTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleSuuryouChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const isWeightValueOrderCorrect = (e, no, field) => {
    const temp = weightMstDataTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      changeWeightMstData(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setWeightErrIndex([...errIndexVal, insertIndex]);
      setWeightErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setWeightErrIndex(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setWeightErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setWeightErrIndex([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setWeightErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setWeightErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setWeightErrIndex(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setWeightErrIndex([...errIndexVal, insertIndex]);
        setWeightErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  // const getInputBorderStyle = (index) => {
  //   const item = suuryouMstDataTemp?.filter((item) => !item.isOutOfRange)[index];

  //   if (item.value === 0 && item.isOutOfRange === false) {
  //     return { border: '1px solid red' };
  //   } else {
  //     return { border: '' };
  //   }
  // };
  // 個数マスタ一覧
  const addSuuryouContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {suuryouMstDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onChange={(e) => handleSuuryouChange(e, item.no, 'value')}
                          style={{
                            ...getInputBorderStyle(index, suuryouMstDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSuuryou(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSuuryou(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okSuuryouMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelSuuryouMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 子部品点数又子部品種類数の値を入力された場合、
  const changeSuuryou = (event, no, coeffType) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    // const copyData = [...suuryouChildList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    if (coeffType === PrepCoeffType.ChildQty) {
      suuryouChildList
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event;
        });
      setSuuryouChildList(suuryouChildList);
      props.updatedSuuryouChildList(suuryouChildList);
    } else {
      suuryouChildTypeList
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event;
        });
      setSuuryouChildTypeList(suuryouChildTypeList);
      props.updatedSuuryouChildTypeList(suuryouChildTypeList);
    }
  };
  // 子部品点数又子部品種類数の値を'0'入力された場合、
  const changeSuuryouOnBlur = (event, no, coeffType) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    if (coeffType === PrepCoeffType.ChildQty) {
      suuryouChildList
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          event = regexExp.test(event) ? toHalfWidth(event) : event;
          list.value = parseFloat(event) > 0 ? event : 1;
        });
      setSuuryouChildList(suuryouChildList);
      props.updatedSuuryouChildList(suuryouChildList);
    } else {
      suuryouChildTypeList
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          event = regexExp.test(event) ? toHalfWidth(event) : event;
          list.value = parseFloat(event) > 0 ? event : 1;
        });
      setSuuryouChildTypeList(suuryouChildTypeList);
      props.updatedSuuryouChildTypeList(suuryouChildTypeList);
    }
  };

  // 子部品点数又子部品種類数のチェックボタンを押下、
  const changeChkValue = (e, coeffType) => {
    if (coeffType === PrepCoeffType.ChildQty && e.target.checked) {
      setUsedPrepCoeffType(PrepCoeffType.ChildQty);
      props.updatedPrepCoeffType(PrepCoeffType.ChildQty);
    } else {
      setUsedPrepCoeffType(PrepCoeffType.ChildTypeQty);
      props.updatedPrepCoeffType(PrepCoeffType.ChildTypeQty);
    }
  };

  // 重量ボタン押下
  const addWeight = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsWeight(true);
  };

  // 重量マスタ一覧のヘッダー
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );

  // 重量マスタ一覧の削除ボタン押下
  const deleteWeight = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = weightErrIndex?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setWeightErrIndex(finalErrorIndexes);
    if (weightMstDataTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = weightMstDataTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setWeightMstDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputWeightRef.current[index].focus();
      }
    }
  };

  // 重量マスタ一覧の追加ボタン押下
  const addWeightRow = (no, index) => {
    inputWeightRef.current[index].focus();
    const copyData = weightMstDataTemp.slice(); // Create a copy of the original array

    const insertIndex = weightMstDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: weightMstDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setWeightMstDataTemp(copyData);
    }
  };

  // 重量マスタ一覧のCancelボタン押下
  const cancelWeightMaster = () => {
    setWeightMstDataTemp(weightMstData);
    setIsWeight(false);
  };

  // 重量マスタ一覧のOKボタン押下
  const okWeightMaster = () => {
    const deletedId = [];

    weightMstData?.forEach((item1, index) => {
      const found = weightMstDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = weightMstDataTemp
      ?.filter((item) => !item.isOutOfRange && !weightMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deletedData = deleteListColData(weightList, deletedId);
    // const deletedData2 = deleteListColData(productSizeList, deletedId);

    const updatedData = updateListColData(deletedData, newId);
    // const updatedData2 = updateListColData(deletedData2, newId);

    const sortedData = sortListData(updatedData, weightMstDataTemp);
    // const sortedData2 = sortListData(updatedData2, weightMstDataTemp);

    setIsWeight(false);
    setWeightMstData(weightMstDataTemp);
    if (
      category === WorkType.PaSteelWeight ||
      category === WorkType.PaSteelArea ||
      category === WorkType.PaSteelWeldFinish ||
      category === WorkType.PaSteelWeld ||
      category === WorkType.PaSteelDebbur ||
      category === WorkType.PaSteelGroove ||
      category === WorkType.PaSteelMount ||
      category === WorkType.PaSteelBend ||
      category === WorkType.PaSteelNotch ||
      category === WorkType.PaSteelHole ||
      category === WorkType.PaSteelCut
    ) {
      props.updatedWeightClassificationCoeffThMaster(weightMstDataTemp);
      props.updatedWeightClassificationCoeffThItems(sortedData);
    } else {
      props.updatedWeightMstData(weightMstDataTemp);
      props.updatedWeightListData(sortedData);
    }

    setWeightList(sortedData);

    // setProductSizeList(sortedData2);
    // props.updatedSizesList(sortedData2);
  };

  // 重量マスタ一覧の値を入力された場合、
  const changeWeightMstData = (event, no, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = weightMstDataTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setWeightMstDataTemp(updatedData);
  };

  // 重量マスタ一覧
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label> （≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {weightMstDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>

                      <td>
                        {weightErrIndex?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{weightErrorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          className={weightErrIndex?.includes(index) ? 'red-border' : ''}
                          onChange={(e) => changeWeightMstData(e, item.no, 'value')}
                          onBlur={(e) => isWeightValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isWeightValueOrderCorrect(e, item.no, 'value')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          style={{
                            ...getInputBorderStyle(index, weightMstDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputWeightRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(weightErrIndex) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okWeightMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 重量リストの値を入力された場合、
  const changeWeightListData = (event, no) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    // const copyData = [...weightList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    // setWeightList(updatedData);
    // props.updatedDantoriSizeList(updatedData);
    weightList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setWeightList(weightList);
    props.updatedWeightListData(weightList);
  };
  // 重量リストの値を'0'入力された場合、
  const changeWeightListDataOnBlur = (event, no) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    weightList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
        list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
      });
    setWeightList(weightList);
    props.updatedWeightListData(weightList);
  };

  const changeflag = (e) => {
    const isChecked = e.target.checked;
    setDantoriFlag(isChecked);
    props.updateDantoriFlag(isChecked);
  };

  // 段取設定の詳細情報を得る
  const getPrepSettingDetailValues = (id) => {
    var detailValue = {};
    detailValue = Object.entries(dantoriKouteiDetails)?.filter(([key, data]) => parseInt(key) === id)?.[0];
    return detailValue;
  };

  // 形鋼形状区分係数の変更イベント処理
  const changeSetupShapeCoeff = (itemId, value) => {
    Object.entries(setupShapeCoeff)?.map(([key]) => {
      if (parseInt(key) === parseInt(itemId)) {
        setupShapeCoeff[key] = value;
      }
    });
    setSetupShapeCoeff(setupShapeCoeff);
    props.updatedSetupShapeCoeff(setupShapeCoeff);
  };

  // 形鋼材質区分係数の変更イベント処理
  const changeMaterialClassificationCoeff = (itemId, value) => {
    Object.entries(materialClassificationCoeff)?.map(([key]) => {
      if (parseInt(key) === parseInt(itemId)) {
        materialClassificationCoeff[key] = value;
      }
    });
    setMaterialClassificationCoeff(materialClassificationCoeff);
    props.updatedMaterialClassificationCoeff(materialClassificationCoeff);
  };

  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        {/*  category=>溶接、溶接仕上、表面処理 */}
        {category === WorkType.PaWelding || category === WorkType.PaWeldFinish || category === WorkType.PaSurface ? (
          <>
            {Object.entries(groupDisplayList)?.map(([key, groupItem]) => (
              <Row key={key} className="chargeAmount">
                {groupItem?.map((subItem, index) => (
                  <Col key={index} style={{ width: '20%' }}>
                    <Table>
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">{subItem?.name}</th>
                        </tr>
                      </thead>
                      {getPrepSettingDetailValues(subItem?.id)?.map((item, index) => {
                        if (parseInt(item) === subItem?.id && index === 0) return;
                        return (
                          <tbody key={index}>
                            <tr style={{ textAlign: 'center', height: 35 }}>
                              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Row align="middle">
                                  {!editMode ? (
                                    <>
                                      <Col span={24}>
                                        <label>{secondsToHms(item)}</label>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col span={10}>
                                        <Input
                                          value={secondsToHms(item)}
                                          onChange={(event) => changeDantoriTime(subItem?.id, event.target.value)}
                                        ></Input>
                                      </Col>
                                      <Col span={14}>
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 16 + 'px',
                                            marginLeft: 3,
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(secondsToHms(item), '10min');
                                            changeDantoriTime(subItem?.id, editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(secondsToHms(item), '10min');
                                            changeDantoriTime(subItem?.id, editedTime);
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(secondsToHms(item), 'min');
                                            changeDantoriTime(subItem?.id, editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(secondsToHms(item), 'min');
                                            changeDantoriTime(subItem?.id, editedTime);
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleIncreaseTime(secondsToHms(item), 'sec');
                                            changeDantoriTime(subItem?.id, editedTime);
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{
                                            fontSize: 17 + 'px',
                                            color: '#000',
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            let editedTime = souchiHandleDecreaseTime(secondsToHms(item), 'sec');
                                            changeDantoriTime(subItem?.id, editedTime);
                                          }}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  </Col>
                ))}
              </Row>
            ))}
          </>
        ) : (
          // 組立、梱包、検査の場合、
          <>
            <Row className="chargeAmount">
              {/* <Table style={{ width: '40%' }}> */}
              <Table style={{ width: '20%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="tbl-header" style={{ width: '20%' }}>
                      {category === WorkType.PaSteelWeight ||
                        category === WorkType.PaSteelArea ||
                        category === WorkType.PaSteelWeldFinish ||
                        category === WorkType.PaSteelWeld ||
                        category === WorkType.PaSteelDebbur ||
                        category === WorkType.PaSteelGroove ||
                        category === WorkType.PaSteelMount ||
                        category === WorkType.PaSteelBend ||
                        category === WorkType.PaSteelNotch ||
                        category === WorkType.PaSteelHole ||
                        category === WorkType.PaSteelCut
                        ? '初期段取時間(sec)'
                        : '基本段取時間'}
                    </th>
                    {/* <th className="tbl-header" style={{ width: '20%' }}>
                      段取按分しない
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center', height: 35, verticalAlign: 'middle' }}>
                    <td>
                      <Row style={{ textAlign: 'center' }} align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>
                                {category === WorkType.PaSteelWeight ||
                                  category === WorkType.PaSteelArea ||
                                  category === WorkType.PaSteelWeldFinish ||
                                  category === WorkType.PaSteelWeld ||
                                  category === WorkType.PaSteelDebbur ||
                                  category === WorkType.PaSteelGroove ||
                                  category === WorkType.PaSteelMount ||
                                  category === WorkType.PaSteelBend ||
                                  category === WorkType.PaSteelNotch ||
                                  category === WorkType.PaSteelHole ||
                                  category === WorkType.PaSteelCut
                                  ? basicTime
                                  : secondsToHms(basicTime)}
                              </label>
                            </Col>
                          </>
                        ) : (
                          <>
                            {category === WorkType.PaSteelWeight ||
                              category === WorkType.PaSteelArea ||
                              category === WorkType.PaSteelWeldFinish ||
                              category === WorkType.PaSteelWeld ||
                              category === WorkType.PaSteelDebbur ||
                              category === WorkType.PaSteelGroove ||
                              category === WorkType.PaSteelMount ||
                              category === WorkType.PaSteelBend ||
                              category === WorkType.PaSteelNotch ||
                              category === WorkType.PaSteelHole ||
                              category === WorkType.PaSteelCut ? (
                              <>
                                <Col span={24}>
                                  <Input
                                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                                    value={basicTime}
                                    onChange={(e) => {
                                      setBasicTime(e.target.value);
                                      changeBasicTime(e.target.value, true);
                                    }}
                                  ></Input>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col span={10}>
                                  <Input
                                    value={secondsToHms(basicTime)}
                                    onChange={(e) => {
                                      setBasicTime(setTimetoSec(e.target.value));
                                    }}
                                  ></Input>
                                </Col>
                                <Col span={14}>
                                  <CaretUpOutlined
                                    style={{
                                      fontSize: 17 + 'px',
                                      marginLeft: 3,
                                      pointerEvents: 'auto',
                                    }}
                                    onClick={() => {
                                      let editedTime = souchiHandleIncreaseTime(secondsToHms(basicTime), '10min');
                                      changeBasicTime(editedTime);
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{
                                      fontSize: 17 + 'px',
                                      color: '#000',
                                      pointerEvents: 'auto',
                                    }}
                                    onClick={() => {
                                      let editedTime = souchiHandleDecreaseTime(secondsToHms(basicTime), '10min');
                                      changeBasicTime(editedTime);
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{
                                      fontSize: 17 + 'px',
                                      color: '#000',
                                      pointerEvents: 'auto',
                                    }}
                                    onClick={() => {
                                      let editedTime = souchiHandleIncreaseTime(secondsToHms(basicTime), 'min');
                                      changeBasicTime(editedTime);
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{
                                      fontSize: 17 + 'px',
                                      color: '#000',
                                      pointerEvents: 'auto',
                                    }}
                                    onClick={() => {
                                      let editedTime = souchiHandleDecreaseTime(secondsToHms(basicTime), 'min');
                                      changeBasicTime(editedTime);
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{
                                      fontSize: 17 + 'px',
                                      color: '#000',
                                      pointerEvents: 'auto',
                                    }}
                                    onClick={() => {
                                      let editedTime = souchiHandleIncreaseTime(secondsToHms(basicTime), 'sec');
                                      changeBasicTime(editedTime);
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{
                                      fontSize: 17 + 'px',
                                      color: '#000',
                                      pointerEvents: 'auto',
                                    }}
                                    onClick={() => {
                                      let editedTime = souchiHandleDecreaseTime(secondsToHms(basicTime), 'sec');
                                      changeBasicTime(editedTime);
                                    }}
                                  />
                                </Col>
                              </>
                            )}
                          </>
                        )}
                      </Row>
                    </td>
                    {/* <td style={{ verticalAlign: 'middle' }}>
                      <Checkbox
                        style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        checked={dantoriFlag}
                        onChange={changeflag}
                      ></Checkbox>
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        )}
        {/* {category === WorkType.PaSurface ? (
          // 表面処理の場合、
          <></>
        ) : ( */}{' '}
        {/** IQX_WEBEST-226 */}
        {category === WorkType.PaSteelWeight ||
          category === WorkType.PaSteelArea ||
          category === WorkType.PaSteelWeldFinish ||
          category === WorkType.PaSteelWeld ||
          category === WorkType.PaSteelDebbur ||
          category === WorkType.PaSteelGroove ||
          category === WorkType.PaSteelMount ||
          category === WorkType.PaSteelBend ||
          category === WorkType.PaSteelNotch ||
          category === WorkType.PaSteelHole ||
          category === WorkType.PaSteelCut ? (
          <>
            <div>
              {/* 形状区分係数 */}
              <>
                <Row>
                  <label style={{ fontSize: 14, fontWeight: 400 }}>形状区分係数</label>
                </Row>
                <div className='section-margin-bottom'>
                  <Row className="table-container">
                    {Object.entries(setupShapeCoeff)?.map(([key, data], index) => (
                      <Col key={index} style={{ width: '20%' }}>
                        <Table className='reset-spacing'>
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th className="tbl-header">
                                <Typography.Text className="tbl-header" ellipsis={{ tooltip: [getProcessingShapeCoeffNm(key, props?.steelShapeData)] }}>
                                  {getProcessingShapeCoeffNm(key, props?.steelShapeData)}
                                </Typography.Text>
                              </th>
                            </tr>
                          </thead>
                          <tbody key={index}>
                            <tr style={{ textAlign: 'center', height: 35 }}>
                              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Row align="middle">
                                  {!editMode ? (
                                    <>
                                      <Col span={24}>
                                        <label>{getIQ5CoeffDetailValues(key, setupShapeCoeff)}</label>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col span={24}>
                                        <Input
                                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                                          value={getIQ5CoeffDetailValues(key, setupShapeCoeff)}
                                          onChange={(event) => changeSetupShapeCoeff(key, event.target.value)}
                                        ></Input>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    ))}
                  </Row>
                </div>
              </>
              {/* 材質区分係数 */}
              <>
                <Row>
                  <label style={{ fontSize: 14, fontWeight: 400 }}>材質区分係数</label>
                </Row>
                <div className='section-margin-bottom'>
                  <Row className="table-container">
                    {Object.entries(materialClassificationCoeff)?.map(([key, data], index) => (
                      <Col key={index} style={{ width: '20%' }}>
                        <Table className='reset-spacing'>
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th className="tbl-header">{getMaterialTypeIq5Nm(key, props?.materialTypes)}</th>
                            </tr>
                          </thead>
                          <tbody key={index}>
                            <tr style={{ textAlign: 'center', height: 35 }}>
                              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Row align="middle">
                                  {!editMode ? (
                                    <>
                                      <Col span={24}>
                                        <label>{getIQ5CoeffDetailValues(key, materialClassificationCoeff)}</label>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col span={24}>
                                        <Input
                                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                                          value={getIQ5CoeffDetailValues(key, materialClassificationCoeff)}
                                          onChange={(event) => changeMaterialClassificationCoeff(key, event.target.value)}
                                        ></Input>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    ))}
                  </Row>
                </div>
              </>
              {/* 重量(Kg)区分係数 */}
              <>
                <Row>
                  <label style={{ fontSize: 14, fontWeight: 400 }}>重量(Kg)区分係数</label>
                </Row>
                <Row className="table-container">
                  <Table
                    style={{
                      width:
                        weightClassificationCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                          ? '100%'
                          : weightClassificationCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                    }}
                  >
                    <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                      <td style={{ width: '196px' }} className="tbl-header fixedCol">
                        <Row justify="center" className="tbl-header">
                          <Space>
                            {editMode ? (
                              <>
                                <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                                  重量(Kg)
                                </Button>
                              </>
                            ) : (
                              <>
                                <label>重量(Kg)</label>
                              </>
                            )}
                          </Space>
                        </Row>
                      </td>
                      {weightClassificationCoeffThMaster
                        ?.filter((subitem) => !subitem.isOutOfRange)
                        ?.map((row, index) => (
                          <td key={index} style={{ color: 'white', width: '98px' }}>
                            ～{row.value}kg
                          </td>
                        ))}
                    </tr>

                    <tr style={{ textAlign: 'center' }}>
                      <td style={{ width: '196px' }} className="fixedOneRowCol">
                        {'係数'}
                      </td>
                      {weightClassificationCoeffThItems
                        ?.filter((i) => !i.isOutOfRange)
                        ?.map((row, index) => (
                          <td key={index} style={{ width: '98px' }}>
                            <Input
                              value={editMode ? row.value : toDecimal(row.value, 2)}
                              onChange={(e) => changeWeightListData(e, row.no)}
                              onBlur={(e) => changeWeightListDataOnBlur(e, row.no)}
                              onPressEnter={(e) => changeWeightListDataOnBlur(e, row.no)}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable'}
                            />
                          </td>
                        ))}
                    </tr>
                  </Table>
                </Row>
              </>
            </div>
          </>
        ) : (
          <>
            <div>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>段取係数1</label>
              </Row>
              <Row className="table-container">
                <Table
                  style={{
                    width:
                      suuryouMstData?.filter((i) => !i.isOutOfRange)?.length > 7
                        ? '100%'
                        : suuryouMstData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                  }}
                >
                  <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                    <td style={{ width: '196px' }} className="tbl-header fixedCol">
                      <Row justify="center" className="tbl-header">
                        <Space>
                          {editMode ? (
                            <>
                              <label>種類</label>/
                              <Button className="cancleButton" onClick={suuryou} style={{ pointerEvents: 'auto' }}>
                                個数
                              </Button>
                            </>
                          ) : (
                            <>
                              <label>種類 / 個数</label>
                            </>
                          )}
                        </Space>
                      </Row>
                    </td>
                    {/* 種類 / 個数のヘッダー */}
                    {suuryouMstData
                      ?.filter((subitem) => !subitem.isOutOfRange)
                      ?.map((item, index) => (
                        <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                          {/* {suuryouMstData[suuryouMstData?.length - 1].no === item.no ? (
                            <>{item.value}個 ～</>
                          ) : (
                            <>～{item.value}個</>
                          )} */}
                          ～{item.value}個
                        </td>
                      ))}
                  </tr>
                  {/* 子部品点数のリスト一覧 */}
                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="fixedOneRowCol">
                      <Row className="tbl-header">
                        {/* IQX_WEBEST-262 段取係数1の子部品点数の前にチェック項目があるが、常にONの状態で表示にしてほしい */}
                        {category === WorkType.PaSurface ? (
                          <Col span={5}>
                            <Checkbox
                              // style={{ marginLeft: 10, pointerEvents: editMode ? 'auto' : 'none' }}
                              // checked={PrepCoeffType.ChildQty === usedPrepCoeffType}
                              // onChange={(e) => changeChkValue(e, PrepCoeffType.ChildQty)}
                              style={{ marginLeft: 10, pointerEvents: 'none' }}
                              checked={true}
                            ></Checkbox>
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col span={category === WorkType.PaSurface ? 19 : 24}>
                          <label style={{ color: 'white' }}>{getPrepCoeffTypeName(PrepCoeffType.ChildQty)}</label>
                        </Col>
                      </Row>
                    </td>
                    {suuryouChildList
                      ?.filter((item) => !item.isOutOfRange)
                      ?.map((row, index) => (
                        <td key={index} style={{ width: '98px' }}>
                          <Input
                            value={editMode ? row.value : toDecimal(row.value, 2)}
                            onChange={(e) => changeSuuryou(e.target.value, row.no, PrepCoeffType.ChildQty)}
                            onBlur={(e) => changeSuuryouOnBlur(e.target.value, row.no, PrepCoeffType.ChildQty)}
                            onPressEnter={(e) => changeSuuryouOnBlur(e.target.value, row.no, PrepCoeffType.ChildQty)}
                            onKeyPress={(e) => {
                              handleKeyPress(e, false);
                            }}
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                          />
                        </td>
                      ))}
                  </tr>
                  {/* 子部品種類数のリスト一覧 */}
                  {/* <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="fixedOneRowCol">
                      <Row className="tbl-header">
                        <Col span={5}>
                          <Checkbox
                            style={{ marginLeft: 10, pointerEvents: editMode ? 'auto' : 'none' }}
                            checked={PrepCoeffType.ChildTypeQty === usedPrepCoeffType}
                            onChange={(e) => changeChkValue(e, PrepCoeffType.ChildTypeQty)}
                          ></Checkbox>
                        </Col>
                        <Col span={19}>
                          <label style={{ color: 'white' }}>{getPrepCoeffTypeName(PrepCoeffType.ChildTypeQty)}</label>
                        </Col>
                      </Row>
                    </td>
                    {suuryouChildTypeList
                      ?.filter((item) => !item.isOutOfRange)
                      ?.map((row) => (
                        <td style={{ width: '98px' }}>
                          <Input
                            value={row.value}
                            onChange={(e) => changeSuuryou(e, row.no, PrepCoeffType.ChildTypeQty)}
                            onKeyPress={(e) => {
                              handleKeyPress(e, false);
                            }}
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                          />
                        </td>
                      ))}
                  </tr> */}
                </Table>
              </Row>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>段取係数2</label>
              </Row>
              {/* 重量 */}
              <Row className="table-container">
                <Table
                  style={{
                    width:
                      weightMstData?.filter((i) => !i.isOutOfRange)?.length > 8
                        ? '100%'
                        : weightMstData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                  }}
                >
                  <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                    <td style={{ width: '196px' }} className="tbl-header fixedCol">
                      <Row justify="center" className="tbl-header">
                        <Space>
                          {editMode ? (
                            <>
                              <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                                重量
                              </Button>
                            </>
                          ) : (
                            <>
                              <label>重量</label>
                            </>
                          )}
                        </Space>
                      </Row>
                    </td>
                    {weightMstData
                      ?.filter((subitem) => !subitem.isOutOfRange)
                      ?.map((row, index) => (
                        <td key={index} style={{ color: 'white', width: '98px' }}>
                          ～{row.value}kg
                        </td>
                      ))}
                  </tr>

                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="fixedOneRowCol">
                      {'重量係数'}
                    </td>
                    {weightList
                      ?.filter((i) => !i.isOutOfRange)
                      ?.map((row, index) => (
                        <td key={index} style={{ width: '98px' }}>
                          <Input
                            value={editMode ? row.value : toDecimal(row.value, 2)}
                            onChange={(e) => changeWeightListData(e, row.no)}
                            onBlur={(e) => changeWeightListDataOnBlur(e, row.no)}
                            onPressEnter={(e) => changeWeightListDataOnBlur(e, row.no)}
                            onKeyPress={(e) => {
                              handleKeyPress(e, false);
                            }}
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                          />
                        </td>
                      ))}
                  </tr>
                </Table>
              </Row>
            </div>
          </>
        )}
        {/* )} */} {/** IQX_WEBEST-226 */}
      </div>

      {/* 数量 */}
      {isSuuryou
        ? commonModal(
          isSuuryou,
          addSuuryouModalTitle,
          null,
          null,
          400,
          addData,
          closeAddModal,
          addSuuryouContent,
          null,
          null,
          true
        )
        : ''}

      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
          isUpdateData,
          confirmModalTitle,
          commonFooter(updateDataOk, updateDataCancel),
          null,
          400,
          updateDataOk,
          closeAddModal,
          confirmUpdateContent,
          null,
          null,
          false
        )
        : ''}

      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
          isDiscardData,
          confirmModalTitle,
          commonFooter(discardOk, discardCancel),
          null,
          400,
          discardOk,
          discardCancel,
          confirmDiscardContent,
          null,
          null,
          false
        )
        : ''}

      {/* 重量 */}
      {isWeight
        ? commonModal(
          isWeight,
          addWeightModalTitle,
          null,
          null,
          400,
          addData,
          closeAddModal,
          addWeightContent,
          null,
          null,
          true
        )
        : ''}
    </>
  );
});

export default SetupSetting;
