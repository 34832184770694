/**
 * クラス名：形鋼の加工設定
 * 作成者：ナンス
 * 作成日：2024/10/23
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Row, Col, Input, Space, Button, Popover } from 'antd';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  handleKeyPress,
  updateList,
  sortListByMasterData,
  getInputBorderStyle,
  regexExp,
  toHalfWidth,
  isDigitZenkaku,
  isDigit,
  toDecimal,
  defaultWeightData,
  deleteListColData,
  updateListColData,
  sortListData,
} from '../../../common/Common';
import Quantity from '../../parent/devices/Quantity';
import { WorkType } from '../../../common/enums';
import {
  getIQ5CoeffDetailValues,
  getMaterialTypeIq5Nm,
  getProcessingShapeCoeffNm,
  getWeightDetailNm,
} from '../../../common/CommonIQ5';
import { timeWeightDefaultValue } from '../../../common/Constant';

const IQ5_Device_ProcessTime = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [isSize, setIsSize] = useState(false);
  const [isProcessTimeWeight, setIsProcessTimeWeight] = useState(false);
  const [isProcessTimeSize, setIsProcessTimeSize] = useState(false);
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [singleProcessTimeSec, setSingleProcessTimeSec] = useState([]);
  const [isSetupSetting, setIsSetupSetting] = useState(false);
  const [kgProcessingTimeThMaster, setKgProcessingTimeThMaster] = useState([]);
  const [kgProcessingTimeThItems, setKgProcessingTimeThItems] = useState([]);
  const [sizeProcessingTimeThMaster, setSizeProcessingTimeThMaster] = useState([]);
  const [sizeProcessingTimeThItems, setSizeProcessingTimeThItems] = useState([]);
  const [processingShapeCoeff, setProcessingShapeCoeff] = useState([]);
  const [machiningMaterialCoeff, setMachiningMaterialCoeff] = useState([]);
  const [t1SizeCoeffThMaster, setT1SizeCoeffThMaster] = useState([]);
  const [t1SizeCoeffThItems, setT1SizeCoeffThItems] = useState([]);
  const [aSizeCoeffThMaster, setASizeCoeffThMaster] = useState([]);
  const [aSizeCoeffThItems, setASizeCoeffThItems] = useState([]);
  const [bSizeCoeffThMaster, setBSizeCoeffThMaster] = useState([]);
  const [bSizeCoeffThItems, setBSizeCoeffThItems] = useState([]);
  const [cSizeCoeffThMaster, setCSizeCoeffThMaster] = useState([]);
  const [cSizeCoeffThItems, setCSizeCoeffThItems] = useState([]);
  const [processingWeightCoeffThMaster, setProcessingWeightCoeffThMaster] = useState([]);
  const [processingWeightCoeffThItems, setProcessingWeightCoeffThItems] = useState([]);
  const [weightErrIndex, setWeightErrIndex] = useState([]);
  const [weightErrorMessage, setWeightErrorMessage] = useState('');
  const [weightMstDataTemp, setWeightMstDataTemp] = useState([]);
  const [weightMstData, setWeightMstData] = useState([]);
  const [weightList, setWeightList] = useState([]);
  const [sizeErrIndex, setSizeErrIndex] = useState([]);
  const [sizeErrorMessage, setSizeErrorMessage] = useState('');
  const [sizeMstDataTemp, setSizeMstDataTemp] = useState([]);
  const [sizeMstData, setSizeMstData] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [sizeType, setSizeType] = useState('t1');
  // 面積加工時間
  const [areaProcessingTimeThMaster, setAreaProcessingTimeThMaster] = useState([]);
  const [areaProcessingTimeThItems, setAreaProcessingTimeThItems] = useState([]);
  // 仮付け加工時間
  const [tempInstProcessTimeSec, setTempInstProcessTimeSec] = useState([]);
  // 加工速度
  const [processingSpeedThMaster, setProcessingSpeedThMaster] = useState([]);
  const [processingSpeedThItems, setProcessingSpeedThItems] = useState([]);
  // 切断
  const [disposal, setDisposal] = useState(0); // 捨代(mm)
  const [bladeWidth, setBladeWidth] = useState(0); // 捨代(mm)
  const [singleProcessTimeSecDec, setSingleProcessTimeSecDec] = useState(0); // 捨代(mm)
  const [processingSpeedMinThMaster, setProcessingSpeedMinThMaster] = useState([]);
  const [processingSpeedMinThItems, setProcessingSpeedMinThItems] = useState([]);
  //曲げRサイズ
  const [rSizeCoeffThMaster, setRSizeCoeffThMaster] = useState([]);
  const [rSizeCoeffThItems, setRSizeCoeffThItems] = useState([]);

  const inputWeightRef = useRef([]);
  const inputSizeRef = useRef([]);

  useEffect(() => {
    setErrIndexVal([]);
    setEditMode(props.editMode);
    if (props?.detailsData?.kgProcessingTimeThMaster) {
      setKgProcessingTimeThMaster(props?.detailsData?.kgProcessingTimeThMaster);
    }
    if (props?.detailsData?.kgProcessingTimeThItems) {
      setKgProcessingTimeThItems(props?.detailsData?.kgProcessingTimeThItems);
    }
    if (props?.detailsData?.areaProcessingTimeThMaster) {
      setAreaProcessingTimeThMaster(props?.detailsData?.areaProcessingTimeThMaster);
    }
    if (props?.detailsData?.areaProcessingTimeThItems) {
      setAreaProcessingTimeThItems(props?.detailsData?.areaProcessingTimeThItems);
    }
    if (props?.detailsData?.sizeProcessingTimeThMaster) {
      setSizeProcessingTimeThMaster(props?.detailsData?.sizeProcessingTimeThMaster);
    }
    if (props?.detailsData?.sizeProcessingTimeThItems) {
      setSizeProcessingTimeThItems(props?.detailsData?.sizeProcessingTimeThItems);
    }
    if (props?.detailsData?.singleProcessTimeSec && props?.iq5WorkType !== WorkType.PaSteelCut)
      setSingleProcessTimeSec(props?.detailsData?.singleProcessTimeSec);
    if (props?.detailsData?.tempInstProcessTimeSec)
      setTempInstProcessTimeSec(props?.detailsData?.tempInstProcessTimeSec);
    if (props?.detailsData?.processingShapeCoeff) setProcessingShapeCoeff(props?.detailsData?.processingShapeCoeff);
    if (props?.detailsData?.machiningMaterialCoeff)
      setMachiningMaterialCoeff(props?.detailsData?.machiningMaterialCoeff);
    if (props?.detailsData?.t1SizeCoeffThMaster) setT1SizeCoeffThMaster(props?.detailsData?.t1SizeCoeffThMaster);
    if (props?.detailsData?.t1SizeCoeffThItems) setT1SizeCoeffThItems(props?.detailsData?.t1SizeCoeffThItems);
    if (props?.detailsData?.aSizeCoeffThMaster) setASizeCoeffThMaster(props?.detailsData?.aSizeCoeffThMaster);
    if (props?.detailsData?.aSizeCoeffThItems) setASizeCoeffThItems(props?.detailsData?.aSizeCoeffThItems);
    if (props?.detailsData?.bSizeCoeffThMaster) setBSizeCoeffThMaster(props?.detailsData?.bSizeCoeffThMaster);
    if (props?.detailsData?.bSizeCoeffThItems) setBSizeCoeffThItems(props?.detailsData?.bSizeCoeffThItems);
    if (props?.detailsData?.cSizeCoeffThMaster) setCSizeCoeffThMaster(props?.detailsData?.cSizeCoeffThMaster);
    if (props?.detailsData?.cSizeCoeffThItems) setCSizeCoeffThItems(props?.detailsData?.cSizeCoeffThItems);
    if (props?.detailsData?.rSizeCoeffThMaster) setRSizeCoeffThMaster(props?.detailsData?.rSizeCoeffThMaster);
    if (props?.detailsData?.rSizeCoeffThItems) setRSizeCoeffThItems(props?.detailsData?.rSizeCoeffThItems);
    if (props?.detailsData?.processingWeightCoeffThMaster)
      setProcessingWeightCoeffThMaster(props?.detailsData?.processingWeightCoeffThMaster);
    if (props?.detailsData?.processingWeightCoeffThItems)
      setProcessingWeightCoeffThItems(props?.detailsData?.processingWeightCoeffThItems);
    if (props?.detailsData?.processingWeightCoeffThMaster)
      setWeightMstData(props?.detailsData?.processingWeightCoeffThMaster);
    if (props?.detailsData?.processingWeightCoeffThMaster?.length > 0) {
      setWeightMstDataTemp(props?.detailsData?.processingWeightCoeffThMaster);
    } else {
      setWeightMstDataTemp(defaultWeightData);
    }
    if (props?.detailsData?.processingWeightCoeffThItems)
      setWeightList(props?.detailsData?.processingWeightCoeffThItems);
    if (props?.detailsData?.processingSpeedThMaster)
      setProcessingSpeedThMaster(props?.detailsData?.processingSpeedThMaster);
    if (props?.detailsData?.processingSpeedThItems)
      setProcessingSpeedThItems(props?.detailsData?.processingSpeedThItems);
    setDisposal(props?.detailsData?.disposal);
    setBladeWidth(props?.detailsData?.bladeWidth);
    if (props?.iq5WorkType === WorkType.PaSteelCut)
      setSingleProcessTimeSecDec(props?.detailsData?.singleProcessTimeSec);
    if (props?.detailsData?.processingSpeedMinThMaster)
      setProcessingSpeedMinThMaster(props?.detailsData?.processingSpeedMinThMaster);
    if (props?.detailsData?.processingSpeedMinThItems)
      setProcessingSpeedMinThItems(props?.detailsData?.processingSpeedMinThItems);
  }, [props.editMode, props.detailsData, props.materialTypes]);

  useEffect(() => {
    setIsSetupSetting(props?.isIq5ProcessSetting);
  }, [props?.isIq5ProcessSetting]);

  // 加工時間の重量ボタン押下
  const processTimeWeightModal = () => {
    setErrIndexVal([]);
    setIsProcessTimeWeight(true);
    if (props?.iq5WorkType === WorkType.PaSteelWeight) {
      if (props?.detailsData?.kgProcessingTimeThMaster) setWeightMstData(props?.detailsData?.kgProcessingTimeThMaster);
      if (props?.detailsData?.kgProcessingTimeThMaster?.length > 0) {
        setWeightMstDataTemp(props?.detailsData?.kgProcessingTimeThMaster);
      } else {
        setWeightMstDataTemp(defaultWeightData);
      }
      if (props?.detailsData?.kgProcessingTimeThItems) setWeightList(props?.detailsData?.kgProcessingTimeThItems);
    } else if (props?.iq5WorkType === WorkType.PaSteelArea) {
      if (props?.detailsData?.areaProcessingTimeThMaster)
        setWeightMstData(props?.detailsData?.areaProcessingTimeThMaster);
      if (props?.detailsData?.areaProcessingTimeThMaster?.length > 0) {
        setWeightMstDataTemp(props?.detailsData?.areaProcessingTimeThMaster);
      } else {
        setWeightMstDataTemp(defaultWeightData);
      }
      if (props?.detailsData?.areaProcessingTimeThItems) setWeightList(props?.detailsData?.areaProcessingTimeThItems);
    } else if (
      props?.iq5WorkType === WorkType.PaSteelWeldFinish ||
      props?.iq5WorkType === WorkType.PaSteelWeld ||
      props?.iq5WorkType === WorkType.PaSteelDebbur ||
      props?.iq5WorkType === WorkType.PaSteelGroove ||
      props?.iq5WorkType === WorkType.PaSteelBend ||
      props?.iq5WorkType === WorkType.PaSteelNotch ||
      props?.iq5WorkType === WorkType.PaSteelMount ||
      props?.iq5WorkType === WorkType.PaSteelHole
    ) {
      if (props?.detailsData?.processingSpeedThMaster) setWeightMstData(props?.detailsData?.processingSpeedThMaster);
      if (props?.detailsData?.processingSpeedThMaster?.length > 0) {
        setWeightMstDataTemp(props?.detailsData?.processingSpeedThMaster);
      } else {
        setWeightMstDataTemp(defaultWeightData);
      }
      if (props?.detailsData?.processingSpeedThItems) setWeightList(props?.detailsData?.processingSpeedThItems);
    }
  };

  // 加工時間のサイズボタン押下
  const processTimeSizeModal = () => {
    setErrIndexVal([]);
    setIsProcessTimeSize(true);
    if (props?.detailsData?.sizeProcessingTimeThMaster)
      setWeightMstData(props?.detailsData?.sizeProcessingTimeThMaster);
    if (props?.detailsData?.sizeProcessingTimeThMaster?.length > 0) {
      setWeightMstDataTemp(props?.detailsData?.sizeProcessingTimeThMaster);
    } else {
      setWeightMstDataTemp(defaultWeightData);
    }
    if (props?.detailsData?.sizeProcessingTimeThItems) setWeightList(props?.detailsData?.sizeProcessingTimeThItems);
  };

  // 重量ボタン押下
  const addWeight = () => {
    setErrIndexVal([]);
    setIsWeight(true);
  };

  // サイズボタン押下
  const addSize = (type) => {
    setSizeType(type);
    if (type === 't1') {
      setSizeMstDataTemp(t1SizeCoeffThMaster);
      setSizeMstData(t1SizeCoeffThMaster);
      setSizeList(t1SizeCoeffThItems);
    } else if (type === 'a') {
      setSizeMstDataTemp(aSizeCoeffThMaster);
      setSizeMstData(aSizeCoeffThMaster);
      setSizeList(aSizeCoeffThItems);
    } else if (type === 'b') {
      setSizeMstDataTemp(bSizeCoeffThMaster);
      setSizeMstData(bSizeCoeffThMaster);
      setSizeList(bSizeCoeffThItems);
    } else if (type === 'c') {
      setSizeMstDataTemp(cSizeCoeffThMaster);
      setSizeMstData(cSizeCoeffThMaster);
      setSizeList(cSizeCoeffThItems);
    } else if (type === 'speed') {
      setSizeMstDataTemp(processingSpeedMinThMaster);
      setSizeMstData(processingSpeedMinThMaster);
      setSizeList(processingSpeedMinThItems);
    } else if (type === 'r') {
      setSizeMstDataTemp(rSizeCoeffThMaster);
      setSizeMstData(rSizeCoeffThMaster);
      setSizeList(rSizeCoeffThItems);
    }
    setErrIndexVal([]);

    setIsSize(true);
  };

  // 重量マスタ一覧の値を入力された場合、
  const changeWeightMstData = (event, no, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = weightMstDataTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setWeightMstDataTemp(updatedData);
  };

  // 重量マスタ一覧の入力値をチェック
  const isWeightValueOrderCorrect = (e, no, field) => {
    const temp = weightMstDataTemp?.filter((i) => i.isOutOfRange === false);
    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      changeWeightMstData(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setWeightErrIndex([...errIndexVal, insertIndex]);
      setWeightErrorMessage('数字値を入力してください！');
      return;
    }

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setWeightErrIndex(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setWeightErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setWeightErrIndex([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setWeightErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setWeightErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setWeightErrIndex(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setWeightErrIndex([...errIndexVal, insertIndex]);
        setWeightErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };

  // 加工時間の重量の追加ボタン押下
  const addProcessTimeWeightRow = (no, index) => {
    inputWeightRef.current[index].focus();
    const copyData = weightMstDataTemp.slice(); // Create a copy of the original array
    const insertIndex = weightMstDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2
    if (insertIndex !== -1) {
      const data = {
        no: weightMstDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setWeightMstDataTemp(copyData);
    }
  };

  // 重量マスタ一覧の追加ボタン押下
  const addWeightRow = (no, index) => {
    inputWeightRef.current[index].focus();
    const copyData = weightMstDataTemp.slice(); // Create a copy of the original array
    const insertIndex = weightMstDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2
    if (insertIndex !== -1) {
      const data = {
        no: weightMstDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setWeightMstDataTemp(copyData);
    }
  };

  // 加工時間の重量の削除ボタン押下
  const deleteProcessTimeWeightRow = (id, e, index) => {
    // 更新するエラーインデックスを取得
    const updatedErrorIndexes = weightErrIndex?.filter((item) => item !== index);
    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setWeightErrIndex(finalErrorIndexes);
    if (weightMstDataTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = weightMstDataTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setWeightMstDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputWeightRef.current[index].focus();
      }
    }
  };

  // 重量マスタ一覧の削除ボタン押下
  const deleteWeight = (no, e, index) => {
    // 更新するエラーインデックスを取得
    const updatedErrorIndexes = weightErrIndex?.filter((item) => item !== index);
    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setWeightErrIndex(finalErrorIndexes);
    if (weightMstDataTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = weightMstDataTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setWeightMstDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputWeightRef.current[index].focus();
      }
    }
  };

  // 重量マスタ一覧のCancelボタン押下
  const cancelWeightMaster = () => {
    setWeightMstDataTemp(weightMstData);
    setIsWeight(false);
    setIsProcessTimeWeight(false);
    setIsProcessTimeSize(false);
  };

  // 加工速度のデフォルト値を設定する
  const setDefaultValueTimeWeightMaster = (list, ids, defaultValue) => {
    // リスト内の各キーを反復処理する
    Object.keys(list).forEach((key) => {
      const items = list[key];

      // 項目が配列の場合のみ続行する
      if (Array.isArray(items)) {
        // 各IDについて、検索して更新する
        ids.forEach((id) => {
          // 指定された ID を持つデータを検索します
          const item = items.find((item) => item.no === id);

          if (item) {
            // アイテムが存在する場合は、その値をデフォルト値に設定します。
            item.value = defaultValue;
          }
        });
      }
    });
    // デフォルト値を設定したリストを返却する
    return list;
  };

  // 加工速度の場合、工程種別よりデフォルト値を取得する
  const getUpdateValue = (workType) => {
    // 工程種別が穴又は取付の場合
    if (workType === WorkType.PaSteelHole || workType === WorkType.PaSteelMount) {
      return timeWeightDefaultValue.thirtyValueWeight;
    }
    // 工程種別が切欠又は開先の場合
    else if (workType === WorkType.PaSteelGroove || workType === WorkType.PaSteelNotch) {
      return timeWeightDefaultValue.tenValueWeight;
    }
    // 工程種別が切断の場合
    else if (workType === WorkType.PaSteelCut) {
      return timeWeightDefaultValue.sixtyValueWeight;
    }
    // 工程種別が表面積又は重量の場合
    else if (workType === WorkType.PaSteelWeight || workType === WorkType.PaSteelArea) {
      return timeWeightDefaultValue.sixtyValueTime;
    }
    // 工程種別が曲げ又はバリ取り又は溶接又は溶接仕上の場合
    else {
      return timeWeightDefaultValue.hundredValueWeight;
    }
  };

  // 加工時間の場合、工程種別よりデフォルト値を取得する
  const getUpdateValueTime = (workType) => {
    // 工程種別が穴の場合
    if (workType === WorkType.PaSteelHole) {
      return timeWeightDefaultValue.hundredValueTime;
    }
    // 工程種別が取付又は表面積又は重量の場合
    else {
      return timeWeightDefaultValue.sixtyValueTime;
    }
  };

  // 加工時間の重量・面積のOKボタン押下
  const okProcessTimeWeightMaster = () => {
    const deletedId = [];
    weightMstData?.forEach((item1, index) => {
      const found = weightMstDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });
    const newId = weightMstDataTemp
      ?.filter((item) => !item.isOutOfRange && !weightMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedList = updateList(weightList, deletedId, newId);
    // 工程種別よりデフォルト値を取得する
    const updateValue = getUpdateValue(props?.iq5WorkType);
    // 加工速度のデフォルト値を設定する
    const updatedDefaultValueList = setDefaultValueTimeWeightMaster(updatedList, newId, updateValue);
    const sortedList = sortListByMasterData(updatedDefaultValueList, weightMstDataTemp);
    setWeightMstData(weightMstDataTemp);
    if (props?.iq5WorkType === WorkType.PaSteelWeight) {
      props.updatedKgProcessingTimeThMaster(weightMstDataTemp);
      props.updatedKgProcessingTimeThItems(sortedList);
    } else if (props?.iq5WorkType === WorkType.PaSteelArea) {
      props.updatedAreaProcessingTimeThMaster(weightMstDataTemp);
      props.updatedAreaProcessingTimeThItems(sortedList);
    } else if (
      props?.iq5WorkType === WorkType.PaSteelWeldFinish ||
      props?.iq5WorkType === WorkType.PaSteelWeld ||
      props?.iq5WorkType === WorkType.PaSteelDebbur ||
      props?.iq5WorkType === WorkType.PaSteelGroove ||
      props?.iq5WorkType === WorkType.PaSteelBend ||
      props?.iq5WorkType === WorkType.PaSteelNotch ||
      props?.iq5WorkType === WorkType.PaSteelMount ||
      props?.iq5WorkType === WorkType.PaSteelHole
    ) {
      props.updatedProcessingSpeedThMaster(weightMstDataTemp);
      props.updatedProcessingSpeedThItems(sortedList);
    }
    setWeightList(sortedList);
    setIsProcessTimeWeight(false);
  };

  // 加工時間のサイズのOKボタン押下
  const okProcessTimeSizeMaster = () => {
    const deletedId = [];
    weightMstData?.forEach((item1, index) => {
      const found = weightMstDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });
    const newId = weightMstDataTemp
      ?.filter((item) => !item.isOutOfRange && !weightMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedList = updateList(weightList, deletedId, newId);
    // 工程種別よりデフォルト値を取得する
    const updateValue = getUpdateValueTime(props?.iq5WorkType);
    // 加工速度のデフォルト値を設定する
    const updatedDefaultValueList = setDefaultValueTimeWeightMaster(updatedList, newId, updateValue);
    const sortedList = sortListByMasterData(updatedDefaultValueList, weightMstDataTemp);
    setWeightMstData(weightMstDataTemp);
    props.updatedSizeProcessingTimeThMaster(weightMstDataTemp);
    props.updatedSizeProcessingTimeThItems(sortedList);

    setWeightList(sortedList);
    setIsProcessTimeSize(false);
  };

  // 重量マスタ一覧のOKボタン押下
  const okWeightMaster = () => {
    const deletedId = [];
    weightMstData?.forEach((item1, index) => {
      const found = weightMstDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });
    const newId = weightMstDataTemp
      ?.filter((item) => !item.isOutOfRange && !weightMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);
    const deletedData = deleteListColData(weightList, deletedId);
    const updatedData = updateListColData(deletedData, newId);
    const sortedData = sortListData(updatedData, weightMstDataTemp);
    setIsWeight(false);
    setWeightMstData(weightMstDataTemp);
    props.updatedProcessingWeightCoeffThMaster(weightMstDataTemp);
    props.updatedProcessingWeightCoeffThItems(sortedData);
    setWeightList(sortedData);
  };

  // サイズマスタ一覧の値を入力された場合、
  const changeSizeMstData = (event, no, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = sizeMstDataTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setSizeMstDataTemp(updatedData);
  };

  const isSizeValueOrderCorrect = (e, no, field) => {
    const temp = sizeMstDataTemp?.filter((i) => i.isOutOfRange === false);
    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      changeSizeMstData(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setSizeErrIndex([...errIndexVal, insertIndex]);
      setSizeErrorMessage('数字値を入力してください！');
      return;
    }

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setSizeErrIndex(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setSizeErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setSizeErrIndex([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setSizeErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setSizeErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setSizeErrIndex(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setSizeErrIndex([...errIndexVal, insertIndex]);
        setSizeErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };

  // サイズマスタ一覧の追加ボタン押下
  const addSizeRow = (no, index) => {
    inputSizeRef.current[index].focus();
    const copyData = sizeMstDataTemp.slice(); // Create a copy of the original array

    const insertIndex = sizeMstDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: sizeMstDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSizeMstDataTemp(copyData);
    }
  };

  // サイズマスタ一覧の削除ボタン押下
  const deleteSize = (no, e, index) => {
    // 更新するエラーインデックスを取得
    const updatedErrorIndexes = sizeErrIndex?.filter((item) => item !== index);
    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setSizeErrIndex(finalErrorIndexes);
    if (sizeMstDataTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = sizeMstDataTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setSizeMstDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputSizeRef.current[index].focus();
      }
    }
  };

  // サイズマスタ一覧のCancelボタン押下
  const cancelSizeMaster = () => {
    setSizeMstDataTemp(sizeMstData);
    setIsSize(false);
  };

  // サイズマスタ一覧のOKボタン押下
  const okSizeMaster = () => {
    const deletedId = [];
    sizeMstData?.forEach((item1, index) => {
      const found = sizeMstDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });
    const newId = sizeMstDataTemp
      ?.filter((item) => !item.isOutOfRange && !sizeMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);
    const deletedData = deleteListColData(sizeList, deletedId);
    let updatedData = updateListColData(deletedData, newId);
    if (sizeType === 'speed') {
      // 工程種別よりデフォルト値を取得する
      const updateValue = getUpdateValueTime(props?.iq5WorkType);
      // 加工速度のデフォルト値を設定する
      updatedData = setDefaultValueTimeWeightMaster(updatedData, newId, updateValue);
    }
    const sortedData = sortListData(updatedData, sizeMstDataTemp);
    setIsSize(false);
    setSizeMstData(sizeMstDataTemp);
    if (sizeType === 't1') {
      props.updatedT1SizeCoeffThMaster(sizeMstDataTemp);
      props.updatedT1SizeCoeffThItems(sortedData);
    } else if (sizeType === 'a') {
      props.updatedASizeCoeffThMaster(sizeMstDataTemp);
      props.updatedASizeCoeffThItems(sortedData);
    } else if (sizeType === 'b') {
      props.updatedBSizeCoeffThMaster(sizeMstDataTemp);
      props.updatedBSizeCoeffThItems(sortedData);
    } else if (sizeType === 'c') {
      props.updatedCSizeCoeffThMaster(sizeMstDataTemp);
      props.updatedCSizeCoeffThItems(sortedData);
    } else if (sizeType === 'speed') {
      props.updatedProcessingSpeedMinThMaster(sizeMstDataTemp);
      props.updatedProcessingSpeedMinThItems(sortedData);
    } else if (sizeType === 'r') {
      props.updatedRSizeCoeffThMaster(sizeMstDataTemp);
      props.updatedRSizeCoeffThItems(sortedData);
    }
    setWeightList(sortedData);
  };

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isWeight === true) {
      setIsWeight(false);
    } else if (isProcessTimeWeight === true) {
      setIsProcessTimeWeight(false);
    } else if (isSize === true) {
      setIsSize(false);
    } else if (isProcessTimeSize === true) {
      setIsProcessTimeSize(false);
    }
  };

  const setupSetting = () => {
    if (isSetupSetting == true) {
      setIsSetupSetting(false);
    } else {
      setIsSetupSetting(true);
    }
  };

  // 形鋼形状区分係数の変更イベント処理
  const changeProcessingShapeCoeff = (itemId, value) => {
    Object.entries(processingShapeCoeff)?.map(([key]) => {
      if (parseInt(key) === parseInt(itemId)) {
        processingShapeCoeff[key] = value;
      }
    });
    setProcessingShapeCoeff(processingShapeCoeff);
    props.updatedProcessingShapeCoeff(processingShapeCoeff);
  };

  // 形鋼材質区分係数の変更イベント処理
  const changeMachiningMaterialCoeff = (itemId, value) => {
    Object.entries(machiningMaterialCoeff)?.map(([key]) => {
      if (parseInt(key) === parseInt(itemId)) {
        machiningMaterialCoeff[key] = value;
      }
    });
    setMachiningMaterialCoeff(machiningMaterialCoeff);
    props.updatedMachiningMaterialCoeff(machiningMaterialCoeff);
  };

  // 一回加工時間(sec)の変更イベント処理
  const changeSingleProcessTimeSec = (itemId, value) => {
    Object.entries(singleProcessTimeSec)?.map(([key]) => {
      if (parseInt(key) === parseInt(itemId)) {
        singleProcessTimeSec[key] = value;
      }
    });
    setSingleProcessTimeSec(singleProcessTimeSec);
    props.updatedSingleProcessTimeSec(singleProcessTimeSec);
  };

  // 仮付け加工時間(sec)の変更イベント処理
  const changeTempInstProcessTimeSec = (itemId, value) => {
    Object.entries(tempInstProcessTimeSec)?.map(([key]) => {
      if (parseInt(key) === parseInt(itemId)) {
        tempInstProcessTimeSec[key] = value;
      }
    });
    setTempInstProcessTimeSec(tempInstProcessTimeSec);
    props.updatedTempInstProcessTimeSec(tempInstProcessTimeSec);
  };

  const changeKgProcessingTimeThItems = (event, key, no, isCheckZenkaku = true) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    if (isCheckZenkaku && regexExp.test(event)) {
      // 全角の場合、
      event = toHalfWidth(event); // 半角へ変換
      if (!isDigit(event)) return;
    }
    let newList = { ...kgProcessingTimeThItems };
    if (props?.iq5WorkType === WorkType.PaSteelArea) {
      newList = { ...areaProcessingTimeThItems };
    } else if (
      props?.iq5WorkType === WorkType.PaSteelWeldFinish ||
      props?.iq5WorkType === WorkType.PaSteelWeld ||
      props?.iq5WorkType === WorkType.PaSteelDebbur ||
      props?.iq5WorkType === WorkType.PaSteelGroove ||
      props?.iq5WorkType === WorkType.PaSteelBend ||
      props?.iq5WorkType === WorkType.PaSteelNotch ||
      props?.iq5WorkType === WorkType.PaSteelMount ||
      props?.iq5WorkType === WorkType.PaSteelHole
    ) {
      newList = { ...processingSpeedThItems };
    } else if (props?.iq5WorkType === WorkType.PaSteelM) {
      newList = { ...sizeProcessingTimeThItems };
    }
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);
    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event;
      if (props?.iq5WorkType === WorkType.PaSteelWeight) {
        setKgProcessingTimeThItems(newList);
        props.updateKgProcessingTimeThItems(newList);
      } else if (props?.iq5WorkType === WorkType.PaSteelArea) {
        setAreaProcessingTimeThItems(newList);
        props.updateAreaProcessingTimeThItems(newList);
      } else if (
        props?.iq5WorkType === WorkType.PaSteelWeldFinish ||
        props?.iq5WorkType === WorkType.PaSteelWeld ||
        props?.iq5WorkType === WorkType.PaSteelDebbur ||
        props?.iq5WorkType === WorkType.PaSteelGroove ||
        props?.iq5WorkType === WorkType.PaSteelBend ||
        props?.iq5WorkType === WorkType.PaSteelNotch ||
        props?.iq5WorkType === WorkType.PaSteelMount ||
        props?.iq5WorkType === WorkType.PaSteelHole
      ) {
        setProcessingSpeedThItems(newList);
        props.updateProcessingSpeedThItems(newList);
      }
    }
  };

  const changeSizeProcessingTimeThItems = (event, key, no, isCheckZenkaku = true) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    if (isCheckZenkaku && regexExp.test(event)) {
      // 全角の場合、
      event = toHalfWidth(event); // 半角へ変換
      if (!isDigit(event)) return;
    }
    let newList = { ...sizeProcessingTimeThItems };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);
    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event;
      setSizeProcessingTimeThItems(newList);
      props.updateSizeProcessingTimeThItems(newList);
    }
  };

  const changeCoeffListData = (event, no, itemNm) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    if (itemNm === 't1') {
      t1SizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setT1SizeCoeffThItems(t1SizeCoeffThItems);
      props.updatedT1SizeCoeffThItems(t1SizeCoeffThItems);
    } else if (itemNm === 'a') {
      aSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setASizeCoeffThItems(aSizeCoeffThItems);
      props.updatedASizeCoeffThItems(aSizeCoeffThItems);
    } else if (itemNm === 'b') {
      bSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setBSizeCoeffThItems(bSizeCoeffThItems);
      props.updatedBSizeCoeffThItems(bSizeCoeffThItems);
    } else if (itemNm === 'c') {
      cSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setCSizeCoeffThItems(cSizeCoeffThItems);
      props.updatedCSizeCoeffThItems(cSizeCoeffThItems);
    } else if (itemNm === 'kg') {
      processingWeightCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setProcessingWeightCoeffThItems(processingWeightCoeffThItems);
      props.updatedProcessingWeightCoeffThItems(processingWeightCoeffThItems);
    } else if (itemNm === 'speed') {
      processingSpeedMinThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setProcessingSpeedMinThItems(processingSpeedMinThItems);
      props.updatedProcessingSpeedMinThItems(processingSpeedMinThItems);
    } else if (itemNm === 'r') {
      rSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setRSizeCoeffThItems(rSizeCoeffThItems);
      props.updatedRSizeCoeffThItems(rSizeCoeffThItems);
    }
  };

  // 係数リストの値を'0'入力された場合、
  const changeCoeffListDataOnBlur = (event, no, itemNm) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    if (itemNm === 't1') {
      t1SizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setT1SizeCoeffThItems(t1SizeCoeffThItems);
      props.updatedT1SizeCoeffThItems(t1SizeCoeffThItems);
    } else if (itemNm === 'a') {
      aSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setASizeCoeffThItems(aSizeCoeffThItems);
      props.updatedASizeCoeffThItems(aSizeCoeffThItems);
    } else if (itemNm === 'b') {
      bSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setBSizeCoeffThItems(bSizeCoeffThItems);
      props.updatedBSizeCoeffThItems(bSizeCoeffThItems);
    } else if (itemNm === 'c') {
      cSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setCSizeCoeffThItems(cSizeCoeffThItems);
      props.updatedCSizeCoeffThItems(cSizeCoeffThItems);
    } else if (itemNm === 'kg') {
      processingWeightCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setProcessingWeightCoeffThItems(processingWeightCoeffThItems);
      props.updatedProcessingWeightCoeffThItems(processingWeightCoeffThItems);
    } else if (itemNm === 'speed') {
      processingSpeedMinThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setProcessingSpeedMinThItems(processingSpeedMinThItems);
      props.updatedProcessingSpeedMinThItems(processingSpeedMinThItems);
    } else if (itemNm === 'r') {
      rSizeCoeffThItems
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          let eventVal = regexExp.test(event.target.value) ? toHalfWidth(event.target.value) : event.target.value;
          list.value = parseFloat(eventVal) > 0 ? eventVal : 1;
        });
      setRSizeCoeffThItems(rSizeCoeffThItems);
      props.updatedRSizeCoeffThItems(rSizeCoeffThItems);
    }
  };

  // 重量マスタ一覧のヘッダー
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );

  // サイズマスタ一覧のヘッダー
  const addSizeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>サイズ</p>
    </div>
  );

  // 加工時間の重量マスタ一覧
  const addProcessTimeWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label> （≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {weightMstDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>

                      <td>
                        {weightErrIndex?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{weightErrorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          className={weightErrIndex?.includes(index) ? 'red-border' : ''}
                          onChange={(e) => changeWeightMstData(e, item.no, 'value')}
                          onBlur={(e) => isWeightValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isWeightValueOrderCorrect(e, item.no, 'value')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          style={{
                            ...getInputBorderStyle(index, weightMstDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputWeightRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addProcessTimeWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteProcessTimeWeightRow(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(weightErrIndex) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={isProcessTimeWeight ? okProcessTimeWeightMaster : okProcessTimeSizeMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 重量マスタ一覧
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label> （≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {weightMstDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>

                      <td>
                        {weightErrIndex?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{weightErrorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          className={weightErrIndex?.includes(index) ? 'red-border' : ''}
                          onChange={(e) => changeWeightMstData(e, item.no, 'value')}
                          onBlur={(e) => isWeightValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isWeightValueOrderCorrect(e, item.no, 'value')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          style={{
                            ...getInputBorderStyle(index, weightMstDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputWeightRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(weightErrIndex) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okWeightMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // サイズマスタ一覧
  const addSizeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label> （≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sizeMstDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>

                      <td>
                        {sizeErrIndex?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{sizeErrorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          className={sizeErrIndex?.includes(index) ? 'red-border' : ''}
                          onChange={(e) => changeSizeMstData(e, item.no, 'value')}
                          onBlur={(e) => isSizeValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isSizeValueOrderCorrect(e, item.no, 'value')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          style={{
                            ...getInputBorderStyle(index, sizeMstDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputSizeRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSizeRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSize(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(sizeErrIndex) ? 'mainButton' : 'disabledButton'} id="update" onClick={okSizeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelSizeMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  return (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={setupSetting}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工設定</label>
            {isSetupSetting ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSetupSetting ? 'block' : 'none' }} className="table-container">
        <div style={{ marginLeft: 10, marginRight: 10 }} className="table-container">
          {/* 一回加工時間(sec) タイトル */}
          {props?.iq5WorkType === WorkType.PaSteelWeight ? (
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>一回加工時間(sec)</label>
            </Row>
          ) : (
            ''
          )}
          {/* 1面加工時間(sec) タイトル */}
          {props?.iq5WorkType === WorkType.PaSteelArea ? (
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>1面加工時間(sec)</label>
            </Row>
          ) : (
            ''
          )}
          {/* 1箇所加工時間(sec) タイトル */}
          {props?.iq5WorkType === WorkType.PaSteelWeldFinish ||
          props?.iq5WorkType === WorkType.PaSteelWeld ||
          props?.iq5WorkType === WorkType.PaSteelDebbur ||
          props?.iq5WorkType === WorkType.PaSteelGroove ||
          props?.iq5WorkType === WorkType.PaSteelBend ||
          props?.iq5WorkType === WorkType.PaSteelNotch ? (
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>1箇所加工時間(sec)</label>
            </Row>
          ) : (
            ''
          )}
          {/* 一回加工時間(sec) 又は1面加工時間(sec) 又は1箇所加工時間(sec)  */}
          {props?.iq5WorkType !== WorkType.PaSteelMount &&
          props?.iq5WorkType !== WorkType.PaSteelHole &&
          props?.iq5WorkType !== WorkType.PaSteelCut ? (
            <Row className="table-container">
              {Object.entries(singleProcessTimeSec)?.map(([key, data], index) => (
                <Col key={index} style={{ width: '20%' }}>
                  <Table className="reset-spacing">
                    <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                      <tr style={{ textAlign: 'center' }}>
                        <th className="tbl-header">
                          {getWeightDetailNm(key, props?.processMstRecord?.details?.processDetailTypes)}
                        </th>
                      </tr>
                    </thead>
                    <tbody key={index}>
                      <tr style={{ textAlign: 'center', height: 35 }}>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          <Row align="middle">
                            {!editMode ? (
                              <>
                                <Col span={24}>
                                  <label>{getIQ5CoeffDetailValues(key, singleProcessTimeSec)}</label>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col span={24}>
                                  <Input
                                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                                    value={getIQ5CoeffDetailValues(key, singleProcessTimeSec)}
                                    onChange={(event) => changeSingleProcessTimeSec(key, event.target.value)}
                                  ></Input>
                                </Col>
                              </>
                            )}
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              ))}
            </Row>
          ) : (
            ''
          )}

          {/* 仮付け加工時間(sec) */}
          {props?.iq5WorkType === WorkType.PaSteelWeldFinish || props?.iq5WorkType === WorkType.PaSteelWeld ? (
            <>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>仮付け加工時間(sec)</label>
              </Row>
              <Row className="table-container">
                {Object.entries(tempInstProcessTimeSec)?.map(([key, data], index) => (
                  <Col key={index} style={{ width: '20%' }}>
                    <Table className="table-container">
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">
                            {getWeightDetailNm(key, props?.processMstRecord?.details?.processDetailTypes)}
                          </th>
                        </tr>
                      </thead>
                      <tbody key={index}>
                        <tr style={{ textAlign: 'center', height: 35 }}>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{getIQ5CoeffDetailValues(key, tempInstProcessTimeSec)}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={24}>
                                    <Input
                                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                                      value={getIQ5CoeffDetailValues(key, tempInstProcessTimeSec)}
                                      onChange={(event) => changeTempInstProcessTimeSec(key, event.target.value)}
                                    ></Input>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ))}
              </Row>{' '}
            </>
          ) : (
            ''
          )}

          {/* 取付加工時間(sec) / 穴サイズ加工時間(sec)*/}
          {props?.iq5WorkType === WorkType.PaSteelMount || props?.iq5WorkType === WorkType.PaSteelHole ? (
            <>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>
                  {props?.iq5WorkType === WorkType.PaSteelMount ? '取付サイズ加工時間(sec)' : '穴サイズ加工時間(sec)'}
                </label>
              </Row>
              <Table
                style={{
                  width:
                    sizeProcessingTimeThMaster?.filter((i) => i.isOutOfRange === false)?.length > 8
                      ? '100%'
                      : sizeProcessingTimeThMaster?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        <label>種類</label>
                        <label>/</label>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={processTimeSizeModal}
                              style={{ pointerEvents: 'auto' }}
                            >
                              サイズ
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {sizeProcessingTimeThMaster
                    ?.filter((subitem) => subitem.isOutOfRange === false)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value}
                      </td>
                    ))}
                </tr>

                {Object.keys(sizeProcessingTimeThItems)?.map((key) => (
                  <tr key={key} style={{ textAlign: 'center' }}>
                    <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                      {
                        props?.processMstRecord?.details?.processDetailTypes?.find((item) => item.id.toString() === key)
                          ?.name
                      }
                    </td>
                    {sizeProcessingTimeThItems[key]?.map((item, index) => (
                      <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                        <Input
                          value={item.value}
                          onChange={(e) => changeSizeProcessingTimeThItems(e.target.value, key, item.no, false)}
                          onBlur={(e) => changeSizeProcessingTimeThItems(e.target.value, key, item.no)}
                          onPressEnter={(e) => changeSizeProcessingTimeThItems(e.target.value, key, item.no)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </>
          ) : (
            ''
          )}

          {/* 重量(Kg)加工時間(sec) */}
          {props?.iq5WorkType === WorkType.PaSteelWeight ? (
            <>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>重量(Kg)加工時間(sec)</label>
              </Row>
              <Table
                style={{
                  width:
                    kgProcessingTimeThMaster?.filter((i) => i.isOutOfRange === false)?.length > 8
                      ? '100%'
                      : kgProcessingTimeThMaster?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        <label>種類</label>
                        <label>/</label>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={processTimeWeightModal}
                              style={{ pointerEvents: 'auto' }}
                            >
                              重量(kg)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>重量(kg)</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {kgProcessingTimeThMaster
                    ?.filter((subitem) => subitem.isOutOfRange === false)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value}kg
                      </td>
                    ))}
                </tr>

                {Object.keys(kgProcessingTimeThItems)?.map((key) => (
                  <tr key={key} style={{ textAlign: 'center' }}>
                    <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                      {
                        props?.processMstRecord?.details?.processDetailTypes?.find((item) => item.id.toString() === key)
                          ?.name
                      }
                    </td>
                    {kgProcessingTimeThItems[key]?.map((item, index) => (
                      <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                        <Input
                          value={item.value}
                          onChange={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no, false)}
                          onBlur={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no)}
                          onPressEnter={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </>
          ) : (
            ''
          )}

          {/* 面積(cm)加工時間(sec) */}
          {props?.iq5WorkType === WorkType.PaSteelArea ? (
            <>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>
                  面積(cm<sup>2</sup>)加工時間(sec)
                </label>
              </Row>
              <Table
                style={{
                  width:
                    areaProcessingTimeThMaster?.filter((i) => i.isOutOfRange === false)?.length > 8
                      ? '100%'
                      : areaProcessingTimeThMaster?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        <label>種類</label>
                        <label>/</label>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={processTimeWeightModal}
                              style={{ pointerEvents: 'auto' }}
                            >
                              面積(cm<sup>2</sup>)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>
                              面積(cm<sup>2</sup>)
                            </label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {areaProcessingTimeThMaster
                    ?.filter((subitem) => subitem.isOutOfRange === false)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value ? new Intl.NumberFormat('en-US').format(item.value) : item.value}cm
                        <sup className="tbl-header">2</sup>
                      </td>
                    ))}
                </tr>

                {Object.keys(areaProcessingTimeThItems)?.map((key) => (
                  <tr key={key} style={{ textAlign: 'center' }}>
                    <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                      {
                        props?.processMstRecord?.details?.processDetailTypes?.find((item) => item.id.toString() === key)
                          ?.name
                      }
                    </td>
                    {areaProcessingTimeThItems[key]?.map((item, index) => (
                      <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                        <Input
                          value={item.value}
                          onChange={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no, false)}
                          onBlur={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no)}
                          onPressEnter={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </>
          ) : (
            ''
          )}

          {/* 加工速度(mm/min) */}
          {props?.iq5WorkType === WorkType.PaSteelWeldFinish ||
          props?.iq5WorkType === WorkType.PaSteelWeld ||
          props?.iq5WorkType === WorkType.PaSteelDebbur ||
          props?.iq5WorkType === WorkType.PaSteelGroove ||
          props?.iq5WorkType === WorkType.PaSteelBend ||
          props?.iq5WorkType === WorkType.PaSteelNotch ||
          props?.iq5WorkType === WorkType.PaSteelMount ||
          props?.iq5WorkType === WorkType.PaSteelHole ? (
            <>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>加工速度(mm/min)</label>
              </Row>
              <Table
                style={{
                  width:
                    processingSpeedThMaster?.filter((i) => i.isOutOfRange === false)?.length > 8
                      ? '100%'
                      : processingSpeedThMaster?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        <label>種類</label>
                        <label>/</label>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={processTimeWeightModal}
                              style={{ pointerEvents: 'auto' }}
                            >
                              mm
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>mm</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {processingSpeedThMaster
                    ?.filter((subitem) => subitem.isOutOfRange === false)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value}mm
                      </td>
                    ))}
                </tr>

                {Object.keys(processingSpeedThItems)?.map((key) => (
                  <tr key={key} style={{ textAlign: 'center' }}>
                    <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                      {
                        props?.processMstRecord?.details?.processDetailTypes?.find((item) => item.id.toString() === key)
                          ?.name
                      }
                    </td>
                    {processingSpeedThItems[key]?.map((item, index) => (
                      <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                        <Input
                          value={item.value}
                          onChange={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no, false)}
                          onBlur={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no)}
                          onPressEnter={(e) => changeKgProcessingTimeThItems(e.target.value, key, item.no)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </>
          ) : (
            ''
          )}

          {/* 切断の加工時間部分  */}
          {props?.iq5WorkType === WorkType.PaSteelCut ? (
            <>
              {/* 捨代(mm)  */}
              <Row className="chargeAmount">
                <Table style={{ width: '20%' }}>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header" style={{ width: '20%' }}>
                        捨代(mm)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', height: 35, verticalAlign: 'middle' }}>
                      <td>
                        <Row style={{ textAlign: 'center' }} align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{disposal}</label>
                              </Col>
                            </>
                          ) : (
                            <Col span={24}>
                              <Input
                                style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                className={editMode ? 'input-editable ' : 'input-non-editable'}
                                value={disposal}
                                onChange={(e) => {
                                  if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                                  setDisposal(e.target.value);
                                  props?.updatedDisposal(e.target.value);
                                }}
                              ></Input>
                            </Col>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* 刃幅(mm) */}
                <Table style={{ width: '20%' }}>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header" style={{ width: '20%' }}>
                        刃幅(mm)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', height: 35, verticalAlign: 'middle' }}>
                      <td>
                        <Row style={{ textAlign: 'center' }} align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{bladeWidth}</label>
                              </Col>
                            </>
                          ) : (
                            <Col span={24}>
                              <Input
                                style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                className={editMode ? 'input-editable ' : 'input-non-editable'}
                                value={bladeWidth}
                                onChange={(e) => {
                                  if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                                  setBladeWidth(e.target.value);
                                  props?.updatedBladeWidth(e.target.value);
                                }}
                              ></Input>
                            </Col>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* 1カット加工時間(sec) */}
                <Table style={{ width: '20%' }}>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header" style={{ width: '20%' }}>
                        1カット加工時間(sec)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', height: 35, verticalAlign: 'middle' }}>
                      <td>
                        <Row style={{ textAlign: 'center' }} align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{singleProcessTimeSecDec}</label>
                              </Col>
                            </>
                          ) : (
                            <Col span={24}>
                              <Input
                                style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                className={editMode ? 'input-editable ' : 'input-non-editable'}
                                value={singleProcessTimeSecDec}
                                onChange={(e) => {
                                  if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                                  setSingleProcessTimeSecDec(e.target.value);
                                  props?.updatedSingleProcessTimeSecDec(e.target.value);
                                }}
                              ></Input>
                            </Col>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              {/* 加工速度(mm/min) */}
              <>
                <Row>
                  <label style={{ fontSize: 14, fontWeight: 400 }}>加工速度(mm/min)</label>
                </Row>
                {/* 加工速度(mm/min) */}
                <Row className="table-container">
                  <Table
                    style={{
                      width:
                        processingSpeedMinThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                          ? '100%'
                          : processingSpeedMinThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                    }}
                  >
                    <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                      <td style={{ width: '196px' }} className="tbl-header fixedCol">
                        <Row justify="center" className="tbl-header">
                          <Space>
                            {editMode ? (
                              <>
                                <Button
                                  className="cancleButton"
                                  onClick={(e) => addSize('speed')}
                                  style={{ pointerEvents: 'auto' }}
                                >
                                  サイズ(mm)
                                </Button>
                              </>
                            ) : (
                              <>
                                <label>サイズ(mm)</label>
                              </>
                            )}
                          </Space>
                        </Row>
                      </td>
                      {processingSpeedMinThMaster
                        ?.filter((subitem) => !subitem.isOutOfRange)
                        ?.map((row, index) => (
                          <td key={index} style={{ color: 'white', width: '98px' }}>
                            ～{row.value}mm
                          </td>
                        ))}
                    </tr>

                    <tr style={{ textAlign: 'center' }}>
                      <td style={{ width: '196px' }} className="fixedOneRowCol">
                        {'加工速度(min)'}
                      </td>
                      {processingSpeedMinThItems
                        ?.filter((i) => !i.isOutOfRange)
                        ?.map((row, index) => (
                          <td key={index} style={{ width: '98px' }}>
                            <Input
                              value={row.value}
                              onChange={(e) => changeCoeffListData(e, row.no, 'speed')}
                              onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 'speed')}
                              onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 'speed')}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable'}
                            />
                          </td>
                        ))}
                    </tr>
                  </Table>
                </Row>
              </>
            </>
          ) : (
            ''
          )}

          {/* 形状区分係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>形状区分係数</label>
            </Row>
            <div className="section-margin-bottom">
              <Row className="table-container">
                {Object.entries(processingShapeCoeff)?.map(([key, data], index) => (
                  <Col key={index} style={{ width: '20%' }}>
                    <Table className="reset-spacing">
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">{getProcessingShapeCoeffNm(key, props?.steelShapeData)}</th>
                        </tr>
                      </thead>
                      <tbody key={index}>
                        <tr style={{ textAlign: 'center', height: 35 }}>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{getIQ5CoeffDetailValues(key, processingShapeCoeff)}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={24}>
                                    <Input
                                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                                      value={getIQ5CoeffDetailValues(key, processingShapeCoeff)}
                                      onChange={(event) => changeProcessingShapeCoeff(key, event.target.value)}
                                    ></Input>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ))}
              </Row>
            </div>
          </>

          {/* 材質区分係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>材質区分係数</label>
            </Row>
            <div className="section-margin-bottom">
              <Row className="table-container">
                {Object.entries(machiningMaterialCoeff)?.map(([key, data], index) => (
                  <Col key={index} style={{ width: '20%' }}>
                    <Table className="reset-spacing">
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">{getMaterialTypeIq5Nm(key, props?.materialTypes)}</th>
                        </tr>
                      </thead>
                      <tbody key={index}>
                        <tr style={{ textAlign: 'center', height: 35 }}>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{getIQ5CoeffDetailValues(key, machiningMaterialCoeff)}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={24}>
                                    <Input
                                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                                      value={getIQ5CoeffDetailValues(key, machiningMaterialCoeff)}
                                      onChange={(event) => changeMachiningMaterialCoeff(key, event.target.value)}
                                    ></Input>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ))}
              </Row>
            </div>
          </>

          {/* T1サイズ(mm)係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>T1サイズ(mm)係数</label>
            </Row>
            {/* T1サイズ(mm)係数 */}
            <Row className="table-container">
              <Table
                style={{
                  width:
                    t1SizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : t1SizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={(e) => addSize('t1')}
                              style={{ pointerEvents: 'auto' }}
                            >
                              サイズ(mm)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ(mm)</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {t1SizeCoeffThMaster
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ color: 'white', width: '98px' }}>
                        ～{row.value}mm
                      </td>
                    ))}
                </tr>

                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="fixedOneRowCol">
                    {'係数'}
                  </td>
                  {t1SizeCoeffThItems
                    ?.filter((i) => !i.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={editMode ? row.value : toDecimal(row.value, 2)}
                          onChange={(e) => changeCoeffListData(e, row.no, 't1')}
                          onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 't1')}
                          onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 't1')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                </tr>
              </Table>
            </Row>
          </>

          {/* Aサイズ(mm)係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>Aサイズ(mm)係数</label>
            </Row>
            {/* Aサイズ(mm)係数 */}
            <Row className="table-container">
              <Table
                style={{
                  width:
                    aSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : aSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={(e) => addSize('a')}
                              style={{ pointerEvents: 'auto' }}
                            >
                              サイズ(mm)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ(mm)</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {aSizeCoeffThMaster
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ color: 'white', width: '98px' }}>
                        ～{row.value}mm
                      </td>
                    ))}
                </tr>

                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="fixedOneRowCol">
                    {'係数'}
                  </td>
                  {aSizeCoeffThItems
                    ?.filter((i) => !i.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={editMode ? row.value : toDecimal(row.value, 2)}
                          onChange={(e) => changeCoeffListData(e, row.no, 'a')}
                          onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 'a')}
                          onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 'a')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                </tr>
              </Table>
            </Row>
          </>

          {/* Bサイズ(mm)係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>Bサイズ(mm)係数</label>
            </Row>
            {/* Bサイズ(mm)係数 */}
            <Row className="table-container">
              <Table
                style={{
                  width:
                    bSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : bSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={(e) => addSize('b')}
                              style={{ pointerEvents: 'auto' }}
                            >
                              サイズ(mm)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ(mm)</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {bSizeCoeffThMaster
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ color: 'white', width: '98px' }}>
                        ～{row.value}mm
                      </td>
                    ))}
                </tr>

                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="fixedOneRowCol">
                    {'係数'}
                  </td>
                  {bSizeCoeffThItems
                    ?.filter((i) => !i.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={editMode ? row.value : toDecimal(row.value, 2)}
                          onChange={(e) => changeCoeffListData(e, row.no, 'b')}
                          onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 'b')}
                          onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 'b')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                </tr>
              </Table>
            </Row>
          </>

          {/* Cサイズ(mm)係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>Cサイズ(mm)係数</label>
            </Row>
            {/* Cサイズ(mm)係数 */}
            <Row className="table-container">
              <Table
                style={{
                  width:
                    cSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : cSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button
                              className="cancleButton"
                              onClick={(e) => addSize('c')}
                              style={{ pointerEvents: 'auto' }}
                            >
                              サイズ(mm)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ(mm)</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {cSizeCoeffThMaster
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ color: 'white', width: '98px' }}>
                        ～{row.value}mm
                      </td>
                    ))}
                </tr>

                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="fixedOneRowCol">
                    {'係数'}
                  </td>
                  {cSizeCoeffThItems
                    ?.filter((i) => !i.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={editMode ? row.value : toDecimal(row.value, 2)}
                          onChange={(e) => changeCoeffListData(e, row.no, 'c')}
                          onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 'c')}
                          onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 'c')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                </tr>
              </Table>
            </Row>
          </>

          {/* 重量(Kg)区分係数 */}
          <>
            <Row>
              <label style={{ fontSize: 14, fontWeight: 400 }}>重量(Kg)区分係数</label>
            </Row>
            {/* 重量(Kg)区分係数 */}
            <Row className="table-container">
              <Table
                style={{
                  width:
                    processingWeightCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : processingWeightCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                              重量(Kg)
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>重量(Kg)</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {processingWeightCoeffThMaster
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ color: 'white', width: '98px' }}>
                        ～{row.value}kg
                      </td>
                    ))}
                </tr>

                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="fixedOneRowCol">
                    {'重量係数'}
                  </td>
                  {processingWeightCoeffThItems
                    ?.filter((i) => !i.isOutOfRange)
                    ?.map((row, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={editMode ? row.value : toDecimal(row.value, 2)}
                          onChange={(e) => changeCoeffListData(e, row.no, 'kg')}
                          onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 'kg')}
                          onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 'kg')}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                        />
                      </td>
                    ))}
                </tr>
              </Table>
            </Row>
          </>

          {/* Rサイズ(mm)係数 */}
          {props?.iq5WorkType === WorkType.PaSteelBend ? (
            <>
              <Row>
                <label style={{ fontSize: 14, fontWeight: 400 }}>Rサイズ(mm)係数</label>
              </Row>
              {/* Rサイズ(mm)係数 */}
              <Row className="table-container">
                <Table
                  style={{
                    width:
                      rSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length > 8
                        ? '100%'
                        : rSizeCoeffThMaster?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                  }}
                >
                  <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
                    <td style={{ width: '196px' }} className="tbl-header fixedCol">
                      <Row justify="center" className="tbl-header">
                        <Space>
                          {editMode ? (
                            <>
                              <Button
                                className="cancleButton"
                                onClick={(e) => addSize('r')}
                                style={{ pointerEvents: 'auto' }}
                              >
                                サイズ(mm)
                              </Button>
                            </>
                          ) : (
                            <>
                              <label>サイズ(mm)</label>
                            </>
                          )}
                        </Space>
                      </Row>
                    </td>
                    {rSizeCoeffThMaster
                      ?.filter((subitem) => !subitem.isOutOfRange)
                      ?.map((row, index) => (
                        <td key={index} style={{ color: 'white', width: '98px' }}>
                          ～{row.value}mm
                        </td>
                      ))}
                  </tr>

                  <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="fixedOneRowCol">
                      {'係数'}
                    </td>
                    {rSizeCoeffThItems
                      ?.filter((i) => !i.isOutOfRange)
                      ?.map((row, index) => (
                        <td key={index} style={{ width: '98px' }}>
                          <Input
                            value={editMode ? row.value : toDecimal(row.value, 2)}
                            onChange={(e) => changeCoeffListData(e, row.no, 'r')}
                            onBlur={(e) => changeCoeffListDataOnBlur(e, row.no, 'r')}
                            onPressEnter={(e) => changeCoeffListDataOnBlur(e, row.no, 'r')}
                            onKeyPress={(e) => {
                              handleKeyPress(e, false);
                            }}
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                          />
                        </td>
                      ))}
                  </tr>
                </Table>
              </Row>
            </>
          ) : (
            ''
          )}

          {/* 生産個数係数 タイトル */}
          <Row>
            <label style={{ fontSize: 14, fontWeight: 400 }}>生産個数係数</label>
          </Row>
        </div>
        {/* 生産個数係数 */}
        <Quantity
          detailsData={props?.detailsData}
          editMode={editMode}
          updatedKosuu={props?.updateProductionQtyCoeffMst}
          updatedKosuuList={props?.updateProductionQtyCoeffList}
        />
      </div>

      {/* 加工時間の重量 */}
      {isProcessTimeWeight || isProcessTimeSize
        ? commonModal(
            isProcessTimeWeight || isProcessTimeSize,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addProcessTimeWeightContent,
            null,
            null,
            true
          )
        : ''}

      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}

      {/* サイズ */}
      {isSize
        ? commonModal(
            isSize,
            addSizeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSizeContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default IQ5_Device_ProcessTime;
