import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { initProcessSelect, patternPage } from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';
import { updateUserSetting } from '../common/CommonAPI';
import { CreatePMXData, ServiceClass } from '../common/enums';
import { Spin } from 'antd';

const ParentKouteiSentaku = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState(0);
  const [kouteiSentakuPatternList, setKouteiSentakuPatternList] = useState([]);
  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);

  const patternformRef = useRef();

  useEffect(() => {
    if (props?.activeTabKey === '2' || props?.pmxMode) {
      getProcesssPatternInfo();
    }
  }, [props.activeTabKey, props?.isReCal]);

  const getProcesssPatternInfo = () => {
    let kouteiPatternList = [];
    let parameters = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.Parent);

    setListData(kouteiPatternList);
    setKouteiSentakuPatternList(kouteiPatternList);
    let processSelect = props?.selectedDataDetail?.estimateProducts?.processSelect;
    // //初期状態に金額を０にしてほしい対応
    // if (processSelect.id === undefined) {
    //   let initProcessSelect = {
    //     id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
    //     name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
    //     details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
    //     modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
    //   };
    //   processSelect = initProcessSelect;
    // }
    setProcessSelectedData(processSelect);
    setKouteiSentakuPatternNo(processSelect?.id);
    setDeviceMasterInfo(parameters?.device);
    setProcessMasterInfo(parameters?.process);
    setClientInfo(parameters?.client);
  };

  const updateKouteiSentakuInfo = async (selectedProcessPattern, list) => {
    setKouteiSentakuPatternNo(selectedProcessPattern.id);
    setProcessSelectedData(selectedProcessPattern);
    setKouteiSentakuPatternList(list);
    props.selectedDataDetail.estimateProducts.processSelect = initProcessSelect(selectedProcessPattern);

    if (props?.type === 'pmx' && props?.isNewPMXOrNot === CreatePMXData.New) {
      props.updateSelectedParentPatternNo(selectedProcessPattern?.id);
    } else {
      // IQX_WEBEST-323 新規処理の前回値の採用
      let userSetting = props.selectedDataDetail.estimateProducts.calcParameters.settings.userSettings;
      if (userSetting?.length > 0) userSetting = userSetting[0];
      if (userSetting !== null) {
        if (userSetting.users?.newParentInitialInfo?.parentProcessPatternId !== undefined) {
          userSetting.users.newParentInitialInfo.parentProcessPatternId = selectedProcessPattern.id;
          await updateUserSetting(userSetting, 0);
        }
      }
    }

    // props.updatePatternList(updatePatternList)
  };

  const updateIsPrepareInfoFlg = (flg) => {
    props?.updateIsPrepareInfoFlg(flg);
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
      getProcessSelectedData: () => {
        return processSelectedData;
      },
      getKouteiSentakuPatternList: () => {
        return kouteiSentakuPatternList;
      },
    }),
    [kouteiSentakuPatternNo, processSelectedData, kouteiSentakuPatternList]
  );

  return (
    <Spin spinning={props?.activeTabKey === '2' || props?.pmxMode ? false : true} tip="" size="large">
      <>
        {props?.activeTabKey === '2' || props?.pmxMode ? (
          <ProcessPatternForm
            ref={patternformRef}
            selectedData={processSelectedData}
            listData={listData}
            editMode={props?.editMode}
            page={patternPage[0]}
            isParameter={false}
            updateKouteiSentakuInfo={updateKouteiSentakuInfo}
            kouteiSentakuEditMode={props?.editMode}
            pmxMode={props.pmxMode}
            processMasterData={processMasterInfo}
            deviceMasterData={deviceMasterInfo}
            clientMasterData={clientInfo}
            updateIsPrepareInfoFlg={updateIsPrepareInfoFlg}
          />
        ) : (
          ''
        )}
      </>
    </Spin>
  );
});

export default ParentKouteiSentaku;
