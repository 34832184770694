/**
 * クラス名：形鋼の表示設定
 * 作成者：ナンス
 * 作成日：2024/10/24
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Row, Col, Select, Checkbox } from 'antd';
import { WorkType } from '../../../common/enums';

const IQ5_Device_DisplaySetting = forwardRef((props, ref) => {
  const [iQ5ManufactSizeInfoKinds, setIQ5ManufactSizeInfoKinds] = useState([]);
  const [initialDisplay, setInitialDisplay] = useState(0);
  const [isSetupSetting, setIsSetupSetting] = useState(false);
  const [selectedKinds, setSelectedKinds] = useState([]);

  useEffect(() => {
    if (props?.detailsData?.iQ5ManufactSizeInfo && Object.keys(props?.detailsData?.iQ5ManufactSizeInfo)?.length > 0) {
      if (props?.detailsData?.iQ5ManufactSizeInfo?.kinds?.length > 0) {
        setIQ5ManufactSizeInfoKinds(props?.detailsData?.iQ5ManufactSizeInfo?.kinds);
        let checkedKinds = props?.detailsData?.iQ5ManufactSizeInfo?.kinds?.filter((item) => item.dispSW);
        setSelectedKinds(checkedKinds?.length > 0 ? checkedKinds : []);
      }
    }
    if (props?.detailsData?.initialDisplay !== undefined) {
      setInitialDisplay(props?.detailsData?.initialDisplay);
    }
  }, [props.editMode, props.detailsData]);

  useEffect(() => {
    setIsSetupSetting(props?.isIq5DisplaySetting);
  }, [props?.isIq5DisplaySetting]);

  const setupSetting = () => {
    if (isSetupSetting == true) {
      setIsSetupSetting(false);
    } else {
      setIsSetupSetting(true);
    }
  };

  return (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={setupSetting}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>表示設定</label>
            {isSetupSetting ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div
        style={{
          display: isSetupSetting ? 'block' : 'none',
          marginLeft: 10,
          marginRight: 10,
        }}
        className="table-container"
      >
        {/* 加工指示種類 */}
        <Row>
          <Col style={{ width: '40%' }}>
            <Table>
              {iQ5ManufactSizeInfoKinds?.map((item, index) => (
                <tbody key={index}>
                  <tr style={{ textAlign: 'left', height: 33 }}>
                    <td style={{ verticalAlign: 'middle', textAlign: 'left' }}>
                      <Row align="middle">
                        <Col span={13} style={{ marginLeft: 5 }}>
                          <label>{item?.name}</label>
                        </Col>
                        <Col span={10}>
                          {props?.iq5WorkType === WorkType.PaSteelHole ? (
                            <>
                              {item.no > 2 ? (
                                <>
                                  <Checkbox
                                    checked={item?.dispSW}
                                    disabled={props?.editMode ? false : true}
                                    onChange={(e) => {
                                      if (props?.editMode) {
                                        let tempData = [...iQ5ManufactSizeInfoKinds];
                                        if (tempData?.length > 0) {
                                          tempData[index].dispSW = e.target.checked;
                                          setIQ5ManufactSizeInfoKinds(tempData);
                                          let checkedKinds = tempData?.filter((item) => item.dispSW);
                                          setSelectedKinds(checkedKinds?.length > 0 ? checkedKinds : []);
                                          let selectedValueExist = checkedKinds?.filter(
                                            (item) => item.no === initialDisplay
                                          );
                                          if (selectedValueExist?.length === 0) {
                                            setInitialDisplay(0);
                                            props?.updatedInitialDisplay(0);
                                          }
                                          let iQ5ManufactSizeInfoTemp = props?.detailsData?.iQ5ManufactSizeInfo;
                                          if (iQ5ManufactSizeInfoTemp) {
                                            iQ5ManufactSizeInfoTemp.kinds = tempData;
                                            props?.updatedIQ5ManufactSizeInfo(iQ5ManufactSizeInfoTemp);
                                          }
                                        }
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {item.no > 0 ? (
                                <>
                                  <Checkbox
                                    checked={item?.dispSW}
                                    disabled={props?.editMode ? false : true}
                                    onChange={(e) => {
                                      if (props?.editMode) {
                                        let tempData = [...iQ5ManufactSizeInfoKinds];
                                        if (tempData?.length > 0) {
                                          tempData[index].dispSW = e.target.checked;
                                          setIQ5ManufactSizeInfoKinds(tempData);
                                          let checkedKinds = tempData?.filter((item) => item.dispSW);
                                          setSelectedKinds(checkedKinds?.length > 0 ? checkedKinds : []);
                                          let selectedValueExist = checkedKinds?.filter(
                                            (item) => item.no === initialDisplay
                                          );
                                          if (selectedValueExist?.length === 0) {
                                            setInitialDisplay(0);
                                            props?.updatedInitialDisplay(0);
                                          }
                                          let iQ5ManufactSizeInfoTemp = props?.detailsData?.iQ5ManufactSizeInfo;
                                          if (iQ5ManufactSizeInfoTemp) {
                                            iQ5ManufactSizeInfoTemp.kinds = tempData;
                                            props?.updatedIQ5ManufactSizeInfo(iQ5ManufactSizeInfoTemp);
                                          }
                                        }
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </Col>
        </Row>
        {/* 初期表示設定 */}
        <>
          <Row>
            <label style={{ fontSize: 14, fontWeight: 400 }}>初期表示設定</label>
          </Row>
          <Row>
            <Select
              style={{ width: '20%' }}
              value={initialDisplay}
              defaultValue={0}
              onChange={(e) => {
                if (props?.editMode) {
                  setInitialDisplay(e);
                  props?.updatedInitialDisplay(e);
                }
              }}
            >
              {selectedKinds?.map((item, index) => (
                <Select.Option key={index} value={item.no}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </>
      </div>
    </>
  );
});

export default IQ5_Device_DisplaySetting;
