/**
 * クラス名：板金子部品見積材料詳細
 * 説明：板板金子部品見積材料詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2023/06/29
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { draw, freeDraw, scaleSet } from '../common/Common';

const IQ3MaterialDetail_SheetDetailPattern = forwardRef((props, ref) => {
  /** 配置情報 */
  // Scale
  let ca1Scale = 1;

  const toDraw = (toDrawInfo, isPattern2, ySetting, rightFit, topFit, isFree, isExistPattern2) => {
    let partCntInfo = undefined;

    /** 配置するCanvasの情報 */
    const canvas = document.getElementById('canvas');
    if (canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      const canvasContext = canvas.getContext('2d');
      if (canvasContext !== null) {
        if (!isFree) {
          // 材料・掴み代・部品矩形・桟幅の配置
          partCntInfo = draw(
            toDrawInfo.partInfo,
            toDrawInfo.settingInfo,
            toDrawInfo.materialInfo,
            isPattern2,
            ySetting,
            rightFit,
            topFit,
            document.getElementById('canvas')
          );
        } else {
          // 材料・掴み代・部品矩形・桟幅の配置
          partCntInfo = freeDraw(
            toDrawInfo.partInfo,
            toDrawInfo.settingInfo,
            toDrawInfo.materialInfo,
            isPattern2,
            ySetting,
            rightFit,
            topFit,
            document.getElementById('canvas'),
            isExistPattern2
          );
        }
        // 部品XYサイズ配置
        // 製品の矩形X
        let partX = toDrawInfo.partInfo.mPartX + toDrawInfo.settingInfo.mMarginX;
        let partY = toDrawInfo.partInfo.mPartY + toDrawInfo.settingInfo.mMarginY;
        let partCntX = partCntInfo?.partCntX ? partCntInfo?.partCntX : 0;
        let partCntY = partCntInfo?.partCntY ? partCntInfo?.partCntY : 0;
        let fillTextX = 'X=' + partX * partCntX + '(' + partCntX + ')';
        let fillTextY = 'Y=' + partY * partCntY + '(' + partCntY + ')';
        canvasContext.beginPath();
        const { backgroundColor = 'black', color = 'black' } = { backgroundColor: 'PowderBlue', color: 'black' };

        let mSheetX = !isFree
          ? toDrawInfo.materialInfo.mSheetX
          : isPattern2
          ? toDrawInfo.materialInfo.p2MSheetX
          : toDrawInfo.materialInfo.p1MSheetX;
        let mSheetY = !isFree
          ? toDrawInfo.materialInfo.mSheetY
          : isPattern2
          ? toDrawInfo.materialInfo.p2MSheetY
          : toDrawInfo.materialInfo.p1MSheetY;
        if (canvas !== '') {
          //　指定したCanvasに配置できるScaleを計算
          ca1Scale = scaleSet(0, mSheetX, mSheetY, canvas);
          if (ca1Scale == 0) return;
        }
        // 開始X
        let startX = mSheetX * ca1Scale;
        let startY = mSheetY * ca1Scale;
        canvasContext.fillStyle = backgroundColor;
        canvasContext.fillRect(startX - 90, startY - 13, 100, 12);
        canvasContext.fillStyle = color;
        canvasContext.font = 'bold';
        canvasContext.fillText(fillTextX, startX - 88, startY - 3, 200);
        // 開始Y
        canvasContext.fillStyle = backgroundColor;
        canvasContext.fillRect(0, 0, 100, 12);
        canvasContext.fillStyle = color;
        canvasContext.font = 'bold';
        canvasContext.fillText(fillTextY, 2, 10, 200);
      }
    }
    return partCntInfo;
  };

  useImperativeHandle(ref, () => ({
    sheetDetailDraw: toDraw,
  }));

  return (
    <>
      <canvas width="480" height="240" id="canvas"></canvas>
    </>
  );
});

export default IQ3MaterialDetail_SheetDetailPattern;
