/**
 * クラス名：形鋼の共通ファイル
 * 説明：形鋼に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/10/30
 * バージョン：1.0
 */

// 鋼材区分の詳細情報を得る
export const getProcessingShapeCoeffNm = (id, steelShapeData) => {
  var coeffId = id ? parseInt(id) : 0;
  var steelShapeNmValue = '';
  var steelShapeNm = [];
  steelShapeNm = steelShapeData?.filter((item) => item.shapeNo === coeffId);
  steelShapeNmValue = steelShapeNm?.length > 0 ? steelShapeNm?.[0]?.name : '';
  return steelShapeNmValue;
};

// 鋼材区分の詳細情報を得る
export const getIQ5CoeffDetailValues = (id, coeffList) => {
  var detailValue = {};
  var coeffId = id ? parseInt(id) : 0;
  detailValue = Object.entries(coeffList)?.filter(([key, data]) => parseInt(key) === coeffId)?.[0];
  detailValue = detailValue?.length > 0 ? detailValue[1] : 0;
  return detailValue;
};

// 形鋼材質区分の詳細情報を得る
export const getMaterialTypeIq5Nm = (id, materialTypes) => {
  var typeId = id ? parseInt(id) : 0;
  var steelNmValue = '';
  var steelNm = [];
  steelNm = materialTypes?.filter((item) => item.id === typeId);
  steelNmValue = steelNm?.length > 0 ? steelNm?.[0]?.name : '';
  return steelNmValue;
};

// 鋼材区分の詳細情報を取得
export const getWeightDetailNm = (id, processDetailTypes) => {
  var weightDetailId = id ? parseInt(id) : 0;
  var weightDetailNmVal = '';
  var weightDetailNm = [];
  weightDetailNm = processDetailTypes?.filter((item) => item.id === weightDetailId);
  weightDetailNmVal = weightDetailNm?.length > 0 ? weightDetailNm?.[0]?.name : '';
  return weightDetailNmVal;
};

// 鋼材区分のIdを得る
export const getProcessingShapeCoeffShapeNo = (name, steelShapeData) => {
  var steelShapeNo = 0;
  var steelShapeNm = [];
  steelShapeNm = steelShapeData?.filter((item) => item.name === name);
  steelShapeNo = steelShapeNm?.length > 0 ? steelShapeNm?.[0]?.shapeNo : 0;
  steelShapeNo = steelShapeNo ? parseInt(steelShapeNo) : steelShapeNo;
  return steelShapeNo;
};

// 形鋼材質区分のを得る
export const getIQ5IdByName = (name, materialTypes) => {
  var materialTypeId = 0;
  var materialType = [];
  materialType = materialTypes?.filter((item) => item.name === name);
  materialTypeId = materialType?.length > 0 ? materialType?.[0]?.id : 0;
  materialTypeId = materialTypeId ? parseInt(materialTypeId) : materialTypeId;
  return materialTypeId;
};
