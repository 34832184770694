/**
 * クラス名：形鋼子部品見積
 * 説明：形鋼子部品のタブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/06
 * バージョン：1.0
 */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Button, Row, Col, Space, Image, Tooltip, Modal, Checkbox, Spin, Input } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CloseOutlined,
  CaretRightOutlined,
  FileExclamationOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  FormOutlined,
  DollarOutlined,
  MoneyCollectOutlined,
  ShopOutlined,
  ShoppingOutlined,
  ProductOutlined,
} from '@ant-design/icons';
import Draggable from 'react-draggable';

import '../../assets/styles/common.css';
import '../../assets/styles/style.css';
import down_Arrow from '../../assets/images/down_Arrow.png';
import up_Arrow from '../../assets/images/up_Arrow.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import KouteiSuryouBetsuBtn from '../parent/KouteiSuryouBetsuBtn.js';
import IQ3KouteiCalculate from '../iq3/IQ3KouteiCalculate.js';
import IQ3KouteiInput from '../iq3/IQ3KouteiInput.js';
import IQ5KouteiSentaku from '../iq5/IQ5KouteiSentaku.js';
import {
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  confirmModalTitle,
  commonModal,
  commonFooter,
  commonDeleteFooter,
} from '../common/CommonModal.js';
import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  padWithLeadingZeros,
  formatDateString,
  todayTime,
  toDecimal,
  updateEstimateInfoByReCal,
  JPYs,
  getDetails,
  getInitMaterialSizeInfo,
  getInitMaterialPatternsInfo,
  iq5EstimateInitInfo,
  toDecimal_place3,
  calculateIQ5Material,
} from '../common/Common.js';
import { GetParametersByCompId, getEstimateIq3Info, getEstimateIq5Info } from '../common/CommonAPI.js';
import { WorkType, DataType, ServiceClass, SurfaceProtectionType, EstimateCreationType } from '../common/enums.js';
import { amtEmptyStr, PNG, STEEL_SHAPE_URL } from '../common/Constant.js';
import copy_sm from '../../assets/images/copy_sm.png';
import edit_list from '../../assets/images/edit_list.png';
import manual from '../../assets/images/manual.png';
import auto from '../../assets/images/auto.png';
import IQ5EstimateDetail from './IQ5EstimateDetail.js';
import SteelMaterialList from '../parameter/iq5/steelMaterial/SteelMaterialList.js';
import IQ5KouteiCalculate from './IQ5KouteiCalculate.js';
import IQ5Purchase from './IQ5Purchase.js';

dayjs.extend(customParseFormat);

const IQ5Estimate = forwardRef((props, ref) => {
  const [selectedData, setSelectedData] = React.useState();
  const [modalTitleTxt, setMdalTitleTxt] = useState('確認');
  // 確認ダイアログ
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [discardMode, setDiscardMode] = useState(false);
  const [iQ5DataLst, setIQ5DataLst] = useState([]);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordNo, setCurrentRecordNo] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState('0');
  const [selectedTabKey, setSelectedTabKey] = useState('1');
  const [isDialogOk, setIsDialogOk] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAddPart, setIsAddPart] = useState(false);
  const [materialLst, setMaterialLst] = useState([]);
  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientMasterInfo, setClientMasterInfo] = useState([]);
  const [iq5ActionForCal, setIq5ActionForCal] = useState(false);
  const [selectedProcessSelect, setSelectedProcessSelect] = useState({});
  const [detailTabModalWidth, setDetailTabModalWidth] = useState(1200);
  const [reCalByAddDelete, setReCalByAddDelete] = useState(false);
  const [isPartCountChange, setIsPartCountChange] = useState(false);
  const [currentRecordNoByChangeQty, setCurrentRecordNoByChangeQty] = useState(1);
  const [currentRecordNoByBultEdit, setCurrentRecordNoByBultEdit] = useState(0);
  const [isBulkEditAction, setIsBulkEditAction] = useState(false);
  const [detailProcessSelect, setDetailProcessSelect] = useState(false);
  const [isSteelMaterialListModalVisible, setIsSteelMaterialListModalVisible] = useState(false);
  const [selectedSteelSelect, setSelectedSteelSelect] = useState({});
  const [selectedSteelClassList, setSelectedSteelClassList] = useState({});
  const [selectedSteelTypeList, setSelectedSteelTypeList] = useState({});
  const [isOpenProcessDetail, setIsOpenProcessDetail] = useState(false);
  const [openWorkType, setOpenWorkType] = useState(WorkType.PaSteelCut);
  const [processTabItems, setProcessTabItems] = useState([]);
  const [processTabItemsModalTitleTxt, setProcessTabItemsModalTitleTxt] = useState('');
  const [selectedProcessAdditions, setSelectedProcessAdditions] = useState([]);
  const [selectedProcessInput, setSelectedProcessInput] = useState([]);
  const [purchaseModifiedData, setPurchaseModifiedData] = useState([]);

  // 形鋼子部品プロパティ情報
  let iq3DetailRef = useRef();

  // 工程選択
  let iq5KouteiSentakuRef = useRef();

  // 工程計算
  let iq3KouteiCalRef = useRef();

  // 工程入力
  let iq5DetailRef = useRef();

  // 購入品
  let purchaseRef = useRef();

  //
  let kouteiCalculateRef = useRef();

  const estimateParameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

  // iQ3形鋼タブを選択中で、子部品追加呼出で追加した時
  useEffect(() => {
    if (props?.activePartMenu === '3' && !isAddPart) {
      let iq5Infos = props.selectedEstimateData?.esIq5Info;
      if (iq5Infos === undefined) return;
      setIQ5DataLst(iq5Infos);
    }
  }, [props.selectedEstimateData?.esIq5Info]);

  //　新規呼出
  useEffect(() => {
    if (!props.type) {
      // 新規モードの場合、
      if (props.selectedEstimateData?.estimateProducts?.id == 0) {
        initIQ5EstimateInfo(props.selectedEstimateData);
      } else {
        // 保存データを開くの場合
        if (props?.selectedEstimateData?.esIq5Info?.length > 0) {
          setIQ5DataLst(props.selectedEstimateData?.esIq5Info);
          setSelectedData(props.selectedEstimateData?.esIq5Info?.[0]);
          setCurrentRecordNo(props.selectedEstimateData?.esIq5Info?.[0]?.no);
          let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
          // 材料リスト
          let materialList = parameters?.materialIq3;
          setMaterialLst(materialList);
          if (parameters?.process?.length > 0) {
            let processMstData = getProcessMstInfo(parameters?.process);
            setProcessMasterInfo(processMstData);

            if (parameters?.device?.length > 0) {
              let deviceLst = getDeviceMstInfo(parameters?.device, parameters?.process);
              setDeviceMasterInfo(deviceLst);
            }
          }
          if (parameters?.client?.length > 0) {
            setClientMasterInfo(parameters?.client);
          }
        }
      }
    }
  }, [props.selectedEstimateData, props.type, props?.activePartMenu]);

  //基本情報・工程選択・工程入力・工程積算・購入品・材料詳細ダイアログのOKボタンを押した場合、再計算を行う。
  // 行追加・行削除の場合も再計算を行う。
  useEffect(() => {
    if (isDialogOk || reCalByAddDelete || iq5ActionForCal) {
      setTimeout(() => {
        let calEstimateInfo = updateEstimateInfoByReCal(props.selectedEstimateData, props?.isEstimateOpen);
        if (calEstimateInfo) {
          // 一覧の情報を反映
          if (calEstimateInfo?.esIq5Info) {
            const esIq5InfoByRecal = JSON.parse(JSON.stringify(calEstimateInfo?.esIq5Info));
            setIQ5DataLst(esIq5InfoByRecal);
            setSelectedData(esIq5InfoByRecal?.[0]);
            props.selectedEstimateData.esIq5Info = esIq5InfoByRecal;
          }
          props.updateEstimateInfo(calEstimateInfo);
          props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
          setSelectedEstimateData(calEstimateInfo);
          setIsDialogOk(false);
          setReCalByAddDelete(false);
          setIq5ActionForCal(false);
          props?.updateIsRecal(false);
        }
      }, 100);
    }
  }, [isDialogOk, reCalByAddDelete, iq5ActionForCal]);

  useEffect(() => {
    if (isAddPart) {
      setIsAddPart(false);
      let no = iQ5DataLst?.length > 0 ? iQ5DataLst[iQ5DataLst?.length - 1].no : 0;
      scrollTo(no);
    }
  }, [iQ5DataLst]);

  useEffect(() => {
    if (props?.parentQuantityChangeFlg && props.isReCal) {
      let curNo = currentRecordNoByChangeQty;
      if (curNo <= iQ5DataLst?.length) {
        let curData = iQ5DataLst?.filter((item) => item.no === curNo);
        if (curData?.length > 0) {
          setSelectedData(curData?.[0]);
          setCurrentRecordNo(curData?.[0]?.no);
          setCurrentRecordNoByChangeQty(curNo + 1);
          props.updateIsRecal(true);
        }
      } else {
        if (props?.selectedEstimateData?.esIq5Info?.length > 0) {
          setSelectedData(props.selectedEstimateData?.esIq5Info?.[0]);
          setCurrentRecordNo(props.selectedEstimateData?.esIq5Info?.[0]?.no);
        }
        props.updateParentQuantityChange(false);
        props.updateIsRecal(false);
      }
    }
  }, [props.isReCal]);

  useEffect(() => {
    if (isBulkEditAction) {
      let curNo = currentRecordNoByBultEdit;
      if (curNo <= iQ5DataLst?.length) {
        let curData = iQ5DataLst?.filter((item) => item.no === curNo);
        if (curData?.length > 0) {
          setSelectedData(curData?.[0]);
          setCurrentRecordNo(curData?.[0]?.no);
          setCurrentRecordNoByBultEdit(curNo + 1);
          props.updateIsRecal(true);
        }
      } else {
        let calEstimateInfo = updateEstimateInfoByReCal(props.selectedEstimateData, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
        if (calEstimateInfo) {
          // 一覧の情報を反映
          setIQ5DataLst(calEstimateInfo?.esIq5Info);
          setSelectedData(calEstimateInfo?.esIq5Info?.[0]);
          setCurrentRecordNo(calEstimateInfo?.esIq5Info?.[0]?.no);
          props.updateEstimateInfo(calEstimateInfo);
          props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
          props.selectedEstimateData.esIq5Info = calEstimateInfo?.esIq5Info;
          setSelectedEstimateData(calEstimateInfo);
        }
        setIq5ActionForCal(false);
        setIsBulkEditAction(false);
        setCurrentRecordNoByBultEdit(0);
        props.updateIsRecal(false);
      }
    }
  }, [currentRecordNoByBultEdit]);

  const updateiq5DataByBulkEdit = (bulkEditiq5Infos) => {
    if (bulkEditiq5Infos?.length > 0) {
      if (props.selectedEstimateData.esIq5Info) {
        let totalPartCount = bulkEditiq5Infos?.reduce((total, item) => {
          return total + parseFloat(item.partCount);
        }, 0);
        if (props.selectedEstimateData.estimateProducts) {
          props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount =
            totalPartCount;
          // IQX_WEBEST-179
          props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = totalPartCount;
        }
        setCurrentRecordNoByBultEdit(1);
        setIsBulkEditAction(true);
      }
    }
  };

  // 形鋼部品見積もりの初期化
  const initIQ5EstimateInfo = async (selectedEstimateData) => {
    if (selectedEstimateData === undefined) {
      return;
    }
    let iq5Infos = [];
    if (!props.type) {
      // 新規モードの場合、
      if (selectedEstimateData?.estimateProducts?.id == 0) {
        let iq5Data = [...iq5EstimateInitInfo];
        let newIQ5 =
          selectedEstimateData?.esIq5Info && selectedEstimateData?.esIq5Info?.length > 0 // 親部品タブを選択中で、子部品追加呼出で追加した時
            ? selectedEstimateData?.esIq5Info
            : getNewIQ5EstimateInfo(iq5Data);
        iq5Infos = newIQ5;
        setIQ5DataLst(newIQ5);
      } else {
        //　形鋼情報リストを取得
        let estimateId = selectedEstimateData?.estimateProducts?.id;
        if (estimateId) iq5Infos = await getEstimateIq5Info(estimateId);
      }
      selectedEstimateData.esIq5Info = iq5Infos;
      // props.updatedSelectedEstimateDataTemp(selectedEstimateData);
    } else {
      let newIQ5 = selectedEstimateData?.esIq5Info;
      iq5Infos = newIQ5;
      setIQ5DataLst(iq5Infos);
    }
    let selectedData = iq5Infos?.length > 0 ? iq5Infos?.[0] : {};
    // setQuantity(props?.getParentQuantity()); // 入力した数量情報を得る
    setSelectedEstimateData(selectedEstimateData);
    setCurrentRecordNo(selectedData?.no ? selectedData?.no : 1);
    setSelectedData(selectedData);

    let parameters = selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 材料リスト
    let materialList = parameters?.materialIq3;
    setMaterialLst(materialList);

    if (parameters?.process?.length > 0) {
      let processMstData = getProcessMstInfo(parameters?.process);
      setProcessMasterInfo(processMstData);

      if (parameters?.device?.length > 0) {
        let deviceLst = getDeviceMstInfo(parameters?.device, parameters?.process);
        setDeviceMasterInfo(deviceLst);
      }
    }
    if (parameters?.client?.length > 0) {
      setClientMasterInfo(parameters?.client);
    }
  };

  // 材料マスタ情報
  const getProcessMstInfo = (process) => {
    let processMstData = process?.filter((process) => process?.class === ServiceClass.Beams);
    return processMstData;
  };

  // 装置マスタ情報
  const getDeviceMstInfo = (device, process) => {
    let processMstData = getProcessMstInfo(process);
    let deviceLst = device?.filter((item) => processMstData?.filter((process) => process?.id === item?.processId));
    return deviceLst;
  };

  // 新規モードの場合、形鋼部品情報を初期化する
  const getNewIQ5EstimateInfo = (lists) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    let userSettingData = props.selectedEstimateData?.estimateProducts?.calcParameters?.settings?.userSettings;

    const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
    // let kouteiPatternList = patternLst?.filter((item) => item.id === userSettingData?.[0]?.users?.iq3ProcessPatternId);
    // IQX_WEBEST-323 新規処理の前回値の採用
    // if (userSettingData?.length > 0 && userSettingData?.[0].users.newSmInitialInfo.iq3ProcessPatternId !== undefined) {
    //   let userSettingProcessPattern = patternLst?.filter(
    //     (i) => i.id === userSettingData?.[0].users.newSmInitialInfo.iq3ProcessPatternId
    //   );
    //   kouteiPatternList = userSettingProcessPattern?.length > 0 ? userSettingProcessPattern : kouteiPatternList;
    // }
    let kouteiPatternList = [];
    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.Beams);
    }

    // 材料明細情報
    const materialIq5 = parameters?.materialIq5?.filter((item) => item.info.isUsed && !item.isDeleted)?.[0];
    // 材質情報
    const materialTypeIq5 = parameters?.materialTypeIq5?.filter(
      (item) => item.info.isUsed && !item.isDeleted && materialIq5?.materialTypeIq5Id === item.id
    )?.[0];
    // 鋼材区分情報
    const steelShape = parameters?.steelShape?.filter((item) => item.id === materialIq5?.steelShapeId)?.[0];

    let materialIq5Id = materialIq5 ? materialIq5?.id : 0;
    let materialName = materialIq5 ? materialIq5?.name : '';
    let materialTypeIq5Id = materialTypeIq5 ? materialTypeIq5?.id : 0;
    let materialTypeName = materialTypeIq5 ? materialTypeIq5?.name : '';
    let steelShapeId = steelShape ? steelShape?.id : 0;
    let steelShapeName = steelShape ? steelShape?.name : '';
    let steelShapeURL = steelShape ? STEEL_SHAPE_URL + steelShape?.shapeNo + PNG : '';
    // 工程マスタ情報取得
    let processMaster = [];
    const processMstData = parameters?.process; // 工程マスタデータ
    processMaster = processMstData?.filter((process) => process?.class === ServiceClass.Beams);

    // 工程入力
    let processSelectDetails = kouteiPatternList?.[0]?.details;
    let kouteiInputArr = [];
    if (processSelectDetails) {
      kouteiInputArr = processSelectDetails?.map((item, index) => {
        let data = {
          id: index + 1,
          processId: item.processId,
          deviceProcessInputDetails: [],
        };
        return data;
      });
    }

    //　工程積算
    let kouteiCalArr = [];
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const dataItems = {
          totalDandoriTime: 0,
          totalDandoriAmt: 0,
          totalSagyoTime: 0,
          totalSagyoAmt: 0,
        };
        let addition = {
          no: item.processId,
          processId: item.processId,
          dataType: DataType.Data,
          deviceAdditionsDetails: [],
          dataItems: dataItems,
          editItems: dataItems,
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
          editDataFlg: false,
        };

        return addition;
      });
    }

    // 使用している装置の情報を取得する
    let grabbingX = 0;
    let grabbingY = 0;
    let pierWidthX = 0;
    let pierWidthY = 0;
    let worlRangeX = 0;
    let worlRangeY = 0;

    const updatedList = lists?.map((i, index) => {
      if (i?.id === 0) {
        i.no = 1;
        i.creationType = EstimateCreationType.Auto;
        i.drawingNo = 'IQ5Part-' + formatDateString(todayTime());
        i.name = 'IQ5Part-' + padWithLeadingZeros(index + 1, 5);
        i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
        i.materialIq5Id = materialIq5Id;
        i.materialName = materialName;
        i.materialTypeIq5Id = materialTypeIq5Id;
        i.materialTypeName = materialTypeName;
        i.steelShapeId = steelShapeId;
        i.steelShapeName = steelShapeName;
        i.imageId = steelShape ? steelShape?.shapeNo : 0;
        i.processSelect = {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        };
        i.processInput = kouteiInputArr;
        i.purchases = [];
        i.additions = kouteiCalArr;
        i.weight = 0;
        i.totalSurfaceArea = 0;
        i.partCount = 1;
        i.size = 0;
        i.materail;
        i.workCostUnitPrice = 0;
        i.workCostTotalPrice = 0;
        i.costUnitPrice = 0;
        i.costTotalPrice = 0;
        i.surfaceTotalPrice = 0;
        i.surfaceUnitPrice = 0;
        i.purchaseUnitPrice = 0;
        i.purchaseTotalPrice = 0;
        i.materialCostUnitPrice = 0;
        i.materialCostTotalPrice = 0;
      }
      return i;
    });
    return updatedList;
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecord = (classId, processTypes, mstInfo) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter(
      (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
    );
    return mstRecordInfo;
  };

  // 形鋼部品見積もりを取得する
  const getEstimateiq5Data = async (estimateProductId) => {
    let estimateiq5Infos = await getEstimateIq5Info(estimateProductId);
    if (estimateiq5Infos) {
      setIQ5DataLst(estimateiq5Infos);
    }
    return estimateiq5Infos;
  };

  // Scrollの移動
  const scrollTo = (rowKey) => {
    // Scrollの移動
    var activeRowElements = document.getElementById('IQ5EstimateTbl').getElementsByClassName('ant-table-row');
    for (let i = 0; i < activeRowElements?.length; i++) {
      var targetElement = activeRowElements[i].dataset.rowKey == rowKey ? activeRowElements[i] : [];
      if (targetElement != '') {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  // 編集中破棄確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    props.updateMainEstimateEditMode(false);
    onChangeTab(selectedTabKey);
    props?.updateIq3ActiveTabKey(selectedTabKey);
    getAllEstimateIq5Info();
  };

  // 編集中破棄確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // 部品リストの行を選択した場合、
  const onRowSelect = (record) => {
    setCurrentRecordNo(record.no);
    setSelectedData(record);
  };

  // 選択中であり、部品の各タブ情報をリストに反映するようにする
  const getAllEstimateIq5Info = () => {
    setConfirmMsgOpen(false);
    props.updateMainEstimateEditMode(false);

    let estimateData = { ...props.selectedEstimateData };
    let selectedIQ5EstimateData = estimateData?.esIq5Info?.find((iq3) => iq3?.no === selectedData?.no);

    // 一覧の情報を反映するため、データ取得
    let updatedData = estimateData?.esIq5Info;
    let data = [...iQ5DataLst];
    if (data?.length > 0) {
      updatedData = data?.map((item) => {
        if (item.no === selectedIQ5EstimateData?.no) {
          item = selectedIQ5EstimateData;
        }
        // IQX_WEBEST-286 自動：工程積算タブでデータ欄を使用している状態
        let isManual = false;
        for (let i = 0; i < item?.additions?.length; i++) {
          let eqAddition = item?.additions[i];
          if (eqAddition?.dataType) {
            isManual = true;
            break;
          }
        }
        if (isManual) {
          item.creationType = EstimateCreationType.Manual;
        } else {
          item.creationType = EstimateCreationType.Auto;
        }
        return item;
      });
    }

    estimateData = { ...estimateData, esIq5Info: updatedData };
    if (estimateData) {
      //タブ切替で再計算
      let calEstimateInfo = updateEstimateInfoByReCal(estimateData, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
      if (calEstimateInfo) {
        // 一覧の情報を反映
        setIQ5DataLst(calEstimateInfo?.esIq5Info);
        calEstimateInfo?.esIq5Info?.map((item) => {
          if (item.no === selectedIQ5EstimateData?.no) {
            // 選択中の部品情報を反映
            setSelectedData(item);
          }
        });
      }
      props.updateEstimateInfo(estimateData);
      props.selectedEstimateData.estimateProducts = estimateData?.estimateProducts;
      props.selectedEstimateData.esIq5Info = estimateData?.esIq5Info;
      setSelectedEstimateData(estimateData);
      return estimateData;
    }
  };

  const confirmOk = (e) => {
    setIsOpenProcessDetail(false);
    setConfirmMsgOpen(false);
    selectedData.processInput = selectedProcessInput;
    selectedData.additions = selectedProcessAdditions;
    getAllEstimateIq5Info();
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
    // props.childEditMode(false);
  };

  // 工程選択画面の工程リストを選択した場合、
  const updateSelectedData = (selectedPartNo) => {
    getAllEstimateIq5Info(); // タブの切り替えで入力中の情報を保持するようにする
    let selectedData = iQ5DataLst?.filter((item) => item.no == selectedPartNo);
    setCurrentRecordNo(selectedPartNo);
    setSelectedData(selectedData?.length > 0 ? selectedData[0] : iQ5DataLst[0]);
    scrollTo(selectedPartNo);
  };

  // 工程選択画面のイベント変更により呼出
  const updateProcessSelectOfSelectedData = (processSelect) => {
    setSelectedProcessSelect(processSelect);
  };

  const iq5KihonFormValidation = () => {
    let validation = iq3DetailRef?.current?.formValidation;
    return validation;
  };

  useImperativeHandle(ref, () => ({
    getIQ3EditMode: () => {
      return editMode;
    },
    getIQ5EstimateDetailInfo: getAllEstimateIq5Info,
    getEstimateIq3Info: () => {
      return iQ5DataLst;
    },
    iq5KihonFormValidation: iq5KihonFormValidation,
    getIQ3ActiveTabKey: () => {
      return activeTabKey;
    },
    updateiq5DataByBulkEdit: updateiq5DataByBulkEdit,
    getNewIQ5EstimateInfo: getNewIQ5EstimateInfo,
    updateiq5ActionForCal: updateiq5ActionForCal,
  }));

  // 部品追加ボタンの押下
  const addPart = async () => {
    let iq5Data = [...iq5EstimateInitInfo];
    var copyPart = getNewIQ5EstimateInfo(JSON.parse(JSON.stringify(iq5Data)));
    if (copyPart?.length > 0) copyPart = copyPart[0];
    if (props?.selectedEstimateData?.esIq5Info != undefined && props?.selectedEstimateData?.esIq5Info?.length > 0) {
      let tempIQ3List = [...props?.selectedEstimateData?.esIq5Info];
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart = {
        ...copyPart,
        no: lastRecord?.no + 1,
        name: 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5),
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
      let tempiQ5DataLst = [...iQ5DataLst];
      tempiQ5DataLst?.push(copyPart);
      setIQ5DataLst(tempiQ5DataLst);
      props?.selectedEstimateData?.esIq5Info?.push(copyPart);
    } else {
      copyPart = {
        ...copyPart,
        no: 1,
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
      let tempiQ5DataLst = [...iQ5DataLst];
      tempiQ5DataLst?.push(copyPart);
      setIQ5DataLst(tempiQ5DataLst);
      props?.selectedEstimateData?.esIq5Info?.push(copyPart);
    }
    setIsAddPart(true);
    setSelectedEstimateData(props.selectedEstimateData);
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
    // 部品追加により再計算
    setReCalByAddDelete(true);
    props.updateIsRecal(true);
  };

  // 部品削除ボタンの押下
  const deletePart = () => {
    if (iQ5DataLst?.length <= 1) return;
    let deletePart =
      props?.userSettingInfo?.[0]?.massages?.deletePart == undefined
        ? true
        : props?.userSettingInfo?.[0]?.massages?.deletePart;
    setIsDelete(deletePart);
    if (!deletePart) {
      deleteOk();
    }
  };

  // 削除ポップアップのOKボタンの押下
  const deleteOk = () => {
    setIsDelete(false);
    let tempIQ3List = [...iQ5DataLst];
    tempIQ3List = tempIQ3List?.filter((item) => item.no !== currentRecordNo);
    tempIQ3List = tempIQ3List?.map((item, index) => {
      return { ...item, no: index + 1 };
    });
    setIQ5DataLst(tempIQ3List);
    setSelectedEstimateData((prev) => ({ ...prev, esIq5Info: tempIQ3List }));
    if (tempIQ3List?.length > 0) {
      setCurrentRecordNo(tempIQ3List[0]?.no);
      setSelectedData(tempIQ3List[0]);
    }
    if (props.selectedEstimateData.esIq5Info) {
      props.selectedEstimateData.esIq5Info = tempIQ3List;
    }
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
    // 部品削除により再計算
    setReCalByAddDelete(true);
    props.updateIsRecal(true);
  };

  // 削除ポップアップのCancelボタンの押下
  const deleteCancel = () => {
    setIsDelete(false);
  };

  // 部品複写追加ボタンの押下(IQX_WEBEST-311 形鋼子部品の「部品複写追加」機能の追加)
  const addCopyPart = async () => {
    let tempIQ3List = JSON.parse(JSON.stringify(props?.selectedEstimateData?.esIq5Info));
    var copyPart = tempIQ3List?.filter((item) => item.no === currentRecordNo);
    if (tempIQ3List != undefined && tempIQ3List?.length > 0 && copyPart?.length > 0) {
      copyPart = JSON.parse(JSON.stringify(copyPart[0]));
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart.no = lastRecord?.no + 1;
      copyPart.drawingNo = 'Part_' + formatDateString(todayTime()); // IQX_WEBEST-311 部品複写機能で新たに追加された子部品の子部品図番と子部品名称は、新規子部品追加時と同様
      copyPart.name = 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5); // IQX_WEBEST-311 部品複写機能で新たに追加された子部品の子部品図番と子部品名称は、新規子部品追加時と同様
      let tempiQ5DataLst = [...iQ5DataLst];
      tempiQ5DataLst?.push(copyPart);
      setIQ5DataLst(tempiQ5DataLst);
      props?.selectedEstimateData?.esIq5Info?.push(copyPart);
      setIsAddPart(true);
      setSelectedEstimateData(props.selectedEstimateData);
      calculateChildPartCount();
      calculateWeightAreaForPaSurface();
      // 部品複写により再計算
      setReCalByAddDelete(true);
      props.updateIsRecal(true);
    }
  };

  const calculateChildPartCount = () => {
    let childpart = 0;
    if (props?.selectedEstimateData?.esIq5Info?.length > 0) {
      childpart = props?.selectedEstimateData?.esIq5Info?.reduce((total, item) => {
        return total + parseInt(item.partCount);
      }, 0);
    }
    if (props?.selectedEstimateData?.estimateProducts?.processInput != undefined) {
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount = childpart;
      // IQX_WEBEST-179
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = childpart;
    }
  };

  const calculateWeightAreaForPaSurface = () => {
    let totalWeight = 0;
    let totalArea = 0;
    totalWeight = props?.selectedEstimateData?.esIq5Info?.reduce((total, item) => {
      return total + parseFloat(item.weight * Number(item.partCount)); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
    }, 0);
    totalArea = props?.selectedEstimateData?.esIq5Info?.reduce((total, item) => {
      return total + parseFloat(item.totalSurfaceArea * Number(item.partCount)); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
    }, 0);
    if (totalWeight === 0 && totalArea === 0) {
    } else {
      if (props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.length > 0) {
        let parentSurfaceData =
          props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.map((paSurface) => {
            return {
              ...paSurface,
              areaUnitPrice: parseFloat(toDecimal(totalArea, 2)),
              weightUnitPrice: parseFloat(toDecimal(totalWeight, 2)),

              areaAutoExtract: parseFloat(toDecimal(totalArea, 2)),
              weightAutoExtract: parseFloat(toDecimal(totalWeight, 2)),
              totalArea: parseFloat(toDecimal(totalArea * paSurface?.areaCount, 2)),
              totalWeight: parseFloat(toDecimal(totalWeight * paSurface?.weightCount, 2)),
            };
          });
        props.selectedEstimateData.estimateProducts.processInput.surfaceProcessInputInfo = parentSurfaceData;
      }
    }
  };

  // 上方向ボタンの押下
  const upRowSelect = () => {
    let tempIQ3List = [...iQ5DataLst];
    let no = selectedData?.no - 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setCurrentRecordNo(record[0]?.no);
      setSelectedData(record[0]);
      scrollTo(record[0]?.no);
    }
  };

  // 下方向ボタンの押下
  const downRowSelect = () => {
    let tempIQ3List = [...iQ5DataLst];
    let no = selectedData?.no + 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setCurrentRecordNo(record[0]?.no);
      setSelectedData(record[0]);
      scrollTo(record[0]?.no);
    }
  };

  // タブの切り替え
  const onChange = (key) => {
    setSelectedTabKey(key);
    onChangeTab(key);
    props?.updateIq3ActiveTabKey(key);
  };

  // タブの切り替え
  const onChangeTab = (key) => {
    setActiveTabKey(key);
    if (key === '2') {
      setMdalTitleTxt('部品詳細');
      setDetailTabModalWidth(1300);
    } else if (key === '3') {
      setMdalTitleTxt('購入品');
      setDetailTabModalWidth(1300);
    }
  };

  // 材料明細モデルを表示
  const showSteelMaterialList = () => {
    setIsSteelMaterialListModalVisible(true);
  };

  // 基本情報タブのOKボタン押下
  const detailDialogOk = () => {
    setIsDialogOk(true);
    if (!detailProcessSelect) setActiveTabKey('0');
    if (activeTabKey === '1' || detailProcessSelect) {
      if (selectedData && Object.keys(selectedProcessSelect)?.length > 0) {
        selectedData.processSelect = selectedProcessSelect;
      }
    }
    if (activeTabKey === '3') {
      if (selectedData && purchaseModifiedData?.length > 0) {
        selectedData.purchases = purchaseModifiedData;
      }
    }
    // props.updateIsRecal(true);
    setDetailProcessSelect(false);
  };

  const calMaterialAndSteelCut = (materialInfo) => {
    let curMaterialInfo = materialInfo;
    // 切断工程が有効の場合、切断計算より材料費が計算される。
    let processSelect = selectedData.processSelect?.details?.filter(
      (item) => item.isUsedForCalc && item.workType === WorkType.PaSteelCut
    );
    if (processSelect?.length > 0) {
    } else {
      // 切断工程がない場合、材料費を計算する。
      // 材料寸法計算
      let materialSize = selectedData?.size;
      selectedData.materialSize = materialSize;
      // 材料重量計算 （重量＝単位重量×寸法/1000）
      let materialWeight = (curMaterialInfo?.info?.kgPerM * materialSize) / 1000;
      //テスト
      selectedData.materialUnitType = 2;
      // 形鋼子部品数
      let iq5_manufacturingCnt = selectedData?.partCount * props.selectedEstimateData?.estimateProducts?.quantity;
      let upSelectedData = calculateIQ5Material(
        selectedData,
        materialSize,
        selectedData.materialUnitType,
        selectedData.materialUnit,
        selectedData.materialQuantity,
        materialWeight,
        curMaterialInfo,
        iq5_manufacturingCnt
      );
      setSelectedData(upSelectedData);
      props.selectedEstimateData.esIq5Info?.map((item) => {
        if (item.id === selectedData?.id) {
          item = upSelectedData;
        }
      });
    }
  };

  // 材料明細モデルのOKボタン押下
  const steelDetailDialogOk = () => {
    setIsDialogOk(true);
    if (!isSteelMaterialListModalVisible) setActiveTabKey('0');
    if (isSteelMaterialListModalVisible) {
      if (selectedData && Object.keys(selectedSteelSelect)?.length > 0) {
        selectedData.steelShapeId = selectedSteelSelect?.steelShapeId;
        selectedData.steelShapeName = selectedSteelClassList?.filter(
          (item) => item.id === selectedSteelSelect?.steelShapeId
        )?.[0]?.name;
        selectedData.materialTypeIq5Id = selectedSteelSelect?.materialTypeIq5Id;
        selectedData.materialTypeName = selectedSteelTypeList?.filter(
          (item) => item.id === selectedSteelSelect?.materialTypeIq5Id
        )?.[0]?.name;
        selectedData.materialName = selectedSteelSelect?.name;
        selectedData.materialIq5Id = selectedSteelSelect?.id;
        selectedData.imageId = selectedSteelClassList?.filter(
          (item) => item.id === selectedSteelSelect?.steelShapeId
        )?.[0]?.shapeNo;
      }
    }
    // 材料費計算と切断工程計算
    let curMaterialIq5 = estimateParameters?.materialIq5?.find(
      (item) => item.id === selectedSteelSelect?.id && item?.info?.isUsed
    );
    calMaterialAndSteelCut(curMaterialIq5);
    setIq5ActionForCal(true);
    props.updateIsRecal(true);
    // props.updateIsRecal(true);
    setIsSteelMaterialListModalVisible(false);
  };

  // 材料明細モデルのCancelボタン押下
  const steelDetailDialogCancel = () => {
    setIsDialogOk(false);
    if (!isSteelMaterialListModalVisible) setActiveTabKey('0');
    setIsSteelMaterialListModalVisible(false);
  };

  // 購入品を登録する
  const updatePurchases = (modifiedData) => {
    setPurchaseModifiedData(modifiedData);
  };

  // 基本情報タブのCancelボタン押下
  const detailDialogCancel = () => {
    setIsDialogOk(false);
    if (!detailProcessSelect) setActiveTabKey('0');
    setDetailProcessSelect(false);
  };

  const updateiq5ActionForCal = (flg) => {
    setIq5ActionForCal(flg);
  };

  const updateDetailProcessSelect = (flg) => {
    setDetailProcessSelect(flg);
  };

  // 材料明細データを更新
  const updateSteelSelect = (record, classList, TypeList) => {
    setSelectedSteelSelect(record);
    setSelectedSteelClassList(classList);
    setSelectedSteelTypeList(TypeList);
  };
  const onChangeOpenProcessDetail = (flg, workType) => {
    setIsOpenProcessDetail(flg);
    setOpenWorkType(workType);
    let processTabItems = [];
    processMasterInfo?.map((item, i) => {
      processTabItems.push({
        key: i + 1,
        label: item.name,
      });
    });
    setProcessTabItems(processTabItems);
    let process = processMasterInfo?.filter((item) => item.workType === workType)?.[0];
    setProcessTabItemsModalTitleTxt(process ? process?.name : '');
    let processAdditions = JSON.parse(JSON.stringify(selectedData?.additions));
    setSelectedProcessAdditions(processAdditions);
    let processInput = JSON.parse(JSON.stringify(selectedData?.processInput));
    setSelectedProcessInput(processInput);
  };

  // 工程詳細タブのCancelボタン押下
  const processDetailDialogCancel = () => {
    setIsOpenProcessDetail(false);
  };

  const changeProcessTabItemsModalTitleTxt = (title) => {
    setProcessTabItemsModalTitleTxt(title);
  };

  const changeSelectedProcessInputAdditions = (additions, processInput) => {
    setSelectedProcessAdditions(additions);
    setSelectedProcessInput(processInput);
  };

  const kouteiCalculateDialogOk = () => {
    setConfirmMsgOpen(true);
  };

  const kouteiCalculateDialogCancel = () => {
    setIsOpenProcessDetail(false);
  };

  const changeWorkType = (workType) => {
    setOpenWorkType(workType);
  };

  const menuIconList = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: '6%',
      }}
    >
      <Row style={{ marginTop: '0px', marginLeft: '0', width: '-webkit-fill-available' }}>
        <Col span={24}>
          <Space size="middle">
            <Tooltip title="部品追加" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  addPart();
                }}
              >
                <Image preview={false} width={25} src={child_add} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            {/** IQX_WEBEST-311 板金子部品の「部品複写追加」機能の追加 */}
            <Tooltip title="部品複写追加" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  addCopyPart();
                }}
              >
                <Image preview={false} width={25} src={copy_sm}></Image>
              </a>
            </Tooltip>
            <Tooltip title="部品削除" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  deletePart();
                }}
              >
                <Image preview={false} width={23} src={child_delete} style={{ marginBottom: '1px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="Up" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  upRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={up_Arrow}
                  style={{ marginLeft: '5px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
            <Tooltip title="Down" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  downRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={down_Arrow}
                  style={{ marginLeft: '5px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
            <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={props?.updateAll}>
                <Image preview={false} width={25} src={edit_list} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '1' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('1');
                }}
              >
                <FileDoneOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '1' ? 'white' : '#4a4747', fontWeight: 'bold' }}>工程選択</label>
              </Button>
            </div>
            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '2' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('2');
                }}
              >
                <MoneyCollectOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '2' ? 'white' : '#4a4747', fontWeight: 'bold' }}>部品詳細</label>
              </Button>
            </div>
            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '3' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('3');
                }}
              >
                <ShoppingOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '3' ? 'white' : '#4a4747', fontWeight: 'bold' }}>購入品</label>
              </Button>
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  );

  const childPartListHeader = (
    <div
      style={{
        display: 'flex',
        height: 27,
      }}
    >
      <Space size="small">
        <p
          style={{
            fontSize: '13px',
            fontWeight: 'bold',
            margin: 0,
            paddingLeft: 7,
            color: 'black',
          }}
        >
          部品リスト：
        </p>
        <div style={{ display: 'flex', marginLeft: '0px', marginTop: '1.3px' }}>
          {' '}
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 3,
              paddingRight: 3,
              color: '#4A4747',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#4A4747',
              backgroundColor: '#fcfade',
              borderRadius: 3,
            }}
          >
            選択中
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              paddingLeft: 10,
              margin: 0,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined && selectedData.no != undefined ? 'No.' + selectedData.no : 'No.0'}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              color: 'black',
            }}
          >
            /
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined ? selectedData.drawingNo : 0}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              color: 'black',
            }}
          >
            /
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined ? selectedData.name : 0}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              color: 'black',
            }}
          >
            /
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined ? selectedData.partCount : 0}
          </p>
        </div>
      </Space>
    </div>
  );

  const modalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  const iq5Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      className: 'cm-a-right',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '状態',
      dataIndex: 'creationType',
      width: 80 + 'px',
      align: 'center',
      render: (value) =>
        value === EstimateCreationType.Auto ? (
          <Image src={auto} alt="" width={31} preview={false} />
        ) : (
          <Image src={manual} alt="" width={31} preview={false} />
        ),
    },
    {
      id: '3',
      title: '画像',
      dataIndex: 'imageId',
      width: 120 + 'px',
      align: 'center',
      render: (value) => (
        <img
          className={value === undefined || value === '' ? 'pmx-no-img' : 'steel-shape-img'}
          src={STEEL_SHAPE_URL + value + PNG}
          alt=""
        />
      ),
    },
    {
      id: '4',
      title: ['工程パターン', ' / ', '子部品図番', ' / ', '子部品名称'],
      dataIndex: 'drawingNoName',
      width: 280 + 'px',
      align: 'center',
      render: (_, record, index) => (
        <div style={{ textAlign: 'left' }}>
          <label style={{ fontSize: '13px' }}>{record?.processSelect?.name}</label>
          <br />
          <Input
            style={{
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
              marginTop: 8,
            }}
            className="input-editable"
            name="drawingNo"
            value={record.drawingNo}
            onChange={(e) => {
              if (record.drawingNo !== e.target.value) {
                let tempIQ3List = [...iQ5DataLst];
                tempIQ3List[index].drawingNo = e.target.value;
                setIQ5DataLst(tempIQ3List);
                props.selectedEstimateData.esIq5Info = tempIQ3List;
              }
            }}
          />
          <br />
          <Input
            style={{
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
              marginTop: 8,
            }}
            className="input-editable"
            name="name"
            value={record.name}
            onChange={(e) => {
              if (record.name !== e.target.value) {
                let tempIQ3List = [...iQ5DataLst];
                tempIQ3List[index].name = e.target.value;
                setIQ5DataLst(tempIQ3List);
                props.selectedEstimateData.esIq5Info = tempIQ3List;
              }
            }}
          />
          <br />
        </div>
      ),
    },
    {
      id: '5',
      title: ['鋼材', ' / ', '材料', ' / ', '材質'],
      dataIndex: 'materialItems',
      width: 200 + 'px',
      align: 'center',
      render: (_, record) =>
        estimateParameters ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px' }}>{record.steelShapeName}</label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 8 }}>{record.materialName}</label>
            <Row>
              <Col span={18}>
                <label style={{ fontSize: '13px', marginTop: 8 }}>{record.materialTypeName}</label>
              </Col>
              <Col span={6}>
                <div style={{ marginTop: 8, textAlign: 'left' }}>
                  <Button className={'iq5-material-select-btn'} onClick={showSteelMaterialList}>
                    選択
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: '6',
      title: ['寸法', ' / ', '員数'],
      dataIndex: 'partCount',
      width: 150 + 'px',
      align: 'center',
      render: (_, record, index) => (
        <div style={{ textAlign: 'center' }}>
          <Input
            style={{
              textAlign: 'left',
              width: 100,
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
            }}
            className="input-editable"
            name="size"
            value={record.size}
            onChange={(e) => {
              if (record.size !== e.target.value) {
                let tempIQ3List = [...iQ5DataLst];
                tempIQ3List[index].size = e.target.value ? Number(e.target.value) : 0;
                setIQ5DataLst(tempIQ3List);
                setIsPartCountChange(true);
              }
            }}
            onBlur={(e) => {
              // 員数はフォーカス離す、タブを離れることで自動計算される。
              if (isPartCountChange) {
                setIsPartCountChange(false);
                // 材料費計算と切断工程計算
                let curMaterialIq5 = estimateParameters?.materialIq5?.find(
                  (item) => item.id === selectedData?.materialIq5Id && item?.info?.isUsed
                );
                calMaterialAndSteelCut(curMaterialIq5);
                setIq5ActionForCal(true);
                props.updateIsRecal(true);
              }
            }}
            onPressEnter={(e) => {
              // 員数はEnterキーを押すことで自動計算される。
              if (isPartCountChange) {
                setIsPartCountChange(false);
                // 材料費計算と切断工程計算
                let curMaterialIq5 = estimateParameters?.materialIq5?.find(
                  (item) => item.id === selectedData?.materialIq5Id && item?.info?.isUsed
                );
                calMaterialAndSteelCut(curMaterialIq5);
                setIq5ActionForCal(true);
                props.updateIsRecal(true);
              }
            }}
          />
          <br />
          <Input
            style={{
              textAlign: 'left',
              width: 100,
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
              marginTop: 8,
            }}
            className="input-editable"
            name="partCount"
            value={record.partCount}
            onChange={(e) => {
              if (record.partCount !== e.target.value) {
                let tempIQ3List = [...iQ5DataLst];
                tempIQ3List[index].partCount = e.target.value ? Number(e.target.value) : 1;
                setIQ5DataLst(tempIQ3List);
                setIsPartCountChange(true);
              }
            }}
            onBlur={(e) => {
              // 員数はフォーカス離す、タブを離れることで自動計算される。
              if (isPartCountChange) {
                setIsPartCountChange(false);
                setIq5ActionForCal(true);
                props.updateIsRecal(true);
              }
            }}
            onPressEnter={(e) => {
              // 員数はEnterキーを押すことで自動計算される。
              if (isPartCountChange) {
                setIsPartCountChange(false);
                setIq5ActionForCal(true);
                props.updateIsRecal(true);
              }
            }}
          />
          {/* <label style={{ fontSize: '13px', marginLeft: 5 }}>
            {'(' + record.partCount * props?.selectedEstimateData?.estimateProducts?.quantity + ')'}
          </label> */}
        </div>
      ),
    },
    {
      id: '7',
      title: ['重量', ' / ', '面積'],
      dataIndex: 'amountItems',
      width: 150 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.weight ? toDecimal_place3(record.weight, 3) : toDecimal_place3(0, 3)}
            kg
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 10 }}>
            {record.totalSurfaceArea != undefined ? Math.round(record.totalSurfaceArea) : 0}
            cm<sup>2</sup>
          </label>
        </div>
      ),
    },
    {
      id: '8',
      title: ['材料', ' / ', '製造', ' / ', '購入品'],
      dataIndex: 'amountItems',
      width: 200 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.materialCostUnitPrice ? JPYs.format(Math.round(record.materialCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.workCostUnitPrice ? JPYs.format(Math.round(record.workCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.purchaseUnitPrice ? JPYs.format(Math.round(record.purchaseUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: '原価',
      dataIndex: 'costUnitPrice',
      width: 200 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costUnitPrice ? JPYs.format(Math.round(record.costUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '10',
      title: '原価合計',
      dataIndex: 'costTotalPrice',
      width: 200 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costTotalPrice ? JPYs.format(Math.round(record.costTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
  ];

  const detailModalTitle = (
    <div
      style={{
        width: detailTabModalWidth,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  const detail_ProcessSelect_ModalTitle = (
    <div
      style={{
        width: 1200,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{detailProcessSelect ? '工程選択' : '材料明細'}</p>
    </div>
  );

  const detail_Process_Calculate_ModalTitle = (
    <div
      style={{
        width: 1200,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{processTabItemsModalTitleTxt}</p>
    </div>
  );

  const confirmContent = <p style={{ fontSize: '13.5px' }}>入力工程で再計算します。よろしいでしょうか。</p>;

  return (
    <>
      <Spin
        spinning={isDialogOk || reCalByAddDelete || iq5ActionForCal || props?.activePartMenu !== '3' ? true : false}
        tip=""
        size="large"
      >
        {/* {props?.activePartMenu === '2' ? ( //見積保存の時、形鋼の基本情報をRefをアクセスできないため、この条件を解除して子JSごとに条件をいれる。 */}
        <>
          <Form>
            {/* 一覧とプロパティ表示 */}{' '}
            <div
              style={{
                height: '90.8vh',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            >
              <Row id="iq3-rows" style={{ width: '99.5%' }}>
                <Col span={24}>{childPartListHeader}</Col>
              </Row>
              {menuIconList}
              <div id="iq3-list-div" className="iq3-list-div">
                <Table
                  id="iq3EstimateTbl"
                  showSorterTooltip={false}
                  rowClassName={(record) => (record.no == currentRecordNo ? 'active-row' : 'data-row')}
                  columns={iq5Columns}
                  rowKey={(record) => record.no}
                  dataSource={iQ5DataLst}
                  className="iq3-list-tb"
                  pagination={false}
                  scroll={{ y: 717, x: '10vw' }}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                />
              </div>
              {/* ダイアログOKで再計算するための設置、条件付けで実行 */}
              <div style={{ display: 'none' }} className="iq3-tab-div">
                {/* 購入品 */}
                <IQ5Purchase
                  ref={purchaseRef}
                  selectedIQ5DataDetail={selectedData}
                  selectedEstimateData={props.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                  // editMode={editMode}
                  isChildPart={true}
                  activeTabKey={activeTabKey}
                  currentRecordNo={currentRecordNo}
                  isReCal={props?.isReCal}
                  dialogOk={isDialogOk}
                />
              </div>
              {/* 工程別合計 */}
              <div
                style={{
                  display: 'flex',
                  marginTop: '0px',
                  position: 'absolute',
                  top: '-36px',
                  right: 0,
                }}
              >
                <KouteiSuryouBetsuBtn
                  selectedEstimateData={props?.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                />
              </div>
            </div>
          </Form>

          {/** 編集中破棄確認ポップアップ */}
          {editModeCancelConfirm
            ? commonModal(
                editModeCancelConfirm,
                confirmScreenChangeTitle,
                commonFooter(discardConfirmOk, discardConfirmCancel),
                null,
                400,
                discardConfirmOk,
                discardConfirmCancel,
                confirmScreenChangeContent,
                null,
                null,
                false
              )
            : ''}
          {/** 更新 / 破棄の確認メッセージ */}
          {/* {confirmMsgOpen
            ? commonModal(
                confirmMsgOpen,
                modalTitle,
                commonFooter(confirmOk, confirmCancel),
                null,
                400,
                confirmOk,
                confirmCancel,
                discardMode ? confirmDiscardContent : confirmUpdateContent,
                null,
                null,
                false
              )
            : ''} */}

          {/* 削除 */}
          {isDelete
            ? commonModal(
                isDelete,
                confirmModalTitle,
                commonDeleteFooter(deleteOk, deleteCancel),
                null,
                400,
                deleteOk,
                deleteCancel,
                /*  editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent, */
                <p style={{ fontSize: '13.5px' }}>
                  選択中の子部品を削除します。
                  <br></br>削除したデータは戻せませんが、よろしいですか？
                </p>,
                null,
                null,
                false
              )
            : ''}

          {/* 工程選択ダイアログ */}
          {activeTabKey === '1' || detailProcessSelect ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '1' || detailProcessSelect ? true : false}
              title={detail_ProcessSelect_ModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1200}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 150 }}
              centered={true}
              modalRender={(modal) => (
                <Draggable handle=".ant-modal-header">
                  <div>{modal}</div>
                </Draggable>
              )}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                {!isSteelMaterialListModalVisible ? (
                  <IQ5KouteiSentaku
                    type={'list'}
                    ref={iq5KouteiSentakuRef}
                    editMode={editMode}
                    selectediq5DataDetail={selectedData}
                    // selectedEstimateData={props.selectedEstimateData}
                    selectedDataDetail={props.selectedEstimateData}
                    iQ5DataLst={iQ5DataLst}
                    pmxMode={false}
                    updateSelectedData={updateSelectedData}
                    processMasterData={processMasterInfo}
                    deviceMasterData={deviceMasterInfo}
                    clientMasterData={clientMasterInfo}
                    currentRecordNo={currentRecordNo}
                    activeTabKey={activeTabKey}
                    isReCal={props?.isReCal}
                    updateProcessSelectOfSelectedData={updateProcessSelectOfSelectedData}
                    tabOpen={activeTabKey === '1' || detailProcessSelect ? true : false}
                  />
                ) : (
                  <SteelMaterialList
                    fromEstimate={true}
                    updateSteelSelect={updateSteelSelect}
                    loading={props?.loading} // Waitingダイアログ
                  />
                )}
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 部品詳細ダイアログ */}
          {activeTabKey === '2' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '2' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '75vh', marginLeft: -17 }}
              width={1300}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              // style={{ top: 85, left: 215 }}
              centered={true}
              modalRender={(modal) => (
                <Draggable handle=".ant-modal-header">
                  <div>{modal}</div>
                </Draggable>
              )}
            >
              <div className="iq3-tab-div" style={{ height: '73vh', overflowY: 'auto' }}>
                <IQ5EstimateDetail
                  ref={iq5DetailRef}
                  processMasterData={processMasterInfo}
                  // editMode={editMode}
                  selectedIQ5DataDetail={selectedData}
                  materialList={materialLst}
                  currentRecordNo={currentRecordNo}
                  activeTabKey={activeTabKey}
                  selectedEstimateData={props.selectedEstimateData}
                  isReCal={props?.isReCal}
                  tabOpen={activeTabKey === '2' ? true : false}
                  deviceMasterInfo={deviceMasterInfo}
                  updateDetailProcessSelect={updateDetailProcessSelect}
                  showSteelMaterialList={showSteelMaterialList}
                  onChangeOpenProcessDetail={onChangeOpenProcessDetail}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk}>
                  閉じる
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 購入品ダイアログ */}
          {activeTabKey === '3' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '3' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1300}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 205 }}
              centered={true}
              modalRender={(modal) => (
                <Draggable handle=".ant-modal-header">
                  <div>{modal}</div>
                </Draggable>
              )}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                <IQ5Purchase
                  ref={purchaseRef}
                  selectediq5DataDetail={selectedData}
                  selectedEstimateData={props.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                  // editMode={editMode}
                  isChildPart={true}
                  activeTabKey={activeTabKey}
                  currentRecordNo={currentRecordNo}
                  isReCal={props?.isReCal}
                  tabOpen={activeTabKey === '3' ? true : false}
                  updatePurchases={updatePurchases}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}

          {/* 材料明細モデル */}
          {isSteelMaterialListModalVisible ? (
            <Modal
              maskClosable={false}
              open={isSteelMaterialListModalVisible ? true : false}
              title={detail_ProcessSelect_ModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1200}
              onOk={steelDetailDialogOk}
              onCancel={steelDetailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 150 }}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflow: 'hidden' }}>
                <SteelMaterialList
                  fromEstimate={true}
                  updateSteelSelect={updateSteelSelect}
                  loading={props?.loading} // Waitingダイアログ
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button
                  className="mainButton"
                  onClick={isSteelMaterialListModalVisible ? steelDetailDialogOk : detailDialogOk}
                  style={{ marginRight: 5 }}
                >
                  OK
                </Button>
                <Button
                  className="cancelButton"
                  onClick={isSteelMaterialListModalVisible ? steelDetailDialogCancel : detailDialogCancel}
                >
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 工程詳細ダイアログ */}
          {isOpenProcessDetail ? (
            <Modal
              id="process-tbl-drag"
              maskClosable={false}
              open={isOpenProcessDetail}
              title={detail_Process_Calculate_ModalTitle}
              footer={null}
              bodyStyle={{ height: '65vh', marginLeft: -17 }}
              width={1200}
              onCancel={processDetailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              centered={true}
              modalRender={(modal) => (
                <Draggable handle=".ant-modal-header">
                  <div>{modal}</div>
                </Draggable>
              )}
            >
              <IQ5KouteiCalculate
                ref={kouteiCalculateRef}
                isOpenProcessDetail={isOpenProcessDetail}
                processTabItems={processTabItems}
                workType={openWorkType}
                processMasterInfo={processMasterInfo}
                deviceMasterInfo={deviceMasterInfo}
                selectedIQ5DataDetail={selectedData}
                selectedProcessInput={selectedProcessInput}
                selectedProcessAdditions={selectedProcessAdditions}
                changeProcessTabItemsModalTitleTxt={changeProcessTabItemsModalTitleTxt}
                changeSelectedProcessInputAdditions={changeSelectedProcessInputAdditions}
                selectedEstimateData={props.selectedEstimateData}
                estimateParameters={estimateParameters}
                kouteiCalculateDialogOk={kouteiCalculateDialogOk}
                kouteiCalculateDialogCancel={kouteiCalculateDialogCancel}
                changeWorkType={changeWorkType}
              />
            </Modal>
          ) : (
            ''
          )}
          {/** 更新 / 破棄の確認メッセージ */}
          {confirmMsgOpen
            ? commonModal(
                confirmMsgOpen,
                confirmModalTitle,
                commonFooter(confirmOk, confirmCancel),
                null,
                400,
                confirmOk,
                confirmCancel,
                confirmContent,
                null,
                null,
                false
              )
            : ''}
        </>
      </Spin>
    </>
  );
});

export default IQ5Estimate;
