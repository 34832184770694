import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

import { handleKeyPress, regexExp } from '../common/Common';

const IQ3KouteiInput_Kensa = forwardRef((props, ref) => {
  const [numOfKensa, setNumOfKensa] = useState('');
  const [autoExtract, setAutoExtract] = useState(0); // IQX_WEBEST-179

  useEffect(() => {
    if (props?.tabOpen) {
      let data = props.inspectionInfo;
      if (data != undefined) {
        setNumOfKensa(data?.details?.[0]?.numOfCuts);
        setAutoExtract(data?.details?.[0]?.autoExtract); // IQX_WEBEST-179
      }
    }
  }, [props.inspectionInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  return (
    <>
      {props?.tabOpen && props?.isKensa ? (
        <Row className="mt-2 registerModal">
          <Table className="kouteiinput " style={{ width: '20%' }}>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: '0.1%' }} className="tbl-header">
                  No.
                </th>
                <th style={{ width: '17%' }} className="tbl-header">
                  検査箇所
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {numOfKensa === '' ? (
              <></>
            ) : (
              <> */}
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>1</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', verticalAlign: 'middle' }}>
                  {/* {editMode ? (
                      <> */}
                  {/** IQX_WEBEST-179 */}
                  <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{autoExtract}</label>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={numOfKensa}
                    onChange={(e) => {
                      if (regexExp.test(e.target.value)) {
                        e.target.value = 0;
                      }
                      setNumOfKensa(e.target.value);
                      props.inspectionInfo.details[0].numOfCuts = e.target.value;
                      props.updateInspection(props.inspectionInfo);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e, true);
                    }}
                    style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  ></Input>
                  {/* </>
                    ) : (
                      <>{numOfKensa}</>
                    )} */}
                </td>
              </tr>
              {/* </>
            )} */}
            </tbody>
          </Table>
        </Row>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_Kensa;
