import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input, Radio } from 'antd';

import { MaterialSurfaceBillingUnit } from '../common/enums';
import { ProcessDetailGroups, handleKeyPress, regexExp, toDecimal } from '../common/Common';

const IQ3KouteiInput_Hyoumenshori = forwardRef((props, ref) => {
  const [hyoumenshoriValues, setHyoumenshoriValues] = useState([]);
  const [surfaceWorkType, setSurfaceWorkType] = useState([]);
  const [processDetailsType, setProcessDetailsType] = useState([]);

  useEffect(() => {
    if (props.type === '親部品' || props.isReCal) {
      const surfaceDetailsData = props?.kouteiInputInfo?.surfaceProcessInputInfo;
      setHyoumenshoriValues(surfaceDetailsData);
    } else {
      if (props?.tabOpen) {
        let data = props?.surfaceInfo;
        if (data != undefined) {
          setHyoumenshoriValues(data?.details);
        }
      }
    }
    setSurfaceWorkType(ProcessDetailGroups);
    const processDetailTypes = props?.surfaceData?.[0]?.details?.processDetailTypes || [];
    setProcessDetailsType(processDetailTypes);
  }, [props.hyoumenShoriVal, props.surfaceData, props.kouteiInputInfo, props.type, props?.isReCal]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const changeDetails = (event, id, types, calMethod) => {
    const temp = JSON.parse(JSON.stringify(hyoumenshoriValues));
    let updatedData;
    if (types === 'calcType') {
      if (calMethod === 'area') {
        updatedData = temp?.map((row) => {
          if (row.no === id) {
            return { ...row, [types]: MaterialSurfaceBillingUnit.SquareMeter };
          }
          return row;
        });
      } else if (calMethod === 'weight') {
        updatedData = temp?.map((row) => {
          if (row.no === id) {
            return { ...row, [types]: MaterialSurfaceBillingUnit.Weight };
          }
          return row;
        });
      }
    } else {
      updatedData = temp?.map((row) => {
        if (row.no === id) {
          const updatedRow = { ...row, [types]: !regexExp.test(event.target.value) ? event.target.value : 0 };
          if (types === 'areaUnitPrice' || types === 'areaCount') {
            // Calculate and update totalCount
            updatedRow.totalArea = toDecimal(toDecimal(updatedRow?.areaUnitPrice, 2) * updatedRow?.areaCount, 2);
          } else if (types === 'weightUnitPrice' || types === 'weightCount') {
            // Calculate and update totalCount
            updatedRow.totalWeight = toDecimal(toDecimal(updatedRow?.weightUnitPrice, 2) * updatedRow?.weightCount, 2);
          }

          return updatedRow;
        }
        return row;
      });
    }
    setHyoumenshoriValues(updatedData);
    if (props.type === '親部品') {
      props.updatedSurfaceProcessInput(updatedData);
    } else {
      const newSurfaceInfo = { ...props.surfaceInfo };
      newSurfaceInfo.details = updatedData;
      props.updateSurface(newSurfaceInfo);
    }
  };

  const blurDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(hyoumenshoriValues));
    let updatedData;

    updatedData = temp?.map((row) => {
      if (row.no === id) {
        const updatedRow = { ...row, [types]: toDecimal(event.target.value, 2) };

        return updatedRow;
      }
      return row;
    });

    setHyoumenshoriValues(updatedData);
    if (props.type === '親部品') {
      props.updatedSurfaceProcessInput(updatedData);
    } else {
      const newSurfaceInfo = { ...props.surfaceInfo };
      newSurfaceInfo.details = updatedData;
      props.updateSurface(newSurfaceInfo);
    }
  };
  return (
    <>
      {props.type === '親部品' || (props?.tabOpen && props?.isHyoumenShori) ? (
        <Row className="mt-1 registerModal" align="middle">
          <Table className="kouteiinput" style={{ width: '87%' }}>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: '1%' }} className="tbl-header">
                  No
                </th>
                <th style={{ width: '17.4%' }} className="tbl-header">
                  計算方法
                </th>
                <th style={{ width: '19.3%' }} colSpan={2} className="tbl-header">
                  詳細
                </th>
                <th style={{ width: '19.3%' }} className="tbl-header">
                  面積/重量
                </th>
                {/* <th style={{ width: '13%', display: 'none' }} className="tbl-header">
                自動抽出
              </th> */}
                <th style={{ width: '19.3%' }} className="tbl-header">
                  回数
                </th>
                <th style={{ width: '11%' }} className="tbl-header">
                  合計
                </th>
              </tr>
            </thead>
            <tbody>
              {hyoumenshoriValues?.map((i, index) => (
                <>
                  <tr key={index} style={{ verticalAlign: 'middle' }}>
                    <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      {index + 1}
                    </td>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center', pointerEvents: 'none' }}>
                      <Radio
                        checked={
                          i.calcType === MaterialSurfaceBillingUnit.SquareMeter ||
                          i.calcType === MaterialSurfaceBillingUnit.Work
                        } // IQX_WEBEST-226
                        // onChange={(e) => changeDetails(e, i.no, 'calcType', 'area')}
                        // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      >
                        面積
                      </Radio>
                    </td>
                    <td rowSpan={2} style={{ verticalAlign: 'middle', width: '9.5%' }}>
                      <label>
                        {surfaceWorkType?.filter((item) => item.value === i.processDetailWorkTypeGroup)?.[0]?.label}
                      </label>
                    </td>

                    <td rowSpan={2} style={{ width: '9.5%' }}>
                      <label>
                        {processDetailsType?.filter((item) => item.id === i.processDetailTypeNo)?.[0]?.name}
                      </label>
                    </td>
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.areaAutoExtract}</label>
                      <Input
                        className="input-editable"
                        // value={toDecimal(i.areaUnitPrice, 2)}
                        value={i.areaUnitPrice}
                        onChange={(e) => changeDetails(e, i.no, 'areaUnitPrice')}
                        onBlur={(e) => blurDetails(e, i.no, 'areaUnitPrice')}
                        style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                      ></Input>
                    </td>
                    {/* <td style={{ display: 'none' }}>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.areaAutoExtract}
                      onChange={(e) => changeDetails(e, i.no, 'areaAutoExtract')}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input>
                  </td> */}
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                        {i.areaCountAutoExtract}
                      </label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.areaCount}
                        onChange={(e) => changeDetails(e, i.no, 'areaCount')}
                        style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                      ></Input>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <label style={{ marginRight: '5px' }}>{toDecimal(i.totalArea, 2)}</label>
                    </td>
                  </tr>
                  <tr style={{ verticalAlign: 'middle' }}>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center', pointerEvents: 'none' }}>
                      {/* <Radio checked={false}>重量</Radio> */}
                      <Radio
                        checked={i.calcType === MaterialSurfaceBillingUnit.Weight}
                        // onChange={(e) => changeDetails(e, i.no, 'calcType', 'weight')}
                      >
                        重量
                      </Radio>
                    </td>

                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                        {i.weightAutoExtract ? toDecimal(i.weightAutoExtract, 2) : toDecimal(0, 2)}
                      </label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.weightUnitPrice ? toDecimal(i.weightUnitPrice, 2) : toDecimal(0, 2)}
                        onChange={(e) => changeDetails(e, i.no, 'weightUnitPrice')}
                        onBlur={(e) => blurDetails(e, i.no, 'weightUnitPrice')}
                        style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                      ></Input>
                    </td>
                    {/* <td style={{ display: 'none' }}>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.weightAutoExtract}
                      onChange={(e) => changeDetails(e, i.no, 'weightAutoExtract')}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input>
                  </td> */}
                    <td>
                      {/** IQX_WEBEST-179 */}
                      <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                        {i.weightCountAutoExtract}
                      </label>
                      <Input
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        value={i.weightCount}
                        onChange={(e) => changeDetails(e, i.no, 'weightCount')}
                        style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                      ></Input>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <label style={{ marginRight: '5px' }}>{toDecimal(i.totalWeight, 2)}</label>
                      {/* <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.totalWeight}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input> */}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Row>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_Hyoumenshori;
