/**
 * クラス名：板金子部品工程選択
 * 説明：板金子部品工程選択ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';

import { initProcessSelect, patternPage } from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';
import { updateUserSetting } from '../common/CommonAPI';
import { ServiceClass, WorkType } from '../common/enums';

const IQ3KouteiSentaku = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState([]);
  const [kouteiSentakuPatternList, setKouteiSentakuPatternList] = useState([]);
  const [iq5List, setIQ5List] = useState([]);
  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);
  const [currentRecordIndex, setCurrentRecordIndex] = useState(0);

  const patternformRef = useRef();

  let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;

  useEffect(() => {
    if (props?.tabOpen || props?.pmxMode) {
      getProcesssPatternInfo();
      let iq5List = props.iQ5DataLst == undefined ? [] : props.iQ5DataLst;
      let iq5List_SelectBoxData = [];
      for (let i = 0; i < iq5List?.length; i++) {
        iq5List_SelectBoxData.push({ label: 'No.' + iq5List[i].no + '　' + iq5List[i].name, value: iq5List[i].no });
      }
      setIQ5List(iq5List_SelectBoxData);
      let curRowNo = props?.currentRecordNo ? props?.currentRecordNo : 1;
      for (let i = 0; i < props.selectedDataDetail?.esIq5Info?.length; i++) {
        if (props.selectedDataDetail?.esIq5Info[i]?.no === curRowNo) {
          setCurrentRecordIndex(i);
        }
      }
    }
  }, [
    props.selectedIQ3DataDetail,
    props.selectedDataDetail,
    props.type,
    props.currentRecordNo,
    props.activeTabKey,
    props?.isReCal,
    props.iQ5DataLst,
  ]);

  useEffect(() => {
    if (props?.tabOpen || props?.pmxMode) {
      setEditMode(props.editMode != undefined ? props.editMode : false);
    }
  }, [props.editMode]);

  const getProcesssPatternInfo = async () => {
    let kouteiPatternList = [];
    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.Beams);
    setListData(kouteiPatternList);
    setKouteiSentakuPatternList(kouteiPatternList);
    if (props.type === 'pmx') {
      if (props?.patternNo != 0) {
        const selectedPatternInfo = kouteiPatternList?.filter((item) => item.id == props.patternNo);
        setProcessSelectedData(selectedPatternInfo[0]);
        setKouteiSentakuPatternNo(selectedPatternInfo[0]?.id);
      } else {
        let esIq5Info = props?.selectedDataDetail?.esIq5Info;
        let processSelect = esIq5Info?.length > 0 ? esIq5Info[0]?.processSelect : undefined;
        if (processSelect) {
          setProcessSelectedData(processSelect);
          setKouteiSentakuPatternNo(processSelect.id);
        }
      }
    } else {
      let processSelect = props?.selectediq5DataDetail?.processSelect;
      setProcessSelectedData(processSelect);
      setKouteiSentakuPatternNo(processSelect?.id);
    }
    setDeviceMasterInfo(parameters?.device);
    setProcessMasterInfo(parameters?.process);
    setClientInfo(parameters?.client);
  };

  const updateKouteiSentakuInfo = async (selectedProcessPattern, list) => {
    setKouteiSentakuPatternNo(selectedProcessPattern?.id);
    setProcessSelectedData(selectedProcessPattern);
    setKouteiSentakuPatternList(list);
    if (props.type === 'pmx') {
      props.updateSelectedPatternNo(selectedProcessPattern?.id);
      const updatedLists = props.selectedDataDetail?.esIq5Info?.map((item) => ({
        ...item,
        processSelect: selectedProcessPattern,
      }));
      props.selectedDataDetail.esIq5Info = updatedLists;

      // props.updatedSelectedData(updatedLists);
    }

    //マイン見積データに板金工程選択更新
    if (props.selectedDataDetail.esIq5Info[currentRecordIndex]) {
      let currentPattern = JSON.parse(JSON.stringify({ ...props.selectedDataDetail.esIq5Info[currentRecordIndex] }));
      let processSelect = initProcessSelect(selectedProcessPattern);
      currentPattern.processSelect.id = processSelect?.id;
      currentPattern.processSelect.name = processSelect?.name;
      currentPattern.processSelect.details = processSelect?.details;
      currentPattern.processSelect.modified = processSelect?.modified;

      props.selectedDataDetail.esIq5Info[currentRecordIndex] = currentPattern;
    }

        // 現在の板金データに工程選択情報更新
        if (props.type !== 'pmx') {
          props.updateProcessSelectOfSelectedData(selectedProcessPattern);
          // IQX_WEBEST-323 新規処理の前回値の採用
          let userSetting = props.selectedDataDetail?.estimateProducts?.calcParameters?.settings?.userSettings;
          if (userSetting?.length > 0) userSetting = userSetting[0];
          if (userSetting !== null) {
            if (userSetting.users?.newSmInitialInfo?.iq3ProcessPatternId !== undefined) {
              userSetting.users.newSmInitialInfo.iq3ProcessPatternId = selectedProcessPattern.id;
              await updateUserSetting(userSetting, 0);
            }
          }
        }
  };

  const updateSelectedData = (selectedPart) => {
    props.updateSelectedData(selectedPart);
  };

  const updateIsPrepareInfoFlg = (flg) => {
    props?.updateIsPrepareInfoFlg(flg);
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
      getKouteiSentakuPatternList: () => {
        return kouteiSentakuPatternList;
      },
      getProcessSelectedData: () => {
        return processSelectedData;
      },
    }),
    [kouteiSentakuPatternNo, kouteiSentakuPatternList, processSelectedData]
  );

  return (
    <>
      {props.tabOpen ? (
        <div style={{ paddingTop: 0 }}>
          <ProcessPatternForm
            ref={patternformRef}
            selectedData={processSelectedData}
            listData={listData}
            editMode={props.editMode}
            page={patternPage[3]}
            isParameter={false}
            updateKouteiSentakuInfo={updateKouteiSentakuInfo}
            kouteiSentakuEditMode={editMode}
            iq5PartList={iq5List}
            childPartNo={props.selectedIQ5DataDetail != undefined ? props.selectedIQ5DataDetail.no : 1}
            updateSelectedData={updateSelectedData}
            pmxMode={props.pmxMode}
            processMasterData={processMasterInfo}
            deviceMasterData={deviceMasterInfo}
            clientMasterData={clientInfo}
            updateIsPrepareInfoFlg={updateIsPrepareInfoFlg}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiSentaku;
