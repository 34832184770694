import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  checkShowHideIcon,
  handleCollpse,
  handleKeyPress,
  JPYs,
  kouteiCalculateSelectedArr,
  toDecimal,
} from '../common/Common.js';
import { DataType, ServiceClass, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_BlankPunch = forwardRef((props, ref) => {
  const [isBlankingdetail, setIBlankingdetail] = useState(false);
  const [isSagyoDetail, setIsSagyoDetail] = useState(false);
  const [isDandoriDetail, setIsDandoriDetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [punchAmt, setPunchAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);

  // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
  const [isMoldCntDetail, setIsMoldCntDetail] = useState(false);

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      dandoriAmtSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });
    let totalSum =
      (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) +
      (dandoriAmtSum ? Math.round(dandoriAmtSum) : 0) +
      (editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole
        ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole)
        : 0) +
      (editDataArr[0].sagyoDetails.sagyoAmtJoint ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtJoint) : 0); // 画面表示用（四捨五入した金額を足す）
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    let totalTimeSum =
      sagyoTimeSec +
      dandoriTimeSec +
      editDataArr[0].sagyoDetails.sagyoTmTwoStepHole +
      editDataArr[0].sagyoDetails.sagyoTmJoint;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum:
        sagyoAmtSum +
        (editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole
          ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole)
          : 0) +
        (editDataArr[0].sagyoDetails.sagyoAmtJoint ? Math.round(editDataArr[0].sagyoDetails.sagyoAmtJoint) : 0),
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec:
        sagyoTimeSec + editDataArr[0].sagyoDetails.sagyoTmTwoStepHole + editDataArr[0].sagyoDetails.sagyoTmJoint,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum:
        sagyoTimeSum + editDataArr[0].sagyoDetails.sagyoTmTwoStepHole + editDataArr[0].sagyoDetails.sagyoTmJoint,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const calPunchCnt = (inputArr, deviceMst) => {
    let quantityList = inputArr.quantityList;
    let outerDiaX = Number(inputArr.dimensionData.outerDia) / 4;
    let punchCnt = 0;
    // IQX_WEBEST-332 パンチ工程、外径のパンチ数算出方法の変更
    let xSize = props?.selectedIQ3DataDetail?.partSizeX ? Number(props?.selectedIQ3DataDetail?.partSizeX) : 0;
    let ySize = props?.selectedIQ3DataDetail?.partSizeY ? Number(props?.selectedIQ3DataDetail?.partSizeY) : 0;
    if ((xSize + ySize) * 2 === Number(inputArr.dimensionData.outerDia)) {
      //『（矩形Xサイズ +矩形Yサイズ) * 2= 外形周長』の場合
      let xPunchCnt = 0;
      let yPunchCnt = 0;
      // Xサイズのパンチ数取得
      let overtakeItemsOuter = deviceMst?.details?.odOvertakeItems?.filter(
        (item) => (item.value == xSize || item.value > xSize) && !item?.isOutOfRange
      );
      let overtakeMoldOuter =
        overtakeItemsOuter?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsOuter[0]?.no)
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      let xOuter = Number(overtakeMoldOuter[0].name.split('x')[0].trim());
      xPunchCnt = Number.isFinite(xSize / (xOuter * 0.95)) ? Math.ceil(xSize / (xOuter * 0.95)) : 0;
      // Yサイズのパンチ数取得
      overtakeItemsOuter = deviceMst?.details?.odOvertakeItems?.filter(
        (item) => (item.value == ySize || item.value > ySize) && !item?.isOutOfRange
      );
      overtakeMoldOuter =
        overtakeItemsOuter?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsOuter[0]?.no)
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      let yOuter = Number(overtakeMoldOuter[0].name.split('x')[0].trim());
      yPunchCnt = Number.isFinite(ySize / (yOuter * 0.95)) ? Math.ceil(ySize / (yOuter * 0.95)) : 0;
      // 外径パンチ数＝（Xサイズのパンチ数＋Yサイズのパンチ数）＊２
      punchCnt = (xPunchCnt + yPunchCnt) * 2;
    } else {
      // 『（矩形Xサイズ +矩形Yサイズ) * 2 != 外形周長』の場合
      let overtakeItemsOuter = deviceMst?.details?.odOvertakeItems?.filter(
        (item) => (item.value == outerDiaX || item.value > outerDiaX) && !item?.isOutOfRange
      );
      let overtakeMoldOuter =
        overtakeItemsOuter?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsOuter[0]?.no)
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      let xOuter = Number(overtakeMoldOuter[0].name.split('x')[0].trim());
      // 外径パンチ数＝（外径周長/４）のパンチ数＊４
      punchCnt = Number.isFinite(outerDiaX / (xOuter * 0.95)) ? Math.ceil(outerDiaX / (xOuter * 0.95)) * 4 : 0;
    }
    quantityList.forEach((e) => {
      let perimeter = Number(e.perimeter);
      let xSize = Number(e.xsize);
      let ySize = Number(e.ysize);
      let diameter = Number(e.diameter);
      let inputCnt = Number(e.count);
      let punchesArr = deviceMst?.details?.punches;

      switch (e.types) {
        case 1:
          if (
            diameter !== undefined &&
            diameter !== null &&
            perimeter !== undefined &&
            perimeter !== null &&
            perimeter !== 0
          ) {
            if (diameter > punchesArr.punchHoleDia) {
              let count = Number.isFinite(perimeter / punchesArr.twoPitch)
                ? Math.ceil(perimeter / punchesArr.twoPitch) + 1
                : 0;
              punchCnt += count * inputCnt;
            } else {
              punchCnt += inputCnt;
            }
          }
          break;
        case 2:
          if (
            diameter !== undefined &&
            diameter !== null &&
            perimeter !== undefined &&
            perimeter !== null &&
            perimeter !== 0
          ) {
            if (perimeter > punchesArr.punchRectanglePer) {
              let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
                (item) => (item.value == diameter || item.value > diameter) && !item?.isOutOfRange
              );

              let overtakeMold =
                overtakeItems?.length > 0
                  ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
                  : [deviceMst?.details?.odOvertakeMoldMaster[0]];

              let x = Number(overtakeMold[0].name.split('x')[0]);
              let count = Number.isFinite(diameter / (x * 0.95)) ? Math.ceil(diameter / (x * 0.95)) * 4 : 0;
              punchCnt += count * inputCnt;
            } else {
              punchCnt += inputCnt;
            }
          }
          break;
        case 3:
          if (
            xSize !== undefined &&
            xSize !== null &&
            ySize !== undefined &&
            ySize !== null &&
            perimeter !== undefined &&
            perimeter !== null &&
            perimeter !== 0
          ) {
            if (perimeter > punchesArr.punchRectanglePer) {
              let overtakeItemsX = deviceMst?.details?.odOvertakeItems?.filter(
                (item) => (item.value == xSize || item.value > xSize) && !item?.isOutOfRange
              );

              let overtakeMoldX =
                overtakeItemsX?.length > 0
                  ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsX[0]?.no)
                  : [deviceMst?.details?.odOvertakeMoldMaster[0]];

              let x = Number(overtakeMoldX[0].name.split('x')[0].trim());

              let resultX = Number.isFinite(xSize / (x * 0.95)) ? Math.ceil(xSize / (x * 0.95)) : 0;

              let overtakeItemsY = deviceMst?.details?.odOvertakeItems?.filter(
                (item) => (item.value == ySize || item.value > ySize) && !item?.isOutOfRange
              );

              let overtakeMoldY =
                overtakeItemsY?.length > 0
                  ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsY[0]?.no)
                  : [deviceMst?.details?.odOvertakeMoldMaster[0]];

              let y = Number(overtakeMoldY[0].name.split('x')[0].trim());
              let resultY = Number.isFinite(ySize / (y * 0.95)) ? Math.ceil(ySize / (y * 0.95)) : 0;
              let count = (resultX + resultY) * 2;
              punchCnt += count * inputCnt;
            } else {
              punchCnt += inputCnt;
            }
          }
          break;
        case 4:
          if (ySize !== undefined && ySize !== null && xSize !== undefined && xSize !== null && perimeter !== 0) {
            let squareSize = xSize - ySize;
            let overtakeItemsSq = deviceMst?.details?.odOvertakeItems?.filter(
              (item) => (item.value == squareSize || item.value > squareSize) && !item?.isOutOfRange
            );

            let overtakeMoldSq =
              overtakeItemsSq?.length > 0
                ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsSq[0]?.no)
                : [deviceMst?.details?.odOvertakeMoldMaster[0]];

            let xSq = Number(overtakeMoldSq[0].name.split('x')[0].trim());
            let count1 = Number.isFinite(squareSize / (xSq * 0.95)) ? Math.ceil((squareSize / (xSq * 0.95)) * 2) : 0;

            let count2 = Number.isFinite((ySize * Math.PI) / punchesArr.twoPitch)
              ? Math.ceil((ySize * Math.PI) / punchesArr.twoPitch) + 1
              : 0;
            let count = count1 + count2;
            punchCnt += count * inputCnt;
          }
          break;
        case 5:
          if (perimeter !== undefined && perimeter !== null && perimeter !== 0) {
            if (perimeter > punchesArr.punchRectanglePer) {
              let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
                (item) => (item.value == perimeter || item.value > perimeter) && !item?.isOutOfRange
              );

              let overtakeMold =
                overtakeItems?.length > 0
                  ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
                  : [deviceMst?.details?.odOvertakeMoldMaster[0]];

              let x = Number(overtakeMold[0].name.split('x')[0]);
              let count = Number.isFinite(perimeter / (x * 0.95)) ? Math.ceil(perimeter / (x * 0.95)) : 0;
              punchCnt += count * inputCnt;
            } else {
              punchCnt += inputCnt;
            }
          }
          break;
      }
    });
    punchCnt = punchCnt ? punchCnt : 0;
    return punchCnt;
  };

  useEffect(() => {
    if (props?.tabOpen || props?.dialogOk || props?.isReCalIQ3 || props?.iq3ActionForCal) {
      let editData = [];
      let toBEdit = [];
      let orgData = [];
      let totals = [];
      let processMst = [];
      let deviceMst = []; //テスト用ID;
      setTotalSum(0);
      setTotalTimeSum(0);

      let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
      let nijiProcessMst;
      if (parameters?.process?.length > 0) {
        processMst = parameters?.process
          ? parameters?.process?.filter(
              (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBlankPunch
            )[0]
          : [];

        nijiProcessMst = parameters?.process
          ? parameters?.process?.filter(
              (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
            )[0]
          : [];
        setProcessMst(processMst ? processMst : []);
        if (parameters?.device?.length > 0) {
          deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
        }
      }

      let patternInfo = props?.selectedIQ3DataDetail?.processSelect
        ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
        : [];
      if (patternInfo == undefined) return;
      if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

      deviceMst =
        deviceMst && patternInfo
          ? deviceMst?.filter(
              (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
            )[0]
          : []; //テスト用ID;
      setDeviceMst(deviceMst ? deviceMst : []);
      if (deviceMst == undefined) return;
      // 選択された行の工程積算情報を正しく取得するための対応
      let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
        (item) => item.no === props?.selectedIQ3DataDetail?.no
      );
      let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
      if (selectedIQ3DataDetail?.length === 0) return;
      let punchAdditions = selectedIQ3DataDetail?.additions?.filter((item) => item.processId == processMst?.id)[0];
      // プログラムの工程入力情報取得
      let punchProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == processMst?.id
      );
      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      let punchAdditionsEditData = punchAdditions?.editItems;
      let editDataFlg = punchAdditions?.editDataFlg;

      let secondaryWorkProcessInput;
      let secondaryUsedForCalc = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === nijiProcessMst?.id
      )?.[0]?.isUsedForCalc;

      if (nijiProcessMst !== undefined && secondaryUsedForCalc) {
        secondaryWorkProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
          (item) => item.processId == nijiProcessMst?.id
        )[0];
      }
      let nijiCnt = 0;
      if (secondaryWorkProcessInput !== undefined && secondaryWorkProcessInput?.details !== undefined) {
        secondaryWorkProcessInput?.details.forEach((e) => {
          if (e.blankFlag && Number(e.diameter) > 0) {
            nijiCnt += Number(e.count);
          }
        });
      }
      /*  nijiCnt = nijiCnt > 1 ? nijiCnt - 1 : 1; */

      // 新規の場合
      // if (punchAdditions?.dataItems?.length == 0 || punchAdditions == undefined) {
      let detailItems = [];
      let editDetailItems = [];
      let dandoriDetail = {
        dandoriAmtBasic: 0,
        dandoriTmBasic: 0,
        dandoriAmtMold: 0,
        dandoriTmMold: 0,
        dandoriMoldCnt: 0, // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
      };
      let sagyoDetail = {
        sagyoAmtPunch: 0,
        sagyoTmPunch: 0,
        punchTm: 0,
        punchCnt: 0,
        sagyoAmtAirDeli: 0,
        sagyoTmAirDeli: 0,
        speedAirDeli: 0,
        speedAirDeliLen: 0,
        sagyoAmtScribing: 0,
        sagyoTmScribing: 0,
        speedScribing: 0,
        speedScribingLen: 0,
        sagyoAmtTwoStepHole: 0,
        sagyoTmTwoStepHole: 0,
        twoStepHoleCnt: 0,
        twoStepHoleTm: 0,
        sagyoAmtJoint: 0,
        sagyoTmJoint: 0,
        jointCnt: 0,
        jointTm: 0,
      };
      detailItems.push({
        id: 1,
        processName: processMst?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        dandoriDetails: dandoriDetail,
        sagyoAmt: 0,
        sagyoTime: 0,
        sagyoDetails: sagyoDetail,
        formula: '',
      });

      editDetailItems = [];
      let punchAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: punchAdditions?.dataType ? punchAdditions.dataType : DataType.Data,
        dataItems: detailItems,
        editItems: punchAdditions?.editItems ? punchAdditions.editItems : editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
        editDataFlg: editDataFlg,
      };
      punchAdditions = punchAddition;
      // }
      if (punchAdditions?.dataItems) {
        //データ設定
        orgData = JSON.parse(JSON.stringify(punchAdditions?.dataItems));
        if (deviceMst != undefined) {
          let totalSagyo = 0;
          let totalSagyoTm = 0;
          let punchCount = 0;
          // 数量
          let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
          // 板厚
          let thickness = parameters?.materialIq3?.filter(
            (item) => item.id == props?.selectedIQ3DataDetail?.thickness
          )?.[0]?.info?.thick;
          // 重量
          let weight = props?.selectedIQ3DataDetail?.weight;
          // 面積
          let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst);
          //総重量が0、子部品数が0の場合、段取と加工を計算しない。
          if (weight === 0 && area === 0) {
            setProcessArr(orgData);
          } else {
            punchCount = calPunchCnt(punchProcessInput?.[0]?.details?.[0], deviceMst);

            // if (punchCount === 0) {
            //   setProcessArr(orgData);
            // } else {
            let speedAirDeliLen = Number(punchProcessInput?.[0]?.details?.[0]?.dimensionData?.airFeedDistance);
            let speedScribingLen = Number(punchProcessInput?.[0]?.details?.[0]?.dimensionData?.etchingLength); //PMX用ケガキ線長

            //材質
            let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

            //材質区分
            let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
              ?.materialTypeId;

            // サイズ係数
            let sizeCoef = getSizeCoeff(deviceMst, weight, area);

            // 基本段取金額/時間
            let basicSetupTimeSec = deviceMst?.details?.prepBasicTime * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
            orgData[0].dandoriDetails.dandoriTmBasic = basicSetupTimeSec === undefined ? 0 : basicSetupTimeSec;
            let danAmtBasic = calDandoriAmt(basicSetupTimeSec, deviceMst);
            orgData[0].dandoriDetails.dandoriAmtBasic = danAmtBasic === undefined ? 0 : danAmtBasic;

            // IQX_WEBEST-294 ブランク工程の金型段取時間の参照する値が間違っている
            let moldQty = 0;
            moldQty = getKanagataCnt(
              deviceMst,
              props?.selectedIQ3DataDetail,
              punchProcessInput?.[0]?.details?.[0]?.quantityList,
              punchProcessInput?.[0]?.details?.[0]
            );
            // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
            if (orgData[0].dandoriDetails.dandoriMoldCnt !== undefined) {
              orgData[0].dandoriDetails.dandoriMoldCnt = moldQty;
            }
            // 金型段取金額/時間
            let prepMoldQtyCoeffThMaster = deviceMst?.details?.prepMoldQtyCoeffThMaster?.filter(
              (item) => (item.value == moldQty || item.value > moldQty) && !item.isOutOfRange // IQX_WEBEST-217
            )?.[0];
            let prepMoldQtyCoeffItems = deviceMst?.details?.prepMoldQtyCoeffItems?.filter(
              (item) => item.no == prepMoldQtyCoeffThMaster?.no && !item.isOutOfRange
            )?.[0];
            let moldSetupTimeSec = prepMoldQtyCoeffItems?.value;
            moldSetupTimeSec = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec; // IQX_WEBEST-217
            orgData[0].dandoriDetails.dandoriTmMold = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;
            let danAmtMold = calDandoriAmt(moldSetupTimeSec, deviceMst);
            orgData[0].dandoriDetails.dandoriAmtMold = danAmtMold === undefined ? 0 : danAmtMold;

            /** 段取金額/時間 (基本段取時間	+	金型段取時間)*/
            orgData[0].dandoriTime = basicSetupTimeSec + moldSetupTimeSec;
            orgData[0].dandoriAmt =
              orgData[0].dandoriDetails.dandoriAmtBasic + orgData[0].dandoriDetails.dandoriAmtMold;

            // 2工程穴加工金額/時間
            let twoStepHoleTm = deviceMst?.details?.twoStepHoleworkTime;
            let twoStepHoleCnt = nijiCnt; //確認必要？
            orgData[0].sagyoDetails.twoStepHoleTm = twoStepHoleTm === undefined ? 0 : twoStepHoleTm;
            orgData[0].sagyoDetails.twoStepHoleCnt = twoStepHoleCnt === undefined ? 0 : twoStepHoleCnt;
            // 2工程穴加工時間	*	個数
            let twoStepHoleTmsec = twoStepHoleTm * twoStepHoleCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmTwoStepHole = twoStepHoleTmsec === undefined ? 0 : twoStepHoleTmsec;
            let sagyoAmt2Step = calSagyoAmtTwoStepHole(twoStepHoleTmsec, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtTwoStepHole = sagyoAmt2Step === undefined ? 0 : sagyoAmt2Step;

            // ジョイントバラシ加工金額/時間
            let jointTimePerSec = getJointTm(deviceMst, thickness, materialTypeId);
            let jointQtySizeThMaster = deviceMst?.details?.jointQtySizeThMaster?.filter(
              (item) => (item.value == area || item.value > area) && !item.isOutOfRange
            )?.[0];
            jointQtySizeThMaster = jointQtySizeThMaster
              ? jointQtySizeThMaster
              : deviceMst?.details?.jointQtySizeThMaster?.[deviceMst?.details?.jointQtySizeThMaster.length - 1];
            let jointCnt = deviceMst?.details?.jointQtyItems?.filter(
              (item) => item.no == jointQtySizeThMaster?.no && !item.isOutOfRange
            )?.[0]?.value;
            orgData[0].sagyoDetails.jointTm = jointTimePerSec === undefined ? 0 : jointTimePerSec;
            orgData[0].sagyoDetails.jointCnt = jointCnt === undefined ? 0 : jointCnt;
            // ジョイントバラシ加工時間	*	個数
            let jointTimeSec = jointTimePerSec * jointCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmJoint = jointTimeSec === undefined ? 0 : jointTimeSec;
            orgData[0].sagyoDetails.sagyoAmtJoint = calSagyoAmtJoint(jointTimeSec, deviceMst);

            // パンチ加工時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
            // パンチ数
            let punchCnt = punchCount; //確認必要
            orgData[0].sagyoDetails.punchCnt = punchCnt === undefined ? 0 : punchCnt;
            let punchTm = getSagyoPunchTm(deviceMst, thickness, materialTypeId);
            orgData[0].sagyoDetails.punchTm = punchTm === undefined ? 0 : punchTm;
            let sagyoTmPunchPerSec = punchTm * punchCnt * sizeCoef * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmPunch = sagyoTmPunchPerSec === undefined ? 0 : sagyoTmPunchPerSec;
            let sagyoPunchAmt = calSagyoAmt(sagyoTmPunchPerSec, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtPunch = sagyoPunchAmt === undefined ? 0 : sagyoPunchAmt;

            // 空送時間（空送距離	/	送り速度）
            let speedAirDeli = deviceMst?.details?.feedSpeed;
            orgData[0].sagyoDetails.speedAirDeli = speedAirDeli === undefined ? 0 : speedAirDeli;

            orgData[0].sagyoDetails.speedAirDeliLen = speedAirDeliLen === undefined ? 0 : speedAirDeliLen;
            let sagyoTmAirDeli = Number.isFinite(speedAirDeliLen / speedAirDeli) ? speedAirDeliLen / speedAirDeli : 0;
            sagyoTmAirDeli = sagyoTmAirDeli === undefined ? 0 : sagyoTmAirDeli * 60 * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmAirDeli = sagyoTmAirDeli;
            let sagyoAirFeedAmt = calSagyoAmt(sagyoTmAirDeli, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtAirDeli = sagyoAirFeedAmt === undefined ? 0 : sagyoAirFeedAmt;

            // ケガキ加工時間（ケガキ線長	/	加工速度）
            let speedScribing = deviceMst?.details?.workSpeed;
            orgData[0].sagyoDetails.speedScribing = speedScribing === undefined ? 0 : speedScribing;
            orgData[0].sagyoDetails.speedScribingLen = speedScribingLen === undefined ? 0 : speedScribingLen;
            let sagyoTmScribing = Number.isFinite(speedScribingLen / speedScribing)
              ? speedScribingLen / speedScribing
              : 0;
            sagyoTmScribing = sagyoTmScribing === undefined ? 0 : sagyoTmScribing * 60 * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmScribing = sagyoTmScribing;
            let sAmtSubscribing = calSagyoAmt(sagyoTmScribing, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtScribing = sAmtSubscribing === undefined ? 0 : sAmtSubscribing;

            //作業合計金額・時間
            orgData[0].sagyoAmt =
              orgData[0].sagyoDetails.sagyoAmtPunch +
              orgData[0].sagyoDetails.sagyoAmtAirDeli +
              orgData[0].sagyoDetails.sagyoAmtScribing;
            orgData[0].sagyoTime =
              orgData[0].sagyoDetails.sagyoTmPunch +
              orgData[0].sagyoDetails.sagyoTmAirDeli +
              orgData[0].sagyoDetails.sagyoTmScribing;

            totalSagyo =
              (orgData[0].sagyoAmt ? Math.round(orgData[0].sagyoAmt) : 0) +
              (orgData[0].sagyoDetails.sagyoAmtTwoStepHole
                ? Math.round(orgData[0].sagyoDetails.sagyoAmtTwoStepHole)
                : 0) +
              (orgData[0].sagyoDetails.sagyoAmtJoint ? Math.round(orgData[0].sagyoDetails.sagyoAmtJoint) : 0);
            totalSagyoTm =
              orgData[0].sagyoTime + orgData[0].sagyoDetails.sagyoTmTwoStepHole + orgData[0].sagyoDetails.sagyoTmJoint;

            /** 　合計時間　*/
            let totalTimeSum = orgData[0].dandoriTime + totalSagyoTm;
            setTotalTimeSum(totalTimeSum);
            punchAdditions.totalDataTime = totalTimeSum;
            punchAdditions.totalDandori.dataItemsTime = orgData[0].dandoriTime;
            punchAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

            /** 　合計金額　*/
            let totalSum =
              (orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0) +
              (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
            setTotalSum(totalSum);
            punchAdditions.totalDataPrice = totalSum;
            punchAdditions.totalDandori.dataItemsAmt = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0;
            punchAdditions.totalSagyo.dataItemsAmt = totalSagyo;
            // }
          }
          setProcessArr(orgData);
          punchAdditions.dataItems = orgData;

          // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
          if (punchAdditionsEditData?.length > 0 && editDataFlg) {
            toBEdit = JSON.parse(JSON.stringify(punchAdditionsEditData));
          } else {
            toBEdit = JSON.parse(JSON.stringify(orgData));
          }
        }
      }
      if (toBEdit?.length > 0) {
        editData = toBEdit;
        punchAdditions.editItems = editData;
        if (editData?.length > 0) {
          setEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum);
          punchAdditions.totalEditPrice = totals[0]?.totalSum;
          punchAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum;
          punchAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

          setTotalTime(totals[0]?.totalTimeSum);
          punchAdditions.totalEditTime = totals[0]?.totalTimeSum;
          punchAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum;
          punchAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;
        }
        setUpdateEditedData(punchAdditions);
      }

      if (punchAdditions?.dataType == DataType.Data) {
        setPunchAmt(punchAdditions.totalDataPrice);
      } else {
        setPunchAmt(punchAdditions.totalEditPrice);
      }
      props?.updateIQ3KouteiCal(punchAdditions, WorkType.SmBlankPunch);
    }
  }, [props?.isReCalIQ3, props?.iq3ActionForCal, props?.tabOpen, props?.dialogOk]);

  useEffect(() => {
    if (props?.tabOpen) {
      setEditMode(props?.editMode != undefined ? props?.editMode : false);
    }
  }, [props?.editMode]);

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );

    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : [deviceMst?.details?.prodSizeItems[0]];

    // サイズ係数
    let partsCoeff =
      prodSizeAreaThMaster?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    partsCoeff = partsCoeff ? partsCoeff : 0;

    return partsCoeff;
  };

  // const getSpeedCoef = (deviceMst, thickness, processType) => {
  //   if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  //   let childPartsCoeff = 0;
  //   let workSpeedCoeffThickThMaster = deviceMst?.details?.workSpeedCoeffThickThMaster?.filter(
  //     (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  //   )?.[0];

  //   if (processType == 0) {
  //     childPartsCoeff = deviceMst?.details?.workSpeedCoeffInternalWorkItems?.filter(
  //       (item) => item.no == workSpeedCoeffThickThMaster?.no && !item.isOutOfRange
  //     )?.[0]?.value;
  //   } else {
  //     childPartsCoeff = deviceMst?.details?.workSpeedCoeffExternalWorkItems?.filter(
  //       (item) => item.no == workSpeedCoeffThickThMaster?.no && !item.isOutOfRange
  //     )?.[0]?.value;
  //   }

  //   return childPartsCoeff;
  // };

  const getSagyoPunchTm = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    let punchWorkTimeThickThMaster = deviceMst?.details?.punchWorkTimeThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    )?.[0];

    let punchWorkTimeItems = Object.entries(deviceMst.details?.punchWorkTimeItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    punchWorkTimeItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter(
          (item) => parseInt(item.no) == punchWorkTimeThickThMaster?.no && !item.isOutOfRange
        )?.[0]?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getJointTm = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let jointSeparateThickThMaster = deviceMst?.details?.jointSeparateThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    )?.[0];

    let jointSeparateItems = Object.entries(deviceMst.details?.jointSeparateItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];

    // ジョイント時間
    let childPartsCoeff = 0;
    jointSeparateItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter(
          (item) => parseInt(item.no) == jointSeparateThickThMaster?.no && !item.isOutOfRange
        )?.[0]?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const calSagyoAmtJoint = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.jointSeparate;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoAmtTwoStepHole = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.twoStepHolework;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  // IQX_WEBEST-294 ブランク工程の金型段取時間の参照する値が間違っている
  const getKanagataCnt = (deviceMst, iq3Info, blankTypeList, inputArr) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let kanagataCnt = 0;
    let kanagataType = {
      maru: 0,
      kaku: 0,
      nagakaku: 0,
      other: 0,
      twoPitch: 0,
      overTakingCon: [],
    };
    // 外周使用金型本数のカウント
    let xSize = Number(iq3Info?.partSizeX); // 矩形サイズX
    let ySize = Number(iq3Info?.partSizeY); // 矩形サイズY
    // IQX_WEBEST-332 パンチ工程、外径のパンチ数算出方法の変更
    if ((xSize + ySize) * 2 === Number(inputArr.dimensionData.outerDia)) {
      //『（矩形Xサイズ +矩形Yサイズ) * 2 = 外形周長』の場合
      // Xサイズの追い抜き条件の加工辺長の値を元に金型情報取得
      let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
        (item) => (Number(item.value) === xSize || item.value > xSize) && !item?.isOutOfRange
      );
      let overtakeMold =
        overtakeItems?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      kanagataType.overTakingCon.push(overtakeMold[0]);
      // Yサイズの追い抜き条件の加工辺長の値を元に金型情報取得
      overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
        (item) => (Number(item.value) === ySize || item.value > ySize) && !item?.isOutOfRange
      );
      overtakeMold =
        overtakeItems?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      kanagataType.overTakingCon.push(overtakeMold[0]);
    } else {
      //『（矩形Xサイズ +矩形Yサイズ) * 2 != 外形周長』の場合
      // 利用金型：「外径周長/４」線長の金型
      let outerDiaX = Number(inputArr?.dimensionData?.outerDia) / 4;
      let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
        (item) => (Number(item.value) === outerDiaX || item.value > outerDiaX) && !item?.isOutOfRange
      );
      let overtakeMold =
        overtakeItems?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      kanagataType.overTakingCon.push(overtakeMold[0]);
    }
    // 内周使用金型本数のカウント
    // パンチ条件
    let punchesArr = deviceMst?.details?.punches;
    for (let i = 0; i < blankTypeList?.length; i++) {
      let item = blankTypeList[i];
      if (item.types === 1 && item.diameter !== undefined && item.diameter !== null && Number(item.diameter) !== 0) {
        // 丸（径を元に決定）
        if (Number(item.diameter) <= Number(punchesArr?.punchHoleDia)) {
          // 丸種類
          kanagataType.maru += 1;
        } else {
          // 二プリング種類
          kanagataType.twoPitch += 1;
        }
      } else if (
        item.types === 2 &&
        item.perimeter !== undefined &&
        item.perimeter !== null &&
        Number(item.perimeter) !== 0 &&
        item.diameter !== undefined &&
        item.diameter !== null &&
        Number(item.diameter) !== 0
      ) {
        // 角（辺長を元に決定）
        if (Number(item.perimeter) <= Number(punchesArr?.punchRectanglePer)) {
          // 角種類
          kanagataType.kaku += 1;
        } else {
          // 追い抜き条件種類
          overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
            (odOvertakeItem) =>
              (Number(odOvertakeItem.value) === Number(item.diameter) ||
                Number(odOvertakeItem.value) > Number(item.diameter)) &&
              !item?.isOutOfRange
          );
          overtakeMold =
            overtakeItems?.length > 0
              ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                  (odOvertakeItem) => odOvertakeItem.no === overtakeItems[0]?.no
                )
              : [deviceMst?.details?.odOvertakeMoldMaster[0]];
          kanagataType.overTakingCon.push(overtakeMold[0]);
        }
      } else if (
        item.types === 3 &&
        item.perimeter !== undefined &&
        item.perimeter !== null &&
        Number(item.perimeter) !== 0 &&
        item.xsize !== undefined &&
        item.xsize !== null &&
        Number(item.xsize) !== 0 &&
        item.ysize !== undefined &&
        item.ysize !== null &&
        Number(item.ysize) !== 0
      ) {
        // 長角
        if (Number(item.perimeter) <= Number(punchesArr.punchRectanglePer)) {
          // 長角種類
          kanagataType.nagakaku += 1;
        } else {
          //（XサイズとYサイズを元に決定）
          // Xサイズ（mm）
          // 追い抜き条件種類
          overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
            (odOvertakeItem) =>
              (Number(odOvertakeItem.value) === Number(item.xsize) ||
                Number(odOvertakeItem.value) > Number(item.xsize)) &&
              !odOvertakeItem?.isOutOfRange
          );
          overtakeMold =
            overtakeItems?.length > 0
              ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                  (odOvertakeItem) => odOvertakeItem.no === overtakeItems?.[0]?.no
                )
              : [deviceMst?.details?.odOvertakeMoldMaster[0]];
          kanagataType.overTakingCon.push(overtakeMold[0]);
          // Yサイズ（mm）
          // 追い抜き条件種類
          overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
            (odOvertakeItem) =>
              (Number(odOvertakeItem.value) === Number(item.ysize) ||
                Number(odOvertakeItem.value) > Number(item.ysize)) &&
              !odOvertakeItem?.isOutOfRange
          );
          overtakeMold =
            overtakeItems?.length > 0
              ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                  (odOvertakeItem) => odOvertakeItem.no === overtakeItems[0]?.no
                )
              : [deviceMst?.details?.odOvertakeMoldMaster[0]];
          kanagataType.overTakingCon.push(overtakeMold[0]);
        }
      } else if (
        item.types === 4 &&
        item.xsize !== undefined &&
        item.xsize !== null &&
        Number(item.xsize) !== 0 &&
        item.ysize !== undefined &&
        item.ysize !== null &&
        Number(item.ysize) !== 0
      ) {
        // 長丸（XサイズとYサイズを元に決定）
        let squareXSize = Number(item.xsize) - Number(item.ysize);
        let overtakeItemsSq = deviceMst?.details?.odOvertakeItems?.filter(
          (odOvertakeItem) =>
            (Number(odOvertakeItem.value) === squareXSize || Number(odOvertakeItem.value) > squareXSize) &&
            !odOvertakeItem?.isOutOfRange
        );
        let overtakeMoldSq =
          overtakeItemsSq?.length > 0
            ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                (odOvertakeItem) => odOvertakeItem.no === overtakeItemsSq[0]?.no
              )
            : [deviceMst?.details?.odOvertakeMoldMaster[0]];
        // 追い抜き条件種類
        kanagataType.overTakingCon.push(overtakeMoldSq[0]); // X線分の追い抜き条件情報
        // 円弧部分(二プリング種類)
        kanagataType.twoPitch += 1;
      } else if (
        item.types === 5 &&
        item.perimeter !== undefined &&
        item.perimeter !== null &&
        Number(item.perimeter) !== 0
      ) {
        // その他（周長を元に決定）
        if (Number(item.perimeter) <= Number(punchesArr?.punchRectanglePer)) {
          // その他種類
          kanagataType.other += 1;
        } else {
          // 追い抜き条件種類
          overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
            (odOvertakeItem) =>
              (Number(odOvertakeItem.value) === Number(item.perimeter) ||
                Number(odOvertakeItem.value) > Number(item.perimeter)) &&
              !odOvertakeItem?.isOutOfRange
          );
          overtakeMold =
            overtakeItems?.length > 0
              ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                  (odOvertakeItem) => odOvertakeItem.no === overtakeItems[0]?.no
                )
              : [deviceMst?.details?.odOvertakeMoldMaster[0]];
          kanagataType.overTakingCon.push(overtakeMold[0]);
        }
      }
    }
    // 使用追い抜き条件種類数カウント
    let overTakingConCnt = 0;
    let overTakingConInfo = kanagataType.overTakingCon?.reduce((a, e) => {
      a[e.name] = ++a[e.name] || 0;
      return a;
    }, {});
    if (overTakingConInfo) {
      overTakingConCnt = Object.keys(overTakingConInfo)?.length;
    }
    // 二プリング（不使用時：０，使用時：１）
    if (kanagataType.twoPitch > 0) {
      kanagataType.twoPitch = 1;
    } else {
      kanagataType.twoPitch = 0;
    }
    // 使用金型種類数
    kanagataCnt =
      kanagataType.maru +
      kanagataType.kaku +
      kanagataType.nagakaku +
      kanagataType.other +
      kanagataType.twoPitch +
      overTakingConCnt;
    kanagataCnt = kanagataCnt ? kanagataCnt : 0;
    return kanagataCnt;
  };

  const Blanking = () => {
    if (isBlankingdetail == true) {
      setIBlankingdetail(false);
    } else {
      setIBlankingdetail(true);
    }
  };

  const setPunchDisplay = (display) => {
    setIBlankingdetail(display);
  };

  // const dandoriDetail = () => {
  //   if (isdandoriDetail == true) {
  //     setIsDandoriDetail(false);
  //   } else {
  //     setIsDandoriDetail(true);
  //   }
  // };

  const sagyoDetail = () => {
    if (isSagyoDetail == true) {
      setIsSagyoDetail(false);
    } else {
      setIsSagyoDetail(true);
    }
  };

  const dandoriDetails = () => {
    if (isDandoriDetail == true) {
      setIsDandoriDetail(false);
    } else {
      setIsDandoriDetail(true);
    }
  };

  const moldCntDetails = () => {
    if (isMoldCntDetail == true) {
      setIsMoldCntDetail(false);
    } else {
      setIsMoldCntDetail(true);
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setPunchDisplay: setPunchDisplay,
  }));

  const toTwoDecimal = (j, param) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    newState[j].sagyoDetails[param] = toDecimal(newState[j]?.sagyoDetails?.[param], 2);
    setEditedData(newState);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setPunchAmt(updateEditedData.totalDataPrice);
    } else {
      setPunchAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBlankPunch);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct, arrType) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn

    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let totals = [];
    // 重量
    let weight = props?.selectedIQ3DataDetail?.weight;
    // 面積
    let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);

    let sizeCoef = getSizeCoeff(deviceMst, weight, area);
    //let deviceMst = [];
    let oldValue;
    if (value === undefined) {
      value = 0;
    } else {
      if (objId.includes('speed') && objId.includes('Len')) {
        value = value ? value : 0;
      } else {
        value = value ? Number(value) : 0;
      }
    }
    if (arrType !== 'dandori' && arrType !== 'sagyo') {
      newState[0][objId] = value;
    }

    if (arrType === 'dandori') {
      // 基本段取金額/時間
      if (objId.includes('Tm')) {
        let details = newState?.[index]?.dandoriDetails;
        newState[0].dandoriDetails[objId] = value;
        let amtId = objId.replace('Tm', 'Amt');
        let cuId = amtId + 'Cu';
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              details[amtId] = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue[index].dandoriTime;
              details[amtId] = changeAmtwithTime(details?.[amtId], timeType, timeAct, value, 'dandoriTime');
            } else {
              details[amtId] = changeAmtwithTime(details?.[amtId], timeType, timeAct, value, 'dandoriTime');
            }
          }
        }
        updateEditedData.editItems[index].dandoriDetails[amtId] = details?.[amtId];
        updateEditedData.editItems[index].dandoriDetails[objId] = value;
      } else if (objId.includes('Amt')) {
        newState[0].dandoriDetails[objId] = value;
        updateEditedData.editItems[index].dandoriDetails[objId] = value;
      } else if (objId.includes('Cnt')) {
        // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
        if (newState[0].dandoriDetails.dandoriMoldCnt !== undefined) {
          newState[0].dandoriDetails[objId] = value;
          updateEditedData.editItems[index].dandoriDetails[objId] = value;
          if (value > 0) {
            // 金型段取金額/時間
            let moldSetupTimeSec = 0;
            let prepMoldQtyCoeffThMaster = deviceMst?.details?.prepMoldQtyCoeffThMaster?.filter(
              (item) => (item.value == value || item.value > value) && !item.isOutOfRange // IQX_WEBEST-216
            )?.[0];
            let prepMoldQtyCoeffItems = deviceMst?.details?.prepMoldQtyCoeffItems?.filter(
              (item) => item.no == prepMoldQtyCoeffThMaster?.no && !item.isOutOfRange
            )?.[0];
            moldSetupTimeSec = prepMoldQtyCoeffItems?.value;
            moldSetupTimeSec = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;
            updateEditedData.editItems[index].dandoriDetails.dandoriTmMold =
              moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;
            newState[0].dandoriDetails.dandoriTmMold = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;
            let danAmtMold = calDandoriAmt(moldSetupTimeSec, deviceMst);
            updateEditedData.editItems[index].dandoriDetails.dandoriAmtMold = danAmtMold === undefined ? 0 : danAmtMold;
            newState[0].dandoriDetails.dandoriAmtMold = danAmtMold === undefined ? 0 : danAmtMold;
          } else {
            updateEditedData.editItems[index].dandoriDetails.dandoriTmMold = 0;
            newState[0].dandoriDetails.dandoriTmMold = 0;
            updateEditedData.editItems[index].dandoriDetails.dandoriAmtMold = 0;
            newState[0].dandoriDetails.dandoriAmtMold = 0;
          }
        }
      }
    } else if (arrType === 'sagyo') {
      if (objId.includes('Tm') && objId !== 'punchTm') {
        let details = newState[index].sagyoDetails;
        newState[0].sagyoDetails[objId] = value;
        let amtId = objId.replace('Tm', 'Amt');
        let cuId = amtId + 'Cu';
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            if (value <= 0) {
              newState[index].sagyoDetails[amtId] = 0;
            } else if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].sagyoTime);
              details[amtId] = changeAmtwithTime(details[amtId], timeType, timeAct, value, 'sagyoTime');
              checkInput(index + objId, value);
            } else {
              newState[index].sagyoDetails[amtId] = changeAmtwithTime(
                details[amtId],
                timeType,
                timeAct,
                value,
                'sagyoTime'
              );
            }
          }
        }
        updateEditedData.editItems[index].sagyoDetails[amtId] = newState[index].sagyoDetails[amtId];
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      } else if (objId.includes('speed') || objId.includes('Len')) {
        let amtId, tmId, id, cuId;
        id = objId;
        if (objId.includes('Len')) {
          id = objId.replace('Len', '');
        }
        amtId = id.replace('speed', 'sagyoAmt');
        tmId = id.replace('speed', 'sagyoTm');
        cuId = amtId + 'Cu';

        let length, speed, lengthId, speedId;

        if (objId.includes('Len')) {
          speedId = objId.replace('Len', '');
          speed = newState[index].sagyoDetails[speedId];
          length = value;
        } else if (objId.includes('speed') && !objId.includes('Len')) {
          lengthId = objId + 'Len';
          length = newState[index].sagyoDetails[lengthId];
          speed = value;
        }

        /* let length = objId.includes('Len') ? value : sagyo[index]?.length;
        let speed = objId.includes('speed') && !objId.includes('len') ? value : sagyo[index].speed; */
        // ケガキ加工時間（ケガキ線長	/	加工速度）
        let sagyoTimePerSec = Number.isFinite(length / speed) ? length / speed : 0;
        sagyoTimePerSec = sagyoTimePerSec * 60 * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
        //sagyo[index].sagyoTime = secondsToHms(sagyoTimePerSec);
        newState[index].sagyoDetails[tmId] = sagyoTimePerSec;
        /*  sagyo[index].sagyoAmt = calSagyoAmt(setTimetoSec(sagyo[index].sagyoTime), deviceMst); */
        if (document.getElementById(cuId).style.display !== undefined) {
          let showHideCu = document.getElementById(cuId).style.display;
          if (showHideCu === 'none') {
            newState[index].sagyoDetails[amtId] = calSagyoAmt(sagyoTimePerSec, deviceMst);
          }
        }
        newState[0].sagyoDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails[amtId] = newState[index].sagyoDetails[amtId];
        updateEditedData.editItems[index].sagyoDetails[tmId] = newState[index].sagyoDetails[tmId];
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      } else if (objId.includes('Amt')) {
        newState[0].sagyoDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      } else if (objId === 'punchCnt' || objId === 'punchTm') {
        // サイズ係数
        let punchTm = objId === 'punchTm' ? value : newState[index].sagyoDetails.punchTm;
        let punchCnt = objId === 'punchCnt' ? value : newState[index].sagyoDetails.punchCnt;
        // 通常パンチ時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
        let sagyoTimePerSec = punchTm * punchCnt * sizeCoef * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
        newState[index].sagyoDetails.sagyoTmPunch = sagyoTimePerSec;
        if (document.getElementById('sagyoAmtPunchCu').style.display !== undefined) {
          let showHideCu = document.getElementById('sagyoAmtPunchCu').style.display;
          if (showHideCu === 'none') {
            newState[index].sagyoDetails.sagyoAmtPunch = calSagyoAmt(sagyoTimePerSec, deviceMst);
          }
        }
        updateEditedData.editItems[index].sagyoDetails['sagyoAmtPunch'] = newState[index].sagyoDetails.sagyoAmtPunch;
        updateEditedData.editItems[index].sagyoDetails['sagyoTmPunch'] = newState[index].sagyoDetails.sagyoTmPunch;

        newState[0].sagyoDetails[objId] = value;
        updateEditedData.editItems[index].sagyoDetails[objId] = value;
      }
    } else if (objId === 'twoStepHoleCnt' || objId === 'twoStepHoleTm') {
      // 2工程穴加工金額/時間
      newState[index].sagyoDetails.twoStepHoleCnt =
        objId === 'twoStepHoleCnt' ? value : newState?.[index]?.sagyoDetails?.twoStepHoleCnt;
      newState[index].sagyoDetails.twoStepHoleTm =
        objId === 'twoStepHoleTm' ? value : newState?.[index]?.sagyoDetails?.twoStepHoleTm;

      // 2工程穴加工時間	*	個数
      let drillingTimeSec =
        newState?.[index]?.sagyoDetails?.twoStepHoleTm *
        newState?.[index]?.sagyoDetails?.twoStepHoleCnt *
        getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
      newState[index].sagyoDetails.sagyoTmTwoStepHole = drillingTimeSec;
      if (document.getElementById('sagyoAmtTwoStepHoleCu').style.display !== undefined) {
        let showHideCu = document.getElementById('sagyoAmtTwoStepHoleCu').style.display;
        if (showHideCu === 'none') {
          newState[index].sagyoDetails.sagyoAmtTwoStepHole = calSagyoAmtTwoStepHole(drillingTimeSec, deviceMst);
        }
      }
      newState[0].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails['sagyoAmtTwoStepHole'] =
        newState[index].sagyoDetails.sagyoAmtTwoStepHole;
      updateEditedData.editItems[index].sagyoDetails['sagyoTmTwoStepHole'] =
        newState[index].sagyoDetails.sagyoTmTwoStepHole;
    } else if (objId === 'jointCnt' || arrType === 'jointTm') {
      // ジョイント加工金額/時間
      newState[index].sagyoDetails.jointTm = objId === 'jointTm' ? value : newState?.[index]?.sagyoDetails?.jointTm;
      newState[index].sagyoDetails.jointCnt = objId === 'jointCnt' ? value : newState?.[index]?.sagyoDetails?.jointCnt;

      // ジョイント加工時間	*	個数
      let jointTimeSec =
        newState?.[index]?.sagyoDetails?.jointTm *
        newState?.[index]?.sagyoDetails?.jointCnt *
        getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
      newState[index].sagyoDetails.sagyoTmJoint = jointTimeSec;
      if (document.getElementById('sagyoAmtJointCu').style.display !== undefined) {
        let showHideCu = document.getElementById('sagyoAmtJointCu').style.display;
        if (showHideCu === 'none') {
          newState[index].sagyoDetails.sagyoAmtJoint = calSagyoAmtJoint(jointTimeSec, deviceMst);
        }
      }
      newState[0].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails[objId] = value;
      updateEditedData.editItems[index].sagyoDetails['sagyoAmtJoint'] = newState[index].sagyoDetails.sagyoAmtJoint;
      updateEditedData.editItems[index].sagyoDetails['sagyoTmJoint'] = newState[index].sagyoDetails.sagyoTmJoint;
    }

    /** 段取金額/時間 (基本段取時間	+	金型段取時間)*/

    newState[0].dandoriTime = newState[0].dandoriDetails.dandoriTmBasic + newState[0].dandoriDetails.dandoriTmMold;
    newState[0].dandoriAmt = newState[0].dandoriDetails.dandoriAmtBasic + newState[0].dandoriDetails.dandoriAmtMold;
    updateEditedData.editItems[index].dandoriTime = newState[0].dandoriTime;
    updateEditedData.editItems[index].dandoriAmt = newState[0].dandoriAmt;
    updateEditedData.totalDandori.editItemsAmt = newState[0].dandoriAmt ? Math.round(newState[0].dandoriAmt) : 0;
    updateEditedData.totalDandori.editItemsTime = newState[0].dandoriTime;

    //作業合計金額・時間
    newState[0].sagyoAmt =
      newState[0].sagyoDetails.sagyoAmtPunch +
      newState[0].sagyoDetails.sagyoAmtAirDeli +
      newState[0].sagyoDetails.sagyoAmtScribing;
    updateEditedData.editItems[index].sagyoAmt = newState[0].sagyoAmt;

    newState[0].sagyoTime =
      newState[0].sagyoDetails.sagyoTmPunch +
      newState[0].sagyoDetails.sagyoTmAirDeli +
      newState[0].sagyoDetails.sagyoTmScribing;
    updateEditedData.editItems[index].sagyoTime = newState[0].sagyoTime;

    let totalSagyo =
      (newState[0].sagyoAmt ? Math.round(newState[0].sagyoAmt) : 0) +
      (newState[0].sagyoDetails.sagyoAmtTwoStepHole ? Math.round(newState[0].sagyoDetails.sagyoAmtTwoStepHole) : 0) +
      (newState[0].sagyoDetails.sagyoAmtJoint ? Math.round(newState[0].sagyoDetails.sagyoAmtJoint) : 0);
    updateEditedData.totalSagyo.editItemsAmt = totalSagyo;
    let totalSagyoTm =
      newState[0].sagyoTime + newState[0].sagyoDetails.sagyoTmTwoStepHole + newState[0].sagyoDetails.sagyoTmJoint;
    updateEditedData.totalSagyo.editItemsTime = totalSagyoTm;

    /** 　合計時間　*/
    let totalTimeSum = newState[0].dandoriTime + totalSagyoTm;
    setTotalTime(totalTimeSum);
    updateEditedData.totalEditTime = totalTimeSum;

    /** 　合計金額　*/
    let totalSum =
      (newState[0].dandoriAmt ? Math.round(newState[0].dandoriAmt) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
    setTotalAmt(totalSum);
    updateEditedData.totalEditPrice = totalSum;

    setEditedData(newState);
    if (updateEditedData.dataType == DataType.Data) {
      setPunchAmt(updateEditedData?.totalDataPrice);
    } else {
      setPunchAmt(updateEditedData?.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBlankPunch);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    //let deviceMst = [];

    if (processType === 'dandoriTime' || processType === 'drillingTime' || processType === 'jointTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('punchProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('punchProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownLaserIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpLaserIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('punchProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownLaserIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpLaserIQ3' + i).style.display = 'block';
    }
  };

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setPunchAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmBlankPunch);
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <>
      {props?.tabOpen && props?.isPunch ? (
        <div className="kouteiCalculateDiv">
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={Blanking}>
            <Col span={4} className="oyabuhin-col">
              <div style={{ display: 'flex' }}>
                {isBlankingdetail ? (
                  <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                )}
                <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
              </div>
            </Col>
            <Col span={6} className="oyabuhin-col">
              <div>{punchAmt ? JPYs.format(Math.round(punchAmt)) : amtEmptyStr}</div>
            </Col>
            <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
              <div>
                <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
              </div>
            </Col>
          </Row>
          <div style={{ display: isBlankingdetail ? 'block' : 'none' }}>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                工程詳細
              </Col>
              <Col span={6} className="oyabuhin-detail-col">
                <Checkbox
                  key={kouteiCalculateSelectedArr[0].key}
                  value={kouteiCalculateSelectedArr[0].value}
                  checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
                  disabled={!props?.editMode}
                  style={{ marginRight: 6 }}
                  onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
                ></Checkbox>
                <label>{kouteiCalculateSelectedArr[0].value}</label>
              </Col>
              <Col span={8} className="oyabuhin-detail-col">
                <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                  <Checkbox
                    key={kouteiCalculateSelectedArr[1].key}
                    value={kouteiCalculateSelectedArr[1].value}
                    disabled={!props?.editMode}
                    onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                    checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                    style={{ marginRight: 6 }}
                  ></Checkbox>
                  <label>{kouteiCalculateSelectedArr[1].value}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                  <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                    {DataButton}
                  </Button>
                </div>
              </Col>

              <Col span={1} className="oyabuhin-detail-col">
                単位
              </Col>
              <Col span={5} className="formula-column">
                計算式
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                {processMst?.name}
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalAmtIn"
                  style={{
                    color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
                  }}
                >
                  {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalTimeIn"
                  style={{
                    color: totalTime === totalTimeSum ? 'black' : '#4cc12f',
                  }}
                >
                  {totalTime ? secondsToHms(totalTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.procTotalAmt_laser}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.procTotalTm_laser}>時間</Popover>
              </Col>
            </Row>
            {/** 段取金額/時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={dandoriDetails}>
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  {isSagyoDetail ? (
                    <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                  )}
                  <label> 段取金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriAmtBlank">
                  {processArr[0]?.dandoriAmt ? JPYs.format(Math.round(processArr[0]?.dandoriAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriTimeBlank">
                  {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriAmtBlank"
                  style={{
                    color: editedData[0]?.dandoriAmt === processArr[0]?.dandoriAmt ? 'black' : '#4cc12f',
                  }}
                >
                  {editedData[0]?.dandoriAmt ? JPYs.format(Math.round(editedData[0]?.dandoriAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriTimeBlank"
                  style={{
                    color: editedData[0]?.dandoriTime === processArr[0]?.dandoriTime ? 'black' : '#4cc12f',
                  }}
                >
                  {editedData[0]?.dandoriTime ? secondsToHms(editedData[0]?.dandoriTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.setupTm_p5}>時間</Popover>
              </Col>
            </Row>
            <div style={{ display: isDandoriDetail ? 'block' : 'none' }}>
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '25px' }}>
                    <label>基本段取金額/時間</label>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id="dandoriAmtBasic">
                    {processArr[0]?.dandoriDetails?.dandoriAmtBasic
                      ? JPYs.format(Math.round(processArr[0]?.dandoriDetails?.dandoriAmtBasic))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id="dandoriTmBasic">
                    {processArr[0]?.dandoriDetails?.dandoriTmBasic
                      ? secondsToHms(processArr[0]?.dandoriDetails?.dandoriTmBasic)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'dandoriAmtBasicIn'}
                    value={
                      editedData[0]?.dandoriDetails?.dandoriAmtBasic
                        ? Math.round(editedData[0]?.dandoriDetails?.dandoriAmtBasic)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.dandoriDetails?.dandoriAmtBasic != e) {
                        settingNewDandoriAmtInArr(e, 0, 'dandoriAmtBasic', '', '', 'dandori');
                        checkInputWithColor(false, '', 'dandoriAmtBasic', e, 'amt');
                      }
                    }}
                    /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(false,'', 'dandoriAmt', e, 'amt');
                            }
                          }} */
                    style={{
                      border: 'none',
                      color:
                        processArr[0]?.dandoriDetails?.dandoriAmtBasic ===
                        editedData[0]?.dandoriDetails?.dandoriAmtBasic
                          ? 'black'
                          : checkShowHideIcon('dandoriAmtBasicCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />
                  <div id={'dandoriAmtBasicCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.dandoriDetails?.dandoriTmBasic
                            ? secondsToHms(editedData[0]?.dandoriDetails?.dandoriTmBasic)
                            : timeHmsStr
                        }
                        id={'dandoriTmBasicIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.dandoriDetails?.dandoriTmBasic ===
                            processArr[0]?.dandoriDetails?.dandoriTmBasic
                              ? 'black'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          //disableTimer('test');
                          settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTmBasic', 'input', 'input', 'dandori');
                          // checkInput( 'dandoriTime', e.target.value);
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmBasic,
                                '10min',
                                'dandoriTmBasic'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmBasic', '10min', 'inc', 'dandori');
                              /*  settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTime', 'input', 'input'); */
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmBasic,
                                '10min',
                                'dandoriTmBasic'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmBasic', '10min', 'dec', 'dandori');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmBasic,
                                'min',
                                'dandoriTmBasic'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmBasic', 'min', 'inc', 'dandori');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmBasic,
                                'min',
                                'dandoriTmBasic'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmBasic', 'min', 'dec', 'dandori');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmBasic,
                                'sec',
                                'dandoriTmBasic'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmBasic', 'sec', 'inc', 'dandori');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmBasic,
                                'sec',
                                'dandoriTmBasic'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmBasic', 'sec', 'dec', 'dandori');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={5} className="formula-column"></Col>
              </Row>
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '10px' }}>
                    {isMoldCntDetail ? (
                      <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} onClick={moldCntDetails} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} onClick={moldCntDetails} />
                    )}
                    <label>金型段取金額/時間</label>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id="dandoriAmtMold">
                    {processArr[0]?.dandoriDetails?.dandoriAmtMold
                      ? JPYs.format(Math.round(processArr[0]?.dandoriDetails?.dandoriAmtMold))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id="dandoriTmMold">
                    {processArr[0]?.dandoriDetails?.dandoriTmMold
                      ? secondsToHms(processArr[0]?.dandoriDetails?.dandoriTmMold)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'dandoriAmtMoldIn'}
                    value={
                      editedData[0]?.dandoriDetails?.dandoriAmtMold
                        ? Math.round(editedData[0]?.dandoriDetails?.dandoriAmtMold)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.dandoriDetails?.dandoriAmtMold != e) {
                        settingNewDandoriAmtInArr(e, 0, 'dandoriAmtMold', '', '', 'dandori');
                        checkInputWithColor(false, '', 'dandoriAmtMold', e, 'amt');
                      }
                    }}
                    /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(false,'', 'dandoriAmt', e, 'amt');
                            }
                          }} */
                    style={{
                      border: 'none',
                      color:
                        processArr[0]?.dandoriDetails?.dandoriAmtMold === editedData[0]?.dandoriDetails?.dandoriAmtMold
                          ? 'black'
                          : checkShowHideIcon('dandoriAmtMoldCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />
                  <div id={'dandoriAmtMoldCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.dandoriDetails?.dandoriTmMold
                            ? secondsToHms(editedData[0]?.dandoriDetails?.dandoriTmMold)
                            : timeHmsStr
                        }
                        id={'dandoriTmMoldIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.dandoriDetails?.dandoriTmMold ===
                            processArr[0]?.dandoriDetails?.dandoriTmMold
                              ? 'black'
                              : '#4cc12f',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          //disableTimer('test');
                          settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTmMold', 'input', 'input', 'dandori');
                          // checkInput( 'dandoriTime', e.target.value);
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmMold,
                                '10min',
                                'dandoriTmMold'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmMold', '10min', 'inc', 'dandori');
                              /*  settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTime', 'input', 'input'); */
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmMold,
                                '10min',
                                'dandoriTmMold'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmMold', '10min', 'dec', 'dandori');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmMold,
                                'min',
                                'dandoriTmMold'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmMold', 'min', 'inc', 'dandori');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmMold,
                                'min',
                                'dandoriTmMold'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmMold', 'min', 'dec', 'dandori');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmMold,
                                'sec',
                                'dandoriTmMold'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmMold', 'sec', 'inc', 'dandori');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.dandoriDetails?.dandoriTmMold,
                                'sec',
                                'dandoriTmMold'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTmMold', 'sec', 'dec', 'dandori');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={5} className="formula-column"></Col>
              </Row>
              <div style={{ display: isMoldCntDetail ? 'block' : 'none' }}>
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex', marginLeft: '32px' }}>
                      <label>使用金型本数</label>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="dandoriMoldCnt">
                      {processArr[0]?.dandoriDetails?.dandoriMoldCnt
                        ? processArr[0]?.dandoriDetails?.dandoriMoldCnt
                        : 0}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col" />
                  <Col span={4} className="input-col">
                    <Input
                      value={
                        editedData[0]?.dandoriDetails?.dandoriMoldCnt
                          ? editedData[0]?.dandoriDetails?.dandoriMoldCnt
                          : 0
                      }
                      id={'dandoriMoldCnt'}
                      style={{
                        width: '45%',
                        color:
                          editedData[0]?.dandoriDetails?.dandoriMoldCnt ===
                          processArr[0]?.dandoriDetails?.dandoriMoldCnt
                            ? 'black'
                            : 'red',
                      }}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      onChange={(e) => {
                        settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriMoldCnt', 'input', 'input', 'dandori');
                      }}
                    ></Input>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col" />
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
              </div>
            </div>
            {/** 加工金額/時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={sagyoDetail}>
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  {isSagyoDetail ? (
                    <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                  )}
                  <label> 加工金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoAmtBlank">
                  {processArr[0]?.sagyoAmt ? JPYs.format(Math.round(processArr[0]?.sagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoTimeBlank">
                  {processArr[0]?.sagyoTime ? secondsToHms(processArr[0]?.sagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoAmtBlankIn"
                  style={{
                    color: editedData[0]?.sagyoAmt === processArr[0]?.sagyoAmt ? 'black' : '#4cc12f',
                  }}
                >
                  {editedData[0]?.sagyoAmt ? JPYs.format(Math.round(editedData[0]?.sagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoTimeBlankIn"
                  style={{
                    color: editedData[0]?.sagyoTime === processArr[0]?.sagyoTime ? 'black' : '#4cc12f',
                  }}
                >
                  {editedData[0]?.sagyoTime ? secondsToHms(editedData[0]?.sagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.workAmt_p2}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.workTotalTm_punch}>時間</Popover>
              </Col>
            </Row>
            {/** パンチ加工金額/時間 */}
            <div style={{ display: isSagyoDetail ? 'block' : 'none' }}>
              {/** パンチ */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ32'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(2)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ32'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(2)}
                    />
                    <span style={{ marginLeft: '5px' }}>パンチ加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtPunch'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtPunch
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtPunch))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmPunch'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmPunch
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmPunch)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtPunchIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtPunch
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtPunch)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoAmt != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtPunch', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtPunch', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Number(editedData[0]?.sagyoDetails?.sagyoAmtPunch) ===
                        Number(processArr[0]?.sagyoDetails?.sagyoAmtPunch)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtPunchCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtPunchCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row
                    style={{
                      width: '100%',
                    }}
                  >
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmPunch
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmPunch)
                            : timeHmsStr
                        }
                        id={'sagyoTmPunchIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoTmPunch === processArr[0]?.sagyoDetails?.sagyoTmPunch
                              ? 'black'
                              : Number(oldEditedData[0]?.sagyoDetails?.punchCnt) !==
                                  Number(editedData[0]?.sagyoDetails?.punchCnt) ||
                                Number(oldEditedData[0]?.sagyoDetails?.punchTm) !==
                                  Number(editedData[0]?.sagyoDetails?.punchTm)
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmPunch', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPunch,
                                '10min',
                                'sagyoTmPunch'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPunch', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPunch,
                                '10min',
                                'sagyoTmPunch'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPunch', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPunch,
                                'min',
                                'sagyoTmPunch'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPunch', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPunch,
                                'min',
                                'sagyoTmPunch'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPunch', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPunch,
                                'sec',
                                'sagyoTmPunch'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPunch', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmPunch,
                                'sec',
                                'sagyoTmPunch'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmPunch', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_punch}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_punch}>時間</Popover>
                </Col>
              </Row>
              <div id={'punchProcessIQ32'} style={{ display: 'none' }}>
                {/* パンチ数/1パンチ加工時間 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      パンチ数/1パンチ加工時間
                    </span>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'punchCnt'}>
                      {processArr[0]?.sagyoDetails?.punchCnt ? Math.round(processArr[0]?.sagyoDetails?.punchCnt) : 0}
                    </label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="punchTm">
                      {processArr[0]?.sagyoDetails?.punchTm
                        ? secondsToHms(processArr[0]?.sagyoDetails?.punchTm)
                        : timeHmsStr}
                    </label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={
                        editedData[0]?.sagyoDetails?.punchCnt ? Math.round(editedData[0]?.sagyoDetails?.punchCnt) : 0
                      }
                      id={'punchCntIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.punchCnt === editedData[0]?.sagyoDetails?.punchCnt
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'punchCnt', '', '', 'sagyo')}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.punchTm
                              ? secondsToHms(editedData[0]?.sagyoDetails?.punchTm)
                              : timeHmsStr
                          }
                          id={'punchTmIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            color:
                              processArr[0]?.sagyoDetails?.punchTm === editedData[0]?.sagyoDetails?.punchTm
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, '', 'punchTm', 'input', 'input', 'sagyo')
                          }
                        />
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.punchTm,
                                  '10min',
                                  'punchTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'punchTm', '10min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.punchTm,
                                  '10min',
                                  'punchTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'punchTm', '10min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.punchTm,
                                  'min',
                                  'punchTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'punchTm', 'min', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.punchTm,
                                  'min',
                                  'punchTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'punchTm', 'min', 'dec', 'sagyo');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.punchTm,
                                  'sec',
                                  'punchTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'punchTm', 'sec', 'inc', 'sagyo');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.punchTm,
                                  'sec',
                                  'punchTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'punchTm', 'sec', 'dec', 'sagyo');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
              {/** 空送 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ34'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(4)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ34'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(4)}
                    />
                    <span style={{ marginLeft: '5px' }}>空送金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtAirDeli'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtAirDeli
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtAirDeli))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoAmtAirDeli'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmAirDeli
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmAirDeli)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtAirDeliIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtAirDeli
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtAirDeli)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoAmt != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtAirDeli', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtAirDeli', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Number(editedData[0]?.sagyoDetails?.sagyoAmtAirDeli) ===
                        Number(processArr[0]?.sagyoDetails?.sagyoAmtAirDeli)
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtAirDeliCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtAirDeliCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmAirDeli
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmAirDeli)
                            : timeHmsStr
                        }
                        id={'sagyoTmAirDeliIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli === processArr[0]?.sagyoDetails?.sagyoTmAirDeli
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.speedAirDeli !==
                                  editedData[0]?.sagyoDetails?.speedAirDeli ||
                                oldEditedData[0]?.sagyoDetails?.speedAirDeliLen !==
                                  editedData[0]?.sagyoDetails?.speedAirDeliLen
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTmAirDeli', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                '10min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                '10min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'min',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'sec',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                                'sec',
                                'sagyoTmAirDeli'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmAirDeli', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_airDistance}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_airDistance}>時間</Popover>
                </Col>
              </Row>
              <div id={'punchProcessIQ34'} style={{ display: 'none' }}>
                {/* 空送速度/空送距離 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      空送速度/空送距離
                    </span>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="speedAirDeli">{processArr[0]?.sagyoDetails?.speedAirDeli}</label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'speedAirDeliLen'}>{processArr[0]?.sagyoDetails?.speedAirDeliLen}</label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedAirDeli}
                      id={'speedAirDeliIn'}
                      min={0}
                      className={editMode ? 'input-editable' : 'input-non-editable '}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedAirDeli === editedData[0]?.sagyoDetails?.speedAirDeli
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedAirDeli', '', '', 'sagyo')}
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedAirDeli');
                      // }}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedAirDeliLen}
                      id={'speedAirDeliLenIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          Number(processArr[0]?.sagyoDetails?.speedAirDeliLen) ===
                          Number(editedData[0]?.sagyoDetails?.speedAirDeliLen)
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedAirDeliLen', '', '', 'sagyo')}
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedAirDeliLen');
                      // }}
                    />
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
              {/** ケガキ加工金額/時間 */}
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col">
                  <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <CaretUpOutlined
                      id={'arrowUpLaserIQ35'}
                      style={{ fontSize: 17, marginTop: 2 }}
                      onClick={(e) => subpartDetail(5)}
                    />
                    <CaretDownOutlined
                      id={'arrowDownLaserIQ35'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                      onClick={(e) => subpartDetail(5)}
                    />
                    <span style={{ marginLeft: '5px' }}>ケガキ加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  {/* ¥ */}
                  <label id={'sagyoAmtScribing'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtScribing
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtScribing))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col">
                  <label id={'sagyoTmScribing'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmScribing
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmScribing)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id={'sagyoAmtScribingIn'}
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtScribing
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtScribing)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtScribing != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtScribing', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtScribing', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        Math.round(Number(editedData[0]?.sagyoDetails?.sagyoAmtScribing)) ===
                        Math.round(Number(processArr[0]?.sagyoDetails?.sagyoAmtScribing))
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtScribingCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />

                  <div id={'sagyoAmtScribingCu'} style={{ display: 'none' }} className="currencyReset">
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmScribing
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmScribing)
                            : timeHmsStr
                        }
                        id={'sagyoTmScribingIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoAmtScribing ===
                            processArr[0]?.sagyoDetails?.sagyoAmtScribing
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.speedScribing !==
                                  editedData[0]?.sagyoDetails?.speedScribing ||
                                oldEditedData[0]?.sagyoDetails?.speedScribingLen !==
                                  editedData[0]?.sagyoDetails?.speedScribingLen
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmScribing', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                '10min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                '10min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'min',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'sec',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmScribing,
                                'sec',
                                'sagyoTmScribing'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmScribing', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col"></Col>
                <Col span={2} className="formula-column formula-column-right-border">
                  <Popover content={helpCmt.workAmt_scribing}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column">
                  <Popover content={helpCmt.workTm_scribing}>時間</Popover>
                </Col>
              </Row>
              <div id={'punchProcessIQ35'} style={{ display: 'none' }}>
                {/* ケガキ加工速度/切断長 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                      ケガキ加工速度/切断長
                    </span>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="speedScribing">{processArr[0]?.sagyoDetails?.speedScribing}</label>
                  </Col>

                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={'speedScribingLen'}>{processArr[0]?.sagyoDetails?.speedScribingLen}</label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedScribing}
                      id={'speedScribingIn'}
                      min={0}
                      className={editMode ? 'input-editable' : 'input-non-editable '}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedScribing === editedData[0]?.sagyoDetails?.speedScribing
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedScribing', '', '', 'sagyo')}
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedScribing');
                      // }}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.speedScribingLen}
                      id={'speedScribingLenIn'}
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          processArr[0]?.sagyoDetails?.speedScribingLen ===
                          editedData[0]?.sagyoDetails?.speedScribingLen
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      onChange={(e) =>
                        settingNewDandoriAmtInArr(e.target.value, 0, 'speedScribingLen', '', '', 'sagyo')
                      }
                      // onBlur={(e) => {
                      //   toTwoDecimal(0, 'speedScribingLen');
                      // }}
                    />
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={4} className="formula-column"></Col>
                </Row>
              </div>
            </div>
            {/** 2工程穴加工金額/時間 */}
            <div>
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <div style={{ display: 'flex' }}>
                    <CaretUpOutlined
                      id={'arrowUpBlankDrilling'}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                    />

                    <CaretDownOutlined id={'arrowDownBlankDrilling'} style={{ fontSize: 17, marginTop: 2 }} />
                    <span>2工程穴加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <label id={'sagyoAmtTwoStepHole'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <label id={'sagyoTmTwoStepHole'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id="sagyoAmtTwoStepHoleIn"
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtTwoStepHole', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtTwoStepHole', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole ===
                        editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtTwoStepHoleCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />
                  <div id={'sagyoAmtTwoStepHoleCu'} style={{ display: 'none' }}>
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole)
                            : timeHmsStr
                        }
                        id={'sagyoTmTwoStepHoleIn'}
                        style={{
                          width: '100%',
                          color:
                            processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole ===
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole
                              ? 'black'
                              : oldEditedData[0]?.twoStepHoleCnt !== editedData[0]?.sagyoDetails?.twoStepHoleCnt ||
                                oldEditedData[0]?.twoStepHoleTm !== editedData[0]?.sagyoDetails?.twoStepHoleTm
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          //disableTimer('test');
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmTwoStepHole', 'input', 'input', 'sagyo');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                '10min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                '10min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'min',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'sec',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                                'sec',
                                'sagyoTmTwoStepHole'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmTwoStepHole', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}></Col>
                <Col
                  span={2}
                  className="formula-column formula-column-right-border"
                  onClick={(e) => handleCollpse('BlankDrilling')}
                >
                  <Popover content={helpCmt.workAmt_twoStepHole}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column" onClick={(e) => handleCollpse('BlankDrilling')}>
                  <Popover content={helpCmt.workTm_twoStepHole}>時間</Popover>
                </Col>
              </Row>

              <div id={'divBlankDrilling'} style={{ display: 'none' }}>
                <Row className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="drillingCount">{processArr[0]?.sagyoDetails?.twoStepHoleCnt}</label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="drillingCountTime">
                      {processArr[0]?.sagyoDetails?.twoStepHoleTm
                        ? secondsToHms(processArr[0]?.sagyoDetails?.twoStepHoleTm)
                        : timeHmsStr}
                    </label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.twoStepHoleCnt}
                      id="drillingCountIn"
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          editedData[0]?.sagyoDetails?.twoStepHoleCnt === processArr[0]?.sagyoDetails?.twoStepHoleCnt
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) =>
                        settingNewDandoriAmtInArr(e.target.value, 0, 'twoStepHoleCnt', '', '', 'twoStepHoleCnt')
                      }
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.twoStepHoleTm
                              ? secondsToHms(editedData[0]?.sagyoDetails?.twoStepHoleTm)
                              : timeHmsStr
                          }
                          id="drillingCountTimeIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            color:
                              editedData[0]?.sagyoDetails?.twoStepHoleTm === processArr[0]?.sagyoDetails?.twoStepHoleTm
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(
                              e.target.value,
                              0,
                              'twoStepHoleTm',
                              'input',
                              'input',
                              'twoStepHoleTm'
                            )
                          }
                        />
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  '10min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  '10min',
                                  'inc',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  '10min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  '10min',
                                  'dec',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'min',
                                  'inc',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'min',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'min',
                                  'dec',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'sec',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'sec',
                                  'inc',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.twoStepHoleTm,
                                  'sec',
                                  'twoStepHoleTm'
                                );
                                settingNewDandoriAmtInArr(
                                  editedTime,
                                  0,

                                  'twoStepHoleTm',
                                  'sec',
                                  'dec',
                                  'twoStepHoleTm'
                                );
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
              </div>
            </div>
            {/* ジョイントバラシ加工金額/時間 */}
            <div>
              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
                  <div style={{ display: 'flex' }}>
                    <CaretUpOutlined id={'arrowUpBlankJoint'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

                    <CaretDownOutlined id={'arrowDownBlankJoint'} style={{ fontSize: 17, marginTop: 2 }} />
                    <span style={{ fontSize: 11 }}>ジョイントバラシ加工金額/時間</span>
                  </div>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
                  <label id={'sagyoAmtJoint'}>
                    {processArr[0]?.sagyoDetails?.sagyoAmtJoint
                      ? JPYs.format(Math.round(processArr[0]?.sagyoDetails?.sagyoAmtJoint))
                      : amtEmptyStr}
                  </label>
                </Col>
                <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
                  <label id={'sagyoTmJoint'}>
                    {processArr[0]?.sagyoDetails?.sagyoTmJoint
                      ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmJoint)
                      : timeHmsStr}
                  </label>
                </Col>
                <Col span={4} className="input-col">
                  <CurrencyInput
                    id="sagyoAmtJointIn"
                    value={
                      editedData[0]?.sagyoDetails?.sagyoAmtJoint
                        ? Math.round(editedData[0]?.sagyoDetails?.sagyoAmtJoint)
                        : 0
                    }
                    defaultValue={0}
                    onValueChange={(e) => {
                      if (editedData[0]?.sagyoDetails?.sagyoAmtJoint != e) {
                        settingNewDandoriAmtInArr(e, 0, 'sagyoAmtJoint', '', '', 'sagyo');
                        checkInputWithColor(false, '', 'sagyoAmtJoint', e, 'amt');
                      }
                    }}
                    style={{
                      border: 'none',
                      color:
                        editedData[0]?.sagyoDetails?.sagyoAmtJoint === processArr[0]?.sagyoDetails?.sagyoAmtJoint
                          ? 'black'
                          : checkShowHideIcon('sagyoAmtJointCu') === 'none'
                          ? '#4cc12f'
                          : 'red',
                    }}
                    className={
                      editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                  />
                  <div id={'sagyoAmtJointCu'} style={{ display: 'none' }}>
                    <div className="currencyIcon">
                      <BsUsbC style={{ fontSize: '25' }} />
                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                      <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                    </div>
                  </div>
                </Col>
                <Col span={4} className="input-col">
                  <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={
                          editedData[0]?.sagyoDetails?.sagyoTmJoint
                            ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmJoint)
                            : timeHmsStr
                        }
                        id={'sagyoTmJointIn'}
                        style={{
                          width: '100%',
                          color:
                            editedData[0]?.sagyoDetails?.sagyoTmJoint === processArr[0]?.sagyoDetails?.sagyoTmJoint
                              ? 'black'
                              : oldEditedData[0]?.sagyoDetails?.sagyoDetails?.jointCnt !== editedData[0]?.jointCnt ||
                                oldEditedData[0]?.sagyoDetails?.jointTm !== editedData[0]?.sagyoDetails?.jointTm
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          //disableTimer('test');
                          settingNewDandoriAmtInArr(e.target.value, 0, 'sagyoTmJoint', 'input', 'input', 'sagyo');
                          // settingNewDandoriAmtInArr(e.target.value, 0, 'kensaTime', 'input', 'input');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                '10min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', '10min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                '10min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', '10min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'min', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'min',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'min', 'dec', 'sagyo');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'sec',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'sec', 'inc', 'sagyo');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[0]?.sagyoDetails?.sagyoTmJoint,
                                'sec',
                                'sagyoTmJoint'
                              );
                              settingNewDandoriAmtInArr(editedTime, 0, 'sagyoTmJoint', 'sec', 'dec', 'sagyo');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}></Col>
                <Col
                  span={2}
                  className="formula-column formula-column-right-border"
                  onClick={(e) => handleCollpse('BlankJoint')}
                >
                  <Popover content={helpCmt.workAmt_joint}>金額</Popover>
                </Col>
                <Col span={2} className="formula-column" onClick={(e) => handleCollpse('BlankJoint')}>
                  <Popover content={helpCmt.workTm_joint}>時間</Popover>
                </Col>
              </Row>

              <div id={'divBlankJoint'} style={{ display: 'none' }}>
                <Row className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="jointCnt">{processArr[0]?.sagyoDetails?.jointCnt}</label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id="jointCntTime">
                      {processArr[0]?.sagyoDetails?.jointTm
                        ? secondsToHms(processArr[0]?.sagyoDetails?.jointTm)
                        : timeHmsStr}
                    </label>
                  </Col>

                  <Col span={4} className="input-col">
                    <Input
                      value={editedData[0]?.sagyoDetails?.jointCnt}
                      id="jointCntIn"
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '45%',
                        border: 'none',
                        color:
                          parseFloat(editedData[0]?.sagyoDetails?.jointCnt) === processArr[0]?.sagyoDetails?.jointCnt
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'jointCnt', '', '', 'jointCnt')}
                    />
                  </Col>

                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={
                            editedData[0]?.sagyoDetails?.jointTm
                              ? secondsToHms(editedData[0]?.sagyoDetails?.jointTm)
                              : timeHmsStr
                          }
                          id="jointTmIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            color:
                              editedData[0]?.sagyoDetails?.jointTm === processArr[0]?.sagyoDetails?.jointTm
                                ? 'black'
                                : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) =>
                            settingNewDandoriAmtInArr(e.target.value, 0, 'jointTm', 'input', 'input', 'jointTm')
                          }
                        />
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  '10min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', '10min', 'inc', 'jointTm');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  '10min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', '10min', 'dec', 'jointTm');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'min', 'inc', 'jointTm');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'min',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'min', 'dec', 'jointTm');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'sec',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'sec', 'inc', 'jointTm');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[0]?.sagyoDetails?.jointTm,
                                  'sec',
                                  'jointTm'
                                );
                                settingNewDandoriAmtInArr(editedTime, 0, 'jointTm', 'sec', 'dec', 'jointTm');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiCalculate_BlankPunch;
