/**
 * クラス名：工程マスター詳細画面
 * 説明：工程マスター詳細画面である
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { Component, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, Row, Col, Select, Button, Radio, Image, Form, Collapse, Checkbox } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table as BootstrapTable } from 'react-bootstrap';
import { Input, DatePicker } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  PlusCircleFilled,
  CloseOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { formValidatorMode } from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';
import { error } from '../../../common/CommonModal';
import Draggable from "react-draggable";

const showProcessDetail = (i) => {
  let showHide = document.getElementById('detailPart' + i).style.display;
  if (showHide == 'block') {
    document.getElementById('detailPart' + i).style.display = 'none';
    document.getElementById('arrowUpDetail' + i).style.display = 'none';
    document.getElementById('arrowDownDetail' + i).style.display = 'block';
  } else {
    document.getElementById('detailPart' + i).style.display = 'block';
    document.getElementById('arrowUpDetail' + i).style.display = 'block';
    document.getElementById('arrowDownDetail' + i).style.display = 'none';
  }
};

const nameChangeArr = [500, 600, 700, 11000, 11100];
const noAddBtnArr = [
  400, 500, 600, 11000, 10510, 10520, 10200, 10220, 10230, 20100, 20200, 20300, 20400, 20500, 20600, 20700, 20800,
  20900, 21000, 21100,
];
const noAddButEdit = [100, 10600, 10400, 10300];
const defaultRowArr = [];
//const noAddBtnArr = ['組立', '検査', '梱包', 'バリ取', 'ブランク'];
//const nameChangeArr = [カスタム, 検査, 梱包];
//const noAddButEdit = ['溶接', 'ベンディング', '2次加工'];

const ProcessMasterDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    formValidation: formValidation,
  }));

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [updDateFlg, setUpdDateFlg] = useState(false);
  const [detailNameArr, setDetailNameArr] = useState([]);

  const inputItemRef = {};

  //詳細工程テーブルに新しい行追加
  const addRows = (index, jIndex) => {
    setSelectedRowData((prevData) => {
      const updatedData = JSON.parse(JSON.stringify(prevData));
      const processDetail = updatedData.processDetail[index] || [];
      const processDetailTypes = prevData?.details.processDetailTypes;
      const detailValueLength = processDetail.processDetailValue?.length + 1;
      let detailLastRow = 0;
      updatedData?.processDetail?.forEach((processDetail) => {
        detailLastRow += processDetail.processDetailValue?.length;
      });

      const detailValue = {
        /* key: detailValueLength,
        processDetailNo: detailValueLength, */
        id: detailLastRow + 1 /* processDetailTypes[processDetailTypes?.length - 1].id + 1 */,
        isUsed: true,
        code: detailValueLength.toString().padStart(3, '0'),
        name: '',
        created: dayjs().format('YYYY-MM-DD'),
        modified: dayjs().format('YYYY-MM-DD'),
        remarks: '',
        group: processDetail.group,
      };
      processDetail.processDetailValue.splice(jIndex, 0, detailValue);
      updatedData.processDetail[index] = processDetail;
      setOriginData(updatedData);
      setUpdDateFlg(true);
      return updatedData;
    });
  };

  //詳細工程テーブルの行削除
  const deleteRows = (indexi, indexj) => {
    setSelectedRowData((prevData) => {
      const updatedData = JSON.parse(JSON.stringify(prevData));
      const processDetail = updatedData.processDetail[indexi];
      if (
        processDetail &&
        processDetail.processDetailValue?.length > 1 &&
        /* !processDetail.processDetailValue[indexj].useFlg && */
        processDetail.processDetailValue?.length > indexj
      ) {
        processDetail.processDetailValue.splice(indexj, 1);
      }
      setOriginData(updatedData);
      return updatedData;
    });
  };

  const editDetailInput = (indexi, indexj, objKey, value) => {
    let showData = JSON.parse(JSON.stringify(selectedRowData));
    let updatedData = JSON.parse(JSON.stringify(originData));
    setSelectedRowData(() => {
      if (indexi !== '' && indexj !== '') {
        let processDetailValueEdt = showData.processDetail[indexi].processDetailValue || [];
        processDetailValueEdt[indexj][objKey] = value;
        showData.processDetail[indexi].processDetailValue = processDetailValueEdt;
        // 更新用Stateオブジェクト

        let processDetailValue = updatedData.processDetail[indexi].processDetailValue || [];
        processDetailValue[indexj][objKey] = value;
        processDetailValue[indexj]['modified'] = dayjs(dayjs()).format('YYYY-MM-DD');
      } else {
        let isValueChanged = String(updatedData[objKey]) !== String(value);
        updatedData[objKey] = value;
        showData[objKey] = value;
        if (objKey === 'remarks' || objKey === 'isUsed') {
          isValueChanged = String(updatedData?.info[objKey]) !== String(value);
          if (updatedData && updatedData.info && showData && showData.info) {
            updatedData.info[objKey] = value;
            showData.info[objKey] = value;
          }
        } else if (objKey === 'processQty') {
          isValueChanged = String(updatedData?.details[objKey]) !== String(value);
          if (updatedData && updatedData.details && showData && showData.details) {
            updatedData.details[objKey] = value;
            showData.details[objKey] = value;
          }
        }
        setUpdDateFlg(isValueChanged);
      }
      setOriginData(updatedData);
      return showData;
    });
  };

  useEffect(() => {
    if (Object.keys(props.selectedData)?.length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      let origin = JSON.parse(JSON.stringify(props.selectedData));
      let processDetail = selectedData.processDetail || [];
      let processDetailNameArr = {};
      for (let i = 0; i < processDetail?.length; i++) {
        processDetailNameArr[i] = processDetail[i].processDetailTitle;
      }
      setDetailNameArr(processDetailNameArr);
      setOriginData(origin);
      setSelectedRowData(selectedData);
    }
  }, [props.selectedData]);

  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const getSelectedRowData = () => {
    let selectedRowData = props.selectedData;
    //let selectedRowData = originData;
    setSelectedRowData(selectedRowData);
  };

  const editData = () => {
    /* setEditMode(true); */
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };
  const updateData = () => {
    setUpdateConfirm(true);
    setIsUpdate(true);
  };
  const discardData = () => {
    setCancelConfirm(true);
  };

  const updEditDate = (obj, objOrigin) => {
    let numKey = ['displayFlg', 'detailCode', 'detailType', 'remark'];
    let isUpd = false;
    numKey?.forEach((k) => {
      if (objOrigin[k] !== obj[k]) {
        isUpd = true;
      }
    });
    return isUpd;
  };

  const updConfirmOk = (e) => {
    setUpdateConfirm(false);
    if (isUpdate) {
      // 画面の入力チェック
      if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      if (props.selectedData && props.selectedData.key === originData.key) {
        props.selectedData.code = originData.code;
        props.selectedData.info.isUsed = originData.info.isUsed;
        props.selectedData.name = originData.name;
        props.selectedData.processType = originData.processType;
        props.selectedData.info.remarks = originData.info.remarks;

        if (props.selectedData.details.processQty !== undefined) {
          props.selectedData.details.processQty = originData.details.processQty;
        }

        const unmatchedElements = [];
        for (let i = 0; i < originData.processDetail?.length; i++) {
          let detailValueOld = props.selectedData.processDetail[i].processDetailValue;
          let detailValueNew = originData.processDetail[i].processDetailValue;
          props.selectedData.processDetail[i].processDetailValue = detailValueNew;

          const unmatchedValues = detailValueNew?.filter(
            (newItem) =>
              !detailValueOld.some(
                (oldItem) =>
                  newItem.key === oldItem.key && newItem.code === oldItem.code && newItem.name === oldItem.name
              )
          );
          unmatchedElements.push(unmatchedValues);
        }
        if (updDateFlg || unmatchedElements?.length > 0) {
          props.selectedData.modified = dayjs().format('YYYY-MM-DD');
        }
        props.submitData(props.selectedData);
      }
      setSelectedRowData(originData);
      setIsUpdate(false);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    /* setEditMode(false); */
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    getSelectedRowData();
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  function getDetailName(inputString, substring) {
    const index = inputString.indexOf(substring);
    let detailName;
    let i;
    if (index !== -1 && index + substring?.length < inputString?.length) {
      i = parseInt(inputString[index + substring?.length]);
    } else i = -1;

    if (i >= 0) {
      return detailNameArr[i];
    }
    return null;
  }

  // 画面の入力チェック
  const formValidation = (inputItems, procMode) => {
    let ret = true;
    let message = [];
    let detailCodeArr,
      detailNameArr = [];
    let lineNo = 0;
    let count = 0;
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }
    // ロット数別単価の上限個数
    detailCodeArr = Object.keys(inputItems)?.filter((v) => v.startsWith('detailCode'));
    detailNameArr = Object.keys(inputItems)?.filter((v) => v.startsWith('detailName'));

    detailCodeArr?.map((name) => {
      let detailName = getDetailName(name, 'detailCode');
      input = inputItems[name]?.input;
      input?.classList?.remove('input-error');
      ++count;
      if (input?.value?.length <= 0) {
        // 詳細テーブルコード
        message.push(<li>{ErrorMessage(detailName + 'テーブル' + count + '行目のコード').E007}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      }
      /*  if (input?.value?.length > 0  && !isDigit(input?.value) ) {
        message.push(<li>{ErrorMessage('上限個数の' + lineNo + '行目').E009}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      } */
      return true;
    });

    detailNameArr?.map((name) => {
      let detailName = getDetailName(name, 'detailName');
      input = inputItems[name]?.input;
      input?.classList?.remove('input-error');
      ++lineNo;
      if (input?.value?.length <= 0) {
        // 詳細テーブル種類
        message.push(<li>{ErrorMessage(detailName + 'テーブル ' + lineNo + '行目の種類').E007}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      }
      return true;
    });

    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  return (
    <>
      <Form>
        {/* ボタン部分 */}
        <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
          <div>
            <Button className="mainButton" style={{ marginLeft: 10, display: 'none' }} id="update" onClick={updateData}>
              更新
            </Button>
          </div>
          <div>
            <Button
              className="cancelButton"
              style={{ marginLeft: 10, display: 'none' }}
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button className="mainButton" id="edit" onClick={editData}>
              編集
            </Button>
          </div>
        </div>
        {/* プロパティ表示 */}

        <div
          id="showData"
          className="kouteiMaster overflow-auto"
          style={{ height: 741, marginTop: 8, textAlign: 'center' }}
        >
          <div className="upperTbl">
            <BootstrapTable className="kouteiProcess" style={{ width: '98%' }}>
              <thead style={{ backgroundColor: '#fafafa', color: '#1063aa', height: 20 }}>
                <tr style={{ textAlign: 'center' }}>
                  <th>No</th>
                  <th>分類</th>
                  <th>種別</th>
                  <th>コード</th>
                  <th>工程名</th>
                  <th>工程タイプ</th>
                  <th>備考</th>
                  <th>表示</th>
                </tr>
              </thead>

              <tbody style={{ textAlign: 'center' }}>
                <tr>
                  <td style={{ width: '6%' }}>
                    <label>
                      {/* {selectedRowData.No} */}
                      {props.itemNo}
                    </label>
                  </td>

                  <td style={{ width: '6%' }}>
                    <label>{selectedRowData.relatedClass}</label>
                  </td>
                  <td style={{ width: '16%' }}>
                    <label>{selectedRowData.isAdditionalRelated}</label>
                  </td>
                  <td style={{ width: '16%' }}>
                    <Input
                      value={selectedRowData.code}
                      id={'code'}
                      style={{ width: '100%', textAlign: 'center' }}
                      className={props.editMode ? 'input-editable' : 'input-non-editable'}
                      onChange={(e) => editDetailInput('', '', 'code', e.target.value)}
                      ref={(ref) => {
                        inputItemRef['code'] = ref;
                      }}
                    ></Input>
                  </td>
                  <td style={{ width: '16%' }}>
                    {nameChangeArr?.includes(selectedRowData.workType) ? (
                      <Input
                        value={selectedRowData.name}
                        id={'processName'}
                        style={{ width: '100%', textAlign: 'center' }}
                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                        onChange={(e) => editDetailInput('', '', 'name', e.target.value)}
                        ref={(ref) => {
                          inputItemRef['name'] = ref;
                        }}
                      ></Input>
                    ) : (
                      <label>{selectedRowData.name}</label>
                    )}
                  </td>
                  <td style={{ width: '16%' }}>
                    <label>{selectedRowData.relatedType}</label>
                  </td>

                  <td style={{ width: '16%' }}>
                    <Input
                      value={selectedRowData?.info?.remarks}
                      id={'remark'}
                      style={{ width: '100%', textAlign: 'center!important', height: '20px!important' }}
                      className={props.editMode ? 'input-editable' : 'input-non-editable'}
                      onChange={(e) => editDetailInput('', '', 'remarks', e.target.value)}
                    ></Input>
                  </td>
                  <td style={{ width: '6%' }}>
                    <Checkbox
                      checked={selectedRowData?.info?.isUsed == true}
                      disabled={!props.editMode || ![700, 11100]?.includes(selectedRowData.workType)}
                      style={{ marginRight: 6 }}
                      onChange={(e) => editDetailInput('', '', 'isUsed', e.target.checked)}
                    ></Checkbox>
                  </td>
                </tr>
              </tbody>
            </BootstrapTable>
          </div>
          {selectedRowData?.details?.processQty !== 0 &&
            selectedRowData?.details?.processQty !== undefined &&
            selectedRowData?.details?.processQty !== null && (
              <div class="numOfProcess">
                <Row>
                  <Col span={3}>
                    <BootstrapTable className="detailInfo" style={{ marginLeft: 10, marginBottom: 8, width: '95%' }}>
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th>加工処理数</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {props.editMode ? (
                              <Select
                                defaultValue="4"
                                style={{
                                  width: '100%',
                                  fontSize: 13,
                                }}
                                className="numOfProcessDDL"
                                value={selectedRowData?.details?.processQty}
                                onChange={(e) => editDetailInput('', '', 'processQty', e)}
                                options={[4, 5, 6, 7, 8, 9, 10]?.map((province) => ({
                                  label: province,
                                  value: province,
                                }))}
                              />
                            ) : (
                              <label>{selectedRowData?.details?.processQty}</label>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </BootstrapTable>
                  </Col>
                </Row>
              </div>
            )}
          {(() => {
            const subArr = [];
            let i = 0;
            const noAddProcess = noAddBtnArr?.includes(selectedRowData.workType);
            const noAddCanEdit = noAddButEdit?.includes(selectedRowData.workType);
            let processDetail = selectedRowData.processDetail || [];
            for (i = 0; i < processDetail?.length; i++) {
              let index = i;

              subArr.push(
                <div className="detailTbl">
                  <Row
                    style={{ color: 'black' }}
                    className="oyabuhin oyabuhin-row"
                    onClick={(e) => showProcessDetail(index)}
                  >
                    <Col span={20} className="deli-col" style={{ padding: '4px 12px ' }}>
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>{processDetail[i].processDetailTitle}</label>
                        <CaretUpOutlined id={'arrowUpDetail' + i} style={{ fontSize: 18, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownDetail' + i}
                          style={{ fontSize: 18, marginTop: 2, display: 'none' }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div id={'detailPart' + i} style={{ display: 'block', margin: '3px 10px 8px' }}>
                    <Row>
                      <Col span={24}>
                        <BootstrapTable className="detailInfo">
                          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                              <th style={{ width: '6%' }}>No</th>
                              <th style={{ width: '6%' }}>使用中</th>
                              <th style={{ width: '16%' }}>コード</th>
                              <th style={{ width: '16%' }}>種類</th>
                              <th style={{ width: '16%' }}>作成日</th>
                              <th style={{ width: '16%' }}>更新日</th>
                              <th style={{ width: '16%' }}>備考</th>
                            </tr>
                          </thead>

                          <tbody>
                            {(() => {
                              const tblRow = [];
                              let processDetailValue =
                                { ...selectedRowData }?.processDetail?.[i]?.processDetailValue || [];

                              for (let j = 0; j < processDetailValue?.length; j++) {
                                let idStr = String(index) + String(j);
                                tblRow.push(
                                  <tr>
                                    <td style={{ width: '6%' }}>{j + 1}</td>
                                    <td style={{ width: '6%' }}>
                                      <Checkbox
                                        checked={processDetailValue[j].isUsed == true}
                                        disabled={
                                          !props.editMode || [500, 600, 11000]?.includes(selectedRowData.workType)
                                        }
                                        style={{ marginRight: 6 }}
                                        onChange={(e) => editDetailInput(index, j, 'isUsed', e.target.checked)}
                                      ></Checkbox>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].code}
                                        id={'detailCode' + idStr}
                                        style={{ width: '100%' }}
                                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                                        // disabled={j < originRow ? true : false}
                                        onChange={(e) => editDetailInput(index, j, 'code', e.target.value)}
                                        ref={(ref) => {
                                          inputItemRef['detailCode' + idStr] = ref;
                                        }}
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].name}
                                        id={'detailType' + idStr}
                                        style={{ width: '100%' }}
                                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                                        disabled={noAddProcess && selectedRowData?.class !== 2}
                                        onChange={(e) => editDetailInput(index, j, 'name', e.target.value)}
                                        ref={(ref) => {
                                          inputItemRef['detailName' + idStr] = ref;
                                        }}
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].created?.split('T')[0]}
                                        id={'created' + idStr}
                                        style={{ width: '100%' }}
                                        className="input-non-editable"
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].modified?.split('T')[0]}
                                        id={'modified' + idStr}
                                        style={{ width: '100%' }}
                                        className="input-non-editable"
                                      ></Input>
                                    </td>
                                    <td style={{ width: '16%' }}>
                                      <Input
                                        value={processDetailValue[j].remarks}
                                        id={'remarks' + idStr}
                                        style={{ width: '100%' }}
                                        className={props.editMode ? 'input-editable' : 'input-non-editable'}
                                        //disabled={j < originRow ? true : false}
                                        onChange={(e) => editDetailInput(index, j, 'remarks', e.target.value)}
                                      ></Input>
                                    </td>
                                    <td style={{ border: '0px solid', textAlign: 'left' }}>
                                      {props.editMode && (
                                        <div>
                                          {!noAddProcess && !noAddCanEdit && j === processDetailValue?.length - 1 && (
                                            <PlusCircleFilled
                                              className="add-icon"
                                              onClick={() => addRows(index, j + 1)}
                                            />
                                          )}

                                          {/* {!processDetailValue[j].useFlg && j >= processDetail[index].defaultRow && (
                                            <CloseCircleFilled
                                              className="add-icon"
                                              onClick={() => deleteRows(index, j)}
                                            />
                                          )} */}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                              return tblRow;
                            })()}
                          </tbody>
                        </BootstrapTable>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            }
            return subArr;
          })()}
        </div>
      </Form>
      {/* 更新確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        modalRender={(modal) => (
          <Draggable handle=".ant-modal-header">
            <div>{modal}</div>
          </Draggable>
        )}
        width={400}
        open={updateConfirm}
        onOk={updConfirmOk}
        onCancel={updConfirmCancel}
        footer={[
          <Button key="submit14" onClick={updConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={updConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>データを更新します。よろしいでしょうか。</p>
      </Modal>
      {/* 破棄確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        modalRender={(modal) => (
          <Draggable handle=".ant-modal-header">
            <div>{modal}</div>
          </Draggable>
        )}
        width={400}
        open={cancelConfirm}
        onOk={discardConfirmOk}
        onCancel={discardConfirmCancel}
        footer={[
          <Button key="submit15" type="primary" onClick={discardConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={discardConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>データを破棄します。よろしいでしょうか。</p>
      </Modal>
    </>
  );
});

export default ProcessMasterDetails;
