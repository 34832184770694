import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Input, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  secondsToHms,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  ProcessDetailGroups,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { DataType, WorkType, MaterialSurfaceBillingUnit, ProcessDetailGroup } from '../common/enums';
import { DataButton, amtEmptyStr, timeHmsStr, helpCmt } from '../common/Constant';

const KouteiCalculate_Hyomen = forwardRef((props, ref) => {
  const [isHyomendetail, setIHyomendetail] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [hyomenAmt, setHyomenAmt] = useState(0);

  useEffect(() => {
    if (props?.activeTabKey === '4' || props.isReCal) {
      let editData = [];
      let totals = [];
      let toBEdit = [];
      let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
      let processMst = parameters?.process
        ? parameters.process?.filter((item) => item.workType === WorkType.PaSurface)[0]
        : [];
      let surfaceAdditions = props?.selectedDataDetail?.estimateProducts?.additions?.filter(
        (item) => item.processId == processMst?.id
      )[0];
      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      let surfaceAdditionsEditData = surfaceAdditions?.editItems;
      let editDataFlg = surfaceAdditions?.editDataFlg;
      let patternInfo = props?.selectedDataDetail?.estimateProducts?.processSelect
        ? props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
            (item) => item.processId == processMst?.id
          )
        : [];
      setPatternInfo([]);
      if (patternInfo == undefined) return;
      if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

      let deviceMst =
        parameters?.device && patternInfo
          ? parameters?.device?.filter(
              (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
            )[0]
          : []; //テスト用ID;
      setProcessMst(processMst ? processMst : []);
      setDeviceMst(deviceMst ? deviceMst : []);
      setPatternInfo(patternInfo);
      // 表面処理の工程入力情報取得
      let surfaceProcessInput = props?.selectedDataDetail?.estimateProducts?.processInput?.surfaceProcessInputInfo;
      // 新規の場合
      /*  if (surfaceAdditions == undefined) { */
      let detailItems = [];
      let editDetailItems = [];
      setDandoriAmtSum(0);
      setDandoriTimeSum(0);
      setSagyoAmtSum(0);
      setTotalTimeSum(0);
      setTotalSum(0);
      setTotalAmt(0);
      setTotalSagyoAmt(0);
      setTotalSagyoTime(0);
      setTotalDandoriAmt(0);
      setTotalDandoriTime(0);
      setHyomenAmt(0);
      for (let j = 0; j < surfaceProcessInput?.length; j++) {
        let calType = '面積';
        if (surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.SquareMeter) {
          calType = '面積';
        } else if (surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.Work) {
          calType = '時間';
        } else if (surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.Weight) {
          calType = '重量';
        }
        detailItems.push({
          id: j + 1,
          processName: '表面処理(No' + (j + 1) + ')',
          processDetailTypeNo: surfaceProcessInput[j].processDetailTypeNo,
          dandoriAmt: 0,
          dandoriTime: 0,
          sagyoAmt: 0,
          sagyoTime: 0,
          calMethod: calType,
          calInput:
            surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.SquareMeter ||
            surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.Work
              ? surfaceProcessInput[j].areaUnitPrice
              : surfaceProcessInput[j].weightUnitPrice,
          processDetailWorkTypeGroup: surfaceProcessInput[j].processDetailWorkTypeGroup,
          formula: '',
        });
      }

      editDetailItems = [];
      let additionsNo = 1;
      if (props?.selectedDataDetail?.estimateProducts?.additions?.length > 0) {
        let lastRecord = props?.selectedDataDetail?.estimateProducts?.additions?.sort((a, b) => b.no - a.no)?.[0];
        additionsNo = lastRecord?.no + 1;
      }

      let hyomenAddition = {
        no: processMst.id,
        processId: processMst.id,
        dataType: surfaceAdditions?.dataType ? surfaceAdditions?.dataType : DataType.Data,
        dataItems: detailItems,
        editItems: editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
        editDataFlg: editDataFlg,
      };
      surfaceAdditions = hyomenAddition;
      /*  } */
      if (surfaceAdditions?.dataItems) {
        let orgData = JSON.parse(JSON.stringify(surfaceAdditions?.dataItems));
        setOrgData(JSON.parse(JSON.stringify(surfaceAdditions?.dataItems)));
        if (deviceMst != undefined) {
          let totalDandori = 0;
          let totalSagyo = 0;
          let totalDandoriTm = 0;
          let totalSagyoTm = 0;
          //子部品数
          let partCnt = 0;
          if (props?.selectedDataDetail?.esIq3Info?.length > 0) {
            partCnt = props?.selectedDataDetail?.esIq3Info?.reduce((total, item) => {
              return total + parseInt(item.partCount);
            }, 0);
          }
          // 基本情報の総重量
          let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
          for (let i = 0; i < orgData?.length; i++) {
            let hyoumenInfo = orgData[i];
            let calInput = Number(hyoumenInfo?.calInput);
            orgData[i].calInput = calInput ? calInput : 0;
            surfaceAdditions.dataItems[i].calInput = calInput;

            let calMethod = hyoumenInfo?.calMethod;
            orgData[i].calMethod = calMethod;
            surfaceAdditions.dataItems[i].calMethod = calMethod;

            let processType = Number(hyoumenInfo.processDetailTypeNo);

            //表面/重量が0の場合、段取と加工を計算しない。
            if (calInput === 0) {
              /** 段取金額/時間 */
              surfaceAdditions.dataItems[i].dandoriTime = 0;
              orgData[i].dandoriTime = 0;
              // 段取金額設定
              //段階時間
              orgData[i].dandoriAmt = 0;
              surfaceAdditions.dataItems[i].dandoriAmt = 0;

              // 処理選択名称設定
              orgData[i].processDetailWorkTypeGroup = ProcessDetailGroups?.filter(
                (item) => item.value == orgData[i].processDetailWorkTypeGroup
              )[0]?.label;
              orgData[i].processDetailWorkTypeGroup = orgData[i].processDetailWorkTypeGroup
                ? orgData[i].processDetailWorkTypeGroup
                : ProcessDetailGroups?.[0]?.label;

              orgData[i].processDetailTypeNo = processMst.details?.processDetailTypes?.filter(
                (item) => item.id == processType
              )[0]?.name;
              orgData[i].processDetailTypeNo = orgData[i].processDetailTypeNo
                ? orgData[i].processDetailTypeNo
                : processMst?.details?.processDetailTypes?.[0]?.name;

              /** 　加工金額/時間　*/
              // 加工金額設定
              orgData[i].sagyoAmt = 0;
              surfaceAdditions.dataItems[i].sagyoAmt = 0;
            } else {
              /** 段取金額/時間 */
              // 段取時間設定（基本段取時間	＊	サイズ係数	*	子部品個数係数）
              let dandoriTimeSec = calDandoriTime(partCnt, deviceMst, weight, processType);
              dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
              surfaceAdditions.dataItems[i].dandoriTime = dandoriTimeSec;

              let dandoriTime = dandoriTimeSec;
              orgData[i].dandoriTime = dandoriTime;
              totalDandoriTm += dandoriTime;

              // 段取金額設定（段取時間＊時間チャージ）
              let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst, processType);
              dandoriAmt = dandoriAmt ? dandoriAmt : 0;
              orgData[i].dandoriAmt = dandoriAmt;
              surfaceAdditions.dataItems[i].dandoriAmt = dandoriAmt;
              totalDandori += dandoriAmt ? Math.round(dandoriAmt) : 0;

              // 処理選択名称設定
              orgData[i].processDetailWorkTypeGroup = ProcessDetailGroups?.filter(
                (item) => item.value == orgData[i].processDetailWorkTypeGroup
              )[0]?.label;
              orgData[i].processDetailWorkTypeGroup = orgData[i].processDetailWorkTypeGroup
                ? orgData[i].processDetailWorkTypeGroup
                : ProcessDetailGroups?.[0]?.label;

              orgData[i].processDetailTypeNo = processMst.details?.processDetailTypes?.filter(
                (item) => item.id == processType
              )[0]?.name;
              orgData[i].processDetailTypeNo = orgData[i].processDetailTypeNo
                ? orgData[i].processDetailTypeNo
                : processMst?.details?.processDetailTypes?.[0]?.name;

              /** 　加工金額/時間　*/
              // IQX_WEBEST-65
              let shoriDetail =
                ProcessDetailGroup.DoubleSide == surfaceAdditions?.dataItems[i]?.processDetailWorkTypeGroup ? 2 : 1;
              // 加工時間設定（加工時間	＊	重量係数）
              let sagyoTime = 0;
              if (calMethod === '時間') {
                // IQX_WEBEST-309 面積の作業金額算出式の変更
                let sagyoTimeSec = calSagyoTime(calInput, deviceMst, weight, processType);
                sagyoTimeSec = sagyoTimeSec ? sagyoTimeSec : 0;
                surfaceAdditions.dataItems[i].sagyoTime = sagyoTimeSec;
                sagyoTime = sagyoTimeSec;
              }
              orgData[i].sagyoTime = sagyoTime;
              totalSagyoTm += sagyoTime;
              // 加工金額設定
              //装置の面積・重量時間チャージ // IQX_WEBEST-226
              let mWorkHRate = 0;
              let sagyoAmt = 0;
              if (calMethod === '時間') {
                sagyoAmt = calSagyoAmt(sagyoTime, deviceMst, calMethod, shoriDetail, processType);
              } else if (calMethod === '面積') {
                // IQX_WEBEST-309 面積の作業金額算出式の変更(平方メートルに変換対応)
                calInput = calInput / 10000;
                calInput = Number.isFinite(calInput) ? calInput : 0;
                // IQX_WEBEST-309 面積の作業金額算出式の変更
                mWorkHRate = deviceMst?.details?.charges[processType]?.squareMeter;
                sagyoAmt = calInput * mWorkHRate * shoriDetail; //部品矩形面積 *チャージ金額(A) // 両面塗装の場合は片面塗装*2
              } else if (calMethod === '重量') {
                mWorkHRate = deviceMst?.details?.charges[processType]?.weight;
                sagyoAmt = calInput * mWorkHRate * shoriDetail; //重量＊重量チャージ // 両面塗装の場合は片面塗装*2
              }
              orgData[i].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
              surfaceAdditions.dataItems[i].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
              totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
            }
            // 段取金額の合計
            setDandoriAmtSum(totalDandori);
            // 段取時間の合計
            setDandoriTimeSum(totalDandoriTm);
            // 作業金額の合計
            setSagyoAmtSum(totalSagyo);
            // 作業時間の合計
            setSagyoTimeSum(totalSagyoTm);

            /** 　合計時間　*/
            let totalTimeSum = totalDandoriTm + totalSagyoTm;
            setTotalTimeSum(totalTimeSum);
            surfaceAdditions.totalDataTime = totalTimeSum ? totalTimeSum : 0;
            surfaceAdditions.totalDandori.dataItemsTime = totalDandoriTm ? totalDandoriTm : 0;
            surfaceAdditions.totalSagyo.dataItemsTime = totalSagyoTm ? totalSagyoTm : 0;

            /** 　合計金額　*/
            let totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
            setTotalSum(totalSum ? totalSum : 0);
            surfaceAdditions.totalDataPrice = totalSum ? totalSum : 0;
            surfaceAdditions.totalDandori.dataItemsAmt = totalDandori ? totalDandori : 0;
            surfaceAdditions.totalSagyo.dataItemsAmt = totalSagyo ? totalSagyo : 0;
          }
        }
        setProcessArr(orgData);
        // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
        // 編集データに変更がある場合、保持
        if (surfaceAdditionsEditData?.length > 0 && editDataFlg) {
          toBEdit = JSON.parse(JSON.stringify(surfaceAdditionsEditData));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
      if (toBEdit?.length > 0) {
        editData = toBEdit != undefined ? settingEditData(toBEdit, surfaceAdditions?.dataItems) : [];
        surfaceAdditions.editItems = editData;
        if (editData?.length > 0) {
          setEditedData(editData);
          totals = calculateTotals(editData);
          setTotalAmt(totals[0]?.totalSum ? totals[0]?.totalSum : 0);
          surfaceAdditions.totalEditPrice = totals[0]?.totalSum ? totals[0]?.totalSum : 0;
          surfaceAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum ? totals[0]?.dandoriAmtSum : 0;
          surfaceAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum ? totals[0]?.sagyoAmtSum : 0;

          setTotalTime(totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0);
          surfaceAdditions.totalEditTime = totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0;
          surfaceAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum ? totals[0]?.dandoriTimeSum : 0;
          surfaceAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum ? totals[0]?.sagyoTimeSum : 0;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum);
          setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
          setTotalDandoriTime(totals[0]?.dandoriTimeSum);
        }
        setUpdateEditedData(surfaceAdditions);
      }
      if (surfaceAdditions?.dataType == DataType.Data) {
        setHyomenAmt(surfaceAdditions.totalDataPrice);
      } else {
        setHyomenAmt(surfaceAdditions.totalEditPrice);
      }
      props?.updateKouteiCal(surfaceAdditions, WorkType.PaSurface);
    }
  }, [
    props?.activeTabKey,
    props?.selectedDataDetail,
    props?.activePartMenu,
    props?.selectedDataDetail?.esIq3Info,
    props?.isReCal,
  ]);

  const calSagyoTime = (area, deviceMst, weight, processType) => {
    // 作業時間
    // 重量係数
    let prepCoeffThWeights = deviceMst?.details?.prepCoeffThWeightMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    let prepCoeffThWeightNo =
      prepCoeffThWeights?.length > 0
        ? prepCoeffThWeights?.[0]?.no
        : deviceMst?.details?.prepCoeffThWeightMaster?.[0]?.no;
    let prepCoeffThWeight =
      prepCoeffThWeightNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThWeightItems?.filter(
            (item) => item.no == prepCoeffThWeightNo && !item.isOutOfRange
          )[0]?.value;
    // 加工時間
    let sagyoTime = 0;
    let areaThMaster = deviceMst?.details?.areaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let workTimeThItems = [];
    if (deviceMst?.details?.workTimeThItems) {
      workTimeThItems = Object.entries(deviceMst?.details?.workTimeThItems)?.filter(
        ([key, data]) => parseInt(key) === processType
      )?.[0];
    }

    workTimeThItems?.map((item, index) => {
      if (index > 0) {
        sagyoTime = item?.filter(
          (item) => (item.no == areaThMaster?.[0]?.no || item.no > areaThMaster?.[0]?.no) && !item.isOutOfRange
        )?.[0]?.value;
      }
    });
    sagyoTime = sagyoTime ? sagyoTime : 0;
    // 作業時間＊重量係数
    let cSagyoTimeSec = sagyoTime * prepCoeffThWeight;
    cSagyoTimeSec = cSagyoTimeSec ? cSagyoTimeSec : 0;
    cSagyoTimeSec = cSagyoTimeSec * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
    return cSagyoTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeSec, deviceMst, calMethod, shoriDetail, processType) => {
    //装置の作業時間チャージ
    let mWorkHRate = 0;
    if (calMethod == '面積') {
      mWorkHRate = deviceMst?.details?.charges[processType]?.squareMeter;
    } else if (calMethod == '時間') {
      mWorkHRate = deviceMst?.details?.charges[processType]?.work;
    }
    // 作業時間
    let mWorkHTime = cSagyoTimeSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //　加工時間＊時間チャージ
    let cSagyoAmt = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmt = Number.isFinite(cSagyoAmt) ? cSagyoAmt * shoriDetail : 0;
    return cSagyoAmt;
  };

  const calDandoriTime = (partCnt, deviceMst, weight, processType) => {
    if (partCnt == 0) return 0;
    // 段取時間設定
    // 重量係数
    let mBendCoeffMsts = deviceMst?.details?.prepCoeffThWeightMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    let mBendCoeffMstNo =
      mBendCoeffMsts?.length > 0 ? mBendCoeffMsts[0]?.no : deviceMst?.details?.prepCoeffThWeightMaster?.[0]?.no;
    let mBendCoeff =
      mBendCoeffMstNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThWeightItems?.filter(
            (item) => item.no == mBendCoeffMstNo && !item.isOutOfRange
          )[0]?.value;
    // 部品点数
    let prepThQtyMasters = deviceMst?.details?.prepThQtyMaster?.filter(
      (item) => item.value == partCnt || item.value > partCnt
    );
    let buhinCntNo =
      prepThQtyMasters?.length > 0 ? prepThQtyMasters[0]?.no : deviceMst?.details?.prepThQtyMaster?.[0]?.no;
    let buhinCnt =
      buhinCntNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThChildQtyItems?.filter((item) => item.no == buhinCntNo && !item.isOutOfRange)[0]
            ?.value;
    //　基本段取時間＊重量係数＊部品点数
    let dandoriTimeSec = deviceMst?.details?.prepSecs[processType] * mBendCoeff * buhinCnt;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst, processType) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges?.[processType]?.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = dandoriAmt ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setHyomenAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateKouteiCal(data, WorkType.PaSurface);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(updateEditedData?.dataItems)));
    setTotalDandoriAmt(dandoriAmtSum);
    setTotalDandoriTime(dandoriTimeSum);
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    updateEditedData.totalDandori.editItemsAmt = dandoriAmtSum;
    updateEditedData.totalDandori.editItemsTime = dandoriTimeSum;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    updateEditedData.totalSagyo.editItemsAmt = sagyoAmtSum;
    updateEditedData.totalSagyo.editItemsTime = sagyoTimeSum;

    if (updateEditedData.dataType == DataType.Data) {
      setHyomenAmt(updateEditedData.totalDataPrice);
    } else {
      setHyomenAmt(updateEditedData.totalEditPrice);
    }
    props?.updateKouteiCal(updateEditedData, WorkType.PaSurface);
  };

  const settingEditData = (arr, propsOrgData) => {
    let editDataArr = [];

    for (let i = 0; i < arr?.length; i++) {
      let subArr = arr[i];
      editDataArr.push({
        id: i,
        dandoriAmt: subArr.dandoriAmt,
        dandoriTime: subArr.dandoriTime,
        sagyoAmt: subArr.sagyoAmt,
        sagyoTime: subArr.sagyoTime,
        processDetailTypeNo: propsOrgData[i]?.processDetailTypeNo,
        sumAmt: subArr.dandoriAmt + subArr.sagyoAmt,
        sumTime: subArr.dandoriTime + subArr.sagyoTime,
        calMethod: subArr.calMethod,
        calInput: subArr.calInput,
        processDetailWorkTypeGroup: propsOrgData[i]?.processDetailWorkTypeGroup,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      dandoriAmtSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.round(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) + (dandoriAmtSum ? Math.round(dandoriAmtSum) : 0); // 画面表示用（四捨五入した金額を足す）
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const hyomen = () => {
    if (isHyomendetail == true) {
      setIHyomendetail(false);
    } else {
      setIHyomendetail(true);
    }
  };

  const setHyomenDisplay = (display) => {
    setIHyomendetail(display);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('hyomenProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('hyomenProcess' + i).style.display = 'none';
      document.getElementById('arrowDownHyomen' + i).style.display = 'block';
      document.getElementById('arrowUpHyomen' + i).style.display = 'none';
    } else {
      document.getElementById('hyomenProcess' + i).style.display = 'block';
      document.getElementById('arrowDownHyomen' + i).style.display = 'none';
      document.getElementById('arrowUpHyomen' + i).style.display = 'block';
    }
  };

  useImperativeHandle(ref, () => ({
    getKouteiCalInfoSurface: () => {
      return updateEditedData;
    },
    setHyomenDisplay: setHyomenDisplay,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    } else {
      if (objId !== 'calInput') {
        value = value ? Number(value) : 0;
      }
    }

    if (objId === 'dandoriTime') {
      newState[index][objId] = value;
      if (document.getElementById(index + 'HyomenDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'HyomenDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          //checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (value === 0) {
            newState[index].dandoriAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, index);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, index);
          }
          // checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
      updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
      updateEditedData.editItems[index].dandoriTime = value;
    } else if (objId === 'calInput') {
      newState[index][objId] = value;
      if (document.getElementById(index + 'HyomenSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'HyomenSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          /** 　加工金額/時間　*/
          let calMethod = newState[index].calMethod;
          // IQX_WEBEST-65
          let shori = newState[index].processDetailWorkTypeGroup;
          let shoriDetail = ProcessDetailGroup.DoubleSide == shori ? 2 : 1;
          let mWorkHRate = 0;
          let sagyoAmt = 0;
          if (calMethod === '時間') {
            // 基本情報の総重量
            let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
            let sagyoTimeSec = calSagyoTime(value, deviceMst, weight, orgData[index]?.processDetailTypeNo);
            sagyoTimeSec = sagyoTimeSec ? sagyoTimeSec : 0;
            newState[index]['sagyoTime'] = sagyoTimeSec;
            updateEditedData.editItems[index].sagyoTime = sagyoTimeSec;
            sagyoAmt = calSagyoAmt(
              sagyoTimeSec,
              deviceMst,
              calMethod,
              shoriDetail,
              orgData[index]?.processDetailTypeNo
            ); //加工時間＊時間チャージ
          } else if (calMethod === '面積') {
            // IQX_WEBEST-309 面積の作業金額算出式の変更
            // 面積の場合
            let squareVal = value / 10000; // IQX_WEBEST-309 面積の作業金額算出式の変更(平方メートルに変換対応)
            squareVal = Number.isFinite(squareVal) ? squareVal : 0;
            mWorkHRate = deviceMst?.details?.charges[processType]?.squareMeter;
            sagyoAmt = squareVal * mWorkHRate * shoriDetail; //面積＊面積チャージ
          } else {
            // 重量の場合
            mWorkHRate = deviceMst?.details?.charges[processType]?.weight;
            sagyoAmt = value * mWorkHRate * shoriDetail; //重量＊重量チャージ
          }

          // 加工金額設定
          sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          newState[index]['sagyoAmt'] = sagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = sagyoAmt;
          updateEditedData.editItems[index].calInput = value;
        }
      }
    } else if (objId === 'dandoriAmt') {
      updateEditedData.editItems[index].dandoriAmt = value;
    } else if (objId === 'sagyoTime') {
      if (orgData[index]?.calMethod === '時間') {
        // IQX_WEBEST-309 面積の作業金額算出式の変更
        newState[index][objId] = value;
        if (document.getElementById(index + 'HyomenSagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'HyomenSagyoAmtCu').style.display;
          if (showHideCu === 'block') {
            //checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'red');
          } else {
            if (value === 0) {
              newState[index].sagyoAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newState[index].sagyoAmt = changeAmtwithSagyoTime(
                newState[index].sagyoAmt,
                timeType,
                timeAct,
                value,
                index
              );
            } else {
              newState[index].sagyoAmt = changeAmtwithSagyoTime(
                newState[index].sagyoAmt,
                timeType,
                timeAct,
                value,
                index
              );
            }
            // checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'green');
          }
        }
        updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
        updateEditedData.editItems[index].sagyoTime = value;
      }
    } else if (objId === 'sagyoAmt') {
      newState[index][objId] = value;
      updateEditedData.editItems[index].sagyoAmt = value;
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      newDandoriSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      newSagyoTimeSec += element.sagyoTime ? element.sagyoTime : 0;
      newDandoriTimeSec += element.dandoriTime ? element.dandoriTime : 0;
    });

    newSum = (newSagyoSum ? Math.round(newSagyoSum) : 0) + (newDandoriSum ? Math.round(newDandoriSum) : 0); // 画面表示用（四捨五入した金額を足す）

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalDandori.editItemsTime = newTotalTimeSum;

    setEditedData(newState);
    setHyomenAmt(
      updateEditedData.dataType == DataType.Data ? updateEditedData.totalDataPrice : updateEditedData.totalEditPrice
    );
    props?.updateKouteiCal(updateEditedData, WorkType.PaSurface);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, index) => {
    let editedAmt;
    let totalMin = Number.isFinite(Number(timeVal) / 60) ? Number(timeVal) / 60 : 0;
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      //装置の段階時間チャージ
      let mSetHRate = deviceMst?.details?.charges[orgData[index].processDetailTypeNo].prep;
      editedAmt = Number.isFinite((totalMin * mSetHRate) / 60.0) ? (totalMin * mSetHRate) / 60.0 : 0; //段階時間＊時間チャージ
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  const changeAmtwithSagyoTime = (amt, typeTime, action, timeVal, index) => {
    let editedAmt;
    let totalMin = Number.isFinite(Number(timeVal) / 60) ? Number(timeVal) / 60 : 0;
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      let mWorkHRate = 0;
      //装置の加工時間チャージ
      if (orgData[index]?.calMethod === '面積') {
        mWorkHRate = deviceMst?.details?.charges[orgData[index]?.processDetailTypeNo]?.squareMeter;
      } else if (orgData[index]?.calMethod === '時間') {
        mWorkHRate = deviceMst?.details?.charges[orgData[index]?.processDetailTypeNo]?.work;
      }
      editedAmt = Number.isFinite((totalMin * mWorkHRate) / 60.0) ? (totalMin * mWorkHRate) / 60.0 : 0; //加工時間＊時間チャージ
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = props?.selectedDataDetail?.estimateProducts?.quantity;
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == quantity || item.value > quantity) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  return (
    <>
      {props?.activeTabKey === '4' ? (
        <div className="kouteiCalculateDiv" style={{ display: patternInfo?.length > 0 ? 'block' : 'none' }}>
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={hyomen}>
            <Col span={4} className="oyabuhin-col">
              <div style={{ display: 'flex' }}>
                {isHyomendetail ? (
                  <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
                )}
                <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
              </div>
            </Col>
            <Col span={6} className="oyabuhin-col">
              <div>{hyomenAmt ? JPYs.format(Math.round(hyomenAmt)) : amtEmptyStr}</div>
            </Col>
            <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
              <div>
                <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
              </div>
            </Col>
          </Row>
          <div style={{ display: isHyomendetail ? 'block' : 'none' }}>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                工程詳細
              </Col>
              <Col span={6} className="oyabuhin-detail-col">
                <Checkbox
                  key={kouteiCalculateSelectedArr[0].key}
                  value={kouteiCalculateSelectedArr[0].value}
                  checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
                  disabled={!props?.editMode}
                  style={{ marginRight: 6 }}
                  onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
                ></Checkbox>
                <label>{kouteiCalculateSelectedArr[0].value}</label>
              </Col>
              <Col span={8} className="oyabuhin-detail-col">
                <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                  <Checkbox
                    key={kouteiCalculateSelectedArr[1].key}
                    value={kouteiCalculateSelectedArr[1].value}
                    disabled={!props?.editMode}
                    onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                    checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                    style={{ marginRight: 6 }}
                  ></Checkbox>
                  <label>{kouteiCalculateSelectedArr[1].value}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                  <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                    {DataButton}
                  </Button>
                </div>
              </Col>

              <Col span={1} className="oyabuhin-detail-col">
                単位
              </Col>
              <Col span={5} className="formula-column">
                計算式
              </Col>
            </Row>
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                {processMst?.name}
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalAmtIn"
                  className="resetColor"
                  style={{ color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f' }}
                >
                  {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalTimeIn"
                  className="resetColor"
                  style={{ color: totalTime === totalTimeSum ? 'black' : '#4cc12f' }}
                >
                  {totalTime ? secondsToHms(totalTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.procTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.procTotalTm}>時間</Popover>
              </Col>
            </Row>
            {/* 段取金額/時間 */}
            <Row
              style={{ color: 'black' }}
              className="oyabuhin-detail oyabuhin-row"
              onClick={(e) => handleCollpse('ParentHyomenDandori')}
            >
              <Col span={4} className="oyabuhin-detail-col">
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpParentHyomenDandori'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />

                  <CaretDownOutlined id={'arrowDownParentHyomenDandori'} style={{ fontSize: 17, marginTop: 2 }} />
                  <label> 段取金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalDandoriAmt">
                  {dandoriAmtSum ? JPYs.format(Math.round(dandoriAmtSum)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="hyoumenDandoriTimeSum">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriAmtIn"
                  className="resetColor"
                  style={{ color: Math.round(dandoriAmtSum) === Math.round(totalDandoriAmt) ? 'black' : '#4cc12f' }}
                >
                  {totalDandoriAmt ? JPYs.format(Math.round(totalDandoriAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalDandoriTimeIn"
                  className="resetColor"
                  style={{ color: dandoriTimeSum === totalDandoriTime ? 'black' : '#4cc12f' }}
                >
                  {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.setupTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.setupTotalTm}>時間</Popover>
              </Col>
            </Row>
            <div id={'divParentHyomenDandori'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];
                for (let j = 0; j < processArr?.length; j++) {
                  arr.push(
                    <div>
                      {/* ○○_標準 */}
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col">
                          <div style={{ display: 'flex' }}>
                            <span style={{ marginLeft: '20px' }}>{processArr[j]?.processDetailTypeNo}</span>
                          </div>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={j + 'HyomenDandoriAmt'}>
                            {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                              ? JPYs.format(Math.round(processArr[j]?.dandoriAmt))
                              : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          {
                            <label id={j + 'HyomenDndoriTime'}>
                              {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                            </label>
                          }
                        </Col>

                        <Col span={4} className="input-col">
                          <CurrencyInput
                            id={j + 'HyomenDandoriAmtIn'}
                            value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                            defaultValue={0}
                            style={{
                              border: 'none',
                              color:
                                Math.round(processArr[j]?.dandoriAmt) === Math.round(editedData[j]?.dandoriAmt)
                                  ? 'black'
                                  : checkShowHideIcon(j + 'HyomenDandoriAmtCu') === 'none'
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            onValueChange={(e) => {
                              //checkRegex(e.target.value);
                              if (editedData[j]?.dandoriAmt != e) {
                                settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                                checkInputWithColor(false, '', j + 'HyomenDandoriAmt', e, 'amt');
                              }
                            }}
                            className={
                              props?.editMode
                                ? 'input-editable currency resetColor'
                                : 'input-non-editable currency resetColor'
                            }
                            prefix="¥"
                            decimalsLimit={100}
                          />
                          <div id={j + 'HyomenDandoriAmtCu'} style={{ display: 'none' }}>
                            <div className="currencyIcon">
                              <BsUsbC style={{ fontSize: '25' }} />
                              <BsX style={{ margin: '-8px 0 0 16px' }} />
                              <span class="tooltiptext">
                                金額に変更がありましたので、以降時間と連携して自動計算されない。
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={
                                  editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr
                                }
                                id={j + 'HyomenDandoriTimeIn'}
                                style={{
                                  width: '100%',
                                  color:
                                    processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                      ? handleShowHide(
                                          Number(editedData[j]?.dandoriAmt),
                                          processArr[j]?.dandoriAmt,
                                          j + 'HyomenDandoriAmtCu'
                                        )
                                      : 'red',
                                }}
                                className={
                                  props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                }
                                onChange={(e) => {
                                  settingNewDandoriAmtInArr(e.target.value, j, 'HyomendandoriAmt', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {props?.editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[j]?.dandoriTime,
                                        '10min',
                                        j + 'HyomenDandoriTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[j]?.dandoriTime,
                                        '10min',
                                        j + 'HyomenDandoriTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[j]?.dandoriTime,
                                        'min',
                                        j + 'HyomenDandoriTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[j]?.dandoriTime,
                                        'min',
                                        j + 'HyomenDandoriTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editedData[j]?.dandoriTime,
                                        'sec',
                                        j + 'HyomenDandoriTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editedData[j]?.dandoriTime,
                                        'sec',
                                        j + 'HyomenDandoriTime'
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col"></Col>
                        <Col span={2} className="formula-column formula-column-right-border">
                          <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                        </Col>
                        <Col span={2} className="formula-column">
                          <Popover content={helpCmt.setupTm_p1}>時間</Popover>
                        </Col>
                      </Row>
                    </div>
                  );
                }
                return arr;
              })()}
            </div>
            {/*  作業金額/時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('ParentHyomenSagyoTotal')}>
                <div style={{ display: 'flex' }}>
                  <CaretUpOutlined
                    id={'arrowUpParentHyomenSagyoTotal'}
                    style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  />

                  <CaretDownOutlined id={'arrowDownParentHyomenSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

                  <label> 加工金額/時間</label>
                </div>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label
                  id="totalSagyoAmtIn"
                  className="resetColor"
                  style={{ color: Math.round(totalSagyoAmt) === Math.round(sagyoAmtSum) ? 'black' : '#4cc12f' }}
                >
                  {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
                </label>
              </Col>
              <Col span={4} className="oyabuhin-detail-col">
                <label id="totalSagyoTimeIn" className="resetColor">
                  {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
                </label>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={2} className="formula-column formula-column-right-border">
                <Popover content={helpCmt.workTotalAmt}>金額</Popover>
              </Col>
              <Col span={2} className="formula-column">
                <Popover content={helpCmt.workTotalTm}>時間</Popover>
              </Col>
            </Row>
            <div id={'divParentHyomenSagyoTotal'} style={{ display: 'none' }}>
              {(() => {
                const arr = [];

                for (let i = 0; i < processArr?.length; i++) {
                  arr.push(
                    <div>
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <div style={{ display: 'flex', marginLeft: '20px' }}>
                            <CaretUpOutlined id={'arrowUpHyomen' + i} style={{ fontSize: 17, marginTop: 2 }} />
                            <CaretDownOutlined
                              id={'arrowDownHyomen' + i}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <span>{processArr[i]?.processName}</span>
                          </div>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          {/* ¥ */}
                          <label id={i + 'hyomenSagyoAmt'}>
                            {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <label id={i + 'hyomenSagyoTime'}>
                            {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          {/* ¥ */}
                          <label id={i + 'hyomenSagyoAmt'}>
                            {editedData[i]?.sagyoAmt ? JPYs.format(Math.round(editedData[i]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                          <label id={i + 'hyomenSagyoTime'}>
                            {editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                        <Col
                          span={2}
                          className="formula-column formula-column-right-border"
                          onClick={(e) => subpartDetail(i)}
                        >
                          <Popover content={helpCmt.workAmt_p1}>金額</Popover>
                        </Col>
                        <Col span={2} className="formula-column" onClick={(e) => subpartDetail(i)}>
                          <Popover content={helpCmt.workTm_p1}>時間</Popover>
                        </Col>
                      </Row>
                      <div id={'hyomenProcess' + i} style={{ display: 'none' }}>
                        <div>
                          <div>
                            {/* 作業金額/時間 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <div style={{ display: 'flex' }}>
                                  <span style={{ marginLeft: '38px' }}>作業金額/時間</span>
                                </div>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'HyomenSagyoAmt'}>
                                  {processArr[i]?.sagyoAmt && processArr[i]?.sagyoAmt > 0
                                    ? JPYs.format(Math.round(processArr[i]?.sagyoAmt))
                                    : amtEmptyStr}
                                </label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                {
                                  <label id={i + 'HyomenSagyoTime'}>
                                    {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                                  </label>
                                }
                              </Col>

                              <Col span={4} className="input-col">
                                <CurrencyInput
                                  id={i + 'HyomenSagyoAmtIn'}
                                  value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                                  defaultValue={0}
                                  style={{
                                    border: 'none',
                                    color:
                                      Math.round(processArr[i]?.sagyoAmt) === Math.round(editedData[i]?.sagyoAmt)
                                        ? 'black'
                                        : checkShowHideIcon(i + 'HyomenSagyoAmtCu') === 'none'
                                        ? '#4cc12f'
                                        : 'red',
                                  }}
                                  onValueChange={(e) => {
                                    //checkRegex(e.target.value);
                                    if (editedData[i]?.sagyoAmt != e) {
                                      settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                                      checkInputWithColor(false, '', i + 'HyomenSagyoAmt', e, 'amt');
                                    }
                                  }}
                                  className={
                                    props?.editMode
                                      ? 'input-editable currency resetColor'
                                      : 'input-non-editable currency resetColor'
                                  }
                                  prefix="¥"
                                  decimalsLimit={100}
                                />
                                <div id={i + 'HyomenSagyoAmtCu'} style={{ display: 'none' }}>
                                  <div className="currencyIcon">
                                    <BsUsbC style={{ fontSize: '25' }} />
                                    <BsX style={{ margin: '-8px 0 0 16px' }} />
                                    <span class="tooltiptext">
                                      金額に変更がありましたので、以降時間と連携して自動計算されない。
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col span={4} className="input-col">
                                <Row style={{ width: '100%' }}>
                                  <Col className="timer-input-col">
                                    <Input
                                      value={
                                        editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr
                                      }
                                      id={i + 'HyomenSagyoTimeIn'}
                                      style={{
                                        width: '100%',
                                        color:
                                          processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                            ? handleShowHide(
                                                Number(editedData[i]?.sagyoAmt),
                                                processArr[i]?.sagyoAmt,
                                                i + 'HyomenSagyoAmtCu'
                                              )
                                            : 'red',
                                      }}
                                      className={
                                        props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                      }
                                      onChange={(e) => {
                                        settingNewDandoriAmtInArr(
                                          e.target.value,
                                          i,
                                          'HyomenSagyoAmt',
                                          'input',
                                          'input'
                                        );
                                      }}
                                    ></Input>
                                  </Col>
                                  <Col className="timer-button-col">
                                    {props?.editMode ? (
                                      <>
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.sagyoTime,
                                              '10min',
                                              i + 'HyomenSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.sagyoTime,
                                              '10min',
                                              i + 'HyomenSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.sagyoTime,
                                              'min',
                                              i + 'HyomenSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.sagyoTime,
                                              'min',
                                              i + 'HyomenSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              editedData[i]?.sagyoTime,
                                              'sec',
                                              i + 'HyomenSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              editedData[i]?.sagyoTime,
                                              'sec',
                                              i + 'HyomenSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={5} className="formula-column"></Col>
                            </Row>
                            {/* 計算法 /入力 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <span className="ddl_2_noImg" style={{ marginLeft: 38 }}>
                                  計算法 /入力
                                </span>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'calMethod'}>{processArr[i]?.calMethod}</label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'calInput'}>{processArr[i]?.calInput}</label>
                              </Col>

                              <Col span={4} className="oyabuhin-detail-col">
                                <label id={i + 'calMethodIn'}>{editedData[i]?.calMethod}</label>
                              </Col>

                              <Col span={4} className="input-col">
                                <Input
                                  value={editedData[i]?.calInput}
                                  id={i + 'calInputIn'}
                                  min={0}
                                  className={props?.editMode ? 'input-editable' : 'input-non-editable '}
                                  controls={false}
                                  style={{
                                    width: '45%',
                                    border: 'none',
                                    color:
                                      Number(editedData[i]?.calInput) === Number(processArr[i]?.calInput)
                                        ? 'black'
                                        : 'red',
                                  }}
                                  onKeyPress={(e) => {
                                    handleKeyPress(e, false);
                                  }}
                                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'calInput', '', '')}
                                />
                              </Col>
                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={4} className="formula-column"></Col>
                            </Row>

                            {/*  処理選択 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <span className="ddl_2_noImg" style={{ marginLeft: 38 }}>
                                  処理選択
                                </span>
                              </Col>

                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'shori'}>{processArr[i]?.processDetailWorkTypeGroup}</label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={i + 'processType'}>{processArr[i]?.processDetailTypeNo}</label>
                              </Col>

                              <Col span={4} className="oyabuhin-detail-col">
                                <label id={i + 'shoriIn'}>{processArr[i]?.processDetailWorkTypeGroup}</label>
                              </Col>

                              <Col span={4} className="oyabuhin-detail-col">
                                <label id={i + 'processTypeIn'}>{processArr[i]?.processDetailTypeNo}</label>
                              </Col>

                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={5} className="formula-column"></Col>
                            </Row>

                            {/* 加工種類/仮付箇所 */}
                            {/*   <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg">加工種類/仮付箇所</span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="proType">{processArr[i]?.processDetailTypeNo}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'subCount'}>{processArr[i]?.subCount}</label>
                      </Col>

                      {props?.editMode ? (
                        <Col span={4} className="input-col">
                          <Select
                            defaultValue={editedData[i]?.processDetailTypeNo}
                            style={{
                              width: '100%',
                              margin: '0 5px',
                              fontSize: 14,
                            }}
                            id="proTypeIn"
                            onChange={(e) => settingNewDandoriAmtInArr(e, i, 'processType', '', '')}
                            options={hyomensArr?.map((province) => ({
                              label: province,
                              value: province,
                            }))}
                          />
                        </Col>
                      ) : (
                        <Col span={4} className="oyabuhin-detail-col">
                          <label id="proTypeIn">{editedData[i]?.processDetailTypeNo}</label>
                        </Col>
                      )}

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[i]?.subCount}
                          id={i + 'subCountIn'}
                          min={0}
                          className={props?.editMode ? 'input-editable' : 'input-non-editable '}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[i]?.subCount) === processArr[i]?.subCount ? 'black' : 'red',
                          }}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'subCount', '', '')}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="formula-column"></Col>
                    </Row> */}

                            {/*  加工回数/溶接仕上 */}
                            {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg">加工回数/溶接仕上</span>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'subTimekari'}>{processArr[i]?.subTimekari}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'subFinishKari'}>{processArr[i]?.subFinishKari}</label>
                      </Col>
                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[i]?.subTimekari}
                          id={i + 'subTimekariIn'}
                          min={0}
                          className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              Number(editedData[i]?.subTimekari) === processArr[i]?.subTimekari ? 'black' : 'red',
                          }}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'subTimekari', '', '')}
                        />
                      </Col>

                      {props?.editMode ? (
                        <Col span={4} className="input-col">
                          <Select
                            defaultValue={editedData[i]?.subFinishKari}
                            style={{
                              width: '100%',
                              margin: '0 5px',
                              fontSize: 14,
                            }}
                            id={i + 'subFinishKariIn'}
                            onChange={(e) => settingNewDandoriAmtInArr(e, i, 'subFinishKari', '', '')}
                            options={finishData?.map((finish) => ({ label: finish, value: finish }))}
                          />
                        </Col>
                      ) : (
                        <Col span={4} className="oyabuhin-detail-col">
                          <label id={i + 'subFinishKariIn'}>{editedData[i]?.subFinishKari}</label>
                        </Col>
                      )}
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return arr;
              })()}
              {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
        <Col span={4} className="oyabuhin-detail-col">
          <span style={{ marginLeft: '5px', fontSize: 11 }}>マテリアルハンドリング金額/時間</span>
        </Col>
        <Col span={3} className="oyabuhin-detail-col">
       
          <label id="HyomenHandlingAmt">{JPYs.format(handelingAmt)}</label>
        </Col>
        <Col span={3} className="oyabuhin-detail-col">
          <label id="hyomenHandelingTime">{handelingTime}</label>
        </Col>
        <Col span={4} className="input-col">
          <CurrencyInput
            id="HyomenHandlingAmtIn"
            value={editedData[0]?.handelingAmt}
            defaultValue={0}
            onValueChange={(e) => {
              if (editedData[0]?.handelingAmt != e) {
               
                settingNewDandoriAmtInArr(e, 0, 'handelingAmt', '', '');
                checkInputWithColor(false,'','HyomenHandlingAmt', e, 'amt');
              }
            }}
            style={{ border: 'none' }}
            className={props?.editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
            prefix="¥"
            decimalsLimit={100}
          />

          <div id={'HyomenHandlingAmtCu'} style={{ display: 'none' }} className="currencyReset">
            <div className="currencyIcon">
              <BsUsbC style={{ fontSize: '25' }} />
              <BsX style={{ margin: '-8px 0 0 16px' }} />
              <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
            </div>
          </div>
        </Col>
        <Col span={4} className="input-col">
          <Row style={{ width: '100%' }}>
            <Col className="timer-input-col">
              <Input
                value={editedData[0]?.handelingTime}
                id="HyomenHandlingTimeIn"
                style={{
                  width: '100%',
                  color:
                    editedData[0]?.handelingTime === handelingTime
                      ? handleShowHide(
                          Number(handelingAmt),
                          Number(editedData[0]?.handelingAmt),
                          'HyomenHandlingAmtCu'
                        )
                      : 'red',
                }}
                className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                onChange={(e) => {
                  settingNewDandoriAmtInArr(e.target.value, 0, 'handelingTime', 'input', 'input');
                }}
              ></Input>
            </Col>
            <Col className="timer-button-col">
              {props?.editMode ? (
                <>
                  <CaretUpOutlined
                    style={{ fontSize: 17 + 'px' }}
                    onClick={() => {
                      let editedTime = handelIncreaseTime(
                        editedData[0]?.handelingTime,
                        '10min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', '10min', 'inc');
                    }}
                  />
                  <CaretDownOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelDecreaseTime(
                        editedData[0]?.handelingTime,
                        '10min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', '10min', 'dec');
                    }}
                  />
                  <CaretUpOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelIncreaseTime(
                        editedData[0]?.handelingTime,
                        'min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'min', 'inc');
                    }}
                  />
                  <CaretDownOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelDecreaseTime(
                        editedData[0]?.handelingTime,
                        'min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'min', 'dec');
                    }}
                  />
                  <CaretUpOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelIncreaseTime(
                        editedData[0]?.handelingTime,
                        'sec',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'sec', 'inc');
                    }}
                  />
                  <CaretDownOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelDecreaseTime(
                        editedData[0]?.handelingTime,
                        'sec',
                        'hyomenHandelingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'sec', 'dec');
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={1} className="oyabuhin-detail-col"></Col>
        <Col span={5} className="formula-column"></Col>
      </Row> */}
            </div>
            {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
      <Col span={4} className="oyabuhin-detail-col">
        <span style={{ marginLeft: '5px' }}>工程内検査金額/時間</span>
      </Col>
      <Col span={3} className="oyabuhin-detail-col">
        <label id="HyomenKensaAmt">{JPYs.format(kensaAmt)}</label>
      </Col>
      <Col span={3} className="oyabuhin-detail-col">
        <label id="HyomenKensaTime">{kensaTime}</label>
      </Col>
      <Col span={4} className="input-col">
        <CurrencyInput
          id="HyomenKensaAmtIn"
          value={editedData[0]?.kensaAmt}
          defaultValue={0}
          onValueChange={(e) => {
            if (editedData[0]?.kensaAmt != e) {
              settingNewDandoriAmtInArr(e, 0, 'kensaAmt', '', '');
              checkInputWithColor(false,'','HyomenKensaAmt', e, 'amt');
            }
          }}
          style={{
            border: 'none',
            color:
              Number(editedData[0]?.kensaAmt) === kensaAmt
                ? 'black'
                : checkShowHideIcon('HyomenKensaAmtCu') === 'none'
                ? '#4cc12f'
                : 'red',
          }}
          className={props?.editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
          prefix="¥"
          decimalsLimit={100}
        />
        <div id="HyomenKensaAmtCu" style={{ display: 'none' }} className="currencyReset">
          <div className="currencyIcon">
            <BsUsbC style={{ fontSize: '25' }} />
            <BsX style={{ margin: '-8px 0 0 16px' }} />
            <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
          </div>
        </div>
      </Col>
      <Col span={4} className="input-col">
        <Row style={{ width: '100%' }}>
          <Col className="timer-input-col">
            <Input
              value={editedData[0]?.kensaTime}
              id="HyomenKensaTimeIn"
              style={{
                width: '100%',
                color:
                  editedData[0]?.kensaTime === kensaTime
                    ? handleShowHide(Number(kensaAmt), Number(editedData[0]?.kensaAmt), 'HyomenKensaAmtCu')
                    : 'red',
              }}
              className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
              onChange={(e) => {
                settingNewDandoriAmtInArr(e.target.value, 0, 'kensaTime', 'input', 'input');
              }}
            ></Input>
          </Col>
          <Col className="timer-button-col">
            {props?.editMode ? (
              <>
                <CaretUpOutlined
                  style={{ fontSize: 17 + 'px' }}
                  onClick={() => {
                    let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, '10min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'inc');
                  }}
                />
                <CaretDownOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, '10min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'dec');
                  }}
                />
                <CaretUpOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'inc');
                  }}
                />
                <CaretDownOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'dec');
                  }}
                />
                <CaretUpOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'sec', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'inc');
                  }}
                />
                <CaretDownOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'sec', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'dec');
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={1} className="oyabuhin-detail-col"></Col>
      <Col span={5} className="formula-column"></Col>
    </Row> */}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default KouteiCalculate_Hyomen;
