/**
 * クラス名：材料計算詳細画面
 * 説明：形鋼に使用の材料計算詳細画面です。
 * 作成者：ナンス
 * 作成日：2024/10/03
 * バージョン：1.0
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Input, Checkbox, Select } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import { formValidatorMode, isDigit, regexExp, toHalfWidth } from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);

const SteelMaterialCalculateDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;
  const [selectedRowData, setSelectedRowData] = useState();
  const [originData, setOriginData] = useState();
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [steelUnitUse, setSteelUnitUse] = useState([]);

  const inputItemRef = {};

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let selectedData = JSON.parse(JSON.stringify({ ...props.selectedData }));
      setOriginData({ ...props.selectedData });
      setSelectedRowData(selectedData);
      setSteelUnitUse(props?.steelUnitUseLst ? JSON.parse(JSON.stringify(props?.steelUnitUseLst)) : []);
      setIsNoData(false);
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData, props?.materialTypeList, props?.mstMaterialTypeIq3List, props?.steelUnitUseLst]);

  useEffect(() => {
    if (selectedRowData === undefined) return;
    typeof selectedRowData === 'object' ? setOriginData({ ...selectedRowData }) : setOriginData([...selectedRowData]);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const editDetailInput = (objKey, value) => {
    if (editMode === false) return;
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      var updatedData = { ...prevData, info: { ...prevData.info } };
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  const editData = () => {
    if (
      selectedRowData?.name?.trim()?.toLowerCase() === 'other' ||
      selectedRowData?.name?.trim()?.toLowerCase() === 'free'
    ) {
      setCheckName(true);
    } else {
      setCheckName(false);
    }
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = async (inputItems, procMode, isDetail) => {
    let ret = true;
    let message = [];
    // 名称
    let input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 長さ
    input = inputItems['length']?.input;
    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('長さ').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('長さ').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = async (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!(await formValidation(inputItemRef, formValidatorMode.Edit, true))) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };

      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData, steelUnitUse);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData({ ...props.selectedData });
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (editMode === false) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    editDetailInput(name, value);
  };

  const changeSteelFixedSizeMatTypes = (id, val) => {
    if (editMode === false) return;
    let selectedRowDataUp = JSON.parse(JSON.stringify({ ...selectedRowData }));
    let checkDataExist = selectedRowDataUp?.steelFixedSizeMatTypes?.filter((item) => item.materialType.id == id);
    let addNewSteelFixedSizeType = [];
    selectedRowDataUp?.steelFixedSizeMatTypes?.map((item, index) => {
      if (item.materialType.id == id) {
        item.info.isUsed = val;
        index = selectedRowDataUp?.steelFixedSizeMatTypes?.length + 1;
      } else if (item.materialType.id != id && checkDataExist?.length == 0 && addNewSteelFixedSizeType.length == 0) {
        // 新規追加
        let newItem = JSON.parse(JSON.stringify(item));
        newItem.id = 0;
        newItem.materialType.id = id;
        newItem.info.isUsed = val;
        addNewSteelFixedSizeType.push(newItem);
        index = selectedRowDataUp?.steelFixedSizeMatTypes?.length + 1;
      }
    });
    if (selectedRowDataUp?.steelFixedSizeMatTypes?.length === 0) {
      // 新規追加
      let newItem = {
        id: 0,
        materialType: { id: id },
        info: { isUsed: val },
      };
      addNewSteelFixedSizeType.push(newItem);
    }
    if (addNewSteelFixedSizeType?.length > 0) {
      let orgSteelFixedSizeType = JSON.parse(JSON.stringify(selectedRowDataUp?.steelFixedSizeMatTypes));
      let edSteelFixedSizeType = [...orgSteelFixedSizeType, ...addNewSteelFixedSizeType];
      selectedRowDataUp.steelFixedSizeMatTypes = edSteelFixedSizeType;
    }
    setSelectedRowData((prevData) => {
      var updatedData = { ...prevData, steelFixedSizeMatTypes: selectedRowDataUp.steelFixedSizeMatTypes };
      return updatedData;
    });
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          {/* 採用単価初期設定 */}
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">採用単価初期設定</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        style={{ marginLeft: 0, width: '60%' }}
                        onChange={(key) => {
                          {
                            if (editMode === false) return;
                            let steelTemp = [...steelUnitUse];
                            steelTemp?.map((s) => {
                              if (s.id === key) {
                                s.used = true;
                              } else {
                                s.used = false;
                              }
                            });
                            setSteelUnitUse(steelTemp);
                          }
                        }}
                        placeholder="採用単価初期設定"
                        id="steelUnitUse"
                        name="steelUnitUse-U"
                        value={steelUnitUse?.filter((item) => item.used)?.[0]?.id}
                      >
                        {steelUnitUse?.map((item, index) => (
                          <Select.Option key={index} id={'steelUnitUse' + item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <>
                      <label className="param_detail-title-label">
                        {steelUnitUse?.filter((item) => item.used)?.[0]?.name}
                      </label>
                    </>
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* 名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode && !checkName ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('name', e.target.value)}
                  />
                </td>
              </tr>
              {/* 長さ */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">長さ</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode && !checkName ? 'input-editable' : 'input-non-editable'}
                    name="length"
                    id="length"
                    value={selectedRowData?.length}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['length'] = ref;
                    }}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                  />
                </td>
              </tr>
              {/* SteelMaterialType */}
              {props?.selectedMaterialType != undefined
                ? props?.selectedMaterialType?.map((mateiralType, index) => {
                    let { id, name } = mateiralType;
                    const steelMaterialType = selectedRowData?.steelFixedSizeMatTypes?.filter(
                      (item) => item.materialType.id == id
                    );
                    return (
                      <>
                        <tr className="detail-tbl-row" key={index}>
                          <td className="param-detail-tbl-val-title">
                            <label className="param_detail-title-label">{name}</label>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <Checkbox
                              style={{ marginLeft: 10, marginRight: 10 }}
                              type="checkbox"
                              name={'steelMaterialType' + index}
                              id={'steelMaterialType' + index}
                              defaultChecked={false}
                              checked={steelMaterialType && steelMaterialType?.[0]?.info?.isUsed ? true : false}
                              onChange={(e) => changeSteelFixedSizeMatTypes(id, e.target.checked)}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default SteelMaterialCalculateDetail;
