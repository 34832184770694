export const DiffList = [
  {
    name: 'A級',
    id: 1,
  },
  {
    name: 'B級',
    id: 2,
  },
  {
    name: 'C級',
    id: 3,
  },
];
export const WeldFinish = [
  {
    name: '80',
    id: 1,
  },
  {
    name: '120',
    id: 2,
  },
  {
    name: 'バフ',
    id: 3,
  },
  {
    name: '酸洗い',
    id: 4,
  },
  {
    name: 'HL',
    id: 5,
  },
  {
    name: '鏡面',
    id: 6,
  },
];
export const UsedPlace = [
  {
    name: '外装品',
    id: 1,
  },
  {
    name: '強度必要',
    id: 2,
  },
  {
    name: '公差厳しい',
    id: 3,
  },
  {
    name: '水漏れ不可',
    id: 4,
  },
  {
    name: '内部部品',
    id: 5,
  },
];
export const ProcessDateRange = [
  {
    name: '当日',
    id: 1,
  },
  {
    name: '2日～3日',
    id: 2,
  },
  {
    name: '4日～6日',
    id: 3,
  },
  {
    name: '7日～10日',
    id: 4,
  },
  {
    name: '10日～20日',
    id: 5,
  },
  {
    name: '21日～31日',
    id: 5,
  },
  {
    name: '1か月以上',
    id: 5,
  },
];
export const WeldPartCount = [
  {
    name: '1個',
    id: 1,
  },
  {
    name: '2個～4個',
    id: 2,
  },
  {
    name: '5個～8個',
    id: 3,
  },
  {
    name: '9個～12個',
    id: 4,
  },
  {
    name: '13個～20個',
    id: 5,
  },
  {
    name: '21個～',
    id: 5,
  },
];
export const InstallationParts = [
  {
    name: 'スタッドボルト・ナット',
    id: 1,
  },
  {
    name: '溶接ボルト・ナット',
    id: 2,
  },
  {
    name: 'カシメボルト・ナット',
    id: 3,
  },
];
export const TapForm = [
  {
    name: 'タップ',
    id: 1,
  },
  {
    name: 'バーリング',
    id: 2,
  },
  {
    name: '皿モミ',
    id: 3,
  },
  {
    name: 'その他',
    id: 4,
  },
];
export const MaxBendingLen = [
  {
    name: '400mm',
    id: 1,
  },
  {
    name: '900mm',
    id: 2,
  },
  {
    name: '1200mm',
    id: 3,
  },
  {
    name: '1800mm',
    id: 4,
  },
  {
    name: '2400mm',
    id: 5,
  },
  {
    name: '3000mm',
    id: 5,
  },
  {
    name: '3001mm～',
    id: 5,
  },
];
export const DeliveryArea = [
  {
    name: '北海道',
    id: 1,
  },
  {
    name: '青森県',
    id: 2,
  },
  {
    name: '岩手県',
    id: 3,
  },
  {
    name: '宮城県',
    id: 4,
  },
  {
    name: '秋田県',
    id: 5,
  },
  {
    name: '山形県',
    id: 6,
  },
  {
    name: '福島県',
    id: 7,
  },
  {
    name: '茨城県',
    id: 8,
  },
  {
    name: '栃木県',
    id: 9,
  },
  {
    name: '群馬県',
    id: 10,
  },
  {
    name: '埼玉県',
    id: 11,
  },
  {
    name: '千葉県',
    id: 12,
  },
  {
    name: '東京都',
    id: 13,
  },
  {
    name: '神奈川県',
    id: 14,
  },
  {
    name: '新潟県',
    id: 15,
  },
  {
    name: '富山県',
    id: 16,
  },
  {
    name: '石川県',
    id: 17,
  },
  {
    name: '福井県',
    id: 18,
  },
  {
    name: '山梨県',
    id: 19,
  },
  {
    name: '長野県',
    id: 20,
  },
  {
    name: '岐阜県',
    id: 21,
  },
  {
    name: '静岡県',
    id: 22,
  },
  {
    name: '愛知県',
    id: 23,
  },
  {
    name: '三重県',
    id: 24,
  },
  {
    name: '滋賀県',
    id: 25,
  },
  {
    name: '京都府',
    id: 26,
  },
  {
    name: '大阪府',
    id: 27,
  },
  {
    name: '兵庫県',
    id: 28,
  },
  {
    name: '奈良県',
    id: 29,
  },
  {
    name: '和歌山県',
    id: 30,
  },
  {
    name: '鳥取県',
    id: 31,
  },
  {
    name: '島根県',
    id: 32,
  },
  {
    name: '岡山県',
    id: 33,
  },
  {
    name: '広島県',
    id: 34,
  },
  {
    name: '山口県',
    id: 35,
  },
  {
    name: '徳島県',
    id: 36,
  },
  {
    name: '香川県',
    id: 37,
  },
  {
    name: '愛媛県',
    id: 38,
  },
  {
    name: '高知県',
    id: 39,
  },
  {
    name: '福岡県',
    id: 40,
  },
  {
    name: '佐賀県',
    id: 41,
  },
  {
    name: '長崎県',
    id: 42,
  },
  {
    name: '熊本県',
    id: 43,
  },
  {
    name: '大分県',
    id: 44,
  },
  {
    name: '宮崎県',
    id: 45,
  },
  {
    name: '鹿児島県',
    id: 46,
  },
  {
    name: '沖縄県',
    id: 47,
  },
];
export const Plating = [
  {
    name: 'クロメート',
    id: 1,
  },
  {
    name: '三価クロメート',
    id: 2,
  },
  {
    name: 'ユニクロ',
    id: 3,
  },
  {
    name: 'ニッケル',
    id: 4,
  },
  {
    name: '無電解ニッケル',
    id: 5,
  },
  {
    name: 'クロム',
    id: 6,
  },
  {
    name: 'アルマイト',
    id: 7,
  },
  {
    name: '亜鉛',
    id: 8,
  },
];
export const Painting = [
  {
    name: '粉体',
    id: 1,
  },
  {
    name: '溶剤',
    id: 2,
  },
  {
    name: 'エポキシ',
    id: 3,
  },
  {
    name: 'アクリル樹脂',
    id: 4,
  },
  {
    name: 'フッ素樹脂',
    id: 5,
  },
  {
    name: 'カチオン',
    id: 6,
  },
];
