import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

import { handleKeyPress, regexExp } from '../common/Common';

const IQ3KouteiInput_Shearing = forwardRef((props, ref) => {
  const [numOfCuts, setNumOfCuts] = useState('');
  const [autoExtract, setAutoExtract] = useState(0); // IQX_WEBEST-179

  useEffect(() => {
    if (props?.tabOpen) {
      let data = props.shearingInfo;
      if (data != undefined && data.details != undefined) {
        setNumOfCuts(data?.details?.[0]?.numOfCuts);
        setAutoExtract(data?.details?.[0]?.autoExtract); // IQX_WEBEST-179
      }
    }
  }, [props.shearingInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  return (
    <>
      {props?.tabOpen && props?.isShirring ? (
        <Row className="mt-2 registerModal">
          <Table className="kouteiinput " style={{ width: '20%' }}>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: '0.1%' }} className="tbl-header">
                  No.
                </th>
                <th style={{ width: '17%' }} className="tbl-header">
                  カット回数
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {numOfCuts === '' ? (
              <></>
            ) : (
              <> */}
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>1</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  {/* {editMode ? (
                      <> */}
                  {/** IQX_WEBEST-179 */}
                  <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{autoExtract}</label>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={numOfCuts}
                    onChange={(e) => {
                      if (!regexExp.test(e.target.value)) {
                        setNumOfCuts(e.target.value);
                        props.shearingInfo.details[0].numOfCuts = e.target.value;
                        props.updateShearing(props.shearingInfo);
                      } else {
                        setNumOfCuts(0);
                        props.shearingInfo.details[0].numOfCuts = 0;
                        props.updateShearing(props.shearingInfo);
                      }
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e, true);
                    }}
                    style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  ></Input>
                  {/* </>
                    ) : (
                      <>{numOfCuts}</>
                    )} */}
                </td>
              </tr>
              {/* </>
            )} */}
            </tbody>
          </Table>
        </Row>
      ) : (
        ''
      )}
    </>
  );
});

export default IQ3KouteiInput_Shearing;
